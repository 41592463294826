<template>
  <div v-if="discountTagDisplayText" class="col-span-2 text-xs">
    <AppPriceAdjustmentTag
      :tag-subtitle-text="discountTagDisplayText"
      :tag-title-text="toTitleCase($t('specialOffer'))"
      :icon="IconDiscount2"
    />
  </div>
</template>

<script setup lang="ts">
import { IconDiscount2 } from '@tabler/icons-vue';
import { computed } from 'vue';
import type { UnitItineraryItem } from '@/booking-itinerary/booking-itinerary';
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import { useUnitItineraryItemStore } from '@/booking-itinerary/unit-itinerary-item/store/unit-itinerary-item.store';
import { useDiscountOffer } from '@/property/offer/discount-offer/discount-offer.composable';
import { useFreeNightOffer } from '@/property/offer/free-night-offer/free-night-offer.composable';
import { OfferType } from '@/property/offer/offer';
import { useSearchStore } from '@/search/search.store';
import { getStayLengthFromStayDates } from '@/stay-dates/stay-dates.utilities';
import { useLocaleString } from '@/string/locale-string.composable';
import AppPriceAdjustmentTag from '@/ui/app-price-adjustment-tag/AppPriceAdjustmentTag.vue';

const props = defineProps<{
  unitItineraryItem: UnitItineraryItem;
}>();

const { toTitleCase } = useLocaleString();

const searchStore = useSearchStore();
const unitItineraryItemStore = useUnitItineraryItemStore(
  props.unitItineraryItem.id,
);
const bookingItineraryStore = useBookingItineraryStore();

const discountTagDisplayText = computed(() => {
  if (unitItineraryItemStore.offer?.offerType === OfferType.Discount) {
    const { generateDiscountOfferSummary } = useDiscountOffer(
      unitItineraryItemStore.offer,
    );

    return generateDiscountOfferSummary(
      unitItineraryItemStore.unit,
      unitItineraryItemStore.selectedMeals,
      searchStore.activeProperty,
    );
  }

  if (unitItineraryItemStore.offer?.offerType === OfferType.FreeNight) {
    const { generateFreeNightOfferSummary } = useFreeNightOffer(
      unitItineraryItemStore.offer,
    );

    return generateFreeNightOfferSummary(
      getStayLengthFromStayDates(bookingItineraryStore.stayDates!),
    );
  }

  return undefined;
});
</script>
