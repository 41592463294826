<template>
  <div class="flex justify-between">
    <div class="flex">
      <AppLinkButton
        v-show="!isNoDateSelected"
        class="mr-1 self-center"
        :text="capitalize($t('clearDates'))"
        @click="clearSelectedDates"
      />
    </div>
    <div class="flex justify-end">
      <AvailabilityCalendarStatus class="hidden md:flex" />
      <AppPrimaryButton
        class="min-w-[7rem] self-center p-3"
        :button-text="buttonText"
        :icon="icon"
        @click="validateAndSearchDates"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Icon } from '@tabler/icons-vue';
import { watchEffect } from 'vue';
import { useAvailabilityCalendarStore } from '@/availability-calendar/availability-calendar.store';
import AvailabilityCalendarStatus from '@/availability-calendar/AvailabilityCalendarStatus.vue';
import { useLocaleString } from '@/string/locale-string.composable';
import AppLinkButton from '@/ui/app-link-button/AppLinkButton.vue';
import AppPrimaryButton from '@/ui/app-primary-button/AppPrimaryButton.vue';

defineProps<{
  buttonText: string;
  icon?: Icon | null | undefined;
}>();

const emit = defineEmits(['submit']);

const { capitalize } = useLocaleString();

const {
  isCheckInAndCheckOutDateSelected,
  isNoDateSelected,
  clearSelectedDates,
  showCalendarStatusError,
  animateCalendarStatusError,
} = useAvailabilityCalendarStore();

watchEffect(() => {
  if (isCheckInAndCheckOutDateSelected.value) {
    showCalendarStatusError.value = animateCalendarStatusError.value = false;
  }
});

const validateAndSearchDates = () => {
  if (!isCheckInAndCheckOutDateSelected.value) {
    showCalendarStatusError.value = animateCalendarStatusError.value = true;
  } else {
    showCalendarStatusError.value = animateCalendarStatusError.value = false;
    emit('submit');
  }
};
</script>
