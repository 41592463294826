import type { Options } from 'ky';
import { ky } from '@/ky';
import {
  type PrivateRateOverrideDTO,
  type PrivateRateOverride,
} from '@/private-rate-override/private-rate-override';
import { mapPrivateRateOverrideFromDTO } from '@/private-rate-override/private-rate-override.mapper';

export const sendFetchPropertyPrivateRateOverrideByCodeRequest = async (
  propertyId: number,
  code: string,
  shouldSuppressError = true,
): Promise<PrivateRateOverride | undefined> =>
  sendFetchPrivateRateOverrideRequest(
    `property/${propertyId}/private-rate-override`,
    { searchParams: { code } },
    shouldSuppressError,
  );

export const sendFetchWidgetPrimaryPropertyPrivateRateOverrideByCodeRequest =
  async (
    widgetId: string,
    code: string,
    shouldSuppressError = true,
  ): Promise<PrivateRateOverride | undefined> =>
    sendFetchPrivateRateOverrideRequest(
      `widgets/${widgetId}/primary-property/private-rate-override`,
      { searchParams: { code } },
      shouldSuppressError,
    );

const sendFetchPrivateRateOverrideRequest = async (
  url: string,
  options: Options,
  shouldSuppressError: boolean,
): Promise<PrivateRateOverride | undefined> => {
  try {
    const privateRateOverrideDTO = await ky
      .get(url, options)
      .json<PrivateRateOverrideDTO>();

    return mapPrivateRateOverrideFromDTO(privateRateOverrideDTO);
  } catch (error) {
    if (shouldSuppressError) {
      return undefined;
    }

    throw error;
  }
};
