import { watchDebounced } from '@vueuse/core';
import { computed, ref } from 'vue';
import type { LocationQueryRaw } from 'vue-router';
import router from '@/router';
import {
  ABANDONED_CART_RECOVERY_REQUEST_QUERY_PARAM_KEY,
  BOOKING_ITINERARY_QUERY_PARAM_KEY,
  BOOKING_SOURCE_QUERY_PARAM_KEY,
  LOCALE_QUERY_PARAM_KEY,
  SEARCH_OCCUPANCIES_QUERY_PARAM_KEY,
  SEARCH_STAY_DATES_QUERY_PARAM_KEY,
} from '@/url/query-params/index';
import {
  findAbandonedCartRecoveryRequestQueryParamValue,
  findBookingItineraryQueryParamValue,
  findBookingSourceQueryParamValue,
  findElementsQueryParamValue,
  findLocaleQueryParamValue,
  findSearchOccupanciesQueryParamValue,
  findSearchStayDatesQueryParamValue,
} from '@/url/query-params/query-params.utilities';

export const queryParamsToSync = ref<LocationQueryRaw>({});

watchDebounced(
  queryParamsToSync,
  (queryParamsToSync) => {
    void router.replace({
      query: {
        ...router.currentRoute.value.query,
        ...queryParamsToSync,
      },
    });
  },
  {
    debounce: 500,
    deep: true,
  },
);

export const useQueryParams = () => {
  const addQueryParams = (queryParams: LocationQueryRaw): void => {
    queryParamsToSync.value = {
      ...queryParamsToSync.value,
      ...queryParams,
    };
  };

  const searchStayDatesQueryParam = computed({
    get() {
      return findSearchStayDatesQueryParamValue(
        router.currentRoute.value.query,
      );
    },
    set(searchStayDates) {
      addQueryParams({
        [SEARCH_STAY_DATES_QUERY_PARAM_KEY]: JSON.stringify(searchStayDates),
      });
    },
  });

  const searchOccupanciesQueryParam = computed({
    get() {
      return findSearchOccupanciesQueryParamValue(
        router.currentRoute.value.query,
      );
    },
    set(searchOccupancies) {
      addQueryParams({
        [SEARCH_OCCUPANCIES_QUERY_PARAM_KEY]: JSON.stringify(searchOccupancies),
      });
    },
  });

  const bookingItineraryQueryParam = computed({
    get() {
      return findBookingItineraryQueryParamValue(
        router.currentRoute.value.query,
      );
    },
    set(bookingItinerary) {
      addQueryParams({
        [BOOKING_ITINERARY_QUERY_PARAM_KEY]: JSON.stringify(bookingItinerary),
      });
    },
  });

  const bookingSourceQueryParam = computed({
    get() {
      return findBookingSourceQueryParamValue(router.currentRoute.value.query);
    },
    set(bookingSource) {
      addQueryParams({
        [BOOKING_SOURCE_QUERY_PARAM_KEY]: JSON.stringify(bookingSource),
      });
    },
  });

  const localeQueryParam = computed({
    get() {
      return findLocaleQueryParamValue(router.currentRoute.value.query);
    },
    set(locale) {
      addQueryParams({
        [LOCALE_QUERY_PARAM_KEY]: locale,
      });
    },
  });

  const elementsQueryParam = computed(() =>
    findElementsQueryParamValue(router.currentRoute.value.query),
  );

  const abandonedCartRecoveryRequestQueryParam = computed({
    get() {
      return findAbandonedCartRecoveryRequestQueryParamValue(
        router.currentRoute.value.query,
      );
    },
    set(abandonedCartRecoveryRequest) {
      addQueryParams({
        [ABANDONED_CART_RECOVERY_REQUEST_QUERY_PARAM_KEY]: JSON.stringify(
          abandonedCartRecoveryRequest,
        ),
      });
    },
  });

  return {
    addQueryParams,
    searchStayDatesQueryParam,
    searchOccupanciesQueryParam,
    bookingItineraryQueryParam,
    bookingSourceQueryParam,
    localeQueryParam,
    elementsQueryParam,
    abandonedCartRecoveryRequestQueryParam,
  };
};
