<template>
  <Transition
    leave-active-class="transition-opacity duration-300"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <BookingConfirmationEditBookingDetails
      v-if="isOpen"
      @close="isOpen = false"
    />
  </Transition>
</template>

<script setup lang="ts">
import { useIntervalFn } from '@vueuse/core';
import { ref, watchEffect } from 'vue';
import {
  canGuestModifyBooking,
  isBookingCurrentlyWithinGuestModificationTimeLimit,
} from '@/booking/booking.utilities';
import { useBookingConfirmationStore } from '@/booking-confirmation-page/booking-confirmation.store';
import BookingConfirmationEditBookingDetails from '@/booking-confirmation-page/edit-booking-details/BookingConfirmationEditBookingDetails.vue';

const bookingConfirmationStore = useBookingConfirmationStore();

const isOpen = ref(canGuestModifyBooking(bookingConfirmationStore.booking));

const closeIfBookingNotCurrentlyWithinGuestModificationTimeLimit = () => {
  if (
    !isBookingCurrentlyWithinGuestModificationTimeLimit(
      bookingConfirmationStore.booking,
    )
  ) {
    isOpen.value = false;
  }
};

const { pause, resume } = useIntervalFn(
  closeIfBookingNotCurrentlyWithinGuestModificationTimeLimit,
  30000, // 30 seconds
  { immediate: false },
);

watchEffect(() => {
  if (isOpen.value) {
    resume();
  } else {
    pause();
  }
});
</script>
