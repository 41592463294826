import type {
  MonetaryPromocode,
  MonetaryPromocodeDTO,
} from '@/property/promocode/monetary-promocode/monetary-promocode';
import { PromocodeType } from '@/property/promocode/promocode';

export const mapMonetaryPromocodeFromDTO = (
  promocodeDTO: MonetaryPromocodeDTO,
): MonetaryPromocode => {
  return {
    promocodeType: PromocodeType.Monetary,
    promocodeText: promocodeDTO.promocodeText,
    description: promocodeDTO.description,
    amount: Number(promocodeDTO.amount),
    fromDate: promocodeDTO.fromDate,
    toDate: promocodeDTO.toDate,
    minimumStayLengthDays: promocodeDTO.minimumStayLengthDays,
  };
};
