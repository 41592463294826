<template>
  <div v-if="discountSummaryText">
    {{ discountSummaryText }}
  </div>

  <div v-if="promocode.description" translate="yes" class="break-anywhere">
    {{ promocode.description }}
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { Promocode } from '@/property/promocode/promocode';
import { usePromocodeDiscountSummary } from '@/property/promocode/promocode.composable';
import { useSearchStore } from '@/search/search.store';

const props = defineProps<{ promocode: Promocode }>();

const searchStore = useSearchStore();

const { discountSummaryText } = usePromocodeDiscountSummary(
  computed(() => props.promocode),
  computed(() => searchStore.activeProperty),
);
</script>
