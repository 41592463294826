import type {
  BeforeArrivalDepositRefundPolicy,
  BeforeArrivalDepositRefundPolicyDTO,
} from '@/property/booking-policy/deposit-policy/refund-policy/before-arrival/before-arrival-deposit-refund-policy';
import type {
  ByCancellationPolicyDepositRefundPolicy,
  ByCancellationPolicyDepositRefundPolicyDTO,
} from '@/property/booking-policy/deposit-policy/refund-policy/by-cancellation-policy/by-cancellation-policy-deposit-refund-policy';

export enum DepositRefundPolicyType {
  ByCancellationPolicy = 'by_cancellation_policy',
  BeforeArrival = 'before_arrival',
}

export type DepositRefundPolicy =
  | BeforeArrivalDepositRefundPolicy
  | ByCancellationPolicyDepositRefundPolicy;

export type DepositRefundPolicyDTO =
  | BeforeArrivalDepositRefundPolicyDTO
  | ByCancellationPolicyDepositRefundPolicyDTO;
