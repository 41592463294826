<template>
  <div
    ref="appMiniImageCarousel"
    class="isolate overflow-hidden rounded-lg"
    :style="{
      height: `${thumbnailHeight}px`,
    }"
  >
    <Swiper
      v-if="!isLoading && isIntersecting"
      class="swiper-wrapper"
      :space-between="10"
      :pagination="{
        clickable: true,
        dynamicBullets: true,
      }"
      :loop="hasMoreThanOneImage"
      :modules="modules"
      @slide-change="
        (swiper: SwiperType) => imageCarousel.onSlideChange(swiper.realIndex)
      "
    >
      <SwiperSlide v-if="images.length === 0" class="swiper-no-swiping">
        <AppImageCarouselImageError />
      </SwiperSlide>

      <SwiperSlide
        v-for="(image, index) in images"
        v-else
        :key="image.id"
        :class="{ 'swiper-no-swiping': !isClickable }"
      >
        <button
          :aria-label="$t('unitTypeNameImages', { imageLabel })"
          class="h-full w-full rounded-lg focus-visible:overflow-hidden focus-visible:border-2 focus-visible:border-slate-800 focus-visible:outline-none"
          :class="{ 'pointer-events-none': !isClickable }"
          @click="$emit('open-carousel', image.id)"
        >
          <AppMiniImageCarouselImage
            :data-test="`AppMiniImageCarouselSlide-${image.id}`"
            :url="image.url"
            :width="thumbnailWidth"
            :should-load-placeholder-image="
              imageCarousel.shouldLoadPlaceholderImage(index, image.id)
            "
            :should-load-full-scale-image="
              imageCarousel.shouldLoadFullScaleImage(index, image.id)
            "
          />
        </button>
      </SwiperSlide>
      <div v-if="hasMoreThanOneImage">
        <AppImageCarouselNavigationButton
          class="swiper-navigation-wrapper"
          direction="previous"
          is-small
        />
        <AppImageCarouselNavigationButton
          class="swiper-navigation-wrapper"
          direction="next"
          is-small
        />
      </div>
    </Swiper>
    <AppSkeletonBox
      v-else
      class="w-full"
      :style="{
        height: `${thumbnailHeight}px`,
      }"
    />
  </div>
</template>
<script setup lang="ts">
import { useBreakpoints, useIntersectionObserver } from '@vueuse/core';
import type SwiperType from 'swiper';
import { Navigation, Pagination, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { computed, ref } from 'vue';
import type { Image } from '@/image/image';
import AppImageCarouselImageError from '@/ui/app-image-carousel/AppImageCarouselImageError.vue';
import AppImageCarouselNavigationButton from '@/ui/app-image-carousel/AppImageCarouselNavigationButton.vue';
import AppMiniImageCarouselImage from '@/ui/app-image-carousel/AppMiniImageCarouselImage.vue';
import { useImageCarousel } from '@/ui/app-image-carousel/image-carousel.composable';
import AppSkeletonBox from '@/ui/app-skeleton-box/AppSkeletonBox.vue';

const modules = [Navigation, Pagination, A11y];

const THUMBNAIL_SM = 'thumbnail_sm';
const THUMBNAIL_MD = 'thumbnail_md';

/**
 * The breakpoints for the thumbnail images.
 * These are different from default tailwind breakpoints.
 * Values are in pixels.
 */
const THUMBNAIL_BREAKPOINTS = {
  [THUMBNAIL_SM]: 460,
  [THUMBNAIL_MD]: 768,
};

const THUMBNAIL_SM_HEIGHT = 180;
const THUMBNAIL_MD_HEIGHT = 280;

const THUMBNAIL_SM_WIDTH = 420;
const THUMBNAIL_MD_WIDTH = 620;

defineEmits(['open-carousel']);

const props = withDefaults(
  defineProps<{
    images: Image[];
    isLoading: boolean;
    imageLabel: string;
    isClickable?: boolean;
  }>(),
  {
    isClickable: true,
  },
);

const imageCarousel = useImageCarousel(props.images);

const isIntersecting = ref(false);
const appMiniImageCarousel = ref<HTMLDivElement>();

const thumbnailHeight = computed(() =>
  betweenSmallAndMediumBreakpoint.value
    ? THUMBNAIL_MD_HEIGHT
    : THUMBNAIL_SM_HEIGHT,
);

const thumbnailWidth = computed(() =>
  betweenSmallAndMediumBreakpoint.value
    ? THUMBNAIL_MD_WIDTH
    : THUMBNAIL_SM_WIDTH,
);

const betweenSmallAndMediumBreakpoint = useBreakpoints(
  THUMBNAIL_BREAKPOINTS,
).between(THUMBNAIL_SM, THUMBNAIL_MD);

const hasMoreThanOneImage = computed(() => props.images.length > 1);

useIntersectionObserver(
  appMiniImageCarousel,
  (entries: IntersectionObserverEntry[]) => {
    if (entries[0]?.isIntersecting) {
      isIntersecting.value = true;
    }
  },
  { rootMargin: '100px' },
);
</script>

<style scoped>
:deep(.swiper-pagination) {
  padding: 8px 0;
}

:deep(.swiper-pagination-bullet) {
  opacity: 0.6;
  background-color: #ffffff;
}

:deep(.swiper-pagination-bullet:focus-visible) {
  outline-style: solid;
  outline-width: 2px;
  outline-color: #1e293b;
}

:deep(.swiper-pagination-bullet-active-main) {
  opacity: 1;
}

.swiper-navigation-wrapper {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.swiper-wrapper:hover .swiper-navigation-wrapper {
  opacity: 1;
}
</style>
