<template>
  <div>
    <p class="mb-1 font-semibold capitalize">{{ labelText }}:</p>

    <div class="flex items-center">
      <div class="mr-1">
        <IconUser :size="24" />
      </div>

      <p>
        {{ generateBasicDisplayText(occupancy) }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { IconUser } from '@tabler/icons-vue';
import type { Occupancy } from '@/occupancy/occupancy';
import { useOccupancy } from '@/occupancy/occupancy.composable';

defineProps<{
  labelText: string;
  occupancy: Occupancy;
}>();

const { generateBasicDisplayText } = useOccupancy();
</script>
