import type {
  PackageOfferNightlyWayToSellRate,
  PackageOfferNightlyWayToSellRateDTO,
} from '@/property/offer/package-offer/package-offer-nightly-rate/package-offer-nightly-way-to-sell-rate/package-offer-nightly-way-to-sell-rate';

export const mapPackageOfferNightlyWayToSellRateFromDTO = (
  packageOfferNightlyWayToSellRateDTO: PackageOfferNightlyWayToSellRateDTO,
): PackageOfferNightlyWayToSellRate => ({
  rate: Number(packageOfferNightlyWayToSellRateDTO.rate),
  wayToSellId: packageOfferNightlyWayToSellRateDTO.wayToSellId,
});
