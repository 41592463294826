import type { AdultOnlyOccupancy, Occupancy } from '@/occupancy/occupancy';
import {
  getAgePartitionedChildrenInOccupancy,
  getAgePartitionedNumberOfChildrenInOccupancy,
} from '@/occupancy/occupancy.utilities';
import type { OccupancyLimits } from '@/property/occupancy-limits/occupancy-limits';

/**
 * Returns true if the given occupancy is "equal" to this set of limits. To be
 * "equal", the number of adults and children in the occupancy must match the
 * corresponding maximum occupancy limit.
 */
export const occupancyLimitsAreEqualToOccupancy = (
  {
    maximumNumberOfAdults,
    maximumNumberOfNonInfantChildren,
    maximumNumberOfInfantChildren,
  }: OccupancyLimits,
  occupancy: Occupancy,
): boolean => {
  const { numberOfAdults } = occupancy;
  const { numberOfNonInfantChildren, numberOfInfantChildren } =
    getAgePartitionedNumberOfChildrenInOccupancy(occupancy);

  return (
    maximumNumberOfAdults === numberOfAdults &&
    maximumNumberOfNonInfantChildren === numberOfNonInfantChildren &&
    maximumNumberOfInfantChildren === numberOfInfantChildren
  );
};

/**
 * Returns true if the given Occupancy can fit within this set of limits. To "fit", the
 * following conditions must be met:
 * - The number of adults in the Occupancy must be less than or equal to the maximum
 * number of adults.
 * - The number of non-infant children in the Occupancy must be less than or equal to the
 * maximum number of non-infant children plus any remaining spaces not taken by adults.
 * - The number of infant children in the Occupancy must be less than or equal to the
 * maximum number of infant children plus any remaining spaces not taken by adults or
 * non-infant children.
 */
export const occupancyLimitsCanFitOccupancy = (
  {
    maximumNumberOfAdults,
    maximumNumberOfNonInfantChildren,
    maximumNumberOfInfantChildren,
  }: OccupancyLimits,
  occupancy: Occupancy,
): boolean => {
  const { numberOfAdults } = occupancy;
  const { numberOfNonInfantChildren, numberOfInfantChildren } =
    getAgePartitionedNumberOfChildrenInOccupancy(occupancy);

  const remainingAdultSpaces = maximumNumberOfAdults - numberOfAdults;

  if (remainingAdultSpaces < 0) {
    return false;
  }

  const remainingNonInfantChildSpaces =
    remainingAdultSpaces +
    maximumNumberOfNonInfantChildren -
    numberOfNonInfantChildren;

  if (remainingNonInfantChildSpaces < 0) {
    return false;
  }

  const remainingInfantChildSpaces =
    remainingNonInfantChildSpaces +
    maximumNumberOfInfantChildren -
    numberOfInfantChildren;

  return remainingInfantChildSpaces >= 0;
};

/**
 * "Constrains" the given occupancy to these occupancy limits, retaining
 * as many of the original occupants as possible.
 */
export const constrainOccupancyToOccupancyLimits = (
  {
    maximumNumberOfAdults,
    maximumNumberOfNonInfantChildren,
    maximumNumberOfInfantChildren,
  }: OccupancyLimits,
  occupancy: Occupancy,
): Occupancy => {
  const { nonInfantChildren, infantChildren } =
    getAgePartitionedChildrenInOccupancy(occupancy);

  const constrainedNumberOfAdults = Math.min(
    occupancy.numberOfAdults,
    maximumNumberOfAdults,
  );

  const constrainedNumberOfNonInfantChildren = Math.min(
    nonInfantChildren.length,
    maximumNumberOfNonInfantChildren,
  );

  const constrainedNumberOfInfantChildren = Math.min(
    infantChildren.length,
    maximumNumberOfInfantChildren,
  );

  return {
    numberOfAdults: constrainedNumberOfAdults,
    children: [
      ...nonInfantChildren.slice(0, constrainedNumberOfNonInfantChildren),
      ...infantChildren.slice(0, constrainedNumberOfInfantChildren),
    ],
  };
};

export const convertOccupancyLimitsToAdultOnlyOccupancy = ({
  maximumNumberOfAdults,
}: OccupancyLimits): AdultOnlyOccupancy => ({
  numberOfAdults: maximumNumberOfAdults,
  children: [],
});
