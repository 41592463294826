import type { Ref } from 'vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { ParkingPolicyChargeType } from '@/property/parking-policy/charge/parking-policy-charge';
import type { StreetParkingPolicy } from '@/property/parking-policy/street/street-parking-policy';
import type { Property } from '@/property/property';

export const useStreetParkingPolicyTermsAndConditionsDisplayText = (
  property: Ref<Property>,
) => {
  const { t, n } = useI18n();

  const onSiteParkingPolicy = computed(
    () => property.value.parkingPolicies.onSiteParkingPolicy,
  );

  const streetParkingPolicy = computed(
    () => property.value.parkingPolicies.streetParkingPolicy,
  );

  const nearbyParkingPolicy = computed(
    () => property.value.parkingPolicies.nearbyParkingPolicy,
  );

  const noAvailableStreetParkingDisplayText = computed(() =>
    onSiteParkingPolicy.value && nearbyParkingPolicy.value
      ? ''
      : t('thereIsNoOnStreetParkingAvailable'),
  );

  const generateStreetParkingDisplayText = ({
    charge,
  }: StreetParkingPolicy): string => {
    switch (charge.chargeType) {
      case ParkingPolicyChargeType.Free:
        return t('onStreetParkingIsAvailableAndIsFreeOfCharge');
      case ParkingPolicyChargeType.PerDayMonetary:
        return t('onStreetParkingIsAvailableAtAChargeOfChargePerDay', {
          charge: n(charge.amountPerDay, {
            style: 'currency',
            currency: property.value.currencyCode,
          }),
        });
    }
  };

  return computed(() =>
    streetParkingPolicy.value
      ? generateStreetParkingDisplayText(streetParkingPolicy.value)
      : noAvailableStreetParkingDisplayText.value,
  );
};
