import { useNonMandatoryNonSelectableExtraItineraryItemStore } from '@/booking-itinerary/extra-itinerary/store/non-mandatory-non-selectable-night-extra-itinerary-item.store';
import { useSelectableNightExtraItineraryItemStore } from '@/booking-itinerary/extra-itinerary/store/selectable-night-extra-itinerary-item.store';
import type { NonMandatoryExtra } from '@/property/extra/extra';
import { ExtraChargeType } from '@/property/extra/extra';

export const useExtraItineraryItemStore = (extra: NonMandatoryExtra) =>
  extra.chargeType === ExtraChargeType.SelectNightsOfStay
    ? useSelectableNightExtraItineraryItemStore({
        ...extra,
        chargeType: extra.chargeType,
      })
    : useNonMandatoryNonSelectableExtraItineraryItemStore({
        ...extra,
        chargeType: extra.chargeType,
      });
