import type {
  BookingExtra,
  BookingExtraDTO,
  BookingExtraQuestion,
  BookingExtraQuestionDTO,
} from '@/booking/extra/booking-extra';

export const mapBookingExtraFromDTO = (
  bookingExtraDTO: BookingExtraDTO,
): BookingExtra => ({
  extraName: bookingExtraDTO.extraName,
  itemCharge: Number(bookingExtraDTO.itemCharge),
  quantity: bookingExtraDTO.quantity,
  numberOfNights: bookingExtraDTO.numberOfNights ?? undefined,
  totalCharge: Number(bookingExtraDTO.totalCharge),
  isMandatory: bookingExtraDTO.isMandatory,
  bookingExtraQuestion: bookingExtraDTO.bookingExtraQuestion
    ? mapBookingExtraQuestionFromDTO(bookingExtraDTO.bookingExtraQuestion)
    : undefined,
});

export const mapBookingExtraQuestionFromDTO = (
  bookingExtraQuestionDTO: BookingExtraQuestionDTO,
): BookingExtraQuestion => ({
  question: bookingExtraQuestionDTO.question,
  answer: bookingExtraQuestionDTO.answer ?? undefined,
});
