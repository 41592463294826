import { addHours } from 'date-fns';
import type { BookingSource } from '@/booking-source/booking-source';
import { BookingSourceType } from '@/booking-source/booking-source';
import type { PersistableBookingSource } from '@/booking-source/persistable/persistable-booking-source';

export const createPersistableBookingSource = (
  widgetId: string,
  bookingSource: BookingSource,
): PersistableBookingSource => ({
  expiresAtTimestamp: addHours(
    new Date(),
    getExpiryHoursOfBookingSourceType(bookingSource.type),
  ).getTime(),
  widgetId,
  bookingSource,
});

export const isPersistableBookingSourceValid = (
  persistableBookingSource: PersistableBookingSource,
  activeWidgetId: string,
): boolean =>
  !hasPersistableBookingSourceExpired(persistableBookingSource) &&
  persistableBookingSourceUsesWidgetId(
    persistableBookingSource,
    activeWidgetId,
  );

const getExpiryHoursOfBookingSourceType = (type: BookingSourceType): number => {
  switch (type) {
    case BookingSourceType.GoogleHPA:
    case BookingSourceType.GoogleZero:
    case BookingSourceType.GoogleVR:
      return 24;
    default:
      return 2;
  }
};

const hasPersistableBookingSourceExpired = ({
  expiresAtTimestamp,
}: PersistableBookingSource): boolean => expiresAtTimestamp < Date.now();

const persistableBookingSourceUsesWidgetId = (
  persistableBookingSource: PersistableBookingSource,
  widgetId: string,
): boolean => persistableBookingSource.widgetId === widgetId;
