<template>
  <form novalidate @submit.prevent="$emit('submit')">
    <slot></slot>
  </form>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useProvideForm } from '@/ui/app-form/app-form.composable';

defineEmits(['submit']);

const { errorMessages, validate } = useProvideForm();

const isValid = computed(() => errorMessages.value.length === 0);

defineExpose({ isValid, validate });
</script>
