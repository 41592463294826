<template>
  <button
    :aria-label="$t(direction === 'previous' ? 'previousSlide' : 'nextSlide')"
    class="absolute top-1/2 z-10 hidden -translate-y-1/2 justify-between rounded-full focus-visible:rounded-full focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-white pointer-fine:flex"
    :class="direction === 'previous' ? 'left-0 ml-2' : 'right-0 mr-2'"
    @click="direction === 'previous' ? swiper.slidePrev() : swiper.slideNext()"
  >
    <div
      class="aspect-square rounded-full bg-white opacity-90 shadow-md transition-all hover:opacity-100 hover:shadow-lg active:scale-95"
      :class="isSmall ? 'p-2' : 'p-4'"
    >
      <Component
        :is="direction === 'previous' ? IconChevronLeft : IconChevronRight"
        :size="26"
        class="mx-auto my-0"
      ></Component>
    </div>
  </button>
</template>
<script setup lang="ts">
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-vue';
import { useSwiper } from 'swiper/vue';

const swiper = useSwiper();

defineProps<{
  direction: 'previous' | 'next';
  isSmall?: boolean;
}>();
</script>
