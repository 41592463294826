import type {
  PropertyTaxes,
  PropertyTaxesDTO,
} from '@/property/property-taxes/property-taxes';
import { mapAdditionalTaxFromDTO } from '@/tax/additional/additional-tax.mapper';
import { mapVatFromDTO } from '@/tax/vat/vat.mapper';

export const mapPropertyTaxesFromDTO = (
  propertyTaxesDTO: PropertyTaxesDTO,
): PropertyTaxes => ({
  vat: propertyTaxesDTO.vat ? mapVatFromDTO(propertyTaxesDTO.vat) : undefined,
  cityTaxes: propertyTaxesDTO.cityTaxes.map(mapAdditionalTaxFromDTO),
  serviceCharges: propertyTaxesDTO.serviceCharges.map(mapAdditionalTaxFromDTO),
  otherTaxes: propertyTaxesDTO.otherTaxes.map(mapAdditionalTaxFromDTO),
});
