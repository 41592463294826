import { Locale } from '@/i18n/locales';
import type { LocaleToGrammaticalGender } from '@/i18n/locales/gender/gender';
import { GrammaticalGender } from '@/i18n/locales/gender/gender';
import type {
  KnownUnitType,
  TranslatableUnitTypeName,
} from '@/property/unit/type/unit-type';
import {
  UNIT_TYPE_NAME_APARTMENT,
  UNIT_TYPE_NAME_BED,
  UNIT_TYPE_NAME_BOAT,
  UNIT_TYPE_NAME_CARAVAN,
  UNIT_TYPE_NAME_CHALET,
  UNIT_TYPE_NAME_COTTAGE,
  UNIT_TYPE_NAME_HOUSE,
  UNIT_TYPE_NAME_LODGE,
  UNIT_TYPE_NAME_PITCH,
  UNIT_TYPE_NAME_ROOM,
  UNIT_TYPE_NAME_TEEPEE,
  UNIT_TYPE_NAME_TENT,
  UNIT_TYPE_NAME_UNIT,
  UNIT_TYPE_NAME_VILLA,
  UNIT_TYPE_NAME_YURT,
} from '@/property/unit/type/unit-type';

export const findUnitTypeNameTranslationKeys = (
  translatableUnitTypeName: TranslatableUnitTypeName,
): UnitTypeNameTranslationKeys =>
  unitTypeNameTranslationKeyMapping[translatableUnitTypeName];

export const createKnownUnitTypeFromTranslatableUnitTypeName = (
  translatableUnitTypeName: TranslatableUnitTypeName,
): KnownUnitType => {
  const { basic, count, genders } = findUnitTypeNameTranslationKeys(
    translatableUnitTypeName,
  );

  return {
    isKnown: true,
    name: translatableUnitTypeName,
    basicTranslationKey: basic,
    countTranslationKey: count,
    genders,
  };
};

export const translatableUnitTypeNames = [
  UNIT_TYPE_NAME_ROOM,
  UNIT_TYPE_NAME_UNIT,
  UNIT_TYPE_NAME_BED,
  UNIT_TYPE_NAME_BOAT,
  UNIT_TYPE_NAME_CHALET,
  UNIT_TYPE_NAME_COTTAGE,
  UNIT_TYPE_NAME_PITCH,
  UNIT_TYPE_NAME_CARAVAN,
  UNIT_TYPE_NAME_HOUSE,
  UNIT_TYPE_NAME_LODGE,
  UNIT_TYPE_NAME_APARTMENT,
  UNIT_TYPE_NAME_TENT,
  UNIT_TYPE_NAME_VILLA,
  UNIT_TYPE_NAME_TEEPEE,
  UNIT_TYPE_NAME_YURT,
] as const;

const unitTypeNameTranslationKeyMapping: UnitTypeNameTranslationKeyMapping = {
  [UNIT_TYPE_NAME_ROOM]: {
    basic: 'room',
    count: 'roomCount',
    genders: {
      [Locale.deDE]: GrammaticalGender.Neuter,
      [Locale.esES]: GrammaticalGender.Feminine,
      [Locale.frFR]: GrammaticalGender.Feminine,
      [Locale.itIT]: GrammaticalGender.Feminine,
      [Locale.ptPT]: GrammaticalGender.Masculine,
    },
  },
  [UNIT_TYPE_NAME_UNIT]: {
    basic: 'unit',
    count: 'unitCount',
    genders: {
      [Locale.deDE]: GrammaticalGender.Feminine,
      [Locale.esES]: GrammaticalGender.Feminine,
      [Locale.frFR]: GrammaticalGender.Masculine,
      [Locale.itIT]: GrammaticalGender.Feminine,
      [Locale.ptPT]: GrammaticalGender.Feminine,
    },
  },
  [UNIT_TYPE_NAME_BED]: {
    basic: 'bed',
    count: 'bedCount',
    genders: {
      [Locale.deDE]: GrammaticalGender.Neuter,
      [Locale.esES]: GrammaticalGender.Feminine,
      [Locale.frFR]: GrammaticalGender.Masculine,
      [Locale.itIT]: GrammaticalGender.Masculine,
      [Locale.ptPT]: GrammaticalGender.Feminine,
    },
  },
  [UNIT_TYPE_NAME_BOAT]: {
    basic: 'boat',
    count: 'boatCount',
    genders: {
      [Locale.deDE]: GrammaticalGender.Neuter,
      [Locale.esES]: GrammaticalGender.Masculine,
      [Locale.frFR]: GrammaticalGender.Masculine,
      [Locale.itIT]: GrammaticalGender.Feminine,
      [Locale.ptPT]: GrammaticalGender.Masculine,
    },
  },
  [UNIT_TYPE_NAME_CHALET]: {
    basic: 'chalet',
    count: 'chaletCount',
    genders: {
      [Locale.deDE]: GrammaticalGender.Neuter,
      [Locale.esES]: GrammaticalGender.Masculine,
      [Locale.frFR]: GrammaticalGender.Masculine,
      [Locale.itIT]: GrammaticalGender.Masculine,
      [Locale.ptPT]: GrammaticalGender.Masculine,
    },
  },
  [UNIT_TYPE_NAME_COTTAGE]: {
    basic: 'cottage',
    count: 'cottageCount',
    genders: {
      [Locale.deDE]: GrammaticalGender.Neuter,
      [Locale.esES]: GrammaticalGender.Feminine,
      [Locale.frFR]: GrammaticalGender.Masculine,
      [Locale.itIT]: GrammaticalGender.Masculine,
      [Locale.ptPT]: GrammaticalGender.Feminine,
    },
  },
  [UNIT_TYPE_NAME_PITCH]: {
    basic: 'pitch',
    count: 'pitchCount',
    genders: {
      [Locale.deDE]: GrammaticalGender.Masculine,
      [Locale.esES]: GrammaticalGender.Feminine,
      [Locale.frFR]: GrammaticalGender.Masculine,
      [Locale.itIT]: GrammaticalGender.Feminine,
      [Locale.ptPT]: GrammaticalGender.Feminine,
    },
  },
  [UNIT_TYPE_NAME_CARAVAN]: {
    basic: 'caravan',
    count: 'caravanCount',
    genders: {
      [Locale.deDE]: GrammaticalGender.Masculine,
      [Locale.esES]: GrammaticalGender.Feminine,
      [Locale.frFR]: GrammaticalGender.Feminine,
      [Locale.itIT]: GrammaticalGender.Feminine,
      [Locale.ptPT]: GrammaticalGender.Feminine,
    },
  },
  [UNIT_TYPE_NAME_HOUSE]: {
    basic: 'house',
    count: 'houseCount',
    genders: {
      [Locale.deDE]: GrammaticalGender.Neuter,
      [Locale.esES]: GrammaticalGender.Feminine,
      [Locale.frFR]: GrammaticalGender.Feminine,
      [Locale.itIT]: GrammaticalGender.Feminine,
      [Locale.ptPT]: GrammaticalGender.Feminine,
    },
  },
  [UNIT_TYPE_NAME_LODGE]: {
    basic: 'lodge',
    count: 'lodgeCount',
    genders: {
      [Locale.deDE]: GrammaticalGender.Feminine,
      [Locale.esES]: GrammaticalGender.Feminine,
      [Locale.frFR]: GrammaticalGender.Masculine,
      [Locale.itIT]: GrammaticalGender.Masculine,
      [Locale.ptPT]: GrammaticalGender.Masculine,
    },
  },
  [UNIT_TYPE_NAME_APARTMENT]: {
    basic: 'apartment',
    count: 'apartmentCount',
    genders: {
      [Locale.deDE]: GrammaticalGender.Feminine,
      [Locale.esES]: GrammaticalGender.Masculine,
      [Locale.frFR]: GrammaticalGender.Masculine,
      [Locale.itIT]: GrammaticalGender.Masculine,
      [Locale.ptPT]: GrammaticalGender.Masculine,
    },
  },
  [UNIT_TYPE_NAME_TENT]: {
    basic: 'tent',
    count: 'tentCount',
    genders: {
      [Locale.deDE]: GrammaticalGender.Neuter,
      [Locale.esES]: GrammaticalGender.Feminine,
      [Locale.frFR]: GrammaticalGender.Feminine,
      [Locale.itIT]: GrammaticalGender.Feminine,
      [Locale.ptPT]: GrammaticalGender.Feminine,
    },
  },
  [UNIT_TYPE_NAME_VILLA]: {
    basic: 'villa',
    count: 'villaCount',
    genders: {
      [Locale.deDE]: GrammaticalGender.Feminine,
      [Locale.esES]: GrammaticalGender.Feminine,
      [Locale.frFR]: GrammaticalGender.Feminine,
      [Locale.itIT]: GrammaticalGender.Feminine,
      [Locale.ptPT]: GrammaticalGender.Feminine,
    },
  },
  [UNIT_TYPE_NAME_TEEPEE]: {
    basic: 'teepee',
    count: 'teepeeCount',
    genders: {
      [Locale.deDE]: GrammaticalGender.Neuter,
      [Locale.esES]: GrammaticalGender.Masculine,
      [Locale.frFR]: GrammaticalGender.Masculine,
      [Locale.itIT]: GrammaticalGender.Masculine,
      [Locale.ptPT]: GrammaticalGender.Masculine,
    },
  },
  [UNIT_TYPE_NAME_YURT]: {
    basic: 'yurt',
    count: 'yurtCount',
    genders: {
      [Locale.deDE]: GrammaticalGender.Feminine,
      [Locale.esES]: GrammaticalGender.Feminine,
      [Locale.frFR]: GrammaticalGender.Feminine,
      [Locale.itIT]: GrammaticalGender.Feminine,
      [Locale.ptPT]: GrammaticalGender.Feminine,
    },
  },
};

type UnitTypeNameTranslationKeyMapping = Record<
  TranslatableUnitTypeName,
  UnitTypeNameTranslationKeys
>;

interface UnitTypeNameTranslationKeys {
  basic: string;
  count: string;
  genders: LocaleToGrammaticalGender;
}
