import { compareValuesByArrayPosition } from '@/array/array.utilities';
import type {
  Extra,
  MandatoryExtra,
  NonMandatoryExtra,
} from '@/property/extra/extra';
import { isDateCurrentlyAllowedByLateBookingThresholds } from '@/property/late-booking-threshold/late-booking-thresholds.utilities';
import type { IncludedMeal } from '@/property/meal/included-meal/included-meal';
import type { Meal, MealType } from '@/property/meal/meal';
import type { SupplementalMeal } from '@/property/meal/supplemental-meal/supplemental-meal';
import type { Offer } from '@/property/offer/offer';
import { PaymentGatewayType } from '@/property/payment-gateway/payment-gateway';
import type { Property } from '@/property/property';
import type { Unit } from '@/property/unit/unit';

export const getPropertyUnitIds = ({ units }: Property): number[] =>
  units.map(({ id }) => id);

export const findPropertyUnitById = (
  { units }: Property,
  unitId: number,
): Unit | undefined => units.find(({ id }) => id === unitId);

export const findPropertyOfferById = (
  { offers }: Property,
  offerId: string,
): Offer | undefined => offers.find(({ id }) => id === offerId);

export const findPropertyMealByMealType = (
  { meals }: Property,
  mealType: MealType,
): Meal | undefined => meals.find(({ type }) => type === mealType);

export const findPropertyMealsByMealTypes = (
  property: Property,
  mealTypes: MealType[],
): Meal[] =>
  mealTypes.reduce<Meal[]>((propertyMeals, mealType) => {
    const propertyMeal = findPropertyMealByMealType(property, mealType);

    if (propertyMeal) {
      propertyMeals.push(propertyMeal);
    }

    return propertyMeals;
  }, []);

export const findPropertyIncludedMeals = ({
  meals,
}: Property): IncludedMeal[] =>
  meals.filter((meal): meal is IncludedMeal => meal.isIncluded);

export const findPropertySupplementalMeals = ({
  meals,
}: Property): SupplementalMeal[] =>
  meals.filter((meal): meal is SupplementalMeal => !meal.isIncluded);

export const findPropertyExtraById = (
  { extras }: Property,
  extraId: number,
): Extra | undefined => extras.find(({ id }) => id === extraId);

export const findPropertyExtraByIdOrFail = (
  property: Property,
  extraId: number,
): Extra => {
  const extra = findPropertyExtraById(property, extraId);

  if (extra === undefined) {
    throw new Error(`Extra ${extraId} not found on property ${property.id}`);
  }

  return extra;
};

export const findPropertyNonMandatoryExtraByIdOrFail = (
  property: Property,
  extraId: number,
): NonMandatoryExtra => {
  const extra = findPropertyExtraByIdOrFail(property, extraId);

  if (extra.isMandatory) {
    throw new Error(
      `Extra ${extraId} found on property ${property.id} is mandatory`,
    );
  }

  return extra;
};

export const findPropertyMandatoryExtraByIdOrFail = (
  property: Property,
  extraId: number,
): MandatoryExtra => {
  const extra = findPropertyExtraByIdOrFail(property, extraId);

  if (!extra.isMandatory) {
    throw new Error(
      `Extra ${extraId} found on property ${property.id} is not mandatory`,
    );
  }

  return extra;
};

export const findPropertyMainImage = ({ images, mainImageId }: Property) =>
  images.find(({ id }) => id === mainImageId);

export const getPropertyNonMandatoryExtras = (
  property: Property,
): NonMandatoryExtra[] =>
  property.extras.filter(
    (extra): extra is NonMandatoryExtra => !extra.isMandatory,
  );

export const comparePropertyExtrasByPriority = (
  { priorityOrderedExtraIds }: Property,
  extraA: Extra,
  extraB: Extra,
): number =>
  compareValuesByArrayPosition(extraA.id, extraB.id, priorityOrderedExtraIds);

export const propertyHasPaymentGateway = ({
  paymentGateway: { type },
}: Property): boolean => type !== PaymentGatewayType.None;

export const isDateCurrentlyAllowedByPropertyLateBookingThresholds = (
  date: string,
  property: Property,
): boolean =>
  isDateCurrentlyAllowedByLateBookingThresholds(
    date,
    property.timezone,
    property.lateBookingThresholds,
  );
