<template>
  <div class="flex justify-between">
    <AppCheckbox
      v-if="isUpdatingSelectedMeals"
      v-model="updatedSelectedMealTypes"
      :input-value="meal.type"
      :label-id="`${unitItineraryItem.id}_${meal.type}`"
      :label-text="meal.name"
    />

    <div v-else class="break-anywhere">{{ meal.name }}</div>

    <BookingSummaryBodyUnitItemMealRate :meal="meal" />
  </div>
</template>
<script setup lang="ts">
import { useBookingSummaryMealsStore } from '@/booking-summary/body/unit-item/meals/booking-summary-meals.store';
import BookingSummaryBodyUnitItemMealRate from '@/booking-summary/body/unit-item/meals/BookingSummaryBodyUnitItemMealRate.vue';
import type { SupplementalMeal } from '@/property/meal/supplemental-meal/supplemental-meal';
import AppCheckbox from '@/ui/app-checkbox/AppCheckbox.vue';

defineProps<{ meal: SupplementalMeal }>();

const { unitItineraryItem, isUpdatingSelectedMeals, updatedSelectedMealTypes } =
  useBookingSummaryMealsStore();
</script>
