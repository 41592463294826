import { Decimal } from 'decimal.js';
import i18n from '@/i18n';
import type { Meal } from '@/property/meal/meal';
import { MealType } from '@/property/meal/meal';
import type { DiscountOffer } from '@/property/offer/discount-offer/discount-offer';
import { DiscountOfferType } from '@/property/offer/discount-offer/discount-offer';
import {
  findDiscountOfferMealAdjustmentByMealType,
  findDiscountOfferUnitAdjustmentByUnitIdOrFail,
} from '@/property/offer/discount-offer/discount-offer.utilities';
import type { Property } from '@/property/property';
import type { Unit } from '@/property/unit/unit';

export const useDiscountOffer = (offer: DiscountOffer) => {
  const { t, n } = i18n.global;

  /**
   * The discount offer summary text should include the nightly, unit discount. This should
   * be followed by any nightly, per-person, meal discounts.
   */
  const generateDiscountOfferSummary = (
    unit: Unit,
    meals: Meal[],
    property: Property,
  ): string => {
    const allDiscountedRateAmountText: string[] = [];

    const unitAdjustment = findDiscountOfferUnitAdjustmentByUnitIdOrFail(
      offer,
      unit.id,
    );

    allDiscountedRateAmountText.push(
      getDiscountedRateAmountText(
        offer.discountType,
        'nightly',
        unitAdjustment.adjustmentAmount,
        property,
      ),
    );

    for (const meal of meals) {
      const mealAdjustment = findDiscountOfferMealAdjustmentByMealType(
        offer,
        meal.type,
      );

      if (!mealAdjustment) {
        continue;
      }

      allDiscountedRateAmountText.push(
        getDiscountedRateAmountText(
          offer.discountType,
          meal.type,
          mealAdjustment.adjustmentAmount,
          property,
        ),
      );
    }

    return allDiscountedRateAmountText.join(', ');
  };

  const getDiscountedRateAmountText = (
    discountType: DiscountOfferType,
    discountTarget: MealType | 'nightly',
    adjustmentAmount: number,
    property: Property,
  ): string =>
    t(getDiscountTranslationKeyForDiscountTarget(discountTarget), {
      discountAmount:
        discountType === DiscountOfferType.Monetary
          ? n(adjustmentAmount, {
              style: 'currency',
              currency: property.currencyCode,
            })
          : n(
              new Decimal(adjustmentAmount).dividedBy(100).toNumber(),
              'percent',
            ),
    });

  const getDiscountTranslationKeyForDiscountTarget = (
    discountTarget: MealType | 'nightly',
  ): string => {
    switch (discountTarget) {
      case MealType.Breakfast:
        return 'discountOffBreakfastRate';
      case MealType.Lunch:
        return 'discountOffLunchRate';
      case MealType.Dinner:
        return 'discountOffDinnerRate';
      case 'nightly':
        return 'discountOffNightlyRate';
    }
  };

  return { generateDiscountOfferSummary };
};
