import { mapPropertyThemeAccentColorFromDTO } from '@/property/property-theme/accent-color/property-theme-accent-color.mapper';
import { mapPropertyThemeHeaderFromDTO } from '@/property/property-theme/header/property-theme-header.mapper';
import { mapPropertyThemeLogoFromDTO } from '@/property/property-theme/logo/property-theme-logo.mapper';
import { mapPropertyThemeNameFromDTO } from '@/property/property-theme/name/property-theme-name.mapper';
import type {
  PropertyTheme,
  PropertyThemeDTO,
} from '@/property/property-theme/property-theme';

export const mapPropertyThemeFromDTO = (
  propertyThemeDTO: PropertyThemeDTO,
): PropertyTheme => ({
  header: propertyThemeDTO.header
    ? mapPropertyThemeHeaderFromDTO(propertyThemeDTO.header)
    : undefined,
  logo: propertyThemeDTO.logo
    ? mapPropertyThemeLogoFromDTO(propertyThemeDTO.logo)
    : undefined,
  accentColor: mapPropertyThemeAccentColorFromDTO(
    propertyThemeDTO.accentColour,
  ),
  name: propertyThemeDTO.name
    ? mapPropertyThemeNameFromDTO(propertyThemeDTO.name)
    : undefined,
});
