import { useBreakpoints, breakpointsTailwind } from '@vueuse/core';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useBookingSummaryStore = defineStore('booking-summary', () => {
  const breakpoints = useBreakpoints(breakpointsTailwind);
  const showModalVersion = breakpoints.smaller('lg');

  const isModalOpen = ref(false);

  const isLoading = ref(false);

  return {
    showModalVersion,
    isModalOpen,
    isLoading,
  };
});
