import type { Ref } from 'vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import type { Property } from '@/property/property';
import { useUnitType } from '@/property/unit/type/unit-type.composable';
import type { AdditionalTaxRate } from '@/tax/additional/rate/additional-tax-rate';
import { AdditionalTaxRateFrequencyType } from '@/tax/additional/rate/additional-tax-rate';
import { AdditionalTaxRateBasisType } from '@/tax/additional/rate/basis/additional-tax-rate-basis';
import { AdditionalTaxRateChargeType } from '@/tax/additional/rate/charge/additional-tax-rate-charge';

export const useAdditionalTaxRateDisplayText = (
  { charge, basis, frequencyType }: AdditionalTaxRate,
  property: Ref<Property>,
) => {
  const { t, n } = useI18n();

  const { unitTypeName } = useUnitType(computed(() => property.value.unitType));

  const chargeDisplayText = computed(() =>
    charge.type === AdditionalTaxRateChargeType.Monetary
      ? n(charge.amount, {
          style: 'currency',
          currency: property.value.currencyCode,
        })
      : n(charge.percentage, 'percent'),
  );

  const basisDisplayText = computed(() => {
    switch (basis.type) {
      case AdditionalTaxRateBasisType.Booking:
        return t('booking');
      case AdditionalTaxRateBasisType.Guest:
        return t('guest');
      default:
        return unitTypeName.value;
    }
  });

  const frequencyDisplayText = t(
    frequencyType === AdditionalTaxRateFrequencyType.Nightly ? 'night' : 'stay',
  );

  return computed(() =>
    t('chargePerBasisPerFrequency', {
      charge: chargeDisplayText.value,
      basis: basisDisplayText.value,
      frequency: frequencyDisplayText,
    }),
  );
};
