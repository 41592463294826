<template>
  <UnitSelectionAvailableUnitList
    v-if="searchStore.activePropertyOverStay.isAvailable"
  />

  <UnitSelectionNoAvailability
    v-else
    :show-property-name="preferAvailableAdditionalProperties"
  />

  <template
    v-if="
      preferAvailableAdditionalProperties &&
      searchStore.hasAvailableAdditionalProperties
    "
  >
    <UnitSelectionAdditionalPropertiesSeparator type="available" />

    <UnitSelectionAdditionalPropertiesLists type="available" />
  </template>

  <UnitSelectionUnavailableUnitList
    :show-property-name="preferAvailableAdditionalProperties"
  />

  <template v-if="preferAvailableAdditionalProperties">
    <template v-if="searchStore.hasUnavailableAdditionalProperties">
      <UnitSelectionAdditionalPropertiesSeparator type="unavailable" />

      <UnitSelectionAdditionalPropertiesLists type="unavailable" />
    </template>
  </template>

  <template v-else-if="searchStore.hasAdditionalProperties">
    <UnitSelectionAdditionalPropertiesSeparator />

    <UnitSelectionAdditionalPropertiesLists />
  </template>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useSearchStore } from '@/search/search.store';
import UnitSelectionAdditionalPropertiesLists from '@/unit-selection-page/additional-properties/lists/UnitSelectionAdditionalPropertiesLists.vue';
import UnitSelectionAdditionalPropertiesSeparator from '@/unit-selection-page/additional-properties/separator/UnitSelectionAdditionalPropertiesSeparator.vue';
import UnitSelectionNoAvailability from '@/unit-selection-page/no-availability/UnitSelectionNoAvailability.vue';
import UnitSelectionAvailableUnitList from '@/unit-selection-page/unit/lists/available/UnitSelectionAvailableUnitList.vue';
import UnitSelectionUnavailableUnitList from '@/unit-selection-page/unit/lists/unavailable/UnitSelectionUnavailableUnitList.vue';
import { UnavailableSearchResultsPreference } from '@/widget/widget';

const searchStore = useSearchStore();

const preferAvailableAdditionalProperties = computed(
  () =>
    searchStore.widget.unavailableSearchResultsPreference ===
    UnavailableSearchResultsPreference.AvailableAdditionalProperties,
);
</script>
