<template>
  <source
    v-for="(width, index) in WIDTHS"
    :key="width"
    :media="
      index !== WIDTHS.length
        ? `(max-width: ${width}px)`
        : `(min-width: ${WIDTHS[-1]}px)`
    "
    :srcset="generateImageUrl(width, isPlaceholder)"
  />
</template>

<script setup lang="ts">
const WIDTHS = [500, 1000, 2000];

defineProps<{
  generateImageUrl: (width: number, isPlaceholder?: boolean) => string;
  isPlaceholder?: boolean;
}>();
</script>
