<template>
  <AppModal
    :is-open="isOpen"
    :sm-placement="smPlacement"
    @close="$emit('close')"
  >
    <AppModalDialog
      class="relative md:w-auto"
      :add-body-padding="false"
      @close="$emit('close')"
    >
      <template v-if="isSmallerThanMd" #header>
        <AvailabilityCalendarHeader />
      </template>

      <AvailabilityCalendarContainer
        :check-in-date="checkInDate"
        :check-out-date="checkOutDate"
        :auto-select-stay-dates="props.autoSelectStayDates"
      />

      <template #footer>
        <AvailabilityCalendarFooter
          :icon="icon"
          :button-text="buttonText"
          @submit="$emit('submit')"
        />
      </template>
    </AppModalDialog>
  </AppModal>
</template>

<script setup lang="ts">
import type { Icon } from '@tabler/icons-vue';
import { useBreakpoints, breakpointsTailwind } from '@vueuse/core';
import AvailabilityCalendarContainer from '@/availability-calendar/AvailabilityCalendarContainer.vue';
import AvailabilityCalendarFooter from '@/availability-calendar/AvailabilityCalendarFooter.vue';
import AvailabilityCalendarHeader from '@/availability-calendar/AvailabilityCalendarHeader.vue';
import AppModal from '@/ui/app-modal/AppModal.vue';
import AppModalDialog from '@/ui/app-modal/AppModalDialog.vue';

type Placement = 'top' | 'middle';

const props = withDefaults(
  defineProps<{
    isOpen: boolean;
    checkInDate: string;
    checkOutDate: string;
    autoSelectStayDates?: boolean;
    smPlacement?: Placement;
    buttonText: string;
    icon?: Icon | null | undefined;
  }>(),
  {
    smPlacement: 'top',
    autoSelectStayDates: true,
    icon: null,
  },
);

defineEmits(['submit', 'close']);

const breakpoints = useBreakpoints(breakpointsTailwind);

const isSmallerThanMd = breakpoints.smaller('md');
</script>
