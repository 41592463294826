import { Decimal } from 'decimal.js';
import type { DiscountOfferAdjustment } from '@/property/offer/discount-offer/adjustment/discount-offer-adjustment';
import { DiscountOfferType } from '@/property/offer/discount-offer/discount-offer';

export const applyDiscountOfferAdjustmentToValue = (
  discountOfferAdjustment: DiscountOfferAdjustment,
  value: number,
  discountType: DiscountOfferType,
): number =>
  Decimal.max(
    0,
    applyDiscountOfferAdjustmentToDecimalValue(
      discountOfferAdjustment,
      new Decimal(value),
      discountType,
    ),
  ).toNumber();

const applyDiscountOfferAdjustmentToDecimalValue = (
  { adjustmentAmount }: DiscountOfferAdjustment,
  decimalValue: Decimal,
  discountType: DiscountOfferType,
): Decimal => {
  switch (discountType) {
    case DiscountOfferType.Monetary:
      return decimalValue.minus(adjustmentAmount);
    case DiscountOfferType.Percentage:
      return decimalValue
        .times(Decimal.sub(100, adjustmentAmount).dividedBy(100))
        .toDecimalPlaces(2);
  }
};
