import kyInstance from 'ky';

export const ky: typeof kyInstance = kyInstance.create({
  prefixUrl: `${import.meta.env.VITE_FREETOBOOK_ORIGIN}/booking-pages`,
  retry: 1,
  timeout: 30000,
});

export const eventTrackingKy: typeof kyInstance = kyInstance.create({
  prefixUrl: `${import.meta.env.VITE_EVENT_TRACKING_BASE_URL}/`,
  retry: 1,
});
