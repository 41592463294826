import type { BookingCreateProcessingError } from '@/booking/create/error/processing/booking-create-processing-error';
import { BookingCreateProcessingErrorType } from '@/booking/create/error/processing/booking-create-processing-error';

export const shouldBookingCreateProcessingErrorTriggerAvailabilityError = ({
  type,
}: BookingCreateProcessingError): boolean =>
  [
    BookingCreateProcessingErrorType.PropertyUnavailable,
    BookingCreateProcessingErrorType.UnitUnavailable,
    BookingCreateProcessingErrorType.RateMismatch,
  ].includes(type);
