import type { SearchParamsOption } from 'ky';
import {
  type PropertyAvailabilityDTO,
  type PropertyAvailability,
} from '@/availability/property-availability/property-availability';
import {
  mapPropertyAvailabilitiesFromDTOs,
  mapPropertyAvailabilityFromDTO,
} from '@/availability/property-availability/property-availability.mapper';
import { ky } from '@/ky';

export const fetchPropertyAvailabilities = async (
  widgetId: string,
  options: { fromDate: string | undefined; toDate: string | undefined },
): Promise<PropertyAvailability[]> => {
  const searchParams: SearchParamsOption = {};

  if (options.fromDate && options.toDate) {
    searchParams['from_date'] = options.fromDate;
    searchParams['to_date'] = options.toDate;
  }

  const propertyAvailabilityDTOs = await ky
    .get(`widgets/${widgetId}/properties/availability`, {
      searchParams,
    })
    .json<PropertyAvailabilityDTO[]>();

  return mapPropertyAvailabilitiesFromDTOs(propertyAvailabilityDTOs);
};

export const fetchPropertyAvailability = async (
  propertyId: number,
  options: { fromDate: string; toDate: string },
): Promise<PropertyAvailability> => {
  const searchParams: SearchParamsOption = {
    from_date: options.fromDate,
    to_date: options.toDate,
  };

  const propertyAvailabilityDTO = await ky
    .get(`property/${propertyId}/availability`, {
      searchParams,
    })
    .json<PropertyAvailabilityDTO>();

  return mapPropertyAvailabilityFromDTO(propertyAvailabilityDTO);
};
