import type {
  InternalMessageEvent,
  InternalMessageEventData,
} from '@/message-event/internal/internal-message-event';
import { isMessageEventFromOrigin } from '@/message-event/message-event.utilities';

export const isInternalMessageEvent = (
  event: MessageEvent,
): event is InternalMessageEvent =>
  isMessageEventFromOrigin(event, location.origin);

export const sendInternalPostMessageEvent = (
  window: Window,
  messageData: InternalMessageEventData,
): void => {
  window.postMessage(messageData);
};
