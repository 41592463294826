<template>
  <div class="fixed -z-10 h-full w-full bg-accent-color">
    <VanityImage
      v-if="searchStore.activeProperty.propertyVanity"
      :background-image="
        searchStore.activeProperty.propertyVanity.backgroundImage
      "
    />
  </div>
</template>

<script setup lang="ts">
import { useSearchStore } from '@/search/search.store';
import VanityImage from '@/vanity-page/image/VanityImage.vue';

const searchStore = useSearchStore();
</script>
