import { defineStore } from 'pinia';
import type { ComputedRef, Ref, WritableComputedRef } from 'vue';
import { computed, ref } from 'vue';
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import type { SelectableNightExtra } from '@/property/extra/extra';
import { calculateTotalRunningRateForSelectableNightExtra } from '@/property/extra/extra.utilities';
import { getStayLengthFromStayDates } from '@/stay-dates/stay-dates.utilities';

export const useSelectableNightExtraItineraryItemStore = (
  extra: SelectableNightExtra,
) =>
  defineStore(`selectable-extra-itinerary-item-${extra.id}`, () => {
    const bookingItineraryStore = useBookingItineraryStore();

    const quantity = ref(0);

    const numberOfNights: Ref<number | undefined> = ref();

    const numberOfNightsApplied: WritableComputedRef<number> = computed({
      get() {
        return (
          numberOfNights.value ??
          getStayLengthFromStayDates(bookingItineraryStore.stayDates!)
        );
      },
      set(newNumberOfNights) {
        numberOfNights.value = newNumberOfNights;
      },
    });

    const answer = ref<string>();

    const totalRate: ComputedRef<number> = computed(() =>
      calculateTotalRunningRateForSelectableNightExtra(
        { ...extra, chargeType: extra.chargeType },
        quantity.value,
        numberOfNightsApplied.value,
      ),
    );

    return {
      extra,
      quantity,
      numberOfNightsApplied,
      answer,
      totalRate,
    };
  })();
