<template>
  <AppFormValidatedInput
    v-slot="{ isValid }"
    :value="guestStore.administrativeArea"
    :validation-rules="[isRequiredRule($t('pleaseSelectAState'))]"
  >
    <GuestDetailsFormAmericanStateInput :is-valid="isValid" />
  </AppFormValidatedInput>
</template>

<script setup lang="ts">
import { useGuestStore } from '@/guest/guest.store';
import GuestDetailsFormAmericanStateInput from '@/guest-details-page/form/american-state/GuestDetailsFormAmericanStateInput.vue';
import { isRequiredRule } from '@/ui/app-form/app-form-input-validation-rules.utilities';
import AppFormValidatedInput from '@/ui/app-form/AppFormValidatedInput.vue';

const guestStore = useGuestStore();
</script>
