import { mapDepositPolicyChargeFromDTO } from '@/property/booking-policy/deposit-policy/charge/deposit-policy-charge.mapper';
import { DepositPolicyType } from '@/property/booking-policy/deposit-policy/deposit-policy';
import type {
  MandatoryDepositPolicy,
  MandatoryDepositPolicyDTO,
} from '@/property/booking-policy/deposit-policy/mandatory/mandatory-deposit-policy';
import { mapDepositRefundPolicyFromDTO } from '@/property/booking-policy/deposit-policy/refund-policy/deposit-refund-policy.mapper';

export const mapMandatoryDepositPolicyFromDTO = (
  mandatoryDepositPolicyDTO: MandatoryDepositPolicyDTO,
): MandatoryDepositPolicy => ({
  policyType: DepositPolicyType.Mandatory,
  charge: mapDepositPolicyChargeFromDTO(mandatoryDepositPolicyDTO.charge),
  refundPolicy: mandatoryDepositPolicyDTO.refundPolicy
    ? mapDepositRefundPolicyFromDTO(mandatoryDepositPolicyDTO.refundPolicy)
    : undefined,
});
