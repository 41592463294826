import type {
  DiscountOfferMealAdjustment,
  DiscountOfferMealAdjustmentDTO,
} from '@/property/offer/discount-offer/adjustment/meal-adjustment/discount-offer-meal-adjustment';
import type {
  DiscountOfferUnitAdjustment,
  DiscountOfferUnitAdjustmentDTO,
} from '@/property/offer/discount-offer/adjustment/unit-adjustment/discount-offer-unit-adjustment';
import type {
  BaseOffer,
  RefundableOffer,
  DescribableOffer,
  StayLengthBoundableOffer,
  AdvanceBookingLengthBoundableOffer,
  WayToSellApplicableOffer,
  OfferType,
} from '@/property/offer/offer';

export enum DiscountOfferType {
  Percentage = 'percentage',
  Monetary = 'monetary',
}

export interface DiscountOffer
  extends BaseOffer,
    DescribableOffer,
    StayLengthBoundableOffer,
    AdvanceBookingLengthBoundableOffer,
    WayToSellApplicableOffer,
    RefundableOffer {
  readonly offerType: OfferType.Discount;
  discountType: DiscountOfferType;
  unitAdjustments: DiscountOfferUnitAdjustment[];
  mealAdjustments: DiscountOfferMealAdjustment[];
}

export interface DiscountOfferDTO
  extends Omit<DiscountOffer, 'unitAdjustments' | 'mealAdjustments'> {
  unitAdjustments: DiscountOfferUnitAdjustmentDTO[];
  mealAdjustments: DiscountOfferMealAdjustmentDTO[];
}
