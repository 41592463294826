import { computed } from 'vue';
import i18n from '@/i18n';
import { PaymentMethodType } from '@/payment-method/payment-method';

export const usePaymentMethodName = (paymentMethodType: PaymentMethodType) => {
  const { t } = i18n.global;

  const getPaymentMethodName = (): string => {
    switch (paymentMethodType) {
      case PaymentMethodType.Account:
        return t('account');
      case PaymentMethodType.BACS:
        return 'BACS';
      case PaymentMethodType.Card:
        return t('card');
      case PaymentMethodType.Cash:
        return t('cash');
      case PaymentMethodType.Cheque:
        return t('cheque');
      case PaymentMethodType.Paypal:
        return 'PayPal';
      case PaymentMethodType.Voucher:
        return t('voucher');
    }
  };

  return computed(() => getPaymentMethodName());
};
