<template>
  <div v-if="extraItineraryItemStore.quantity > 0" class="whitespace-nowrap">
    {{
      $n(extraItineraryItemStore.totalRate, {
        style: 'currency',
        currency: searchStore.activeProperty.currencyCode,
      })
    }}
  </div>
</template>
<script setup lang="ts">
import { useExtraItineraryItemStore } from '@/booking-itinerary/extra-itinerary/store/extra-itinerary-item.store';
import { useMandatoryExtraItineraryItemStore } from '@/booking-itinerary/extra-itinerary/store/mandatory-extra-itinerary-item.store';
import type { Extra } from '@/property/extra/extra';
import { useSearchStore } from '@/search/search.store';

const props = defineProps<{
  extra: Extra;
}>();

const searchStore = useSearchStore();
const extraItineraryItemStore = props.extra.isMandatory
  ? useMandatoryExtraItineraryItemStore(props.extra)
  : useExtraItineraryItemStore(props.extra);
</script>
