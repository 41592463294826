<template>
  <TermsAndConditionsStatementSection
    :title="toTitleCase($t('cancellationConditions'))"
  >
    <TermsAndConditionsStatementSectionList>
      <li
        v-for="condition of [...cancellationPoliciesTerms, noShowPolicyTerms]"
        :key="condition"
      >
        {{ condition }}
      </li>
    </TermsAndConditionsStatementSectionList>
  </TermsAndConditionsStatementSection>
</template>

<script setup lang="ts">
import { useLocaleString } from '@/string/locale-string.composable';
import TermsAndConditionsStatementSection from '@/terms-and-conditions/statement/sections/TermsAndConditionsStatementSection.vue';
import TermsAndConditionsStatementSectionList from '@/terms-and-conditions/statement/sections/TermsAndConditionsStatementSectionList.vue';
import { useTermsAndConditionsStatement } from '@/terms-and-conditions/statement/terms-and-conditions-statement.composable';

const { cancellationPoliciesTerms, noShowPolicyTerms } =
  useTermsAndConditionsStatement();

const { toTitleCase } = useLocaleString();
</script>
