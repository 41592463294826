<template>
  <div class="bg-lime-200 p-5">
    <template v-if="bookingItineraryStore.depositAmount > 0">
      <p class="text-xl font-semibold">
        {{
          $t(
            isNonRefundable
              ? 'aNonRefundablePaymentTypeOfAmountIsRequired'
              : 'aPaymentTypeOfAmountIsRequired',
            {
              paymentType: $t(
                bookingItineraryStore.depositAmount <
                  bookingItineraryStore.totalRate
                  ? 'deposit'
                  : 'payment',
              ),
              amount: generateCurrencyDisplayText(
                bookingItineraryStore.depositAmount,
              ),
            },
          )
        }}
      </p>

      <p
        v-if="
          paymentGateway.type === PaymentGatewayType.Stripe &&
          paymentGateway.transactionCharge
        "
      >
        {{
          $t('excludesACardProcessingFeeOfAmount', {
            amount: generateStripeTransactionChargeDisplayText(
              paymentGateway.transactionCharge,
            ),
          })
        }}
      </p>
    </template>

    <template v-else>
      <p class="text-xl font-semibold first-letter:capitalize">
        {{ $t('cardGuaranteeRequired') }}
      </p>

      <p class="mt-2">
        {{
          $t(
            'yourCardDetailsAreRequiredToGuaranteeYourBookingNoPaymentWillBeTakenAtThisTime',
          )
        }}
      </p>
    </template>

    <template v-if="!guestDetailsStore.hasAgreedToTermsAndConditions">
      <ul
        v-if="cancellationPoliciesTerms.length > 0"
        class="mt-2 text-sm font-medium"
      >
        <li
          v-for="cancellationPolicyTerms in cancellationPoliciesTerms"
          :key="cancellationPolicyTerms"
          class="mt-1"
        >
          {{ cancellationPolicyTerms }}
        </li>
      </ul>

      <BookingItineraryTermsAndConditionsAgreement class="mt-2 text-xs" />
    </template>
  </div>
</template>

<script setup lang="ts">
import { Decimal } from 'decimal.js';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import BookingItineraryTermsAndConditionsAgreement from '@/booking-itinerary/terms-and-conditions/agreement/BookingItineraryTermsAndConditionsAgreement.vue';
import { useGuestDetailsStore } from '@/guest-details-page/guest-details.store';
import { useCancellationPoliciesTermsAndConditionsDisplayTexts } from '@/property/booking-policy/cancellation-policy/terms-and-conditions/cancellation-policies-terms-and-conditions-display-texts.composable';
import { DepositPolicyType } from '@/property/booking-policy/deposit-policy/deposit-policy';
import type { PaymentGateway } from '@/property/payment-gateway/payment-gateway';
import { PaymentGatewayType } from '@/property/payment-gateway/payment-gateway';
import type { StripeTransactionCharge } from '@/property/payment-gateway/stripe/transaction-charge/stripe-transaction-charge';
import { StripeTransactionChargeType } from '@/property/payment-gateway/stripe/transaction-charge/stripe-transaction-charge';
import { useSearchStore } from '@/search/search.store';

defineProps<{ paymentGateway: PaymentGateway }>();

const { n } = useI18n();

const bookingItineraryStore = useBookingItineraryStore();
const searchStore = useSearchStore();
const guestDetailsStore = useGuestDetailsStore();

const currencyCode = computed(() => searchStore.activeProperty.currencyCode);

const bookingPolicy = computed(
  () =>
    bookingItineraryStore.propertyOverStay.bookingPolicyOverStay.bookingPolicy,
);

const depositPolicy = computed(() => bookingPolicy.value.depositPolicy);

const isDepositPolicyNonRefundable = computed(
  () =>
    depositPolicy.value.policyType === DepositPolicyType.Mandatory &&
    !depositPolicy.value.refundPolicy,
);

const isNonRefundable = computed(
  () =>
    bookingItineraryStore.isAnyUnitItineraryItemNonRefundable ||
    isDepositPolicyNonRefundable.value,
);

const cancellationPoliciesTerms =
  useCancellationPoliciesTermsAndConditionsDisplayTexts(
    bookingPolicy,
    computed(() => searchStore.activeProperty),
    computed(() => searchStore.stayDates),
  );

const generateCurrencyDisplayText = (amount: number): string =>
  n(amount, {
    style: 'currency',
    currency: currencyCode.value,
  });

const generatePercentageDisplayText = (percentage: number): string =>
  n(new Decimal(percentage).dividedBy(100).toNumber(), 'percent');

const generateStripeTransactionChargeDisplayText = (
  transactionCharge: StripeTransactionCharge,
): string =>
  transactionCharge.type === StripeTransactionChargeType.Percentage
    ? generatePercentageDisplayText(transactionCharge.percentage)
    : generateCurrencyDisplayText(transactionCharge.amount);
</script>
