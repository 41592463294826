import { type Ref, watchEffect } from 'vue';
import type { Property } from '@/property/property';
import { getRGBColorFromHex } from '@/string/string.utilities';

export const useAccentColorCalculator = (property: Ref<Property>) => {
  const setCSSVariable = (key: string, value: string) => {
    document.documentElement.style.setProperty(key, value);
  };

  watchEffect(() => {
    const { hex, contrastHex, isHighLuminance } =
      property.value.propertyTheme.accentColor;

    const rgbStringAccentColor = getRGBColorFromHex(hex);
    const rgbAccentContrastColor = getRGBColorFromHex(contrastHex);

    setCSSVariable('--accent-color', rgbStringAccentColor);

    setCSSVariable(
      '--accent-contrast-border',
      isHighLuminance ? '1px solid rgb(15 23 42)' : 'none',
    );

    setCSSVariable(
      '--accent-contrast-shadow',
      isHighLuminance ? '0 10px 20px -15px rgb(15 23 42)' : 'none',
    );

    setCSSVariable(
      '--icon-accent-color',
      isHighLuminance ? rgbAccentContrastColor : rgbStringAccentColor,
    );

    setCSSVariable('--accent-contrast-color', rgbAccentContrastColor);
  });
};
