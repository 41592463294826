import { Decimal } from 'decimal.js';
import type { PropertyAvailability } from '@/availability/property-availability/property-availability';
import type { Occupancy } from '@/occupancy/occupancy';
import type { SupplementalMeal } from '@/property/meal/supplemental-meal/supplemental-meal';
import type { DiscountOffer } from '@/property/offer/discount-offer/discount-offer';
import { applyDiscountOfferUnitAdjustmentToNightlyRates } from '@/property/offer/discount-offer/discount-offer.utilities';
import type { FreeNightOffer } from '@/property/offer/free-night-offer/free-night-offer';
import { applyFreeNightOfferToNightlyRates } from '@/property/offer/free-night-offer/free-night-offer.utilities';
import { OfferType } from '@/property/offer/offer';
import type { PackageOffer } from '@/property/offer/package-offer/package-offer';
import {
  applyPackageOfferToUnitNightlyRates,
  applyPackageOfferToWayToSellNightlyRates,
} from '@/property/offer/package-offer/package-offer.utilities';
import type { Promocode } from '@/property/promocode/promocode';
import {
  createDiscountOfferMealsRates,
  createFreeNightOfferMealsRates,
} from '@/rates/meal-rates/meal-rates.utilities';
import type { NightlyRates } from '@/rates/nightly-rates/nightly-rates';
import {
  areAllNightlyRatesPositive,
  getFreeNightsFromNightlyRates,
  getTotalRateFromNightlyRates,
} from '@/rates/nightly-rates/nightly-rates.utilities';
import type {
  OfferRates,
  NonPackageOfferRates,
  PackageOfferRates,
} from '@/rates/offer-rates/offer-rates';
import { createPromocodeRates } from '@/rates/promocode-rates/promocode-rates.utilities';
import type { StayDates } from '@/stay-dates/stay-dates';

/**
 * Returns true if the first set of given offer rates are cheaper than the second,
 * false otherwise.
 */
export const areOfferRatesCheaper = (
  offerRatesToCompare: OfferRates,
  offerRatesToCompareAgainst: OfferRates,
): boolean =>
  new Decimal(
    getTotalRateFromNightlyRates(offerRatesToCompare.nightlyRates),
  ).lessThan(
    getTotalRateFromNightlyRates(offerRatesToCompareAgainst.nightlyRates),
  );

export const getCheapestRefundableNonPackageOfferRates = (
  offersRates: OfferRates[],
): NonPackageOfferRates | undefined => {
  const refundableNonPackageOffersRates =
    getRefundableNonPackageOffersRates(offersRates);

  return getCheapestOfferRate(refundableNonPackageOffersRates);
};

export const getCheapestNonRefundableNonPackageOfferRates = (
  offersRates: OfferRates[],
): NonPackageOfferRates | undefined => {
  const nonRefundableNonPackageOffersRates =
    getNonRefundableNonPackageOffersRates(offersRates);

  return getCheapestOfferRate(nonRefundableNonPackageOffersRates);
};

export const getPackageOffersRates = (
  offersRates: OfferRates[],
): PackageOfferRates[] =>
  offersRates.filter<PackageOfferRates>(
    (offerRates): offerRates is PackageOfferRates =>
      offerRates.offer.offerType === OfferType.Package,
  );

const getRefundableNonPackageOffersRates = (
  offersRates: OfferRates[],
): NonPackageOfferRates[] =>
  offersRates.filter<NonPackageOfferRates>(
    (offerRates): offerRates is NonPackageOfferRates =>
      offerRates.offer.offerType !== OfferType.Package &&
      offerRates.offer.isRefundable,
  );

const getNonRefundableNonPackageOffersRates = (
  offersRates: OfferRates[],
): NonPackageOfferRates[] =>
  offersRates.filter<NonPackageOfferRates>(
    (offerRates): offerRates is NonPackageOfferRates =>
      offerRates.offer.offerType !== OfferType.Package &&
      !offerRates.offer.isRefundable,
  );

const getCheapestOfferRate = <T extends OfferRates>([
  firstOfferRate,
  ...remainingOfferRates
]: T[]): T | undefined => {
  if (!firstOfferRate) {
    return;
  }

  return remainingOfferRates.reduce(
    (cheapestOfferRate, offerRate) =>
      getTotalRateFromNightlyRates(offerRate.nightlyRates) <
      getTotalRateFromNightlyRates(cheapestOfferRate.nightlyRates)
        ? offerRate
        : cheapestOfferRate,
    firstOfferRate,
  );
};

export const isNonPackageOfferRates = (
  offerRates: OfferRates,
): offerRates is NonPackageOfferRates =>
  offerRates.offer.offerType !== OfferType.Package;

export const calculateDiscountOfferRatesOnNightlyRates = (
  unitId: number,
  standardNightlyRates: NightlyRates,
  discountOffer: DiscountOffer,
  promocode: Promocode | undefined,
  propertyAvailability: PropertyAvailability,
  stayDates: StayDates,
  occupancy: Occupancy,
  meals: SupplementalMeal[],
): OfferRates | undefined => {
  const offerNightlyRates = applyDiscountOfferUnitAdjustmentToNightlyRates(
    discountOffer,
    propertyAvailability,
    standardNightlyRates,
    unitId,
  );

  if (!areAllNightlyRatesPositive(offerNightlyRates)) {
    return undefined;
  }

  const offerMealsRates = createDiscountOfferMealsRates(
    meals,
    discountOffer,
    propertyAvailability,
    stayDates,
    occupancy,
  );

  const offerPromocodeRates = promocode
    ? createPromocodeRates(promocode, offerNightlyRates)
    : undefined;

  return {
    offer: discountOffer,
    nightlyRates: offerNightlyRates,
    promocodeRates: offerPromocodeRates,
    mealsRates: offerMealsRates,
  };
};

export const calculateFreeNightOfferRatesOnNightlyRates = (
  standardNightlyRates: NightlyRates,
  freeNightOffer: FreeNightOffer,
  promocode: Promocode | undefined,
  propertyAvailability: PropertyAvailability,
  stayDates: StayDates,
  occupancy: Occupancy,
  meals: SupplementalMeal[],
): OfferRates => {
  const offerNightlyRates = applyFreeNightOfferToNightlyRates(
    freeNightOffer,
    standardNightlyRates,
  );

  const freeNights = getFreeNightsFromNightlyRates(offerNightlyRates);

  const offerMealsRates = createFreeNightOfferMealsRates(
    meals,
    freeNights,
    propertyAvailability,
    stayDates,
    occupancy,
  );

  const offerPromocodeRates = promocode
    ? createPromocodeRates(promocode, offerNightlyRates)
    : undefined;

  return {
    offer: freeNightOffer,
    nightlyRates: offerNightlyRates,
    promocodeRates: offerPromocodeRates,
    mealsRates: offerMealsRates,
  };
};

export const calculatePackageOfferUnitRatesOnNightlyRates = (
  unitId: number,
  standardNightlyRates: NightlyRates,
  packageOffer: PackageOffer,
): OfferRates => {
  const offerNightlyRates = applyPackageOfferToUnitNightlyRates(
    packageOffer,
    standardNightlyRates,
    unitId,
  );

  return {
    offer: packageOffer,
    nightlyRates: offerNightlyRates,
    mealsRates: undefined,
  };
};

export const calculatePackageOfferWayToSellRatesOnNightlyRates = (
  wayToSellId: number,
  standardNightlyRates: NightlyRates,
  packageOffer: PackageOffer,
): OfferRates => {
  const offerNightlyRates = applyPackageOfferToWayToSellNightlyRates(
    packageOffer,
    standardNightlyRates,
    wayToSellId,
  );

  return {
    offer: packageOffer,
    nightlyRates: offerNightlyRates,
    mealsRates: undefined,
  };
};
