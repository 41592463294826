<template>
  <InlineBookingStageSelector
    ref="inlineSelectorElement"
    :class="{ invisible: bookingStageStore.shouldShowDropdownSelector }"
    class="sticky top-0 z-20 w-full"
  />

  <DropdownBookingStageSelector
    v-if="bookingStageStore.shouldShowDropdownSelector"
  />
</template>

<script setup lang="ts">
import { useResizeObserver } from '@vueuse/core';
import { ref } from 'vue';
import { useBookingStageStore } from '@/booking-stage/booking-stage.store';
import DropdownBookingStageSelector from '@/booking-stage/selector/dropdown/DropdownBookingStageSelector.vue';
import InlineBookingStageSelector from '@/booking-stage/selector/inline/InlineBookingStageSelector.vue';

const bookingStageStore = useBookingStageStore();

const inlineSelectorElement = ref<HTMLDivElement>();

useResizeObserver(inlineSelectorElement, ([entry]) => {
  if (!entry) {
    return;
  }

  const { scrollWidth, clientWidth, clientHeight } = entry.target;

  const isInlineSelectorOverflowing = scrollWidth > clientWidth;

  bookingStageStore.shouldShowDropdownSelector = isInlineSelectorOverflowing;

  document.documentElement.style.setProperty(
    '--inline-booking-stage-selector-height',
    `${clientHeight}px`,
  );
});
</script>
