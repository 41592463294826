import {
  differenceInDays,
  eachDayOfInterval,
  format,
  isBefore,
  parseISO,
  startOfDay,
  subDays,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

/**
 * Parses the given ISO 8601 formatted date and returns an instance of Date.
 */
export const createDateObject = (date: string): Date => parseISO(date);

/**
 * @see {@link https://date-fns.org/v2.28.0/docs/format}
 */
export const formatDate = (date: string, dateFormat?: string): string =>
  formatDateObject(createDateObject(date), dateFormat);

export const getCurrentDateInTimezone = (timezone: string): string =>
  formatDateObject(getCurrentDateObjectInTimezone(timezone));

export const getCurrentDateObjectInTimezone = (timezone: string): Date =>
  utcToZonedTime(Date.now(), timezone);

export const subtractDays = (date: string, numberOfDays: number): string =>
  formatDateObject(subDays(createDateObject(date), numberOfDays));

export const getDatesInRange = (
  fromDate: string,
  toDate: string,
  {
    dateFormat,
    includeToDate,
  }: {
    dateFormat?: string;
    includeToDate?: boolean;
  } = {
    includeToDate: true,
  },
): string[] =>
  eachDayOfInterval({
    start: createDateObject(fromDate),
    end: createDateObject(includeToDate ? toDate : subtractDays(toDate, 1)),
  }).map((date) => formatDateObject(date, dateFormat));

export const getNumberOfDaysInRange = (
  fromDate: string,
  toDate: string,
): number =>
  differenceInDays(createDateObject(toDate), createDateObject(fromDate));

export const formatDateObject = (dateObject: Date, dateFormat = 'yyyy-MM-dd') =>
  format(dateObject, dateFormat);

export const isBeforeToday = (date: string, timezone: string): boolean =>
  isBefore(
    startOfDay(createDateObject(date)),
    startOfDay(getCurrentDateObjectInTimezone(timezone)),
  );

export const getStartOfDateTime = (date: string): number =>
  startOfDay(createDateObject(date)).getTime();
