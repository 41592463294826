import type { SmokingPolicy } from '@/property/smoking-policy/smoking-policy';

export const smokingPolicyAllowsSmokingInAllAreas = ({
  isAllowedInOpenAir,
  isAllowedInPublicAreas,
  isAllowedInRooms,
}: SmokingPolicy): boolean =>
  isAllowedInOpenAir && isAllowedInPublicAreas && isAllowedInRooms;

export const smokingPolicyDisallowsSmokingInAllAreas = ({
  isAllowedInOpenAir,
  isAllowedInPublicAreas,
  isAllowedInRooms,
}: SmokingPolicy): boolean =>
  !isAllowedInOpenAir && !isAllowedInPublicAreas && !isAllowedInRooms;
