import type { Ref } from 'vue';
import { computed } from 'vue';
import { useNearbyParkingPolicyTermsAndConditionsDisplayText } from '@/property/parking-policy/nearby/terms-and-conditions/nearby-parking-policy-terms-and-conditions-display-text.composable';
import { useOnSiteParkingPolicyTermsAndConditionsDisplayText } from '@/property/parking-policy/on-site/terms-and-conditions/on-site-parking-policy-terms-and-conditions-display-text.composable';
import { useStreetParkingPolicyTermsAndConditionsDisplayText } from '@/property/parking-policy/street/terms-and-conditions/street-parking-policy-terms-and-conditions-display-text.composable';
import type { Property } from '@/property/property';

export const useParkingPoliciesTermsAndConditionsDisplayText = (
  property: Ref<Property>,
) => {
  const onSiteParkingPolicy = computed(
    () => property.value.parkingPolicies.onSiteParkingPolicy,
  );

  const onSiteParkingDisplayText =
    useOnSiteParkingPolicyTermsAndConditionsDisplayText(property);

  const streetParkingDisplayText =
    useStreetParkingPolicyTermsAndConditionsDisplayText(property);

  const nearbyParkingDisplayText =
    useNearbyParkingPolicyTermsAndConditionsDisplayText(property);

  const shouldIncludeNonOnSiteParkingPolicies = computed(
    () => !onSiteParkingPolicy.value?.isEnoughForAllGuests,
  );

  return computed(() =>
    [
      onSiteParkingDisplayText.value,
      ...(shouldIncludeNonOnSiteParkingPolicies.value
        ? [streetParkingDisplayText.value, nearbyParkingDisplayText.value]
        : []),
    ]
      .filter(Boolean)
      .join(' '),
  );
};
