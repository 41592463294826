import { Decimal } from 'decimal.js';
import type { MonetaryPromocode } from '@/property/promocode/monetary-promocode/monetary-promocode';
import type { NightlyRates } from '@/rates/nightly-rates/nightly-rates';

/**
 * Applies the given monetary promocode to the given nightly rates by
 * determining the nightly promocode discount and subtracting that
 * amount from each night.
 */
export const applyMonetaryPromocodeToNightlyRates = (
  { amount }: MonetaryPromocode,
  nightlyRates: NightlyRates,
): NightlyRates => {
  const nightlyDiscount = Decimal.div(amount, nightlyRates.length);

  return nightlyRates.map((nightlyRate) => ({
    ...nightlyRate,
    rate: Decimal.sub(nightlyRate.rate, nightlyDiscount).toNumber(),
  }));
};
