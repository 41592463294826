import { GrammaticalGender } from '@/i18n/locales/gender/gender';
import type { TranslationKeyToMessageFunction } from '@/i18n/locales/message-functions/message-functions';
import type itITStaticTranslations from '@/i18n/locales/messages/it-IT.json';

export type ItITMessageFunctions = Omit<
  TranslationKeyToMessageFunction,
  keyof typeof itITStaticTranslations
>;

export const itIT: ItITMessageFunctions = {
  youHaveSelectedTheLastUnitAvailable: ({ values }) => {
    switch (values.unitTypeGender) {
      case GrammaticalGender.Neuter:
      case GrammaticalGender.Masculine:
        return `Hai selezionato l'ultimo ${values.unitTypeName} disponibile`;
      case GrammaticalGender.Feminine:
        return `Hai selezionato l'ultima ${values.unitTypeName} disponibile`;
    }
  },
  pleaseSelectAUnitToContinue: ({ values }) => {
    switch (values.unitTypeGender) {
      case GrammaticalGender.Neuter:
      case GrammaticalGender.Masculine:
        return `Si prega di selezionare un ${values.unitTypeName} per continuare`;
      case GrammaticalGender.Feminine:
        return `Si prega di selezionare una ${values.unitTypeName} per continuare`;
    }
  },
  someOfTheUnits: ({ values }) => {
    switch (values.unitTypeGender) {
      case GrammaticalGender.Neuter:
      case GrammaticalGender.Masculine:
        return `Alcuni dei ${values.unitTypeName}`;
      case GrammaticalGender.Feminine:
        return `Alcune delle ${values.unitTypeName}`;
    }
  },
  theseUnitsWillBeDiscardedIfYouSwitchToPropertyName: ({ type, values }) => {
    if (type !== 'vnode') {
      switch (values.unitTypeGender) {
        case GrammaticalGender.Neuter:
        case GrammaticalGender.Masculine:
          return `Questi ${values.unitTypeName} saranno eliminati se si passa a ${values.propertyName}.`;
        case GrammaticalGender.Feminine:
          return `Queste ${values.unitTypeName} saranno eliminate se si passa a ${values.propertyName}.`;
      }
    }

    return [
      values.unitTypeGender[0]!.children === GrammaticalGender.Feminine
        ? 'Queste '
        : 'Questi ',
      values.unitTypeName,
      values.unitTypeGender[0]!.children === GrammaticalGender.Feminine
        ? ' saranno eliminate se si passa a '
        : ' saranno eliminati se si passa a ',
      values.propertyName,
      '.',
    ];
  },
  youAreAboutToRemoveTheLastUnit: ({ values }) => {
    switch (values.unitTypeGender) {
      case GrammaticalGender.Neuter:
      case GrammaticalGender.Masculine:
        return `Stai per rimuovere l'ultimo ${values.unitTypeName} dalla tua selezione. Sarai reindirizzato alla pagina di selezione dei ${values.unitTypeName}. Vuoi continuare?`;
      case GrammaticalGender.Feminine:
        return `Stai per rimuovere l'ultima ${values.unitTypeName} dalla tua selezione. Sarai reindirizzato alla pagina di selezione delle ${values.unitTypeName}. Vuoi continuare?`;
    }
  },
  youHaveAlreadySelectedUnitsForDifferentStayDates: ({ values }) => {
    switch (values.unitTypeGender) {
      case GrammaticalGender.Neuter:
      case GrammaticalGender.Masculine:
        return `Hai già selezionato diversi ${values.unitTypeName} per date di soggiorno diverse.`;
      case GrammaticalGender.Feminine:
        return `Hai già selezionato diverse ${values.unitTypeName} per date di soggiorno diverse.`;
    }
  },
  youHaveAlreadySelectedUnitsForPropertyName: ({ type, values }) => {
    if (type !== 'vnode') {
      switch (values.unitTypeGender) {
        case GrammaticalGender.Neuter:
        case GrammaticalGender.Masculine:
          return `Hai già selezionato dei ${values.unitTypeName} per ${values.propertyName}.`;
        case GrammaticalGender.Feminine:
          return `Hai già selezionato delle ${values.unitTypeName} per ${values.propertyName}.`;
      }
    }

    return [
      values.unitTypeGender[0]!.children === GrammaticalGender.Feminine
        ? 'Hai già selezionato delle '
        : 'Hai già selezionato dei ',
      values.unitTypeName,
      ' per ',
      values.propertyName,
      '.',
    ];
  },
  ifYouRemoveThisPrivateRateYourUnitsWillBeRemovedAndYouWillBeRedirected: ({
    values: { unitTypeGender, unitTypeName, unitTypeNamePlural },
  }) => {
    switch (unitTypeGender) {
      case GrammaticalGender.Neuter:
      case GrammaticalGender.Masculine:
        return `Se rimuovi questa tariffa privata, il tuo ${unitTypeNamePlural} attualmente selezionato verrà rimosso e sarai reindirizzato alla pagina di selezione del ${unitTypeName}. Vuoi continuare?`;
      case GrammaticalGender.Feminine:
        return `Se rimuovi questa tariffa privata, la tua ${unitTypeNamePlural} attualmente selezionata verrà rimossa e sarai reindirizzata alla pagina di selezione della ${unitTypeName}. Vuoi continuare?`;
    }
  },
  unitsBooked: ({ values: { unitTypeName, unitTypeGender } }) => {
    switch (unitTypeGender) {
      case GrammaticalGender.Neuter:
      case GrammaticalGender.Masculine:
        return `${unitTypeName} prenotati`;
      case GrammaticalGender.Feminine:
        return `${unitTypeName} prenotate`;
    }
  },
};
