import type { Icon } from '@tabler/icons-vue';

export enum BookingStageType {
  UnitSelection = 'unit_selection',
  Extras = 'extras',
  GuestDetails = 'guest_details',
  Payments = 'payments',
}

export interface BookingStage {
  type: BookingStageType;
  routeName: string;
  title: string;
  icon: Icon;
  bookingSummaryContinueButton: {
    text: string;
    icon?: Icon;
  };
  shouldBePresent: boolean;
  canAccess: boolean;
  requiresNonEmptyUnitItinerary: boolean;
  shouldDisplayTermsAndConditionsAgreement: boolean;
  shouldDisplayRecaptchaInformation: boolean;
  shouldDisplayGuestDetailsInSummary: boolean;
  shouldDisplayModalBookingSummaryFooterStayDates: boolean;
  goToNextStage: () => Promise<void> | void;
  onInitialize?: () => void;
}
