<template>
  <div class="text-lg font-semibold capitalize text-slate-800">
    {{ $t('unitDetails', { unitTypeName }) }}
  </div>

  <div
    v-for="(unitItineraryItem, index) in bookingItineraryStore.unitItinerary"
    :key="unitItineraryItem.id"
    class="mt-8"
  >
    <GuestDetailsFormUnitHeader
      class="mb-2"
      :unit-itinerary-item-id="unitItineraryItem.id"
      :unit-number="
        bookingItineraryStore.unitItinerary.length > 1 ? index + 1 : undefined
      "
    />

    <GuestDetailsFormUnit
      v-model="bookingItineraryStore.unitItinerary[index]!"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import GuestDetailsFormUnit from '@/guest-details-page/form/unit/GuestDetailsFormUnit.vue';
import GuestDetailsFormUnitHeader from '@/guest-details-page/form/unit/header/GuestDetailsFormUnitHeader.vue';
import { useUnitType } from '@/property/unit/type/unit-type.composable';
import { useSearchStore } from '@/search/search.store';

const searchStore = useSearchStore();
const bookingItineraryStore = useBookingItineraryStore();

const { unitTypeName } = useUnitType(
  computed(() => searchStore.activeProperty.unitType),
);
</script>
