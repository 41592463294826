<template>
  <AppFormValidatedInput
    v-slot="{ isValid }"
    :value="bookingItineraryStore.specialRequests"
    :validation-rules="[
      isLessThanOrEqualToLengthRule(
        SPECIAL_REQUESTS_MAX_LENGTH,
        $t('inputNameMustNotExceedMaxLengthCharacters', {
          inputName: capitalize($t('specialRequests')),
          maxLength: SPECIAL_REQUESTS_MAX_LENGTH,
        }),
      ),
    ]"
  >
    <label :for="TEXT_AREA_LABEL">
      <div class="my-2 font-medium capitalize">
        {{ $t('doYouHaveAnySpecialRequests') }}
      </div>

      <textarea
        :id="TEXT_AREA_LABEL"
        v-model="bookingItineraryStore.specialRequests"
        class="app-textarea"
        :class="{
          'bg-red-500/10 text-red-700 shadow-red-500 focus-visible:shadow-red-500':
            !isValid,
        }"
        :maxlength="SPECIAL_REQUESTS_MAX_LENGTH"
        :aria-describedby="CHARACTER_COUNTER_LABEL"
      ></textarea>
    </label>

    <AppCharacterCounter
      :id="CHARACTER_COUNTER_LABEL"
      class="mt-1 flex justify-end text-sm"
      :current-number-of-characters="
        bookingItineraryStore.specialRequests.length
      "
      :maximum-number-of-characters="SPECIAL_REQUESTS_MAX_LENGTH"
    />
  </AppFormValidatedInput>
</template>

<script setup lang="ts">
import { SPECIAL_REQUESTS_MAX_LENGTH } from '@/booking/booking';
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import { useLocaleString } from '@/string/locale-string.composable';
import AppCharacterCounter from '@/ui/app-character-counter/AppCharacterCounter.vue';
import { isLessThanOrEqualToLengthRule } from '@/ui/app-form/app-form-input-validation-rules.utilities';
import AppFormValidatedInput from '@/ui/app-form/AppFormValidatedInput.vue';

const TEXT_AREA_LABEL = 'special_requests';
const CHARACTER_COUNTER_LABEL = 'character_counter_special_requests';

const bookingItineraryStore = useBookingItineraryStore();

const { capitalize } = useLocaleString();
</script>
