import type {
  BeforeArrivalAdvancedPaymentPolicy,
  BeforeArrivalAdvancedPaymentPolicyDTO,
} from '@/property/booking-policy/advanced-payment-policy/before-arrival/before-arrival-advanced-payment-policy';
import type {
  OnArrivalAdvancedPaymentPolicy,
  OnArrivalAdvancedPaymentPolicyDTO,
} from '@/property/booking-policy/advanced-payment-policy/on-arrival/on-arrival-advanced-payment-policy';
import type {
  OnDepartureAdvancedPaymentPolicy,
  OnDepartureAdvancedPaymentPolicyDTO,
} from '@/property/booking-policy/advanced-payment-policy/on-departure/on-departure-advanced-payment-policy';

export enum AdvancedPaymentPolicyType {
  OnArrival = 'on_arrival',
  OnDeparture = 'on_departure',
  BeforeArrival = 'before_arrival',
}

export type AdvancedPaymentPolicy =
  | OnArrivalAdvancedPaymentPolicy
  | OnDepartureAdvancedPaymentPolicy
  | BeforeArrivalAdvancedPaymentPolicy;

export type AdvancedPaymentPolicyDTO =
  | OnArrivalAdvancedPaymentPolicyDTO
  | OnDepartureAdvancedPaymentPolicyDTO
  | BeforeArrivalAdvancedPaymentPolicyDTO;
