<template>
  <div class="flex">
    <div
      class="animate-bouncing mx-[1px] h-1 w-1 rounded-full bg-current [animation-delay:_100ms_!important]"
    ></div>
    <div
      class="animate-bouncing mx-[1px] h-1 w-1 rounded-full bg-current [animation-delay:_200ms_!important]"
    ></div>
    <div
      class="animate-bouncing mx-[1px] h-1 w-1 rounded-full bg-current [animation-delay:_300ms_!important]"
    ></div>
  </div>
</template>
<style scoped>
@keyframes bounce {
  0%,
  100% {
    transform: none;
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(50%);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}
.animate-bouncing {
  animation: bounce 1s infinite;
}
</style>
