<!--
  After PCIB has successfully captured a card, this is where we expect them to redirect to within the
  iframe. They should have attached any relevant card details as query parameters. We'll extract
  those details and send them in a message event back to the parent window, which should be the
  payment page hosting the iframe.

  We don't render any template here as it's expected that, instead, the parent window will be
  displaying some kind of overlay during this process.
-->

<script setup lang="ts">
import { isString } from 'lodash-es';
import { useRoute } from 'vue-router';
import { InternalMessageEventDataType } from '@/message-event/internal/internal-message-event';
import { sendInternalPostMessageEvent } from '@/message-event/internal/internal-message-event.utilities';

const {
  query: { cardTokenUrl, cardType, cardHolderName, cardExpiration },
} = useRoute();

const expectedQueryParamsAreValid =
  isString(cardTokenUrl) &&
  isString(cardType) &&
  isString(cardHolderName) &&
  isString(cardExpiration);

sendInternalPostMessageEvent(
  parent,
  expectedQueryParamsAreValid
    ? {
        type: InternalMessageEventDataType.PcibCardCaptureSuccess,
        cardTokenUrl,
        cardType,
        cardHolderName,
        cardExpiration,
      }
    : {
        type: InternalMessageEventDataType.PcibCardCaptureFailure,
      },
);
</script>
