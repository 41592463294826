<template>
  <div
    v-if="sortedUnitIds.length > 0"
    data-test="UnitSelectionUnavailableUnitList"
  >
    <div
      class="my-4 bg-zinc-100 py-6 text-center text-lg font-medium"
      data-test="UnitSelectionList__separator"
    >
      <p class="px-3">
        <template v-if="showPropertyName">
          {{
            $t('availableUnitsOnOtherDatesAtProperty', {
              unitTypeName,
              propertyName: searchStore.activeProperty.name,
            })
          }}
        </template>

        <template v-else>
          {{
            $t('availableUnitsOnOtherDates', {
              unitTypeName,
            })
          }}
        </template>
      </p>
    </div>

    <UnitSelectionUnits :unit-ids="sortedUnitIds" :are-available="false" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useUnitType } from '@/property/unit/type/unit-type.composable';
import { compareUnitsByPriority } from '@/property/unit/unit.utilities';
import { useSearchStore } from '@/search/search.store';
import { useLocaleString } from '@/string/locale-string.composable';
import UnitSelectionUnits from '@/unit-selection-page/unit/UnitSelectionUnits.vue';

defineProps<{ showPropertyName: boolean }>();

const { toTitleCase } = useLocaleString();

const searchStore = useSearchStore();

const { unitTypeNamePlural } = useUnitType(
  computed(() => searchStore.activeProperty.unitType),
);

const unitTypeName = computed(() => toTitleCase(unitTypeNamePlural.value));

/**
 * Note that if the property itself is unavailable, then we'll consider all units
 * unavailable.
 */
const units = computed(() =>
  searchStore.activePropertyOverStay.isAvailable
    ? searchStore.activePropertyOverStay.unavailableUnits
    : searchStore.activePropertyOverStay.units,
);

const sortedUnitIds = computed(() =>
  [...units.value]
    .sort((unitA, unitB) =>
      compareUnitsByPriority(
        searchStore.activeProperty.priorityOrderedUnitIds,
        unitA,
        unitB,
      ),
    )
    .map(({ id }) => id),
);
</script>
