<template>
  <button
    class="group focus-visible:outline-none"
    :disabled="disabled"
    :aria-label="label"
    @click="$emit('click')"
  >
    <div
      class="flex h-9 w-9 items-center justify-center rounded-md border border-slate-900 bg-white transition-[filter] group-focus-visible:outline group-focus-visible:outline-1 group-focus-visible:outline-offset-2 group-focus-visible:outline-slate-900 group-hover:group-enabled:brightness-95 group-active:group-enabled:brightness-90 group-disabled:border-neutral-300 group-disabled:text-neutral-300"
    >
      <IconPlus
        v-if="isIncrementing"
        :size="ICON_SIZE"
        :stroke-width="ICON_STROKE_WIDTH"
      />

      <IconMinus v-else :size="ICON_SIZE" :stroke-width="ICON_STROKE_WIDTH" />
    </div>
  </button>
</template>

<script setup lang="ts">
import { IconPlus, IconMinus } from '@tabler/icons-vue';

const ICON_SIZE = 14;
const ICON_STROKE_WIDTH = '3';

defineProps<{
  isIncrementing: boolean;
  disabled: boolean;
  label: string;
}>();

defineEmits(['click']);
</script>
