<template>
  <ListboxOption
    v-slot="{ active, selected }"
    :value="value"
    :disabled="props.disabled"
  >
    <li
      :class="{
        'bg-yellow-100': active,
        'bg-neutral-100': disabled,
      }"
      class="flex cursor-pointer select-none items-center p-2"
    >
      <div v-if="selected && !disabled" class="mr-1 text-amber-500">
        <IconCheck class="h-5 w-5" aria-hidden="true" />
      </div>

      <div :class="selected ? 'font-semibold' : 'font-normal'" class="truncate">
        {{ label || value }}
      </div>
    </li>
  </ListboxOption>
</template>

<script setup lang="ts">
import { ListboxOption } from '@headlessui/vue';
import { IconCheck } from '@tabler/icons-vue';

const props = withDefaults(
  defineProps<{
    value: string | number | object;
    label?: string;
    disabled?: boolean;
  }>(),
  {
    disabled: false,
    label: '',
  },
);
</script>
