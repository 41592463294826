import { mapDailyPropertyAvailabilityFromDTO } from '@/availability/daily-property-availability/daily-property-availability.mapper';
import type {
  PropertyAvailability,
  PropertyAvailabilityDTO,
} from '@/availability/property-availability/property-availability';

export const mapPropertyAvailabilityFromDTO = (
  propertyAvailabilityDTO: PropertyAvailabilityDTO,
): PropertyAvailability => ({
  propertyId: propertyAvailabilityDTO.propertyId,
  dailyPropertyAvailabilities:
    propertyAvailabilityDTO.datedPropertyAvailabilities.map(
      mapDailyPropertyAvailabilityFromDTO,
    ),
});

export const mapPropertyAvailabilitiesFromDTOs = (
  propertyAvailabilityDTOs: PropertyAvailabilityDTO[],
): PropertyAvailability[] =>
  propertyAvailabilityDTOs.map(mapPropertyAvailabilityFromDTO);
