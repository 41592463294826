import { whenever } from '@vueuse/core';
import type { Ref } from 'vue';
import { ref } from 'vue';
import type { BookingSource } from '@/booking-source/booking-source';
import {
  createPersistableBookingSource,
  isPersistableBookingSourceValid,
} from '@/booking-source/persistable/persistable-booking-source.utilities';
import { IDB_KEY_BOOKING_SOURCE } from '@/idb';
import { useIDBRef } from '@/idb/idb-ref.composable';
import { useSearchStore } from '@/search/search.store';

/**
 * Synchronizes the given booking source ref to indexed DB.
 *
 * Additionally, automatically resets the booking source value if the
 * persisted version has:
 * - expired, or
 * - originally used a widget different to the currently active widget.
 */
export const usePersistableBookingSource = (
  bookingSource: Ref<BookingSource | undefined>,
) => {
  const searchStore = useSearchStore();

  const persistableBookingSource = ref(
    bookingSource.value
      ? createPersistableBookingSource(
          searchStore.widget.id,
          bookingSource.value,
        )
      : undefined,
  );

  useIDBRef(IDB_KEY_BOOKING_SOURCE, persistableBookingSource, {
    shouldInitialize: persistableBookingSource.value === undefined,
  });

  whenever(persistableBookingSource, (persistableBookingSource) => {
    bookingSource.value = isPersistableBookingSourceValid(
      persistableBookingSource,
      searchStore.widget.id,
    )
      ? persistableBookingSource.bookingSource
      : undefined;
  });
};
