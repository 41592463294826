import { DepositPolicyType } from '@/property/booking-policy/deposit-policy/deposit-policy';
import type {
  NoDepositPolicy,
  NoDepositPolicyDTO,
} from '@/property/booking-policy/deposit-policy/no/no-deposit-policy';

export const mapNoDepositPolicyFromDTO = (
  noDepositPolicyDTO: NoDepositPolicyDTO,
): NoDepositPolicy => ({
  policyType: DepositPolicyType.No,
  isCardGuaranteeRequired: noDepositPolicyDTO.isCardGuaranteeRequired,
});
