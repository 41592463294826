<template>
  <div
    class="whitespace-nowrap"
    :class="{ 'font-semibold': !isUpdatingSelectedMeals }"
  >
    {{ totalRateDisplayText }}
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useBookingSummaryMealsStore } from '@/booking-summary/body/unit-item/meals/booking-summary-meals.store';
import type { SupplementalMeal } from '@/property/meal/supplemental-meal/supplemental-meal';

const props = defineProps<{ meal: SupplementalMeal }>();

const { isUpdatingSelectedMeals, getNumberFormattedTotalRateForMeal } =
  useBookingSummaryMealsStore();

const numberFormattedTotalRate = computed(() =>
  getNumberFormattedTotalRateForMeal(props.meal),
);

const totalRateDisplayText = computed(() =>
  isUpdatingSelectedMeals.value
    ? `(+${numberFormattedTotalRate.value})`
    : numberFormattedTotalRate.value,
);
</script>
