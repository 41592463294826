import type { PropertyAvailability } from '@/availability/property-availability/property-availability';
import { findPropertyAvailabilityMealAvailabilityByDate } from '@/availability/property-availability/property-availability.utilities';
import type { IncludedMeal } from '@/property/meal/included-meal/included-meal';
import { MealType } from '@/property/meal/meal';
import type { Meal } from '@/property/meal/meal';
import type { StayDates } from '@/stay-dates/stay-dates';
import { getNightsOfStayFromStayDates } from '@/stay-dates/stay-dates.utilities';

export const getMealTranslationKey = ({ type }: Meal): string =>
  MEAL_TYPE_TRANSLATION_KEY_MAPPING[type];

export const getOrderedMealTypes = (): MealType[] => [
  MealType.Breakfast,
  MealType.Lunch,
  MealType.Dinner,
];

export const isMealApplicableOnStayDates = (
  meal: Meal,
  propertyAvailability: PropertyAvailability,
  stayDates: StayDates,
): boolean =>
  isMealAvailableOnAllDates(
    meal,
    propertyAvailability,
    getNightsOfStayFromStayDates(stayDates),
  );

export const isAnyMealTypeClosedOutOnAnyStayDate = (
  mealTypes: MealType[],
  stayDates: StayDates,
  propertyAvailability: PropertyAvailability,
): boolean =>
  getNightsOfStayFromStayDates(stayDates).some((date) =>
    mealTypes.some((mealType) => {
      const mealAvailability = findPropertyAvailabilityMealAvailabilityByDate(
        propertyAvailability,
        mealType,
        date,
      );

      return mealAvailability?.isClosedOut;
    }),
  );

export const convertToIncludedMeal = (meal: Meal): IncludedMeal => ({
  ...meal,
  isIncluded: true,
});

const isMealAvailableOnAllDates = (
  { type, isIncluded }: Meal,
  propertyAvailability: PropertyAvailability,
  dates: string[],
): boolean =>
  dates.every((date) => {
    const mealAvailability = findPropertyAvailabilityMealAvailabilityByDate(
      propertyAvailability,
      type,
      date,
    );

    if (isIncluded) {
      return !mealAvailability?.isClosedOut;
    }

    return (
      mealAvailability &&
      !mealAvailability.isClosedOut &&
      mealAvailability.rate !== undefined
    );
  });

export const MEAL_TYPE_TRANSLATION_KEY_MAPPING: Record<MealType, string> = {
  [MealType.Breakfast]: 'breakfast',
  [MealType.Lunch]: 'lunch',
  [MealType.Dinner]: 'dinner',
};
