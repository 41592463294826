import { computed } from 'vue';
import { useAvailabilityCalendarStore } from '@/availability-calendar/availability-calendar.store';
import { useSearchStore } from '@/search/search.store';
import { useUnitSelectionStore } from '@/unit-selection-page/unit-selection.store';

export const useUnitSelectionSearchPropertyAvailabilityCalendar = () => {
  const searchStore = useSearchStore();
  const unitSelectionStore = useUnitSelectionStore();

  const { selectedStayDates } = useAvailabilityCalendarStore();

  const autoSelectStayDates = computed(
    () => searchStore.activePropertyOverStay.isAvailable,
  );

  const updateSearchDates = () => {
    void unitSelectionStore.updateStayDates(selectedStayDates.value!);
  };

  return {
    autoSelectStayDates,
    updateSearchDates,
  };
};
