import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { MealServiceType } from '@/property/meal/meal-service-type/meal-service-type';
import type { PresetUnitFacility } from '@/property/unit/preset-unit-facility/preset-unit-facility';
import { getPresetUnitFacilityTranslationKey } from '@/property/unit/preset-unit-facility/preset-unit-facility.utilities';
import type { Unit } from '@/property/unit/unit';

export const useUnit = (unit: Unit) => {
  const { t } = useI18n();

  const mealServiceTypeDisplayText = computed(() => {
    const { mealServiceType } = unit;

    if (mealServiceType === MealServiceType.Serviced) {
      return t('roomOnly');
    }

    if (mealServiceType === MealServiceType.SelfCatering) {
      return t('selfCatering');
    }

    return undefined;
  });

  const presetUnitFacilitiesDisplayText = computed(() => {
    const allUnitFacilities = [];
    // Consider room size as a unit facility
    if (unit.sizeSquareMeters) {
      allUnitFacilities.push(
        t('unitSizeMeterSquared', { unitSize: unit.sizeSquareMeters }),
      );
    }

    return allUnitFacilities
      .concat(
        unit.presetUnitFacilities.map(
          (presetUnitFacility: PresetUnitFacility): string => {
            const translationKey =
              getPresetUnitFacilityTranslationKey(presetUnitFacility);

            return translationKey ? t(translationKey) : presetUnitFacility.name;
          },
        ),
        unit.customFacilityNames,
      )
      .join(' • ');
  });

  return { mealServiceTypeDisplayText, presetUnitFacilitiesDisplayText };
};
