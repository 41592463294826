<template>
  <RouterView v-slot="{ Component, route }">
    <LoadingView v-if="isLoading" />

    <BookingStageLayout v-else>
      <Transition
        mode="out-in"
        enter-active-class="transition-opacity duration-200"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition-opacity duration-200"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
        @enter="resolveTransitionCompletePromise()"
        @before-leave="initializeTransitionCompletePromise(route)"
      >
        <KeepAlive>
          <Component :is="Component" />
        </KeepAlive>
      </Transition>
    </BookingStageLayout>
  </RouterView>
</template>

<script setup lang="ts">
import { useHead } from '@unhead/vue';
import { onMounted, ref } from 'vue';
import type { RouteRecordRaw } from 'vue-router';
import { useBookingStageStore } from '@/booking-stage/booking-stage.store';
import BookingStageLayout from '@/booking-stage/BookingStageLayout.vue';
import LoadingView from '@/loading-overlay/LoadingOverlay.vue';
import { useSearchStore } from '@/search/search.store';
import { useLocaleString } from '@/string/locale-string.composable';

const bookingStageStore = useBookingStageStore();
const searchStore = useSearchStore();

const { toTitleCase } = useLocaleString();

const isLoading = ref(true);

const initializeBookingStage = async () => {
  await bookingStageStore.initialize();

  isLoading.value = false;

  useHead({
    title: () =>
      toTitleCase(bookingStageStore.currentStage.title) +
      ' | ' +
      searchStore.activeProperty.name,
  });
};

let resolveTransitionCompletePromise: () => void;

const initializeTransitionCompletePromise = (route: RouteRecordRaw) => {
  if (route.meta) {
    route.meta.transitionCompletePromise = new Promise<void>((resolve) => {
      resolveTransitionCompletePromise = resolve;
    });
  }
};

onMounted(initializeBookingStage);
</script>
