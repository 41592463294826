import { computed } from 'vue';
import type { CustomAttributes } from '@/log/log.utilities';
import { logError as logBasicError } from '@/log/log.utilities';
import { useQueryParams } from '@/url/query-params/query-params.composable';

export const useLogger = () => {
  const queryParameters = useQueryParams();

  const additionalCustomAttributes = computed<CustomAttributes>(() => ({
    searchStayDates: JSON.stringify(
      queryParameters.searchStayDatesQueryParam.value,
    ),
    searchOccupancy: JSON.stringify(
      queryParameters.searchOccupanciesQueryParam.value,
    ),
    bookingItinerary: JSON.stringify(
      queryParameters.bookingItineraryQueryParam.value,
    ),
    bookingSource: JSON.stringify(
      queryParameters.bookingSourceQueryParam.value,
    ),
    locale: JSON.stringify(queryParameters.localeQueryParam.value),
  }));

  const logError = (error: Error, customAttributes?: CustomAttributes) => {
    logBasicError(error, {
      ...customAttributes,
      ...additionalCustomAttributes.value,
    });
  };

  return {
    logError,
  };
};
