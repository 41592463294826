import { isEqual, partition } from 'lodash-es';
import { defineStore } from 'pinia';
import type { Ref } from 'vue';
import { computed, ref, watch } from 'vue';
import type { PropertyAvailability } from '@/availability/property-availability/property-availability';
import { fetchPropertyAvailabilities } from '@/availability/property-availability/property-availability.api';
import {
  findPropertyAvailabilityByPropertyIdOrFail,
  getPropertyAvailabilityStayDates,
} from '@/availability/property-availability/property-availability.utilities';
import { useCodeResourceStore } from '@/code/resource/code-resource.store';
import { useFaviconSetter } from '@/favicon-setter/favicon-setter';
import type { Occupancy } from '@/occupancy/occupancy';
import { removeChildrenFromOccupancies } from '@/occupancy/occupancy.utilities';
import { useGoogleAnalyticsStore } from '@/property/google/analytics/google-analytics.store';
import { PaymentGatewayType } from '@/property/payment-gateway/payment-gateway';
import { usePropertyOverStay } from '@/property/property-over-stay/property-over-stay.composable';
import { useAccentColorCalculator } from '@/property/property-theme/accent-color/accent-color-calculator';
import { useRoute } from '@/router/route.composable';
import type { StayDates } from '@/stay-dates/stay-dates';
import { getStayLengthFromStayDates } from '@/stay-dates/stay-dates.utilities';
import { useQueryParams } from '@/url/query-params/query-params.composable';
import type { SearchUserEventAction } from '@/user-event/user-event';
import { UserEventActionLabel } from '@/user-event/user-event';
import { useUserEventStore } from '@/user-event/user-event.store';
import type { Widget } from '@/widget/widget';
import { fetchWidget } from '@/widget/widget.api';
import {
  findWidgetOnlineProperties,
  findWidgetOnlinePropertyByIdOrFail,
  findWidgetPrimaryPropertyOrFail,
} from '@/widget/widget.utilities';

export const useSearchStore = defineStore('search', () => {
  const { widgetIdRouteParam, propertyIdRouteParam } = useRoute();

  const { searchStayDatesQueryParam, searchOccupanciesQueryParam } =
    useQueryParams();

  const { resetFavicon, setPropertyThemeLogoFavicon } = useFaviconSetter();

  const codeResourceStore = useCodeResourceStore();
  const { trackUserEventAction } = useUserEventStore();
  const { emitGASearchEvent } = useGoogleAnalyticsStore();

  const widget = ref<Widget>() as Ref<Widget>;

  const stayDates = ref<StayDates>() as Ref<StayDates>;

  const occupancies = ref<Occupancy[]>([]);

  const propertyAvailabilities = ref<PropertyAvailability[]>([]);

  const isInitialized = ref(false);

  const isLoading = ref(false);

  const isUpdatingProperty = ref(false);

  const activePropertyId = computed({
    get() {
      return propertyIdRouteParam.value ?? primaryProperty.value.id;
    },
    set(activePropertyId) {
      propertyIdRouteParam.value = activePropertyId;
    },
  });

  const stayLength = computed(() =>
    getStayLengthFromStayDates(stayDates.value),
  );

  const onlineProperties = computed(() =>
    findWidgetOnlineProperties(widget.value),
  );

  const primaryProperty = computed(() =>
    findWidgetPrimaryPropertyOrFail(widget.value),
  );

  const activeProperty = computed(() =>
    findWidgetOnlinePropertyByIdOrFail(widget.value, activePropertyId.value),
  );

  const activePropertyAvailability = computed(() =>
    findPropertyAvailabilityByPropertyIdOrFail(
      propertyAvailabilities.value,
      activeProperty.value.id,
    ),
  );

  const activePropertyCanUseExpressCheckout = computed(
    () =>
      activeProperty.value.canUseExpressCheckout &&
      activeProperty.value.paymentGateway.type === PaymentGatewayType.Stripe,
  );

  const activePropertyOverStay = usePropertyOverStay(
    activeProperty,
    activePropertyAvailability,
    computed(() => codeResourceStore.resource),
  );

  const additionalProperties = computed(() =>
    onlineProperties.value.filter(({ id }) => id !== activeProperty.value.id),
  );

  const additionalPropertiesOverStay = computed(() =>
    additionalProperties.value.map((property) => {
      const propertyAvailability = findPropertyAvailabilityByPropertyIdOrFail(
        propertyAvailabilities.value,
        property.id,
      );

      return usePropertyOverStay(ref(property), ref(propertyAvailability));
    }),
  );

  const availabilityPartitionedAdditionalPropertiesOverStay = computed(() =>
    partition(
      additionalPropertiesOverStay.value,
      ({ isAvailable }) => isAvailable,
    ),
  );

  const availableAdditionalPropertiesOverStay = computed(
    () => availabilityPartitionedAdditionalPropertiesOverStay.value[0],
  );

  const unavailableAdditionalPropertiesOverStay = computed(
    () => availabilityPartitionedAdditionalPropertiesOverStay.value[1],
  );

  const hasAdditionalProperties = computed(
    () => additionalProperties.value.length > 0,
  );

  const hasAvailableAdditionalProperties = computed(
    () => availableAdditionalPropertiesOverStay.value.length > 0,
  );

  const hasUnavailableAdditionalProperties = computed(
    () => unavailableAdditionalPropertiesOverStay.value.length > 0,
  );

  const initialize = async () => {
    if (isInitialized.value) {
      return;
    }

    const initialCheckInDate = searchStayDatesQueryParam.value?.checkInDate;

    await Promise.all([
      loadWidget(widgetIdRouteParam.value),
      loadPropertyAvailabilities(
        widgetIdRouteParam.value,
        initialCheckInDate,
        searchStayDatesQueryParam.value?.checkOutDate,
      ),
    ]);

    stayDates.value = getPropertyAvailabilityStayDates(
      activePropertyAvailability.value,
    );

    occupancies.value = searchOccupanciesQueryParam.value ?? [
      activeProperty.value.defaultSearchOccupancy,
    ];

    useAccentColorCalculator(activeProperty);

    watchPropertyChanges();

    trackUserEventAction(
      getSearchUserEventAction(
        initialCheckInDate === stayDates.value.checkInDate,
      ),
    );

    isInitialized.value = true;
  };

  const loadWidget = async (widgetId: string) => {
    widget.value = await fetchWidget(widgetId);
  };

  const loadPropertyAvailabilities = async (
    widgetId: string,
    fromDate: string | undefined,
    toDate: string | undefined,
  ) => {
    propertyAvailabilities.value = await fetchPropertyAvailabilities(widgetId, {
      fromDate,
      toDate,
    });
  };

  const refreshPropertyAvailabilitiesWithLoadingFlag = async () => {
    isLoading.value = true;

    await refreshPropertyAvailabilities();

    isLoading.value = false;
  };

  const refreshPropertyAvailabilities = async () => {
    await loadPropertyAvailabilities(
      widget.value.id,
      stayDates.value.checkInDate,
      stayDates.value.checkOutDate,
    );
  };

  const updateActiveProperty = async (
    propertyId: number,
    stayDates?: StayDates,
  ) => {
    isUpdatingProperty.value = true;

    activePropertyId.value = propertyId;

    if (stayDates) {
      // Update stay dates *after* switching property to track search analytics event on new property
      await updateStayDates(stayDates);
    } else {
      trackUserEventAction(getSearchUserEventAction());

      await simulateLoading(300);
    }

    isUpdatingProperty.value = false;
  };

  const updateStayDates = async (updatedStayDates: StayDates) => {
    if (isEqual(updatedStayDates, stayDates.value)) {
      return;
    }

    stayDates.value = updatedStayDates;

    await refreshPropertyAvailabilitiesWithLoadingFlag();

    trackUserEventAction(getSearchUserEventAction());

    void emitGASearchEvent(
      activeProperty.value.googleAnalytics,
      stayDates.value,
    );
  };

  const updateOccupancies = async (updatedOccupancies: Occupancy[]) => {
    occupancies.value = updatedOccupancies;

    await simulateLoading(300);

    trackUserEventAction(getSearchUserEventAction());
  };

  const simulateLoading = async (durationMs: number) => {
    isLoading.value = true;

    await new Promise<void>((resolve) =>
      setTimeout(() => {
        isLoading.value = false;

        resolve();
      }, durationMs),
    );
  };

  const getSearchUserEventAction = (
    isUserAcknowledgedSearch = true,
  ): SearchUserEventAction => ({
    label: UserEventActionLabel.Search,
    metadata: {
      check_in_date: stayDates.value.checkInDate,
      check_out_date: stayDates.value.checkOutDate,
      did_find_availability: activePropertyOverStay.isAvailable,
      widget_id: widget.value.id,
      property_id: activePropertyId.value,
      is_user_acknowledged: isUserAcknowledgedSearch,
    },
  });

  const watchPropertyChanges = () => {
    watch(
      activeProperty,
      (property) => {
        if (property.propertyTheme.logo) {
          setPropertyThemeLogoFavicon(property.propertyTheme.logo);
        } else {
          resetFavicon();
        }

        if (!property.childPolicy.childrenAreAllowed) {
          occupancies.value = removeChildrenFromOccupancies(occupancies.value);
        }
      },
      { immediate: true },
    );
  };

  watch(stayDates, (stayDates) => {
    searchStayDatesQueryParam.value = stayDates;
  });

  watch(occupancies, (occupancies) => {
    searchOccupanciesQueryParam.value = occupancies;
  });

  return {
    widget,
    propertyAvailabilities,
    primaryProperty,
    activePropertyId,
    activeProperty,
    activePropertyAvailability,
    activePropertyCanUseExpressCheckout,
    activePropertyOverStay,
    additionalProperties,
    availableAdditionalPropertiesOverStay,
    unavailableAdditionalPropertiesOverStay,
    hasAdditionalProperties,
    hasAvailableAdditionalProperties,
    hasUnavailableAdditionalProperties,
    stayDates,
    occupancies,
    stayLength,
    isLoading,
    isUpdatingProperty,
    initialize,
    refreshPropertyAvailabilitiesWithLoadingFlag,
    updateActiveProperty,
    updateStayDates,
    updateOccupancies,
  };
});
