import { defineStore } from 'pinia';
import type { ComputedRef } from 'vue';
import { computed, ref } from 'vue';
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import type { MandatoryExtra } from '@/property/extra/extra';
import { ExtraChargeType } from '@/property/extra/extra';
import {
  calculateTotalRunningRateForAllNightsExtra,
  calculateTotalRunningRateForSingleNightExtra,
} from '@/property/extra/extra.utilities';
import { getStayLengthFromStayDates } from '@/stay-dates/stay-dates.utilities';

export const useMandatoryExtraItineraryItemStore = (extra: MandatoryExtra) =>
  defineStore(`mandatory-extra-itinerary-item-${extra.id}`, () => {
    const bookingItineraryStore = useBookingItineraryStore();

    const quantity = computed(() => 1);

    const numberOfNightsApplied: ComputedRef<number> = computed(() =>
      extra.chargeType === ExtraChargeType.AllNightsOfStay
        ? getStayLengthFromStayDates(bookingItineraryStore.stayDates!)
        : 1,
    );

    const answer = ref<string>();

    const totalRate: ComputedRef<number> = computed(() => calculateTotalRate());

    const calculateTotalRate = () => {
      switch (extra.chargeType) {
        case ExtraChargeType.AllNightsOfStay:
          return calculateTotalRunningRateForAllNightsExtra(
            extra,
            quantity.value,
            bookingItineraryStore.stayDates!,
          );
        case ExtraChargeType.Single:
          return calculateTotalRunningRateForSingleNightExtra(
            extra,
            quantity.value,
          );
      }
    };

    return {
      extra,
      answer,
      totalRate,
      quantity,
      numberOfNightsApplied,
    };
  })();
