import type {
  PropertyVanityBackgroundImage,
  PropertyVanityBackgroundImageDTO,
} from '@/property/property-vanity/background/property-vanity-background-image';

export const mapPropertyVanityBackgroundImageFromDTO = (
  propertyVanityBackgroundImageDTO: PropertyVanityBackgroundImageDTO,
): PropertyVanityBackgroundImage => ({
  url: propertyVanityBackgroundImageDTO.url,
});
