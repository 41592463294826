import type { PropertyAvailability } from '@/availability/property-availability/property-availability';
import type { Occupancy } from '@/occupancy/occupancy';
import type { PrivateRateOverride } from '@/private-rate-override/private-rate-override';
import type { IncludedMeal } from '@/property/meal/included-meal/included-meal';
import type { SupplementalMeal } from '@/property/meal/supplemental-meal/supplemental-meal';
import type { Offer } from '@/property/offer/offer';
import { OfferType } from '@/property/offer/offer';
import {
  offerAppliesToUnit,
  offerAppliesToWayToSell,
} from '@/property/offer/offer.utilities';
import type { Promocode } from '@/property/promocode/promocode';
import type { Unit } from '@/property/unit/unit';
import type { WayToSell } from '@/property/way-to-sell/way-to-sell';
import { applyWayToSellToNightlyRates } from '@/property/way-to-sell/way-to-sell.utilities';
import { createStandardMealsRates } from '@/rates/meal-rates/meal-rates.utilities';
import type { NightlyRates } from '@/rates/nightly-rates/nightly-rates';
import { areAllNightlyRatesPositive } from '@/rates/nightly-rates/nightly-rates.utilities';
import type { OfferRates } from '@/rates/offer-rates/offer-rates';
import {
  calculateDiscountOfferRatesOnNightlyRates,
  calculateFreeNightOfferRatesOnNightlyRates,
  calculatePackageOfferWayToSellRatesOnNightlyRates,
} from '@/rates/offer-rates/offer-rates.utilities';
import { calculatePrivateRatesOnWayToSellNightlyRates } from '@/rates/private-rates/private-rates.utilities';
import { createPromocodeRates } from '@/rates/promocode-rates/promocode-rates.utilities';
import type { WayToSellRates } from '@/rates/way-to-sell-rates/way-to-sell-rates';
import type { StayDates } from '@/stay-dates/stay-dates';

export const createWaysToSellRates = (
  unit: Unit,
  waysToSell: WayToSell[],
  unitNightlyRates: NightlyRates,
  promocode: Promocode | undefined,
  privateRateOverride: PrivateRateOverride | undefined,
  includedUnitMeals: IncludedMeal[],
  supplementalUnitMeals: SupplementalMeal[],
  offers: Offer[],
  propertyAvailability: PropertyAvailability,
  stayDates: StayDates,
  occupancy: Occupancy,
): WayToSellRates[] =>
  waysToSell.reduce<WayToSellRates[]>((waysToSellRates, wayToSell) => {
    const wayToSellRates = createWayToSellRates(
      wayToSell,
      offers,
      promocode,
      privateRateOverride,
      includedUnitMeals,
      supplementalUnitMeals,
      unit,
      unitNightlyRates,
      propertyAvailability,
      stayDates,
      occupancy,
    );

    if (!areAllNightlyRatesPositive(wayToSellRates.nightlyRates)) {
      return waysToSellRates;
    }

    waysToSellRates.push(wayToSellRates);

    return waysToSellRates;
  }, []);

const createWayToSellRates = (
  wayToSell: WayToSell,
  offers: Offer[],
  promocode: Promocode | undefined,
  privateRateOverride: PrivateRateOverride | undefined,
  includedUnitMeals: IncludedMeal[],
  supplementalUnitMeals: SupplementalMeal[],
  unit: Unit,
  unitNightlyRates: NightlyRates,
  propertyAvailability: PropertyAvailability,
  stayDates: StayDates,
  occupancy: Occupancy,
): WayToSellRates => {
  const standardNightlyRates = applyWayToSellToNightlyRates(
    wayToSell,
    unitNightlyRates,
    propertyAvailability,
  );

  const privateRates = privateRateOverride
    ? calculatePrivateRatesOnWayToSellNightlyRates(
        privateRateOverride,
        wayToSell.id,
        standardNightlyRates,
      )
    : undefined;

  const standardMealsRates = createStandardMealsRates(
    supplementalUnitMeals,
    propertyAvailability,
    stayDates,
    occupancy,
  );

  const offersRates = createOffersRates(
    wayToSell.id,
    standardNightlyRates,
    unit,
    offers,
    promocode,
    propertyAvailability,
    stayDates,
    occupancy,
    supplementalUnitMeals,
  );

  const standardPromocodeRates = promocode
    ? createPromocodeRates(promocode, standardNightlyRates)
    : undefined;

  return {
    wayToSell,
    nightlyRates: standardNightlyRates,
    privateRates,
    offersRates,
    promocodeRates: standardPromocodeRates,
    mealsRates: standardMealsRates,
    includedMeals: includedUnitMeals,
  };
};

const createOffersRates = (
  wayToSellId: number,
  standardNightlyRates: NightlyRates,
  unit: Unit,
  offers: Offer[],
  promocode: Promocode | undefined,
  propertyAvailability: PropertyAvailability,
  stayDates: StayDates,
  occupancy: Occupancy,
  supplementalUnitMeals: SupplementalMeal[],
): OfferRates[] =>
  offers.reduce<OfferRates[]>((offersRates, offer) => {
    const offerRates = calculateWayToSellOfferRatesOnNightlyRates(
      unit,
      wayToSellId,
      standardNightlyRates,
      offer,
      promocode,
      propertyAvailability,
      stayDates,
      occupancy,
      supplementalUnitMeals,
    );

    if (offerRates === undefined) {
      return offersRates;
    }

    offersRates.push(offerRates);

    return offersRates;
  }, []);

export const calculateWayToSellOfferRatesOnNightlyRates = (
  unit: Unit,
  wayToSellId: number,
  standardNightlyRates: NightlyRates,
  offer: Offer,
  promocode: Promocode | undefined,
  propertyAvailability: PropertyAvailability,
  stayDates: StayDates,
  occupancy: Occupancy,
  supplementalUnitMeals: SupplementalMeal[],
): OfferRates | undefined => {
  if (
    !offerAppliesToUnit(offer, unit) ||
    !offerAppliesToWayToSell(offer, wayToSellId)
  ) {
    return undefined;
  }

  switch (offer.offerType) {
    case OfferType.Discount:
      return calculateDiscountOfferRatesOnNightlyRates(
        unit.id,
        standardNightlyRates,
        offer,
        promocode,
        propertyAvailability,
        stayDates,
        occupancy,
        supplementalUnitMeals,
      );
    case OfferType.FreeNight:
      return calculateFreeNightOfferRatesOnNightlyRates(
        standardNightlyRates,
        offer,
        promocode,
        propertyAvailability,
        stayDates,
        occupancy,
        supplementalUnitMeals,
      );
    case OfferType.Package:
      return calculatePackageOfferWayToSellRatesOnNightlyRates(
        wayToSellId,
        standardNightlyRates,
        offer,
      );
  }
};
