<template>
  <AppListbox v-model="selectedNumberOfNights">
    <div class="flex items-center justify-between">
      <div class="mr-2 first-letter:capitalize">
        <AppListboxLabel :text="$t('pleaseSelectNumberOfNights')" />
      </div>

      <div>
        <div ref="listboxOptionsAnchor">
          <AppListboxButton
            class="border border-slate-900 py-2"
            :text="convertNumberOfNightsToLabel(selectedNumberOfNights)"
          />
        </div>

        <AppListboxOptions :anchor="listboxOptionsAnchor">
          <AppListboxOption
            v-for="numberOfNightsOption in numberOfNightsOptions"
            :key="numberOfNightsOption"
            :value="numberOfNightsOption"
            :label="convertNumberOfNightsToLabel(numberOfNightsOption)"
          />
        </AppListboxOptions>
      </div>
    </div>
  </AppListbox>
</template>

<script setup lang="ts">
import { range } from 'lodash-es';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useSelectableNightExtraItineraryItemStore } from '@/booking-itinerary/extra-itinerary/store/selectable-night-extra-itinerary-item.store';
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import type { SelectableNightExtra } from '@/property/extra/extra';
import { getStayLengthFromStayDates } from '@/stay-dates/stay-dates.utilities';
import AppListbox from '@/ui/app-listbox/AppListbox.vue';
import AppListboxButton from '@/ui/app-listbox/AppListboxButton.vue';
import AppListboxLabel from '@/ui/app-listbox/AppListboxLabel.vue';
import AppListboxOption from '@/ui/app-listbox/AppListboxOption.vue';
import AppListboxOptions from '@/ui/app-listbox/AppListboxOptions.vue';

const MINIMUM_NUMBER_NIGHTS = 1;

const props = defineProps<{
  extra: SelectableNightExtra;
}>();

const { t } = useI18n();

const bookingItineraryStore = useBookingItineraryStore();
const extraItineraryItemStore = useSelectableNightExtraItineraryItemStore(
  props.extra,
);

const stayLength = computed(() =>
  getStayLengthFromStayDates(bookingItineraryStore.stayDates!),
);

const numberOfNightsOptions = computed(() =>
  range(MINIMUM_NUMBER_NIGHTS, stayLength.value + 1),
);

const selectedNumberOfNights = computed({
  get() {
    return extraItineraryItemStore.numberOfNightsApplied;
  },
  set(numberOfNightsSelected) {
    extraItineraryItemStore.numberOfNightsApplied = numberOfNightsSelected;
  },
});

const convertNumberOfNightsToLabel = (numberOfNights: number): string =>
  t('countNights', { count: numberOfNights });

const listboxOptionsAnchor = ref<HTMLDivElement | null>(null);
</script>
