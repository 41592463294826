<template>
  <div>
    <!--
      Here we are effectively extending the width of whatever container we are given to provide room
      for the "wrapping" triangles. Note that all the space will be "added" on the right-hand side.
      Consequently, we may need to "reset" the position back to the center by translating the
      distance of one triangle leftwards. Note this reset is only needed when wrapping on the
      left-hand side, otherwise the banner is automatically shifted to the correct position.
    -->
    <div
      :class="[
        { '-translate-x-1': shouldWrapLeftSide },
        sideToWrap === 'both'
          ? 'w-[calc(100%+(theme(spacing.2)))]'
          : 'w-[calc(100%+(theme(spacing.1)))]',
      ]"
    >
      <div class="flex justify-between">
        <div>
          <AppBannerWrappingTriangle v-if="shouldWrapLeftSide" wrap-left-side />
        </div>

        <div>
          <AppBannerWrappingTriangle
            v-if="shouldWrapRightSide"
            :wrap-left-side="false"
          />
        </div>
      </div>

      <div
        class="bg-white px-2.5 py-1.5 text-center drop-shadow-lg"
        :class="{
          'rounded-l-lg': !shouldWrapLeftSide,
          'rounded-r-lg': !shouldWrapRightSide,
        }"
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import AppBannerWrappingTriangle from '@/ui/app-banner/AppBannerWrappingTriangle.vue';

type Side = 'left' | 'right' | 'both';

const props = withDefaults(
  defineProps<{
    sideToWrap?: Side;
  }>(),
  {
    sideToWrap: 'right',
  },
);

const sidesToWrap = computed(() =>
  props.sideToWrap === 'both' ? ['left', 'right'] : [props.sideToWrap],
);

const shouldWrapLeftSide = computed(() => shouldWrapSide('left'));

const shouldWrapRightSide = computed(() => shouldWrapSide('right'));

const shouldWrapSide = (side: Side): boolean =>
  sidesToWrap.value.includes(side);
</script>
