export enum BookingCreateProcessingErrorType {
  PropertyUnavailable = 'property_unavailable',
  UnitUnavailable = 'unit_unavailable',
  RateMismatch = 'rate_mismatch',
  InvalidRecaptchaToken = 'invalid_recaptcha_token',
  Other = 'other',
}

export type BookingCreateProcessingError =
  | {
      type: BookingCreateProcessingErrorType.PropertyUnavailable;
    }
  | {
      type: BookingCreateProcessingErrorType.UnitUnavailable;
      unitId: number;
    }
  | {
      type: BookingCreateProcessingErrorType.RateMismatch;
    }
  | {
      type: BookingCreateProcessingErrorType.InvalidRecaptchaToken;
    }
  | {
      type: BookingCreateProcessingErrorType.Other;
    };
