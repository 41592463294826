import type { Ref } from 'vue';
import { computed, reactive } from 'vue';
import type { BookingPolicy } from '@/property/booking-policy/booking-policy';
import { getBookingPolicyApplicableCancellationPolicyForStayDates } from '@/property/booking-policy/booking-policy.utilities';
import { CancellationPolicyType } from '@/property/booking-policy/cancellation-policy/cancellation-policy';
import { getCancellationPolicyEndDate } from '@/property/booking-policy/cancellation-policy/cancellation-policy.utilities';
import { stayDatesIncurNightlyCancellationPolicyFullCharge } from '@/property/booking-policy/cancellation-policy/nightly/nightly-cancellation-policy.utilities';
import type { CancellationPolicyOverStay } from '@/property/booking-policy/cancellation-policy/over-stay/cancellation-policy-over-stay';
import type { Property } from '@/property/property';
import type { StayDates } from '@/stay-dates/stay-dates';

export const useCancellationPolicyOverStay = (
  property: Ref<Property>,
  bookingPolicy: Ref<BookingPolicy>,
  stayDates: Ref<StayDates>,
): CancellationPolicyOverStay => {
  const cancellationPolicy = computed(() =>
    getBookingPolicyApplicableCancellationPolicyForStayDates(
      bookingPolicy.value,
      stayDates.value,
      property.value.timezone,
    ),
  );

  const isFree = computed(
    () =>
      cancellationPolicy.value.cancellationType === CancellationPolicyType.Free,
  );

  const rendersBookingNonRefundable = computed(() => {
    switch (cancellationPolicy.value.cancellationType) {
      case CancellationPolicyType.Percentage:
        return cancellationPolicy.value.percentage === 100;
      case CancellationPolicyType.Nightly:
        return stayDatesIncurNightlyCancellationPolicyFullCharge(
          cancellationPolicy.value,
          stayDates.value,
        );
    }

    return false;
  });

  const cutOffDate = computed(() =>
    getCancellationPolicyEndDate(
      cancellationPolicy.value,
      stayDates.value.checkInDate,
    ),
  );

  return reactive({
    isFree,
    rendersBookingNonRefundable,
    cutOffDate,
  });
};
