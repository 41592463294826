import { AdditionalTaxRateChargeType } from '@/tax/additional/rate/charge/additional-tax-rate-charge';
import type {
  AdditionalTaxRateMonetaryCharge,
  AdditionalTaxRateMonetaryChargeDTO,
} from '@/tax/additional/rate/charge/monetary/additional-tax-rate-monetary-charge';

export const mapAdditionalTaxRateMonetaryChargeFromDTO = (
  additionalTaxRateMonetaryChargeDTO: AdditionalTaxRateMonetaryChargeDTO,
): AdditionalTaxRateMonetaryCharge => ({
  type: AdditionalTaxRateChargeType.Monetary,
  amount: Number(additionalTaxRateMonetaryChargeDTO.amount),
});
