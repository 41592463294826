import type {
  NoShowPolicyCharge,
  NoShowPolicyChargeDTO,
} from '@/property/booking-policy/no-show-policy/charge/no-show-policy-charge';
import { NoShowPolicyChargeType } from '@/property/booking-policy/no-show-policy/charge/no-show-policy-charge';

export const mapNoShowPolicyChargeFromDTO = (
  noShowPolicyChargeDTO: NoShowPolicyChargeDTO,
): NoShowPolicyCharge => {
  const { chargeType } = noShowPolicyChargeDTO;

  switch (chargeType) {
    case NoShowPolicyChargeType.Free:
      return { chargeType: NoShowPolicyChargeType.Free };
    case NoShowPolicyChargeType.Full:
      return { chargeType: NoShowPolicyChargeType.Full };
    case NoShowPolicyChargeType.Nightly:
      return {
        chargeType: NoShowPolicyChargeType.Nightly,
        numberOfNights: noShowPolicyChargeDTO.numberOfNights,
      };
    case NoShowPolicyChargeType.Monetary:
      return {
        chargeType: NoShowPolicyChargeType.Monetary,
        amount: Number(noShowPolicyChargeDTO.amount),
      };
    case NoShowPolicyChargeType.Percentage:
      return {
        chargeType: NoShowPolicyChargeType.Percentage,
        percentage: Number(noShowPolicyChargeDTO.percentage),
      };
    case NoShowPolicyChargeType.PerUnitMonetary:
      return {
        chargeType: NoShowPolicyChargeType.PerUnitMonetary,
        amountPerUnit: Number(noShowPolicyChargeDTO.amountPerUnit),
      };
  }
};
