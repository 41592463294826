import type {
  AdvancedPaymentPolicy,
  AdvancedPaymentPolicyDTO,
} from '@/property/booking-policy/advanced-payment-policy/advanced-payment-policy';
import { AdvancedPaymentPolicyType } from '@/property/booking-policy/advanced-payment-policy/advanced-payment-policy';

export const mapAdvancedPaymentPolicyFromDTO = (
  advancedPaymentPolicyDTO: AdvancedPaymentPolicyDTO,
): AdvancedPaymentPolicy => {
  const { advancedPaymentType } = advancedPaymentPolicyDTO;

  switch (advancedPaymentType) {
    case AdvancedPaymentPolicyType.OnArrival:
      return { advancedPaymentType };
    case AdvancedPaymentPolicyType.OnDeparture:
      return { advancedPaymentType };
    case AdvancedPaymentPolicyType.BeforeArrival:
      return {
        advancedPaymentType,
        numberOfDaysBeforeArrival:
          advancedPaymentPolicyDTO.numberOfDaysBeforeArrival,
      };
  }
};
