import type { PropertyAvailability } from '@/availability/property-availability/property-availability';
import type { FreeNightOffer } from '@/property/offer/free-night-offer/free-night-offer';
import { FreeNightOfferType } from '@/property/offer/free-night-offer/free-night-offer';
import { OfferApplicableStayDaysType } from '@/property/offer/offer';
import {
  doesWayToSellApplyToOffer,
  isDateApplicableOfferDayOfWeek,
  isDateWithinOfferFromToBounds,
  isOfferClosedOutOnAnyDate,
  isOfferClosedOutOnDate,
} from '@/property/offer/offer.utilities';
import type { NightlyRates } from '@/rates/nightly-rates/nightly-rates';
import {
  sortNightlyRatesByDate,
  sortNightlyRatesByRate,
} from '@/rates/nightly-rates/nightly-rates.utilities';
import type { StayDates } from '@/stay-dates/stay-dates';
import {
  getNightsOfStayFromStayDates,
  getStayLengthFromStayDates,
} from '@/stay-dates/stay-dates.utilities';

export const freeNightOfferAppliesToUnit = (
  { applicableUnitIds }: FreeNightOffer,
  unitId: number,
): boolean =>
  applicableUnitIds.some((applicableUnitId) => applicableUnitId === unitId);

export const freeNightOfferAppliesToWayToSell = (
  freeNightOffer: FreeNightOffer,
  wayToSellId: number,
): boolean => doesWayToSellApplyToOffer(freeNightOffer, wayToSellId);

export const isFreeNightOfferApplicableOnStayDates = (
  freeNightOffer: FreeNightOffer,
  propertyAvailability: PropertyAvailability,
  stayDates: StayDates,
): boolean =>
  stayLengthIsApplicableToFreeNightOffer(
    freeNightOffer,
    getStayLengthFromStayDates(stayDates),
  ) &&
  isFreeNightOfferApplicableForStayDaysType(
    freeNightOffer,
    propertyAvailability,
    stayDates,
  );

const isFreeNightOfferApplicableForStayDaysType = (
  freeNightOffer: FreeNightOffer,
  propertyAvailability: PropertyAvailability,
  stayDates: StayDates,
): boolean => {
  switch (freeNightOffer.applicableStayDaysType) {
    case OfferApplicableStayDaysType.Arrivals:
      return isFreeNightOfferApplicableForArrivalsType(
        freeNightOffer,
        propertyAvailability,
        stayDates,
      );
    case OfferApplicableStayDaysType.NightOfStay:
      return isFreeNightOfferApplicableForNightsOfStayType(
        freeNightOffer,
        propertyAvailability,
        stayDates,
      );
  }
};

const isFreeNightOfferApplicableForNightsOfStayType = (
  freeNightOffer: FreeNightOffer,
  propertyAvailability: PropertyAvailability,
  stayDates: StayDates,
): boolean =>
  getNightsOfStayFromStayDates(stayDates).every(
    (nightOfStay) =>
      isDateWithinOfferFromToBounds(freeNightOffer, nightOfStay) &&
      isDateApplicableOfferDayOfWeek(freeNightOffer, nightOfStay) &&
      !isOfferClosedOutOnDate(
        freeNightOffer,
        propertyAvailability,
        nightOfStay,
      ),
  );

const isFreeNightOfferApplicableForArrivalsType = (
  freeNightOffer: FreeNightOffer,
  propertyAvailability: PropertyAvailability,
  stayDates: StayDates,
): boolean =>
  isDateWithinOfferFromToBounds(freeNightOffer, stayDates.checkInDate) &&
  isDateApplicableOfferDayOfWeek(freeNightOffer, stayDates.checkInDate) &&
  !isOfferClosedOutOnAnyDate(
    freeNightOffer,
    propertyAvailability,
    getNightsOfStayFromStayDates(stayDates),
  );

export const applyFreeNightOfferToNightlyRates = (
  { freeNightType, numberOfFreeNights }: FreeNightOffer,
  nightlyRates: NightlyRates,
): NightlyRates => {
  let freeNightTypeSortedNightlyRates: NightlyRates;

  switch (freeNightType) {
    case FreeNightOfferType.Cheapest:
      freeNightTypeSortedNightlyRates = sortNightlyRatesByRate(nightlyRates);
      break;
    case FreeNightOfferType.Last:
      freeNightTypeSortedNightlyRates = sortNightlyRatesByDate(nightlyRates);
      break;
  }

  return nightlyRates.map((nightlyRate) =>
    freeNightTypeSortedNightlyRates.findIndex(
      (freeNightTypeSortedNightlyRate) =>
        freeNightTypeSortedNightlyRate.date === nightlyRate.date,
    ) < numberOfFreeNights
      ? {
          ...nightlyRate,
          rate: 0,
          isFreeNight: true,
        }
      : nightlyRate,
  );
};

const stayLengthIsApplicableToFreeNightOffer = (
  { applicableStayDurations }: FreeNightOffer,
  stayLength: number,
): boolean => applicableStayDurations.includes(stayLength);
