import { mapChildMealRateAdjustmentsFromDTOs } from '@/property/meal/meal-rate-adjustment/child-meal-rate-adjustment/child-meal-rate-adjustment.mapper';
import type {
  SupplementalMeal,
  SupplementalMealDTO,
} from '@/property/meal/supplemental-meal/supplemental-meal';

export const mapSupplementalMealFromDTO = (
  supplementalMealDTO: SupplementalMealDTO,
): SupplementalMeal => ({
  isIncluded: false,
  type: supplementalMealDTO.type,
  name: supplementalMealDTO.name,
  childMealRateAdjustments: mapChildMealRateAdjustmentsFromDTOs(
    supplementalMealDTO.childMealRateAdjustments,
  ),
});
