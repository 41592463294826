import type { PropertyAvailability } from '@/availability/property-availability/property-availability';
import type { IncludedMeal } from '@/property/meal/included-meal/included-meal';
import {
  convertToIncludedMeal,
  isAnyMealTypeClosedOutOnAnyStayDate,
} from '@/property/meal/meal.utilities';
import { OfferApplicableStayDaysType } from '@/property/offer/offer';
import {
  isStayLengthWithinOfferBounds,
  isAdvanceBookingDateWithinOfferBounds,
  isDateApplicableOfferDayOfWeek,
  isDateWithinOfferFromToBounds,
  isOfferClosedOutOnDate,
  isOfferClosedOutOnAnyDate,
} from '@/property/offer/offer.utilities';
import type { PackageOffer } from '@/property/offer/package-offer/package-offer';
import type { PackageOfferNightlyUnitRate } from '@/property/offer/package-offer/package-offer-nightly-rate/package-offer-nightly-unit-rate/package-offer-nightly-unit-rate';
import type { PackageOfferNightlyWayToSellRate } from '@/property/offer/package-offer/package-offer-nightly-rate/package-offer-nightly-way-to-sell-rate/package-offer-nightly-way-to-sell-rate';
import type { Property } from '@/property/property';
import { findPropertyMealsByMealTypes } from '@/property/property.utilities';
import type { NightlyRates } from '@/rates/nightly-rates/nightly-rates';
import { adjustNightlyRatesByRate } from '@/rates/nightly-rates/nightly-rates.utilities';
import type { StayDates } from '@/stay-dates/stay-dates';
import {
  getNightsOfStayFromStayDates,
  getStayLengthFromStayDates,
} from '@/stay-dates/stay-dates.utilities';

export const findPackageOfferIncludedPropertyMeals = (
  { includedMealTypes }: PackageOffer,
  property: Property,
): IncludedMeal[] =>
  findPropertyMealsByMealTypes(property, includedMealTypes).map(
    convertToIncludedMeal,
  );

export const packageOfferAppliesToUnit = (
  packageOffer: PackageOffer,
  unitId: number,
): boolean => !!findPackageOfferNightlyUnitRateByUnitId(packageOffer, unitId);

export const packageOfferAppliesToWayToSell = (
  packageOffer: PackageOffer,
  wayToSellId: number,
): boolean =>
  !!findPackageOfferNightlyWayToSellRateByWayToSellId(
    packageOffer,
    wayToSellId,
  );

export const isPackageOfferApplicableOnStayDates = (
  packageOffer: PackageOffer,
  propertyAvailability: PropertyAvailability,
  stayDates: StayDates,
  timezone: string,
): boolean =>
  isStayLengthWithinOfferBounds(
    packageOffer,
    getStayLengthFromStayDates(stayDates),
  ) &&
  isAdvanceBookingDateWithinOfferBounds(
    packageOffer,
    stayDates.checkInDate,
    timezone,
  ) &&
  !isAnyPackageMealClosedOutOnAnyStayDate(
    packageOffer,
    propertyAvailability,
    stayDates,
  ) &&
  isPackageOfferApplicableForStayDaysType(
    packageOffer,
    propertyAvailability,
    stayDates,
  );

const isAnyPackageMealClosedOutOnAnyStayDate = (
  { includedMealTypes }: PackageOffer,
  propertyAvailability: PropertyAvailability,
  stayDates: StayDates,
): boolean =>
  isAnyMealTypeClosedOutOnAnyStayDate(
    includedMealTypes,
    stayDates,
    propertyAvailability,
  );

const isPackageOfferApplicableForStayDaysType = (
  packageOffer: PackageOffer,
  propertyAvailability: PropertyAvailability,
  stayDates: StayDates,
): boolean => {
  switch (packageOffer.applicableStayDaysType) {
    case OfferApplicableStayDaysType.Arrivals:
      return isPackageOfferApplicableForArrivalsType(
        packageOffer,
        propertyAvailability,
        stayDates,
      );
    case OfferApplicableStayDaysType.NightOfStay:
      return isPackageOfferApplicableForNightsOfStayType(
        packageOffer,
        propertyAvailability,
        stayDates,
      );
  }
};

const isPackageOfferApplicableForNightsOfStayType = (
  packageOffer: PackageOffer,
  propertyAvailability: PropertyAvailability,
  stayDates: StayDates,
): boolean =>
  getNightsOfStayFromStayDates(stayDates).every(
    (nightOfStay) =>
      isDateWithinOfferFromToBounds(packageOffer, nightOfStay) &&
      isDateApplicableOfferDayOfWeek(packageOffer, nightOfStay) &&
      !isOfferClosedOutOnDate(packageOffer, propertyAvailability, nightOfStay),
  );

const isPackageOfferApplicableForArrivalsType = (
  packageOffer: PackageOffer,
  propertyAvailability: PropertyAvailability,
  stayDates: StayDates,
): boolean =>
  isDateWithinOfferFromToBounds(packageOffer, stayDates.checkInDate) &&
  isDateApplicableOfferDayOfWeek(packageOffer, stayDates.checkInDate) &&
  !isOfferClosedOutOnAnyDate(
    packageOffer,
    propertyAvailability,
    getNightsOfStayFromStayDates(stayDates),
  );

export const findPackageOfferNightlyUnitRateByUnitId = (
  { nightlyUnitRates }: PackageOffer,
  unitId: number,
): PackageOfferNightlyUnitRate | undefined =>
  nightlyUnitRates.find((nightlyUnitRate) => nightlyUnitRate.unitId === unitId);

export const findPackageOfferNightlyWayToSellRateByWayToSellId = (
  { nightlyWayToSellRates }: PackageOffer,
  wayToSellId: number,
): PackageOfferNightlyWayToSellRate | undefined =>
  nightlyWayToSellRates.find(
    (nightlyWayToSellRate) => nightlyWayToSellRate.wayToSellId === wayToSellId,
  );

export const findPackageOfferNightlyUnitRateByUnitIdOrFail = (
  packageOffer: PackageOffer,
  unitId: number,
): PackageOfferNightlyUnitRate => {
  const nightlyUnitRate = findPackageOfferNightlyUnitRateByUnitId(
    packageOffer,
    unitId,
  );

  if (!nightlyUnitRate) {
    throw new Error(
      `Could not find package offer nightly rate for unit ${unitId}`,
    );
  }

  return nightlyUnitRate;
};

export const findPackageOfferNightlyWayToSellRateByWayToSellIdOrFail = (
  packageOffer: PackageOffer,
  wayToSellId: number,
): PackageOfferNightlyWayToSellRate => {
  const nightlyWayToSellRate =
    findPackageOfferNightlyWayToSellRateByWayToSellId(
      packageOffer,
      wayToSellId,
    );

  if (!nightlyWayToSellRate) {
    throw new Error(
      `Could not find package offer nightly rate for way to sell ${wayToSellId}`,
    );
  }

  return nightlyWayToSellRate;
};

export const applyPackageOfferToUnitNightlyRates = (
  packageOffer: PackageOffer,
  nightlyRates: NightlyRates,
  unitId: number,
): NightlyRates => {
  const { rate } = findPackageOfferNightlyUnitRateByUnitIdOrFail(
    packageOffer,
    unitId,
  );

  return adjustNightlyRatesByRate(nightlyRates, rate);
};

export const applyPackageOfferToWayToSellNightlyRates = (
  packageOffer: PackageOffer,
  nightlyRates: NightlyRates,
  wayToSellId: number,
): NightlyRates => {
  const { rate } = findPackageOfferNightlyWayToSellRateByWayToSellIdOrFail(
    packageOffer,
    wayToSellId,
  );

  return adjustNightlyRatesByRate(nightlyRates, rate);
};
