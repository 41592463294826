<template>
  <div class="text-sm">
    <div
      v-if="!searchStore.isLoading"
      :class="{ 'text-red-600': searchOccupancyIsLargerThanOccupancy }"
    >
      <div class="flex items-center">
        <div>
          <IconUser :size="16" stroke-width="2" />
        </div>

        <div class="ml-1 flex flex-wrap">
          <p class="mr-1 whitespace-nowrap">
            {{ occupancyDisplayText }}
          </p>

          <p v-if="pricePlan.wayToSell" translate="yes">
            ({{ pricePlan.wayToSell.name }})
          </p>
        </div>
      </div>

      <p
        v-if="searchOccupancyIsLargerThanOccupancy"
        class="mt-1 text-xs font-semibold"
      >
        {{ $t('fewerNumberOfGuestsThanSearched') }} ({{
          searchOccupancyDisplayText
        }})
      </p>
    </div>

    <AppSkeletonText v-else class="w-20" />
  </div>
</template>

<script setup lang="ts">
import { IconUser } from '@tabler/icons-vue';
import { computed } from 'vue';
import { useOccupancy } from '@/occupancy/occupancy.composable';
import type { PricePlan } from '@/price-plan/price-plan';
import { isPricePlanSearchOccupancyLargerThanOccupancy } from '@/price-plan/price-plan.utilities';
import { useSearchStore } from '@/search/search.store';
import AppSkeletonText from '@/ui/app-skeleton-text/AppSkeletonText.vue';

const props = defineProps<{
  pricePlan: PricePlan;
}>();

const searchStore = useSearchStore();

const { generateBasicDisplayText } = useOccupancy();

const searchOccupancyIsLargerThanOccupancy = computed(() =>
  isPricePlanSearchOccupancyLargerThanOccupancy(props.pricePlan),
);

const occupancyDisplayText = computed(() =>
  generateBasicDisplayText(props.pricePlan.occupancy),
);

const searchOccupancyDisplayText = computed(() =>
  generateBasicDisplayText(props.pricePlan.searchOccupancy),
);
</script>
