<template>
  <AppErrors v-if="appError !== undefined" :app-error="appError" />

  <RouterView v-else v-slot="{ Component }">
    <Transition
      appear
      mode="out-in"
      enter-active-class="duration-200"
      enter-from-class="opacity-0 translate-y-1"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="duration-200"
      leave-from-class="opacity-100 translate-y-0"
      leave-to-class="opacity-0 translate-y-1"
    >
      <Component :is="Component" />
    </Transition>
  </RouterView>
</template>

<script setup lang="ts">
import { useHead } from '@unhead/vue';
import { onErrorCaptured } from 'vue';
import { useI18n } from 'vue-i18n';
import { useErrorHandler } from '@/error-handler/error-handler';
import AppErrors from '@/error-pages/AppErrors.vue';
import { useInitializeLocale } from '@/locale/initialize/initialize-locale.composable';

const { locale } = useI18n();

const { appError, handleError } = useErrorHandler();

void useInitializeLocale();

onErrorCaptured(handleError);

useHead({
  htmlAttrs: { lang: () => locale.value },
});
</script>
