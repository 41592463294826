import { computed } from 'vue';
import { readAbandonedCartRecoveryRequestIdFromIdb } from '@/abandoned-cart/recovery-request/abandoned-cart-recovery-request.utilities';
import { sendCreateBookingRequest } from '@/booking/booking.api';
import type { BookingCreateData } from '@/booking/create/data/booking-create-data';
import type { BookingCreateResult } from '@/booking/create/result/booking-create-result';
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import { useBookingSourceStore } from '@/booking-source/booking-source.store';
import { useCodeResourceStore } from '@/code/resource/code-resource.store';
import { useElementsStore } from '@/elements/elements.store';
import { useGuestStore } from '@/guest/guest.store';
import i18n from '@/i18n';
import { useSearchStore } from '@/search/search.store';

export const useBookingCreate = () => {
  const searchStore = useSearchStore();
  const elementsStore = useElementsStore();
  const codeResourceStore = useCodeResourceStore();
  const bookingItineraryStore = useBookingItineraryStore();
  const guestStore = useGuestStore();
  const bookingSourceStore = useBookingSourceStore();

  const bookingCreateUnitsData = computed<BookingCreateData['units']>(() =>
    bookingItineraryStore.unitItinerary.map((unitItineraryItem) => ({
      id: unitItineraryItem.unitId,
      wayToSellId: unitItineraryItem.wayToSellId,
      offerId: unitItineraryItem.offerId,
      occupancy: unitItineraryItem.occupancy,
      mealTypes: unitItineraryItem.selectedMealTypes,
      twinDoubleBedOption: unitItineraryItem.twinDoubleBedOption,
      leadGuestName: unitItineraryItem.leadGuestName,
    })),
  );

  const bookingCreateExtrasData = computed<BookingCreateData['extras']>(() =>
    [
      ...bookingItineraryStore.selectedExtraItinerary,
      ...bookingItineraryStore.mandatoryExtraItinerary,
    ].map((extraItineraryItem) => ({
      id: extraItineraryItem.extra.id,
      answer: extraItineraryItem.answer,
      numberOfNights: extraItineraryItem.numberOfNightsApplied,
      quantity: extraItineraryItem.quantity,
    })),
  );

  const createBooking = async (
    bookingCreatePaymentGatewayData: BookingCreateData['paymentGateway'],
  ): Promise<BookingCreateResult> =>
    sendCreateBookingRequest(searchStore.activeProperty.id, {
      widgetId: searchStore.widget.id,
      checkInDate: bookingItineraryStore.stayDates!.checkInDate,
      checkOutDate: bookingItineraryStore.stayDates!.checkOutDate,
      arrivalTime: bookingItineraryStore.checkInTime,
      specialRequests: bookingItineraryStore.specialRequests,
      locale: i18n.global.locale.value,
      promocode: codeResourceStore.promocode?.promocodeText,
      privateRateOverride: codeResourceStore.resource?.privateRateOverride
        ? {
            id: codeResourceStore.resource.privateRateOverride.id,
            codeUsed: codeResourceStore.resource.code,
          }
        : undefined,
      units: bookingCreateUnitsData.value,
      extras: bookingCreateExtrasData.value,
      guest: guestStore.guest,
      paymentGateway: bookingCreatePaymentGatewayData,
      source: bookingSourceStore.bookingSource,
      elements: elementsStore.elements,
      usedExpressCheckout: searchStore.activePropertyCanUseExpressCheckout,
      abandonedCartId: await readAbandonedCartRecoveryRequestIdFromIdb(
        searchStore.activePropertyId,
        searchStore.widget.id,
      ),
      expectedTotalAmount: bookingItineraryStore.totalRateBeforeTax,
      expectedDepositAmount: bookingItineraryStore.depositAmount,
    });

  return { createBooking };
};
