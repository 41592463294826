import type { PropertyAvailability } from '@/availability/property-availability/property-availability';
import { findPropertyAvailabilityUnitAvailabilityByDateOrFail } from '@/availability/property-availability/property-availability.utilities';
import type { Occupancy } from '@/occupancy/occupancy';
import type { PrivateRateOverride } from '@/private-rate-override/private-rate-override';
import type { IncludedMeal } from '@/property/meal/included-meal/included-meal';
import type { SupplementalMeal } from '@/property/meal/supplemental-meal/supplemental-meal';
import type { Offer } from '@/property/offer/offer';
import { OfferType } from '@/property/offer/offer';
import { offerAppliesToUnit } from '@/property/offer/offer.utilities';
import type { Promocode } from '@/property/promocode/promocode';
import type { Unit } from '@/property/unit/unit';
import type { WayToSell } from '@/property/way-to-sell/way-to-sell';
import { createStandardMealsRates } from '@/rates/meal-rates/meal-rates.utilities';
import type { NightlyRates } from '@/rates/nightly-rates/nightly-rates';
import type { OfferRates } from '@/rates/offer-rates/offer-rates';
import {
  calculateDiscountOfferRatesOnNightlyRates,
  calculateFreeNightOfferRatesOnNightlyRates,
  calculatePackageOfferUnitRatesOnNightlyRates,
} from '@/rates/offer-rates/offer-rates.utilities';
import { calculatePrivateRatesOnUnitNightlyRates } from '@/rates/private-rates/private-rates.utilities';
import { createPromocodeRates } from '@/rates/promocode-rates/promocode-rates.utilities';
import type { UnitRates } from '@/rates/unit-rates/unit-rates';
import { createWaysToSellRates } from '@/rates/way-to-sell-rates/way-to-sell-rates.utilities';
import type { StayDates } from '@/stay-dates/stay-dates';
import { getNightsOfStayFromStayDates } from '@/stay-dates/stay-dates.utilities';

export const createUnitRates = (
  propertyAvailability: PropertyAvailability,
  stayDates: StayDates,
  offers: Offer[],
  promocode: Promocode | undefined,
  privateRateOverride: PrivateRateOverride | undefined,
  includedUnitMeals: IncludedMeal[],
  supplementalUnitMeals: SupplementalMeal[],
  unit: Unit,
  waysToSell: WayToSell[],
  occupancies: Occupancy[],
): UnitRates[] =>
  occupancies.map((occupancy) => {
    const standardNightlyRates = createStandardNightlyRates(
      unit.id,
      propertyAvailability,
      getNightsOfStayFromStayDates(stayDates),
    );

    const privateRates = privateRateOverride
      ? calculatePrivateRatesOnUnitNightlyRates(
          privateRateOverride,
          unit.id,
          standardNightlyRates,
        )
      : undefined;

    const standardMealsRates = createStandardMealsRates(
      supplementalUnitMeals,
      propertyAvailability,
      stayDates,
      occupancy,
    );

    const offersRates = createOffersRates(
      unit,
      standardNightlyRates,
      offers,
      promocode,
      propertyAvailability,
      stayDates,
      occupancy,
      supplementalUnitMeals,
    );

    const waysToSellRates = createWaysToSellRates(
      unit,
      waysToSell,
      standardNightlyRates,
      promocode,
      privateRateOverride,
      includedUnitMeals,
      supplementalUnitMeals,
      offers,
      propertyAvailability,
      stayDates,
      occupancy,
    );

    const standardPromocodeRates = promocode
      ? createPromocodeRates(promocode, standardNightlyRates)
      : undefined;

    return {
      unit,
      occupancy,
      nightlyRates: standardNightlyRates,
      privateRates,
      offersRates,
      promocodeRates: standardPromocodeRates,
      waysToSellRates,
      mealsRates: standardMealsRates,
      includedMeals: includedUnitMeals,
    };
  });

export const createStandardNightlyRates = (
  unitId: number,
  propertyAvailability: PropertyAvailability,
  nightsOfStay: string[],
): NightlyRates =>
  nightsOfStay.map((nightOfStay) => {
    const { rate } = findPropertyAvailabilityUnitAvailabilityByDateOrFail(
      propertyAvailability,
      unitId,
      nightOfStay,
    );

    return {
      date: nightOfStay,
      rate,
      isFreeNight: false,
    };
  });

const createOffersRates = (
  unit: Unit,
  standardNightlyRates: NightlyRates,
  offers: Offer[],
  promocode: Promocode | undefined,
  propertyAvailability: PropertyAvailability,
  stayDates: StayDates,
  occupancy: Occupancy,
  supplementalUnitMeals: SupplementalMeal[],
): OfferRates[] =>
  offers.reduce<OfferRates[]>((offersRates, offer) => {
    const offerRates = calculateUnitOfferRatesOnNightlyRates(
      unit,
      standardNightlyRates,
      offer,
      promocode,
      propertyAvailability,
      stayDates,
      occupancy,
      supplementalUnitMeals,
    );

    if (offerRates === undefined) {
      return offersRates;
    }

    offersRates.push(offerRates);

    return offersRates;
  }, []);

export const calculateUnitOfferRatesOnNightlyRates = (
  unit: Unit,
  standardNightlyRates: NightlyRates,
  offer: Offer,
  promocode: Promocode | undefined,
  propertyAvailability: PropertyAvailability,
  stayDates: StayDates,
  occupancy: Occupancy,
  supplementalUnitMeals: SupplementalMeal[],
): OfferRates | undefined => {
  if (!offerAppliesToUnit(offer, unit)) {
    return undefined;
  }

  switch (offer.offerType) {
    case OfferType.Discount:
      return calculateDiscountOfferRatesOnNightlyRates(
        unit.id,
        standardNightlyRates,
        offer,
        promocode,
        propertyAvailability,
        stayDates,
        occupancy,
        supplementalUnitMeals,
      );
    case OfferType.FreeNight:
      return calculateFreeNightOfferRatesOnNightlyRates(
        standardNightlyRates,
        offer,
        promocode,
        propertyAvailability,
        stayDates,
        occupancy,
        supplementalUnitMeals,
      );
    case OfferType.Package:
      return calculatePackageOfferUnitRatesOnNightlyRates(
        unit.id,
        standardNightlyRates,
        offer,
      );
  }
};
