import type { Ref } from 'vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { GroupsPolicyType } from '@/property/groups-policy/groups-policy';
import type { LimitedGroupsPolicy } from '@/property/groups-policy/limited/limited-groups-policy';
import type { Property } from '@/property/property';

export const useGroupsPolicyTermsAndConditionsDisplayText = (
  property: Ref<Property>,
) => {
  const { t } = useI18n();

  const groupsPolicy = computed(() => property.value.groupsPolicy);

  const generateLimitedGroupsDisplayText = (
    limitedGroupsPolicy: LimitedGroupsPolicy,
  ): string =>
    [
      generateMaximumGroupSizeDisplayText(limitedGroupsPolicy),
      generateAcceptsStagAndHenGroupsDisplayText(limitedGroupsPolicy),
    ]
      .filter(Boolean)
      .join(' ');

  const generateMaximumGroupSizeDisplayText = ({
    maximumGroupSize,
  }: LimitedGroupsPolicy): string =>
    !!maximumGroupSize && maximumGroupSize > 0
      ? t('groupsAreAcceptedUpToAMaximumOfMaximumPeople', {
          maximum: maximumGroupSize,
        })
      : '';

  const generateAcceptsStagAndHenGroupsDisplayText = ({
    acceptsHenAndStagGroups,
  }: LimitedGroupsPolicy): string =>
    acceptsHenAndStagGroups
      ? t('stagAndHenGroupsAreAllowed')
      : t('noStagAndHenGroups');

  return computed(() =>
    groupsPolicy.value.policyType === GroupsPolicyType.All
      ? ''
      : generateLimitedGroupsDisplayText(groupsPolicy.value),
  );
};
