<template>
  <div class="h-full w-full">
    <img
      :src="generateImageUrl(500, 300, true)"
      alt=""
      class="absolute z-[1] h-full w-full object-cover"
    />

    <picture>
      <source
        media="(max-width:500px) and (max-height: 900px)"
        :srcset="generateImageUrl(500, 900)"
      />

      <source
        media="(max-width: 1000px) and (max-height: 1000px)"
        :srcset="generateImageUrl(1000, 1000)"
      />

      <source
        media="(orientation: landscape) and (max-height: 1000px)"
        :srcset="generateImageUrl(2000, 1000)"
      />

      <source
        media="(orientation: portrait) and (max-width: 1000px)"
        :srcset="generateImageUrl(1000, 2000)"
      />

      <source
        media="(min-width: 1000px) and (min-height: 1000px)"
        :srcset="generateImageUrl(2000, 2000)"
      />

      <img alt="" class="absolute z-[2] h-full w-full object-cover" />
    </picture>
  </div>
</template>
<script setup lang="ts">
import { format, quality } from '@cloudinary/url-gen/actions/delivery';
import { blur } from '@cloudinary/url-gen/actions/effect';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { auto } from '@cloudinary/url-gen/qualifiers/format';
import { cloudinary } from '@/cloudinary';
import type { PropertyVanityBackgroundImage } from '@/property/property-vanity/background/property-vanity-background-image';

const props = defineProps<{ backgroundImage: PropertyVanityBackgroundImage }>();

const generateImageUrl = (
  width: number,
  height: number,
  isPlaceholder = false,
): string => {
  const image = cloudinary
    .image(props.backgroundImage.url)
    .setDeliveryType('fetch')
    .delivery(format(auto()))
    .resize(fill().width(width).height(height));

  if (isPlaceholder) {
    image.effect(blur(500)).delivery(quality('auto'));
  }

  return image.toURL();
};
</script>
