import { mapPropertyVanityBackgroundImageFromDTO } from '@/property/property-vanity/background/property-vanity-background-image.mapper';
import type {
  PropertyVanity,
  PropertyVanityDTO,
} from '@/property/property-vanity/property-vanity';

export const mapPropertyVanityFromDTO = (
  propertyVanityDTO: PropertyVanityDTO,
): PropertyVanity => ({
  useAlternateLogoPosition: propertyVanityDTO.useAlternateLogoPosition,
  backgroundImage: mapPropertyVanityBackgroundImageFromDTO(
    propertyVanityDTO.backgroundImage,
  ),
});
