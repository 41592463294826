<template>
  <BookingItineraryTermsAndConditionsAgreement
    v-if="
      bookingStageStore.currentStage.shouldDisplayTermsAndConditionsAgreement
    "
    class="text-xs text-slate-700"
  />
</template>

<script setup lang="ts">
import BookingItineraryTermsAndConditionsAgreement from '@/booking-itinerary/terms-and-conditions/agreement/BookingItineraryTermsAndConditionsAgreement.vue';
import { useBookingStageStore } from '@/booking-stage/booking-stage.store';

const bookingStageStore = useBookingStageStore();
</script>
