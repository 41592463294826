<template>
  <div>
    <VerticalAvailabilityCalendar
      v-if="isVerticalCalendar"
      :start-of-month-dates="startOfMonthDatesForCalendar"
      @navigate-up="subMonth"
      @navigate-down="addMonth"
    />
    <HorizontalAvailabilityCalendar
      v-else
      :start-of-month-dates="startOfMonthDatesForCalendar"
      @navigate-left="subMonth"
      @navigate-right="addMonth"
    />
  </div>
</template>

<script setup lang="ts">
import { useBreakpoints, breakpointsTailwind } from '@vueuse/core';
import {
  eachMonthOfInterval,
  addMonths,
  subMonths,
  isPast,
  startOfMonth,
  endOfMonth,
  format,
} from 'date-fns';
import { computed, ref } from 'vue';
import {
  MONTHS_TO_ADD,
  useAvailabilityCalendarStore,
} from '@/availability-calendar/availability-calendar.store';
import HorizontalAvailabilityCalendar from '@/availability-calendar/HorizontalAvailabilityCalendar.vue';
import VerticalAvailabilityCalendar from '@/availability-calendar/VerticalAvailabilityCalendar.vue';
import { getStartOfDateTime } from '@/date/date.utilities';

const props = defineProps<{
  checkInDate: string;
  checkOutDate: string;
  autoSelectStayDates: boolean;
}>();

const { selectedCheckInDate, selectedCheckOutDate, load } =
  useAvailabilityCalendarStore();

const checkInDate = getStartOfDateTime(props.checkInDate);
const checkOutDate = getStartOfDateTime(props.checkOutDate);

if (props.autoSelectStayDates) {
  selectedCheckInDate.value = checkInDate;
  selectedCheckOutDate.value = checkOutDate;
}

const startDate = ref(checkInDate);
const endDate = ref(checkInDate);

const isVerticalCalendar = useBreakpoints(breakpointsTailwind).smaller('md');

const loadCalendarAvailability = async () => {
  await load(
    format(startOfMonth(startDate.value), 'yyyy-MM-dd'),
    format(endOfMonth(addMonths(startDate.value, MONTHS_TO_ADD)), 'yyyy-MM-dd'),
  );
};

void loadCalendarAvailability();

const startOfMonthDatesForCalendar = computed(() =>
  eachMonthOfInterval({
    start: startDate.value,
    end: addMonths(endDate.value, MONTHS_TO_ADD).getTime(),
  }).map((month) => month.getTime()),
);

const subMonth = (numOfMonthsToMove: number, updateEndDate: boolean) => {
  startDate.value = subMonths(startDate.value, numOfMonthsToMove).getTime();

  if (isPast(startDate.value)) {
    startDate.value = startOfMonth(new Date()).getTime();
  }
  if (updateEndDate) {
    endDate.value = subMonths(endDate.value, numOfMonthsToMove).getTime();
  }
};

const addMonth = (numOfMonthsToMove: number, updateStartDate: boolean) => {
  if (updateStartDate) {
    startDate.value = addMonths(startDate.value, numOfMonthsToMove).getTime();
  }
  endDate.value = addMonths(endDate.value, numOfMonthsToMove).getTime();
};
</script>
