import { Decimal } from 'decimal.js';
import type { WayToSellAdjustment } from '@/property/way-to-sell/adjustment/way-to-sell-adjustment';
import { WayToSellAdjustmentType } from '@/property/way-to-sell/adjustment/way-to-sell-adjustment';

export const applyWayToSellAdjustmentToValue = (
  wayToSellAdjustment: WayToSellAdjustment,
  value: number,
): number =>
  applyWayToSellAdjustmentToDecimalValue(
    wayToSellAdjustment,
    new Decimal(value),
  ).toNumber();

const applyWayToSellAdjustmentToDecimalValue = (
  wayToSellAdjustment: WayToSellAdjustment,
  decimalValue: Decimal,
): Decimal => {
  const { type, adjustmentAmount } = wayToSellAdjustment;

  switch (type) {
    case WayToSellAdjustmentType.MonetaryDiscount:
      return decimalValue.minus(adjustmentAmount);
    case WayToSellAdjustmentType.MonetarySurcharge:
      return decimalValue.plus(adjustmentAmount);
    case WayToSellAdjustmentType.PercentageDiscount:
      return decimalValue.minus(
        applyAdjustmentAmountToDecimalValueAsPercentage(
          adjustmentAmount,
          decimalValue,
        ),
      );
    case WayToSellAdjustmentType.PercentageSurcharge:
      return decimalValue.plus(
        applyAdjustmentAmountToDecimalValueAsPercentage(
          adjustmentAmount,
          decimalValue,
        ),
      );
  }
};

const applyAdjustmentAmountToDecimalValueAsPercentage = (
  adjustmentAmount: number,
  decimalValue: Decimal,
): Decimal =>
  decimalValue.times(Decimal.div(adjustmentAmount, 100)).toDecimalPlaces(2);
