import { onMounted, onUnmounted } from 'vue';

/**
 * Unfortunately, on various mobile devices using standard vh units likely won't look exactly
 * right. Things like address bars, iPhone notch, etc can overlay items sized using vh. As
 * a workaround, we manually calculate the app height using innerHeight (which always
 * takes the browser's interface into account) and make it available as an
 * `--app-height` CSS variable.
 *
 * @see {@link https://dev.to/maciejtrzcinski/100vh-problem-with-ios-safari-3ge9}
 */
export const useViewportHeightCalculator = () => {
  onMounted(() => {
    calculateViewportHeight();
    window.addEventListener('resize', calculateViewportHeight);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', calculateViewportHeight);
  });
};

const calculateViewportHeight = () => {
  document.documentElement.style.setProperty(
    '--app-height',
    `${window.innerHeight}px`,
  );
};
