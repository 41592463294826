import type { LocaleToGrammaticalGender } from '@/i18n/locales/gender/gender';
import type { translatableUnitTypeNames } from '@/property/unit/type/unit-type.utilities';

export const UNIT_TYPE_NAME_ROOM = 'room';
export const UNIT_TYPE_NAME_UNIT = 'unit';
export const UNIT_TYPE_NAME_BED = 'bed';
export const UNIT_TYPE_NAME_BOAT = 'boat';
export const UNIT_TYPE_NAME_CHALET = 'chalet';
export const UNIT_TYPE_NAME_COTTAGE = 'cottage';
export const UNIT_TYPE_NAME_PITCH = 'pitch';
export const UNIT_TYPE_NAME_CARAVAN = 'caravan';
export const UNIT_TYPE_NAME_HOUSE = 'house';
export const UNIT_TYPE_NAME_LODGE = 'lodge';
export const UNIT_TYPE_NAME_APARTMENT = 'apartment';
export const UNIT_TYPE_NAME_TENT = 'tent';
export const UNIT_TYPE_NAME_VILLA = 'villa';
export const UNIT_TYPE_NAME_TEEPEE = 'teepee';
export const UNIT_TYPE_NAME_YURT = 'yurt';

export interface KnownUnitType {
  isKnown: true;
  name: string;
  basicTranslationKey: string;
  countTranslationKey: string;
  genders: LocaleToGrammaticalGender;
}

export interface UnknownUnitType {
  isKnown: false;
  name: string;
}

export type UnitType = KnownUnitType | UnknownUnitType;

export type TranslatableUnitTypeName =
  (typeof translatableUnitTypeNames)[number];
