<template>
  <div
    class="flex grow flex-col items-center justify-center gap-10 p-2 lg:justify-start"
    :class="
      !isLogoEnabled
        ? 'lg:mt-48'
        : useAlternateLogoPosition
        ? 'lg:mt-10'
        : 'lg:mt-20'
    "
  >
    <VanityLogo v-if="isLogoEnabled && useAlternateLogoPosition" />

    <div
      class="flex flex-col gap-4 rounded-md bg-white p-6 shadow-md sm:p-10 md:gap-6"
    >
      <VanitySearchHeader
        v-if="shouldShowHeader"
        :should-show-logo="!useAlternateLogoPosition"
      />

      <div class="items-end lg:flex">
        <VanitySearchAvailabilityCalendarContainer />

        <VanitySearchOccupancySelectorContainer class="mt-2 lg:ml-5 lg:mt-0" />

        <AppPrimaryButton
          class="mt-4 h-12 w-full px-10 lg:ml-5 lg:mt-0 lg:w-auto"
          :button-text="capitalize($t('search'))"
          :icon="IconSearch"
          @click="goToUnitSelection"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { IconSearch } from '@tabler/icons-vue';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { UNIT_SELECTION_ROUTE } from '@/router';
import { useSearchStore } from '@/search/search.store';
import { useLocaleString } from '@/string/locale-string.composable';
import AppPrimaryButton from '@/ui/app-primary-button/AppPrimaryButton.vue';
import VanityLogo from '@/vanity-page/logo/VanityLogo.vue';
import VanitySearchAvailabilityCalendarContainer from '@/vanity-page/search/availability-calendar/VanitySearchAvailabilityCalendarContainer.vue';
import VanitySearchHeader from '@/vanity-page/search/header/VanitySearchHeader.vue';
import VanitySearchOccupancySelectorContainer from '@/vanity-page/search/occupancy-selector/VanitySearchOccupancySelectorContainer.vue';

const router = useRouter();

const searchStore = useSearchStore();

const { capitalize } = useLocaleString();

const shouldShowHeader = computed(
  () => !!searchStore.activeProperty.propertyTheme.name || isLogoEnabled.value,
);

const isLogoEnabled = computed(
  () => !!searchStore.activeProperty.propertyTheme.logo,
);

const useAlternateLogoPosition = computed(
  () => searchStore.activeProperty.propertyVanity?.useAlternateLogoPosition,
);

const goToUnitSelection = () =>
  router.push({
    ...router.currentRoute.value,
    name: UNIT_SELECTION_ROUTE,
  });
</script>
