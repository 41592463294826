import type {
  MealAvailability,
  MealAvailabilityDTO,
} from '@/availability/meal-availability/meal-availability';

export const mapMealAvailabilityFromDTO = (
  mealAvailabilityDTO: MealAvailabilityDTO,
): MealAvailability => ({
  mealType: mealAvailabilityDTO.mealType,
  isClosedOut: mealAvailabilityDTO.isClosedOut,
  rate: mealAvailabilityDTO.rate ? Number(mealAvailabilityDTO.rate) : undefined,
});
