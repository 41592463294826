import { Decimal } from 'decimal.js';
import type { Ref } from 'vue';
import { computed } from 'vue';
import type { NamedValue } from 'vue-i18n';
import { useI18n } from 'vue-i18n';
import {
  createDateObject,
  getCurrentDateInTimezone,
  subtractDays,
} from '@/date/date.utilities';
import type { BookingPolicy } from '@/property/booking-policy/booking-policy';
import type { NonFreeCancellationPolicy } from '@/property/booking-policy/cancellation-policy/cancellation-policy';
import { CancellationPolicyType } from '@/property/booking-policy/cancellation-policy/cancellation-policy';
import {
  getCancellationPolicyEndDate,
  getCancellationPolicyStartDate,
} from '@/property/booking-policy/cancellation-policy/cancellation-policy.utilities';
import { DepositPolicyChargeType } from '@/property/booking-policy/deposit-policy/charge/deposit-policy-charge';
import { DepositPolicyType } from '@/property/booking-policy/deposit-policy/deposit-policy';
import type { Property } from '@/property/property';
import type { StayDates } from '@/stay-dates/stay-dates';

export const useCancellationPoliciesTermsAndConditionsDisplayTexts = (
  bookingPolicy: Ref<BookingPolicy>,
  property: Ref<Property>,
  stayDates: Ref<StayDates>,
) => {
  const { t, d, n } = useI18n();

  const today = computed(() =>
    getCurrentDateInTimezone(property.value.timezone),
  );

  const depositPolicy = computed(() => bookingPolicy.value.depositPolicy);

  const cancellationPolicies = computed(
    () => bookingPolicy.value.cancellationPolicies,
  );

  const depositIsNonRefundable = computed(
    () =>
      depositPolicy.value.policyType === DepositPolicyType.Mandatory &&
      !depositPolicy.value.refundPolicy &&
      depositPolicy.value.charge.chargeType ===
        DepositPolicyChargeType.Percentage &&
      depositPolicy.value.charge.percentage === 100,
  );

  const nonFreeCancellationPolicies = computed(() =>
    cancellationPolicies.value.filter(
      (cancellationPolicy): cancellationPolicy is NonFreeCancellationPolicy =>
        cancellationPolicy.cancellationType !== CancellationPolicyType.Free,
    ),
  );

  const cancellationPoliciesDisplayTexts = computed(() =>
    nonFreeCancellationPolicies.value.reduceRight<string[]>(
      (displayTexts, cancellationPolicy) => {
        const { checkInDate } = stayDates.value;

        const policyEndDate = getCancellationPolicyEndDate(
          cancellationPolicy,
          checkInDate,
        );

        if (policyEndDate < today.value) {
          return displayTexts;
        }

        const policyStartDate = getCancellationPolicyStartDate(
          cancellationPolicy,
          checkInDate,
        );

        const policyEndsOnArrival = policyEndDate === checkInDate;

        displayTexts.push(
          generateCancellationPolicyDisplayText(
            cancellationPolicy,
            policyStartDate,
            policyEndDate,
            policyEndsOnArrival,
          ),
        );

        return displayTexts;
      },
      [],
    ),
  );

  const generateCancellationPolicyDisplayText = (
    cancellationPolicy: NonFreeCancellationPolicy,
    policyStartDate: string | undefined,
    policyEndDate: string,
    policyEndsOnArrival: boolean,
  ): string => {
    const [chargePartialTranslationKey, chargeTranslationNamedValues] =
      generateChargePartialTranslationKeyWithNamedValues(cancellationPolicy);

    if (policyStartDate) {
      return policyEndsOnArrival
        ? t(`cancelOnOrAfterDate${chargePartialTranslationKey}`, {
            date: generateDateDisplayText(subtractDays(policyStartDate, 1)),
            ...chargeTranslationNamedValues,
          })
        : t(
            `cancelOnOrBetweenStartDateAndEndDate${chargePartialTranslationKey}`,
            {
              startDate: generateDateDisplayText(
                subtractDays(policyStartDate, 1),
              ),
              endDate: generateDateDisplayText(subtractDays(policyEndDate, 1)),
              ...chargeTranslationNamedValues,
            },
          );
    }

    return policyEndsOnArrival
      ? t(`ifTheBookingIsCancelled${chargePartialTranslationKey}`, {
          ...chargeTranslationNamedValues,
        })
      : t(`cancelOnOrBeforeDate${chargePartialTranslationKey}`, {
          date: generateDateDisplayText(subtractDays(policyEndDate, 1)),
          ...chargeTranslationNamedValues,
        });
  };

  const generateChargePartialTranslationKeyWithNamedValues = (
    cancellationPolicy: NonFreeCancellationPolicy,
  ): [string, NamedValue?] => {
    switch (cancellationPolicy.cancellationType) {
      case CancellationPolicyType.Nightly:
        return [
          'ThenYouWillBeChargedForTheFirstNightCountNightsOfTheStay',
          {
            nightCount: cancellationPolicy.numberOfNights,
          },
        ];
      case CancellationPolicyType.Monetary:
        return [
          'ThenYouWillBeChargedChargeAmount',
          {
            chargeAmount: generateMonetaryChargeAmountDisplayText(
              cancellationPolicy.amount,
            ),
          },
        ];
      case CancellationPolicyType.Percentage:
        return cancellationPolicy.percentage === 100
          ? ['ThenYouWillBeChargedTheFullCostOfYourBooking']
          : [
              'ThenYouWillBeChargedPercentAmountOfTheStay',
              {
                percentAmount: n(
                  new Decimal(cancellationPolicy.percentage)
                    .dividedBy(100)
                    .toNumber(),
                  'percent',
                ),
              },
            ];
      case CancellationPolicyType.PerUnitMonetary:
        return [
          'ThenYouWillBeChargedChargeAmountPerUnitBooked',
          {
            chargeAmount: generateMonetaryChargeAmountDisplayText(
              cancellationPolicy.amountPerUnit,
            ),
          },
        ];
    }
  };

  const generateMonetaryChargeAmountDisplayText = (
    chargeAmount: number,
  ): string =>
    n(chargeAmount, {
      style: 'currency',
      currency: property.value.currencyCode,
    });

  const generateDateDisplayText = (date: string): string =>
    d(createDateObject(date), {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });

  return computed(() =>
    depositIsNonRefundable.value
      ? [t('thisBookingIsNonRefundable')]
      : cancellationPoliciesDisplayTexts.value,
  );
};
