<template>
  <button
    ref="primaryButton"
    class="accent-contrast-border flex items-center justify-center rounded bg-accent-color font-semibold text-accent-contrast-color outline-none transition-[filter] hover:brightness-95 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-4 focus-visible:outline-slate-800 active:brightness-90 disabled:cursor-not-allowed disabled:bg-slate-300 disabled:text-slate-500 disabled:opacity-75 disabled:brightness-100"
    :disabled="props.disabled || props.isLoading"
  >
    <AppLoadingSpinner v-if="props.isLoading" class="mr-2 w-5" />

    <div v-else-if="!!icon" class="mr-2">
      <Component :is="icon" :size="22" />
    </div>

    {{ buttonText }}
  </button>
</template>

<script setup lang="ts">
import type { Icon } from '@tabler/icons-vue';
import { ref } from 'vue';
import AppLoadingSpinner from '@/ui/app-loading-spinner/AppLoadingSpinner.vue';

const props = withDefaults(
  defineProps<{
    buttonText: string;
    icon?: Icon | null | undefined;
    isLoading?: boolean;
    disabled?: boolean;
  }>(),
  {
    icon: null,
    isLoading: false,
    disabled: false,
  },
);

const primaryButton = ref<HTMLElement>();

defineExpose({ primaryButton });
</script>
