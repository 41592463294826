import type { Stripe, StripeElementLocale } from '@stripe/stripe-js';
import { loadStripe as _loadStripe } from '@stripe/stripe-js';

export const loadStripe = async (
  publishableKey: string,
  locale: StripeElementLocale,
): Promise<Stripe> => {
  const stripe = await _loadStripe(publishableKey, { locale });

  if (!stripe) {
    throw new Error('Failed to load the Stripe.js object');
  }

  return stripe;
};
