<template>
  <UnitSelectionPricePlanList :price-plans="firstPricePlanList" />

  <UnitSelectionPricePlanListDisclosure
    :price-plan-lists="remainingPricePlanLists"
    :is-last-unit="isLastUnit"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import UnitSelectionPricePlanListDisclosure from '@/unit-selection-page/price-plan/list/disclosure/UnitSelectionPricePlanListDisclosure.vue';
import UnitSelectionPricePlanList from '@/unit-selection-page/price-plan/list/UnitSelectionPricePlanList.vue';
import { useUnitSelectionStore } from '@/unit-selection-page/unit-selection.store';

const props = defineProps<{ unitId: number; isLastUnit: boolean }>();

const unitSelectionStore = useUnitSelectionStore();

const organizedPricePlans = computed(() =>
  unitSelectionStore.pricePlans.findOrganizedPricePlansForUnitId(props.unitId),
);

const firstPricePlanList = computed(() => organizedPricePlans.value[0]!);

const remainingPricePlanLists = computed(() =>
  organizedPricePlans.value.slice(1),
);
</script>
