<template>
  <BookingConfirmation v-if="isInitialized" />

  <LoadingOverlay v-else />
</template>

<script setup lang="ts">
import { useHead } from '@unhead/vue';
import { cloneDeep } from 'lodash-es';
import { onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { onBeforeRouteLeave } from 'vue-router';
import type { Booking } from '@/booking/booking';
import { useBookingConfirmationStore } from '@/booking-confirmation-page/booking-confirmation.store';
import BookingConfirmation from '@/booking-confirmation-page/BookingConfirmation.vue';
import { useElementsStore } from '@/elements/elements.store';
import { useFaviconSetter } from '@/favicon-setter/favicon-setter';
import LoadingOverlay from '@/loading-overlay/LoadingOverlay.vue';
import { FacebookPixelPageViewEventAction } from '@/property/facebook-pixel/facebook-pixel-event-action';
import { useFacebookPixelStore } from '@/property/facebook-pixel/facebook-pixel.store';
import { GoogleAnalyticsPageViewEventAction } from '@/property/google/analytics/google-analytics-event-action';
import { useGoogleAnalyticsStore } from '@/property/google/analytics/google-analytics.store';
import { BOOKING_CONFIRMATION_ROUTE, VANITY_ROUTE } from '@/router';
import { useLocaleString } from '@/string/locale-string.composable';
import { UserEventActionLabel } from '@/user-event/user-event';
import { useUserEventStore } from '@/user-event/user-event.store';

const props = defineProps<{ booking?: Booking }>();

const { t } = useI18n();

const bookingConfirmationStore = useBookingConfirmationStore();
const elementsStore = useElementsStore();
const { trackUserEventAction } = useUserEventStore();
const { emitGAPageViewEvent } = useGoogleAnalyticsStore();
const { emitPixelPageViewEvent } = useFacebookPixelStore();
const { toTitleCase } = useLocaleString();
const { setPropertyThemeLogoFavicon } = useFaviconSetter();

const isInitialized = ref(false);

onBeforeMount(async () => {
  bookingConfirmationStore.booking =
    props.booking ?? (await bookingConfirmationStore.fetchBooking());

  bookingConfirmationStore.editableBooking = cloneDeep(
    bookingConfirmationStore.booking,
  );

  isInitialized.value = true;

  if (bookingConfirmationStore.property.propertyTheme.logo) {
    setPropertyThemeLogoFavicon(
      bookingConfirmationStore.property.propertyTheme.logo,
    );
  }

  useHead({
    title: () =>
      toTitleCase(t('bookingConfirmation')) +
      ' | ' +
      bookingConfirmationStore.property.name,
  });

  trackUserEventAction({
    label: UserEventActionLabel.PageView,
    metadata: {
      page_name: BOOKING_CONFIRMATION_ROUTE,
      widget_id: bookingConfirmationStore.booking.widgetId,
      property_id: bookingConfirmationStore.property.id,
      booking_id: bookingConfirmationStore.booking.id,
      is_express_checkout: bookingConfirmationStore.booking.usedExpressCheckout,
      used_elements: elementsStore.usedElements,
      payment_gateway: bookingConfirmationStore.property.paymentGateway.type,
    },
  });

  void emitGAPageViewEvent(
    GoogleAnalyticsPageViewEventAction.BookingConfirmationPage,
    bookingConfirmationStore.property.googleAnalytics,
    bookingConfirmationStore.bookingStayDates,
  );

  emitPixelPageViewEvent(
    FacebookPixelPageViewEventAction.BookingConfirmationPage,
    bookingConfirmationStore.property.facebookPixel,
    bookingConfirmationStore.bookingStayDates,
  );
});

/**
 * When trying to navigate away from booking confirmation, always redirect to
 * the vanity route.
 */
onBeforeRouteLeave(({ name }) =>
  name !== VANITY_ROUTE
    ? {
        name: VANITY_ROUTE,
        params: {
          widgetId: bookingConfirmationStore.booking.widgetId,
          propertyId: bookingConfirmationStore.property.id,
        },
      }
    : true,
);
</script>
