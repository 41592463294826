import type {
  PropertyThemeLogo,
  PropertyThemeLogoDTO,
} from '@/property/property-theme/logo/property-theme-logo';

export const mapPropertyThemeLogoFromDTO = (
  propertyThemeLogoDTO: PropertyThemeLogoDTO,
): PropertyThemeLogo => ({
  url: propertyThemeLogoDTO.url,
});
