<template>
  <div
    :style="{
      height: hasLoaded ? 'auto' : `${imageBoundingBoxEdgeLength}px`,
      width: hasLoaded ? 'auto' : `${imageBoundingBoxEdgeLength}px`,
    }"
  >
    <div>
      <img v-show="!hasLoaded" alt="" :src="imagePlaceholderURL" />

      <img v-show="hasLoaded" alt="" :src="imageURL" @load="hasLoaded = true" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { format } from '@cloudinary/url-gen/actions/delivery';
import { blur } from '@cloudinary/url-gen/actions/effect';
import { fit } from '@cloudinary/url-gen/actions/resize';
import { auto } from '@cloudinary/url-gen/qualifiers/format';
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import { computed, ref } from 'vue';
import { cloudinary } from '@/cloudinary';
import type { PropertyThemeLogo } from '@/property/property-theme/logo/property-theme-logo';

const props = defineProps<{
  propertyThemeLogo: PropertyThemeLogo;
  minEdgeLength: number;
  maxEdgeLength: number;
}>();

const hasLoaded = ref(false);

const shouldUseMinEdgeLength =
  useBreakpoints(breakpointsTailwind).smaller('md');

const imageBoundingBoxEdgeLength = computed(() =>
  shouldUseMinEdgeLength.value ? props.minEdgeLength : props.maxEdgeLength,
);

const generateBaseTransform = () =>
  cloudinary
    .image(props.propertyThemeLogo.url)
    .resize(
      fit()
        .height(imageBoundingBoxEdgeLength.value)
        .width(imageBoundingBoxEdgeLength.value),
    )
    .setDeliveryType('fetch')
    .delivery(format(auto()));

const imageURL = computed(() => generateBaseTransform().toURL());

const imagePlaceholderURL = computed(() =>
  generateBaseTransform().effect(blur(2000)).toURL(),
);
</script>
