import type {
  DiscountOfferUnitAdjustment,
  DiscountOfferUnitAdjustmentDTO,
} from '@/property/offer/discount-offer/adjustment/unit-adjustment/discount-offer-unit-adjustment';

export const mapDiscountOfferUnitAdjustmentFromDTO = (
  discountOfferUnitAdjustmentDTO: DiscountOfferUnitAdjustmentDTO,
): DiscountOfferUnitAdjustment => ({
  unitId: discountOfferUnitAdjustmentDTO.unitId,
  adjustmentAmount: Number(discountOfferUnitAdjustmentDTO.adjustmentAmount),
});
