import { defineStore } from 'pinia';
import { computed } from 'vue';
import { useQueryParams } from '@/url/query-params/query-params.composable';

export const useElementsStore = defineStore('elements', () => {
  const { elementsQueryParam: elements } = useQueryParams();

  const usedElements = computed(() => !!elements.value);

  return { elements, usedElements };
});
