import type { DayOfWeek } from '@/day-of-week/day-of-week';
import type {
  DiscountOffer,
  DiscountOfferDTO,
} from '@/property/offer/discount-offer/discount-offer';
import type {
  FreeNightOffer,
  FreeNightOfferDTO,
} from '@/property/offer/free-night-offer/free-night-offer';
import type {
  PackageOffer,
  PackageOfferDTO,
} from '@/property/offer/package-offer/package-offer';

export enum OfferType {
  Discount = 'discount',
  FreeNight = 'free_night',
  Package = 'package',
}

export enum OfferApplicableStayDaysType {
  NightOfStay = 'nights_of_stay',
  Arrivals = 'arrivals',
}

export interface BaseOffer {
  id: string;
  name: string;
  fromDate: string;
  toDate: string;
  applicableStayDaysType: OfferApplicableStayDaysType;
  applicableDaysOfWeek: DayOfWeek[];
}

export type Offer = DiscountOffer | FreeNightOffer | PackageOffer;

export interface DescribableOffer {
  description: string;
}

export interface AdvanceBookingLengthBoundableOffer {
  minimumAdvanceBookingLengthDays: number;
  maximumAdvanceBookingLengthDays: number;
}

export interface StayLengthBoundableOffer {
  minimumStayLengthDays: number;
  maximumStayLengthDays: number;
}

export interface WayToSellApplicableOffer {
  applicableWayToSellIds: number[];
}

export interface RefundableOffer {
  isRefundable: boolean;
}

export type OfferDTO = DiscountOfferDTO | FreeNightOfferDTO | PackageOfferDTO;
