import { GrammaticalGender } from '@/i18n/locales/gender/gender';
import type { TranslationKeyToMessageFunction } from '@/i18n/locales/message-functions/message-functions';
import type frFRStaticTranslations from '@/i18n/locales/messages/fr-FR.json';

export type FrFRMessageFunctions = Omit<
  TranslationKeyToMessageFunction,
  keyof typeof frFRStaticTranslations
>;

export const frFR: FrFRMessageFunctions = {
  youHaveSelectedTheLastUnitAvailable: ({ values }) => {
    switch (values.unitTypeGender) {
      case GrammaticalGender.Neuter:
      case GrammaticalGender.Masculine:
        return `Vous avez sélectionné le dernier ${values.unitTypeName} disponible`;
      case GrammaticalGender.Feminine:
        return `Vous avez sélectionné la dernière ${values.unitTypeName} disponible`;
    }
  },
  pleaseSelectAUnitToContinue: ({ values }) => {
    switch (values.unitTypeGender) {
      case GrammaticalGender.Neuter:
      case GrammaticalGender.Masculine:
        return `Veuillez sélectionner un ${values.unitTypeName} pour continuer`;
      case GrammaticalGender.Feminine:
        return `Veuillez sélectionner une ${values.unitTypeName} pour continuer`;
    }
  },
  someOfTheUnits: ({ values }) => {
    switch (values.unitTypeGender) {
      case GrammaticalGender.Neuter:
      case GrammaticalGender.Masculine:
        return `Certains des ${values.unitTypeName}`;
      case GrammaticalGender.Feminine:
        return `Certaines des ${values.unitTypeName}`;
    }
  },
  youAreAboutToRemoveTheLastUnit: ({ values }) => {
    switch (values.unitTypeGender) {
      case GrammaticalGender.Neuter:
      case GrammaticalGender.Masculine:
        return `Vous êtes sur le point de supprimer le dernier ${values.unitTypeName} de votre sélection. Vous serez redirigé vers la page de sélection des ${values.unitTypeName}. Voulez-vous continuer ?`;
      case GrammaticalGender.Feminine:
        return `Vous êtes sur le point de supprimer la dernière ${values.unitTypeName} de votre sélection. Vous serez redirigé vers la page de sélection des ${values.unitTypeName}. Voulez-vous continuer ?`;
    }
  },
  youHaveAlreadySelectedUnitsForDifferentStayDates: ({ values }) => {
    switch (values.unitTypeGender) {
      case GrammaticalGender.Neuter:
      case GrammaticalGender.Masculine:
        return `Vous avez déjà sélectionné différents ${values.unitTypeName} pour des dates de séjour différentes.`;
      case GrammaticalGender.Feminine:
        return `Vous avez déjà sélectionné différentes ${values.unitTypeName} pour des dates de séjour différentes.`;
    }
  },
  ifYouRemoveThisPrivateRateYourUnitsWillBeRemovedAndYouWillBeRedirected: ({
    values: { unitTypeGender, unitTypeName, unitTypeNamePlural },
  }) => {
    switch (unitTypeGender) {
      case GrammaticalGender.Neuter:
      case GrammaticalGender.Masculine:
        return `Si vous supprimez ce tarif privé, votre ${unitTypeNamePlural} actuellement sélectionné sera supprimé et vous serez redirigé vers la page de sélection du ${unitTypeName}. Voulez-vous continuer ?`;
      case GrammaticalGender.Feminine:
        return `Si vous supprimez ce tarif privé, votre ${unitTypeNamePlural} actuellement sélectionnée sera supprimée et vous serez redirigée vers la page de sélection de la ${unitTypeName}. Voulez-vous continuer ?`;
    }
  },
  unitsBooked: ({ values: { unitTypeName, unitTypeGender } }) => {
    switch (unitTypeGender) {
      case GrammaticalGender.Neuter:
      case GrammaticalGender.Masculine:
        return `${unitTypeName} réservés`;
      case GrammaticalGender.Feminine:
        return `${unitTypeName} réservées`;
    }
  },
};
