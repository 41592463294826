<template>
  <ul v-if="bookingPayments.length > 0">
    <li
      v-for="(bookingPayment, index) in bookingPayments"
      :key="index"
      class="border-b border-stone-300 py-6 last:border-none"
    >
      <BookingConfirmationPaymentDetailsTransaction
        :booking-payment="bookingPayment"
      />
    </li>
  </ul>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useBookingConfirmationStore } from '@/booking-confirmation-page/booking-confirmation.store';
import BookingConfirmationPaymentDetailsTransaction from '@/booking-confirmation-page/payment-details/BookingConfirmationPaymentDetailsTransaction.vue';

const { bookingPayments } = storeToRefs(useBookingConfirmationStore());
</script>
