import { isAfter, subDays } from 'date-fns';
import {
  createDateObject,
  getCurrentDateObjectInTimezone,
} from '@/date/date.utilities';
import type { LateBookingThreshold } from '@/property/late-booking-threshold/late-booking-threshold';

export const isDateCurrentlyAllowedByLateBookingThreshold = (
  date: string,
  timezone: string,
  { numberOfDaysBeforeArrival, time }: LateBookingThreshold,
): boolean => {
  let lateBookingThresholdDate = createDateObject(date);

  lateBookingThresholdDate.setHours(time.hours);
  lateBookingThresholdDate.setMinutes(time.minutes);

  lateBookingThresholdDate = subDays(
    lateBookingThresholdDate,
    numberOfDaysBeforeArrival,
  );

  const currentDate = getCurrentDateObjectInTimezone(timezone);

  return !isAfter(currentDate, lateBookingThresholdDate);
};
