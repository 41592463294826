import type { AmericanState } from '@/american-states/american-state';

export const americanStates: AmericanState[] = [
  { code: 'AL', displayName: 'Alabama' },
  { code: 'AK', displayName: 'Alaska' },
  { code: 'AZ', displayName: 'Arizona' },
  { code: 'AR', displayName: 'Arkansas' },
  { code: 'CA', displayName: 'California' },
  { code: 'CO', displayName: 'Colorado' },
  { code: 'CT', displayName: 'Connecticut' },
  { code: 'DC', displayName: 'District of Columbia' },
  { code: 'DE', displayName: 'Delaware' },
  { code: 'FL', displayName: 'Florida' },
  { code: 'GA', displayName: 'Georgia' },
  { code: 'HI', displayName: 'Hawaii' },
  { code: 'ID', displayName: 'Idaho' },
  { code: 'IL', displayName: 'Illinois' },
  { code: 'IN', displayName: 'Indiana' },
  { code: 'IA', displayName: 'Iowa' },
  { code: 'KS', displayName: 'Kansas' },
  { code: 'KY', displayName: 'Kentucky' },
  { code: 'LA', displayName: 'Louisiana' },
  { code: 'ME', displayName: 'Maine' },
  { code: 'MD', displayName: 'Maryland' },
  { code: 'MA', displayName: 'Massachusetts' },
  { code: 'MI', displayName: 'Michigan' },
  { code: 'MN', displayName: 'Minnesota' },
  { code: 'MS', displayName: 'Mississippi' },
  { code: 'MO', displayName: 'Missouri' },
  { code: 'MT', displayName: 'Montana' },
  { code: 'NE', displayName: 'Nebraska' },
  { code: 'NV', displayName: 'Nevada' },
  { code: 'NH', displayName: 'New Hampshire' },
  { code: 'NJ', displayName: 'New Jersey' },
  { code: 'NM', displayName: 'New Mexico' },
  { code: 'NY', displayName: 'New York' },
  { code: 'NC', displayName: 'North Carolina' },
  { code: 'ND', displayName: 'North Dakota' },
  { code: 'OH', displayName: 'Ohio' },
  { code: 'OK', displayName: 'Oklahoma' },
  { code: 'OR', displayName: 'Oregon' },
  { code: 'PA', displayName: 'Pennsylvania' },
  { code: 'RI', displayName: 'Rhode Island' },
  { code: 'SC', displayName: 'South Carolina' },
  { code: 'SD', displayName: 'South Dakota' },
  { code: 'TN', displayName: 'Tennessee' },
  { code: 'TX', displayName: 'Texas' },
  { code: 'UT', displayName: 'Utah' },
  { code: 'VT', displayName: 'Vermont' },
  { code: 'VA', displayName: 'Virginia' },
  { code: 'WA', displayName: 'Washington' },
  { code: 'WV', displayName: 'West Virginia' },
  { code: 'WI', displayName: 'Wisconsin' },
  { code: 'WY', displayName: 'Wyoming' },
];

export const findDisplayNameOfAmericanStateCode = (stateCode: string): string =>
  americanStates.find(({ code }) => code === stateCode)?.displayName ??
  stateCode;
