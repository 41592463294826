import { startOfDay, differenceInDays } from 'date-fns';
import {
  getNumberOfDaysInRange,
  getDatesInRange,
  getCurrentDateObjectInTimezone,
  createDateObject,
} from '@/date/date.utilities';
import type { StayDates } from '@/stay-dates/stay-dates';

export const getStayLengthFromStayDates = (stayDates: StayDates): number =>
  getNumberOfDaysInRange(stayDates.checkInDate, stayDates.checkOutDate);

export const getDaysOfStayFromStayDates = (stayDates: StayDates): string[] =>
  getDatesInRange(stayDates.checkInDate, stayDates.checkOutDate);

export const getNightsOfStayFromStayDates = (stayDates: StayDates): string[] =>
  getDatesInRange(stayDates.checkInDate, stayDates.checkOutDate, {
    includeToDate: false,
  });

export const getFinalNightOfStayFromStayDates = (
  stayDates: StayDates,
): string => getNightsOfStayFromStayDates(stayDates).at(-1)!;

export const isArrivalDateOfStayDates = (
  stayDates: StayDates,
  date: string,
): boolean => date === stayDates.checkInDate;

export const isDepartureDateOfStayDates = (
  stayDates: StayDates,
  date: string,
): boolean => date === stayDates.checkOutDate;

/**
 * Returns the number of days between "now" (in the given timezone) and
 * the arrival date.
 *
 * Note that the number of days is always rounded up. Meaning that if the
 * current time is only 6 hours before arrival, that would still be
 * considered as 1 day before arrival.
 */
export const getNumberOfDaysBetweenNowAndArrival = (
  stayDates: StayDates,
  timezone: string,
): number => {
  const currentDate = getCurrentDateObjectInTimezone(timezone);

  const startOfCurrentDate = startOfDay(currentDate);
  const startOfArrivalDate = startOfDay(
    createDateObject(stayDates.checkInDate),
  );

  return differenceInDays(startOfArrivalDate, startOfCurrentDate);
};
