import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { arrayContainSingleItem } from '@/array/array.utilities';
import type { ChildOccupant } from '@/occupancy/child-occupant/child-occupant';
import type { Occupancy } from '@/occupancy/occupancy';
import {
  getAgePartitionedChildrenInOccupancy,
  getAgePartitionedNumberOfChildrenInOccupancy,
  getNumberOfChildrenInOccupancy,
  getTotalNumberOfOccupantsInOccupancies,
} from '@/occupancy/occupancy.utilities';
import type { UnitType } from '@/property/unit/type/unit-type';
import { useUnitTypeCount } from '@/property/unit/type/unit-type-count.composable';
import { useLocaleString } from '@/string/locale-string.composable';

export const useOccupancy = () => {
  const { t, locale } = useI18n();
  const { toTitleCase, capitalize } = useLocaleString();

  const listFormatter = computed(
    () =>
      new Intl.ListFormat(locale.value, {
        style: 'long',
        type: 'conjunction',
      }),
  );

  const generateBasicDisplayText = (occupancy: Occupancy): string => {
    const { numberOfAdults } = occupancy;
    const { numberOfNonInfantChildren, numberOfInfantChildren } =
      getAgePartitionedNumberOfChildrenInOccupancy(occupancy);

    let displayText = t('adultCount', numberOfAdults);

    if (numberOfNonInfantChildren) {
      displayText += `, ${t('childCount', numberOfNonInfantChildren)}`;
    }

    if (numberOfInfantChildren) {
      displayText += `, ${t('infantCount', numberOfInfantChildren)}`;
    }

    return displayText;
  };

  const generateCountDisplayText = (
    occupancies: Occupancy[],
    unitType: UnitType,
  ): string => {
    if (arrayContainSingleItem(occupancies)) {
      const singleOccupancy = occupancies[0];

      const { numberOfAdults } = singleOccupancy;

      const numberOfChildren = getNumberOfChildrenInOccupancy(singleOccupancy);

      let displayText = t('adultCount', numberOfAdults);

      if (numberOfChildren > 0) {
        displayText += `, ${t('childCount', numberOfChildren)}`;
      }

      return displayText;
    }

    const numberOfUnits = occupancies.length;

    const totalNumberOfOccupants =
      getTotalNumberOfOccupantsInOccupancies(occupancies);

    return `${
      useUnitTypeCount(
        computed(() => unitType),
        numberOfUnits,
      ).unitTypeNameCount.value
    }, ${t('guestCount', totalNumberOfOccupants)}`;
  };

  const getAgeList = (childOccupants: ChildOccupant[]): string => {
    const childAges = childOccupants.map(({ age }) => age.toString());

    return capitalize(
      t(childAges.length > 1 ? 'ages' : 'age', {
        age: listFormatter.value.format(childAges),
      }),
    );
  };

  const generateBasicDisplayTextWithOccupantAges = (
    occupancy: Occupancy,
  ): string => {
    let displayText = toTitleCase(
      t('adultCount', { count: occupancy.numberOfAdults }),
    );

    const { nonInfantChildren, infantChildren } =
      getAgePartitionedChildrenInOccupancy(occupancy);

    if (nonInfantChildren.length > 0) {
      displayText += `, ${toTitleCase(
        t('childCount', { count: nonInfantChildren.length }),
      )} (${getAgeList(nonInfantChildren)})`;
    }

    if (infantChildren.length > 0) {
      displayText += `, ${toTitleCase(
        t('infantCount', { count: infantChildren.length }),
      )} (${getAgeList(infantChildren)})`;
    }

    return displayText;
  };

  return {
    generateBasicDisplayText,
    generateCountDisplayText,
    generateBasicDisplayTextWithOccupantAges,
  };
};
