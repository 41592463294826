export enum AvailabilityStatusType {
  ClosedToDeparture = 'close_to_departure',
  ClosedToArrival = 'close_to_arrival',
  NotAvailable = 'not_available',
  MinimumStay = 'minimumStay',
  NoRates = 'no_rates',
  SoldOut = 'sold_out',
  Unknown = 'unknown',
}

export type AvailabilityStatus =
  | {
      isAvailable: true;
      minimumStay: number;
    }
  | {
      isAvailable: false;
      reason:
        | { type: AvailabilityStatusType.ClosedToDeparture }
        | { type: AvailabilityStatusType.ClosedToArrival }
        | { type: AvailabilityStatusType.NotAvailable }
        | { type: AvailabilityStatusType.SoldOut }
        | { type: AvailabilityStatusType.Unknown }
        | { type: AvailabilityStatusType.MinimumStay; minimumStay: number };
    };
