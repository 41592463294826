export enum Locale {
  enGB = 'en-GB',
  deDE = 'de-DE',
  esES = 'es-ES',
  frFR = 'fr-FR',
  itIT = 'it-IT',
  ptPT = 'pt-PT',
}

export const supportedLocales = Object.keys(Locale);

export const languageFallbackLocales = {
  en: Locale.enGB,
  de: Locale.deDE,
  es: Locale.esES,
  fr: Locale.frFR,
  it: Locale.itIT,
  pt: Locale.ptPT,
};
