import type {
  DepositPolicyMonetaryCharge,
  DepositPolicyMonetaryChargeDTO,
} from '@/property/booking-policy/deposit-policy/charge/monetary/deposit-policy-monetary-charge';
import type {
  DepositPolicyNightlyCharge,
  DepositPolicyNightlyChargeDTO,
} from '@/property/booking-policy/deposit-policy/charge/nightly/deposit-policy-nightly-charge';
import type {
  DepositPolicyPerUnitMonetaryCharge,
  DepositPolicyPerUnitMonetaryChargeDTO,
} from '@/property/booking-policy/deposit-policy/charge/per-unit/deposit-policy-per-unit-monetary-charge';
import type {
  DepositPolicyPercentageCharge,
  DepositPolicyPercentageChargeDTO,
} from '@/property/booking-policy/deposit-policy/charge/percentage/deposit-policy-percentage-charge';

export enum DepositPolicyChargeType {
  Nightly = 'nightly',
  Percentage = 'percentage',
  Monetary = 'monetary',
  PerUnitMonetary = 'per_unit_monetary',
}

export type DepositPolicyCharge =
  | DepositPolicyMonetaryCharge
  | DepositPolicyPercentageCharge
  | DepositPolicyNightlyCharge
  | DepositPolicyPerUnitMonetaryCharge;

export type DepositPolicyChargeDTO =
  | DepositPolicyMonetaryChargeDTO
  | DepositPolicyPercentageChargeDTO
  | DepositPolicyNightlyChargeDTO
  | DepositPolicyPerUnitMonetaryChargeDTO;
