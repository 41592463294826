import { Decimal } from 'decimal.js';
import { applyTaxPercentageToValue } from '@/tax/tax.utilities';
import type { TaxableExtra } from '@/tax/taxable-extra/taxable-extra';

/**
 * From the given list of taxable extras, returns the total amount of
 * included and excluded VAT.
 */
export const calculateTaxableExtrasVatAmounts = (
  taxableExtras: TaxableExtra[],
): { included: number; excluded: number } => {
  const { included, excluded } =
    calculateTaxableExtrasDecimalVatAmounts(taxableExtras);

  return { included: included.toNumber(), excluded: excluded.toNumber() };
};

const calculateTaxableExtrasDecimalVatAmounts = (
  taxableExtras: TaxableExtra[],
): { included: Decimal; excluded: Decimal } =>
  taxableExtras.reduce(
    (vatAmounts, { tax: { isIncluded, percentage }, totalRate }) => {
      if (isIncluded) {
        vatAmounts.included = vatAmounts.included.add(
          applyTaxPercentageToValue(percentage, totalRate, true),
        );
      } else {
        vatAmounts.excluded = vatAmounts.excluded.add(
          applyTaxPercentageToValue(percentage, totalRate, false),
        );
      }

      return vatAmounts;
    },
    { included: new Decimal(0), excluded: new Decimal(0) },
  );
