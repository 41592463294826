<template>
  <ul v-if="meals.length > 0" class="grid gap-2">
    <li
      v-for="meal in meals"
      :key="meal.type"
      data-test="UnitSelectionPricePlanPackageMeal"
    >
      <div v-if="!searchStore.isLoading" class="flex items-center">
        <div>
          <IconCheck stroke-width="3" class="h-4 w-4 text-neutral-700" />
        </div>

        <span class="break-anywhere ml-2">{{ meal.name }}</span>
      </div>

      <AppSkeletonText v-else class="w-40" />
    </li>
  </ul>
</template>

<script setup lang="ts">
import { IconCheck } from '@tabler/icons-vue';
import type { Meal } from '@/property/meal/meal';
import { useSearchStore } from '@/search/search.store';
import AppSkeletonText from '@/ui/app-skeleton-text/AppSkeletonText.vue';

defineProps<{ meals: Meal[] }>();

const searchStore = useSearchStore();
</script>
