import { PaymentGatewayType } from '@/property/payment-gateway/payment-gateway';
import type {
  StripePaymentGateway,
  StripePaymentGatewayDTO,
} from '@/property/payment-gateway/stripe/stripe-payment-gateway';
import { mapStripeTransactionChargeFromDTO } from '@/property/payment-gateway/stripe/transaction-charge/stripe-transaction-charge.mapper';

export const mapStripePaymentGatewayFromDTO = (
  stripePaymentGatewayDTO: StripePaymentGatewayDTO,
): StripePaymentGateway => ({
  type: PaymentGatewayType.Stripe,
  isPreAuthEnabled: stripePaymentGatewayDTO.isPreAuthEnabled,
  shouldDepositIncludeTaxes: stripePaymentGatewayDTO.shouldDepositIncludeTaxes,
  publishableKey: stripePaymentGatewayDTO.publishableKey,
  zeroDecimalCurrencyMultiplier:
    stripePaymentGatewayDTO.zeroDecimalCurrencyMultiplier,
  transactionCharge: stripePaymentGatewayDTO.transactionCharge
    ? mapStripeTransactionChargeFromDTO(
        stripePaymentGatewayDTO.transactionCharge,
      )
    : undefined,
});
