<template>
  <div v-if="extra.description" translate="yes">
    <AppCollapsibleText :text="extra.description" :max-lines="MAX_LINES" />
  </div>
</template>

<script setup lang="ts">
import type { Extra } from '@/property/extra/extra';
import AppCollapsibleText from '@/ui/app-collapsible-text/AppCollapsibleText.vue';

const MAX_LINES = 2;

defineProps<{
  extra: Extra;
}>();
</script>
