<template>
  <div class="mb-5">
    <div class="flex">
      <div class="flex-1 text-xl font-semibold capitalize">
        {{ $t('promocodeApplied') }}
      </div>

      <div class="flex-1 text-end">
        {{ promocode.promocodeText }}

        <span v-if="discountSummaryText" class="text-neutral-500">
          - {{ discountSummaryText }}
        </span>
      </div>
    </div>

    <div class="flex justify-end text-neutral-500">
      {{ promocode.description }}
    </div>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useBookingConfirmationStore } from '@/booking-confirmation-page/booking-confirmation.store';
import type { Promocode } from '@/property/promocode/promocode';
import { usePromocodeDiscountSummary } from '@/property/promocode/promocode.composable';

const props = defineProps<{
  promocode: Promocode;
}>();

const { property } = storeToRefs(useBookingConfirmationStore());

const { discountSummaryText } = usePromocodeDiscountSummary(
  computed(() => props.promocode),
  property,
);
</script>
