import i18n from '@/i18n';
import type { FreeNightOffer } from '@/property/offer/free-night-offer/free-night-offer';
import { FreeNightOfferType } from '@/property/offer/free-night-offer/free-night-offer';

export const useFreeNightOffer = (offer: FreeNightOffer) => {
  const { t } = i18n.global;

  const generateFreeNightOfferSummary = (stayLength: number): string =>
    t(
      'freeNightOfferSummary',
      {
        stayLength: stayLength,
        typeOfFreeNights:
          offer.freeNightType === FreeNightOfferType.Cheapest
            ? t('cheapest')
            : t('last'),
        numberOfFreeNights: offer.numberOfFreeNights,
      },
      offer.numberOfFreeNights,
    );

  return { generateFreeNightOfferSummary };
};
