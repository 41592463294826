<template>
  <AppLinkButton
    :text="isCondensed ? $t('T&Cs') : $t('termsAndConditions')"
    :label="$t('termsAndConditions')"
    variant="basic"
    @click="isTermsAndConditionsModalOpen = true"
  />

  <ExtendedTermsAndConditionsModal
    :extended-terms-and-conditions="extendedTermsAndConditions"
    :is-open="isTermsAndConditionsModalOpen"
    @close="isTermsAndConditionsModalOpen = false"
  />
</template>

<script setup lang="ts">
import { ref } from 'vue';
import ExtendedTermsAndConditionsModal from '@/terms-and-conditions/extended/modal/ExtendedTermsAndConditionsModal.vue';
import AppLinkButton from '@/ui/app-link-button/AppLinkButton.vue';

defineProps<{ extendedTermsAndConditions: string; isCondensed: boolean }>();

const isTermsAndConditionsModalOpen = ref(false);
</script>
