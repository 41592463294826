<template>
  <AppLayout :property="searchStore.activeProperty">
    <StageSelector />

    <slot></slot>

    <div class="m-4">
      <PropertyDetails :property="searchStore.activeProperty" />

      <AppBloomByFreetobookLink class="mt-10" />
    </div>

    <ModalBookingSummary />

    <BookingStageBookingConfirmationErrorModal />
  </AppLayout>
</template>

<script setup lang="ts">
import BookingStageBookingConfirmationErrorModal from '@/booking-stage/booking-confirmation-error/BookingStageBookingConfirmationErrorModal.vue';
import StageSelector from '@/booking-stage/selector/BookingStageSelector.vue';
import ModalBookingSummary from '@/booking-summary/ModalBookingSummary.vue';
import AppLayout from '@/layouts/AppLayout.vue';
import PropertyDetails from '@/property/PropertyDetails.vue';
import { useSearchStore } from '@/search/search.store';
import AppBloomByFreetobookLink from '@/ui/app-bloom-by-freetobook-link/AppBloomByFreetobookLink.vue';

const searchStore = useSearchStore();
</script>
