<template>
  <img
    v-if="!hasErrored"
    class="cursor-pointer"
    alt=""
    :src="url"
    @error="hasErrored = true"
  />

  <AppImageCarouselImageError v-else />
</template>

<script setup lang="ts">
import { ref } from 'vue';
import AppImageCarouselImageError from '@/ui/app-image-carousel/AppImageCarouselImageError.vue';

defineProps<{ url: string }>();

const hasErrored = ref(false);
</script>
