import { Decimal } from 'decimal.js';

export const applyTaxPercentageToValue = (
  taxPercentage: number,
  value: number,
  valueIncludesTax: boolean,
): number =>
  applyDecimalTaxPercentageToValue(
    new Decimal(taxPercentage),
    value,
    valueIncludesTax,
  ).toNumber();

const applyDecimalTaxPercentageToValue = (
  decimalTaxPercentage: Decimal,
  value: number,
  valueIncludesTax: boolean,
): Decimal =>
  valueIncludesTax
    ? decimalTaxPercentage
        .div(decimalTaxPercentage.add(1))
        .times(value)
        .toDecimalPlaces(2)
    : decimalTaxPercentage.times(value).toDecimalPlaces(2);
