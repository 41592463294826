<template>
  <AppFormValidatedInput
    v-slot="{ isValid }"
    :value="guestStore.guest.address.countryCode"
    :validation-rules="[isRequiredRule($t('pleaseSelectACountry'))]"
  >
    <AppListbox v-model="guestStore.guest.address.countryCode">
      <AppListboxButton
        data-test="GuestDetailsCountrySelector__button"
        :text="countryDisplayName"
        class="border border-gray-300 py-4 text-left"
        :class="{
          'border-red-500 bg-red-500/10 text-red-700': !isValid,
          'text-gray-500': !guestStore.guest.address.countryCode && isValid,
        }"
      />

      <div class="absolute z-10">
        <AppListboxOptions>
          <AppListboxOption
            v-for="{ regionCode, displayName } in regions"
            :key="regionCode"
            :value="regionCode"
            :label="displayName"
          />
        </AppListboxOptions>
      </div>
    </AppListbox>
  </AppFormValidatedInput>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useGuestStore } from '@/guest/guest.store';
import {
  getRegionCodes,
  getDisplayNameOfRegionCode,
  getRegionCodeOfLocale,
} from '@/intl/intl.utilities';
import { useSearchStore } from '@/search/search.store';
import { useLocaleString } from '@/string/locale-string.composable';
import { isRequiredRule } from '@/ui/app-form/app-form-input-validation-rules.utilities';
import AppFormValidatedInput from '@/ui/app-form/AppFormValidatedInput.vue';
import AppListbox from '@/ui/app-listbox/AppListbox.vue';
import AppListboxButton from '@/ui/app-listbox/AppListboxButton.vue';
import AppListboxOption from '@/ui/app-listbox/AppListboxOption.vue';
import AppListboxOptions from '@/ui/app-listbox/AppListboxOptions.vue';

const { t, locale } = useI18n();

const searchStore = useSearchStore();
const guestStore = useGuestStore();

const { capitalize } = useLocaleString();

const regions = computed(() =>
  getRegionCodes()
    .map((regionCode) => ({
      regionCode,
      displayName: getDisplayNameOfRegionCode(regionCode, locale.value),
    }))
    .sort((regionA, regionB) =>
      regionA.displayName.localeCompare(regionB.displayName),
    ),
);

const countryDisplayName = computed(() =>
  guestStore.guest.address.countryCode
    ? getDisplayNameOfRegionCode(
        guestStore.guest.address.countryCode,
        locale.value,
      )
    : capitalize(t('country')),
);

/**
 * If the guest doesn't already have a country code, use the region code of
 * their browser's language.
 *
 * The exception to this is for Irish properties, where the country code
 * should be set to Ireland, regardless of the browser's language.
 */
const setDefaultCountryCode = () => {
  if (guestStore.guest.address.countryCode) {
    return;
  }

  guestStore.guest.address.countryCode =
    searchStore.activeProperty.address.countryCode === 'IE'
      ? 'IE'
      : getRegionCodeOfLocale(navigator.language);
};

onBeforeMount(setDefaultCountryCode);

watch(
  () => guestStore.guest.address.countryCode,
  () => {
    guestStore.guest.address.administrativeArea = '';
  },
);
</script>
