export enum CardType {
  AmericanExpress = 'american_express',
  BC = 'bc',
  CartaSi = 'carta_si',
  CarteBlanche = 'carte_blanche',
  CarteBleue = 'carte_bleue',
  Dankort = 'dankort',
  DinersClub = 'diners_club',
  Discover = 'discover',
  Electron = 'electron',
  JCB = 'jcb',
  Maestro = 'maestro',
  Mastercard = 'mastercard',
  MCAlaska = 'mc_alaska',
  MCCanada = 'mc_canada',
  Solo = 'solo',
  UnionPay = 'union_pay',
  VisaCredit = 'visa_credit',
  VisaDebit = 'visa_debit',
  CartesBancairesCredit = 'cartes_bancaires_credit',
  CartesBancairesDebit = 'cartes_bancaires_debit',
}

export interface Card {
  isMain: boolean;
  name: string;
}
