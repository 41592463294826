import { formatDate } from '@/date/date.utilities';
import type { DayOfWeek } from '@/day-of-week/day-of-week';

export const convertDateToDayOfWeek = (date: string): DayOfWeek =>
  formatDate(date, 'EEEE').toLowerCase() as DayOfWeek;

export const isDateWithinDaysOfWeek = (
  date: string,
  daysOfWeek: DayOfWeek[],
): boolean => {
  const dayOfWeek = convertDateToDayOfWeek(date);

  return daysOfWeek.includes(dayOfWeek);
};
