import type {
  WayToSellAdjustment,
  WayToSellAdjustmentDTO,
} from '@/property/way-to-sell/adjustment/way-to-sell-adjustment';

export const mapWayToSellAdjustmentFromDTO = (
  wayToSellAdjustmentDTO: WayToSellAdjustmentDTO,
): WayToSellAdjustment => ({
  type: wayToSellAdjustmentDTO.type,
  adjustmentAmount: Number(wayToSellAdjustmentDTO.adjustmentAmount),
});
