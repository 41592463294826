<template>
  <div
    class="flex last:mb-6 sm:last:mb-12"
    :class="{ 'line-through': booking.isCancelled }"
  >
    <span v-if="!booking.isCancelled" class="mr-2 text-green-600">
      <IconCheck :size="22" />
    </span>

    <slot></slot>
  </div>
</template>
<script setup lang="ts">
import { IconCheck } from '@tabler/icons-vue';
import { storeToRefs } from 'pinia';
import { useBookingConfirmationStore } from '@/booking-confirmation-page/booking-confirmation.store';

const { booking } = storeToRefs(useBookingConfirmationStore());
</script>
