<template>
  <div class="flex items-center space-x-4">
    <AppSecondaryButton
      class="w-full p-3"
      :button-text="toTitleCase($t('cancel'))"
      @click="$emit('close')"
    />

    <AppPrimaryButton
      class="w-full p-3"
      :button-text="toTitleCase($t('switch'))"
      @click="$emit('switch')"
    />
  </div>
</template>

<script setup lang="ts">
import { useLocaleString } from '@/string/locale-string.composable';
import AppPrimaryButton from '@/ui/app-primary-button/AppPrimaryButton.vue';
import AppSecondaryButton from '@/ui/app-secondary-button/AppSecondaryButton.vue';

defineEmits(['close', 'switch']);

const { toTitleCase } = useLocaleString();
</script>
