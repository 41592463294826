<template>
  <p
    v-if="bookingStageStore.currentStage.shouldDisplayRecaptchaInformation"
    class="text-xs text-slate-700"
  >
    This site is protected by reCAPTCHA and the Google
    <a
      class="text-blue-600 underline"
      href="https://policies.google.com/privacy"
      target="_blank"
      >Privacy Policy</a
    >
    and
    <a
      class="text-blue-600 underline"
      href="https://policies.google.com/terms"
      target="_blank"
      >Terms of Service</a
    >
    apply.
  </p>
</template>

<script setup lang="ts">
import { useBookingStageStore } from '@/booking-stage/booking-stage.store';

const bookingStageStore = useBookingStageStore();
</script>
