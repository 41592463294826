<template>
  <div v-if="shouldUseModal">
    <UnitSelectionSearchBarButton
      :icon="IconCalendar"
      @click="
        unitSelectionStore.isSearchPropertyAvailabilityCalendarModalOpen =
          !unitSelectionStore.isSearchPropertyAvailabilityCalendarModalOpen
      "
    >
      <UnitSelectionSearchBarAvailabilityCalendarButtonContent />
    </UnitSelectionSearchBarButton>
  </div>

  <AppPopover v-else v-slot="{ close }">
    <AppPopoverButton :as="UnitSelectionSearchBarButton" :icon="IconCalendar">
      <UnitSelectionSearchBarAvailabilityCalendarButtonContent />
    </AppPopoverButton>

    <div
      ref="popoverPanel"
      class="absolute left-0 right-0 top-full flex scroll-mt-[calc(theme(spacing.inline-booking-stage-selector-height)+theme(spacing.unit-selection-search-bar-height))] justify-center"
    >
      <AppPopoverPanel>
        <AvailabilityCalendarContainer
          :check-in-date="searchStore.stayDates.checkInDate"
          :check-out-date="searchStore.stayDates.checkOutDate"
          :auto-select-stay-dates="autoSelectStayDates"
          @vue:mounted="scrollPopoverPanelIntoView"
        />

        <template #footer>
          <AvailabilityCalendarFooter
            :button-text="capitalize($t('search'))"
            :icon="IconSearch"
            @submit="
              () => {
                close();
                updateSearchDates();
              }
            "
          />
        </template>
      </AppPopoverPanel>
    </div>
  </AppPopover>
</template>

<script setup lang="ts">
import { IconCalendar, IconSearch } from '@tabler/icons-vue';
import { useBreakpoints, breakpointsTailwind } from '@vueuse/core';
import { type Ref, ref } from 'vue';
import AvailabilityCalendarContainer from '@/availability-calendar/AvailabilityCalendarContainer.vue';
import AvailabilityCalendarFooter from '@/availability-calendar/AvailabilityCalendarFooter.vue';
import { useSearchStore } from '@/search/search.store';
import { useLocaleString } from '@/string/locale-string.composable';
import AppPopover from '@/ui/app-popover/AppPopover.vue';
import AppPopoverButton from '@/ui/app-popover/AppPopoverButton.vue';
import AppPopoverPanel from '@/ui/app-popover/AppPopoverPanel.vue';
import UnitSelectionSearchBarAvailabilityCalendarButtonContent from '@/unit-selection-page/search-bar/availability-calendar/UnitSelectionSearchBarAvailabilityCalendarButtonContent.vue';
import UnitSelectionSearchBarButton from '@/unit-selection-page/search-bar/button/UnitSelectionSearchBarButton.vue';
import { useUnitSelectionSearchPropertyAvailabilityCalendar } from '@/unit-selection-page/search-property-availability-calendar/unit-selection-search-property-availability-calendar.composable';
import { useUnitSelectionStore } from '@/unit-selection-page/unit-selection.store';

const searchStore = useSearchStore();
const unitSelectionStore = useUnitSelectionStore();

const shouldUseModal = useBreakpoints(breakpointsTailwind).smaller('md');
const { capitalize } = useLocaleString();

const { autoSelectStayDates, updateSearchDates } =
  useUnitSelectionSearchPropertyAvailabilityCalendar();

const popoverPanel: Ref<HTMLElement | null> = ref(null);

const scrollPopoverPanelIntoView = () => {
  popoverPanel.value?.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
  });
};
</script>
