<template>
  <AppAnimatedHeightContainer>
    <Transition enter-active-class="duration-700" enter-from-class="opacity-0">
      <template v-if="status.type === CodeInputStatusType.Valid">
        <CodeInputFeedbackItemValidPromocode
          v-if="status.resource.type === CodeResourceType.Promocode"
          :promocode="status.resource.promocode"
        />

        <CodeInputFeedbackItemValidPrivateRateOverride v-else />
      </template>

      <CodeInputFeedbackItemInvalid
        v-else-if="status.type === CodeInputStatusType.Invalid"
      />
    </Transition>
  </AppAnimatedHeightContainer>
</template>

<script setup lang="ts">
import { CodeInputStatusType } from '@/code/input/code-input-status';
import { useCodeInputStore } from '@/code/input/code-input.store';
import CodeInputFeedbackItemInvalid from '@/code/input/feedback/item/CodeInputFeedbackItemInvalid.vue';
import CodeInputFeedbackItemValidPrivateRateOverride from '@/code/input/feedback/item/CodeInputFeedbackItemValidPrivateRateOverride.vue';
import CodeInputFeedbackItemValidPromocode from '@/code/input/feedback/item/CodeInputFeedbackItemValidPromocode.vue';
import { CodeResourceType } from '@/code/resource/code-resource';
import AppAnimatedHeightContainer from '@/ui/app-animated-height-container/AppAnimatedHeightContainer.vue';

const { status } = useCodeInputStore();
</script>
