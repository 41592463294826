<template>
  <div
    class="mx-3 mb-10 mt-6 flex flex-col items-center py-5 sm:mx-6 lg:py-10"
    data-test="UnitSelectionNoAvailability"
  >
    <div class="flex flex-col items-center justify-center gap-3 sm:flex-row">
      <IconAlertCircle stroke-width="1.2" :size="48" />

      <div class="flex flex-col gap-2 text-center sm:text-left">
        <i18n-t
          v-if="showPropertyName"
          keypath="propertyIsNotAvailableOnStayDates"
          tag="p"
          scope="global"
        >
          <template #propertyName>
            {{ searchStore.activeProperty.name }}
          </template>

          <template #checkInDate>
            <span class="font-semibold">
              {{ checkInDateDisplayText }}
            </span>
          </template>

          <template #checkOutDate>
            <span class="font-semibold">
              {{ checkOutDateDisplayText }}
            </span>
          </template>
        </i18n-t>

        <i18n-t
          v-else
          keypath="sorryThereAreNoUnitsAvailableOnStayDates"
          tag="p"
          scope="global"
        >
          <template #unitTypeName>
            {{ unitTypeNamePlural }}
          </template>

          <template #checkInDate>
            <span class="font-semibold">
              {{ checkInDateDisplayText }}
            </span>
          </template>

          <template #checkOutDate>
            <span class="font-semibold">
              {{ checkOutDateDisplayText }}
            </span>
          </template>
        </i18n-t>

        <template v-if="canShowSpecificAvailabilityProblem">
          <i18n-t
            v-if="searchStore.activePropertyOverStay.isClosedToArrival"
            keypath="dateIsNotAvailableForArrival"
            tag="p"
            scope="global"
          >
            <template #date>
              <span class="font-semibold">
                {{ checkInDateDisplayText }}
              </span>
            </template>
          </i18n-t>

          <i18n-t
            v-else-if="
              searchStore.activePropertyOverStay.allUnitsAreUnavailable &&
              searchStore.activePropertyOverStay
                .hasUnitsThatOnlyViolateMinimumStay
            "
            keypath="toArriveOnDateThereIsAMinimumStayOfCountNights"
            tag="p"
            scope="global"
          >
            <template #date>
              <span class="font-semibold">
                {{ checkInDateDisplayText }}
              </span>
            </template>

            <template #count>
              <span class="font-semibold">
                {{
                  searchStore.activePropertyOverStay
                    .shortestMinimumStayForUnitsThatOnlyViolateMinimumStay
                }}
              </span>
            </template>
          </i18n-t>

          <i18n-t
            v-else-if="searchStore.activePropertyOverStay.isClosedToDeparture"
            keypath="dateIsNotAvailableForDeparture"
            tag="p"
            scope="global"
          >
            <template #date>
              <span class="font-semibold">
                {{ checkOutDateDisplayText }}
              </span>
            </template>
          </i18n-t>
        </template>
      </div>
    </div>

    <div
      v-if="shouldShowThatOtherUnitsAreAvailableOutwithPrivateRateOverride"
      class="mt-2 text-center"
    >
      {{
        $t('thereAreOtherUnitsAvailableIfYouRemoveYourPrivateCode', {
          unitTypeName: unitTypeNamePlural,
        })
      }}
    </div>

    <div class="mt-10 w-full md:w-auto">
      <div
        v-if="shouldShowThatOtherUnitsAreAvailableOutwithPrivateRateOverride"
        class="mb-2"
      >
        <UnitSelectionNoAvailabilityButtonContainer>
          <AppSecondaryButton
            class="w-full px-6 py-2"
            :button-text="
              $t('showUnitsWithoutPrivateCode', {
                unitTypeName: capitalize(unitTypeNamePlural),
              })
            "
            @click="onRemovePrivateRateOverride"
          />
        </UnitSelectionNoAvailabilityButtonContainer>
      </div>

      <UnitSelectionNoAvailabilityButtonContainer>
        <AppPrimaryButton
          class="w-full px-6 py-2"
          :button-text="$t('tryAlternativeDates')"
          :icon="IconSearch"
          @click="
            unitSelectionStore.isSearchPropertyAvailabilityCalendarModalOpen =
              !unitSelectionStore.isSearchPropertyAvailabilityCalendarModalOpen
          "
        />
      </UnitSelectionNoAvailabilityButtonContainer>
    </div>
  </div>
</template>

<script setup lang="ts">
import { IconSearch, IconAlertCircle } from '@tabler/icons-vue';
import { useEventBus } from '@vueuse/core';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import { useCodeResourceStore } from '@/code/resource/code-resource.store';
import { createDateObject } from '@/date/date.utilities';
import { codeResourceRemoveEventBusKey } from '@/event-bus/event-bus';
import { findPrivateRateOverrideUnitById } from '@/private-rate-override/private-rate-override.utilities';
import { useUnitType } from '@/property/unit/type/unit-type.composable';
import { useUnitOverStay } from '@/property/unit/unit-over-stay/unit-over-stay.composable';
import { useSearchStore } from '@/search/search.store';
import { useLocaleString } from '@/string/locale-string.composable';
import AppPrimaryButton from '@/ui/app-primary-button/AppPrimaryButton.vue';
import AppSecondaryButton from '@/ui/app-secondary-button/AppSecondaryButton.vue';
import UnitSelectionNoAvailabilityButtonContainer from '@/unit-selection-page/no-availability/UnitSelectionNoAvailabilityButtonContainer.vue';
import { useUnitSelectionStore } from '@/unit-selection-page/unit-selection.store';

defineProps<{ showPropertyName: boolean }>();

const { d } = useI18n();

const codeResourceStore = useCodeResourceStore();
const searchStore = useSearchStore();
const unitSelectionStore = useUnitSelectionStore();
const bookingItineraryStore = useBookingItineraryStore();

const { capitalize } = useLocaleString();

/**
 * We only want to show the reason the search was unavailable if there is either:
 * - At least one available unit (meaning the property must be closed to arrival/departure)
 * - At least one unit that is unavailable only because it violates the minimum stay
 *
 * Otherwise, we'll assume the search is effectively not "recoverable", and show no
 * specific reason (e.g., all units are closed out, sold out, etc.)
 */
const canShowSpecificAvailabilityProblem = computed(
  () =>
    searchStore.activePropertyOverStay.hasAvailableUnits ||
    searchStore.activePropertyOverStay.hasUnitsThatOnlyViolateMinimumStay,
);

const { unitTypeNamePlural } = useUnitType(
  computed(() => searchStore.activeProperty.unitType),
);

const checkInDateDisplayText = computed(() =>
  generateDateDisplayText(bookingItineraryStore.stayDates!.checkInDate),
);

const checkOutDateDisplayText = computed(() =>
  generateDateDisplayText(bookingItineraryStore.stayDates!.checkOutDate),
);

const shouldShowThatOtherUnitsAreAvailableOutwithPrivateRateOverride = computed(
  () => {
    const { privateRateOverride } = codeResourceStore;

    if (!privateRateOverride) {
      return false;
    }

    const { isClosedToArrival, isClosedToDeparture } =
      searchStore.activePropertyOverStay;

    if (isClosedToArrival || isClosedToDeparture) {
      return false;
    }

    return searchStore.activeProperty.units
      .filter(
        ({ id }) => !findPrivateRateOverrideUnitById(privateRateOverride, id),
      )
      .map((unit) =>
        useUnitOverStay(unit, searchStore.activePropertyAvailability),
      )
      .some(({ isAvailable }) => isAvailable);
  },
);

const onRemovePrivateRateOverride = () => {
  codeResourceStore.clear();

  useEventBus(codeResourceRemoveEventBusKey).emit();
};

const generateDateDisplayText = (date: string): string =>
  d(createDateObject(date), {
    day: 'numeric',
    month: 'long',
  });
</script>
