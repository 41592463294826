import type {
  PetsPolicyFreeCharge,
  PetsPolicyFreeChargeDTO,
} from '@/property/pets-policy/charge/free/pets-policy-free-charge';
import type {
  PetsPolicyPerDayMonetaryCharge,
  PetsPolicyPerDayMonetaryChargeDTO,
} from '@/property/pets-policy/charge/per-day-monetary/pets-policy-per-day-monetary-charge';
import type {
  PetsPolicyPerStayMonetaryCharge,
  PetsPolicyPerStayMonetaryChargeDTO,
} from '@/property/pets-policy/charge/per-stay-monetary/pets-policy-per-stay-monetary';

export enum PetsPolicyChargeType {
  Free = 'free',
  PerDayMonetary = 'per_day_monetary',
  PerStayMonetary = 'per_stay_monetary',
}

export type PetsPolicyCharge =
  | PetsPolicyFreeCharge
  | PetsPolicyPerDayMonetaryCharge
  | PetsPolicyPerStayMonetaryCharge;

export type PetsPolicyChargeDTO =
  | PetsPolicyFreeChargeDTO
  | PetsPolicyPerDayMonetaryChargeDTO
  | PetsPolicyPerStayMonetaryChargeDTO;
