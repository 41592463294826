<template>
  <div
    v-if="sagePayCheckoutStore.cardErrorMessage"
    :class="{ 'animate-shake': shouldAnimateCardErrorMessage }"
    class="mb-2"
    @animationend="shouldAnimateCardErrorMessage = false"
  >
    <PaymentsCardError :message="sagePayCheckoutStore.cardErrorMessage" />
  </div>

  <div
    class="relative overflow-hidden rounded border border-zinc-300 px-6 py-3 xl:max-w-lg"
    :class="{
      'h-48': sagePayCheckoutStore.isRenderingCheckoutForm,
    }"
  >
    <SagePayCheckoutForm v-if="sagePayCheckoutStore.canRenderCheckoutForm" />

    <SagePayCheckoutLoadingOverlay />
  </div>
</template>

<script setup lang="ts">
import { useScriptTag, whenever } from '@vueuse/core';
import { onActivated, onBeforeMount, ref } from 'vue';
import PaymentsCardError from '@/payments-page/PaymentsCardError.vue';
import { useSagePayCheckoutStore } from '@/payments-page/sage-pay-checkout/sage-pay-checkout.store';
import SagePayCheckoutForm from '@/payments-page/sage-pay-checkout/SagePayCheckoutForm.vue';
import SagePayCheckoutLoadingOverlay from '@/payments-page/sage-pay-checkout/SagePayCheckoutLoadingOverlay.vue';
import type { SagePayPaymentGateway } from '@/property/payment-gateway/sage-pay/sage-pay-payment-gateway';

defineProps<{ sagePayPaymentGateway: SagePayPaymentGateway }>();

const sagePayCheckoutStore = useSagePayCheckoutStore();

const shouldAnimateCardErrorMessage = ref(false);

whenever(
  () => sagePayCheckoutStore.cardErrorMessage,
  () => {
    shouldAnimateCardErrorMessage.value = true;
  },
);

onBeforeMount(sagePayCheckoutStore.loadMerchantSessionKey);

onActivated(() => {
  sagePayCheckoutStore.isRenderingCheckoutForm = true;
});

useScriptTag(
  `${import.meta.env.VITE_SAGE_PAY_PI_ORIGIN}/api/v1/js/sagepay.js`,
  () => (sagePayCheckoutStore.isLoadingCheckoutScript = false),
);
</script>
