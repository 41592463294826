import type { UserEventIdentifier } from '@/user-event/identifier/user-event-identifier';
import { type UserEvent, type UserEventAction } from '@/user-event/user-event';

export const createUserEvent = (
  userEventAction: UserEventAction,
  userEventIdentifier: UserEventIdentifier,
): UserEvent => ({
  userEventIdentifier: {
    uniqueUserId: userEventIdentifier.uniqueUserId,
    createdAtTimestamp: userEventIdentifier.createdAtTimestamp,
  },
  userEventAction,
  userAgent: navigator.userAgent,
  screenSize: {
    width: window.screen.width,
    height: window.screen.height,
  },
  datetime: Date.now(),
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
});
