import { Decimal } from 'decimal.js';
import { partition } from 'lodash-es';
import type {
  Extra,
  NonSelectableNightExtra,
  SelectableNightExtra,
} from '@/property/extra/extra';
import { ExtraChargeType } from '@/property/extra/extra';
import type { Property } from '@/property/property';
import { comparePropertyExtrasByPriority } from '@/property/property.utilities';
import type { StayDates } from '@/stay-dates/stay-dates';
import {
  getFinalNightOfStayFromStayDates,
  getStayLengthFromStayDates,
} from '@/stay-dates/stay-dates.utilities';

/**
 * If an extra does have an associated date range, it can only be applied when at
 * least one night of stay lies within the range. Note, this does not include the
 * check-out date.
 */
export const isExtraApplicableOnStayDates = (
  { dateRange }: Extra,
  stayDates: StayDates,
): boolean => {
  if (!dateRange) {
    return true;
  }

  const finalNightOfStay = getFinalNightOfStayFromStayDates(stayDates);

  const isCheckInDateBeforeExtraEnds =
    stayDates.checkInDate <= dateRange.toDate;
  const isFinalNightOfStayAfterExtraStarts =
    finalNightOfStay >= dateRange.fromDate;

  return isCheckInDateBeforeExtraEnds && isFinalNightOfStayAfterExtraStarts;
};

export const sortExtrasByPriority = (
  extras: Extra[],
  property: Property,
): Extra[] =>
  [...extras].sort((extraA, extraB) =>
    comparePropertyExtrasByPriority(property, extraA, extraB),
  );

export const partitionExtrasByMandatoryAndOptional = (
  extras: Extra[],
): [Extra[], Extra[]] => partition(extras, ({ isMandatory }) => isMandatory);

export const getChargeTypeSuffixTranslationKeyForExtra = (extra: Extra) => {
  switch (extra.chargeType) {
    case ExtraChargeType.AllNightsOfStay:
      return extra.isMandatory ? 'perNight' : 'eachPerNight';
    case ExtraChargeType.SelectNightsOfStay:
      return 'eachPerNight';
    case ExtraChargeType.Single:
      return extra.isMandatory ? undefined : 'each';
  }
};

export const calculateTotalRunningRateForSelectableNightExtra = (
  extra: SelectableNightExtra,
  quantity: number,
  numberOfNightsApplied: number,
): number =>
  calculateTotalRunningRate(extra.charge, quantity, numberOfNightsApplied);

export const calculateTotalRunningRateForAllNightsExtra = (
  extra: NonSelectableNightExtra,
  quantity: number,
  stayDates: StayDates,
): number =>
  calculateTotalRunningRate(
    extra.charge,
    getStayLengthFromStayDates(stayDates),
    quantity,
  );

export const calculateTotalRunningRateForSingleNightExtra = (
  extra: NonSelectableNightExtra,
  quantity: number,
) => calculateTotalRunningRate(extra.charge, 1, quantity);

const calculateTotalRunningRate = (
  charge: number,
  numberOfNightsApplied: number,
  quantity: number,
): number =>
  new Decimal(charge).mul(numberOfNightsApplied).mul(quantity).toNumber();
