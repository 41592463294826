import type { NumberFormat } from '@intlify/core-base';

const numberFormat: NumberFormat = {
  currency: {
    style: 'currency',
    currency: 'EUR',
  },
  percent: {
    style: 'percent',
    maximumFractionDigits: 2,
  },
};

export default numberFormat;
