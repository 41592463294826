import type { PropertyAvailability } from '@/availability/property-availability/property-availability';
import { findPropertyAvailabilityOfferAvailabilityByDate } from '@/availability/property-availability/property-availability.utilities';
import { getCurrentDateInTimezone, subtractDays } from '@/date/date.utilities';
import { isDateWithinDaysOfWeek } from '@/day-of-week/day-of-week.utilities';
import {
  discountOfferAppliesToUnit,
  discountOfferAppliesToWayToSell,
  isDiscountOfferApplicableOnStayDates,
} from '@/property/offer/discount-offer/discount-offer.utilities';
import {
  freeNightOfferAppliesToUnit,
  freeNightOfferAppliesToWayToSell,
  isFreeNightOfferApplicableOnStayDates,
} from '@/property/offer/free-night-offer/free-night-offer.utilities';
import type {
  AdvanceBookingLengthBoundableOffer,
  Offer,
  StayLengthBoundableOffer,
  WayToSellApplicableOffer,
} from '@/property/offer/offer';
import { OfferType } from '@/property/offer/offer';
import {
  isPackageOfferApplicableOnStayDates,
  packageOfferAppliesToUnit,
  packageOfferAppliesToWayToSell,
} from '@/property/offer/package-offer/package-offer.utilities';
import type { Unit } from '@/property/unit/unit';
import type { StayDates } from '@/stay-dates/stay-dates';

export const isOfferApplicableOnStayDates = (
  offer: Offer,
  propertyAvailability: PropertyAvailability,
  stayDates: StayDates,
  timezone: string,
): boolean => {
  switch (offer.offerType) {
    case OfferType.Discount:
      return isDiscountOfferApplicableOnStayDates(
        offer,
        propertyAvailability,
        stayDates,
        timezone,
      );
    case OfferType.FreeNight:
      return isFreeNightOfferApplicableOnStayDates(
        offer,
        propertyAvailability,
        stayDates,
      );
    case OfferType.Package:
      return isPackageOfferApplicableOnStayDates(
        offer,
        propertyAvailability,
        stayDates,
        timezone,
      );
  }
};

export const offerAppliesToUnit = (offer: Offer, unit: Unit): boolean => {
  switch (offer.offerType) {
    case OfferType.Discount:
      return discountOfferAppliesToUnit(offer, unit);
    case OfferType.FreeNight:
      return freeNightOfferAppliesToUnit(offer, unit.id);
    case OfferType.Package:
      return packageOfferAppliesToUnit(offer, unit.id);
  }
};

export const offerAppliesToWayToSell = (
  offer: Offer,
  wayToSellId: number,
): boolean => {
  switch (offer.offerType) {
    case OfferType.Discount:
      return discountOfferAppliesToWayToSell(offer, wayToSellId);
    case OfferType.FreeNight:
      return freeNightOfferAppliesToWayToSell(offer, wayToSellId);
    case OfferType.Package:
      return packageOfferAppliesToWayToSell(offer, wayToSellId);
  }
};

export const isDateWithinOfferFromToBounds = (
  { fromDate, toDate }: Offer,
  date: string,
): boolean =>
  // If either date is an empty string, we'll assume the offer has no particular start/end date
  fromDate === '' || toDate === '' || (date >= fromDate && date <= toDate);

export const isDateApplicableOfferDayOfWeek = (
  { applicableDaysOfWeek }: Offer,
  date: string,
): boolean => isDateWithinDaysOfWeek(date, applicableDaysOfWeek);

export const doesWayToSellApplyToOffer = (
  { applicableWayToSellIds }: WayToSellApplicableOffer,
  wayToSellId: number,
): boolean => applicableWayToSellIds.includes(wayToSellId);

export const isStayLengthWithinOfferBounds = (
  { minimumStayLengthDays, maximumStayLengthDays }: StayLengthBoundableOffer,
  stayLength: number,
): boolean =>
  stayLength >= minimumStayLengthDays && stayLength <= maximumStayLengthDays;

export const isAdvanceBookingDateWithinOfferBounds = (
  {
    minimumAdvanceBookingLengthDays,
    maximumAdvanceBookingLengthDays,
  }: AdvanceBookingLengthBoundableOffer,
  checkInDate: string,
  timezone: string,
): boolean => {
  // The advance booking date is effectively "now"
  const advanceBookingDate = getCurrentDateInTimezone(timezone);

  const earliestAdvanceBookingDate = subtractDays(
    checkInDate,
    maximumAdvanceBookingLengthDays,
  );

  const latestAdvanceBookingDate = subtractDays(
    checkInDate,
    minimumAdvanceBookingLengthDays,
  );

  return (
    advanceBookingDate >= earliestAdvanceBookingDate &&
    advanceBookingDate <= latestAdvanceBookingDate
  );
};

export const isOfferClosedOutOnDate = (
  { id }: Offer,
  propertyAvailability: PropertyAvailability,
  date: string,
): boolean =>
  !!findPropertyAvailabilityOfferAvailabilityByDate(
    propertyAvailability,
    id,
    date,
  )?.isClosedOut;

export const isOfferClosedOutOnAnyDate = (
  offer: Offer,
  propertyAvailability: PropertyAvailability,
  dates: string[],
): boolean =>
  dates.some((date) =>
    isOfferClosedOutOnDate(offer, propertyAvailability, date),
  );
