<template>
  <label :for="labelId" class="flex cursor-pointer items-center">
    <div class="relative inline-flex items-center justify-center">
      <input
        :id="labelId"
        ref="input"
        v-model="model"
        v-bind="{ ...(inputValue && { value: inputValue }) }"
        type="checkbox"
        class="h-4 w-4 cursor-pointer appearance-none rounded-sm border-2 border-neutral-700 bg-white transition duration-200 ease-[cubic-bezier(.19,1,.22,1)] checked:bg-neutral-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-neutral-700 focus-visible:ring-offset-1"
      />

      <IconCheck
        v-show="input?.checked"
        aria-hidden="true"
        stroke-width="3"
        class="absolute h-3 w-3 text-white"
      />
    </div>

    <span class="ml-2 select-none">
      {{ labelText }}
    </span>
  </label>
</template>

<script setup lang="ts" generic="T extends InputHTMLAttributes['checked']">
import { IconCheck } from '@tabler/icons-vue';
import type { InputHTMLAttributes } from 'vue';
import { ref } from 'vue';

defineProps<{
  labelId: string;
  labelText: string;
  inputValue?: InputHTMLAttributes['value'];
}>();

const model = defineModel<T>({ required: true });

const input = ref<HTMLInputElement>();
</script>
