<template>
  <AppLayout :property="undefined">
    <ErrorLayout>
      <SomethingWentWrong :error-code="errorCode" />
    </ErrorLayout>
  </AppLayout>
</template>

<script setup lang="ts">
import { useHead } from '@unhead/vue';
import { useI18n } from 'vue-i18n';
import SomethingWentWrong from '@/error-pages/something-went-wrong/SomethingWentWrong.vue';
import AppLayout from '@/layouts/AppLayout.vue';
import ErrorLayout from '@/layouts/ErrorLayout.vue';
import { useLocaleString } from '@/string/locale-string.composable';

defineProps<{ errorCode?: number }>();

const { t } = useI18n();
const { toTitleCase } = useLocaleString();

useHead({
  title: () => toTitleCase(t('somethingWentWrong')),
});
</script>
