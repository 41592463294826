import type {
  AdditionalTax,
  AdditionalTaxDTO,
} from '@/tax/additional/additional-tax';
import { mapAdditionalTaxRateFromDTO } from '@/tax/additional/rate/additional-tax-rate.mapper';

export const mapAdditionalTaxFromDTO = (
  additionalTaxDTO: AdditionalTaxDTO,
): AdditionalTax => ({
  registrationNumber: additionalTaxDTO.registrationNumber ?? undefined,
  rate: mapAdditionalTaxRateFromDTO(additionalTaxDTO.rate),
  fromDate: additionalTaxDTO.fromDate,
  toDate: additionalTaxDTO.toDate ?? undefined,
  ...(() => {
    switch (additionalTaxDTO.name) {
      case 'city':
        return {
          canTranslateName: true,
          nameTranslationKey: 'cityTax',
        };
      case 'service':
        return {
          canTranslateName: true,
          nameTranslationKey: 'serviceCharge',
        };
      default:
        return {
          canTranslateName: false,
          name: additionalTaxDTO.name,
        };
    }
  })(),
});
