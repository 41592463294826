import messages from '@intlify/unplugin-vue-i18n/messages';
import { merge } from 'lodash-es';
import { createI18n } from 'vue-i18n';
import { Locale } from '@/i18n/locales';
import type { TranslationKeyToMessageFunction } from '@/i18n/locales/message-functions/message-functions';
import { localeToMessageFunctions } from '@/i18n/locales/message-functions/message-functions';
import type enGB from '@/i18n/locales/messages/en-GB.json';
import numberFormats from '@/i18n/locales/number-formats';

/**
 * Defines enGB as the 'master' schema (first type argument), meaning
 * all locales (second type argument) must provide the same set of
 * translations.
 */
export default createI18n<
  [
    Record<
      keyof typeof enGB,
      | string
      | TranslationKeyToMessageFunction[keyof TranslationKeyToMessageFunction]
    >,
  ],
  Locale,
  false
>({
  legacy: false,
  locale: Locale.enGB,
  missingWarn: false,
  fallbackWarn: false,
  messages: merge(messages, localeToMessageFunctions),
  numberFormats,
});
