import type {
  AllGroupsPolicy,
  AllGroupsPolicyDTO,
} from '@/property/groups-policy/all/all-groups-policy';
import type {
  LimitedGroupsPolicy,
  LimitedGroupsPolicyDTO,
} from '@/property/groups-policy/limited/limited-groups-policy';

export enum GroupsPolicyType {
  All = 'all',
  Limited = 'limited',
}

export type GroupsPolicy = AllGroupsPolicy | LimitedGroupsPolicy;

export type GroupsPolicyDTO = AllGroupsPolicyDTO | LimitedGroupsPolicyDTO;
