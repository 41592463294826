import { isEqual } from 'lodash-es';
import isEmail from 'validator/es/lib/isEmail';
import { isPhoneNumber } from '@/string/string.utilities';
import type { ValidationRule } from '@/ui/app-form/app-form-input-validation-rules';

export const isRequiredRule =
  <T>(errorMessage: string): ValidationRule<T> =>
  (value) =>
    value ? undefined : errorMessage;

export const isEqualToRule =
  <T>(valueToCompare: T, errorMessage: string): ValidationRule<T> =>
  (value: T) =>
    isEqual(value, valueToCompare) ? undefined : errorMessage;

export const isLessThanOrEqualToLengthRule =
  <T extends string | T[]>(
    maxLength: number,
    errorMessage: string,
  ): ValidationRule<T> =>
  (value) =>
    value.length > maxLength ? errorMessage : undefined;

export const isValidEmailRule =
  (errorMessage: string): ValidationRule<string> =>
  (value) =>
    isEmail(value) ? undefined : errorMessage;

export const isValidPhoneNumberRule =
  (errorMessage: string): ValidationRule<string> =>
  (value) =>
    isPhoneNumber(value) ? undefined : errorMessage;
