import type { PluginOptions } from 'vue-gtag';
import { event, setOptions, bootstrap } from 'vue-gtag';
import type { Booking } from '@/booking/booking';
import { getStayLengthFromStayDates } from '@/stay-dates/stay-dates.utilities';

export const emitAdwordsBookingCompleteEvent = async (
  booking: Booking,
): Promise<void> => {
  const adwords = booking.property.googleAdwords;

  if (typeof adwords === 'undefined') {
    return;
  }

  const options: PluginOptions = {
    config: {
      id: `AW-${adwords.id}`,
      params: {
        cookie_flags: 'SameSite=None;Secure',
      },
    },
  };

  setOptions(options);

  await bootstrap();

  event('conversion', {
    send_to: `AW-${adwords.id}/${adwords.label}`,
    transaction_id: booking.id,
    value: booking.totalAmount,
    currency: booking.property.currencyCode,
  });
};

export const emitAdwordsHpaBookingCompleteEvent = async (
  booking: Booking,
): Promise<void> => {
  const adwordsConversionId = '747145042';
  const adwordsConversionLabel = 'B8ETCPiEt6IBENKOouQC';

  setOptions({
    config: {
      id: `AW-${adwordsConversionId}`,
      params: {
        cookie_flags: 'SameSite=None;Secure',
      },
    },
  });

  await bootstrap();

  event('conversion', {
    send_to: `AW-${adwordsConversionId}/${adwordsConversionLabel}`,
    value: booking.totalAmount,
    currency: booking.property.currencyCode,
    transaction_id: booking.id,
    items: [
      {
        id: booking.property.id,
        start_date: booking.checkInDate,
        stay_length: getStayLengthFromStayDates({
          checkInDate: booking.checkInDate,
          checkOutDate: booking.checkOutDate,
        }),
      },
    ],
  });
};
