import type {
  DescriptivePromocode,
  DescriptivePromocodeDTO,
} from '@/property/promocode/descriptive-promocode/descriptive-promocode';
import { PromocodeType } from '@/property/promocode/promocode';

export const mapDescriptivePromocodeFromDTO = (
  promocodeDTO: DescriptivePromocodeDTO,
): DescriptivePromocode => {
  return {
    promocodeType: PromocodeType.Descriptive,
    promocodeText: promocodeDTO.promocodeText,
    description: promocodeDTO.description,
    fromDate: promocodeDTO.fromDate,
    toDate: promocodeDTO.toDate,
    minimumStayLengthDays: promocodeDTO.minimumStayLengthDays,
  };
};
