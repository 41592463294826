import type {
  OfferAvailability,
  OfferAvailabilityDTO,
} from '@/availability/offer-availability/offer-availability';

export const mapOfferAvailabilityFromDTO = (
  offerAvailabilityDTO: OfferAvailabilityDTO,
): OfferAvailability => ({
  offerId: offerAvailabilityDTO.offerId,
  isClosedOut: offerAvailabilityDTO.isClosedOut,
});
