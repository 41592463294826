import type {
  BookingPrivateRateOverride,
  BookingPrivateRateOverrideDTO,
} from '@/booking/private-rate-override/booking-private-rate-override';
import { mapPrivateRateOverrideFromDTO } from '@/private-rate-override/private-rate-override.mapper';

export const mapBookingPrivateRateOverrideFromDTO = (
  bookingPrivateRateOverrideDTO: BookingPrivateRateOverrideDTO,
): BookingPrivateRateOverride => ({
  codeUsed: bookingPrivateRateOverrideDTO.codeUsed,
  privateRateOverride: mapPrivateRateOverrideFromDTO(
    bookingPrivateRateOverrideDTO.privateRateOverride,
  ),
});
