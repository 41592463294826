<template>
  <AppFormValidatedInput
    :value="unitItineraryItem.twinDoubleBedOption"
    :validation-rules="[isRequiredRule($t('bedPreferenceIsRequired'))]"
  >
    <AppRadioGroup v-model="unitItineraryItem.twinDoubleBedOption">
      <AppRadioGroupLabel class="font-medium capitalize">
        {{ $t('bedPreference') }}
      </AppRadioGroupLabel>

      <div class="flex flex-col items-start">
        <AppRadioGroupOption
          :value="TwinDoubleBedOption.Double"
          class="mb-2 mt-4"
        >
          <span>{{ toTitleCase($t('oneDoubleBed')) }}</span>

          <img :src="DoubleBed" alt="" class="ml-2 w-12" />
        </AppRadioGroupOption>

        <AppRadioGroupOption :value="TwinDoubleBedOption.Twin">
          <span>{{ toTitleCase($t('twoSingleBeds')) }}</span>

          <img :src="TwinBed" alt="" class="ml-2 w-12" />
        </AppRadioGroupOption>
      </div>
    </AppRadioGroup>
  </AppFormValidatedInput>
</template>

<script setup lang="ts">
import DoubleBed from '@/assets/images/double-bed.svg';
import TwinBed from '@/assets/images/twin-bed.svg';
import { TwinDoubleBedOption } from '@/booking/unit/active/active-booking-unit';
import type { UnitItineraryItem } from '@/booking-itinerary/booking-itinerary';
import { useLocaleString } from '@/string/locale-string.composable';
import { isRequiredRule } from '@/ui/app-form/app-form-input-validation-rules.utilities';
import AppFormValidatedInput from '@/ui/app-form/AppFormValidatedInput.vue';
import AppRadioGroup from '@/ui/app-radio-group/AppRadioGroup.vue';
import AppRadioGroupLabel from '@/ui/app-radio-group/AppRadioGroupLabel.vue';
import AppRadioGroupOption from '@/ui/app-radio-group/AppRadioGroupOption.vue';

const unitItineraryItem = defineModel<UnitItineraryItem>({ required: true });

const { toTitleCase } = useLocaleString();
</script>
