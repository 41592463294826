import { defineStore } from 'pinia';
import { ref } from 'vue';
import { createPcibAccessToken } from '@/property/payment-gateway/pcib/pcib-payment-gateway.api';

export const usePcibCardCaptureStore = defineStore('pcib-card-capture', () => {
  const accessToken = ref<string>();

  const initialize = async (): Promise<void> => {
    await loadAccessToken();
  };

  const loadAccessToken = async (): Promise<void> => {
    accessToken.value = await createPcibAccessToken();
  };

  return {
    accessToken,
    initialize,
  };
});
