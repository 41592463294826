import { format } from '@cloudinary/url-gen/actions/delivery';
import { fit } from '@cloudinary/url-gen/actions/resize';
import { auto } from '@cloudinary/url-gen/qualifiers/format';
import { useFavicon } from '@vueuse/core';
import { cloudinary } from '@/cloudinary';
import type { PropertyThemeLogo } from '@/property/property-theme/logo/property-theme-logo';

const FAVICON_IMAGE_SIZE = 48;

const favicon = useFavicon();

favicon.value = '/bloom.png';

export const useFaviconSetter = () => {
  const setPropertyThemeLogoFavicon = (
    propertyThemeLogo: PropertyThemeLogo,
  ) => {
    favicon.value = cloudinary
      .image(propertyThemeLogo.url)
      .resize(fit().height(FAVICON_IMAGE_SIZE).width(FAVICON_IMAGE_SIZE))
      .setDeliveryType('fetch')
      .delivery(format(auto()))
      .toURL();
  };

  const resetFavicon = () => {
    favicon.value = '/bloom.png';
  };

  return { setPropertyThemeLogoFavicon, resetFavicon };
};
