import { mapBookingFromDTO } from '@/booking/booking.mapper';
import { BookingCreateResultType } from '@/booking/create/result/booking-create-result';
import type {
  BookingCreateConfirmedResult,
  BookingCreateConfirmedResultDTO,
} from '@/booking/create/result/confirmed/booking-create-confirmed-result';

export const mapBookingCreateConfirmedResultFromDTO = (
  bookingCreateConfirmedResultDTO: BookingCreateConfirmedResultDTO,
): BookingCreateConfirmedResult => ({
  type: BookingCreateResultType.Confirmed,
  booking: mapBookingFromDTO(bookingCreateConfirmedResultDTO.booking),
  accessToken: bookingCreateConfirmedResultDTO.accessToken,
});
