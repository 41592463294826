<template>
  <button
    :id="buttonId"
    class="w-full"
    :aria-expanded="isOpen"
    :aria-controls="panelId"
    type="button"
    @click="isOpen = !isOpen"
  >
    <slot></slot>
  </button>
</template>

<script setup lang="ts">
import { useAccordionState } from '@/ui/app-accordion/app-accordion.composable';

const { isOpen, buttonId, panelId } = useAccordionState();
</script>
