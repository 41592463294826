import { mapBookingAdditionalTaxFromDTO } from '@/booking/booking-taxes/additional/booking-additional-tax.mapper';
import type {
  BookingTaxes,
  BookingTaxesDTO,
} from '@/booking/booking-taxes/booking-taxes';
import { mapBookingVatFromDTO } from '@/booking/booking-taxes/vat/booking-vat.mapper';

export const mapBookingTaxesFromDTO = (
  bookingTaxesDTO: BookingTaxesDTO,
): BookingTaxes => ({
  bookingVat: bookingTaxesDTO.bookingVat
    ? mapBookingVatFromDTO(bookingTaxesDTO.bookingVat)
    : undefined,
  bookingCityTax: bookingTaxesDTO.bookingCityTax
    ? mapBookingAdditionalTaxFromDTO(bookingTaxesDTO.bookingCityTax)
    : undefined,
  bookingServiceCharge: bookingTaxesDTO.bookingServiceCharge
    ? mapBookingAdditionalTaxFromDTO(bookingTaxesDTO.bookingServiceCharge)
    : undefined,
  bookingOtherTaxes: bookingTaxesDTO.bookingOtherTaxes.map(
    mapBookingAdditionalTaxFromDTO,
  ),
});
