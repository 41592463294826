<template>
  <UnitSelectionUnits
    data-test="UnitSelectionAvailableUnitList"
    :unit-ids="availableUnitIdsSortedByDisplayPriorityOfFirstPricePlan"
    are-available
  />
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { useBookingSourceStore } from '@/booking-source/booking-source.store';
import { useUnitSelectionUnitScroller } from '@/unit-selection-page/unit/unit-selection-unit-scroller.composable';
import UnitSelectionUnits from '@/unit-selection-page/unit/UnitSelectionUnits.vue';
import { useUnitSelectionStore } from '@/unit-selection-page/unit-selection.store';

const unitSelectionStore = useUnitSelectionStore();
const bookingSourceStore = useBookingSourceStore();

const availableUnitIdsSortedByDisplayPriorityOfFirstPricePlan = computed(() =>
  unitSelectionStore.pricePlans.firstPricePlansForAllUnitsSortedByDisplayPriority.map(
    ({ unit }) => unit.id,
  ),
);

onMounted(() => {
  if (bookingSourceStore.isGoogleSource) {
    const { scrollFirstUnitIntoView } = useUnitSelectionUnitScroller();

    scrollFirstUnitIntoView();
  }
});
</script>
