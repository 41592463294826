import { GrammaticalGender } from '@/i18n/locales/gender/gender';
import type { TranslationKeyToMessageFunction } from '@/i18n/locales/message-functions/message-functions';
import type ptPTStaticTranslations from '@/i18n/locales/messages/pt-PT.json';

export type PtPTMessageFunctions = Omit<
  TranslationKeyToMessageFunction,
  keyof typeof ptPTStaticTranslations
>;

export const ptPT: PtPTMessageFunctions = {
  youHaveSelectedTheLastUnitAvailable: ({ values }) => {
    switch (values.unitTypeGender) {
      case GrammaticalGender.Neuter:
      case GrammaticalGender.Masculine:
        return `Você selecionou o último ${values.unitTypeName} disponível`;
      case GrammaticalGender.Feminine:
        return `Você selecionou a última ${values.unitTypeName} disponível`;
    }
  },
  pleaseSelectAUnitToContinue: ({ values }) => {
    switch (values.unitTypeGender) {
      case GrammaticalGender.Neuter:
      case GrammaticalGender.Masculine:
        return `Por favor, selecione um ${values.unitTypeName} para continuar`;
      case GrammaticalGender.Feminine:
        return `Por favor, selecione uma ${values.unitTypeName} para continuar`;
    }
  },
  someOfTheUnits: ({ values }) => {
    switch (values.unitTypeGender) {
      case GrammaticalGender.Neuter:
      case GrammaticalGender.Masculine:
        return `Alguns dos ${values.unitTypeName}`;
      case GrammaticalGender.Feminine:
        return `Algumas das ${values.unitTypeName}`;
    }
  },
  theseUnitsWillBeDiscardedIfYouSwitchToPropertyName: ({ type, values }) => {
    if (type !== 'vnode') {
      switch (values.unitTypeGender) {
        case GrammaticalGender.Neuter:
        case GrammaticalGender.Masculine:
          return `Estes ${values.unitTypeName} serão descartados se mudar para ${values.propertyName}.`;
        case GrammaticalGender.Feminine:
          return `Estas ${values.unitTypeName} serão descartadas se mudar para ${values.propertyName}.`;
      }
    }

    return [
      values.unitTypeGender[0]!.children === GrammaticalGender.Feminine
        ? 'Estas '
        : 'Estes ',
      values.unitTypeName,
      values.unitTypeGender[0]!.children === GrammaticalGender.Feminine
        ? ' serão descartadas se mudar para'
        : ' serão descartados se mudar para',
      values.propertyName,
      '.',
    ];
  },
  youAreAboutToRemoveTheLastUnit: ({ values }) => {
    switch (values.unitTypeGender) {
      case GrammaticalGender.Neuter:
      case GrammaticalGender.Masculine:
        return `Está prestes a remover o último ${values.unitTypeName} da sua seleção. Será redirecionado para a página de seleção de ${values.unitTypeName}. Deseja continuar?`;
      case GrammaticalGender.Feminine:
        return `Está prestes a remover a última ${values.unitTypeName} da sua seleção. Será redirecionado para a página de seleção de ${values.unitTypeName}. Deseja continuar?`;
    }
  },
  ifYouRemoveThisPrivateRateYourUnitsWillBeRemovedAndYouWillBeRedirected: ({
    values: { unitTypeGender, unitTypeName, unitTypeNamePlural },
  }) => {
    switch (unitTypeGender) {
      case GrammaticalGender.Neuter:
      case GrammaticalGender.Masculine:
        return `Se remover esta tarifa privada, o seu ${unitTypeNamePlural} atualmente selecionado será removido e será redirecionado para a página de seleção do ${unitTypeName}. Gostaria de continuar?`;
      case GrammaticalGender.Feminine:
        return `Se remover esta tarifa privada, a sua ${unitTypeNamePlural} atualmente selecionada será removida e será redirecionada para a página de seleção da ${unitTypeName}. Gostaria de continuar?`;
    }
  },
  unitsBooked: ({ values: { unitTypeName, unitTypeGender } }) => {
    switch (unitTypeGender) {
      case GrammaticalGender.Neuter:
      case GrammaticalGender.Masculine:
        return `${unitTypeName} reservados`;
      case GrammaticalGender.Feminine:
        return `${unitTypeName} reservadas`;
    }
  },
};
