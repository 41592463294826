<template>
  <div
    class="z-10 mb-2 grid grid-cols-[repeat(7,_minmax(2rem,_3rem))] items-center border-b bg-white py-2 md:pt-0"
  >
    <div
      v-for="date in weekdayDates"
      :key="date.getDay()"
      data-test="AvailabilityCalendarDayNames__day"
      class="text-center text-sm font-medium capitalize text-slate-600"
    >
      {{ $d(date, { weekday: 'narrow' }) }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { eachDayOfInterval, startOfWeek, endOfWeek } from 'date-fns';
import { useAvailabilityCalendarStore } from '@/availability-calendar/availability-calendar.store';

const { weekStartIndex } = useAvailabilityCalendarStore();

// Now is an arbitrary date only needed to derive the week day dates
const now = new Date();

const weekdayDates = eachDayOfInterval({
  start: startOfWeek(now, { weekStartsOn: weekStartIndex }),
  end: endOfWeek(now, { weekStartsOn: weekStartIndex }),
});
</script>
