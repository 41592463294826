import type {
  GroupsPolicy,
  GroupsPolicyDTO,
} from '@/property/groups-policy/groups-policy';
import { GroupsPolicyType } from '@/property/groups-policy/groups-policy';

export const mapGroupsPolicyFromDTO = (
  groupsPolicyDTO: GroupsPolicyDTO,
): GroupsPolicy => {
  const { policyType } = groupsPolicyDTO;

  switch (policyType) {
    case GroupsPolicyType.All:
      return { policyType };
    case GroupsPolicyType.Limited:
      return {
        policyType,
        maximumGroupSize: groupsPolicyDTO.maximumGroupSize ?? undefined,
        acceptsHenAndStagGroups: groupsPolicyDTO.acceptsHenAndStagGroups,
      };
  }
};
