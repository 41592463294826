<template>
  <div class="flex w-full bg-white">
    <AppNumberSpinnerButton
      class="w-20 md:w-auto md:flex-1"
      :is-incrementing="false"
      :label="$t('removeItemName', { itemName })"
      @click="quantity--"
    />
    <div
      class="accent-contrast-border-y flex flex-[2] items-center justify-center bg-accent-color/20 text-center text-sm capitalize"
    >
      {{ $t('countSelected', { count: quantity }) }}
    </div>
    <AppNumberSpinnerButton
      class="w-20 md:w-auto md:flex-1"
      :is-incrementing="true"
      :label="$t('addAnotherItemName', { itemName })"
      @click="quantity++"
    />
  </div>
</template>

<script setup lang="ts">
import AppNumberSpinnerButton from '@/ui/app-number-spinner/AppNumberSpinnerButton.vue';

defineProps<{ itemName: string }>();

const quantity = defineModel<number>({ required: true });
</script>
