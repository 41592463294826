<template>
  <h1
    v-if="searchStore.activeProperty.propertyTheme.name"
    class="text-center text-lg md:text-xl"
  >
    {{ searchStore.activeProperty.propertyTheme.name.text }}
  </h1>
</template>

<script setup lang="ts">
import { useSearchStore } from '@/search/search.store';

const searchStore = useSearchStore();
</script>
