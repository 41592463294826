import type { Card } from '@/card/card';
import { CardType } from '@/card/card';

export const findCardByCardType = (cardType: CardType): Card => {
  switch (cardType) {
    case CardType.AmericanExpress:
      return {
        isMain: true,
        name: 'American Express',
      };
    case CardType.BC:
      return {
        isMain: false,
        name: 'BC',
      };
    case CardType.CartaSi:
      return {
        isMain: false,
        name: 'CartaSi',
      };
    case CardType.CarteBlanche:
      return {
        isMain: false,
        name: 'CarteBlanche',
      };
    case CardType.CarteBleue:
      return {
        isMain: false,
        name: 'CarteBleue',
      };
    case CardType.Dankort:
      return {
        isMain: false,
        name: 'Dankort',
      };
    case CardType.DinersClub:
      return {
        isMain: false,
        name: 'DinersClub',
      };
    case CardType.Discover:
      return {
        isMain: false,
        name: 'Discover',
      };
    case CardType.Electron:
      return {
        isMain: false,
        name: 'Electron',
      };
    case CardType.JCB:
      return {
        isMain: false,
        name: 'JCB',
      };
    case CardType.Maestro:
      return {
        isMain: true,
        name: 'Maestro',
      };
    case CardType.Mastercard:
      return {
        isMain: true,
        name: 'Mastercard',
      };
    case CardType.MCAlaska:
      return {
        isMain: false,
        name: 'MC Alaska',
      };
    case CardType.MCCanada:
      return {
        isMain: false,
        name: 'MC Canada',
      };
    case CardType.Solo:
      return {
        isMain: false,
        name: 'Solo',
      };
    case CardType.UnionPay:
      return {
        isMain: false,
        name: 'UnionPay',
      };
    case CardType.VisaDebit:
      return {
        isMain: true,
        name: 'Visa Debit',
      };
    case CardType.VisaCredit:
      return {
        isMain: true,
        name: 'Visa Credit',
      };
    case CardType.CartesBancairesCredit:
      return {
        isMain: false,
        name: 'Cartes Bancaires Credit',
      };
    case CardType.CartesBancairesDebit:
      return {
        isMain: false,
        name: 'Cartes Bancaires Debit',
      };
  }
};
