import { GrammaticalGender } from '@/i18n/locales/gender/gender';
import type { TranslationKeyToMessageFunction } from '@/i18n/locales/message-functions/message-functions';
import type deDEStaticTranslations from '@/i18n/locales/messages/de-DE.json';

export type DeDEMessageFunctions = Omit<
  TranslationKeyToMessageFunction,
  keyof typeof deDEStaticTranslations
>;

export const deDE: DeDEMessageFunctions = {
  youHaveSelectedTheLastUnitAvailable: ({ values }) => {
    switch (values.unitTypeGender) {
      case GrammaticalGender.Masculine:
        return `Sie haben den letzten ${values.unitTypeName} ausgewählt`;
      case GrammaticalGender.Feminine:
        return `Sie haben die letzte ${values.unitTypeName} ausgewählt`;
      case GrammaticalGender.Neuter:
        return `Sie haben das letzte ${values.unitTypeName} ausgewählt`;
    }
  },
  amountOffEachUnit: ({ values }) => {
    switch (values.unitTypeGender) {
      case GrammaticalGender.Masculine:
        return `${values.amount} Rabatt auf jeden ${values.unitTypeName}`;
      case GrammaticalGender.Feminine:
        return `${values.amount} Rabatt auf jede ${values.unitTypeName}`;
      case GrammaticalGender.Neuter:
        return `${values.amount} Rabatt auf jedes ${values.unitTypeName}`;
    }
  },
  pleaseSelectAUnitToContinue: ({ values }) => {
    switch (values.unitTypeGender) {
      case GrammaticalGender.Masculine:
        return `Bitte wählen Sie einen ${values.unitTypeName}, um fortzufahren`;
      case GrammaticalGender.Feminine:
        return `Bitte wählen Sie eine ${values.unitTypeName}, um fortzufahren`;
      case GrammaticalGender.Neuter:
        return `Bitte wählen Sie ein ${values.unitTypeName}, um fortzufahren`;
    }
  },
  youAreAboutToRemoveTheLastUnit: ({ values }) => {
    switch (values.unitTypeGender) {
      case GrammaticalGender.Neuter:
        return `Sie sind dabei, das letzte ${values.unitTypeName} in Ihrer Auswahl zu entfernen. Sie werden zur ${values.unitTypeName}-Auswahlseite weitergeleitet. Möchten Sie fortfahren?`;
      case GrammaticalGender.Masculine:
        return `Sie sind dabei, den letzten ${values.unitTypeName} in Ihrer Auswahl zu entfernen. Sie werden zur ${values.unitTypeName}-Auswahlseite weitergeleitet. Möchten Sie fortfahren?`;
      case GrammaticalGender.Feminine:
        return `Sie sind dabei, die letzte ${values.unitTypeName} in Ihrer Auswahl zu entfernen. Sie werden zur ${values.unitTypeName}-Auswahlseite weitergeleitet. Möchten Sie fortfahren?`;
    }
  },
  ifYouRemoveThisPrivateRateYourUnitsWillBeRemovedAndYouWillBeRedirected: ({
    values: { unitTypeGender, unitTypeName, unitTypeNamePlural },
  }) => {
    switch (unitTypeGender) {
      case GrammaticalGender.Neuter:
        return `Wenn Sie diesen privaten Tarif entfernen, wird Ihr aktuell ausgewähltes ${unitTypeNamePlural} entfernt und Sie werden zur ${unitTypeName}-Auswahlseite weitergeleitet. Möchten Sie fortfahren?`;
      case GrammaticalGender.Masculine:
        return `Wenn Sie diesen privaten Tarif entfernen, wird Ihr aktuell ausgewählter ${unitTypeNamePlural} entfernt und Sie werden zur ${unitTypeName}-Auswahlseite weitergeleitet. Möchten Sie fortfahren?`;
      case GrammaticalGender.Feminine:
        return `Wenn Sie diesen privaten Tarif entfernen, wird Ihre aktuell ausgewählte ${unitTypeNamePlural} entfernt und Sie werden zur ${unitTypeName}-Auswahlseite weitergeleitet. Möchten Sie fortfahren?`;
    }
  },
};
