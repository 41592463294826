import { Decimal } from 'decimal.js';
import type { Ref } from 'vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { isPercentagePromocodeApplicableOnAllDaysOfWeek } from '@/property/promocode/percentage-promocode/percentage-promocode.utilities';
import type { Promocode } from '@/property/promocode/promocode';
import { PromocodeType } from '@/property/promocode/promocode';
import type { Property } from '@/property/property';
import { useUnitType } from '@/property/unit/type/unit-type.composable';

export const usePromocodeDiscountSummary = (
  promocode: Ref<Promocode>,
  property: Ref<Property>,
) => {
  const { t, n } = useI18n();
  const { unitTypeName, unitTypeGender } = useUnitType(
    computed(() => property.value.unitType),
  );

  const discountSummaryText = computed(() => {
    switch (promocode.value.promocodeType) {
      case PromocodeType.Percentage:
        return t(
          isPercentagePromocodeApplicableOnAllDaysOfWeek(promocode.value)
            ? 'percentOff'
            : 'percentOffOnSelectiveNights',
          {
            percent: n(
              new Decimal(promocode.value.percentage).dividedBy(100).toNumber(),
              'percent',
            ),
          },
        );
      case PromocodeType.Monetary:
        return t('amountOffEachUnit', {
          amount: n(promocode.value.amount, {
            style: 'currency',
            currency: property.value.currencyCode,
          }),
          unitTypeName: unitTypeName.value,
          unitTypeGender: unitTypeGender.value,
        });
      default:
        return undefined;
    }
  });

  return { discountSummaryText };
};
