<template>
  <div class="relative">
    <AppPopoverButton
      v-if="useAsPopoverButton"
      class="w-full p-3"
      :as="AppPrimaryButton"
      :button-text="buttonText"
    />
    <AppPrimaryButton
      v-else
      class="w-full p-3"
      :button-text="buttonText"
      @click="$emit('click')"
    />

    <AppSkeletonBox
      v-show="searchStore.isLoading"
      class="absolute inset-0 z-10 rounded"
    />
  </div>
</template>

<script setup lang="ts">
import { useSearchStore } from '@/search/search.store';
import AppPopoverButton from '@/ui/app-popover/AppPopoverButton.vue';
import AppPrimaryButton from '@/ui/app-primary-button/AppPrimaryButton.vue';
import AppSkeletonBox from '@/ui/app-skeleton-box/AppSkeletonBox.vue';

const { useAsPopoverButton = false } = defineProps<{
  buttonText: string;
  useAsPopoverButton?: boolean;
}>();

defineEmits(['click']);

const searchStore = useSearchStore();
</script>
