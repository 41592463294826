import { Decimal } from 'decimal.js';
import type { MealRateAdjustmentStandardRatePercentage } from '@/property/meal/meal-rate-adjustment/standard-rate-percentage/meal-rate-adjustment-standard-rate-percentage';

export const applyMealRateAdjustmentStandardRatePercentageToValue = (
  mealRateAdjustmentStandardRatePercentage: MealRateAdjustmentStandardRatePercentage,
  value: number,
): number =>
  Decimal.max(
    0,
    applyMealRateAdjustmentStandardRatePercentageToDecimalValue(
      mealRateAdjustmentStandardRatePercentage,
      new Decimal(value),
    ),
  ).toNumber();

const applyMealRateAdjustmentStandardRatePercentageToDecimalValue = (
  { percentage }: MealRateAdjustmentStandardRatePercentage,
  decimalValue: Decimal,
): Decimal =>
  decimalValue.times(Decimal.div(percentage, 100)).toDecimalPlaces(2);
