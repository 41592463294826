import type { EventBusKey } from '@vueuse/core';

export const codeResourceRemoveEventBusKey: EventBusKey<void> = Symbol(
  'code-resource-remove',
);

export const submitPaymentEventBusKey: EventBusKey<void> =
  Symbol('submit-payment');

export const occupancySelectorAfterValidateEventBusKey: EventBusKey<void> =
  Symbol('occupancy-selector-after-validate');

export const bookingCreateAvailabilityErrorEventBusKey: EventBusKey<void> =
  Symbol('booking-create-availability-error');
