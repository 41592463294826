import type { Ref } from 'vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { ParkingPolicyChargeType } from '@/property/parking-policy/charge/parking-policy-charge';
import type { NearbyParkingPolicy } from '@/property/parking-policy/nearby/nearby-parking-policy';
import type { Property } from '@/property/property';

export const useNearbyParkingPolicyTermsAndConditionsDisplayText = (
  property: Ref<Property>,
) => {
  const { t, n } = useI18n();

  const onSiteParkingPolicy = computed(
    () => property.value.parkingPolicies.onSiteParkingPolicy,
  );

  const streetParkingPolicy = computed(
    () => property.value.parkingPolicies.streetParkingPolicy,
  );

  const nearbyParkingPolicy = computed(
    () => property.value.parkingPolicies.nearbyParkingPolicy,
  );

  const otherPoliciesExist = computed(
    () => !!onSiteParkingPolicy.value || !!streetParkingPolicy.value,
  );

  const noAvailableNearbyParkingDisplayText = computed(() =>
    onSiteParkingPolicy.value && streetParkingPolicy.value
      ? ''
      : t('thereIsNoOtherNearbyParkingAvailable'),
  );

  const generateNearbyParkingDisplayText = ({
    charge,
  }: NearbyParkingPolicy): string => {
    switch (charge.chargeType) {
      case ParkingPolicyChargeType.Free:
        return t(
          otherPoliciesExist.value
            ? 'alternativeParkingIsAvailableNearbyAndIsFreeOfCharge'
            : 'parkingIsAvailableNearbyAndIsFreeOfCharge',
        );
      case ParkingPolicyChargeType.PerDayMonetary:
        return t(
          otherPoliciesExist.value
            ? 'alternativeParkingIsAvailableNearbyAtAChargeOfChargePerDay'
            : 'parkingIsAvailableNearbyAtAChargeOfChargePerDay',
          {
            charge: n(charge.amountPerDay, {
              style: 'currency',
              currency: property.value.currencyCode,
            }),
          },
        );
    }
  };

  return computed(() =>
    nearbyParkingPolicy.value
      ? generateNearbyParkingDisplayText(nearbyParkingPolicy.value)
      : noAvailableNearbyParkingDisplayText.value,
  );
};
