import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import type { BookingUnitMeal } from '@/booking/unit/meal/booking-unit-meal';
import {
  MEAL_TYPE_TRANSLATION_KEY_MAPPING,
  getOrderedMealTypes,
} from '@/property/meal/meal.utilities';

export const useBookingUnitMealsDisplayText = (
  bookingUnitMeals: BookingUnitMeal[],
) => {
  const { t, locale } = useI18n();

  const listFormatter = computed(
    () =>
      new Intl.ListFormat(locale.value, {
        style: 'long',
        type: 'conjunction',
      }),
  );

  const sortedMealNames = computed(() => {
    const orderedMealTypes = getOrderedMealTypes();

    return bookingUnitMeals
      .sort(
        (mealA, mealB) =>
          orderedMealTypes.indexOf(mealA.mealType) -
          orderedMealTypes.indexOf(mealB.mealType),
      )
      .map((meal) => t(MEAL_TYPE_TRANSLATION_KEY_MAPPING[meal.mealType]));
  });

  const bookingUnitMealsDisplayText = computed(() =>
    bookingUnitMeals.length !== 0
      ? t('withMeals', {
          meals: listFormatter.value.format(sortedMealNames.value),
        })
      : undefined,
  );

  return { bookingUnitMealsDisplayText };
};
