import { defineStore } from 'pinia';
import type { ComputedRef } from 'vue';
import { computed, ref } from 'vue';
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import type {
  NonMandatoryExtra,
  NonSelectableNightExtra,
} from '@/property/extra/extra';
import { ExtraChargeType } from '@/property/extra/extra';
import {
  calculateTotalRunningRateForAllNightsExtra,
  calculateTotalRunningRateForSingleNightExtra,
} from '@/property/extra/extra.utilities';
import { getStayLengthFromStayDates } from '@/stay-dates/stay-dates.utilities';

export const useNonMandatoryNonSelectableExtraItineraryItemStore = (
  extra: NonMandatoryExtra & NonSelectableNightExtra,
) =>
  defineStore(
    `non-mandatory-non-selectable-extra-itinerary-item-${extra.id}`,
    () => {
      const bookingItineraryStore = useBookingItineraryStore();

      const quantity = ref(0);

      const answer = ref<string>();

      const numberOfNightsApplied: ComputedRef<number | undefined> = computed(
        () =>
          extra.chargeType === ExtraChargeType.AllNightsOfStay
            ? getStayLengthFromStayDates(bookingItineraryStore.stayDates!)
            : undefined,
      );

      const totalRate: ComputedRef<number> = computed(() =>
        calculateTotalRate(),
      );

      const calculateTotalRate = () => {
        switch (extra.chargeType) {
          case ExtraChargeType.AllNightsOfStay:
            return calculateTotalRunningRateForAllNightsExtra(
              { ...extra, chargeType: extra.chargeType },
              quantity.value,
              bookingItineraryStore.stayDates!,
            );
          case ExtraChargeType.Single:
            return calculateTotalRunningRateForSingleNightExtra(
              { ...extra, chargeType: extra.chargeType },
              quantity.value,
            );
        }
      };

      return {
        extra,
        quantity,
        answer,
        totalRate,
        numberOfNightsApplied,
      };
    },
  )();
