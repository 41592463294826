<template>
  <div class="relative">
    <AppMiniImageCarousel
      :image-label="$t('propertyImages')"
      :images="mainImage ? [mainImage] : []"
      :is-loading="searchStore.isLoading"
      :is-clickable="false"
    />

    <template v-if="!searchStore.isLoading">
      <AppRateBanner
        v-if="bannerRate"
        :rate="bannerRate"
        :currency-code="property.currencyCode"
        class="absolute right-0 top-3"
      />

      <AppBanner
        v-else
        side-to-wrap="both"
        class="absolute top-3 w-full text-xs"
      >
        {{ capitalize($t('propertyIsNotAvailableForTheSelectedDates')) }}
      </AppBanner>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { Property } from '@/property/property';
import { findPropertyMainImage } from '@/property/property.utilities';
import { useSearchStore } from '@/search/search.store';
import { useLocaleString } from '@/string/locale-string.composable';
import AppBanner from '@/ui/app-banner/AppBanner.vue';
import AppRateBanner from '@/ui/app-banner/AppRateBanner.vue';
import AppMiniImageCarousel from '@/ui/app-image-carousel/AppMiniImageCarousel.vue';

const props = defineProps<{
  property: Property;
  bannerRate: number | null;
}>();

const searchStore = useSearchStore();

const { capitalize } = useLocaleString();

const mainImage = computed(() => findPropertyMainImage(props.property));
</script>
