import { isMessageEventFromOrigin } from '@/message-event/message-event.utilities';
import type {
  PcibInboundMessageEvent,
  PcibOutboundMessageEventData,
} from '@/message-event/pcib/pcib-message-event';

const PCIB_MESSAGE_EVENT_ORIGIN = 'https://service.pcibooking.net';

export const isPcibMessageEvent = (
  event: MessageEvent,
): event is PcibInboundMessageEvent =>
  isMessageEventFromOrigin(event, PCIB_MESSAGE_EVENT_ORIGIN);

export const sendPcibPostMessageEvent = (
  window: Window,
  messageData: PcibOutboundMessageEventData,
) => {
  window.postMessage(messageData, PCIB_MESSAGE_EVENT_ORIGIN);
};
