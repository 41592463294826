<template>
  <Transition
    enter-active-class="transition-opacity duration-150 ease-out"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transition-opacity duration-150 ease-out"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <ListboxOptions class="absolute z-10">
      <div
        ref="listboxOptions"
        :style="floatingStyles"
        class="max-h-60 overflow-auto rounded-md border-neutral-200 bg-white py-1 shadow-lg focus:outline-none"
      >
        <slot></slot>
      </div>
    </ListboxOptions>
  </Transition>
</template>

<script setup lang="ts">
import { useFloating, offset, autoUpdate, flip, size } from '@floating-ui/vue';
import { ListboxOptions } from '@headlessui/vue';
import { ref, toRef } from 'vue';

const props = defineProps<{
  /**
   * Optionally, an anchor element may be provided, where the listbox options
   * will automatically "float" around the anchor.
   */
  anchor?: HTMLElement | null;
}>();

const listboxOptionsAnchor = toRef(props, 'anchor');
const listboxOptions = ref<HTMLDivElement | null>(null);

const { floatingStyles } = useFloating(listboxOptionsAnchor, listboxOptions, {
  placement: 'bottom',
  middleware: [
    flip({ padding: 25 }),
    offset(10),
    size({
      apply({
        rects: {
          reference: { width },
        },
      }) {
        if (listboxOptions.value) {
          listboxOptions.value.style.width = `${width}px`;
        }
      },
    }),
  ],
  whileElementsMounted: autoUpdate,
});
</script>
