import type { Ref } from 'vue';
import { computed, reactive } from 'vue';
import type { CancellationPolicyOverStay } from '@/property/booking-policy/cancellation-policy/over-stay/cancellation-policy-over-stay';
import {
  getBeforeArrivalDepositRefundPolicyCutOffDateForStayDates,
  isBeforeArrivalDepositRefundPolicyApplicableForStayDates,
} from '@/property/booking-policy/deposit-policy/refund-policy/before-arrival/before-arrival-deposit-refund-policy.utilities';
import type { DepositRefundPolicy } from '@/property/booking-policy/deposit-policy/refund-policy/deposit-refund-policy';
import { DepositRefundPolicyType } from '@/property/booking-policy/deposit-policy/refund-policy/deposit-refund-policy';
import type { DepositRefundPolicyOverStay } from '@/property/booking-policy/deposit-policy/refund-policy/over-stay/deposit-refund-policy-over-stay';
import type { Property } from '@/property/property';
import type { StayDates } from '@/stay-dates/stay-dates';

export const useDepositRefundPolicyOverStay = (
  property: Ref<Property>,
  depositRefundPolicy: Ref<DepositRefundPolicy>,
  cancellationPolicyOverStay: Ref<CancellationPolicyOverStay>,
  stayDates: Ref<StayDates>,
): DepositRefundPolicyOverStay => {
  const isApplicable = computed(() =>
    depositRefundPolicy.value.refundType ===
    DepositRefundPolicyType.ByCancellationPolicy
      ? !cancellationPolicyOverStay.value.rendersBookingNonRefundable
      : isBeforeArrivalDepositRefundPolicyApplicableForStayDates(
          depositRefundPolicy.value,
          stayDates.value,
          property.value.timezone,
        ),
  );

  const cutOffDate = computed(() =>
    depositRefundPolicy.value.refundType ===
    DepositRefundPolicyType.ByCancellationPolicy
      ? cancellationPolicyOverStay.value.cutOffDate
      : getBeforeArrivalDepositRefundPolicyCutOffDateForStayDates(
          depositRefundPolicy.value,
          stayDates.value,
        ),
  );

  return reactive({
    isApplicable,
    cutOffDate,
  });
};
