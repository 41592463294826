import { generateUniqueId } from '@/generator/generator.utilities';
import type { Occupancy } from '@/occupancy/occupancy';
import {
  createMealPricePlans,
  createStandardMealPricePlans,
} from '@/price-plan/meal-price-plan/meal-price-plan.utilities';
import { PricePlanVariantType } from '@/price-plan/price-plan';
import { calculatePricePlanOccupancy } from '@/price-plan/price-plan.utilities';
import type {
  DiscountedWayToSellPricePlanVariant,
  PackageWayToSellPricePlanVariant,
  PrivateWayToSellPricePlanVariant,
  StandardWayToSellPricePlanVariant,
  WayToSellPricePlan,
} from '@/price-plan/way-to-sell-price-plan/way-to-sell-price-plan';
import { PromocodeType } from '@/property/promocode/promocode';
import { getTotalRateFromNightlyRates } from '@/rates/nightly-rates/nightly-rates.utilities';
import type {
  NonPackageOfferRates,
  PackageOfferRates,
} from '@/rates/offer-rates/offer-rates';
import {
  areOfferRatesCheaper,
  getCheapestNonRefundableNonPackageOfferRates,
  getCheapestRefundableNonPackageOfferRates,
  getPackageOffersRates,
} from '@/rates/offer-rates/offer-rates.utilities';
import type { UnitRates } from '@/rates/unit-rates/unit-rates';
import type { WayToSellRates } from '@/rates/way-to-sell-rates/way-to-sell-rates';

/**
 * From the given way to sell rates, we should try to return the following price plans:
 * - A price plan representing the cheapest, refundable, non-package offer that applies
 * to the way to sell. If none exists, use the standard rate of the way to sell instead.
 * - A price plan representing the cheapest, non-refundable, non-package offer that
 * applies to the way to sell. If none exists, or the rate is not actually cheaper than
 * the refundable price plan, just omit it.
 * - Price plans for each package offer that applies to the way to sell.
 * - A price plan for any private rates that apply to the way to sell.
 */
export const createWayToSellPricePlans = (
  unitRates: UnitRates,
  wayToSellRates: WayToSellRates,
): WayToSellPricePlan[] => {
  const pricePlanOccupancy = calculatePricePlanOccupancy(
    unitRates.occupancy,
    wayToSellRates.wayToSell.occupancyLimits,
  );

  return createWayToSellPricePlansForOccupancy(
    unitRates,
    pricePlanOccupancy,
    wayToSellRates,
  );
};

const createWayToSellPricePlansForOccupancy = (
  unitRates: UnitRates,
  pricePlanOccupancy: Occupancy,
  wayToSellRates: WayToSellRates,
): WayToSellPricePlan[] => {
  const pricePlans: WayToSellPricePlan[] = [];

  const cheapestRefundableNonPackageOfferRates =
    getCheapestRefundableNonPackageOfferRates(wayToSellRates.offersRates);

  const cheapestNonRefundableNonPackageOfferRates =
    getCheapestNonRefundableNonPackageOfferRates(wayToSellRates.offersRates);

  const packageOffersRates = getPackageOffersRates(wayToSellRates.offersRates);

  if (cheapestRefundableNonPackageOfferRates) {
    pricePlans.push(
      createDiscountedWayToSellPricePlan(
        unitRates,
        pricePlanOccupancy,
        wayToSellRates,
        cheapestRefundableNonPackageOfferRates,
      ),
    );

    if (
      cheapestNonRefundableNonPackageOfferRates &&
      areOfferRatesCheaper(
        cheapestNonRefundableNonPackageOfferRates,
        cheapestRefundableNonPackageOfferRates,
      )
    ) {
      pricePlans.push(
        createDiscountedWayToSellPricePlan(
          unitRates,
          pricePlanOccupancy,
          wayToSellRates,
          cheapestNonRefundableNonPackageOfferRates,
        ),
      );
    }
  } else {
    pricePlans.push(
      createStandardWayToSellPricePlan(
        unitRates,
        pricePlanOccupancy,
        wayToSellRates,
      ),
    );

    if (cheapestNonRefundableNonPackageOfferRates) {
      pricePlans.push(
        createDiscountedWayToSellPricePlan(
          unitRates,
          pricePlanOccupancy,
          wayToSellRates,
          cheapestNonRefundableNonPackageOfferRates,
        ),
      );
    }
  }

  pricePlans.push(
    ...createPackageWayToSellPricePlans(
      unitRates,
      pricePlanOccupancy,
      wayToSellRates,
      packageOffersRates,
    ),
  );

  const privatePricePlan = createPrivateWayToSellPricePlan(
    unitRates,
    pricePlanOccupancy,
    wayToSellRates,
  );

  if (privatePricePlan) {
    pricePlans.push(privatePricePlan);
  }

  return pricePlans;
};

const createStandardWayToSellPricePlan = (
  { unit, occupancy }: UnitRates,
  pricePlanOccupancy: Occupancy,
  {
    wayToSell,
    nightlyRates,
    mealsRates,
    promocodeRates,
    includedMeals,
  }: WayToSellRates,
): StandardWayToSellPricePlanVariant => ({
  id: generateUniqueId(),
  type: PricePlanVariantType.Standard,
  preDiscountRate:
    promocodeRates &&
    promocodeRates.promocode.promocodeType !== PromocodeType.Descriptive
      ? getTotalRateFromNightlyRates(nightlyRates)
      : undefined,
  rate: getTotalRateFromNightlyRates(
    promocodeRates?.nightlyRates ?? nightlyRates,
  ),
  unit,
  occupancy: pricePlanOccupancy,
  searchOccupancy: occupancy,
  promocode: promocodeRates?.promocode,
  wayToSell,
  mealPricePlans: createStandardMealPricePlans(mealsRates),
  includedMeals,
});

const createDiscountedWayToSellPricePlan = (
  { unit, occupancy }: UnitRates,
  pricePlanOccupancy: Occupancy,
  { wayToSell, nightlyRates, mealsRates, includedMeals }: WayToSellRates,
  nonPackageOfferRates: NonPackageOfferRates,
): DiscountedWayToSellPricePlanVariant => ({
  id: generateUniqueId(),
  type: PricePlanVariantType.Discounted,
  rate: getTotalRateFromNightlyRates(
    nonPackageOfferRates.promocodeRates?.nightlyRates ??
      nonPackageOfferRates.nightlyRates,
  ),
  unit,
  searchOccupancy: occupancy,
  occupancy: pricePlanOccupancy,
  promocode: nonPackageOfferRates.promocodeRates?.promocode,
  wayToSell,
  preDiscountRate: getTotalRateFromNightlyRates(nightlyRates),
  offer: nonPackageOfferRates.offer,
  mealPricePlans: createMealPricePlans(
    mealsRates,
    nonPackageOfferRates.mealsRates,
  ),
  includedMeals,
});

const createPackageWayToSellPricePlans = (
  unitRates: UnitRates,
  occupancy: Occupancy,
  wayToSellRates: WayToSellRates,
  packageOffersRates: PackageOfferRates[],
): PackageWayToSellPricePlanVariant[] =>
  packageOffersRates.map((packageOfferRates) =>
    createPackageWayToSellPricePlan(
      unitRates,
      occupancy,
      wayToSellRates,
      packageOfferRates,
    ),
  );

const createPackageWayToSellPricePlan = (
  { unit, occupancy }: UnitRates,
  pricePlanOccupancy: Occupancy,
  { wayToSell }: WayToSellRates,
  packageOfferRates: PackageOfferRates,
): PackageWayToSellPricePlanVariant => ({
  id: generateUniqueId(),
  type: PricePlanVariantType.Package,
  rate: getTotalRateFromNightlyRates(packageOfferRates.nightlyRates),
  unit,
  searchOccupancy: occupancy,
  occupancy: pricePlanOccupancy,
  wayToSell,
  offer: packageOfferRates.offer,
});

const createPrivateWayToSellPricePlan = (
  { unit, occupancy }: UnitRates,
  pricePlanOccupancy: Occupancy,
  { wayToSell, privateRates }: WayToSellRates,
): PrivateWayToSellPricePlanVariant | undefined =>
  privateRates
    ? {
        id: generateUniqueId(),
        type: PricePlanVariantType.Private,
        rate: getTotalRateFromNightlyRates(privateRates.nightlyRates),
        unit,
        searchOccupancy: occupancy,
        occupancy: pricePlanOccupancy,
        wayToSell,
        privateRateOverride: privateRates.privateRateOverride,
      }
    : undefined;
