<template>
  <TermsAndConditionsStatementSection
    :title="toTitleCase($t('additionalCheckInNotes'))"
  >
    <p>{{ checkInNotes }}</p>
  </TermsAndConditionsStatementSection>
</template>

<script setup lang="ts">
import { useLocaleString } from '@/string/locale-string.composable';
import TermsAndConditionsStatementSection from '@/terms-and-conditions/statement/sections/TermsAndConditionsStatementSection.vue';
import { useTermsAndConditionsStatement } from '@/terms-and-conditions/statement/terms-and-conditions-statement.composable';

const { checkInNotes } = useTermsAndConditionsStatement();

const { toTitleCase } = useLocaleString();
</script>
