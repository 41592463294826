<template>
  <AppForm
    ref="form"
    class="max-w-lg space-y-8"
    @submit="bookingStageStore.guestDetailsStage.goToNextStage"
  >
    <input type="submit" hidden />

    <AppFormInputValidationGroup>
      <GuestDetailsFormForenameInput />

      <GuestDetailsFormSurnameInput />
    </AppFormInputValidationGroup>

    <AppFormInputValidationGroup>
      <GuestDetailsFormEmailAddressInput />

      <GuestDetailsFormConfirmEmailAddressInput />
    </AppFormInputValidationGroup>

    <AppFormInputValidationGroup>
      <GuestDetailsFormPhoneNumberInput />
    </AppFormInputValidationGroup>

    <AppFormInputValidationGroup>
      <GuestDetailsFormAddressInput />

      <GuestDetailsFormCityInput />

      <GuestDetailsFormPostcodeInput />
    </AppFormInputValidationGroup>

    <div class="space-y-4">
      <AppFormInputValidationGroup>
        <GuestDetailsFormCountryInput />
      </AppFormInputValidationGroup>

      <AppFormInputValidationGroup v-if="guestStore.countryIsUS">
        <GuestDetailsFormAmericanStateValidatedInput />
      </AppFormInputValidationGroup>
    </div>

    <GuestDetailsFormUnits />

    <GuestDetailsFormCheckIn />

    <AppFormInputValidationGroup
      v-if="searchStore.activeProperty.widgetSettings.allowsSpecialRequests"
    >
      <GuestDetailsFormSpecialRequestsInput />
    </AppFormInputValidationGroup>

    <GuestDetailsFormTermsAndConditions />
  </AppForm>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useBookingStageStore } from '@/booking-stage/booking-stage.store';
import { useGuestStore } from '@/guest/guest.store';
import GuestDetailsFormAddressInput from '@/guest-details-page/form/address/GuestDetailsFormAddressInput.vue';
import GuestDetailsFormAmericanStateValidatedInput from '@/guest-details-page/form/american-state/GuestDetailsFormAmericanStateValidatedInput.vue';
import GuestDetailsFormCheckIn from '@/guest-details-page/form/check-in/GuestDetailsFormCheckIn.vue';
import GuestDetailsFormCityInput from '@/guest-details-page/form/city/GuestDetailsFormCityInput.vue';
import GuestDetailsFormConfirmEmailAddressInput from '@/guest-details-page/form/confirm-email-address/GuestDetailsFormConfirmEmailAddressInput.vue';
import GuestDetailsFormCountryInput from '@/guest-details-page/form/country/GuestDetailsFormCountryInput.vue';
import GuestDetailsFormEmailAddressInput from '@/guest-details-page/form/email-address/GuestDetailsFormEmailAddressInput.vue';
import GuestDetailsFormForenameInput from '@/guest-details-page/form/forename/GuestDetailsFormForenameInput.vue';
import GuestDetailsFormPhoneNumberInput from '@/guest-details-page/form/phone-number/GuestDetailsFormPhoneNumberInput.vue';
import GuestDetailsFormPostcodeInput from '@/guest-details-page/form/postcode/GuestDetailsFormPostcodeInput.vue';
import GuestDetailsFormSpecialRequestsInput from '@/guest-details-page/form/special-requests/GuestDetailsFormSpecialRequestsInput.vue';
import GuestDetailsFormSurnameInput from '@/guest-details-page/form/surname/GuestDetailsFormSurnameInput.vue';
import GuestDetailsFormTermsAndConditions from '@/guest-details-page/form/terms-and-conditions/GuestDetailsFormTermsAndConditions.vue';
import GuestDetailsFormUnits from '@/guest-details-page/form/unit/GuestDetailsFormUnits.vue';
import { useGuestDetailsStore } from '@/guest-details-page/guest-details.store';
import { useSearchStore } from '@/search/search.store';
import AppForm from '@/ui/app-form/AppForm.vue';
import AppFormInputValidationGroup from '@/ui/app-form/AppFormInputValidationGroup.vue';

const bookingStageStore = useBookingStageStore();
const guestStore = useGuestStore();
const guestDetailsStore = useGuestDetailsStore();
const searchStore = useSearchStore();

const { form } = storeToRefs(guestDetailsStore);
</script>
