<template>
  <div class="flex flex-col gap-6">
    <component
      :is="section"
      v-for="(section, index) in uncollapsibleSections"
      :key="index"
    />

    <template v-if="canCollapseSections && collapsibleSections.length > 0">
      <AppLinkButton
        class="mx-auto"
        :text="
          $t(
            showCollapsibleSections
              ? 'showFewerTermsAndConditions'
              : 'showMoreTermsAndConditions',
          )
        "
        type="button"
        @click="showCollapsibleSections = !showCollapsibleSections"
      />
    </template>

    <template v-if="showCollapsibleSections">
      <component
        :is="section"
        v-for="(section, index) in collapsibleSections"
        :key="index"
      />

      <template
        v-if="!canCollapseSections && property.extendedTermsAndConditions"
      >
        <AppLinkButton
          class="mx-auto"
          :text="toTitleCase($t('additionalTermsAndConditions'))"
          type="button"
          @click="isExtendedTermsAndConditionsModalOpen = true"
        />

        <ExtendedTermsAndConditionsModal
          :extended-terms-and-conditions="property.extendedTermsAndConditions"
          :is-open="isExtendedTermsAndConditionsModalOpen"
          @close="isExtendedTermsAndConditionsModalOpen = false"
        />
      </template>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import type { BookingPolicy } from '@/property/booking-policy/booking-policy';
import type { Property } from '@/property/property';
import type { StayDates } from '@/stay-dates/stay-dates';
import { useLocaleString } from '@/string/locale-string.composable';
import ExtendedTermsAndConditionsModal from '@/terms-and-conditions/extended/modal/ExtendedTermsAndConditionsModal.vue';
import TermsAndConditionsStatementAdditionalCheckInNotesSection from '@/terms-and-conditions/statement/sections/additional-check-in-notes/TermsAndConditionsStatementAdditionalCheckInNotesSection.vue';
import TermsAndConditionsStatementCancellationConditionsSection from '@/terms-and-conditions/statement/sections/cancellation-conditions/TermsAndConditionsStatementCancellationConditionsSection.vue';
import TermsAndConditionsStatementCancellationInformationSection from '@/terms-and-conditions/statement/sections/cancellation-information/TermsAndConditionsStatementCancellationInformationSection.vue';
import TermsAndConditionsStatementNonRefundableSection from '@/terms-and-conditions/statement/sections/non-refundable/TermsAndConditionsStatementNonRefundableSection.vue';
import TermsAndConditionsStatementPaymentConditionsSection from '@/terms-and-conditions/statement/sections/payment-conditions/TermsAndConditionsStatementPaymentConditionsSection.vue';
import TermsAndConditionsStatementPolicySection from '@/terms-and-conditions/statement/sections/policy/TermsAndConditionsStatementPolicySection.vue';
import { useProvideTermsAndConditionsStatement } from '@/terms-and-conditions/statement/terms-and-conditions-statement.composable';
import AppLinkButton from '@/ui/app-link-button/AppLinkButton.vue';

const NUMBER_OF_UNCOLLAPSIBLE_SECTIONS = 2;

const {
  property,
  bookingPolicy,
  stayDates,
  isNonRefundable,
  canCollapseSections = false,
} = defineProps<{
  property: Property;
  bookingPolicy: BookingPolicy;
  stayDates: StayDates;
  isNonRefundable: boolean;
  canCollapseSections?: boolean;
}>();

const { toTitleCase } = useLocaleString();

const {
  checkInNotes,
  additionalCancellationConditions,
  cancellationPoliciesTerms,
} = useProvideTermsAndConditionsStatement(
  computed(() => property),
  computed(() => bookingPolicy),
  computed(() => stayDates),
);

const showCollapsibleSections = ref(!canCollapseSections);
const isExtendedTermsAndConditionsModalOpen = ref(false);

const sections = computed(() => [
  ...(cancellationPoliciesTerms.value.length > 0 && !isNonRefundable
    ? [TermsAndConditionsStatementCancellationConditionsSection]
    : []),
  ...(additionalCancellationConditions.value
    ? [TermsAndConditionsStatementCancellationInformationSection]
    : []),
  ...(checkInNotes.value
    ? [TermsAndConditionsStatementAdditionalCheckInNotesSection]
    : []),
  TermsAndConditionsStatementPolicySection,
  ...(isNonRefundable
    ? [TermsAndConditionsStatementNonRefundableSection]
    : [TermsAndConditionsStatementPaymentConditionsSection]),
]);

const uncollapsibleSections = computed(() =>
  sections.value.slice(0, NUMBER_OF_UNCOLLAPSIBLE_SECTIONS),
);

const collapsibleSections = computed(() =>
  sections.value.slice(NUMBER_OF_UNCOLLAPSIBLE_SECTIONS),
);
</script>
