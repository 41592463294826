<template>
  <header class="flex items-end justify-between">
    <div class="mr-2">
      <div
        v-if="title"
        class="text-base"
        data-test="UnitSelectionPricePlanHeader__title"
      >
        <h2
          v-if="!searchStore.isLoading"
          class="break-anywhere font-semibold"
          translate="yes"
        >
          {{ title }}
        </h2>

        <AppSkeletonText v-else class="w-40" />
      </div>

      <div :class="title ? 'text-sm' : 'font-semibold'">
        <p v-if="!searchStore.isLoading" translate="yes" class="break-anywhere">
          {{ pricePlan.unit.name }}
        </p>

        <AppSkeletonText v-else class="w-28" />
      </div>
    </div>

    <UnitSelectionPricePlanRate
      :rate="pricePlan.rate"
      :pre-discount-rate="pricePlan.preDiscountRate ?? null"
      :price-label="priceLabel"
    />
  </header>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import type { PricePlan } from '@/price-plan/price-plan';
import { PricePlanVariantType } from '@/price-plan/price-plan';
import { findPrivateRateOverrideIncludedPropertyMeals } from '@/private-rate-override/private-rate-override.utilities';
import { UNIT_TYPE_NAME_BED } from '@/property/unit/type/unit-type';
import { useUnit } from '@/property/unit/unit.composable';
import { useSearchStore } from '@/search/search.store';
import { useLocaleString } from '@/string/locale-string.composable';
import AppSkeletonText from '@/ui/app-skeleton-text/AppSkeletonText.vue';
import UnitSelectionPricePlanRate from '@/unit-selection-page/price-plan/rate/UnitSelectionPricePlanRate.vue';

const props = defineProps<{
  pricePlan: PricePlan;
}>();

const searchStore = useSearchStore();

const { toTitleCase } = useLocaleString();
const { t } = useI18n();

const title = computed(() => {
  if (props.pricePlan.type === PricePlanVariantType.Package) {
    return props.pricePlan.offer.name;
  }

  const meals =
    props.pricePlan.type === PricePlanVariantType.Private
      ? findPrivateRateOverrideIncludedPropertyMeals(
          props.pricePlan.privateRateOverride,
          searchStore.activeProperty,
        )
      : props.pricePlan.includedMeals;

  return meals.length > 0
    ? meals.map(({ name }) => name).join(' • ')
    : noMealsIncludedDisplayText.value;
});

const priceLabel = computed(() =>
  props.pricePlan.unit.type.name === UNIT_TYPE_NAME_BED ? t('perBed') : null,
);

const { mealServiceTypeDisplayText } = useUnit(props.pricePlan.unit);
const noMealsIncludedDisplayText = computed(() => {
  if (mealServiceTypeDisplayText.value) {
    return toTitleCase(mealServiceTypeDisplayText.value);
  }

  return undefined;
});
</script>
