import type { PricePlan } from '@/price-plan/price-plan';
import {
  comparePricePlansByOccupancyAndSearchOccupancyEquality,
  comparePricePlansByRate,
  comparePricePlansByWayToSellPriority,
  comparePricePlansByWhetherOccupancyCanFitSearchOccupancy,
  groupPricePlansByAttribute,
  partitionPricePlansByOccupanciesThatDidFitSearchOccupancy,
  partitionPricePlansByOccupancyAndSearchOccupancyEquality,
} from '@/price-plan/price-plan.utilities';

const MAX_PRICE_PLANS_PER_OCCUPANCY_IN_GROUP_A = 2;

/**
 * Organizes the list of given price plans (belonging to the same unit) into 3 smaller groups
 * (A, B and C), where each group is of descending priority, and internally sorted.
 *
 * In summary:
 * - Group A contains:
 *   - At most, 2 price plans that are directly using a search occupancy. These will be the
 *     cheapest 2, sorted by rate.
 * - Group B contains:
 *   - The remaining price plans that were directly using a search occupancy, but were not
 *     the cheapest 2, sorted by rate.
 *   - The other price plans that were not directly using a search occupancy, but were at
 *     least using an occupancy that could fit the search occupancy, sorted by rate.
 *     - These price plans should always come below the price plans that were directly using
 *       a search occupancy.
 * - Group C contains:
 *   - The price plans that were not directly using a search occupancy, and were not using an
 *     occupancy that could fit the search occupancy, sorted by rate.
 *
 * A higher priority group cannot be empty if a lower priority group is not empty. This means
 * price plans that would typically reside in a lower priority group may move up to a higher
 * priority group if there are gaps to fill.
 *
 * In summary:
 * - Group A may:
 *   - Use price plans that were not directly using a search occupancy, but were at least using
 *     an occupancy that could fit the search occupancy, in the case that no price were directly
 *     using a search occupancy.
 *   - Use price plans that were not directly using a search occupancy, and were not using an
 *     occupancy that could fit the search occupancy, in the case that they are the only price plans
 *     that have been provided.
 * - Group B may:
 *   - Use price plans that were not directly using a search occupancy, and were not using an occupancy
 *     that could fit the search occupancy, in the case that group A is occupied but no remaining price
 *     plans were using an occupancy that could fit the search occupancy.
 */
export const organizeUnitPricePlans = (
  pricePlans: PricePlan[],
): PricePlan[][] => {
  const pricePlanGroupA: PricePlan[] = [];
  const pricePlanGroupB: PricePlan[] = [];
  const pricePlanGroupC: PricePlan[] = [];

  const [
    pricePlansUsingOccupanciesThatDidNotFitSearchOccupancy,
    pricePlansUsingOccupanciesThatDidFitSearchOccupancy,
  ] = partitionPricePlansByOccupanciesThatDidFitSearchOccupancy(pricePlans);

  const [
    pricePlansUsingSearchOccupancies,
    pricePlansUsingNonSearchOccupancies,
  ] = partitionPricePlansByOccupancyAndSearchOccupancyEquality(
    pricePlansUsingOccupanciesThatDidFitSearchOccupancy,
  );

  populatePricePlanGroupsWithPricePlansUsingSearchOccupancies(
    pricePlanGroupA,
    pricePlanGroupB,
    pricePlansUsingSearchOccupancies,
  );

  populatePricePlanGroupsWithPricePlansUsingNonSearchOccupancies(
    pricePlanGroupA,
    pricePlanGroupB,
    pricePlansUsingNonSearchOccupancies,
  );

  populatePricePlanGroupsWithPricePlansUsingOccupanciesThatDidNotFitSearchOccupancy(
    pricePlanGroupA,
    pricePlanGroupB,
    pricePlanGroupC,
    pricePlansUsingOccupanciesThatDidNotFitSearchOccupancy,
  );

  return createOrganizedPricePlansFromPricePlanGroups(
    pricePlanGroupA,
    pricePlanGroupB,
    pricePlanGroupC,
  );
};

const populatePricePlanGroupsWithPricePlansUsingSearchOccupancies = (
  pricePlanGroupA: PricePlan[],
  pricePlanGroupB: PricePlan[],
  pricePlansUsingSearchOccupancies: PricePlan[],
): void => {
  const pricePlansUsingSearchOccupanciesGroupedByOccupancy =
    groupPricePlansByAttribute(pricePlansUsingSearchOccupancies, 'occupancy');

  const cheapestPricePlansUsingSearchOccupancies: PricePlan[] = [];
  const remainingPricePlansUsingSearchOccupancies: PricePlan[] = [];

  for (const pricePlansUsingSearchOccupancy of pricePlansUsingSearchOccupanciesGroupedByOccupancy) {
    const pricePlansUsingSearchOccupancySortedByDisplayPriority =
      sortPricePlans(pricePlansUsingSearchOccupancy);

    cheapestPricePlansUsingSearchOccupancies.push(
      ...pricePlansUsingSearchOccupancySortedByDisplayPriority.slice(
        0,
        MAX_PRICE_PLANS_PER_OCCUPANCY_IN_GROUP_A,
      ),
    );

    remainingPricePlansUsingSearchOccupancies.push(
      ...pricePlansUsingSearchOccupancySortedByDisplayPriority.slice(
        MAX_PRICE_PLANS_PER_OCCUPANCY_IN_GROUP_A,
      ),
    );
  }

  pricePlanGroupA.push(
    ...sortPricePlans(cheapestPricePlansUsingSearchOccupancies),
  );

  pricePlanGroupB.push(
    ...sortPricePlans(remainingPricePlansUsingSearchOccupancies),
  );
};

const populatePricePlanGroupsWithPricePlansUsingNonSearchOccupancies = (
  pricePlanGroupA: PricePlan[],
  pricePlanGroupB: PricePlan[],
  pricePlansUsingNonSearchOccupancies: PricePlan[],
): void => {
  const pricePlansUsingNonSearchOccupanciesSortedByDisplayPriority =
    sortPricePlans(pricePlansUsingNonSearchOccupancies);

  if (pricePlanGroupA.length === 0) {
    pricePlanGroupA.push(
      ...pricePlansUsingNonSearchOccupanciesSortedByDisplayPriority.slice(
        0,
        MAX_PRICE_PLANS_PER_OCCUPANCY_IN_GROUP_A,
      ),
    );

    pricePlanGroupB.push(
      ...pricePlansUsingNonSearchOccupanciesSortedByDisplayPriority.slice(
        MAX_PRICE_PLANS_PER_OCCUPANCY_IN_GROUP_A,
      ),
    );
  } else {
    pricePlanGroupB.push(
      ...pricePlansUsingNonSearchOccupanciesSortedByDisplayPriority,
    );
  }
};

const populatePricePlanGroupsWithPricePlansUsingOccupanciesThatDidNotFitSearchOccupancy =
  (
    pricePlanGroupA: PricePlan[],
    pricePlanGroupB: PricePlan[],
    pricePlanGroupC: PricePlan[],
    pricePlansUsingOccupanciesThatDidNotFitSearchOccupancy: PricePlan[],
  ): void => {
    const pricePlansUsingOccupanciesThatDidNotFitSearchOccupancySortedByDisplayPriority =
      sortPricePlans(pricePlansUsingOccupanciesThatDidNotFitSearchOccupancy);

    if (pricePlanGroupA.length === 0) {
      pricePlanGroupA.push(
        ...pricePlansUsingOccupanciesThatDidNotFitSearchOccupancySortedByDisplayPriority.slice(
          0,
          MAX_PRICE_PLANS_PER_OCCUPANCY_IN_GROUP_A,
        ),
      );

      pricePlanGroupB.push(
        ...pricePlansUsingOccupanciesThatDidNotFitSearchOccupancySortedByDisplayPriority.slice(
          MAX_PRICE_PLANS_PER_OCCUPANCY_IN_GROUP_A,
        ),
      );
    } else if (pricePlanGroupB.length === 0) {
      pricePlanGroupB.push(
        ...pricePlansUsingOccupanciesThatDidNotFitSearchOccupancySortedByDisplayPriority,
      );
    } else {
      pricePlanGroupC.push(
        ...pricePlansUsingOccupanciesThatDidNotFitSearchOccupancySortedByDisplayPriority,
      );
    }
  };

const createOrganizedPricePlansFromPricePlanGroups = (
  pricePlanGroupA: PricePlan[],
  pricePlanGroupB: PricePlan[],
  pricePlanGroupC: PricePlan[],
): PricePlan[][] => {
  const organizedPricePlans: PricePlan[][] = [];

  if (pricePlanGroupA.length > 0) {
    organizedPricePlans.push(pricePlanGroupA);
  }

  if (pricePlanGroupB.length > 0) {
    organizedPricePlans.push(pricePlanGroupB);
  }

  if (pricePlanGroupC.length > 0) {
    organizedPricePlans.push(pricePlanGroupC);
  }

  return organizedPricePlans;
};

const sortPricePlans = (pricePlans: PricePlan[]): PricePlan[] =>
  [...pricePlans].sort(
    (pricePlanA, pricePlanB) =>
      comparePricePlansByOccupancyAndSearchOccupancyEquality(
        pricePlanA,
        pricePlanB,
      ) ||
      comparePricePlansByWhetherOccupancyCanFitSearchOccupancy(
        pricePlanA,
        pricePlanB,
      ) ||
      comparePricePlansByRate(pricePlanA, pricePlanB) ||
      comparePricePlansByWayToSellPriority(pricePlanA, pricePlanB),
  );
