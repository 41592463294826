import type {
  MealRateAdjustment,
  MealRateAdjustmentDTO,
} from '@/property/meal/meal-rate-adjustment/meal-rate-adjustment';
import { MealRateAdjustmentType } from '@/property/meal/meal-rate-adjustment/meal-rate-adjustment';

export const mapMealRateAdjustmentFromDTO = (
  mealRateAdjustmentDTO: MealRateAdjustmentDTO,
): MealRateAdjustment => {
  const { adjustmentType } = mealRateAdjustmentDTO;

  switch (adjustmentType) {
    case MealRateAdjustmentType.Free:
      return {
        adjustmentType,
      };
    case MealRateAdjustmentType.None:
      return {
        adjustmentType,
      };
    case MealRateAdjustmentType.StandardRatePercentage:
      return {
        adjustmentType,
        percentage: Number(mealRateAdjustmentDTO.percentage),
      };
    case MealRateAdjustmentType.PerNightMonetary:
      return {
        adjustmentType,
        amount: Number(mealRateAdjustmentDTO.amount),
      };
  }
};
