<template>
  <div
    v-for="{ extra, isLastItem } in extraItems"
    :key="extra.id"
    :class="{
      'bg-zinc-100': extra.isMandatory,
    }"
  >
    <div
      class="mx-4 py-4"
      :class="{
        'border-b border-neutral-200': !isLastItem,
      }"
    >
      <ExtrasItem :extra="extra" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useExtrasStore } from '@/extras-page/extras.store';
import ExtrasItem from '@/extras-page/item/ExtrasItem.vue';
import type { Extra } from '@/property/extra/extra';
import { sortExtrasByPriority } from '@/property/extra/extra.utilities';
import { useSearchStore } from '@/search/search.store';

interface ExtraItem {
  extra: Extra;
  isLastItem: boolean;
}

const searchStore = useSearchStore();
const extrasStore = useExtrasStore();

const mapExtrasToExtraItems = (extras: Extra[]): ExtraItem[] =>
  sortExtrasByPriority(extras, searchStore.activeProperty).map((extra, i) => ({
    extra,
    isLastItem: i === extras.length - 1,
  }));

const extraItems = computed<ExtraItem[]>(() => [
  ...mapExtrasToExtraItems(extrasStore.mandatoryExtras),
  ...mapExtrasToExtraItems(extrasStore.optionalExtras),
]);
</script>
