<template>
  <div class="flex grow justify-end">
    <div class="relative w-full md:w-48">
      <AppNumberSelectorSpinner
        v-model="pricePlanQuantitySelected"
        :item-name="pricePlan.unit.name"
        :number-to-select="numberToSelect"
        :button-text="buttonText"
      />

      <AppSkeletonBox
        v-show="searchStore.isLoading"
        class="absolute inset-0 z-10 rounded"
      />
    </div>

    <UnitSelectionPricePlanMultiDateBookingSelectionWarningModal
      :is-open="showMultiDateBookingWarning"
      @close="showMultiDateBookingWarning = false"
    />

    <UnitSelectionPricePlanNonFittingSearchOccupancyWarningModal
      :is-open="showNonFittingSearchOccupancyWarning"
      :price-plan="pricePlan"
      @close="showNonFittingSearchOccupancyWarning = false"
    />
  </div>
</template>
<script setup lang="ts">
import { isEqual, cloneDeep } from 'lodash-es';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import {
  convertPricePlanToUnitItineraryItem,
  doesPricePlanMatchUnitItineraryItem,
} from '@/booking-itinerary/booking-itinerary.utilities';
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import { getTotalNumberOfAdultsInOccupancies } from '@/occupancy/occupancy.utilities';
import type { PricePlan } from '@/price-plan/price-plan';
import { isPricePlanSearchOccupancyLargerThanOccupancy } from '@/price-plan/price-plan.utilities';
import { UNIT_TYPE_NAME_BED } from '@/property/unit/type/unit-type';
import { useUnitTypeCount } from '@/property/unit/type/unit-type-count.composable';
import { useSearchStore } from '@/search/search.store';
import { useLocaleString } from '@/string/locale-string.composable';
import AppNumberSelectorSpinner from '@/ui/app-number-selector-spinner/AppNumberSelectorSpinner.vue';
import AppSkeletonBox from '@/ui/app-skeleton-box/AppSkeletonBox.vue';
import UnitSelectionPricePlanMultiDateBookingSelectionWarningModal from '@/unit-selection-page/price-plan/multi-date-booking-selection-warning/UnitSelectionPricePlanMultiDateBookingSelectionWarningModal.vue';
import UnitSelectionPricePlanNonFittingSearchOccupancyWarningModal from '@/unit-selection-page/price-plan/non-fitting-search-occupancy-warning/UnitSelectionPricePlanNonFittingSearchOccupancyWarningModal.vue';
import { useUnitSelectionStore } from '@/unit-selection-page/unit-selection.store';

const props = defineProps<{
  pricePlan: PricePlan;
}>();

const { t } = useI18n();
const { toTitleCase } = useLocaleString();

const emit = defineEmits(['noUnitsRemaining']);

const searchStore = useSearchStore();
const unitSelectionStore = useUnitSelectionStore();
const bookingItineraryStore = useBookingItineraryStore();

const numberToSelect = computed(() =>
  shouldBulkSelectTotalAdultOccupancy.value ? totalNumberOfAdults.value : 1,
);

const buttonText = computed(() =>
  toTitleCase(
    numberToSelect.value === 1
      ? t('select')
      : t('selectCount', {
          count: useUnitTypeCount(
            computed(() => props.pricePlan.unit.type),
            numberToSelect.value,
          ).unitTypeNameCount.value,
        }),
  ),
);

const showMultiDateBookingWarning = ref(false);
const showNonFittingSearchOccupancyWarning = ref(false);

const numberOfUnitsAvailableAfterSelection = computed(() =>
  searchStore.activePropertyOverStay.findNumberOfUnitsAvailableAfterSelectionByUnitId(
    props.pricePlan.unit.id,
  ),
);

const totalNumberOfAdults = computed(() =>
  getTotalNumberOfAdultsInOccupancies(searchStore.occupancies),
);

const shouldBulkSelectTotalAdultOccupancy = computed(
  () =>
    numberOfUnitsAvailableAfterSelection.value - totalNumberOfAdults.value >=
      0 &&
    bookingItineraryStore.hasEmptyUnitItinerary &&
    props.pricePlan.unit.type.name === UNIT_TYPE_NAME_BED,
);

const pricePlanQuantitySelected = computed({
  get() {
    return bookingItineraryStore.unitItinerary.filter((unitItineraryItem) =>
      doesPricePlanMatchUnitItineraryItem(props.pricePlan, unitItineraryItem),
    ).length;
  },
  set(newQuantity) {
    if (newQuantity > pricePlanQuantitySelected.value) {
      if (numberOfUnitsAvailableAfterSelection.value > 0) {
        selectPricePlan(newQuantity - pricePlanQuantitySelected.value);
      } else {
        emit('noUnitsRemaining');
      }
    } else {
      removePricePlan();
    }
  },
});

const selectPricePlan = (numberToSelect: number) => {
  if (!isEqual(bookingItineraryStore.stayDates, searchStore.stayDates)) {
    showMultiDateBookingWarning.value = true;
    return;
  }

  if (isPricePlanSearchOccupancyLargerThanOccupancy(props.pricePlan)) {
    showNonFittingSearchOccupancyWarning.value = true;
    return;
  }

  for (let i = 0; i < numberToSelect; i++) {
    bookingItineraryStore.addUnitItineraryItem(
      convertPricePlanToUnitItineraryItem(
        props.pricePlan,
        cloneDeep(
          unitSelectionStore.pricePlanSelectedMeals.get(props.pricePlan.id),
        ) ?? [],
      ),
    );
  }
};

const removePricePlan = () => {
  bookingItineraryStore.removeUnitItineraryItem(
    convertPricePlanToUnitItineraryItem(
      props.pricePlan,
      cloneDeep(
        unitSelectionStore.pricePlanSelectedMeals.get(props.pricePlan.id),
      ) ?? [],
    ),
  );
};
</script>
