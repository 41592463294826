<template>
  <div>
    <AppDateRange
      v-if="vanityStore.stayDates"
      :from-date="vanityStore.stayDates.checkInDate"
      :to-date="vanityStore.stayDates.checkOutDate"
    />

    <div v-else class="max-w-[12rem] truncate sm:max-w-none">
      {{ $t('selectStayDates') }}
    </div>
  </div>
</template>

<script setup lang="ts">
import AppDateRange from '@/ui/app-date-range/AppDateRange.vue';
import { useVanityStore } from '@/vanity-page/vanity.store';

const vanityStore = useVanityStore();
</script>
