<template>
  <UnitSelectionAdditionalPropertiesItem
    v-for="additionalPropertyItem of sortedAdditionalPropertyItems"
    :key="additionalPropertyItem.property.id"
    :property="additionalPropertyItem.property"
    is-available
    :banner-rate="additionalPropertyItem.bannerRate"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { comparePricePlansByRate } from '@/price-plan/price-plan.utilities';
import { usePropertyPricePlans } from '@/price-plan/property-price-plans/property-price-plans.composable';
import { useSearchStore } from '@/search/search.store';
import UnitSelectionAdditionalPropertiesItem from '@/unit-selection-page/additional-properties/item/UnitSelectionAdditionalPropertiesItem.vue';
import { compareWidgetPropertiesByRank } from '@/widget/widget.utilities';

const searchStore = useSearchStore();

const additionalPropertyPricePlans = computed(() =>
  searchStore.availableAdditionalPropertiesOverStay.map((propertyOverStay) =>
    usePropertyPricePlans(propertyOverStay, searchStore.occupancies),
  ),
);

const sortedAdditionalPropertyItems = computed(() =>
  [...additionalPropertyPricePlans.value]
    .sort(
      (propertyPricePlansA, propertyPricePlansB) =>
        compareWidgetPropertiesByRank(
          searchStore.widget,
          propertyPricePlansA.propertyOverStay.property,
          propertyPricePlansB.propertyOverStay.property,
        ) ||
        comparePricePlansByRate(
          propertyPricePlansA.highestDisplayPriorityPricePlan,
          propertyPricePlansB.highestDisplayPriorityPricePlan,
        ),
    )
    .map(({ propertyOverStay, highestDisplayPriorityPricePlan }) => ({
      property: propertyOverStay.property,
      bannerRate: highestDisplayPriorityPricePlan.rate,
    })),
);
</script>
