import type {
  PercentagePromocode,
  PercentagePromocodeDTO,
} from '@/property/promocode/percentage-promocode/percentage-promocode';
import { PromocodeType } from '@/property/promocode/promocode';

export const mapPercentagePromocodeFromDTO = (
  promocodeDTO: PercentagePromocodeDTO,
): PercentagePromocode => {
  return {
    promocodeType: PromocodeType.Percentage,
    promocodeText: promocodeDTO.promocodeText,
    description: promocodeDTO.description,
    percentage: Number(promocodeDTO.percentage),
    applicableDaysOfWeek: promocodeDTO.applicableDaysOfWeek,
    fromDate: promocodeDTO.fromDate,
    toDate: promocodeDTO.toDate,
    minimumStayLengthDays: promocodeDTO.minimumStayLengthDays,
  };
};
