<template>
  <AppAccordion v-model="isOpen" class="rounded-md border">
    <AppAccordionButton
      class="flex justify-between bg-gray-100 p-4 transition-colors duration-300 hover:bg-gray-200 focus-visible:shadow-[0_0_0_2px] focus-visible:outline-none"
      :class="isOpen ? 'rounded-t-md' : 'rounded-md'"
    >
      <span>{{ $t('termsAndConditions') }}</span>

      <AppAccordionChevron />
    </AppAccordionButton>

    <AppAccordionPanel>
      <div class="p-4 text-gray-600">
        <slot></slot>
      </div>
    </AppAccordionPanel>
  </AppAccordion>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import AppAccordion from '@/ui/app-accordion/AppAccordion.vue';
import AppAccordionButton from '@/ui/app-accordion/AppAccordionButton.vue';
import AppAccordionChevron from '@/ui/app-accordion/AppAccordionChevron.vue';
import AppAccordionPanel from '@/ui/app-accordion/AppAccordionPanel.vue';

const isOpen = ref(false);
</script>
