import type {
  AdditionalTaxRateBasis,
  AdditionalTaxRateBasisDTO,
} from '@/tax/additional/rate/basis/additional-tax-rate-basis';
import { AdditionalTaxRateBasisType } from '@/tax/additional/rate/basis/additional-tax-rate-basis';
import { mapAdditionalTaxRateGuestBasisFromDTO } from '@/tax/additional/rate/basis/guest/additional-tax-rate-guest-basis.mapper';

export const mapAdditionalTaxRateBasisFromDTO = (
  additionalTaxRateBasisDTO: AdditionalTaxRateBasisDTO,
): AdditionalTaxRateBasis => {
  const { type } = additionalTaxRateBasisDTO;

  switch (type) {
    case AdditionalTaxRateBasisType.Unit:
    case AdditionalTaxRateBasisType.Booking:
      return { type };
    case AdditionalTaxRateBasisType.Guest:
      return mapAdditionalTaxRateGuestBasisFromDTO(additionalTaxRateBasisDTO);
    default:
      throw new Error(
        `Unexpected additional tax rate basis type: ${JSON.stringify(type)}`,
      );
  }
};
