<template>
  <Transition
    enter-active-class="ease-out duration-300"
    enter-from-class="opacity-0 -translate-y-1"
    enter-to-class="opacity-100 translate-y-0"
    leave-active-class="ease-in duration-200"
    leave-from-class="opacity-100 translate-y-0"
    leave-to-class="opacity-0 -translate-y-1"
  >
    <PopoverPanel
      class="relative my-2 flex flex-col rounded-xl border border-neutral-200 bg-white shadow-2xl"
    >
      <header
        v-if="hasHeaderSlotContent"
        class="flex items-center justify-between border-b border-neutral-200 px-6 py-4"
      >
        <div class="mr-4">
          <slot name="header"></slot>
        </div>

        <PopoverButton :as="AppCloseButton" />
      </header>

      <div class="overflow-y-auto" role="region">
        <slot></slot>
      </div>

      <footer v-if="hasFooterSlotContent">
        <div class="border-t border-neutral-200 px-6 py-4">
          <slot name="footer"></slot>
        </div>
      </footer>
    </PopoverPanel>
  </Transition>
</template>

<script setup lang="ts">
import { PopoverPanel, PopoverButton } from '@headlessui/vue';
import { useSlots } from 'vue';
import AppCloseButton from '@/ui/app-close-button/AppCloseButton.vue';

const { header, footer } = useSlots();

const hasHeaderSlotContent = !!header;
const hasFooterSlotContent = !!footer;
</script>
