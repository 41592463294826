import { useEventListener } from '@vueuse/core';
import { mapBookingFromDTO } from '@/booking/booking.mapper';
import { useBookingConfirmationNavigation } from '@/booking-confirmation-page/booking-confirmation-navigation.composable';
import type {
  FreetobookMessageEventSagePay3DSSuccessData,
  FreetobookMessageEvent,
  FreetobookMessageEventSagePay3DSFailureData,
} from '@/message-event/freetobook/freetobook-message-event';
import { FreetobookMessageEventDataType } from '@/message-event/freetobook/freetobook-message-event';
import { isFreetobookMessageEvent } from '@/message-event/freetobook/freetobook-message-event.utilities';
import { isSagePayMessageEvent } from '@/message-event/sage-pay/sage-pay-message-event.utilities';
import { useSagePayCheckoutStore } from '@/payments-page/sage-pay-checkout/sage-pay-checkout.store';

export const useSagePayCheckoutMessageEvent = () => {
  const { goToBookingConfirmation } = useBookingConfirmationNavigation();

  const sagePayCheckoutStore = useSagePayCheckoutStore();

  const processMessageEvent = (event: MessageEvent) => {
    if (isSagePayMessageEvent(event)) {
      processSagePayMessageEvent();
    } else if (isFreetobookMessageEvent(event)) {
      processFreetobookMessageEvent(event);
    }
  };

  const processSagePayMessageEvent = () => {
    processSagePayHostedPageLoadedMessageEvent();
  };

  const processSagePayHostedPageLoadedMessageEvent = () => {
    sagePayCheckoutStore.isRenderingCheckoutForm = false;
  };

  const processFreetobookMessageEvent = (event: FreetobookMessageEvent) => {
    const { data } = event;

    if (data.type === FreetobookMessageEventDataType.SagePay3DSSuccess) {
      void processFreetobookSagePay3DSSuccessMessageEvent(data);
    } else {
      void processFreetobookSagePay3DSFailureMessageEvent(data);
    }
  };

  const processFreetobookSagePay3DSSuccessMessageEvent = async ({
    booking,
    accessToken,
  }: FreetobookMessageEventSagePay3DSSuccessData) => {
    sagePayCheckoutStore.is3DSModalOpen = false;

    await goToBookingConfirmation(mapBookingFromDTO(booking), accessToken);
  };

  const processFreetobookSagePay3DSFailureMessageEvent = async ({
    sagePayCode,
  }: FreetobookMessageEventSagePay3DSFailureData) => {
    sagePayCheckoutStore.is3DSModalOpen = false;

    sagePayCheckoutStore.setCardErrorMessageFromSagePayCode(sagePayCode);

    await sagePayCheckoutStore.loadMerchantSessionKey();
  };

  useEventListener(window, 'message', processMessageEvent);
};
