<template>
  <button
    class="AvailabilityCalendarDay__date relative w-full cursor-pointer focus-visible:rounded-full focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-[-2px] focus-visible:outline-slate-800"
    :class="{ 'font-medium': isStayableDate }"
    @mouseover="setDateBeingHovered(date)"
    @mouseleave="unsetDateBeingHovered()"
    @focus="setDateBeingHovered(date)"
    @blur="unsetDateBeingHovered()"
  >
    <div
      class="flex aspect-square flex-col place-content-center items-center hover:rounded-full"
      :class="isStayableDate ? 'hover:bg-amber-100' : 'hover:bg-neutral-100'"
    >
      <div
        class="flex h-full w-full flex-col justify-center"
        :class="[dateTextClassName, dateStyleClassName, hoverRangeClassName]"
      >
        {{ format(date, 'd') }}
      </div>
    </div>
  </button>
</template>

<script setup lang="ts">
import {
  format,
  isAfter,
  isBefore,
  isSameDay,
  isWithinInterval,
} from 'date-fns';
import { computed } from 'vue';
import { useAvailabilityCalendarStore } from '@/availability-calendar/availability-calendar.store';

const props = withDefaults(
  defineProps<{
    date: number;
    isConditionallyUnavailable?: boolean;
    isUnavailable?: boolean;
  }>(),
  {
    isConditionallyUnavailable: false,
    isUnavailable: false,
  },
);

const {
  selectedCheckInDate,
  selectedCheckOutDate,
  isCheckInAndCheckOutDateSelected,
  isOnlyCheckInDateSelected,
  dateBeingHovered,
  setDateBeingHovered,
  unsetDateBeingHovered,
} = useAvailabilityCalendarStore();

const isStayableDate = computed(
  () => !(props.isConditionallyUnavailable || props.isUnavailable),
);

const isSelectedForCheckIn = computed(
  () => selectedCheckInDate.value === props.date,
);

const isSelectedForCheckOut = computed(
  () => selectedCheckOutDate.value === props.date,
);

const isBetweenStay = computed(() =>
  isCheckInAndCheckOutDateSelected.value
    ? isWithinInterval(props.date, {
        start: selectedCheckInDate.value!,
        end: selectedCheckOutDate.value!,
      })
    : false,
);

const dateTextClassName = computed(() => {
  if (props.isConditionallyUnavailable) {
    return 'text-slate-500';
  }
  if (props.isUnavailable) {
    return 'text-slate-300 line-through';
  }
  return 'text-slate-800';
});

const dateStyleClassName = computed(() => {
  const semiCircleBorderClassName =
    'before:border-4 before:rounded-full before:border-transparent before:border-l-amber-500 before:border-b-amber-500 before:h-full before:w-full before:absolute';

  if (isSelectedForCheckIn.value) {
    const borderRadiusClassName =
      (!!dateBeingHovered.value &&
        isAfter(dateBeingHovered.value, selectedCheckInDate.value!)) ||
      isCheckInAndCheckOutDateSelected.value
        ? 'rounded-l-full'
        : 'rounded-full';

    return `bg-amber-100 ${borderRadiusClassName} ${semiCircleBorderClassName} before:rotate-45`;
  }
  if (isSelectedForCheckOut.value) {
    return `bg-amber-100 ${semiCircleBorderClassName} rounded-r-full before:rotate-[225deg]`;
  }
  if (isBetweenStay.value) {
    return `bg-amber-100 rounded-none`;
  }
  return '';
});

const hoverRangeClassName = computed(() => {
  if (
    !props.isUnavailable &&
    dateBeingHovered.value &&
    isOnlyCheckInDateSelected.value &&
    isAfter(props.date, selectedCheckInDate.value!)
  ) {
    if (isBefore(props.date, dateBeingHovered.value)) {
      return `bg-amber-100 rounded-none`;
    }
    if (isSameDay(props.date, dateBeingHovered.value)) {
      return `bg-amber-100 rounded-l-none rounded-r-full`;
    }
  }
  return '';
});
</script>
