<template>
  <div class="h-full" data-test="AppImageCarousel">
    <Swiper
      class="swiper-wrapper !h-[80%]"
      :modules="modules"
      :pagination="{
        type: 'fraction',
      }"
      :keyboard="{ enabled: true }"
      :thumbs="{ swiper: thumbsSwiper!, autoScrollOffset: 1 }"
      :initial-slide="activeIndex"
      grab-cursor
      loop
      @swiper="(swiper: SwiperType) => (mainSwiper = swiper)"
      @slide-change="
        (swiper: SwiperType) => imageCarousel.onSlideChange(swiper.realIndex)
      "
    >
      <SwiperSlide v-for="(image, index) in images" :key="image.id">
        <AppImageCarouselImage
          :data-test="`AppImageCarouselMainSlide-${image.id}`"
          :url="image.url"
          :should-load-placeholder-image="
            imageCarousel.shouldLoadPlaceholderImage(index, image.id)
          "
          :should-load-full-scale-image="
            imageCarousel.shouldLoadFullScaleImage(index, image.id)
          "
        />
      </SwiperSlide>

      <div v-if="props.images.length > 1">
        <AppImageCarouselNavigationButton
          class="swiper-navigation-wrapper"
          direction="previous"
        />
        <AppImageCarouselNavigationButton
          class="swiper-navigation-wrapper"
          direction="next"
        />
      </div>
    </Swiper>

    <div
      v-if="images.length > 1"
      ref="thumbsContainer"
      class="mx-auto my-0 max-w-sm p-2 md:max-w-xl"
    >
      <Swiper
        :slides-per-view="5"
        :space-between="5"
        :modules="modules"
        watch-slides-progress
        center-insufficient-slides
        slide-to-clicked-slide
        grab-cursor
        free-mode
        @swiper="(swiper: SwiperType) => (thumbsSwiper = swiper)"
      >
        <SwiperSlide v-for="image in images" :key="image.id">
          <button
            class="swiper-slide-button m-1 rounded-sm focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-white"
          >
            <AppImageCarouselThumbnailImage
              :data-test="`AppImageCarouselThumbnailSlide-${image.id}`"
              :url="
                cloudinary
                  .image(image.url)
                  .setDeliveryType('fetch')
                  .delivery(format(auto()))
                  .resize(
                    fill().width(THUMB_SIZE).aspectRatio(THUMB_ASPECT_RATIO),
                  )
                  .toURL()
              "
            />
          </button>
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
</template>
<script setup lang="ts">
import { format } from '@cloudinary/url-gen/actions/delivery';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { auto } from '@cloudinary/url-gen/qualifiers/format';
import type SwiperType from 'swiper';
import { Navigation, Pagination, Keyboard, A11y, Thumbs } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { ref } from 'vue';
import { cloudinary } from '@/cloudinary';
import type { Image } from '@/image/image';
import AppImageCarouselImage from '@/ui/app-image-carousel/AppImageCarouselImage.vue';
import AppImageCarouselNavigationButton from '@/ui/app-image-carousel/AppImageCarouselNavigationButton.vue';
import AppImageCarouselThumbnailImage from '@/ui/app-image-carousel/AppImageCarouselThumbnailImage.vue';
import { useImageCarousel } from '@/ui/app-image-carousel/image-carousel.composable';

const modules = [Navigation, Pagination, Keyboard, Thumbs, A11y];

const THUMB_SIZE = 100;
const THUMB_ASPECT_RATIO = '1:1';

const mainSwiper = ref<SwiperType>();
const thumbsSwiper = ref<SwiperType>();
const thumbsContainer = ref<HTMLElement>();

const props = defineProps<{
  images: Image[];
  initialImageId?: number;
}>();

const imageCarousel = useImageCarousel(props.images);

const activeIndex = ref(
  props.images.findIndex((image) => image.id === props.initialImageId),
);
</script>

<style scoped>
.swiper-navigation-wrapper {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

:deep(.swiper-pagination-horizontal) {
  color: white;
}

.swiper-wrapper:hover .swiper-navigation-wrapper {
  opacity: 1;
}

.swiper-slide-button {
  display: flex;
  opacity: 0.3;
}

.swiper-slide-button:focus-visible {
  opacity: 0.7;
}

.swiper-thumbs
  > .swiper-wrapper
  > .swiper-slide-thumb-active
  > .swiper-slide-button {
  opacity: 1;
}
</style>
