import { Decimal } from 'decimal.js';
import type { Ref } from 'vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import type { AdvancedPaymentPolicy } from '@/property/booking-policy/advanced-payment-policy/advanced-payment-policy';
import { AdvancedPaymentPolicyType } from '@/property/booking-policy/advanced-payment-policy/advanced-payment-policy';
import type { BookingPolicy } from '@/property/booking-policy/booking-policy';
import type { DepositPolicyCharge } from '@/property/booking-policy/deposit-policy/charge/deposit-policy-charge';
import { DepositPolicyChargeType } from '@/property/booking-policy/deposit-policy/charge/deposit-policy-charge';
import { DepositPolicyType } from '@/property/booking-policy/deposit-policy/deposit-policy';
import type { MandatoryDepositPolicy } from '@/property/booking-policy/deposit-policy/mandatory/mandatory-deposit-policy';
import type { NoDepositPolicy } from '@/property/booking-policy/deposit-policy/no/no-deposit-policy';
import type { DepositRefundPolicy } from '@/property/booking-policy/deposit-policy/refund-policy/deposit-refund-policy';
import { DepositRefundPolicyType } from '@/property/booking-policy/deposit-policy/refund-policy/deposit-refund-policy';
import type { Property } from '@/property/property';

export const useDepositPolicyTermsAndConditionsDisplayText = (
  bookingPolicy: Ref<BookingPolicy>,
  property: Ref<Property>,
) => {
  const { t, n } = useI18n();

  const depositPolicy = computed(() => bookingPolicy.value.depositPolicy);

  const advancedPaymentPolicy = computed(
    () => bookingPolicy.value.advancedPaymentPolicy,
  );

  const generateNoDepositDisplayText = ({
    isCardGuaranteeRequired,
  }: NoDepositPolicy): string =>
    [
      isCardGuaranteeRequired
        ? t('aValidCreditCardIsRequiredToGuaranteeTheBooking')
        : t('noDepositRequired'),
      advancedPaymentPolicy.value
        ? generateBookingBalanceDueDisplayText(advancedPaymentPolicy.value)
        : '',
    ]
      .filter(Boolean)
      .join(' ');

  const generateMandatoryDepositDisplayText = ({
    charge,
    refundPolicy,
  }: MandatoryDepositPolicy): string => {
    const depositAmountIsFullCharge =
      charge.chargeType === DepositPolicyChargeType.Percentage &&
      charge.percentage === 100;

    return [
      generateDepositChargeDisplayText(charge, !!refundPolicy),
      refundPolicy
        ? generateDepositRefundDisplayText(
            refundPolicy,
            depositAmountIsFullCharge,
          )
        : '',
      !depositAmountIsFullCharge && advancedPaymentPolicy.value
        ? generateBookingBalanceDueDisplayText(advancedPaymentPolicy.value)
        : '',
    ]
      .filter(Boolean)
      .join(' ');
  };

  const generateDepositChargeDisplayText = (
    charge: DepositPolicyCharge,
    isRefundable: boolean,
  ): string => {
    switch (charge.chargeType) {
      case DepositPolicyChargeType.Nightly: {
        return t(
          isRefundable
            ? 'aDepositEqualToTheFirstCountNightsOfTheStayIsRequired'
            : 'aNonRefundableDepositEqualToTheFirstCountNightsOfTheStayIsRequired',
          {
            count: charge.numberOfNights,
          },
        );
      }
      case DepositPolicyChargeType.PerUnitMonetary:
        return t(
          isRefundable
            ? 'aDepositOfChargePerUnitBookedIsRequired'
            : 'aNonRefundableDepositOfChargePerUnitBookedIsRequired',
          {
            charge: generateChargeAmountDisplayText(charge.amountPerUnit),
          },
        );
      case DepositPolicyChargeType.Percentage:
        return charge.percentage === 100
          ? t(
              isRefundable
                ? 'aPaymentEqualToTheFullBookingAmountIsRequired'
                : 'aNonRefundablePaymentEqualToTheFullBookingAmountIsRequired',
            )
          : t(
              isRefundable
                ? 'aDepositOfPercentOfTheStayIsRequired'
                : 'aNonRefundableDepositOfPercentOfTheStayIsRequired',
              {
                percent: n(
                  new Decimal(charge.percentage).dividedBy(100).toNumber(),
                  'percent',
                ),
              },
            );
      case DepositPolicyChargeType.Monetary:
        return t(
          isRefundable
            ? 'aDepositEqualToChargeIsRequired'
            : 'aNonRefundableDepositEqualToChargeIsRequired',
          {
            charge: generateChargeAmountDisplayText(charge.amount),
          },
        );
    }
  };

  const generateDepositRefundDisplayText = (
    depositRefundPolicy: DepositRefundPolicy,
    depositAmountIsFullCharge: boolean,
  ): string => {
    const paymentType = t(depositAmountIsFullCharge ? 'payment' : 'deposit');

    return depositRefundPolicy.refundType ===
      DepositRefundPolicyType.BeforeArrival
      ? t(
          'thisPaymentTypeWillBeRefundedIfCancelledMoreThanCountDaysBeforeArrival',
          {
            paymentType,
            count:
              depositRefundPolicy.minimumNumberOfDaysBeforeArrivalThreshold,
          },
        )
      : t('thisPaymentTypeWillBeRefundedAccordingToTheCancellationConditions', {
          paymentType,
        });
  };

  const generateBookingBalanceDueDisplayText = (
    advancedPaymentPolicy: AdvancedPaymentPolicy,
  ): string => {
    switch (advancedPaymentPolicy.advancedPaymentType) {
      case AdvancedPaymentPolicyType.OnArrival:
        return t('fullBookingBalanceIsDueOnTheDayOfArrival');
      case AdvancedPaymentPolicyType.OnDeparture:
        return t('fullBookingBalanceCanBePaidOnDeparture');
      case AdvancedPaymentPolicyType.BeforeArrival:
        return t('fullBookingBalanceMustBePaidCountDaysBeforeArrival', {
          count: advancedPaymentPolicy.numberOfDaysBeforeArrival,
        });
    }
  };

  const generateChargeAmountDisplayText = (chargeAmount: number): string =>
    n(chargeAmount, {
      style: 'currency',
      currency: property.value.currencyCode,
    });

  return computed(() =>
    depositPolicy.value.policyType === DepositPolicyType.No
      ? generateNoDepositDisplayText(depositPolicy.value)
      : generateMandatoryDepositDisplayText(depositPolicy.value),
  );
};
