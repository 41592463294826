import { mapAllowedChildPolicyFromDTO } from '@/property/child-policy/allowed/allowed-child-policy.mapper';
import type {
  ChildPolicy,
  ChildPolicyDTO,
} from '@/property/child-policy/child-policy';

export const mapChildPolicyFromDTO = (
  childPolicyDTO: ChildPolicyDTO,
): ChildPolicy =>
  childPolicyDTO.childrenAreAllowed
    ? mapAllowedChildPolicyFromDTO(childPolicyDTO)
    : { childrenAreAllowed: false };
