import type {
  BookingUnitMeal,
  BookingUnitMealDTO,
} from '@/booking/unit/meal/booking-unit-meal';

export const mapBookingUnitMealFromDTO = (
  bookingUnitMealDTO: BookingUnitMealDTO,
): BookingUnitMeal => ({
  mealType: bookingUnitMealDTO.mealType,
});
