import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import type { AdditionalTax } from '@/tax/additional/additional-tax';

export const useAdditionalTaxNameDisplayText = (
  additionalTax: AdditionalTax,
) => {
  const { t } = useI18n();

  return computed(() =>
    additionalTax.canTranslateName
      ? t(additionalTax.nameTranslationKey)
      : t('namedTax', { taxName: additionalTax.name }),
  );
};
