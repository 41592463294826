import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { PluginOptions } from 'vue-gtag';
import { event, setOptions, bootstrap } from 'vue-gtag';
import type { Booking } from '@/booking/booking';
import type { BookingExtra } from '@/booking/extra/booking-extra';
import type { ActiveBookingUnit } from '@/booking/unit/active/active-booking-unit';
import type { GoogleAnalytics } from '@/property/google/analytics/google-analytics';
import type { GoogleAnalyticsPageViewEventAction } from '@/property/google/analytics/google-analytics-event-action';
import type { StayDates } from '@/stay-dates/stay-dates';
import { getStayLengthFromStayDates } from '@/stay-dates/stay-dates.utilities';

export const useGoogleAnalyticsStore = defineStore(
  'google-analytics-store',
  () => {
    const activeGoogleAnalyticsKey = ref<string>();
    const bootstrappedGoogleAnalyticsKeys = ref<string[]>([]);

    const emitGAPageViewEvent = async (
      action: GoogleAnalyticsPageViewEventAction,
      googleAnalytics: GoogleAnalytics | undefined,
      stayDates?: StayDates,
    ) => {
      await initializeGoogleAnalytics(googleAnalytics);

      if (activeGoogleAnalyticsKey.value) {
        event(action, {
          check_in_date: stayDates ? stayDates.checkInDate : null,
          check_out_date: stayDates ? stayDates.checkOutDate : null,
          stay_length: stayDates ? getStayLengthFromStayDates(stayDates) : null,
          send_to: activeGoogleAnalyticsKey.value,
        });
      }
    };

    const emitGASearchEvent = async (
      googleAnalytics: GoogleAnalytics | undefined,
      stayDates: StayDates,
    ) => {
      await initializeGoogleAnalytics(googleAnalytics);

      if (activeGoogleAnalyticsKey.value) {
        event('user_search', {
          check_in_date: stayDates.checkInDate,
          check_out_date: stayDates.checkOutDate,
          stay_length: getStayLengthFromStayDates(stayDates),
          send_to: activeGoogleAnalyticsKey.value,
        });
      }
    };

    const emitGABookingCompleteEvent = async (booking: Booking) => {
      if (!booking.property.googleAnalytics?.isEcommerceEnabled) {
        return;
      }

      await initializeGoogleAnalytics(booking.property.googleAnalytics);

      if (activeGoogleAnalyticsKey.value) {
        event('purchase', {
          booking_id: booking.id,
          transaction_id: booking.id,
          affiliation: 'Freetobook',
          value: booking.totalAmount,
          currency: booking.property.currencyCode,
          coupon: booking.promocode?.promocodeText ?? null,
          items: [
            ...booking.activeBookingUnits.map((unit: ActiveBookingUnit) => ({
              id: unit.unitId,
              name: unit.unitName,
              price: unit.rate,
            })),
            ...booking.bookingExtras.map((extra: BookingExtra) => ({
              name: extra.extraName,
              quantity: extra.quantity,
              price: extra.itemCharge,
            })),
          ],
          send_to: activeGoogleAnalyticsKey.value,
        });
      }
    };

    const initializeGoogleAnalytics = async (
      googleAnalytics: GoogleAnalytics | undefined,
    ) => {
      if (typeof googleAnalytics === 'undefined') {
        deactivateKey(activeGoogleAnalyticsKey.value);
      } else if (activeGoogleAnalyticsKey.value !== googleAnalytics.key) {
        await bootstrapPropertyGoogleAnalytics(googleAnalytics);
      }
    };

    const bootstrapPropertyGoogleAnalytics = async (
      googleAnalytics: GoogleAnalytics,
    ) => {
      const isGoogleAnalyticsKeyAlreadyBootstrapped =
        bootstrappedGoogleAnalyticsKeys.value.includes(googleAnalytics.key);
      if (!isGoogleAnalyticsKeyAlreadyBootstrapped) {
        const options: PluginOptions = {
          config: {
            id: googleAnalytics.key,
          },
        };
        if (googleAnalytics.sourceDomain !== '') {
          options.config!.params = {
            linker: {
              domains: [googleAnalytics.sourceDomain],
            },
          };
        }
        setOptions(options);
        await bootstrap();
        activateKey(googleAnalytics.key);
        bootstrappedGoogleAnalyticsKeys.value.push(googleAnalytics.key);
      } else {
        activateKey(googleAnalytics.key);
      }
    };

    /**
     * Replicating opt out logic from:
     * https://github.com/MatteoGabriele/vue-gtag/blob/master/src/api/disable.js#L9C13-L9C14
     *
     * Package disables google analytics for all keys.
     * We want to keep the current property key active.
     * This is needed for the case where we have multiple google analytics keys (multi property widget).
     *
     * This method enables the given google analytics key and disables the current active key.
     * Also sets the new active key as the given google analytics key.
     */
    const activateKey = (googleAnalyticsKey: string) => {
      window[`ga-disable-${googleAnalyticsKey}`] = false;
      deactivateKey(activeGoogleAnalyticsKey.value);
      activeGoogleAnalyticsKey.value = googleAnalyticsKey;
    };

    /**
     * Replicating opt out logic from:
     * https://github.com/MatteoGabriele/vue-gtag/blob/master/src/api/disable.js#L9C13-L9C14
     *
     * Package disables google analytics for all keys.
     * We want to keep the current property key active.
     * This is needed for the case where we have multiple google analytics keys (multi property widget).
     *
     * This method disables the given google analytics key. Sets the active key to undefined.
     */
    const deactivateKey = (googleAnalyticsKey: string | undefined) => {
      if (typeof googleAnalyticsKey !== 'undefined') {
        window[`ga-disable-${googleAnalyticsKey}`] = true;
        activeGoogleAnalyticsKey.value = undefined;
      }
    };

    return {
      emitGAPageViewEvent,
      emitGASearchEvent,
      emitGABookingCompleteEvent,
    };
  },
);
