<template>
  <div>
    <p>
      {{
        $t('youHaveAlreadySelectedUnitsForDifferentStayDates', {
          unitTypeName: unitTypeNamePlural,
        })
      }}
    </p>

    <UnitSelectionPricePlanMultiDateBookingSelectionWarningDateRange
      :check-in-date="bookingItineraryStore.stayDates!.checkInDate"
      :check-out-date="bookingItineraryStore.stayDates!.checkOutDate"
      :label="$t('yourCurrentStayDates')"
    />

    <UnitSelectionPricePlanMultiDateBookingSelectionWarningDateRange
      should-bold-date-range
      :check-in-date="searchStore.stayDates.checkInDate"
      :check-out-date="searchStore.stayDates.checkOutDate"
      :label="$t('yourNewStayDates')"
    />

    <p>
      {{ $t('youNeedToChangeYourSearchToBookTheSelectedPricePlan') }}
    </p>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import { useUnitType } from '@/property/unit/type/unit-type.composable';
import { useSearchStore } from '@/search/search.store';
import UnitSelectionPricePlanMultiDateBookingSelectionWarningDateRange from '@/unit-selection-page/price-plan/multi-date-booking-selection-warning/UnitSelectionPricePlanMultiDateBookingSelectionWarningDateRange.vue';

const searchStore = useSearchStore();
const bookingItineraryStore = useBookingItineraryStore();

const { unitTypeNamePlural } = useUnitType(
  computed(() => searchStore.activeProperty.unitType),
);
</script>
