<template>
  <footer
    class="fixed bottom-0 z-[15] w-full overflow-x-scroll border-t border-gray-300 bg-white"
  >
    <div class="space-y-2 px-4 py-6">
      <BookingSummaryFooterTermsAndConditionsAgreement
        class="mb-2 text-center"
      />

      <div class="flex items-center">
        <div class="grid">
          <BookingSummaryFooterTotalRate class="text-lg font-bold" />

          <div class="overflow-hidden">
            <div class="truncate text-sm capitalize">
              {{
                generateCountDisplayText(
                  bookingItineraryStore.unitItinerary.map(
                    (unitItineraryItem) => unitItineraryItem.occupancy,
                  ),
                  searchStore.activeProperty.unitType,
                )
              }}
            </div>

            <ModalBookingSummaryFooterStayDates
              v-if="
                bookingStageStore.currentStage
                  .shouldDisplayModalBookingSummaryFooterStayDates &&
                bookingItineraryStore.stayDates
              "
              :stay-dates="bookingItineraryStore.stayDates"
            />
          </div>
        </div>

        <div class="ml-2 flex flex-1 justify-end">
          <BookingSummaryFooterContinueButton />
        </div>
      </div>

      <BookingSummaryFooterRecaptchaInformation class="text-center" />
    </div>

    <div v-show="!bookingSummaryStore.isModalOpen" class="flex justify-center">
      <button
        class="absolute top-0 text-zinc-300 focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-slate-800"
        @click="bookingSummaryStore.isModalOpen = true"
      >
        <IconChevronUp />
      </button>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { IconChevronUp } from '@tabler/icons-vue';
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import { useBookingStageStore } from '@/booking-stage/booking-stage.store';
import { useBookingSummaryStore } from '@/booking-summary/booking-summary.store';
import BookingSummaryFooterContinueButton from '@/booking-summary/footer/BookingSummaryFooterContinueButton.vue';
import BookingSummaryFooterRecaptchaInformation from '@/booking-summary/footer/BookingSummaryFooterRecaptchaInformation.vue';
import BookingSummaryFooterTermsAndConditionsAgreement from '@/booking-summary/footer/BookingSummaryFooterTermsAndConditionsAgreement.vue';
import BookingSummaryFooterTotalRate from '@/booking-summary/footer/BookingSummaryFooterTotalRate.vue';
import ModalBookingSummaryFooterStayDates from '@/booking-summary/footer/ModalBookingSummaryFooterStayDates.vue';
import { useOccupancy } from '@/occupancy/occupancy.composable';
import { useSearchStore } from '@/search/search.store';

defineEmits(['click']);

const searchStore = useSearchStore();
const bookingSummaryStore = useBookingSummaryStore();
const bookingItineraryStore = useBookingItineraryStore();
const bookingStageStore = useBookingStageStore();

const { generateCountDisplayText } = useOccupancy();
</script>
