import type {
  AdditionalTaxRateMonetaryChargeLimit,
  AdditionalTaxRateMonetaryChargeLimitDTO,
} from '@/tax/additional/rate/charge-limit/monetary/additional-tax-rate-monetary-charge-limit';
import type {
  AdditionalTaxRateNightsChargeLimit,
  AdditionalTaxRateNightsChargeLimitDTO,
} from '@/tax/additional/rate/charge-limit/nights/additional-tax-rate-nights-charge-limit';

export enum AdditionalTaxRateChargeLimitType {
  Monetary = 'monetary',
  Nights = 'nights',
}

export type AdditionalTaxRateChargeLimit =
  | AdditionalTaxRateMonetaryChargeLimit
  | AdditionalTaxRateNightsChargeLimit;

export type AdditionalTaxRateChargeLimitDTO =
  | AdditionalTaxRateMonetaryChargeLimitDTO
  | AdditionalTaxRateNightsChargeLimitDTO;
