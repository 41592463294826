import type {
  ParkingPolicyCharge,
  ParkingPolicyChargeDTO,
} from '@/property/parking-policy/charge/parking-policy-charge';
import { ParkingPolicyChargeType } from '@/property/parking-policy/charge/parking-policy-charge';

export const mapParkingPolicyChargeFromDTO = (
  parkingPolicyChargeDTO: ParkingPolicyChargeDTO,
): ParkingPolicyCharge => {
  const { chargeType } = parkingPolicyChargeDTO;

  switch (chargeType) {
    case ParkingPolicyChargeType.PerDayMonetary:
      return {
        chargeType,
        amountPerDay: Number(parkingPolicyChargeDTO.amountPerDay),
      };
    case ParkingPolicyChargeType.Free:
      return { chargeType };
  }
};
