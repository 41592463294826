import { defineStore } from 'pinia';
import { computed } from 'vue';
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import { partitionExtrasByMandatoryAndOptional } from '@/property/extra/extra.utilities';

export const useExtrasStore = defineStore('extras', () => {
  const bookingItineraryStore = useBookingItineraryStore();

  const partitionedMandatoryAndOptionalExtras = computed(() =>
    partitionExtrasByMandatoryAndOptional(
      bookingItineraryStore.propertyOverStay.extras,
    ),
  );

  const mandatoryExtras = computed(
    () => partitionedMandatoryAndOptionalExtras.value[0],
  );

  const optionalExtras = computed(
    () => partitionedMandatoryAndOptionalExtras.value[1],
  );

  return {
    mandatoryExtras,
    optionalExtras,
  };
});
