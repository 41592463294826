import { Decimal } from 'decimal.js';
import { isDateWithinDaysOfWeek } from '@/day-of-week/day-of-week.utilities';
import type { PercentagePromocode } from '@/property/promocode/percentage-promocode/percentage-promocode';
import type { NightlyRates } from '@/rates/nightly-rates/nightly-rates';
import type { StayDates } from '@/stay-dates/stay-dates';
import { getNightsOfStayFromStayDates } from '@/stay-dates/stay-dates.utilities';

export const isAnyStayDateOnApplicableDaysOfWeekOfPercentagePromocode = (
  stayDates: StayDates,
  percentagePromocode: PercentagePromocode,
): boolean =>
  getNightsOfStayFromStayDates(stayDates).some((date) =>
    canApplyPercentagePromocodeOnDate(date, percentagePromocode),
  );

export const isPercentagePromocodeApplicableOnAllDaysOfWeek = (
  percentagePromocode: PercentagePromocode,
): boolean => percentagePromocode.applicableDaysOfWeek.length === 7;

export const applyPercentagePromocodeToNightlyRates = (
  percentagePromocode: PercentagePromocode,
  nightlyRates: NightlyRates,
): NightlyRates =>
  nightlyRates.map((nightlyRate) =>
    canApplyPercentagePromocodeOnDate(nightlyRate.date, percentagePromocode)
      ? {
          ...nightlyRate,
          rate: applyPercentagePromocodeToValue(
            nightlyRate.rate,
            percentagePromocode,
          ),
        }
      : nightlyRate,
  );

const canApplyPercentagePromocodeOnDate = (
  date: string,
  { applicableDaysOfWeek }: PercentagePromocode,
): boolean => isDateWithinDaysOfWeek(date, applicableDaysOfWeek);

const applyPercentagePromocodeToValue = (
  value: number,
  percentagePromocode: PercentagePromocode,
): number => {
  const decimalValue = new Decimal(value);

  return applyPercentagePromocodeToDecimalValue(
    decimalValue,
    percentagePromocode,
  ).toNumber();
};

const applyPercentagePromocodeToDecimalValue = (
  decimalValue: Decimal,
  { percentage }: PercentagePromocode,
): Decimal =>
  decimalValue
    .times(Decimal.sub(100, percentage).dividedBy(100))
    .toDecimalPlaces(2);
