<template>
  <div class="flex justify-between">
    <div
      class="break-anywhere font-semibold"
      :class="{ 'line-through': booking.isCancelled }"
    >
      {{ bookingAdHocSupplement.name }}
    </div>

    <div class="text-right">
      <div>
        {{
          $n(totalCharge, {
            style: 'currency',
            currency: property.currencyCode,
          })
        }}
      </div>

      <div v-if="booking.isCancelled" class="text-xs">
        {{ $t('cancellationCharge') }}
      </div>
    </div>
  </div>

  <div v-if="priceDescriptionTag">
    {{ priceDescriptionTag }}
  </div>

  <AppCollapsibleText
    v-if="bookingAdHocSupplement.description"
    class="break-anywhere"
    :text="bookingAdHocSupplement.description"
    :max-lines="3"
  />
</template>
<script setup lang="ts">
import { Decimal } from 'decimal.js';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import type { BookingAdHocSupplement } from '@/booking/ad-hoc-supplement/booking-ad-hoc-supplement';
import { useBookingConfirmationStore } from '@/booking-confirmation-page/booking-confirmation.store';
import AppCollapsibleText from '@/ui/app-collapsible-text/AppCollapsibleText.vue';

const props = defineProps<{
  bookingAdHocSupplement: BookingAdHocSupplement;
}>();

const { property, booking } = storeToRefs(useBookingConfirmationStore());

const { n, t } = useI18n();

const priceDescriptionTag = computed(() =>
  props.bookingAdHocSupplement.quantity > 1
    ? t('quantityTimesChargeEach', {
        quantity: props.bookingAdHocSupplement.quantity,
        charge: n(props.bookingAdHocSupplement.itemCharge, {
          style: 'currency',
          currency: property.value.currencyCode,
        }),
      })
    : undefined,
);

const totalCharge = computed(() =>
  new Decimal(props.bookingAdHocSupplement.itemCharge)
    .mul(props.bookingAdHocSupplement.quantity)
    .toNumber(),
);
</script>
