import type {
  PropertyWidgetSettings,
  PropertyWidgetSettingsDTO,
} from '@/property/property-widget-settings/property-widget-settings';

export const mapPropertyWidgetSettingsFromDTO = (
  propertyWidgetSettingsDTO: PropertyWidgetSettingsDTO,
): PropertyWidgetSettings => ({
  showScarcityFlag: propertyWidgetSettingsDTO.showScarcityFlag,
  isOnline: propertyWidgetSettingsDTO.isOnline,
  allowsSpecialRequests: propertyWidgetSettingsDTO.allowsSpecialRequests,
});
