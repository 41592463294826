<template>
  <div v-if="shouldUseModal">
    <AppModal :is-open="isModalOpen" @close="isModalOpen = false">
      <AppModalDialog @close="isModalOpen = false">
        <template #header>
          <OccupancySelectorHeader />
        </template>

        <OccupancySelectorBody />

        <template #footer>
          <OccupancySelectorFooter
            :search-button-text="capitalize($t('select'))"
            @submit="updateOccupancies"
          />
        </template>
      </AppModalDialog>
    </AppModal>

    <VanitySearchOptionButton :icon="IconUser" @click="isModalOpen = true">
      <VanitySearchOccupancySelectorButtonContent />
    </VanitySearchOptionButton>
  </div>

  <UseElementBounding v-else v-slot="{ right: popoverButtonRight }">
    <AppPopover v-slot="{ close }">
      <AppPopoverButton :as="VanitySearchOptionButton" :icon="IconUser">
        <VanitySearchOccupancySelectorButtonContent />
      </AppPopoverButton>

      <UseElementBounding
        v-slot="{ top: popoverPanelTop }"
        class="absolute left-0 right-0 z-10 flex justify-center lg:left-auto lg:right-auto lg:flex-none lg:translate-x-[-100%]"
        :style="
          !showPopoverCentered
            ? {
                left: `${popoverButtonRight}px`,
              }
            : {}
        "
      >
        <div
          class="flex"
          :style="{
            maxHeight: `calc(var(--app-height) - ${popoverPanelTop}px)`,
          }"
        >
          <AppPopoverPanel>
            <template #header>
              <OccupancySelectorHeader />
            </template>

            <OccupancySelectorBody class="w-96" />

            <template #footer>
              <OccupancySelectorFooter
                :search-button-text="capitalize($t('select'))"
                @submit="close(), updateOccupancies()"
              />
            </template>
          </AppPopoverPanel>
        </div>
      </UseElementBounding>
    </AppPopover>
  </UseElementBounding>
</template>

<script setup lang="ts">
import { IconUser } from '@tabler/icons-vue';
import { UseElementBounding } from '@vueuse/components';
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { mapOccupanciesToOccupancySelectorUnitOccupancies } from '@/occupancy/occupancy.utilities';
import { useProvideOccupancySelectorStore } from '@/occupancy-selector/occupancy-selector.store';
import OccupancySelectorBody from '@/occupancy-selector/OccupancySelectorBody.vue';
import OccupancySelectorFooter from '@/occupancy-selector/OccupancySelectorFooter.vue';
import OccupancySelectorHeader from '@/occupancy-selector/OccupancySelectorHeader.vue';
import { useSearchStore } from '@/search/search.store';
import { useLocaleString } from '@/string/locale-string.composable';
import AppModal from '@/ui/app-modal/AppModal.vue';
import AppModalDialog from '@/ui/app-modal/AppModalDialog.vue';
import AppPopover from '@/ui/app-popover/AppPopover.vue';
import AppPopoverButton from '@/ui/app-popover/AppPopoverButton.vue';
import AppPopoverPanel from '@/ui/app-popover/AppPopoverPanel.vue';
import VanitySearchOptionButton from '@/vanity-page/search/button/VanitySearchOptionButton.vue';
import VanitySearchOccupancySelectorButtonContent from '@/vanity-page/search/occupancy-selector/VanitySearchOccupancySelectorButtonContent.vue';

const searchStore = useSearchStore();

const { capitalize } = useLocaleString();

const { activeProperty } = storeToRefs(searchStore);

const breakpoints = useBreakpoints(breakpointsTailwind);

const isModalOpen = ref(false);

const shouldUseModal = breakpoints.smaller('sm');
const showPopoverCentered = breakpoints.smaller('lg');

const { occupancies } = useProvideOccupancySelectorStore({
  property: activeProperty,
  unitOccupancies: mapOccupanciesToOccupancySelectorUnitOccupancies(
    searchStore.occupancies,
  ),
});

const updateOccupancies = async () => {
  isModalOpen.value = false;

  await searchStore.updateOccupancies(occupancies.value);
};
</script>
