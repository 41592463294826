<template>
  <div class="break-anywhere text-sm">
    <div class="mb-2 mt-6 text-lg font-semibold capitalize">
      {{ $t('guestDetails') }}
    </div>

    <div>{{ guestStore.name }}</div>

    <div>{{ guestStore.guest.emailAddress }}</div>

    <div>{{ guestStore.guest.phoneNumber }}</div>

    <FormattedAddress :address="guestStore.guest.address" />

    <div v-if="arrivalTime" class="mt-2">
      <span class="font-semibold capitalize">{{ $t('arrivalTime') }}:</span>

      {{ arrivalTime }}
    </div>

    <div v-if="bookingItineraryStore.specialRequests" class="mt-2">
      <span class="font-semibold capitalize">{{ $t('specialRequests') }}:</span>

      {{ bookingItineraryStore.specialRequests }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import FormattedAddress from '@/address/formatted/FormattedAddress.vue';
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import { useGuestStore } from '@/guest/guest.store';
import { useTimeDisplayText } from '@/time/time-display-text.composable';

const bookingItineraryStore = useBookingItineraryStore();
const guestStore = useGuestStore();

const arrivalTime = computed(() =>
  bookingItineraryStore.checkInTime
    ? useTimeDisplayText(bookingItineraryStore.checkInTime).value
    : undefined,
);
</script>
