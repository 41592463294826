<template>
  <CodeInputFeedbackItemLayout is-success>
    <div class="space-y-2">
      <div class="font-semibold">
        {{
          promocode.promocodeType === PromocodeType.Descriptive
            ? $t('promotionalOffer')
            : $t('promotionalOfferSummary', {
                summary: discountSummaryText,
              })
        }}
      </div>

      <div v-if="promocode.description" translate="yes">
        {{ promocode.description }}
      </div>
    </div>
  </CodeInputFeedbackItemLayout>
</template>

<script setup lang="ts">
import { toRef } from 'vue';
import { useCodeInputStore } from '@/code/input/code-input.store';
import CodeInputFeedbackItemLayout from '@/code/input/feedback/item/CodeInputFeedbackItemLayout.vue';
import type { Promocode } from '@/property/promocode/promocode';
import { PromocodeType } from '@/property/promocode/promocode';
import { usePromocodeDiscountSummary } from '@/property/promocode/promocode.composable';

const props = defineProps<{ promocode: Promocode }>();

const { property } = useCodeInputStore();

const { discountSummaryText } = usePromocodeDiscountSummary(
  toRef(props.promocode),
  property,
);
</script>
