<template>
  <div class="flex items-center">
    <div class="font-semibold">
      {{
        $n(extra.charge, {
          style: 'currency',
          currency: currencyCode,
        })
      }}
    </div>

    <div v-if="suffixTranslationKey" class="ml-1">
      {{ $t(suffixTranslationKey) }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { Extra } from '@/property/extra/extra';
import { getChargeTypeSuffixTranslationKeyForExtra } from '@/property/extra/extra.utilities';
import { useSearchStore } from '@/search/search.store';

const props = defineProps<{
  extra: Extra;
}>();

const searchStore = useSearchStore();

const suffixTranslationKey = computed(() =>
  getChargeTypeSuffixTranslationKeyForExtra(props.extra),
);

const currencyCode = computed(() => searchStore.activeProperty.currencyCode);
</script>
