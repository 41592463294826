<template>
  <div ref="tooltipAnchor" class="flex">
    <div v-if="isTooltipRequired" ref="tooltip" class="absolute z-10">
      <AppTooltip :is-open="isTooltipOpen" @close="isTooltipOpen = false">
        <div class="whitespace-nowrap capitalize">
          {{ availabilityOnDayDisplayText }}
        </div>
      </AppTooltip>
    </div>

    <AvailabilityCalendarDay
      :date="date"
      :is-unavailable="isNotAvailable && !withinSelectedRange"
      :is-conditionally-unavailable="
        (isClosedToArrival ||
          isClosedToDeparture ||
          isUnavailableBecauseOfMinimumStay) &&
        !withinSelectedRange
      "
      :aria-label="calendarDayLabelText"
      @click="onClick"
    ></AvailabilityCalendarDay>
  </div>
</template>

<script setup lang="ts">
import { isAfter, isBefore, isWithinInterval } from 'date-fns';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useAvailabilityCalendarStore } from '@/availability-calendar/availability-calendar.store';
import type { AvailabilityStatus } from '@/availability-calendar/availability-status/availability-status';
import { AvailabilityStatusType } from '@/availability-calendar/availability-status/availability-status';
import { getMinimumStayFromAvailabilityStatus } from '@/availability-calendar/availability-status/availability-status.utilities';
import AvailabilityCalendarDay from '@/availability-calendar/AvailabilityCalendarDay.vue';
import { useAppTooltipFloating } from '@/ui/app-tooltip/app-tooltip-floating.composable';
import AppTooltip from '@/ui/app-tooltip/AppTooltip.vue';

const { t, d } = useI18n();
const isTooltipOpen = ref<boolean>(false);

const props = defineProps<{
  date: number;
}>();

const {
  selectedCheckInDate,
  selectedCheckOutDate,
  selectedDate,
  isCheckInAndCheckOutDateSelected,
  isOnlyCheckInDateSelected,
  isNoDateSelected,
  availabilityOnSelectedDate,
  getAvailabilityStatusOnDate,
} = useAvailabilityCalendarStore();

const availabilityStatusOnDate = ref<AvailabilityStatus>(
  getAvailabilityStatusOnDate(props.date),
);

const tooltipAnchor = ref<HTMLDivElement | null>(null);
const tooltip = ref<HTMLDivElement | null>(null);

watch(
  () => [selectedCheckInDate.value, selectedCheckOutDate.value],
  () => {
    availabilityStatusOnDate.value = getAvailabilityStatusOnDate(props.date);
  },
);

const withinSelectedRange = computed(
  () =>
    selectedCheckInDate.value &&
    selectedCheckOutDate.value &&
    isWithinInterval(props.date, {
      start: selectedCheckInDate.value,
      end: selectedCheckOutDate.value,
    }),
);

const isTooltipRequired = computed(
  () =>
    !withinSelectedRange.value &&
    selectedDate.value === props.date &&
    (isNotAvailable.value ||
      isClosedToArrival.value ||
      isClosedToDeparture.value ||
      isAvailableWithMinimumStay.value ||
      isUnavailableBecauseOfMinimumStay.value),
);

const isNotAvailable = computed(
  () =>
    !availabilityStatusOnDate.value.isAvailable &&
    [
      AvailabilityStatusType.SoldOut,
      AvailabilityStatusType.NotAvailable,
    ].includes(availabilityStatusOnDate.value.reason.type),
);

const isClosedToArrival = computed(
  () =>
    !availabilityStatusOnDate.value.isAvailable &&
    availabilityStatusOnDate.value.reason.type ===
      AvailabilityStatusType.ClosedToArrival,
);

const isClosedToDeparture = computed(
  () =>
    !availabilityStatusOnDate.value.isAvailable &&
    availabilityStatusOnDate.value.reason.type ===
      AvailabilityStatusType.ClosedToDeparture,
);

const isAvailableWithMinimumStay = computed(
  () =>
    availabilityStatusOnDate.value.isAvailable &&
    availabilityStatusOnDate.value.minimumStay > 1,
);

const isUnavailableBecauseOfMinimumStay = computed(
  () =>
    !availabilityStatusOnDate.value.isAvailable &&
    availabilityStatusOnDate.value.reason.type ===
      AvailabilityStatusType.MinimumStay,
);

const minimumStayLength = computed(() =>
  getMinimumStayFromAvailabilityStatus(availabilityStatusOnDate.value),
);

const hasMinimumStayRequirement = computed(
  () => minimumStayLength.value && minimumStayLength.value > 1,
);

const availabilityOnDayDisplayText = computed(() => {
  return t(
    availabilityOnDayTranslationKey.value,
    hasMinimumStayRequirement.value
      ? { minimumStay: minimumStayLength.value }
      : {},
  );
});

const calendarDayLabelText = computed(() => {
  if (isAvailableWithMinimumStay.value) {
    return t('checkInHasMinimumStay', {
      date: d(props.date, {
        day: 'numeric',
        month: 'short',
      }),
      minimumStay: minimumStayLength.value,
    });
  }
  return t('calendarDateLabel', {
    date: d(props.date, {
      day: 'numeric',
      month: 'short',
    }),
    availabilityType: t(selectionStatusTranslationKey.value),
  });
});

const selectionStatusTranslationKey = computed(() => {
  if (isSelectedForCheckIn.value) {
    return 'selectedAsCheckIn';
  } else if (isSelectedForCheckOut.value) {
    return 'selectedAsCheckOut';
  } else if (isBetweenStay.value) {
    return 'withinTheSelectedStayDates';
  } else {
    return availabilityOnDayTranslationKey.value;
  }
});

const availabilityOnDayTranslationKey = computed(() => {
  if (isClosedToArrival.value) {
    return 'noCheckins';
  } else if (isClosedToDeparture.value) {
    return 'noCheckouts';
  } else if (hasMinimumStayRequirement.value) {
    return 'hasMinimumStay';
  } else if (isNotAvailable.value) {
    return 'notAvailable';
  } else {
    return 'available';
  }
});

const onClick = () => {
  setSelectedDate();
  setAvailabilityForDate();

  isTooltipOpen.value = true;
  if (
    isNotAvailable.value ||
    isClosedToArrival.value ||
    isClosedToDeparture.value ||
    isUnavailableBecauseOfMinimumStay.value
  ) {
    if (isCheckInAndCheckOutDateSelected.value) {
      clearSelectedCheckInDate();
      clearSelectedCheckOutDate();
    }
    return;
  }

  if (isNoDateSelected.value || isCheckInAndCheckOutDateSelected.value) {
    selectAsCheckInDate();
    clearSelectedCheckOutDate();
    return;
  }

  if (isOnlyCheckInDateSelected.value) {
    if (isSelectedDateIsBeforeCheckIn.value) {
      selectAsCheckInDate();
      return;
    }
    if (isSelectedDateIsAfterCheckIn.value) {
      selectAsCheckOutDate();
      return;
    }
  }
};

const isSelectedDateIsBeforeCheckIn = computed(() =>
  isBefore(props.date, selectedCheckInDate.value!),
);
const isSelectedDateIsAfterCheckIn = computed(() =>
  isAfter(props.date, selectedCheckInDate.value!),
);

const isSelectedForCheckIn = computed(
  () => selectedCheckInDate.value === props.date,
);

const isSelectedForCheckOut = computed(
  () => selectedCheckOutDate.value === props.date,
);

const isBetweenStay = computed(
  () =>
    isCheckInAndCheckOutDateSelected.value &&
    isWithinInterval(props.date, {
      start: selectedCheckInDate.value!,
      end: selectedCheckOutDate.value!,
    }),
);

const setSelectedDate = () => {
  selectedDate.value = props.date;
};

const setAvailabilityForDate = () => {
  availabilityOnSelectedDate.value = availabilityStatusOnDate.value;
};

const selectAsCheckInDate = () => {
  selectedCheckInDate.value = props.date;
  setAvailabilityForDate();
};

const selectAsCheckOutDate = () => {
  selectedCheckOutDate.value = props.date;
};

const clearSelectedCheckInDate = () => {
  selectedCheckInDate.value = undefined;
};

const clearSelectedCheckOutDate = () => {
  selectedCheckOutDate.value = undefined;
};

useAppTooltipFloating(tooltipAnchor, tooltip, 'top', ['top-start', 'top-end']);
</script>
