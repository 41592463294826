import type {
  ExtraDateRange,
  ExtraDateRangeDTO,
} from '@/property/extra/date-range/extra-date-range';
import type { ExtraTax, ExtraTaxDTO } from '@/property/extra/tax/extra-tax';

export enum ExtraChargeType {
  Single = 'single',
  AllNightsOfStay = 'all_nights_of_stay',
  SelectNightsOfStay = 'select_nights_of_stay',
}

export interface SelectableNightExtra extends NonMandatoryExtra {
  readonly chargeType: ExtraChargeType.SelectNightsOfStay;
}

export interface NonSelectableNightExtra extends BaseExtra {
  readonly chargeType: Exclude<
    ExtraChargeType,
    ExtraChargeType.SelectNightsOfStay
  >;
}

interface BaseExtra {
  id: number;
  name: string;
  description: string;
  charge: number;
  question: string;
  isMandatory: boolean;
  dateRange: ExtraDateRange | undefined;
  tax: ExtraTax | undefined;
}

export interface MandatoryExtra extends BaseExtra {
  isMandatory: true;
  chargeType: ExtraChargeType.AllNightsOfStay | ExtraChargeType.Single;
}

export interface NonMandatoryExtra extends BaseExtra {
  isMandatory: false;
  chargeType: ExtraChargeType;
}

export type Extra = MandatoryExtra | NonMandatoryExtra;

type BaseExtraDTO = Omit<BaseExtra, 'dateRange' | 'charge' | 'tax'> & {
  charge: string;
  dateRange: ExtraDateRangeDTO | null;
  tax: ExtraTaxDTO | null;
};

export interface MandatoryExtraDTO extends BaseExtraDTO {
  isMandatory: true;
  chargeType: ExtraChargeType.AllNightsOfStay | ExtraChargeType.Single;
}

export interface NonMandatoryExtraDTO extends BaseExtraDTO {
  isMandatory: false;
  chargeType: ExtraChargeType;
}

export type ExtraDTO = MandatoryExtraDTO | NonMandatoryExtraDTO;
