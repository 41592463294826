<template>
  <AppFormValidatedInput
    v-slot="{ isValid }"
    :value="unitItineraryItem.leadGuestName"
    :validation-rules="[
      isRequiredRule($t('leadGuestNameIsRequired')),
      isLessThanOrEqualToLengthRule(
        LEAD_GUEST_NAME_MAX_LENGTH,
        $t('inputNameMustNotExceedMaxLengthCharacters', {
          inputName: capitalize($t('leadGuestName')),
          maxLength: LEAD_GUEST_NAME_MAX_LENGTH,
        }),
      ),
    ]"
  >
    <AppFloatingLabelInput
      v-model="unitItineraryItem.leadGuestName"
      :label="capitalize($t('leadGuestName'))"
      :label-id="`lead-guest-name-${unitItineraryItem.id}`"
      :max-length="LEAD_GUEST_NAME_MAX_LENGTH"
      type="text"
      :is-valid="isValid"
      autocomplete-type="name"
    />
  </AppFormValidatedInput>
</template>

<script setup lang="ts">
import { LEAD_GUEST_NAME_MAX_LENGTH } from '@/booking/booking';
import type { UnitItineraryItem } from '@/booking-itinerary/booking-itinerary';
import { useLocaleString } from '@/string/locale-string.composable';
import AppFloatingLabelInput from '@/ui/app-floating-label-input/AppFloatingLabelInput.vue';
import {
  isRequiredRule,
  isLessThanOrEqualToLengthRule,
} from '@/ui/app-form/app-form-input-validation-rules.utilities';
import AppFormValidatedInput from '@/ui/app-form/AppFormValidatedInput.vue';

const unitItineraryItem = defineModel<UnitItineraryItem>({ required: true });

const { capitalize } = useLocaleString();
</script>
