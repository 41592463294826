<template>
  <AppFormValidatedInput
    v-slot="{ isValid }"
    :value="guestStore.guest.emailAddress"
    :validation-rules="[
      isValidEmailRule($t('pleaseEnterAValidEmail')),
      isLessThanOrEqualToLengthRule(
        EMAIL_MAX_LENGTH,
        $t('inputNameMustNotExceedMaxLengthCharacters', {
          inputName: capitalize($t('email')),
          maxLength: EMAIL_MAX_LENGTH,
        }),
      ),
    ]"
  >
    <AppFloatingLabelInput
      v-model="guestStore.guest.emailAddress"
      type="text"
      :label="capitalize($t('email'))"
      label-id="email"
      autocomplete-type="email"
      :is-valid="isValid"
      :max-length="EMAIL_MAX_LENGTH"
    />
  </AppFormValidatedInput>
</template>

<script setup lang="ts">
import { EMAIL_MAX_LENGTH } from '@/booking/booking';
import { useGuestStore } from '@/guest/guest.store';
import { useLocaleString } from '@/string/locale-string.composable';
import AppFloatingLabelInput from '@/ui/app-floating-label-input/AppFloatingLabelInput.vue';
import {
  isValidEmailRule,
  isLessThanOrEqualToLengthRule,
} from '@/ui/app-form/app-form-input-validation-rules.utilities';
import AppFormValidatedInput from '@/ui/app-form/AppFormValidatedInput.vue';

const guestStore = useGuestStore();

const { capitalize } = useLocaleString();
</script>
