import { createInjectionState } from '@vueuse/core';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import type { UnitItineraryItem } from '@/booking-itinerary/booking-itinerary';
import { useUnitItineraryItemStore } from '@/booking-itinerary/unit-itinerary-item/store/unit-itinerary-item.store';
import { getOrderedMealTypes } from '@/property/meal/meal.utilities';
import type { SupplementalMeal } from '@/property/meal/supplemental-meal/supplemental-meal';
import { useSearchStore } from '@/search/search.store';

const orderedMealTypes = getOrderedMealTypes();

const [useProvideBookingSummaryMealsStore, useMaybeBookingSummaryMealsStore] =
  createInjectionState((unitItineraryItem: UnitItineraryItem) => {
    const { n } = useI18n();

    const searchStore = useSearchStore();

    const unitItineraryItemStore = useUnitItineraryItemStore(
      unitItineraryItem.id,
    );

    const isUpdatingSelectedMeals = ref(false);

    const updatedSelectedMealTypes = ref(unitItineraryItem.selectedMealTypes);

    const supplementalMeals = computed(
      () => unitItineraryItemStore.supplementalMeals,
    );

    const canUpdateSelectedMeals = computed(
      () => supplementalMeals.value.length > 0,
    );

    const hasSelectedAnyMeals = computed(
      () => unitItineraryItemStore.selectedMeals.length > 0,
    );

    const meals = computed(() =>
      [
        ...unitItineraryItemStore.includedMeals,
        ...(isUpdatingSelectedMeals.value
          ? unitItineraryItemStore.supplementalMeals
          : unitItineraryItemStore.selectedMeals),
      ].sort(
        (mealA, mealB) =>
          orderedMealTypes.indexOf(mealA.type) -
          orderedMealTypes.indexOf(mealB.type),
      ),
    );

    const updateSelectedMeals = () => {
      unitItineraryItem.selectedMealTypes = updatedSelectedMealTypes.value;

      isUpdatingSelectedMeals.value = false;
    };

    const getTotalRateForMeal = (meal: SupplementalMeal): number =>
      unitItineraryItemStore.getTotalRateForMealType(meal.type);

    const getNumberFormattedTotalRateForMeal = (
      meal: SupplementalMeal,
    ): string =>
      n(getTotalRateForMeal(meal), {
        style: 'currency',
        currency: searchStore.activeProperty.currencyCode,
      });

    return {
      unitItineraryItem,
      isUpdatingSelectedMeals,
      updatedSelectedMealTypes,
      supplementalMeals,
      canUpdateSelectedMeals,
      hasSelectedAnyMeals,
      meals,
      updateSelectedMeals,
      getNumberFormattedTotalRateForMeal,
    };
  });

const useBookingSummaryMealsStore = () => {
  const store = useMaybeBookingSummaryMealsStore();

  if (!store) {
    throw new Error(
      'Please call `useProvideBookingSummaryMealsStore` on the appropriate parent component',
    );
  }

  return store;
};

export { useBookingSummaryMealsStore, useProvideBookingSummaryMealsStore };
