import type { NumberFormat } from '@intlify/core-base';
import { Locale } from '@/i18n/locales';
import deDENumberFormat from '@/i18n/locales/number-formats/de-DE-number-format';
import enGBNumberFormat from '@/i18n/locales/number-formats/en-GB-number-format';
import esESNumberFormat from '@/i18n/locales/number-formats/es-ES-number-format';
import frFRNumberFormat from '@/i18n/locales/number-formats/fr-FR-number-format';
import itITNumberFormat from '@/i18n/locales/number-formats/it-IT-number-format';
import ptPTNumberFormat from '@/i18n/locales/number-formats/pt-PT-number-format';

const numberFormats: Record<Locale, NumberFormat> = {
  [Locale.enGB]: enGBNumberFormat,
  [Locale.deDE]: deDENumberFormat,
  [Locale.esES]: esESNumberFormat,
  [Locale.frFR]: frFRNumberFormat,
  [Locale.itIT]: itITNumberFormat,
  [Locale.ptPT]: ptPTNumberFormat,
};

export default numberFormats;
