<template>
  <span class="whitespace-nowrap capitalize">
    {{
      generateCountDisplayText(
        searchStore.occupancies,
        searchStore.activeProperty.unitType,
      )
    }}
  </span>

  <div class="ml-2 flex w-full justify-end">
    <IconChevronDown :size="22" />
  </div>
</template>

<script setup lang="ts">
import { IconChevronDown } from '@tabler/icons-vue';
import { useOccupancy } from '@/occupancy/occupancy.composable';
import { useSearchStore } from '@/search/search.store';

const searchStore = useSearchStore();

const { generateCountDisplayText } = useOccupancy();
</script>
