import type { DayOfWeek } from '@/day-of-week/day-of-week';
import type {
  PrivateRateOverrideUnit,
  PrivateRateOverrideUnitDTO,
} from '@/private-rate-override/unit/private-rate-override-unit';
import type {
  PrivateRateOverrideWayToSell,
  PrivateRateOverrideWayToSellDTO,
} from '@/private-rate-override/way-to-sell/private-rate-override-way-to-sell';
import type { MealType } from '@/property/meal/meal';

export interface PrivateRateOverride {
  id: string;
  dateBounds: PrivateRateOverrideDateBounds | undefined;
  stayLengthBounds: PrivateRateOverrideStayLengthBounds;
  activationType: PrivateRateOverrideActivationType;
  daysOfWeek: DayOfWeek[];
  units: PrivateRateOverrideUnit[];
  waysToSell: PrivateRateOverrideWayToSell[];
  includedMealTypes: MealType[];
}

interface PrivateRateOverrideDateBounds {
  from: string;
  to: string;
}

interface PrivateRateOverrideStayLengthBounds {
  minimum: number;
  maximum: number;
}

export enum PrivateRateOverrideActivationType {
  NightsOfStay = 'nights_of_stay',
  Arrivals = 'arrivals',
}

export interface PrivateRateOverrideDTO
  extends Omit<PrivateRateOverride, 'dateBounds' | 'units' | 'waysToSell'> {
  dateBounds: PrivateRateOverrideDateBounds | null;
  units: PrivateRateOverrideUnitDTO[];
  waysToSell: PrivateRateOverrideWayToSellDTO[];
}
