import { get, update } from 'idb-keyval';
import type { PersistableAbandonedCartRecoveryRequest } from '@/abandoned-cart/recovery-request/abandoned-cart-recovery-request';
import { IDB_KEY_ABANDONED_CART_RECOVERY_REQUESTS } from '@/idb';

export const readAbandonedCartRecoveryRequestIdFromIdb = async (
  propertyId: number,
  widgetId: string,
): Promise<string | undefined> => {
  const recoveryRequests = await get<
    PersistableAbandonedCartRecoveryRequest[] | undefined
  >(IDB_KEY_ABANDONED_CART_RECOVERY_REQUESTS);

  if (recoveryRequests === undefined) {
    return undefined;
  }

  return recoveryRequests.find(
    (recoveryRequests) =>
      recoveryRequests.propertyId === propertyId &&
      recoveryRequests.widgetId === widgetId,
  )?.id;
};

export const createAbandonedCartRecoveryRequestInIdb = async (
  abandonedCartRecoveryRequestId: string,
  propertyId: number,
  widgetId: string,
) => {
  const persistableAbandonedCartRecoveryRequest =
    createPersistableAbandonedCartRecoveryRequest(
      abandonedCartRecoveryRequestId,
      propertyId,
      widgetId,
    );

  await update(
    IDB_KEY_ABANDONED_CART_RECOVERY_REQUESTS,
    (
      recoveryRequests: PersistableAbandonedCartRecoveryRequest[] | undefined,
    ) => {
      if (recoveryRequests === undefined) {
        return [persistableAbandonedCartRecoveryRequest];
      }

      const nonDuplicateRecoveryRequests = recoveryRequests.filter(
        (recoveryRequest) =>
          !(
            recoveryRequest.propertyId ===
              persistableAbandonedCartRecoveryRequest.propertyId &&
            recoveryRequest.widgetId ===
              persistableAbandonedCartRecoveryRequest.widgetId
          ),
      );

      return [
        ...nonDuplicateRecoveryRequests,
        persistableAbandonedCartRecoveryRequest,
      ];
    },
  );
};

export const deleteAbandonedCartRecoveryRequestFromIdb = async (
  propertyId: number,
  widgetId: string,
) => {
  await update(
    IDB_KEY_ABANDONED_CART_RECOVERY_REQUESTS,
    (
      recoveryRequests: PersistableAbandonedCartRecoveryRequest[] | undefined,
    ) =>
      recoveryRequests
        ? recoveryRequests.filter(
            (recoveryRequest) =>
              !(
                recoveryRequest.propertyId === propertyId &&
                recoveryRequest.widgetId === widgetId
              ),
          )
        : [],
  );
};

const createPersistableAbandonedCartRecoveryRequest = (
  id: string,
  propertyId: number,
  widgetId: string,
): PersistableAbandonedCartRecoveryRequest => ({
  id,
  propertyId,
  widgetId,
  createdAtTimestamp: Date.now(),
});
