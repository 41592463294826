<template>
  <iframe
    :name="IFRAME_NAME"
    :title="$t('3DSecureRequired')"
    class="h-[80vh] w-full"
  ></iframe>

  <!--
    This is a hidden 'auto-submitting' form that, upon mount, will post all the required data
    to the generated ACS URL, and subsequently render the 3DS screen within the associated
    iframe.

    See: https://developer-eu.elavon.com/docs/opayo/3d-secure-authentication
  -->
  <form
    ref="trigger3DSForm"
    :action="acsUrl"
    :target="IFRAME_NAME"
    method="POST"
  >
    <input type="hidden" name="creq" :value="cReq" />

    <input type="hidden" name="threeDSSessionData" :value="sessionData" />
  </form>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';

const IFRAME_NAME = 'sage-pay-3ds';

const props = defineProps<{
  acsUrl: string;
  transactionId: string;
  vendorTxCode: string;
  cReq: string;
}>();

const trigger3DSForm = ref<HTMLFormElement>();

/**
 * The 3DS session data must be a BASE64 URL encoded string.
 *
 * @see {@link https://developer-eu.elavon.com/docs/opayo/3d-secure-authentication}
 */
const sessionData = computed(() =>
  window.btoa(
    JSON.stringify({
      transactionId: props.transactionId,
      vendorTxCode: props.vendorTxCode,
    }),
  ),
);

onMounted(() => {
  trigger3DSForm.value?.submit();
});
</script>
