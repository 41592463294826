<template>
  <TransitionRoot as="template" :show="isOpen">
    <Dialog
      :initial-focus="initialFocus ?? null"
      class="relative z-30"
      @close="$emit('close')"
    >
      <TransitionChild
        as="template"
        class="opacity-0"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/30" aria-hidden="true"></div>
      </TransitionChild>

      <div
        class="fixed inset-0 flex items-end justify-center sm:p-4"
        :class="smPlacementClassName"
      >
        <TransitionChild
          as="template"
          class="opacity-0"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-full sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-full sm:translate-y-0 sm:scale-95"
        >
          <slot></slot>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { Dialog, TransitionRoot, TransitionChild } from '@headlessui/vue';
import { computed } from 'vue';

type Placement = 'top' | 'middle';

const props = withDefaults(
  defineProps<{
    isOpen: boolean;
    smPlacement?: Placement | undefined;
    initialFocus?: HTMLElement | null | undefined;
  }>(),
  {
    smPlacement: 'middle',
    initialFocus: null,
  },
);

defineEmits(['close']);

const smPlacementClassName = computed(() => {
  switch (props.smPlacement) {
    case 'top':
      return 'sm:items-start';
    default:
      return 'sm:items-center';
  }
});
</script>
