<template>
  <div
    class="my-4 bg-zinc-100 py-6 text-center font-medium"
    data-test="UnitSelectionAdditionalPropertiesSeparator"
  >
    <span class="text-lg">
      <template v-if="type === 'available'">
        {{
          chainName
            ? $t('otherAvailableAccommodationFromChain', {
                chainName,
              })
            : $t('otherAvailableAccommodation')
        }}
      </template>

      <template v-else-if="type === 'unavailable'">
        {{
          chainName
            ? $t('availableAccommodationOnOtherDatesFromChain', {
                chainName,
              })
            : $t('availableAccommodationOnOtherDates')
        }}
      </template>

      <template v-else>
        {{
          chainName
            ? $t('otherAccommodationFromChain', { chainName })
            : $t('otherAccommodation')
        }}
      </template>
    </span>

    <div
      v-if="type === undefined"
      class="mt-1 flex flex-wrap justify-center gap-1 text-sm text-zinc-600"
    >
      <div class="flex items-center gap-3">
        <span>
          {{ formatDate(searchStore.stayDates.checkInDate) }}
        </span>

        <AppArrow class="w-10" />

        <span>
          {{ formatDate(searchStore.stayDates.checkOutDate) }}
        </span>
      </div>

      <span class="capitalize">
        ({{ $t('countNights', { count: searchStore.stayLength }) }})
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { createDateObject } from '@/date/date.utilities';
import { useSearchStore } from '@/search/search.store';
import AppArrow from '@/ui/app-arrow/AppArrow.vue';

defineProps<{ type?: 'available' | 'unavailable' }>();

const { d } = useI18n();

const searchStore = useSearchStore();

const chainName = computed(() => searchStore.widget.chain?.name);

const formatDate = (date: string): string =>
  d(createDateObject(date), {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
</script>
