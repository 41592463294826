import type {
  StripeTransactionCharge,
  StripeTransactionChargeDTO,
} from '@/property/payment-gateway/stripe/transaction-charge/stripe-transaction-charge';
import { StripeTransactionChargeType } from '@/property/payment-gateway/stripe/transaction-charge/stripe-transaction-charge';

export const mapStripeTransactionChargeFromDTO = (
  stripeTransactionChargeDTO: StripeTransactionChargeDTO,
): StripeTransactionCharge => {
  const { type } = stripeTransactionChargeDTO;

  switch (type) {
    case StripeTransactionChargeType.Percentage:
      return {
        type,
        percentage: Number(stripeTransactionChargeDTO.percentage),
      };
    case StripeTransactionChargeType.Monetary:
      return {
        type,
        amount: Number(stripeTransactionChargeDTO.amount),
      };
    default:
      throw new Error(
        `Unexpected Stripe transaction charge type: ${JSON.stringify(type)}`,
      );
  }
};
