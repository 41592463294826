<template>
  <button
    class="rounded-full text-slate-800 hover:text-slate-900 focus-visible:rounded-full focus-visible:text-slate-900 focus-visible:outline focus-visible:outline-1 focus-visible:outline-offset-1 focus-visible:outline-slate-900"
    :aria-label="toTitleCase($t('close'))"
    @click="emit('close')"
  >
    <IconX :size="size" stroke-width="1.5" aria-hidden />
  </button>
</template>

<script setup lang="ts">
import { IconX } from '@tabler/icons-vue';
import { useLocaleString } from '@/string/locale-string.composable';

const { size = 24 } = defineProps<{ size?: number }>();

const emit = defineEmits(['close']);

const { toTitleCase } = useLocaleString();
</script>
