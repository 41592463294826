import type {
  PropertyThemeAccentColor,
  PropertyThemeAccentColorDTO,
} from '@/property/property-theme/accent-color/property-theme-accent-color';

export const mapPropertyThemeAccentColorFromDTO = (
  propertyThemeAccentColorDTO: PropertyThemeAccentColorDTO,
): PropertyThemeAccentColor => ({
  hex: propertyThemeAccentColorDTO.hex,
  contrastHex: propertyThemeAccentColorDTO.contrastHex,
  isHighLuminance: propertyThemeAccentColorDTO.isHighLuminance,
});
