import { groupBy } from 'lodash-es';
import { PricePlanVariantType } from '@/price-plan/price-plan';
import type {
  DiscountedPricePlanVariant,
  GroupedPricePlans,
  NonPackagePricePlanVariant,
  PackagePricePlanVariant,
  PricePlan,
  PrivatePricePlanVariant,
} from '@/price-plan/price-plan';
import {
  findCheapestPricePlan,
  findLargestOccupancyPricePlan,
  partitionPricePlansByOccupancyLimitsAndSearchOccupancyEquality,
  partitionPricePlansByOccupancyLimitsThatCanFitSearchOccupancy,
  isPricePlanCheaperThanAllPricePlans,
  findPricePlansWithSameOccupancyLimitsAsPricePlan,
  groupPricePlansByAttribute,
  findPricePlansWithSameSearchOccupancyAsPricePlan,
  findPricePlansWithSameOccupancyLimitsAsCheapestPricePlan,
} from '@/price-plan/price-plan.utilities';
import { createUnitPricePlans } from '@/price-plan/unit-price-plan/unit-price-plan.utilities';
import { createWayToSellPricePlans } from '@/price-plan/way-to-sell-price-plan/way-to-sell-price-plan.utilities';
import type { UnitRates } from '@/rates/unit-rates/unit-rates';

/**
 * Creates a flat list of price plans based on the unit rates provided. The occupancy belonging to each set of
 * unit rates is used as the "search occupancy" in the generated price plan.
 *
 * If any "private" price plans are generated, *only* those price plans should be returned. Otherwise, for each
 * set of unit rates, the following set of price plans should be generated:
 *   - One or many "refundable" price plans.
 *   - Zero or many "non-refundable" price plans, corresponding to the most cost-effective
 *     non-refundable offer.
 *   - Zero or many "package" price plans, per applicable package offer.
 *
 * Each price plan set above will be built using the rates of the base unit and/or any of the associated
 * ways to sell. In summary:
 *   - Price plans will always be included for every unit and way to sell where the occupancy limits
 *     directly match the search occupancy.
 *   - If neither the occupancy limits of the unit or any way to sell directly match the search occupancy,
 *     price plans will be included for the cheapest unit or way to sell where the occupancy limits can
 *     at least accommodate the search occupancy. Note, in this case, a price plan for the unit or any way
 *     to sell will also be included if it has the same occupancy limits as the cheapest unit or way to
 *     sell.
 *   - If the occupancy limits of the unit or any way to sell do directly match the search occupancy, but
 *     there is a cheaper alternative for a unit or way to sell that can only accommodate the search
 *     occupancy, a price plan will be included for that (cheapest) unit or way to sell.
 *   - If the occupancy limits of the unit or any way to sell cannot accommodate any search occupancy,
 *     then a single price plan will be returned corresponding to the unit or ways to sell where we can squeeze
 *     in the largest (unfitting) occupancy.
 */
export const createPricePlans = (allUnitRates: UnitRates[]): PricePlan[] => {
  const allUnitAndWayToSellPricePlans =
    createAllUnitAndWayToSellPricePlans(allUnitRates);

  const {
    refundablePricePlans,
    nonRefundablePricePlans,
    packagePricePlans,
    privatePricePlans,
  } = groupUnitAndWayToSellPricePlans(allUnitAndWayToSellPricePlans);

  if (privatePricePlans.length > 0) {
    return findBestFitPricePlans(privatePricePlans);
  }

  return [
    ...findBestFitRefundablePricePlans(refundablePricePlans),
    ...findBestFitNonRefundablePricePlans(nonRefundablePricePlans),
    ...findBestFitPackagePricePlans(packagePricePlans),
  ];
};

const createAllUnitAndWayToSellPricePlans = (
  allUnitRates: UnitRates[],
): PricePlan[] =>
  allUnitRates.flatMap((unitRates) => {
    const pricePlans: PricePlan[] = createUnitPricePlans(unitRates);

    for (const wayToSellRates of unitRates.waysToSellRates) {
      const wayToSellPricePlans = createWayToSellPricePlans(
        unitRates,
        wayToSellRates,
      );

      pricePlans.push(...wayToSellPricePlans);
    }

    return pricePlans;
  });

const groupUnitAndWayToSellPricePlans = (
  unitAndWayToSellPricePlans: PricePlan[],
): GroupedPricePlans => {
  const refundablePricePlans: NonPackagePricePlanVariant[] = [];
  const nonRefundablePricePlans: DiscountedPricePlanVariant[] = [];
  const packagePricePlans: PackagePricePlanVariant[] = [];
  const privatePricePlans: PrivatePricePlanVariant[] = [];

  for (const pricePlan of unitAndWayToSellPricePlans) {
    if (pricePlan.type === PricePlanVariantType.Private) {
      privatePricePlans.push(pricePlan);
    } else if (pricePlan.type === PricePlanVariantType.Package) {
      packagePricePlans.push(pricePlan);
    } else if (
      pricePlan.type === PricePlanVariantType.Discounted &&
      !pricePlan.offer.isRefundable
    ) {
      nonRefundablePricePlans.push(pricePlan);
    } else {
      refundablePricePlans.push(pricePlan);
    }
  }

  return {
    refundablePricePlans,
    nonRefundablePricePlans,
    packagePricePlans,
    privatePricePlans,
  };
};

const findBestFitRefundablePricePlans = (
  refundablePricePlans: NonPackagePricePlanVariant[],
): NonPackagePricePlanVariant[] => {
  const bestFitRefundablePricePlans =
    findBestFitPricePlans(refundablePricePlans);

  if (bestFitRefundablePricePlans.length === 0) {
    throw new Error(
      'Could not build price plans. There must be at least one refundable price plan',
    );
  }

  return bestFitRefundablePricePlans;
};

const findBestFitNonRefundablePricePlans = (
  nonRefundablePricePlans: DiscountedPricePlanVariant[],
): DiscountedPricePlanVariant[] =>
  findBestFitPricePlans(nonRefundablePricePlans);

const findBestFitPackagePricePlans = (
  packagePricePlans: PackagePricePlanVariant[],
): PackagePricePlanVariant[] => {
  const packagePricePlansKeyedByPackageId = groupBy(
    packagePricePlans,
    'offer.id',
  );

  const packagePricePlansGroupedByPackage = Object.values(
    packagePricePlansKeyedByPackageId,
  );

  return packagePricePlansGroupedByPackage.flatMap(
    (packagePricePlansForIndividualPackage) =>
      findBestFitPricePlans(packagePricePlansForIndividualPackage),
  );
};

const findBestFitPricePlans = <T extends PricePlan>(pricePlans: T[]): T[] => {
  const [
    pricePlansWithMatchingSearchOccupancy,
    pricePlansWithNonMatchingSearchOccupancy,
  ] =
    partitionPricePlansByOccupancyLimitsAndSearchOccupancyEquality(pricePlans);

  const [
    pricePlansWithAccommodatingSearchOccupancy,
    pricePlansWithNonAccommodatingSearchOccupancy,
  ] = partitionPricePlansByOccupancyLimitsThatCanFitSearchOccupancy(
    pricePlansWithNonMatchingSearchOccupancy,
  );

  if (pricePlansWithAccommodatingSearchOccupancy.length > 0) {
    const pricePlansGroupedByAccommodatingSearchOccupancy =
      groupPricePlansByAttribute(
        pricePlansWithAccommodatingSearchOccupancy,
        'searchOccupancy',
      );

    if (pricePlansWithMatchingSearchOccupancy.length === 0) {
      return pricePlansGroupedByAccommodatingSearchOccupancy.flatMap(
        findPricePlansWithSameOccupancyLimitsAsCheapestPricePlan,
      );
    }

    const pricePlansWhereCheapestAccommodatingSearchOccupancyIsCheaperThanAllEquivalentPricePlansWithMatchingSearchOccupancy =
      pricePlansGroupedByAccommodatingSearchOccupancy.flatMap(
        (pricePlansForAccommodatingSearchOccupancy) => {
          const cheapestPricePlanForAccommodatingSearchOccupancy =
            findCheapestPricePlan(pricePlansForAccommodatingSearchOccupancy);

          return isPricePlanCheaperThanAllPricePlans(
            cheapestPricePlanForAccommodatingSearchOccupancy,
            findPricePlansWithSameSearchOccupancyAsPricePlan(
              cheapestPricePlanForAccommodatingSearchOccupancy,
              pricePlansWithMatchingSearchOccupancy,
            ),
          )
            ? findPricePlansWithSameOccupancyLimitsAsPricePlan(
                cheapestPricePlanForAccommodatingSearchOccupancy,
                pricePlansForAccommodatingSearchOccupancy,
              )
            : [];
        },
      );

    return [
      ...pricePlansWithMatchingSearchOccupancy,
      ...pricePlansWhereCheapestAccommodatingSearchOccupancyIsCheaperThanAllEquivalentPricePlansWithMatchingSearchOccupancy,
    ];
  }

  if (pricePlansWithMatchingSearchOccupancy.length > 0) {
    return pricePlansWithMatchingSearchOccupancy;
  }

  if (pricePlansWithNonAccommodatingSearchOccupancy.length > 0) {
    const pricePlanWithLargestNonAccommodatingSearchOccupancy =
      findLargestOccupancyPricePlan(
        pricePlansWithNonAccommodatingSearchOccupancy,
      );

    return [pricePlanWithLargestNonAccommodatingSearchOccupancy];
  }

  return [];
};
