<template>
  <AppListbox v-model="checkInTime" :compare-by="isEqual">
    <div class="mb-2 font-medium capitalize text-slate-800">
      <AppListboxLabel :text="$t('yourArrivalTime')" />
    </div>

    <AppListboxButton
      :text="checkInTime ? generateTimeDisplayText(checkInTime) : DEFAULT_VALUE"
      class="border border-gray-300 bg-white py-2 text-left"
    />

    <AppListboxOptions>
      <AppListboxOption disabled :value="DEFAULT_VALUE" />

      <AppListboxOption
        v-for="{ time, displayText } in checkInTimeOptions"
        :key="displayText"
        class="w-36"
        :label="displayText"
        :value="time"
      />
    </AppListboxOptions>
  </AppListbox>
</template>

<script setup lang="ts">
import { isEqual } from 'lodash-es';
import { computed } from 'vue';
import type { Property } from '@/property/property';
import type { Time } from '@/time/time';
import { useTimeDisplayText } from '@/time/time-display-text.composable';
import {
  compareTimes,
  getTimesInTimeInterval,
  roundTimeToNextHalfHour,
  roundTimeToPreviousHalfHour,
} from '@/time/time.utilities';
import AppListbox from '@/ui/app-listbox/AppListbox.vue';
import AppListboxButton from '@/ui/app-listbox/AppListboxButton.vue';
import AppListboxLabel from '@/ui/app-listbox/AppListboxLabel.vue';
import AppListboxOption from '@/ui/app-listbox/AppListboxOption.vue';
import AppListboxOptions from '@/ui/app-listbox/AppListboxOptions.vue';

interface CheckInTimeOption {
  time: Time;
  displayText: string;
}

const DEFAULT_VALUE = '-';

const props = defineProps<{ property: Property }>();

const checkInTime = defineModel<Time | undefined>({ required: true });

const earliestCheckInTime = computed(
  () => props.property.checkInPolicy.earliestCheckInTime,
);

const latestCheckInTime = computed(
  () => props.property.checkInPolicy.latestCheckInTime,
);

const checkInTimeOptions = computed<CheckInTimeOption[]>(() => {
  if (compareTimes(earliestCheckInTime.value, latestCheckInTime.value) === 0) {
    return [generateCheckInTimeOption(earliestCheckInTime.value)];
  }

  const checkInTimeOptions = getTimesInTimeInterval(
    roundTimeToNextHalfHour(earliestCheckInTime.value),
    roundTimeToPreviousHalfHour(latestCheckInTime.value),
    30,
  ).map(generateCheckInTimeOption);

  if (
    compareTimes(earliestCheckInTime.value, checkInTimeOptions[0]!.time) === -1
  ) {
    checkInTimeOptions.unshift(
      generateCheckInTimeOption(earliestCheckInTime.value),
    );
  }

  if (
    compareTimes(latestCheckInTime.value, checkInTimeOptions.at(-1)!.time) === 1
  ) {
    checkInTimeOptions.push(generateCheckInTimeOption(latestCheckInTime.value));
  }

  return checkInTimeOptions;
});

const generateCheckInTimeOption = (time: Time): CheckInTimeOption => ({
  time,
  displayText: generateTimeDisplayText(time),
});

const generateTimeDisplayText = (time: Time): string =>
  useTimeDisplayText(time).value;
</script>
