<template>
  <div data-test="UnitSelectionUnit">
    <div
      v-bind="isAvailable ? { id: getScrollAnchorId(unitId) } : {}"
      class="scroll-mt-[calc(theme(spacing.inline-booking-stage-selector-height)+theme(spacing.unit-selection-search-bar-height)+theme(space.4))]"
    ></div>

    <div class="flex flex-col gap-x-5 gap-y-4 md:flex-row">
      <UnitSelectionUnitImages :unit-id="unitId" class="w-full md:w-80" />

      <UnitSelectionUnitDetails :unit-id="unitId" />
    </div>

    <div v-if="isAvailable" class="mt-4">
      <UnitSelectionPricePlans :unit-id="unitId" :is-last-unit="isLastUnit" />
    </div>

    <div v-else class="ml-auto mt-4 w-full md:w-64">
      <UnitSelectionUnitCalendarButton :unit-id="unitId" />
    </div>
  </div>
</template>

<script setup lang="ts">
import UnitSelectionPricePlans from '@/unit-selection-page/price-plan/UnitSelectionPricePlans.vue';
import UnitSelectionUnitCalendarButton from '@/unit-selection-page/unit/calendar-button/UnitSelectionUnitCalendarButton.vue';
import UnitSelectionUnitDetails from '@/unit-selection-page/unit/details/UnitSelectionUnitDetails.vue';
import UnitSelectionUnitImages from '@/unit-selection-page/unit/images/UnitSelectionUnitImages.vue';
import { useUnitSelectionUnitScroller } from '@/unit-selection-page/unit/unit-selection-unit-scroller.composable';

defineProps<{
  unitId: number;
  isAvailable: boolean;
  isLastUnit: boolean;
}>();

const { getScrollAnchorId } = useUnitSelectionUnitScroller();
</script>
