import type { Ref } from 'vue';
import { ref, computed, reactive } from 'vue';
import type { CancellationPolicyOverStay } from '@/property/booking-policy/cancellation-policy/over-stay/cancellation-policy-over-stay';
import { DepositPolicyChargeType } from '@/property/booking-policy/deposit-policy/charge/deposit-policy-charge';
import { stayDatesIncurFullDepositPolicyNightlyCharge } from '@/property/booking-policy/deposit-policy/charge/nightly/deposit-policy-nightly-charge.utilities';
import type { MandatoryDepositPolicy } from '@/property/booking-policy/deposit-policy/mandatory/mandatory-deposit-policy';
import type { MandatoryDepositPolicyOverStay } from '@/property/booking-policy/deposit-policy/mandatory/over-stay/mandatory-deposit-policy-over-stay';
import { useDepositRefundPolicyOverStay } from '@/property/booking-policy/deposit-policy/refund-policy/over-stay/deposit-refund-policy-over-stay.composable';
import type { Property } from '@/property/property';
import type { StayDates } from '@/stay-dates/stay-dates';

export const useMandatoryDepositPolicyOverStay = (
  property: Ref<Property>,
  mandatoryDepositPolicy: Ref<MandatoryDepositPolicy>,
  cancellationPolicyOverStay: Ref<CancellationPolicyOverStay>,
  stayDates: Ref<StayDates>,
): MandatoryDepositPolicyOverStay => {
  const depositRefundPolicyOverStay = computed(() =>
    mandatoryDepositPolicy.value.refundPolicy
      ? useDepositRefundPolicyOverStay(
          property,
          ref(mandatoryDepositPolicy.value.refundPolicy),
          cancellationPolicyOverStay,
          stayDates,
        )
      : undefined,
  );

  const rendersBookingNonRefundable = computed(() => {
    if (depositRefundPolicyOverStay.value?.isApplicable) {
      return false;
    }

    switch (mandatoryDepositPolicy.value.charge.chargeType) {
      case DepositPolicyChargeType.Percentage:
        return mandatoryDepositPolicy.value.charge.percentage === 100;
      case DepositPolicyChargeType.Nightly:
        return stayDatesIncurFullDepositPolicyNightlyCharge(
          mandatoryDepositPolicy.value.charge,
          stayDates.value,
        );
    }

    return false;
  });

  return reactive({
    depositRefundPolicyOverStay,
    rendersBookingNonRefundable,
  });
};
