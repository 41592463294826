import { compareValuesByArrayPosition } from '@/array/array.utilities';
import type { Image } from '@/image/image';
import type { Meal } from '@/property/meal/meal';
import type { Unit } from '@/property/unit/unit';
import type { WayToSell } from '@/property/way-to-sell/way-to-sell';

export const findUnitWayToSellById = (
  { waysToSell }: Unit,
  wayToSellId: number,
): WayToSell | undefined => waysToSell.find(({ id }) => id === wayToSellId);

export const findUnitMeals = <T extends Meal>(
  { hasNoIncludedMeals }: Unit,
  meals: T[],
): T[] => (hasNoIncludedMeals ? [] : meals);

export const compareUnitsByPriority = (
  priorityOrderedUnitIds: number[],
  unitA: Unit,
  unitB: Unit,
): number =>
  compareValuesByArrayPosition(unitA.id, unitB.id, priorityOrderedUnitIds);

export const compareUnitWaysToSellByPriority = (
  { priorityOrderedWayToSellIds }: Unit,
  wayToSellA: WayToSell,
  wayToSellB: WayToSell,
): number =>
  compareValuesByArrayPosition(
    wayToSellA.id,
    wayToSellB.id,
    priorityOrderedWayToSellIds,
  );

export const sortUnitImagesByPriority = ({
  images,
  priorityOrderedImageIds,
}: Unit): Image[] =>
  [...images].sort(
    (imageA, imageB) =>
      compareValuesByArrayPosition(
        imageA.id,
        imageB.id,
        priorityOrderedImageIds,
      ) || imageA.id - imageB.id,
  );
