import type {
  AdditionalTaxRateNightsChargeLimitDTO,
  AdditionalTaxRateNightsChargeLimit,
} from '@/tax/additional/rate/charge-limit/nights/additional-tax-rate-nights-charge-limit';

export const mapAdditionalTaxRateNightsChargeLimitFromDTO = (
  additionalTaxRateNightsChargeLimitDTO: AdditionalTaxRateNightsChargeLimitDTO,
): AdditionalTaxRateNightsChargeLimit => ({
  type: additionalTaxRateNightsChargeLimitDTO.type,
  numberOfNights: additionalTaxRateNightsChargeLimitDTO.numberOfNights,
});
