<template>
  <AppLayout :property="undefined">
    <ErrorLayout>
      <PageNotFound />
    </ErrorLayout>
  </AppLayout>
</template>

<script setup lang="ts">
import { useHead } from '@unhead/vue';
import { useI18n } from 'vue-i18n';
import PageNotFound from '@/error-pages/page-not-found/PageNotFound.vue';
import AppLayout from '@/layouts/AppLayout.vue';
import ErrorLayout from '@/layouts/ErrorLayout.vue';
import { useLocaleString } from '@/string/locale-string.composable';

const { t } = useI18n();
const { toTitleCase } = useLocaleString();

useHead({
  title: () => toTitleCase(t('pageNotFound')),
});
</script>
