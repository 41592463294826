import { useWindowFocus, whenever } from '@vueuse/core';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { BOOKING_CONFIRMATION_ROUTE } from '@/router';
import {
  deleteUserEventIdentifier,
  useUserEventIdentifier,
} from '@/user-event/identifier/user-event-identifier.utilities';
import type { UserEventAction } from '@/user-event/user-event';
import { UserEventActionLabel } from '@/user-event/user-event';
import { trackUserEvent } from '@/user-event/user-event.api';
import { createUserEvent } from '@/user-event/user-event.utilities';

export const useUserEventStore = defineStore('user-event-store', () => {
  const windowIsFocused = useWindowFocus();

  const shouldQueueUserEvents = ref(true);

  const userEventQueue = ref<{ handle: () => Promise<void> }[]>([]);

  const trackUserEventAction = (userEventAction: UserEventAction) => {
    if (shouldQueueUserEvents.value) {
      userEventQueue.value.push({
        handle: () => handleUserEvent(userEventAction),
      });
    } else {
      void handleUserEvent(userEventAction);
    }
  };

  const handleUserEvent = async (userEventAction: UserEventAction) => {
    const userEventIdentifier = await useUserEventIdentifier(
      userEventAction.metadata.widget_id,
      userEventAction.metadata.property_id,
    );

    trackUserEvent(createUserEvent(userEventAction, userEventIdentifier));

    /* Delete user event identifier on confirmation page view
    so further bookings by the same user are tracked as new conversions */
    if (
      userEventAction.label === UserEventActionLabel.PageView &&
      userEventAction.metadata.page_name === BOOKING_CONFIRMATION_ROUTE
    ) {
      void deleteUserEventIdentifier(userEventIdentifier);
    }
  };

  const processUserEventQueue = () => {
    let queuedUserEvent = undefined;

    do {
      queuedUserEvent = userEventQueue.value.shift();

      if (queuedUserEvent) {
        void queuedUserEvent.handle();
      }
    } while (queuedUserEvent);
  };

  whenever(
    windowIsFocused,
    () => {
      if (shouldQueueUserEvents.value) {
        shouldQueueUserEvents.value = false;

        processUserEventQueue();
      }
    },
    { immediate: true },
  );

  return {
    trackUserEventAction,
  };
});
