<template>
  <div class="grid grid-cols-[repeat(auto-fill,minmax(16.5rem,1fr))] gap-8">
    <LocaleSelectorOption is-selected :locale-option="selectedLocaleOption" />

    <LocaleSelectorOption
      v-for="localeOption of unselectedLocaleOptions"
      :key="localeOption.locale"
      :locale-option="localeOption"
      :is-selected="false"
      @select="selectLocale(localeOption.locale)"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import {
  getDisplayNameOfLocaleLanguage,
  getDisplayNameOfLocaleRegion,
  getRegionCodeOfLocale,
} from '@/intl/intl.utilities';
import type { LocaleOption } from '@/locale-selector/LocaleOption';
import LocaleSelectorOption from '@/locale-selector/LocaleSelectorOption.vue';
import { useLocaleString } from '@/string/locale-string.composable';

const emit = defineEmits(['select']);

const { availableLocales, locale, t } = useI18n();

const { toTitleCase, capitalize } = useLocaleString();

/**
 * Note that we intentionally create a non-reactive reference to the selected
 * locale because we anticipate the selector will be immediately unmounted
 * upon selecting an option, and we don't want the order of the options to
 * visually change during any closing animations.
 */
const selectedLocale = locale.value;

const unselectedLocales = availableLocales.filter(
  (locale) => locale !== selectedLocale,
);

const selectedLocaleOption = computed(() => makeLocaleOption(selectedLocale));

const unselectedLocaleOptions = computed(() =>
  unselectedLocales.map(makeLocaleOption).sort(),
);

const selectLocale = (selectedLocale: string) => {
  locale.value = selectedLocale;

  emit('select');
};

/**
 * Note that any text we display for each option should be translated according
 * to the given locale, not the currently active global locale. This is to
 * ensure a user looking for their language can spot it immediately.
 *
 * For example, if 'fr-FR' is provided, all text should be given in French.
 */
const makeLocaleOption = (locale: string): LocaleOption => ({
  locale,
  regionCode: getRegionCodeOfLocale(locale),
  regionName: getDisplayNameOfLocaleRegion(locale),
  languageName: capitalize(
    getDisplayNameOfLocaleLanguage(locale, {
      displayNameOptions: {
        languageDisplay: 'standard',
        style: 'short',
      },
    }),
  ),
  selectLanguageText: toTitleCase(t('selectLanguage', 1, { locale })),
});
</script>
