import type {
  CancellationPolicy,
  CancellationPolicyDTO,
} from '@/property/booking-policy/cancellation-policy/cancellation-policy';
import { CancellationPolicyType } from '@/property/booking-policy/cancellation-policy/cancellation-policy';

export const mapCancellationPolicyFromDTO = (
  cancellationPolicyDTO: CancellationPolicyDTO,
): CancellationPolicy => {
  const {
    cancellationType,
    minimumNumberOfDaysBeforeArrivalThreshold,
    maximumNumberOfDaysBeforeArrivalThreshold,
  } = cancellationPolicyDTO;

  switch (cancellationType) {
    case CancellationPolicyType.Free:
      return {
        cancellationType,
        minimumNumberOfDaysBeforeArrivalThreshold,
        maximumNumberOfDaysBeforeArrivalThreshold:
          maximumNumberOfDaysBeforeArrivalThreshold ?? undefined,
      };
    case CancellationPolicyType.Nightly:
      return {
        cancellationType,
        numberOfNights: cancellationPolicyDTO.numberOfNights,
        minimumNumberOfDaysBeforeArrivalThreshold,
        maximumNumberOfDaysBeforeArrivalThreshold:
          maximumNumberOfDaysBeforeArrivalThreshold ?? undefined,
      };
    case CancellationPolicyType.Monetary:
      return {
        cancellationType,
        amount: Number(cancellationPolicyDTO.amount),
        minimumNumberOfDaysBeforeArrivalThreshold,
        maximumNumberOfDaysBeforeArrivalThreshold:
          maximumNumberOfDaysBeforeArrivalThreshold ?? undefined,
      };
    case CancellationPolicyType.Percentage:
      return {
        cancellationType,
        percentage: Number(cancellationPolicyDTO.percentage),
        minimumNumberOfDaysBeforeArrivalThreshold,
        maximumNumberOfDaysBeforeArrivalThreshold:
          maximumNumberOfDaysBeforeArrivalThreshold ?? undefined,
      };
    case CancellationPolicyType.PerUnitMonetary:
      return {
        cancellationType,
        amountPerUnit: Number(cancellationPolicyDTO.amountPerUnit),
        minimumNumberOfDaysBeforeArrivalThreshold,
        maximumNumberOfDaysBeforeArrivalThreshold:
          maximumNumberOfDaysBeforeArrivalThreshold ?? undefined,
      };
  }
};

export const mapCancellationPoliciesFromDTOs = (
  cancellationPolicyDTOs: CancellationPolicyDTO[],
): CancellationPolicy[] =>
  cancellationPolicyDTOs.map(mapCancellationPolicyFromDTO);
