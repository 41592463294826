import type { BookingPolicy } from '@/property/booking-policy/booking-policy';
import type { CancellationPolicy } from '@/property/booking-policy/cancellation-policy/cancellation-policy';
import { isCancellationPolicyApplicableForStayDates } from '@/property/booking-policy/cancellation-policy/cancellation-policy.utilities';
import type { CardPaymentMethodPolicy } from '@/property/booking-policy/payment-method-policy/card/card-payment-method-policy';
import { isCardPaymentMethodPolicy } from '@/property/booking-policy/payment-method-policy/payment-method-policy.utilities';
import type { StayDates } from '@/stay-dates/stay-dates';

export const getBookingPolicyApplicableCancellationPolicyForStayDates = (
  { cancellationPolicies }: BookingPolicy,
  stayDates: StayDates,
  timezone: string,
): CancellationPolicy =>
  cancellationPolicies.find((cancellationPolicy) =>
    isCancellationPolicyApplicableForStayDates(
      cancellationPolicy,
      stayDates,
      timezone,
    ),
  )!;

export const getBookingPolicyCardPaymentMethodPolicy = ({
  paymentMethodPolicies,
}: BookingPolicy): CardPaymentMethodPolicy | undefined =>
  paymentMethodPolicies.find(isCardPaymentMethodPolicy);
