import type {
  BookingOffer,
  BookingOfferDTO,
} from '@/booking/offer/booking-offer';

export const mapBookingOfferFromDTO = (
  bookingOfferDTO: BookingOfferDTO,
): BookingOffer => ({
  offerName: bookingOfferDTO.offerName,
  offerDescription: bookingOfferDTO.offerDescription ?? undefined,
});
