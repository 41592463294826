<template>
  <div class="text-base">
    <div v-if="!searchStore.isLoading" translate="yes">
      <AppCollapsibleText
        class="break-anywhere"
        :text="description"
        :max-lines="3"
      />
    </div>

    <template v-else>
      <AppSkeletonText class="w-96 max-w-full" />
      <AppSkeletonText class="w-96 max-w-full" />
      <AppSkeletonText class="w-96 max-w-full" />
    </template>
  </div>
</template>

<script setup lang="ts">
import { useSearchStore } from '@/search/search.store';
import AppCollapsibleText from '@/ui/app-collapsible-text/AppCollapsibleText.vue';
import AppSkeletonText from '@/ui/app-skeleton-text/AppSkeletonText.vue';

defineProps<{
  description: string;
}>();

const searchStore = useSearchStore();
</script>
