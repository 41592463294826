<template>
  <div class="grid grid-cols-[auto_min-content]">
    <div class="break-anywhere font-semibold capitalize" translate="yes">
      {{ extra.name }}
    </div>

    <div class="self-center justify-self-end">
      <span
        v-if="extra.isMandatory"
        class="whitespace-nowrap text-xs font-medium capitalize text-gray-600"
      >
        {{ $t('mandatory') }}
      </span>
      <BookingSummaryBodyExtraItemRemoveButton v-else :extra="extra" />
    </div>

    <div class="text-sm">{{ priceDescriptionTag }}</div>

    <ExtrasItemRunningTotalRate
      class="justify-self-end text-sm font-semibold"
      :extra="extra"
    />
  </div>

  <div v-if="canShowQuestion" class="break-anywhere">
    <div class="text-xs font-medium text-gray-600" translate="yes">
      {{ extra.question }}
    </div>

    <div class="border-l border-zinc-500 pl-2 text-sm text-zinc-500">
      {{ answer }}
    </div>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useExtraPriceDescription } from '@/booking-itinerary/extra-itinerary/extra-price-description.composable';
import { useExtraItineraryItemStore } from '@/booking-itinerary/extra-itinerary/store/extra-itinerary-item.store';
import { useMandatoryExtraItineraryItemStore } from '@/booking-itinerary/extra-itinerary/store/mandatory-extra-itinerary-item.store';
import BookingSummaryBodyExtraItemRemoveButton from '@/booking-summary/body/extra-item/BookingSummaryBodyExtraItemRemoveButton.vue';
import ExtrasItemRunningTotalRate from '@/extras-page/item/running-total-rate/ExtrasItemRunningTotalRate.vue';
import type { Extra } from '@/property/extra/extra';

const props = defineProps<{
  extra: Extra;
}>();

const extraItineraryItemStore = props.extra.isMandatory
  ? useMandatoryExtraItineraryItemStore(props.extra)
  : useExtraItineraryItemStore(props.extra);

const { answer } = storeToRefs(extraItineraryItemStore);

const canShowQuestion = computed(
  () =>
    props.extra.question.length > 0 &&
    answer.value !== undefined &&
    answer.value.length > 0,
);

const { priceDescriptionTag } = useExtraPriceDescription(props.extra);
</script>
