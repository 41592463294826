import type { Ref } from 'vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import type { AllowedChildPolicy } from '@/property/child-policy/allowed/allowed-child-policy';
import type { Property } from '@/property/property';

export const useChildPolicyTermsAndConditionsDisplayText = (
  property: Ref<Property>,
) => {
  const { t } = useI18n();

  const childPolicy = computed(() => property.value.childPolicy);

  const generateChildrenAllowedDisplayText = ({
    minimumChildAge,
  }: AllowedChildPolicy): string =>
    minimumChildAge > 0
      ? t('childrenAgedAgeOrOverAreWelcome', { age: minimumChildAge })
      : t('childrenOfAllAgesAreWelcome');

  return computed(() =>
    childPolicy.value.childrenAreAllowed
      ? generateChildrenAllowedDisplayText(childPolicy.value)
      : t('allGuestsMustBe18OrOverNoChildrenAreAllowed'),
  );
};
