<template>
  <slot :is-valid="!canShowErrorMessages || errorMessages.length === 0"></slot>
</template>

<script setup lang="ts" generic="T">
import { reactive, ref, watch, readonly, onMounted } from 'vue';
import { useInputValidationGroup } from '@/ui/app-form/app-form-input-validation-group.composable';
import type { ValidationRule } from '@/ui/app-form/app-form-input-validation-rules';
import { useForm } from '@/ui/app-form/app-form.composable';

const props = defineProps<{
  value: T;
  validationRules: ValidationRule<T>[];
}>();

const { canShowErrorMessages } = useForm();
const { register } = useInputValidationGroup();

const errorMessages = ref<string[]>([]);

const validate = (): boolean => {
  errorMessages.value = [];

  for (const validationRule of props.validationRules) {
    const errorMessage = validationRule(props.value);

    if (errorMessage) {
      errorMessages.value.push(errorMessage);
    }
  }

  return errorMessages.value.length === 0;
};

onMounted(() => {
  register(reactive({ validate, errorMessages: readonly(errorMessages) }));
});

watch(() => props.value, validate, { immediate: true });
</script>
