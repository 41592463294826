<template>
  <div
    class="flex w-full items-center justify-center overflow-hidden bg-white px-6 py-4 shadow-md"
  >
    <template
      v-for="(stage, index) of bookingStageStore.stages"
      :key="stage.type"
    >
      <BookingStageSelectorItemSeparator
        v-if="index > 0"
        :stage="stage"
        is-horizontal
      />

      <BookingStageSelectorItem :stage="stage" />
    </template>
  </div>
</template>

<script setup lang="ts">
import { useBookingStageStore } from '@/booking-stage/booking-stage.store';
import BookingStageSelectorItem from '@/booking-stage/selector/item/BookingStageSelectorItem.vue';
import BookingStageSelectorItemSeparator from '@/booking-stage/selector/item/separator/BookingStageSelectorItemSeparator.vue';

const bookingStageStore = useBookingStageStore();
</script>
