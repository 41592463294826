<template>
  <p>{{ formattedAddress }}</p>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import type { Address } from '@/address/address';
import { findDisplayNameOfAmericanStateCode } from '@/american-states/american-states.utilities';
import { getDisplayNameOfRegionCode } from '@/intl/intl.utilities';

const { locale } = useI18n();

const { address } = defineProps<{ address: Address }>();

const formattedAddress = computed(() =>
  [
    address.line1,
    address.line2,
    address.city,
    address.countryCode === 'US'
      ? findDisplayNameOfAmericanStateCode(address.administrativeArea)
      : address.administrativeArea,
    address.postcode,
    getDisplayNameOfRegionCode(address.countryCode, locale.value),
  ]
    .filter(Boolean)
    .join(', '),
);
</script>
