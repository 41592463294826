import type { ComputedRef, MaybeRef } from 'vue';
import { unref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import type { Time } from '@/time/time';

/**
 * Converts the given Time in to a correctly localized string.
 */
export const useTimeDisplayText = (
  time: MaybeRef<Time>,
): ComputedRef<string> => {
  const { d } = useI18n();

  return computed(() => {
    const { hours, minutes } = unref(time);

    /**
     * Note that, despite only needing the time, we need to pass a full
     * Date object to `d`. Any arbitrary date will do as long as we set
     * the hours/minutes correctly.
     */
    const date = new Date().setHours(hours, minutes);

    return d(date, { hour: 'numeric', minute: 'numeric' });
  });
};
