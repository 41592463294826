import type {
  IncludedMeal,
  IncludedMealDTO,
} from '@/property/meal/included-meal/included-meal';

export const mapIncludedMealFromDTO = (
  includedMealDTO: IncludedMealDTO,
): IncludedMeal => ({
  isIncluded: true,
  type: includedMealDTO.type,
  name: includedMealDTO.name,
});
