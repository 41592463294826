import { throttledRef, useEventBus, useEventListener } from '@vueuse/core';
import { HTTPError } from 'ky';
import type { Ref } from 'vue';
import { computed, onActivated, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { toast } from 'vue3-toastify';
import { useBookingCreate } from '@/booking/create/booking-create.composable';
import type { BookingCreateError } from '@/booking/create/error/booking-create-error';
import { BookingCreateErrorType } from '@/booking/create/error/booking-create-error';
import { shouldBookingCreateProcessingErrorTriggerAvailabilityError } from '@/booking/create/error/processing/booking-create-processing-error.utilities';
import { useBookingConfirmationNavigation } from '@/booking-confirmation-page/booking-confirmation-navigation.composable';
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import { useBookingSummaryStore } from '@/booking-summary/booking-summary.store';
import { CardType } from '@/card/card';
import { bookingCreateAvailabilityErrorEventBusKey } from '@/event-bus/event-bus';
import { logError } from '@/log/log.utilities';
import type {
  InternalMessageEventPcibCardCaptureSuccessData,
  InternalMessageEvent,
} from '@/message-event/internal/internal-message-event';
import { InternalMessageEventDataType } from '@/message-event/internal/internal-message-event';
import { isInternalMessageEvent } from '@/message-event/internal/internal-message-event.utilities';
import type { PcibInboundMessageEvent } from '@/message-event/pcib/pcib-message-event';
import {
  isPcibMessageEvent,
  sendPcibPostMessageEvent,
} from '@/message-event/pcib/pcib-message-event.utilities';
import { generatePcibCardCaptureIFrameUrl } from '@/payments-page/pcib-card-capture/pcib-card-capture-iframe.utilities';
import { usePcibCardCaptureStore } from '@/payments-page/pcib-card-capture/pcib-card-capture.store';
import { getBookingPolicyCardPaymentMethodPolicy } from '@/property/booking-policy/booking-policy.utilities';
import { PaymentGatewayType } from '@/property/payment-gateway/payment-gateway';
import { useUrlResolver } from '@/url/resolver/url-resolver.composable';

export enum PcibCardCaptureIFrameStatus {
  InitializingForm = 'initializing_form',
  FormReady = 'form_ready',
  FormError = 'form_error',
  CapturingCard = 'capturing_card',
  ConfirmingBooking = 'confirming_booking',
}

export const usePcibCardCaptureIFrame = (
  iFrame: Ref<HTMLIFrameElement | null>,
  onInvalid: () => void,
) => {
  const { locale, t } = useI18n();
  const { path } = useRoute();

  const { pcibCardCaptureSuccessUrl, pcibCardCaptureFailureUrl } =
    useUrlResolver();
  const { createBooking } = useBookingCreate();
  const { goToBookingConfirmationWithBookingCreateResult } =
    useBookingConfirmationNavigation();

  const bookingItineraryStore = useBookingItineraryStore();
  const bookingSummaryStore = useBookingSummaryStore();
  const pcibCardCaptureStore = usePcibCardCaptureStore();

  const cardTypes = computed(() => {
    const cardPaymentMethodPolicy = getBookingPolicyCardPaymentMethodPolicy(
      bookingItineraryStore.propertyOverStay.bookingPolicyOverStay
        .bookingPolicy,
    );

    return cardPaymentMethodPolicy
      ? cardPaymentMethodPolicy.cards.map((card) => card.cardType)
      : [
          CardType.AmericanExpress,
          CardType.Discover,
          CardType.Electron,
          CardType.Maestro,
          CardType.Mastercard,
          CardType.VisaCredit,
          CardType.VisaDebit,
        ];
  });

  const iFrameUrl = computed(() =>
    pcibCardCaptureStore.accessToken
      ? generatePcibCardCaptureIFrameUrl(
          pcibCardCaptureStore.accessToken,
          cardTypes.value,
          locale.value,
          location.origin + path,
          pcibCardCaptureSuccessUrl.value.toString(),
          pcibCardCaptureFailureUrl.value.toString(),
        )
      : undefined,
  );

  const iFrameStatus = ref(PcibCardCaptureIFrameStatus.InitializingForm);

  const iFrameHeight = ref(0);

  const throttledIFrameHeight = throttledRef(iFrameHeight, 1000);

  const iFrameWindow = computed(() => iFrame.value!.contentWindow);

  const processMessageEvent = (event: MessageEvent) => {
    if (isPcibMessageEvent(event)) {
      processPcibMessageEvent(event);
    } else if (isInternalMessageEvent(event)) {
      processInternalMessageEvent(event);
    }
  };

  const processPcibMessageEvent = (event: PcibInboundMessageEvent) => {
    const { data } = event;

    if (data === 'ready') {
      processPcibReadyMessageEvent();
    } else if (
      typeof data === 'string' &&
      data.startsWith('frameDimensionsChanged')
    ) {
      processPcibFrameDimensionsChangedMessageEvent(data);
    } else if (data === 'valid') {
      processPcibValidMessageEvent();
    } else if (data === 'invalid') {
      processPcibInvalidMessageEvent();
    }
  };

  const processPcibReadyMessageEvent = () =>
    (iFrameStatus.value = PcibCardCaptureIFrameStatus.FormReady);

  const processPcibFrameDimensionsChangedMessageEvent = (eventData: string) =>
    (iFrameHeight.value = parseInt(eventData.split(':')[2]!));

  const processPcibValidMessageEvent = () => {
    bookingSummaryStore.isLoading = true;

    iFrameStatus.value = PcibCardCaptureIFrameStatus.CapturingCard;

    sendPcibPostMessageEvent(iFrameWindow.value!, 'submit');
  };

  const processPcibInvalidMessageEvent = () => {
    iFrameStatus.value = PcibCardCaptureIFrameStatus.FormError;

    onInvalid();
  };

  const processInternalMessageEvent = ({ data }: InternalMessageEvent) => {
    switch (data.type) {
      case InternalMessageEventDataType.PcibCardCaptureSuccess:
        void processInternalPcibCardCaptureSuccessMessageEvent(data);
        break;
      case InternalMessageEventDataType.PcibCardCaptureFailure:
        processInternalPcibCardCaptureFailureMessageEvent();
        break;
    }
  };

  const processInternalPcibCardCaptureSuccessMessageEvent = async ({
    cardTokenUrl,
    cardType,
    cardHolderName,
    cardExpiration,
  }: InternalMessageEventPcibCardCaptureSuccessData) => {
    iFrameStatus.value = PcibCardCaptureIFrameStatus.ConfirmingBooking;

    try {
      const bookingCreateResult = await createBooking({
        type: PaymentGatewayType.Pcib,
        cardTokenUrl,
        cardType,
        cardHolderName,
        cardExpiration,
      });

      await goToBookingConfirmationWithBookingCreateResult(bookingCreateResult);
    } catch (error) {
      if (error instanceof HTTPError) {
        const responseBody =
          (await error.response.json()) as BookingCreateError;

        if (
          responseBody.type === BookingCreateErrorType.ProcessingError &&
          shouldBookingCreateProcessingErrorTriggerAvailabilityError(
            responseBody.error,
          )
        ) {
          useEventBus(bookingCreateAvailabilityErrorEventBusKey).emit();

          return;
        }
      }

      toast.error(
        t('sorrySomethingWentWrongWhenConfirmingYourBookingPleaseTryAgain'),
        { autoClose: false },
      );

      logError(error as Error);

      reinitializeIFrame();
    } finally {
      bookingSummaryStore.isLoading = false;
    }
  };

  const processInternalPcibCardCaptureFailureMessageEvent = () => {
    bookingSummaryStore.isLoading = false;

    reinitializeIFrame();

    toast.error(
      t('sorrySomethingWentWrongWhenProcessingYourCardPleaseTryAgain'),
      { autoClose: false },
    );
  };

  const submitIFrame = () => {
    /**
     * Note that sending the `validate` message will (if valid) trigger PCIB
     * to send the `valid` message event, which will be used to ultimately
     * submit the form.
     */
    sendPcibPostMessageEvent(iFrameWindow.value!, 'validate');
  };

  const reinitializeIFrame = () => {
    reinitializeIFrameSource();
    reinitializeIFrameStatus();
  };

  const reinitializeIFrameSource = () => (iFrame.value!.src = iFrameUrl.value!);

  const reinitializeIFrameStatus = () =>
    (iFrameStatus.value = PcibCardCaptureIFrameStatus.InitializingForm);

  /**
   * There appears to be a Vue bug where iFrames will reload when inserted in to
   * the DOM, even if their containing component is not being remounted.
   *
   * As a workaround, we at least reinitialize the status on insertion.
   *
   * @see {@link https://github.com/vuejs/vue/issues/9473}
   */
  onActivated(reinitializeIFrameStatus);

  watch(iFrameUrl, reinitializeIFrameStatus);

  useEventListener(window, 'message', processMessageEvent);

  return {
    iFrameUrl,
    iFrameStatus,
    iFrameHeight: throttledIFrameHeight,
    submitIFrame,
  };
};
