<template>
  <AppListbox v-model="guestStore.guest.address.administrativeArea">
    <AppListboxButton
      :text="buttonText"
      class="border py-4 text-left"
      :class="
        isValid
          ? `border-gray-300 ${
              guestStore.administrativeArea ? '' : 'text-gray-500'
            }`
          : 'border-red-500 bg-red-500/10 text-red-700'
      "
    />

    <AppListboxOptions>
      <AppListboxOption
        v-for="{ code, displayName } in americanStates"
        :key="code"
        :value="code"
        :label="displayName"
      />
    </AppListboxOptions>
  </AppListbox>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import {
  americanStates,
  findDisplayNameOfAmericanStateCode,
} from '@/american-states/american-states.utilities';
import { useGuestStore } from '@/guest/guest.store';
import { useLocaleString } from '@/string/locale-string.composable';
import AppListbox from '@/ui/app-listbox/AppListbox.vue';
import AppListboxButton from '@/ui/app-listbox/AppListboxButton.vue';
import AppListboxOption from '@/ui/app-listbox/AppListboxOption.vue';
import AppListboxOptions from '@/ui/app-listbox/AppListboxOptions.vue';

defineProps<{ isValid: boolean }>();

const { t } = useI18n();

const guestStore = useGuestStore();

const { capitalize } = useLocaleString();

const buttonText = computed(() =>
  guestStore.administrativeArea
    ? findDisplayNameOfAmericanStateCode(guestStore.administrativeArea)
    : capitalize(t('state')),
);
</script>
