<template>
  <span v-if="hasMismatchedUnitStayDates" class="font-semibold line-through">
    <AppDateRange
      :from-date="cancelledBookingUnit.checkInDate"
      :to-date="cancelledBookingUnit.checkOutDate"
    />
  </span>

  <div class="flex justify-between">
    <div class="flex-1 font-semibold line-through">
      {{ cancelledBookingUnit.unitName }}
    </div>

    <div v-if="cancelledBookingUnit.chargeAmount > 0" class="flex-1 text-right">
      <div>
        {{
          $n(cancelledBookingUnit.chargeAmount, {
            style: 'currency',
            currency: property.currencyCode,
          })
        }}
      </div>

      <div class="text-xs">{{ $t('cancellationCharge') }}</div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import type { CancelledBookingUnit } from '@/booking/unit/cancelled/cancelled-booking-unit';
import { useBookingConfirmationStore } from '@/booking-confirmation-page/booking-confirmation.store';
import AppDateRange from '@/ui/app-date-range/AppDateRange.vue';

defineProps<{
  cancelledBookingUnit: CancelledBookingUnit;
}>();

const { property, hasMismatchedUnitStayDates } = storeToRefs(
  useBookingConfirmationStore(),
);
</script>
