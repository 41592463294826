<template>
  <div data-test="AvailabilityCalendarMonth">
    <div class="p-4 text-center text-lg font-semibold capitalize">
      {{ $d(startOfMonthDate, { month: 'long', year: 'numeric' }) }}
    </div>

    <AvailabilityCalendarDayNames v-if="showDayNamesHeading" />

    <div class="grid grid-cols-[repeat(7,_minmax(2rem,_3rem))] gap-y-1">
      <div
        v-for="gridItemNumber in numberOfGridItems"
        :key="gridItemNumber"
        class="h-full w-full"
        data-test="AvailabilityCalendarMonth__gridItem"
      >
        <div v-if="isLoading">
          <AvailabilityCalendarDayLoading
            v-if="gridItemNumber > firstDayOfMonthIndex"
            :date="getDateForGridItem(gridItemNumber)"
          />
        </div>
        <div v-else>
          <AvailabilityCalendarDayWrapper
            v-if="gridItemNumber > firstDayOfMonthIndex"
            :date="getDateForGridItem(gridItemNumber)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { addDays, getDay, getDaysInMonth } from 'date-fns';
import { computed } from 'vue';
import { useAvailabilityCalendarStore } from '@/availability-calendar/availability-calendar.store';
import AvailabilityCalendarDayLoading from '@/availability-calendar/AvailabilityCalendarDayLoading.vue';
import AvailabilityCalendarDayNames from '@/availability-calendar/AvailabilityCalendarDayNames.vue';
import AvailabilityCalendarDayWrapper from '@/availability-calendar/AvailabilityCalendarDayWrapper.vue';

const props = withDefaults(
  defineProps<{
    startOfMonthDate: number;
    showDayNamesHeading?: boolean;
  }>(),
  {
    showDayNamesHeading: false,
  },
);

const { monthsWithLoadingAvailability, weekStartIndex } =
  useAvailabilityCalendarStore();

const isLoading = computed(() =>
  monthsWithLoadingAvailability.value.has(props.startOfMonthDate),
);

/**
 * Returns a number in the range 0 -> 6 representing the relative position of
 * the first date in the month, where 0 is the day representing the start of
 * the week (e.g., Monday in GB), and 6 is the day representing the end of
 * the week (e.g., Sunday in GB).
 *
 * For example, January 1st 2022 was a Saturday, which would return the
 * following results for these locales:
 * - GB (week starts on Monday): 5
 * - US (week starts on Sunday): 6
 * - AF (week starts on Saturday): 0
 */
const getFirstDayOfMonthIndex = (): number => {
  let firstDayOfMonthIndex = getDay(props.startOfMonthDate) - weekStartIndex;

  if (firstDayOfMonthIndex < 0) {
    firstDayOfMonthIndex += 7;
  }

  return firstDayOfMonthIndex;
};

const firstDayOfMonthIndex = getFirstDayOfMonthIndex();

const numberOfDaysInMonth = getDaysInMonth(props.startOfMonthDate);

const numberOfGridItems = numberOfDaysInMonth + firstDayOfMonthIndex;

const getDateForGridItem = (gridItemNumber: number): number =>
  addDays(
    props.startOfMonthDate,
    gridItemNumber - firstDayOfMonthIndex - 1,
  ).getTime();
</script>
