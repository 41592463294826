<template>
  <div class="relative min-h-[3rem]">
    <AppPopoverButton
      v-if="useAsPopoverButton"
      class="min-w-full p-3"
      :as="AppSecondaryButton"
      :button-text="buttonText"
    />
    <AppSecondaryButton
      v-else
      class="min-w-full p-3"
      :button-text="buttonText"
      @click="$emit('click')"
    />

    <AppSkeletonBox
      v-show="searchStore.isLoading"
      class="absolute inset-0 z-10 rounded"
    />
  </div>
</template>

<script setup lang="ts">
import { useSearchStore } from '@/search/search.store';
import AppPopoverButton from '@/ui/app-popover/AppPopoverButton.vue';
import AppSecondaryButton from '@/ui/app-secondary-button/AppSecondaryButton.vue';
import AppSkeletonBox from '@/ui/app-skeleton-box/AppSkeletonBox.vue';

withDefaults(
  defineProps<{
    buttonText: string;
    useAsPopoverButton?: boolean;
  }>(),
  {
    useAsPopoverButton: false,
  },
);

defineEmits(['click']);

const searchStore = useSearchStore();
</script>
