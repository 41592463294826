<template>
  <AvailabilityCalendarModal
    :is-open="unitSelectionStore.isSearchPropertyAvailabilityCalendarModalOpen"
    :check-in-date="searchStore.stayDates.checkInDate"
    :check-out-date="searchStore.stayDates.checkOutDate"
    :auto-select-stay-dates="autoSelectStayDates"
    :button-text="capitalize($t('search'))"
    :icon="IconSearch"
    @close="
      unitSelectionStore.isSearchPropertyAvailabilityCalendarModalOpen = false
    "
    @submit="
      () => {
        unitSelectionStore.isSearchPropertyAvailabilityCalendarModalOpen = false;
        updateSearchDates();
      }
    "
  />
</template>

<script setup lang="ts">
import { IconSearch } from '@tabler/icons-vue';
import AvailabilityCalendarModal from '@/availability-calendar/modal/AvailabilityCalendarModal.vue';
import { useSearchStore } from '@/search/search.store';
import { useLocaleString } from '@/string/locale-string.composable';
import { useUnitSelectionSearchPropertyAvailabilityCalendar } from '@/unit-selection-page/search-property-availability-calendar/unit-selection-search-property-availability-calendar.composable';
import { useUnitSelectionStore } from '@/unit-selection-page/unit-selection.store';

const searchStore = useSearchStore();
const unitSelectionStore = useUnitSelectionStore();

const { capitalize } = useLocaleString();

const { autoSelectStayDates, updateSearchDates } =
  useUnitSelectionSearchPropertyAvailabilityCalendar();
</script>
