import { update } from 'idb-keyval';
import { v4 as uuidv4 } from 'uuid';
import { IDB_KEY_USER_EVENT_IDENTIFIERS } from '@/idb';
import type { UserEventIdentifier } from '@/user-event/identifier/user-event-identifier';
import { UserEventIdentifierSchema } from '@/user-event/identifier/user-event-identifier';

export const useUserEventIdentifier = async (
  widgetId: string,
  propertyId: number,
): Promise<UserEventIdentifier> => {
  let userEventIdentifier = {
    widgetId: widgetId,
    propertyId: propertyId,
    uniqueUserId: uuidv4(),
    createdAtTimestamp: Date.now(),
  };

  await update(
    IDB_KEY_USER_EVENT_IDENTIFIERS,
    (userEventIdentifiers: UserEventIdentifier[] | undefined) => {
      if (!userEventIdentifiers) {
        return [userEventIdentifier];
      }

      const validUserEventIdentifiers = userEventIdentifiers.filter(
        (userEventIdentifier) =>
          UserEventIdentifierSchema.safeParse(userEventIdentifier).success,
      );

      const existingUserEventIdentifier = validUserEventIdentifiers.find(
        (userEventIdentifier) =>
          userEventIdentifier.widgetId === widgetId &&
          userEventIdentifier.propertyId === propertyId,
      );

      if (existingUserEventIdentifier) {
        userEventIdentifier = existingUserEventIdentifier;

        return validUserEventIdentifiers;
      }

      return [...validUserEventIdentifiers, userEventIdentifier];
    },
  );

  return userEventIdentifier;
};

export const deleteUserEventIdentifier = async ({
  uniqueUserId,
}: UserEventIdentifier): Promise<void> => {
  await update(
    IDB_KEY_USER_EVENT_IDENTIFIERS,
    (userEventIdentifiers: UserEventIdentifier[] | undefined) =>
      userEventIdentifiers
        ? userEventIdentifiers.filter(
            (userEventIdentifier) =>
              userEventIdentifier.uniqueUserId !== uniqueUserId,
          )
        : [],
  );
};
