import type {
  AdditionalTaxRateBasis,
  AdditionalTaxRateBasisDTO,
} from '@/tax/additional/rate/basis/additional-tax-rate-basis';
import type {
  AdditionalTaxRateCharge,
  AdditionalTaxRateChargeDTO,
} from '@/tax/additional/rate/charge/additional-tax-rate-charge';
import type {
  AdditionalTaxRateChargeLimit,
  AdditionalTaxRateChargeLimitDTO,
} from '@/tax/additional/rate/charge-limit/additional-tax-rate-charge-limit';

export enum AdditionalTaxRateFrequencyType {
  Nightly = 'nightly',
  Stay = 'stay',
}

export enum AdditionalTaxRateInclusionType {
  Include = 'include',
  IncludeInvoiceOnly = 'include_invoice_only',
  Exclude = 'exclude',
}

export interface AdditionalTaxRate {
  charge: AdditionalTaxRateCharge;
  chargeLimit: AdditionalTaxRateChargeLimit | undefined;
  basis: AdditionalTaxRateBasis;
  frequencyType: AdditionalTaxRateFrequencyType;
  inclusionType: AdditionalTaxRateInclusionType;
}

export interface AdditionalTaxRateDTO
  extends Omit<AdditionalTaxRate, 'charge' | 'chargeLimit' | 'basis'> {
  charge: AdditionalTaxRateChargeDTO;
  chargeLimit: AdditionalTaxRateChargeLimitDTO | null;
  basis: AdditionalTaxRateBasisDTO;
}
