<template>
  <div
    class="flex flex-col items-center gap-2 text-center font-bold sm:flex-row sm:items-start sm:text-start"
    :class="booking.isCancelled ? 'text-red-600' : 'text-green-700'"
  >
    <div class="hidden sm:block">
      <IconCircleX v-if="booking.isCancelled" :size="ICON_SIZE" />

      <IconCircleCheck v-else :size="ICON_SIZE" />
    </div>

    <div>
      <h1 class="break-anywhere text-3xl first-letter:capitalize sm:text-4xl">
        {{
          $t(
            booking.isCancelled
              ? 'yourBookingIsCancelledAtProperty'
              : 'yourBookingIsConfirmedAtProperty',
            {
              propertyName: property.name,
            },
          )
        }}
      </h1>

      <div class="text-lg capitalize sm:text-xl">
        {{
          $t('bookingReference', {
            bookingReference: `${booking.prefix}${booking.id}`,
          })
        }}
      </div>

      <div class="mt-3 font-normal">
        <BookingConfirmationAssuranceStayDates
          v-if="booking.isCancelled"
          class="text-slate-800"
        />

        <i18n-t
          v-else
          keypath="thanksForBookingACopyHasBeenSent"
          data-test="BookingConfirmationHeader__emailReceipt"
          tag="div"
          scope="global"
          class="break-anywhere"
        >
          <template #guestName>{{ bookingGuest.forename }}</template>

          <template #guestEmail>
            <span class="font-bold">
              {{ bookingGuest.emailAddress }}
            </span>
          </template>
        </i18n-t>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { IconCircleCheck, IconCircleX } from '@tabler/icons-vue';
import { storeToRefs } from 'pinia';
import BookingConfirmationAssuranceStayDates from '@/booking-confirmation-page/assurances/BookingConfirmationAssuranceStayDates.vue';
import { useBookingConfirmationStore } from '@/booking-confirmation-page/booking-confirmation.store';

const ICON_SIZE = 100;

const { booking, property, bookingGuest } = storeToRefs(
  useBookingConfirmationStore(),
);
</script>
