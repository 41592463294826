import type {
  SmokingPolicy,
  SmokingPolicyDTO,
} from '@/property/smoking-policy/smoking-policy';

export const mapSmokingPolicyFromDTO = (
  smokingPolicyDTO: SmokingPolicyDTO,
): SmokingPolicy => ({
  isAllowedInRooms: smokingPolicyDTO.isAllowedInRooms,
  isAllowedInPublicAreas: smokingPolicyDTO.isAllowedInPublicAreas,
  isAllowedInOpenAir: smokingPolicyDTO.isAllowedInOpenAir,
});
