<template>
  <div class="text-lg">
    <p v-if="!searchStore.isLoading" class="font-semibold">
      {{ property.name }}
    </p>

    <AppSkeletonText v-else class="w-[70%]" />
  </div>
</template>

<script setup lang="ts">
import type { Property } from '@/property/property';
import { useSearchStore } from '@/search/search.store';
import AppSkeletonText from '@/ui/app-skeleton-text/AppSkeletonText.vue';

defineProps<{ property: Property }>();

const searchStore = useSearchStore();
</script>
