import type {
  CardPaymentMethodPolicyCardCharge,
  CardPaymentMethodPolicyCardChargeDTO,
} from '@/property/booking-policy/payment-method-policy/card/charge/card-payment-method-policy-card-charge';
import { CardPaymentMethodPolicyCardChargeType } from '@/property/booking-policy/payment-method-policy/card/charge/card-payment-method-policy-card-charge';

export const mapCardPaymentMethodPolicyCardChargeFromDTO = (
  cardPaymentMethodPolicyCardChargeDTO: CardPaymentMethodPolicyCardChargeDTO,
): CardPaymentMethodPolicyCardCharge => {
  const { chargeType } = cardPaymentMethodPolicyCardChargeDTO;

  switch (chargeType) {
    case CardPaymentMethodPolicyCardChargeType.Free:
      return { chargeType: CardPaymentMethodPolicyCardChargeType.Free };
    case CardPaymentMethodPolicyCardChargeType.Monetary:
      return {
        chargeType: CardPaymentMethodPolicyCardChargeType.Monetary,
        amount: Number(cardPaymentMethodPolicyCardChargeDTO.amount),
      };
    case CardPaymentMethodPolicyCardChargeType.Percentage:
      return {
        chargeType: CardPaymentMethodPolicyCardChargeType.Percentage,
        percentage: Number(cardPaymentMethodPolicyCardChargeDTO.percentage),
      };
  }
};
