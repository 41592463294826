<template>
  <div ref="availabilityCalendar" class="flex items-stretch">
    <AvailabilityCalendarNavigationButton
      :is-left-button="true"
      :is-disabled="!canNavigateLeft"
      @click="onNavigateLeft"
    />

    <div class="relative flex shrink-0 gap-12 overflow-hidden">
      <TransitionGroup :name="`slide-${slideAnimationDirection}`">
        <div
          v-for="startOfMonthDate in startOfMonthDatesToShow"
          :key="startOfMonthDate"
          class="horizontalAvailabilityCalendar__slideMoveTransition my-2"
        >
          <AvailabilityCalendarMonth
            :start-of-month-date="startOfMonthDate"
            show-day-names-heading
          />
        </div>
      </TransitionGroup>
    </div>

    <AvailabilityCalendarNavigationButton
      :is-left-button="false"
      @click="onNavigateRight"
    />
  </div>
</template>

<script setup lang="ts">
import {
  breakpointsTailwind,
  SwipeDirection,
  useBreakpoints,
  useSwipe,
} from '@vueuse/core';
import { format, isFuture } from 'date-fns';
import { computed, ref, watch } from 'vue';
import { useAvailabilityCalendarStore } from '@/availability-calendar/availability-calendar.store';
import AvailabilityCalendarMonth from '@/availability-calendar/AvailabilityCalendarMonth.vue';
import AvailabilityCalendarNavigationButton from '@/availability-calendar/navigation-button/AvailabilityCalendarNavigationButton.vue';

const NUM_OF_MONTHS_TO_MOVE = 1;

const SLIDE_ANIMATION_DIRECTION_LEFT = 'left';
const SLIDE_ANIMATION_DIRECTION_RIGHT = 'right';

const props = defineProps<{
  startOfMonthDates: number[];
}>();

const emit =
  defineEmits<
    (
      eventName: 'navigateLeft' | 'navigateRight',
      numOfMonthsToMove: number,
      updateDate: boolean,
    ) => void
  >();

watch(
  () => props.startOfMonthDates,
  async () => {
    await loadCalendarAvailability();
  },
);

const slideAnimationDirection = ref<string>();
const availabilityCalendar = ref<HTMLElement | null>(null);
const isSingleMonthView = useBreakpoints(breakpointsTailwind).smaller('lg');

const { load } = useAvailabilityCalendarStore();

const startOfMonthDatesToShow = computed(() => {
  const firstMonth = props.startOfMonthDates[0];
  const secondMonth = props.startOfMonthDates[1];
  return isSingleMonthView.value ? [firstMonth!] : [firstMonth!, secondMonth!];
});

const canNavigateLeft = computed(() => {
  const firstVisibleMonthInList = props.startOfMonthDates[0];
  return firstVisibleMonthInList && isFuture(firstVisibleMonthInList);
});

const onNavigateLeft = () => {
  slideAnimationDirection.value = SLIDE_ANIMATION_DIRECTION_RIGHT;
  emit('navigateLeft', NUM_OF_MONTHS_TO_MOVE, true);
};

const onNavigateRight = () => {
  slideAnimationDirection.value = SLIDE_ANIMATION_DIRECTION_LEFT;
  emit('navigateRight', NUM_OF_MONTHS_TO_MOVE, true);
};

const loadCalendarAvailability = async () => {
  const startDate = props.startOfMonthDates.at(0)!;
  const endDate = props.startOfMonthDates.at(-1)!;
  await load(format(startDate, 'yyyy-MM-dd'), format(endDate, 'yyyy-MM-dd'));
};

const { isSwiping, direction } = useSwipe(availabilityCalendar);

watch(isSwiping, (swiping) => {
  if (swiping) {
    if (direction.value === SwipeDirection.LEFT) {
      onNavigateRight();
    } else if (
      direction.value === SwipeDirection.RIGHT &&
      canNavigateLeft.value
    ) {
      onNavigateLeft();
    }
  }
});
</script>

<style scoped>
.horizontalAvailabilityCalendar__slideMoveTransition {
  transition: transform 150ms linear;
}

.slide-right-enter-from,
.slide-left-leave-to {
  transform: translateX(-100%);
  transition: transform 150ms linear;
}

.slide-left-enter-from {
  transform: translateX(100%);
  transition: transform 150ms linear;
}

.slide-right-leave-active {
  position: absolute;
  transform: translateX(200%);
  transition: transform 150ms linear;
}

.slide-left-leave-active {
  position: absolute;
  transition: transform 150ms linear;
}
</style>
