<template>
  <div class="w-full">
    <div class="text-lg">
      <p
        v-if="!searchStore.isLoading"
        class="break-anywhere font-semibold"
        translate="yes"
      >
        {{ unit.name }}
      </p>
      <AppSkeletonText v-else class="w-[70%]" />
    </div>

    <div v-if="!searchStore.isLoading" class="text-base">
      <AppCollapsibleText
        v-if="unit.description"
        class="break-anywhere"
        :text="unit.description"
        :max-lines="3"
        translate="yes"
      />

      <template v-if="presetUnitFacilitiesDisplayText">
        <div class="mt-8 font-semibold">
          {{ toTitleCase($t('facilities')) }}:
        </div>
        <AppCollapsibleText
          :text="presetUnitFacilitiesDisplayText"
          :max-lines="2"
        />
      </template>
    </div>

    <div v-else>
      <AppSkeletonText class="w-full" />
      <AppSkeletonText class="w-full" />
      <AppSkeletonText class="w-full" />
      <AppSkeletonText class="w-full" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useUnit } from '@/property/unit/unit.composable';
import { useSearchStore } from '@/search/search.store';
import { useLocaleString } from '@/string/locale-string.composable';
import AppCollapsibleText from '@/ui/app-collapsible-text/AppCollapsibleText.vue';
import AppSkeletonText from '@/ui/app-skeleton-text/AppSkeletonText.vue';

const props = defineProps<{
  unitId: number;
}>();

const searchStore = useSearchStore();

const { toTitleCase } = useLocaleString();

const unit = computed(() =>
  searchStore.activePropertyOverStay.findUnitById(props.unitId),
);

const { presetUnitFacilitiesDisplayText } = useUnit(unit.value);
</script>
