<template>
  <div
    ref="container"
    :class="{ 'animate-shake': shouldShake }"
    @animationend="shouldShake = false"
  >
    <slot></slot>

    <AppFormInputValidationErrorMessages
      v-show="canShowErrorMessages && errorMessages.length > 0"
      :error-messages="errorMessages"
      class="mt-2"
    />
  </div>
</template>

<script setup lang="ts">
import {
  computed,
  onMounted,
  onUnmounted,
  reactive,
  readonly,
  ref,
  useTemplateRef,
} from 'vue';
import { generateUniqueId } from '@/generator/generator.utilities';
import { useProvideInputValidationGroup } from '@/ui/app-form/app-form-input-validation-group.composable';
import { useForm } from '@/ui/app-form/app-form.composable';
import AppFormInputValidationErrorMessages from '@/ui/app-form/AppFormInputValidationErrorMessages.vue';

const id = generateUniqueId();

const { canShowErrorMessages, unregister, register } = useForm();
const { inputs } = useProvideInputValidationGroup();

const container = useTemplateRef('container');
const shouldShake = ref(false);

const errorMessages = computed(() =>
  inputs.value.map(({ errorMessages }) => errorMessages).flat(),
);

const validate = () => {
  let isAnyInputInvalid = false;

  for (const input of inputs.value) {
    const isValid = input.validate();

    if (!isValid) {
      isAnyInputInvalid = true;
    }
  }

  if (isAnyInputInvalid) {
    shouldShake.value = true;
  }

  return !isAnyInputInvalid;
};

onMounted(() => {
  register(
    reactive({
      id,
      validate,
      errorMessages: readonly(errorMessages),
      container,
    }),
  );
});

onUnmounted(() => {
  unregister(id);
});
</script>
