<template>
  <div class="space-y-8">
    <div
      v-for="(bookingUnit, index) in editableBooking.activeBookingUnits"
      :key="bookingUnit.id"
    >
      <BookingConfirmationEditBookingDetailsUnitHeader
        class="mb-2"
        :unit-name="bookingUnit.unitName"
        :unit-number="
          editableBooking.activeBookingUnits.length > 1 ? index + 1 : undefined
        "
      />

      <BookingConfirmationEditBookingDetailsUnit
        v-model="editableBooking.activeBookingUnits[index] as ActiveBookingUnit"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import type { ActiveBookingUnit } from '@/booking/unit/active/active-booking-unit';
import { useBookingConfirmationStore } from '@/booking-confirmation-page/booking-confirmation.store';
import BookingConfirmationEditBookingDetailsUnitHeader from '@/booking-confirmation-page/edit-booking-details/unit//header/BookingConfirmationEditBookingDetailsUnitHeader.vue';
import BookingConfirmationEditBookingDetailsUnit from '@/booking-confirmation-page/edit-booking-details/unit/BookingConfirmationEditBookingDetailsUnit.vue';

const bookingConfirmationStore = useBookingConfirmationStore();

const { editableBooking } = storeToRefs(bookingConfirmationStore);
</script>
