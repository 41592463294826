<template>
  <UnitSelectionAdditionalPropertiesItem
    v-for="property of sortedUnavailableAdditionalProperties"
    :key="property.id"
    :property
    :is-available="false"
    :banner-rate="null"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useSearchStore } from '@/search/search.store';
import UnitSelectionAdditionalPropertiesItem from '@/unit-selection-page/additional-properties/item/UnitSelectionAdditionalPropertiesItem.vue';
import { compareWidgetPropertiesByRank } from '@/widget/widget.utilities';

const searchStore = useSearchStore();

const unavailableAdditionalProperties = computed(() =>
  searchStore.unavailableAdditionalPropertiesOverStay.map(
    ({ property }) => property,
  ),
);

const sortedUnavailableAdditionalProperties = computed(() =>
  [...unavailableAdditionalProperties.value].sort((propertyA, propertyB) =>
    compareWidgetPropertiesByRank(searchStore.widget, propertyA, propertyB),
  ),
);
</script>
