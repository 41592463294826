import { useRouteParams, useRouteQuery } from '@vueuse/router';

export const useRoute = () => {
  const widgetIdRouteParam = useRouteParams<string>('widgetId');

  const propertyIdRouteParam = useRouteParams<
    string | undefined,
    number | undefined
  >('propertyId', undefined, {
    mode: 'push',
    transform: (propertyId) => (propertyId ? Number(propertyId) : undefined),
  });

  const bookingIdRouteParam = useRouteParams<string>('bookingId');

  const codeQueryParam = useRouteQuery<string | undefined>('promocode');

  const bookingAccessTokenQueryParam = useRouteQuery<string>('access_token');

  return {
    widgetIdRouteParam,
    propertyIdRouteParam,
    bookingIdRouteParam,
    codeQueryParam,
    bookingAccessTokenQueryParam,
  };
};
