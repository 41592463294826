import type { PropertyAvailability } from '@/availability/property-availability/property-availability';
import { findPropertyAvailabilityMealAvailabilityByDate } from '@/availability/property-availability/property-availability.utilities';
import type { MealType } from '@/property/meal/meal';
import type { DiscountOfferAdjustment } from '@/property/offer/discount-offer/adjustment/discount-offer-adjustment';
import { applyDiscountOfferAdjustmentToValue } from '@/property/offer/discount-offer/adjustment/discount-offer-adjustment.utilities';
import type { DiscountOfferMealAdjustment } from '@/property/offer/discount-offer/adjustment/meal-adjustment/discount-offer-meal-adjustment';
import type { DiscountOfferUnitAdjustment } from '@/property/offer/discount-offer/adjustment/unit-adjustment/discount-offer-unit-adjustment';
import type { DiscountOffer } from '@/property/offer/discount-offer/discount-offer';
import { OfferApplicableStayDaysType } from '@/property/offer/offer';
import {
  doesWayToSellApplyToOffer,
  isAdvanceBookingDateWithinOfferBounds,
  isDateApplicableOfferDayOfWeek,
  isDateWithinOfferFromToBounds,
  isOfferClosedOutOnAnyDate,
  isOfferClosedOutOnDate,
  isStayLengthWithinOfferBounds,
} from '@/property/offer/offer.utilities';
import type { Unit } from '@/property/unit/unit';
import type { NightlyRates } from '@/rates/nightly-rates/nightly-rates';
import type { StayDates } from '@/stay-dates/stay-dates';
import {
  getStayLengthFromStayDates,
  getNightsOfStayFromStayDates,
} from '@/stay-dates/stay-dates.utilities';

export const findDiscountOfferUnitAdjustmentByUnitId = (
  { unitAdjustments }: DiscountOffer,
  unitId: number,
): DiscountOfferUnitAdjustment | undefined =>
  unitAdjustments.find((unitAdjustment) => unitAdjustment.unitId === unitId);

export const findDiscountOfferMealAdjustmentByMealType = (
  { mealAdjustments }: DiscountOffer,
  mealType: MealType,
): DiscountOfferMealAdjustment | undefined =>
  mealAdjustments.find(
    (mealAdjustment) => mealAdjustment.mealType === mealType,
  );

export const findDiscountOfferUnitAdjustmentByUnitIdOrFail = (
  discountOffer: DiscountOffer,
  unitId: number,
): DiscountOfferAdjustment => {
  const unitAdjustment = findDiscountOfferUnitAdjustmentByUnitId(
    discountOffer,
    unitId,
  );

  if (!unitAdjustment) {
    throw new Error(
      `Could not find discount offer unit adjustment for unit ${unitId}`,
    );
  }

  return unitAdjustment;
};

export const findDiscountOfferMealAdjustmentByMealTypeOrFail = (
  discountOffer: DiscountOffer,
  mealType: MealType,
): DiscountOfferMealAdjustment => {
  const mealAdjustment = findDiscountOfferMealAdjustmentByMealType(
    discountOffer,
    mealType,
  );

  if (!mealAdjustment) {
    throw new Error(
      `Could not find discount offer meal adjustment for meal '${mealType}'`,
    );
  }

  return mealAdjustment;
};

/**
 * For a discount offer to be available to a unit it must:
 * - Have a corresponding unit adjustment.
 * - Have no meal adjustments if that unit is self-catering.
 */
export const discountOfferAppliesToUnit = (
  discountOffer: DiscountOffer,
  unit: Unit,
): boolean =>
  !!findDiscountOfferUnitAdjustmentByUnitId(discountOffer, unit.id) &&
  (discountOffer.mealAdjustments.length === 0 || !unit.hasNoIncludedMeals);

export const discountOfferAppliesToWayToSell = (
  discountOffer: DiscountOffer,
  wayToSellId: number,
): boolean => doesWayToSellApplyToOffer(discountOffer, wayToSellId);

export const discountOfferAppliesToMeal = (
  discountOffer: DiscountOffer,
  mealType: MealType,
): boolean =>
  !!findDiscountOfferMealAdjustmentByMealType(discountOffer, mealType);

export const isDiscountOfferApplicableOnStayDates = (
  discountOffer: DiscountOffer,
  propertyAvailability: PropertyAvailability,
  stayDates: StayDates,
  timezone: string,
): boolean =>
  isStayLengthWithinOfferBounds(
    discountOffer,
    getStayLengthFromStayDates(stayDates),
  ) &&
  isAdvanceBookingDateWithinOfferBounds(
    discountOffer,
    stayDates.checkInDate,
    timezone,
  ) &&
  allDiscountOfferMealsAreAvailable(
    discountOffer,
    propertyAvailability,
    stayDates,
  ) &&
  isDiscountOfferApplicableForStayDaysType(
    discountOffer,
    propertyAvailability,
    stayDates,
  );

const isDiscountOfferApplicableForStayDaysType = (
  discountOffer: DiscountOffer,
  propertyAvailability: PropertyAvailability,
  stayDates: StayDates,
): boolean => {
  switch (discountOffer.applicableStayDaysType) {
    case OfferApplicableStayDaysType.Arrivals:
      return isDiscountOfferApplicableForArrivalsType(
        discountOffer,
        propertyAvailability,
        stayDates,
      );
    case OfferApplicableStayDaysType.NightOfStay:
      return isDiscountOfferApplicableForNightsOfStayType(
        discountOffer,
        propertyAvailability,
        stayDates,
      );
  }
};

const isDiscountOfferApplicableForNightsOfStayType = (
  discountOffer: DiscountOffer,
  propertyAvailability: PropertyAvailability,
  stayDates: StayDates,
): boolean =>
  getNightsOfStayFromStayDates(stayDates).some(
    (nightOfStay) =>
      isDateWithinOfferFromToBounds(discountOffer, nightOfStay) &&
      isDateApplicableOfferDayOfWeek(discountOffer, nightOfStay) &&
      !isOfferClosedOutOnDate(discountOffer, propertyAvailability, nightOfStay),
  );

const isDiscountOfferApplicableForArrivalsType = (
  discountOffer: DiscountOffer,
  propertyAvailability: PropertyAvailability,
  stayDates: StayDates,
): boolean =>
  isDateWithinOfferFromToBounds(discountOffer, stayDates.checkInDate) &&
  isDateApplicableOfferDayOfWeek(discountOffer, stayDates.checkInDate) &&
  !isOfferClosedOutOnAnyDate(
    discountOffer,
    propertyAvailability,
    getNightsOfStayFromStayDates(stayDates),
  );

const allDiscountOfferMealsAreAvailable = (
  { mealAdjustments }: DiscountOffer,
  propertyAvailability: PropertyAvailability,
  stayDates: StayDates,
): boolean =>
  mealAdjustments.every((mealAdjustment) =>
    getNightsOfStayFromStayDates(stayDates).every((nightOfStay) => {
      const mealAvailability = findPropertyAvailabilityMealAvailabilityByDate(
        propertyAvailability,
        mealAdjustment.mealType,
        nightOfStay,
      );

      return (
        mealAvailability?.rate !== undefined && !mealAvailability.isClosedOut
      );
    }),
  );

export const applyDiscountOfferUnitAdjustmentToNightlyRates = (
  discountOffer: DiscountOffer,
  propertyAvailability: PropertyAvailability,
  nightlyRates: NightlyRates,
  unitId: number,
): NightlyRates => {
  const unitAdjustment = findDiscountOfferUnitAdjustmentByUnitIdOrFail(
    discountOffer,
    unitId,
  );

  return applyDiscountOfferAdjustmentToNightlyRates(
    discountOffer,
    propertyAvailability,
    nightlyRates,
    unitAdjustment,
  );
};

const applyDiscountOfferAdjustmentToNightlyRates = (
  discountOffer: DiscountOffer,
  propertyAvailability: PropertyAvailability,
  nightlyRates: NightlyRates,
  adjustment: DiscountOfferAdjustment,
): NightlyRates =>
  nightlyRates.map((nightlyRate) =>
    canApplyDiscountOfferAdjustmentOnDate(
      discountOffer,
      propertyAvailability,
      nightlyRate.date,
    )
      ? {
          ...nightlyRate,
          rate: applyDiscountOfferAdjustmentToValue(
            adjustment,
            nightlyRate.rate,
            discountOffer.discountType,
          ),
        }
      : nightlyRate,
  );

export const canApplyDiscountOfferAdjustmentOnDate = (
  discountOffer: DiscountOffer,
  propertyAvailability: PropertyAvailability,
  date: string,
): boolean =>
  discountOffer.applicableStayDaysType === OfferApplicableStayDaysType.Arrivals
    ? /**
       * If the offer stay days type is "Arrivals", we will only not apply the offer
       * if it's closed out, otherwise we'll assume it's valid.
       */
      !isOfferClosedOutOnDate(discountOffer, propertyAvailability, date)
    : // Otherwise, we'll need to apply all checks
      isDateWithinOfferFromToBounds(discountOffer, date) &&
      isDateApplicableOfferDayOfWeek(discountOffer, date) &&
      !isOfferClosedOutOnDate(discountOffer, propertyAvailability, date);
