import { differenceInDays } from 'date-fns';
import { useI18n } from 'vue-i18n';
import { AvailabilityStatusType } from '@/availability-calendar/availability-status/availability-status';
import type { AvailabilityStatus } from '@/availability-calendar/availability-status/availability-status';
import { getMinimumStayFromAvailabilityStatus } from '@/availability-calendar/availability-status/availability-status.utilities';

export const useAvailabilityCalendarStatusGenerator = () => {
  const { t, d } = useI18n();

  const generateCalendarStatusHeading = (
    checkInDate: number | undefined,
    checkOutDate: number | undefined,
  ): string => {
    if (checkInDate === undefined && checkOutDate === undefined) {
      return t('selectCheckInDate');
    }
    if (checkInDate && checkOutDate === undefined) {
      return t('selectCheckOutDate');
    }
    return t('lengthOfNightStay', {
      count: differenceInDays(checkOutDate!, checkInDate!),
    });
  };

  const generateCalendarStatusSubHeading = (
    date: number | undefined,
    checkInDate: number | undefined,
    checkOutDate: number | undefined,
    availabilityStatus: AvailabilityStatus | undefined,
  ): string => {
    if (checkInDate !== undefined && checkOutDate !== undefined) {
      return `${d(checkInDate, {
        day: 'numeric',
        month: 'short',
      })} - ${d(checkOutDate, {
        day: 'numeric',
        month: 'short',
      })}`;
    }

    const minimumStayLength = availabilityStatus
      ? getMinimumStayFromAvailabilityStatus(availabilityStatus)
      : undefined;

    if (
      checkInDate === date ||
      typeof availabilityStatus === 'undefined' ||
      availabilityStatus.isAvailable
    ) {
      if (minimumStayLength && minimumStayLength > 1) {
        return t('checkInHasMinimumStay', {
          date: d(date!, {
            day: 'numeric',
            month: 'short',
          }),
          minimumStay: minimumStayLength,
        });
      }
      return t('addYourTravelDates');
    }
    if (
      [
        AvailabilityStatusType.SoldOut,
        AvailabilityStatusType.NotAvailable,
      ].includes(availabilityStatus.reason.type)
    ) {
      return t('dateIsUnavailable', {
        date: d(date!, {
          day: 'numeric',
          month: 'short',
        }),
      });
    }
    if (
      availabilityStatus.reason.type === AvailabilityStatusType.ClosedToArrival
    ) {
      return t('dateIsCheckoutOnly', {
        date: d(date!, {
          day: 'numeric',
          month: 'short',
        }),
      });
    }
    if (
      availabilityStatus.reason.type ===
      AvailabilityStatusType.ClosedToDeparture
    ) {
      return t('dateIsCheckinOnly', {
        date: d(date!, {
          day: 'numeric',
          month: 'short',
        }),
      });
    }
    if (availabilityStatus.reason.type === AvailabilityStatusType.MinimumStay) {
      return t('checkInHasMinimumStay', {
        date: d(checkInDate!, {
          day: 'numeric',
          month: 'short',
        }),
        minimumStay: minimumStayLength,
      });
    }
    return '';
  };

  return {
    generateCalendarStatusHeading,
    generateCalendarStatusSubHeading,
  };
};
