import type { Ref } from 'vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import type { Property } from '@/property/property';
import { useTimeDisplayText } from '@/time/time-display-text.composable';

export const useCheckInPolicyTermsAndConditionsDisplayText = (
  property: Ref<Property>,
) => {
  const { t } = useI18n();

  const checkInPolicy = computed(() => property.value.checkInPolicy);

  const earliestCheckInTimeDisplayText = useTimeDisplayText(
    computed(() => checkInPolicy.value.earliestCheckInTime),
  );

  const latestCheckInTimeDisplayText = useTimeDisplayText(
    computed(() => checkInPolicy.value.latestCheckInTime),
  );

  const latestCheckOutTimeDisplayText = useTimeDisplayText(
    computed(() => checkInPolicy.value.latestCheckOutTime),
  );

  return computed(() =>
    t('checkInOutTimeRange', {
      earliestCheckInTime: earliestCheckInTimeDisplayText.value,
      latestCheckInTime: latestCheckInTimeDisplayText.value,
      latestCheckOutTime: latestCheckOutTimeDisplayText.value,
    }),
  );
};
