import type {
  OccupancyLimits,
  OccupancyLimitsDTO,
} from '@/property/occupancy-limits/occupancy-limits';

export const mapOccupancyLimitsFromDTO = (
  occupancyLimitsDTO: OccupancyLimitsDTO,
): OccupancyLimits => ({
  maximumNumberOfAdults: occupancyLimitsDTO.maximumNumberOfAdults,
  maximumNumberOfNonInfantChildren:
    occupancyLimitsDTO.maximumNumberOfNonInfantChildren,
  maximumNumberOfInfantChildren:
    occupancyLimitsDTO.maximumNumberOfInfantChildren,
});
