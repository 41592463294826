import { cloneDeep } from 'lodash-es';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import type { BookingSource } from '@/booking-source/booking-source';
import { BookingSourceType } from '@/booking-source/booking-source';
import { usePersistableBookingSource } from '@/booking-source/persistable/persistable-booking-source.composable';
import { useQueryParams } from '@/url/query-params/query-params.composable';

export const useBookingSourceStore = defineStore('booking-source', () => {
  const bookingSource = ref(generateDefaultBookingSource());

  usePersistableBookingSource(bookingSource);

  const isGoogleSource = computed(
    () =>
      !!bookingSource.value &&
      [
        BookingSourceType.GoogleHPA,
        BookingSourceType.GoogleZero,
        BookingSourceType.GoogleVR,
      ].includes(bookingSource.value.type),
  );

  const isGoogleVRUnit = (unitId: number): boolean =>
    bookingSource.value?.type === BookingSourceType.GoogleVR &&
    bookingSource.value.unitId === unitId;

  return { bookingSource, isGoogleSource, isGoogleVRUnit };
});

/**
 * Generates the default booking source from the corresponding query parameter.
 *
 * Note the side-effect of clearing the query parameter once we've read it.
 * This is to ensure the booking source is neither:
 * - carried across to other users when links are shared, or
 * - 'stored' indefinitely in the URL.
 */
const generateDefaultBookingSource = (): BookingSource | undefined => {
  const { bookingSourceQueryParam } = useQueryParams();

  const defaultBookingSource = cloneDeep(bookingSourceQueryParam.value);

  bookingSourceQueryParam.value = undefined;

  return defaultBookingSource;
};
