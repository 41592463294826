import pluralize from 'pluralize';
import type { ComputedRef } from 'vue';
import { computed } from 'vue';
import i18n from '@/i18n';
import { Locale } from '@/i18n/locales';
import { isLocaleGenderSpecific } from '@/i18n/locales/gender/gender';
import {
  UNIT_TYPE_NAME_UNIT,
  type UnitType,
} from '@/property/unit/type/unit-type';
import { findUnitTypeNameTranslationKeys } from '@/property/unit/type/unit-type.utilities';

export const useUnitType = (unitType: ComputedRef<UnitType>) => {
  const { t, locale } = i18n.global;

  const basicTranslationKey = computed(() =>
    unitType.value.isKnown
      ? unitType.value.basicTranslationKey
      : UNIT_TYPE_NAME_UNIT,
  );

  const unitTypeNamePlural = computed(() =>
    canSkipTranslation()
      ? pluralize(unitType.value.name)
      : t(basicTranslationKey.value, {
          count: 2,
        }),
  );

  const unitTypeName = computed(() =>
    canSkipTranslation() ? unitType.value.name : t(basicTranslationKey.value),
  );

  const unitTypeGender = computed(() =>
    isLocaleGenderSpecific(locale.value)
      ? unitType.value.isKnown
        ? unitType.value.genders[locale.value]
        : findUnitTypeNameTranslationKeys(UNIT_TYPE_NAME_UNIT).genders[
            locale.value
          ]
      : undefined,
  );

  /**
   * If the unit type does not have a translatable name, but we are currently
   * using the `enGB` locale - we can "skip" direct translation.
   */
  const canSkipTranslation = (): boolean =>
    !unitType.value.isKnown && locale.value === Locale.enGB;

  return {
    unitTypeName,
    unitTypeNamePlural,
    unitTypeGender,
  };
};
