<template>
  <AppModal :is-open="sagePayCheckoutStore.is3DSModalOpen">
    <AppModalDialog class="sm:max-w-2xl">
      <SagePayCheckout3DS
        :acs-url="bookingCreateSagePay3DSRequiredResult.acsUrl"
        :transaction-id="bookingCreateSagePay3DSRequiredResult.transactionId"
        :vendor-tx-code="bookingCreateSagePay3DSRequiredResult.vendorTxCode"
        :c-req="bookingCreateSagePay3DSRequiredResult.cReq"
      />
    </AppModalDialog>
  </AppModal>
</template>

<script setup lang="ts">
import type { BookingCreateSagePay3DSRequiredResult } from '@/booking/create/result/sage-pay-3ds-required/booking-create-sage-pay-3ds-required-result';
import { useSagePayCheckoutStore } from '@/payments-page/sage-pay-checkout/sage-pay-checkout.store';
import SagePayCheckout3DS from '@/payments-page/sage-pay-checkout/SagePayCheckout3DS.vue';
import AppModal from '@/ui/app-modal/AppModal.vue';
import AppModalDialog from '@/ui/app-modal/AppModalDialog.vue';

defineProps<{
  bookingCreateSagePay3DSRequiredResult: BookingCreateSagePay3DSRequiredResult;
}>();

const sagePayCheckoutStore = useSagePayCheckoutStore();
</script>
