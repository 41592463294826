<template>
  <AppFormInputValidationGroup>
    <GuestDetailsFormUnitLeadNameInput v-model="unitItineraryItem" />
  </AppFormInputValidationGroup>

  <AppFormInputValidationGroup
    v-if="unitItineraryItemStore.unit.hasTwinDoubleBedOption"
    class="mt-4"
  >
    <GuestDetailsFormTwinDoubleBedOptionInput v-model="unitItineraryItem" />
  </AppFormInputValidationGroup>
</template>

<script setup lang="ts">
import type { UnitItineraryItem } from '@/booking-itinerary/booking-itinerary';
import { useUnitItineraryItemStore } from '@/booking-itinerary/unit-itinerary-item/store/unit-itinerary-item.store';
import GuestDetailsFormUnitLeadNameInput from '@/guest-details-page/form/unit/lead-name/GuestDetailsFormUnitLeadNameInput.vue';
import GuestDetailsFormTwinDoubleBedOptionInput from '@/guest-details-page/form/unit/twin-double-bed-option/GuestDetailsFormTwinDoubleBedOptionInput.vue';
import AppFormInputValidationGroup from '@/ui/app-form/AppFormInputValidationGroup.vue';

const unitItineraryItem = defineModel<UnitItineraryItem>({ required: true });

const unitItineraryItemStore = useUnitItineraryItemStore(
  unitItineraryItem.value.id,
);
</script>
