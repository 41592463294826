import type { PropertyAvailability } from '@/availability/property-availability/property-availability';
import type { Occupancy } from '@/occupancy/occupancy';
import type { PrivateRateOverride } from '@/private-rate-override/private-rate-override';
import type { SupplementalMeal } from '@/property/meal/supplemental-meal/supplemental-meal';
import type { Offer } from '@/property/offer/offer';
import type { Promocode } from '@/property/promocode/promocode';
import type { Unit } from '@/property/unit/unit';
import type { WayToSell } from '@/property/way-to-sell/way-to-sell';
import { applyWayToSellToNightlyRates } from '@/property/way-to-sell/way-to-sell.utilities';
import { createStandardMealsRates } from '@/rates/meal-rates/meal-rates.utilities';
import type { NightlyRates } from '@/rates/nightly-rates/nightly-rates';
import type { OfferRates } from '@/rates/offer-rates/offer-rates';
import {
  calculatePrivateRatesOnUnitNightlyRates,
  calculatePrivateRatesOnWayToSellNightlyRates,
} from '@/rates/private-rates/private-rates.utilities';
import { createPromocodeRates } from '@/rates/promocode-rates/promocode-rates.utilities';
import type { UnitRateAdjustments } from '@/rates/unit-rate-adjustments/unit-rate-adjustments';
import {
  calculateUnitOfferRatesOnNightlyRates,
  createStandardNightlyRates,
} from '@/rates/unit-rates/unit-rates.utilities';
import { calculateWayToSellOfferRatesOnNightlyRates } from '@/rates/way-to-sell-rates/way-to-sell-rates.utilities';
import type { StayDates } from '@/stay-dates/stay-dates';
import { getNightsOfStayFromStayDates } from '@/stay-dates/stay-dates.utilities';

export const calculateUnitRateAdjustments = (
  unit: Unit,
  wayToSell: WayToSell | undefined,
  offer: Offer | undefined,
  promocode: Promocode | undefined,
  privateRateOverride: PrivateRateOverride | undefined,
  selectedMeals: SupplementalMeal[],
  propertyAvailability: PropertyAvailability,
  stayDates: StayDates,
  occupancy: Occupancy,
): UnitRateAdjustments => {
  let standardNightlyRates = createStandardNightlyRates(
    unit.id,
    propertyAvailability,
    getNightsOfStayFromStayDates(stayDates),
  );

  if (wayToSell) {
    standardNightlyRates = applyWayToSellToNightlyRates(
      wayToSell,
      standardNightlyRates,
      propertyAvailability,
    );
  }

  const standardMealsRates = createStandardMealsRates(
    selectedMeals,
    propertyAvailability,
    stayDates,
    occupancy,
  );

  const offerRates = offer
    ? getOfferAdjustedUnitRateAdjustments(
        unit,
        wayToSell?.id,
        offer,
        standardNightlyRates,
        promocode,
        propertyAvailability,
        stayDates,
        occupancy,
        selectedMeals,
      )
    : undefined;

  const promocodeRates = promocode
    ? createPromocodeRates(promocode, standardNightlyRates)
    : undefined;

  const privateRates = privateRateOverride
    ? wayToSell
      ? calculatePrivateRatesOnWayToSellNightlyRates(
          privateRateOverride,
          wayToSell.id,
          standardNightlyRates,
        )
      : calculatePrivateRatesOnUnitNightlyRates(
          privateRateOverride,
          unit.id,
          standardNightlyRates,
        )
    : undefined;

  return {
    standardNightlyRates,
    standardMealsRates,
    offerNightlyRates: offerRates,
    standardPromocodeRates: promocodeRates,
    standardPrivateRates: privateRates,
  };
};

const getOfferAdjustedUnitRateAdjustments = (
  unit: Unit,
  wayToSellId: number | undefined,
  offer: Offer,
  nightlyRates: NightlyRates,
  promocode: Promocode | undefined,
  propertyAvailability: PropertyAvailability,
  stayDates: StayDates,
  occupancy: Occupancy,
  meals: SupplementalMeal[],
): OfferRates => {
  const offerRates = wayToSellId
    ? calculateWayToSellOfferRatesOnNightlyRates(
        unit,
        wayToSellId,
        nightlyRates,
        offer,
        promocode,
        propertyAvailability,
        stayDates,
        occupancy,
        meals,
      )
    : calculateUnitOfferRatesOnNightlyRates(
        unit,
        nightlyRates,
        offer,
        promocode,
        propertyAvailability,
        stayDates,
        occupancy,
        meals,
      );

  if (offerRates === undefined) {
    throw new Error(`Offer ${offer.id} cannot be applied to Unit ${unit.id}`);
  }

  return offerRates;
};
