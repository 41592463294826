import type { UnitType } from '@/property/unit/type/unit-type';
import {
  translatableUnitTypeNames,
  createKnownUnitTypeFromTranslatableUnitTypeName,
} from '@/property/unit/type/unit-type.utilities';

export const mapUnitTypeFromName = (unitTypeName: string): UnitType => {
  const translatableUnitTypeName = translatableUnitTypeNames.find(
    (translatableUnitTypeName) => translatableUnitTypeName === unitTypeName,
  );

  return translatableUnitTypeName
    ? createKnownUnitTypeFromTranslatableUnitTypeName(translatableUnitTypeName)
    : {
        isKnown: false,
        name: unitTypeName,
      };
};
