<template>
  <BookingConfirmationAssurance
    v-if="booking.amountDue > 0 && advancedPaymentPolicy && hasDepositBeenPaid"
    data-test="BookingConfirmationAssuranceAmountDue"
  >
    <BookingConfirmationAssuranceAmountDueLabel
      :amount-due="booking.amountDue"
      :advanced-payment-policy="advancedPaymentPolicy"
    />
  </BookingConfirmationAssurance>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import BookingConfirmationAssuranceAmountDueLabel from '@/booking-confirmation-page/assurances/amount-due/BookingConfirmationAssuranceAmountDueLabel.vue';
import BookingConfirmationAssurance from '@/booking-confirmation-page/assurances/BookingConfirmationAssurance.vue';
import { useBookingConfirmationStore } from '@/booking-confirmation-page/booking-confirmation.store';

const { booking, bookingPolicy } = storeToRefs(useBookingConfirmationStore());

const advancedPaymentPolicy = computed(
  () => bookingPolicy.value.advancedPaymentPolicy,
);

const hasDepositBeenPaid = computed(
  () => booking.value.depositAmount <= booking.value.amountPaid,
);
</script>
