import { logError } from '@/log/log.utilities';
import type { PresetUnitFacility } from '@/property/unit/preset-unit-facility/preset-unit-facility';
import {
  PRESET_UNIT_FACILITY_ID_AIR_CONDITIONED,
  PRESET_UNIT_FACILITY_ID_ALARM_CLOCK,
  PRESET_UNIT_FACILITY_ID_ALL_BEDROOMS_NON_SMOKING,
  PRESET_UNIT_FACILITY_ID_ALL_ROOMS_NON_SMOKING,
  PRESET_UNIT_FACILITY_ID_AUTOMATIC_WAKE_UP_CALL,
  PRESET_UNIT_FACILITY_ID_BALCONY,
  PRESET_UNIT_FACILITY_ID_BALCONY_PATIO_FURNITURE,
  PRESET_UNIT_FACILITY_ID_BATH_ENSUITE,
  PRESET_UNIT_FACILITY_ID_BATH_SHOWER,
  PRESET_UNIT_FACILITY_ID_BATH_TUB,
  PRESET_UNIT_FACILITY_ID_BATHROBE_ON_REQUEST,
  PRESET_UNIT_FACILITY_ID_BATHROBES,
  PRESET_UNIT_FACILITY_ID_BED_LINEN_CHARGE,
  PRESET_UNIT_FACILITY_ID_BILL_MESSAGE_VIEWING,
  PRESET_UNIT_FACILITY_ID_BOTTLED_WATER,
  PRESET_UNIT_FACILITY_ID_BROADBAND_HIGH_SPEED_INTERNET_ACCESS,
  PRESET_UNIT_FACILITY_ID_BUNK_BEDS,
  PRESET_UNIT_FACILITY_ID_CABLE_TELEVISION_CHANNELS,
  PRESET_UNIT_FACILITY_ID_CD_PLAYER,
  PRESET_UNIT_FACILITY_ID_CEILING_FAN,
  PRESET_UNIT_FACILITY_ID_CENTRAL_HEATING,
  PRESET_UNIT_FACILITY_ID_CERAMIC_HOB,
  PRESET_UNIT_FACILITY_ID_CHOICE_OF_PILLOWS,
  PRESET_UNIT_FACILITY_ID_CLOTHES_DRYING_RAIL,
  PRESET_UNIT_FACILITY_ID_COASTAL_LOCATION,
  PRESET_UNIT_FACILITY_ID_COFFEE_MACHINE,
  PRESET_UNIT_FACILITY_ID_COFFEE_MAKER,
  PRESET_UNIT_FACILITY_ID_COIN_PAYPHONE,
  PRESET_UNIT_FACILITY_ID_COMPLIMENTARY_TOILETRIES,
  PRESET_UNIT_FACILITY_ID_CONNECTING_ROOMS,
  PRESET_UNIT_FACILITY_ID_COOKER,
  PRESET_UNIT_FACILITY_ID_COT_ON_REQUEST,
  PRESET_UNIT_FACILITY_ID_CROCKERY_AND_UTENSILS_SUPPLIED,
  PRESET_UNIT_FACILITY_ID_DAILY_HOUSEKEEPING,
  PRESET_UNIT_FACILITY_ID_DESIGNER_TOILETRIES,
  PRESET_UNIT_FACILITY_ID_DESK_CHAIR,
  PRESET_UNIT_FACILITY_ID_DIGITAL_TELEVISION_CHANNELS,
  PRESET_UNIT_FACILITY_ID_DINING_AREA,
  PRESET_UNIT_FACILITY_ID_DIRECT_DIAL_TELEPHONE,
  PRESET_UNIT_FACILITY_ID_DISHWASHER,
  PRESET_UNIT_FACILITY_ID_DUVET,
  PRESET_UNIT_FACILITY_ID_DVD_LIBRARY,
  PRESET_UNIT_FACILITY_ID_DVD_PLAYER,
  PRESET_UNIT_FACILITY_ID_EARLY_CHECK_IN,
  PRESET_UNIT_FACILITY_ID_EGYPTIAN_COTTON_LINEN,
  PRESET_UNIT_FACILITY_ID_ELECTRIC_SHAVER_POINT,
  PRESET_UNIT_FACILITY_ID_EN_SUITE,
  PRESET_UNIT_FACILITY_ID_ENSUITE_BATH_SHOWER_ROOM_IN_UNIT,
  PRESET_UNIT_FACILITY_ID_EXTRA_LINEN_ON_REQUEST,
  PRESET_UNIT_FACILITY_ID_FLAT_SCREEN_TV,
  PRESET_UNIT_FACILITY_ID_FOUR_POSTER_BED,
  PRESET_UNIT_FACILITY_ID_FREEZER,
  PRESET_UNIT_FACILITY_ID_FRIDGE,
  PRESET_UNIT_FACILITY_ID_FRIDGE_FREEZER,
  PRESET_UNIT_FACILITY_ID_FULL_LENGTH_MIRROR,
  PRESET_UNIT_FACILITY_ID_GAMES_CONSOLE,
  PRESET_UNIT_FACILITY_ID_HAIR_DRYER_IN_ROOM,
  PRESET_UNIT_FACILITY_ID_HAIRDRYER,
  PRESET_UNIT_FACILITY_ID_HEATED_TOWEL_RAILS,
  PRESET_UNIT_FACILITY_ID_HEATING_THROUGHOUT_PROPERTY,
  PRESET_UNIT_FACILITY_ID_HYDROMASSAGE_SHOWER,
  PRESET_UNIT_FACILITY_ID_I_POD_DOCKING_STATION,
  PRESET_UNIT_FACILITY_ID_IN_HOUSE_MOVIE,
  PRESET_UNIT_FACILITY_ID_IN_ROOM_VIDEO_CHECK_OUT,
  PRESET_UNIT_FACILITY_ID_INTERNET_VIA_TV,
  PRESET_UNIT_FACILITY_ID_IRON_AND_IRONING_BOARD_ON_REQUEST,
  PRESET_UNIT_FACILITY_ID_IRONING_FACILITIES,
  PRESET_UNIT_FACILITY_ID_JACUZZI,
  PRESET_UNIT_FACILITY_ID_KETTLE,
  PRESET_UNIT_FACILITY_ID_KING_SIZE_BED,
  PRESET_UNIT_FACILITY_ID_KITCHEN,
  PRESET_UNIT_FACILITY_ID_KITCHENETTE,
  PRESET_UNIT_FACILITY_ID_LAPTOP_SAFE,
  PRESET_UNIT_FACILITY_ID_LATE_CHECK_OUT,
  PRESET_UNIT_FACILITY_ID_LCD_PLASMA_TELEVISION,
  PRESET_UNIT_FACILITY_ID_LINEN_AVAILABLE_CHARGE_APPLIES,
  PRESET_UNIT_FACILITY_ID_LINEN_TOWELS_SUPPLIED,
  PRESET_UNIT_FACILITY_ID_LIVING_ROOM,
  PRESET_UNIT_FACILITY_ID_LOG_FIRE,
  PRESET_UNIT_FACILITY_ID_MAKE_UP_MIRROR,
  PRESET_UNIT_FACILITY_ID_MICROWAVE,
  PRESET_UNIT_FACILITY_ID_MINI_BAR,
  PRESET_UNIT_FACILITY_ID_MIST_FREE_MIRROR,
  PRESET_UNIT_FACILITY_ID_MODEM_POINT_AVAILABLE,
  PRESET_UNIT_FACILITY_ID_MULTI_FUEL_WOOD_BURNING_STOVE,
  PRESET_UNIT_FACILITY_ID_NEWSPAPER_ON_REQUEST,
  PRESET_UNIT_FACILITY_ID_PATIO,
  PRESET_UNIT_FACILITY_ID_PC_IN_ROOM,
  PRESET_UNIT_FACILITY_ID_PERSONAL_SAFE,
  PRESET_UNIT_FACILITY_ID_PRIVATE_BATHROOM,
  PRESET_UNIT_FACILITY_ID_PRIVATE_CAR_SPACE,
  PRESET_UNIT_FACILITY_ID_RADIO,
  PRESET_UNIT_FACILITY_ID_RAINFALL_SHOWER,
  PRESET_UNIT_FACILITY_ID_REMOTE_CONTROL_TV,
  PRESET_UNIT_FACILITY_ID_ROOM_SERVICE_LIMITED_HOURS,
  PRESET_UNIT_FACILITY_ID_SAFE_WITH_LAPTOP_CAPACITY,
  PRESET_UNIT_FACILITY_ID_SATELLITE_CABLE_TELEVISION,
  PRESET_UNIT_FACILITY_ID_SAUNA,
  PRESET_UNIT_FACILITY_ID_SEA_LOCH_VIEW,
  PRESET_UNIT_FACILITY_ID_SHARED_BATHROOM,
  PRESET_UNIT_FACILITY_ID_SHOE_SHINE,
  PRESET_UNIT_FACILITY_ID_SHOWER_ENSUITE,
  PRESET_UNIT_FACILITY_ID_SHOWER_ROOM,
  PRESET_UNIT_FACILITY_ID_SMART_TV,
  PRESET_UNIT_FACILITY_ID_SOUNDPROOFED_WINDOWS,
  PRESET_UNIT_FACILITY_ID_SPA_BATH_ENSUITE,
  PRESET_UNIT_FACILITY_ID_SWIM_UP_POOL,
  PRESET_UNIT_FACILITY_ID_TEA_COFFEE,
  PRESET_UNIT_FACILITY_ID_TELEPHONE,
  PRESET_UNIT_FACILITY_ID_TELEPHONE_IN_ROOM,
  PRESET_UNIT_FACILITY_ID_TELEPHONE_WITH_VOICEMAIL,
  PRESET_UNIT_FACILITY_ID_TELEVISION,
  PRESET_UNIT_FACILITY_ID_TOASTER,
  PRESET_UNIT_FACILITY_ID_TROUSER_PRESS,
  PRESET_UNIT_FACILITY_ID_TUMBLE_DRYER,
  PRESET_UNIT_FACILITY_ID_TV_IN_ROOM,
  PRESET_UNIT_FACILITY_ID_TWIN_DOUBLE_CHANGEABLE,
  PRESET_UNIT_FACILITY_ID_UNDERFLOOR_HEATING,
  PRESET_UNIT_FACILITY_ID_USE_OF_OWN_BED_LINEN_REQUIRED,
  PRESET_UNIT_FACILITY_ID_WAKE_UP_CALLS,
  PRESET_UNIT_FACILITY_ID_WASH_HAND_BASIN_ENSUITE,
  PRESET_UNIT_FACILITY_ID_WASHING_MACHINE,
  PRESET_UNIT_FACILITY_ID_WC_ENSUITE,
  PRESET_UNIT_FACILITY_ID_WIFI_FREE,
  PRESET_UNIT_FACILITY_ID_WIFI_INTERNET_ACCESS,
  PRESET_UNIT_FACILITY_ID_WINDOWS_OPEN,
  PRESET_UNIT_FACILITY_ID_WORK_DESK,
  PRESET_UNIT_FACILITY_ID_WORK_DESK_WITH_LAMP,
} from '@/property/unit/preset-unit-facility/preset-unit-facility';

export const getPresetUnitFacilityTranslationKey = ({
  id,
}: PresetUnitFacility): string | undefined => {
  const translationKey = presetUnitFacilityIdTranslationKeyMapping[id];

  if (!translationKey) {
    logError(
      new Error(`Missing translation key for preset unit facility ${id}`),
    );
  }

  return translationKey;
};

const presetUnitFacilityIdTranslationKeyMapping: Record<number, string> = {
  [PRESET_UNIT_FACILITY_ID_BATH_ENSUITE]: 'bath_ensuite',
  [PRESET_UNIT_FACILITY_ID_BED_LINEN_CHARGE]: 'bed_linen_charge',
  [PRESET_UNIT_FACILITY_ID_BUNK_BEDS]: 'bunk_beds',
  [PRESET_UNIT_FACILITY_ID_CD_PLAYER]: 'cd_player',
  [PRESET_UNIT_FACILITY_ID_DISHWASHER]: 'dishwasher',
  [PRESET_UNIT_FACILITY_ID_COOKER]: 'cooker',
  [PRESET_UNIT_FACILITY_ID_ENSUITE_BATH_SHOWER_ROOM_IN_UNIT]:
    'ensuite_bath_shower_room_in_unit',
  [PRESET_UNIT_FACILITY_ID_EXTRA_LINEN_ON_REQUEST]: 'extra_linen_on_request',
  [PRESET_UNIT_FACILITY_ID_FOUR_POSTER_BED]: 'four_poster_bed',
  [PRESET_UNIT_FACILITY_ID_FREEZER]: 'freezer',
  [PRESET_UNIT_FACILITY_ID_FRIDGE]: 'fridge',
  [PRESET_UNIT_FACILITY_ID_HAIR_DRYER_IN_ROOM]: 'hair_dryer_in_room',
  [PRESET_UNIT_FACILITY_ID_HEATING_THROUGHOUT_PROPERTY]:
    'heating_throughout_property',
  [PRESET_UNIT_FACILITY_ID_IRONING_FACILITIES]: 'ironing_facilities',
  [PRESET_UNIT_FACILITY_ID_KING_SIZE_BED]: 'king_size_bed',
  [PRESET_UNIT_FACILITY_ID_KITCHEN]: 'kitchen',
  [PRESET_UNIT_FACILITY_ID_LINEN_TOWELS_SUPPLIED]: 'linen_towels_supplied',
  [PRESET_UNIT_FACILITY_ID_LINEN_AVAILABLE_CHARGE_APPLIES]:
    'linen_available_charge_applies',
  [PRESET_UNIT_FACILITY_ID_MICROWAVE]: 'microwave',
  [PRESET_UNIT_FACILITY_ID_MINI_BAR]: 'mini_bar',
  [PRESET_UNIT_FACILITY_ID_MODEM_POINT_AVAILABLE]: 'modem_point_available',
  [PRESET_UNIT_FACILITY_ID_PRIVATE_BATHROOM]: 'private_bathroom',
  [PRESET_UNIT_FACILITY_ID_RADIO]: 'radio',
  [PRESET_UNIT_FACILITY_ID_SATELLITE_CABLE_TELEVISION]:
    'satellite_cable_television',
  [PRESET_UNIT_FACILITY_ID_SEA_LOCH_VIEW]: 'sea_loch_view',
  [PRESET_UNIT_FACILITY_ID_SHARED_BATHROOM]: 'shared_bathroom',
  [PRESET_UNIT_FACILITY_ID_SHOWER_ENSUITE]: 'shower_ensuite',
  [PRESET_UNIT_FACILITY_ID_SPA_BATH_ENSUITE]: 'spa_bath_ensuite',
  [PRESET_UNIT_FACILITY_ID_TV_IN_ROOM]: 'tv_in_room',
  [PRESET_UNIT_FACILITY_ID_TELEPHONE_IN_ROOM]: 'telephone_in_room',
  [PRESET_UNIT_FACILITY_ID_TROUSER_PRESS]: 'trouser_press',
  [PRESET_UNIT_FACILITY_ID_TUMBLE_DRYER]: 'tumble_dryer',
  [PRESET_UNIT_FACILITY_ID_USE_OF_OWN_BED_LINEN_REQUIRED]:
    'use_of_own_bed_linen_required',
  [PRESET_UNIT_FACILITY_ID_WC_ENSUITE]: 'wc_ensuite',
  [PRESET_UNIT_FACILITY_ID_WASH_HAND_BASIN_ENSUITE]: 'wash_hand_basin_ensuite',
  [PRESET_UNIT_FACILITY_ID_WASHING_MACHINE]: 'washing_machine',
  [PRESET_UNIT_FACILITY_ID_BALCONY]: 'balcony',
  [PRESET_UNIT_FACILITY_ID_BATHROBES]: 'bathrobes',
  [PRESET_UNIT_FACILITY_ID_BILL_MESSAGE_VIEWING]: 'bill_message_viewing',
  [PRESET_UNIT_FACILITY_ID_BROADBAND_HIGH_SPEED_INTERNET_ACCESS]:
    'broadband_high_speed_internet_access',
  [PRESET_UNIT_FACILITY_ID_CHOICE_OF_PILLOWS]: 'choice_of_pillows',
  [PRESET_UNIT_FACILITY_ID_COMPLIMENTARY_TOILETRIES]:
    'complimentary_toiletries',
  [PRESET_UNIT_FACILITY_ID_CONNECTING_ROOMS]: 'connecting_rooms',
  [PRESET_UNIT_FACILITY_ID_DINING_AREA]: 'dining_area',
  [PRESET_UNIT_FACILITY_ID_GAMES_CONSOLE]: 'games_console',
  [PRESET_UNIT_FACILITY_ID_I_POD_DOCKING_STATION]: 'i_pod_docking_station',
  [PRESET_UNIT_FACILITY_ID_IN_HOUSE_MOVIE]: 'in_house_movie',
  [PRESET_UNIT_FACILITY_ID_IN_ROOM_VIDEO_CHECK_OUT]: 'in_room_video_check_out',
  [PRESET_UNIT_FACILITY_ID_INTERNET_VIA_TV]: 'internet_via_tv',
  [PRESET_UNIT_FACILITY_ID_KITCHENETTE]: 'kitchenette',
  [PRESET_UNIT_FACILITY_ID_LAPTOP_SAFE]: 'laptop_safe',
  [PRESET_UNIT_FACILITY_ID_LCD_PLASMA_TELEVISION]: 'lcd_plasma_television',
  [PRESET_UNIT_FACILITY_ID_LIVING_ROOM]: 'living_room',
  [PRESET_UNIT_FACILITY_ID_MIST_FREE_MIRROR]: 'mist_free_mirror',
  [PRESET_UNIT_FACILITY_ID_PC_IN_ROOM]: 'pc_in_room',
  [PRESET_UNIT_FACILITY_ID_PERSONAL_SAFE]: 'personal_safe',
  [PRESET_UNIT_FACILITY_ID_SOUNDPROOFED_WINDOWS]: 'soundproofed_windows',
  [PRESET_UNIT_FACILITY_ID_TEA_COFFEE]: 'tea_coffee',
  [PRESET_UNIT_FACILITY_ID_TELEPHONE_WITH_VOICEMAIL]:
    'telephone_with_voicemail',
  [PRESET_UNIT_FACILITY_ID_WIFI_INTERNET_ACCESS]: 'wifi_internet_access',
  [PRESET_UNIT_FACILITY_ID_WORK_DESK]: 'work_desk',
  [PRESET_UNIT_FACILITY_ID_AIR_CONDITIONED]: 'air_conditioned',
  [PRESET_UNIT_FACILITY_ID_ALARM_CLOCK]: 'alarm_clock',
  [PRESET_UNIT_FACILITY_ID_ALL_BEDROOMS_NON_SMOKING]:
    'all_bedrooms_non_smoking',
  [PRESET_UNIT_FACILITY_ID_ALL_ROOMS_NON_SMOKING]: 'all_rooms_non_smoking',
  [PRESET_UNIT_FACILITY_ID_AUTOMATIC_WAKE_UP_CALL]: 'automatic_wake_up_call',
  [PRESET_UNIT_FACILITY_ID_HAIRDRYER]: 'hairdryer',
  [PRESET_UNIT_FACILITY_ID_DVD_PLAYER]: 'dvd_player',
  [PRESET_UNIT_FACILITY_ID_FRIDGE_FREEZER]: 'fridge_freezer',
  [PRESET_UNIT_FACILITY_ID_BATH_TUB]: 'bath_tub',
  [PRESET_UNIT_FACILITY_ID_SHOWER_ROOM]: 'shower_room',
  [PRESET_UNIT_FACILITY_ID_TELEVISION]: 'television',
  [PRESET_UNIT_FACILITY_ID_ELECTRIC_SHAVER_POINT]: 'electric_shaver_point',
  [PRESET_UNIT_FACILITY_ID_BATH_SHOWER]: 'bath_shower',
  [PRESET_UNIT_FACILITY_ID_MULTI_FUEL_WOOD_BURNING_STOVE]:
    'multi_fuel_wood_burning_stove',
  [PRESET_UNIT_FACILITY_ID_LOG_FIRE]: 'log_fire',
  [PRESET_UNIT_FACILITY_ID_COIN_PAYPHONE]: 'coin_payphone',
  [PRESET_UNIT_FACILITY_ID_SAUNA]: 'sauna',
  [PRESET_UNIT_FACILITY_ID_PRIVATE_CAR_SPACE]: 'private_car_space',
  [PRESET_UNIT_FACILITY_ID_COASTAL_LOCATION]: 'coastal_location',
  [PRESET_UNIT_FACILITY_ID_JACUZZI]: 'jacuzzi',
  [PRESET_UNIT_FACILITY_ID_CERAMIC_HOB]: 'ceramic_hob',
  [PRESET_UNIT_FACILITY_ID_COFFEE_MAKER]: 'coffee_maker',
  [PRESET_UNIT_FACILITY_ID_UNDERFLOOR_HEATING]: 'underfloor_heating',
  [PRESET_UNIT_FACILITY_ID_TELEPHONE]: 'telephone',
  [PRESET_UNIT_FACILITY_ID_CENTRAL_HEATING]: 'central_heating',
  [PRESET_UNIT_FACILITY_ID_TWIN_DOUBLE_CHANGEABLE]: 'twin_double_changeable',
  [PRESET_UNIT_FACILITY_ID_DUVET]: 'duvet',
  [PRESET_UNIT_FACILITY_ID_EGYPTIAN_COTTON_LINEN]: 'egyptian_cotton_linen',
  [PRESET_UNIT_FACILITY_ID_COT_ON_REQUEST]: 'cot_on_request',
  [PRESET_UNIT_FACILITY_ID_EN_SUITE]: 'en_suite',
  [PRESET_UNIT_FACILITY_ID_DESIGNER_TOILETRIES]: 'designer_toiletries',
  [PRESET_UNIT_FACILITY_ID_BATHROBE_ON_REQUEST]: 'bathrobe_on_request',
  [PRESET_UNIT_FACILITY_ID_HEATED_TOWEL_RAILS]: 'heated_towel_rails',
  [PRESET_UNIT_FACILITY_ID_REMOTE_CONTROL_TV]: 'remote_control_tv',
  [PRESET_UNIT_FACILITY_ID_FLAT_SCREEN_TV]: 'flat_screen_tv',
  [PRESET_UNIT_FACILITY_ID_DVD_LIBRARY]: 'dvd_library',
  [PRESET_UNIT_FACILITY_ID_KETTLE]: 'kettle',
  [PRESET_UNIT_FACILITY_ID_ROOM_SERVICE_LIMITED_HOURS]:
    'room_service_limited_hours',
  [PRESET_UNIT_FACILITY_ID_SAFE_WITH_LAPTOP_CAPACITY]:
    'safe_with_laptop_capacity',
  [PRESET_UNIT_FACILITY_ID_NEWSPAPER_ON_REQUEST]: 'newspaper_on_request',
  [PRESET_UNIT_FACILITY_ID_IRON_AND_IRONING_BOARD_ON_REQUEST]:
    'iron_and_ironing_board_on_request',
  [PRESET_UNIT_FACILITY_ID_FULL_LENGTH_MIRROR]: 'full_length_mirror',
  [PRESET_UNIT_FACILITY_ID_MAKE_UP_MIRROR]: 'make_up_mirror',
  [PRESET_UNIT_FACILITY_ID_SHOE_SHINE]: 'shoe_shine',
  [PRESET_UNIT_FACILITY_ID_WAKE_UP_CALLS]: 'wake_up_calls',
  [PRESET_UNIT_FACILITY_ID_DAILY_HOUSEKEEPING]: 'daily_housekeeping',
  [PRESET_UNIT_FACILITY_ID_LATE_CHECK_OUT]: 'late_check_out',
  [PRESET_UNIT_FACILITY_ID_EARLY_CHECK_IN]: 'early_check_in',
  [PRESET_UNIT_FACILITY_ID_WINDOWS_OPEN]: 'windows_open',
  [PRESET_UNIT_FACILITY_ID_WIFI_FREE]: 'wifi_free',
  [PRESET_UNIT_FACILITY_ID_DIRECT_DIAL_TELEPHONE]: 'direct_dial_telephone',
  [PRESET_UNIT_FACILITY_ID_WORK_DESK_WITH_LAMP]: 'work_desk_with_lamp',
  [PRESET_UNIT_FACILITY_ID_DESK_CHAIR]: 'desk_chair',
  [PRESET_UNIT_FACILITY_ID_HYDROMASSAGE_SHOWER]: 'hydromassage_shower',
  [PRESET_UNIT_FACILITY_ID_RAINFALL_SHOWER]: 'rainfall_shower',
  [PRESET_UNIT_FACILITY_ID_BOTTLED_WATER]: 'bottled_water',
  [PRESET_UNIT_FACILITY_ID_CROCKERY_AND_UTENSILS_SUPPLIED]:
    'crockery_and_utensils_supplied',
  [PRESET_UNIT_FACILITY_ID_DIGITAL_TELEVISION_CHANNELS]:
    'digital_television_channels',
  [PRESET_UNIT_FACILITY_ID_CABLE_TELEVISION_CHANNELS]:
    'cable_television_channels',
  [PRESET_UNIT_FACILITY_ID_PATIO]: 'patio',
  [PRESET_UNIT_FACILITY_ID_SMART_TV]: 'smart_tv',
  [PRESET_UNIT_FACILITY_ID_BALCONY_PATIO_FURNITURE]: 'balcony_patio_furniture',
  [PRESET_UNIT_FACILITY_ID_CLOTHES_DRYING_RAIL]: 'clothes_drying_rail',
  [PRESET_UNIT_FACILITY_ID_CEILING_FAN]: 'ceiling_fan',
  [PRESET_UNIT_FACILITY_ID_TOASTER]: 'toaster',
  [PRESET_UNIT_FACILITY_ID_COFFEE_MACHINE]: 'coffee_machine',
  [PRESET_UNIT_FACILITY_ID_SWIM_UP_POOL]: 'swim_up_pool',
};
