<template>
  <div class="space-y-4">
    <AppFormInputValidationGroup>
      <BookingConfirmationEditBookingDetailsUnitLeadGuestName
        v-model="bookingUnit"
      />
    </AppFormInputValidationGroup>

    <BookingConfirmationEditBookingDetailsUnitTwinDoubleBedOption
      v-if="unit && unit.hasTwinDoubleBedOption"
      v-model="bookingUnit"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { ActiveBookingUnit } from '@/booking/unit/active/active-booking-unit';
import { useBookingConfirmationStore } from '@/booking-confirmation-page/booking-confirmation.store';
import BookingConfirmationEditBookingDetailsUnitLeadGuestName from '@/booking-confirmation-page/edit-booking-details/unit/lead-guest-name/BookingConfirmationEditBookingDetailsUnitLeadGuestName.vue';
import BookingConfirmationEditBookingDetailsUnitTwinDoubleBedOption from '@/booking-confirmation-page/edit-booking-details/unit/twin-double-bed-option/BookingConfirmationEditBookingDetailsUnitTwinDoubleBedOption.vue';
import { findPropertyUnitById } from '@/property/property.utilities';
import AppFormInputValidationGroup from '@/ui/app-form/AppFormInputValidationGroup.vue';

const bookingUnit = defineModel<ActiveBookingUnit>({ required: true });

const bookingConfirmationStore = useBookingConfirmationStore();

const unit = computed(() =>
  findPropertyUnitById(
    bookingConfirmationStore.property,
    bookingUnit.value.unitId,
  ),
);
</script>
