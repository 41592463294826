import { matchSupportedLocale } from 'match-supported-locale';
import { useI18n } from 'vue-i18n';
import {
  Locale,
  languageFallbackLocales,
  supportedLocales,
} from '@/i18n/locales';
import { IDB_KEY_LOCALE } from '@/idb';
import { useIDBRef } from '@/idb/idb-ref.composable';
import router from '@/router';
import { useQueryParams } from '@/url/query-params/query-params.composable';

export const useInitializeLocale = async () => {
  const { locale } = useI18n();

  const [defaultLocale, wasQueryParamLocaleUsed] =
    await generateDefaultLocale();

  locale.value = defaultLocale;

  useIDBRef(IDB_KEY_LOCALE, locale, {
    shouldInitialize: !wasQueryParamLocaleUsed,
  });
};

/**
 * Generates the default locale using either the locale found within the query
 * parameters, or using the browser's current language. The return value
 * indicates whether the query parameter locale was used.
 *
 * Note the side-effect of clearing the query parameter once we've read it.
 * This is to ensure the locale is neither:
 * - carried across to other users when links are shared, or
 * - 'stored' indefinitely in the URL.
 */
const generateDefaultLocale = async (): Promise<[string, boolean]> => {
  const { localeQueryParam } = useQueryParams();

  await router.isReady();

  const defaultLocale = matchSupportedLocale(
    localeQueryParam.value ?? navigator.language,
    supportedLocales,
    languageFallbackLocales,
  );

  const wasQueryParamLocaleUsed = !!defaultLocale && !!localeQueryParam.value;

  localeQueryParam.value = undefined;

  return [defaultLocale ?? Locale.enGB, wasQueryParamLocaleUsed];
};
