<template>
  <div
    :class="{
      'transition-[height] duration-700 md:transition-none':
        shouldTransitionHeight,
    }"
    :style="{
      height: `${scarcityFlagContainerHeight}px`,
    }"
  >
    <div ref="scarcityFlagContainer">
      <Transition
        enter-active-class="duration-500"
        enter-from-class="opacity-0 translate-y-1"
        enter-to-class="opacity-100 translate-y-0"
        leave-active-class="duration-500 absolute md:relative"
        leave-from-class="opacity-100 translate-y-0"
        leave-to-class="opacity-0 translate-y-1"
        @before-enter="shouldTransitionHeight = true"
      >
        <div
          v-if="shouldShowScarcityFlag || unitTypeIsBed"
          data-test="UnitSelectionPricePlanScarcityFlag"
          class="text-sm font-medium"
        >
          <AppSkeletonText v-if="searchStore.isLoading" class="w-28" />

          <template v-else>
            <div
              v-if="shouldShowScarcityFlag"
              class="flex items-center text-red-600"
            >
              <IconFlag :size="16" stroke-width="2" class="mr-1" />

              <span v-if="areThereUnitsRemaining" class="capitalize">
                {{
                  $t('onlyXLeft', {
                    count: numberOfUnitsAvailableAfterSelection,
                  })
                }}
              </span>

              <span v-else class="first-letter:capitalize">
                {{
                  $t('youHaveSelectedTheLastUnitAvailable', {
                    unitTypeName,
                    unitTypeGender,
                  })
                }}
              </span>
            </div>

            <div v-else>
              {{
                $t('countBedsAvailable', {
                  count: numberOfUnitsAvailableAfterSelection,
                })
              }}
            </div>
          </template>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import { IconFlag } from '@tabler/icons-vue';
import { useElementSize } from '@vueuse/core';
import { computed, ref } from 'vue';
import type { PricePlan } from '@/price-plan/price-plan';
import { UNIT_TYPE_NAME_BED } from '@/property/unit/type/unit-type';
import { useUnitType } from '@/property/unit/type/unit-type.composable';
import { useSearchStore } from '@/search/search.store';
import AppSkeletonText from '@/ui/app-skeleton-text/AppSkeletonText.vue';

const props = defineProps<{
  pricePlan: PricePlan;
}>();

const searchStore = useSearchStore();

const scarcityFlagContainer = ref(null);

const scarcityFlagContainerHeight = useElementSize(
  scarcityFlagContainer,
).height;

const shouldTransitionHeight = ref(false);

const SCARCITY_FLAG_THRESHOLD = 5;

const numberOfUnitsAvailableAfterSelection = computed(() =>
  searchStore.activePropertyOverStay.findNumberOfUnitsAvailableAfterSelectionByUnitId(
    props.pricePlan.unit.id,
  ),
);

const areThereUnitsRemaining = computed(
  () => numberOfUnitsAvailableAfterSelection.value > 0,
);

const { unitTypeName, unitTypeGender } = useUnitType(
  computed(() => searchStore.activeProperty.unitType),
);

const shouldShowScarcityFlag = computed(
  () =>
    (numberOfUnitsAvailableAfterSelection.value < SCARCITY_FLAG_THRESHOLD &&
      searchStore.activeProperty.widgetSettings.showScarcityFlag &&
      !unitTypeIsBed.value) ||
    !areThereUnitsRemaining.value,
);

const unitTypeIsBed = computed(
  () => props.pricePlan.unit.type.name === UNIT_TYPE_NAME_BED,
);
</script>
