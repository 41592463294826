import {
  BookingCreateResultType,
  type BookingCreateResult,
  type BookingCreateResultDTO,
} from '@/booking/create/result/booking-create-result';
import { mapBookingCreateConfirmedResultFromDTO } from '@/booking/create/result/confirmed/booking-create-confirmed-result.mapper';
import { mapBookingCreateSagePay3DSRequiredResultFromDTO } from '@/booking/create/result/sage-pay-3ds-required/booking-create-sage-pay-3ds-required-result.mapper';
import { mapBookingCreateStripeActionRequiredResultFromDTO } from '@/booking/create/result/stripe-action-required/booking-create-stripe-action-required.mapper';

export const mapBookingCreateResultFromDTO = (
  bookingCreateResultDTO: BookingCreateResultDTO,
): BookingCreateResult => {
  const { type } = bookingCreateResultDTO;

  switch (type) {
    case BookingCreateResultType.Confirmed:
      return mapBookingCreateConfirmedResultFromDTO(bookingCreateResultDTO);
    case BookingCreateResultType.SagePay3DSRequired:
      return mapBookingCreateSagePay3DSRequiredResultFromDTO(
        bookingCreateResultDTO,
      );
    case BookingCreateResultType.StripeActionRequired:
      return mapBookingCreateStripeActionRequiredResultFromDTO(
        bookingCreateResultDTO,
      );
  }
};
