import type {
  BookingVat,
  BookingVatDTO,
} from '@/booking/booking-taxes/vat/booking-vat';
import { mapVatFromDTO } from '@/tax/vat/vat.mapper';

export const mapBookingVatFromDTO = (
  bookingVatDTO: BookingVatDTO,
): BookingVat => ({
  vat: mapVatFromDTO(bookingVatDTO.vat),
  includedAmount: Number(bookingVatDTO.includedAmount),
  excludedAmount: Number(bookingVatDTO.excludedAmount),
});
