<template>
  <div class="col-span-2">
    <div v-for="meal in meals" :key="meal.type">
      <BookingSummaryBodyUnitItemMeal :meal="meal" />
    </div>
  </div>

  <div v-if="canUpdateSelectedMeals" class="col-span-2 ml-auto mt-2">
    <BookingSummaryBodyUnitItemUpdateSelectedMealsMenu />
  </div>
</template>

<script setup lang="ts">
import type { UnitItineraryItem } from '@/booking-itinerary/booking-itinerary';
import { useProvideBookingSummaryMealsStore } from '@/booking-summary/body/unit-item/meals/booking-summary-meals.store';
import BookingSummaryBodyUnitItemMeal from '@/booking-summary/body/unit-item/meals/BookingSummaryBodyUnitItemMeal.vue';
import BookingSummaryBodyUnitItemUpdateSelectedMealsMenu from '@/booking-summary/body/unit-item/meals/BookingSummaryBodyUnitItemUpdateSelectedMealsMenu.vue';

const props = defineProps<{ unitItineraryItem: UnitItineraryItem }>();

const { canUpdateSelectedMeals, meals } = useProvideBookingSummaryMealsStore(
  props.unitItineraryItem,
);
</script>
