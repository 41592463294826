import type {
  PetsPolicyCharge,
  PetsPolicyChargeDTO,
} from '@/property/pets-policy/charge/pets-policy-charge';
import { PetsPolicyChargeType } from '@/property/pets-policy/charge/pets-policy-charge';

export const mapPetsPolicyChargeFromDTO = (
  petsPolicyChargeDTO: PetsPolicyChargeDTO,
): PetsPolicyCharge => {
  const { chargeType } = petsPolicyChargeDTO;

  switch (chargeType) {
    case PetsPolicyChargeType.PerDayMonetary:
      return {
        chargeType,
        amountPerDay: Number(petsPolicyChargeDTO.amountPerDay),
      };
    case PetsPolicyChargeType.PerStayMonetary:
      return {
        chargeType,
        amountPerStay: Number(petsPolicyChargeDTO.amountPerStay),
      };
    case PetsPolicyChargeType.Free:
      return { chargeType };
  }
};
