<template>
  <button
    :class="isIncrementing ? 'rounded-r' : 'rounded-l'"
    class="accent-contrast-border flex items-center justify-center bg-accent-color text-accent-contrast-color outline-none transition-[filter] hover:brightness-95 focus-visible:outline-none focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-4 focus-visible:outline-slate-800 active:brightness-90"
    :aria-label="label"
    @click="$emit('click')"
  >
    <IconPlus v-if="isIncrementing" :size="ICON_SIZE" />

    <IconMinus v-else :size="ICON_SIZE" />
  </button>
</template>

<script setup lang="ts">
import { IconPlus, IconMinus } from '@tabler/icons-vue';

const ICON_SIZE = 22;

defineProps<{
  isIncrementing: boolean;
  label: string;
}>();

defineEmits(['click']);
</script>
