<template>
  <AvailabilityCalendarModal
    :is-open="isAvailabilityCalendarModalOpen"
    :check-in-date="searchStore.stayDates.checkInDate"
    :check-out-date="searchStore.stayDates.checkOutDate"
    :auto-select-stay-dates="false"
    :button-text="capitalize($t('search'))"
    :icon="IconSearch"
    @close="isAvailabilityCalendarModalOpen = false"
    @submit="onCalendarSearch"
  />

  <UnitSelectionAdditionalPropertiesItemButton
    v-if="shouldUseModal"
    :button-text="toTitleCase($t('tryAlternativeDates'))"
    @click="isAvailabilityCalendarModalOpen = true"
  />

  <AppPopover v-else v-slot="{ close }">
    <UnitSelectionAdditionalPropertiesItemButton
      ref="popoverButton"
      :button-text="toTitleCase($t('tryAlternativeDates'))"
      :use-as-popover-button="true"
    />

    <div
      ref="popoverPanel"
      class="z-50 flex scroll-mt-[calc(theme(spacing.inline-booking-stage-selector-height)+theme(spacing.unit-selection-search-bar-height))] justify-center"
      :style="floatingStyles"
    >
      <AppPopoverPanel>
        <AvailabilityCalendarContainer
          :check-in-date="searchStore.stayDates.checkInDate"
          :check-out-date="searchStore.stayDates.checkOutDate"
          :auto-select-stay-dates="false"
          @vue:mounted="scrollPopoverPanelIntoView"
        />

        <template #footer>
          <AvailabilityCalendarFooter
            :button-text="capitalize($t('search'))"
            :icon="IconSearch"
            @submit="
              () => {
                close();
                onCalendarSearch();
              }
            "
          />
        </template>
      </AppPopoverPanel>
    </div>
  </AppPopover>

  <UnitSelectionAdditionalPropertiesSwitchPropertyNonEmptyItineraryWarningModal
    :is-open="isPropertySwitchWarningModalOpen"
    :property-to-switch-from="searchStore.activeProperty"
    :property-to-switch-to="property"
    @close="isPropertySwitchWarningModalOpen = false"
    @switch="onPropertySwitchWarningSwitch"
  />
</template>

<script setup lang="ts">
import { useFloating, autoUpdate, shift } from '@floating-ui/vue';
import { IconSearch } from '@tabler/icons-vue';
import { useBreakpoints, breakpointsTailwind } from '@vueuse/core';
import type { Ref } from 'vue';
import { computed, ref } from 'vue';
import { useProvideAvailabilityCalendarStore } from '@/availability-calendar/availability-calendar.store';
import AvailabilityCalendarContainer from '@/availability-calendar/AvailabilityCalendarContainer.vue';
import AvailabilityCalendarFooter from '@/availability-calendar/AvailabilityCalendarFooter.vue';
import AvailabilityCalendarModal from '@/availability-calendar/modal/AvailabilityCalendarModal.vue';
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import type { Property } from '@/property/property';
import { useSearchStore } from '@/search/search.store';
import { useLocaleString } from '@/string/locale-string.composable';
import AppPopover from '@/ui/app-popover/AppPopover.vue';
import AppPopoverPanel from '@/ui/app-popover/AppPopoverPanel.vue';
import UnitSelectionAdditionalPropertiesItemButton from '@/unit-selection-page/additional-properties/item/button/UnitSelectionAdditionalPropertiesItemButton.vue';
import UnitSelectionAdditionalPropertiesSwitchPropertyNonEmptyItineraryWarningModal from '@/unit-selection-page/additional-properties/switch-property-non-empty-itinerary-warning-modal/UnitSelectionAdditionalPropertiesSwitchPropertyNonEmptyItineraryWarningModal.vue';
import { useUnitSelectionStore } from '@/unit-selection-page/unit-selection.store';

const props = defineProps<{ property: Property }>();

const searchStore = useSearchStore();
const unitSelectionStore = useUnitSelectionStore();
const bookingItineraryStore = useBookingItineraryStore();

const shouldUseModal = useBreakpoints(breakpointsTailwind).smaller('md');
const { toTitleCase, capitalize } = useLocaleString();

const { selectedStayDates } = useProvideAvailabilityCalendarStore({
  property: computed(() => props.property),
});

const isAvailabilityCalendarModalOpen = ref(false);
const isPropertySwitchWarningModalOpen = ref(false);

const onCalendarSearch = () => {
  isAvailabilityCalendarModalOpen.value = false;

  if (bookingItineraryStore.hasEmptyUnitItinerary) {
    switchSearchProperty();
  } else {
    isPropertySwitchWarningModalOpen.value = true;
  }
};

const onPropertySwitchWarningSwitch = () => {
  isPropertySwitchWarningModalOpen.value = false;

  switchSearchProperty();
};

const switchSearchProperty = () => {
  void unitSelectionStore.updateProperty(
    props.property,
    selectedStayDates.value,
  );
};

const popoverButton = ref<HTMLElement | null>(null);
const popoverPanel: Ref<HTMLElement | null> = ref(null);

const scrollPopoverPanelIntoView = () => {
  setTimeout(() => {
    popoverPanel.value?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  });
};

const { floatingStyles } = useFloating(popoverButton, popoverPanel, {
  placement: 'top',
  middleware: [shift({ padding: 15 })],
  whileElementsMounted: autoUpdate,
});
</script>
