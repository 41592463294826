<template>
  <TermsAndConditionsStatementSection :title="toTitleCase($t('nonRefundable'))">
    <p>{{ $t('thisBookingIsNonRefundable') }}</p>
  </TermsAndConditionsStatementSection>
</template>

<script setup lang="ts">
import { useLocaleString } from '@/string/locale-string.composable';
import TermsAndConditionsStatementSection from '@/terms-and-conditions/statement/sections/TermsAndConditionsStatementSection.vue';

const { toTitleCase } = useLocaleString();
</script>
