<template>
  <div class="flex items-center gap-2 text-sm text-slate-700 md:items-start">
    <div>
      <IconMapPin stroke-width="1.5" />
    </div>

    <address v-if="!searchStore.isLoading">
      <FormattedAddress :address="property.address" />
    </address>

    <div v-else class="w-full">
      <AppSkeletonText />
      <AppSkeletonText />
      <AppSkeletonText />
    </div>
  </div>
</template>

<script setup lang="ts">
import { IconMapPin } from '@tabler/icons-vue';
import FormattedAddress from '@/address/formatted/FormattedAddress.vue';
import type { Property } from '@/property/property';
import { useSearchStore } from '@/search/search.store';
import AppSkeletonText from '@/ui/app-skeleton-text/AppSkeletonText.vue';

defineProps<{ property: Property }>();

const searchStore = useSearchStore();
</script>
