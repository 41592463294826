import { eventTrackingKy } from '@/ky/index';
import type { UserEvent } from '@/user-event/user-event';

export const trackUserEvent = (userEvent: UserEvent) => {
  void eventTrackingKy.post(`events/booking-pages`, {
    json: {
      user_event_identifier: {
        unique_user_id: userEvent.userEventIdentifier.uniqueUserId,
        created_at_timestamp: userEvent.userEventIdentifier.createdAtTimestamp,
      },
      action: userEvent.userEventAction.label,
      action_metadata: userEvent.userEventAction.metadata,
      user_agent: userEvent.userAgent,
      screen_size: userEvent.screenSize,
      datetime: userEvent.datetime,
      timezone: userEvent.timezone,
    },
  });
};
