import { createInjectionState } from '@vueuse/core';
import { type ComputedRef, computed } from 'vue';
import type { BookingPolicy } from '@/property/booking-policy/booking-policy';
import { useCancellationPoliciesTermsAndConditionsDisplayTexts } from '@/property/booking-policy/cancellation-policy/terms-and-conditions/cancellation-policies-terms-and-conditions-display-texts.composable';
import { useDepositPolicyTermsAndConditionsDisplayText } from '@/property/booking-policy/deposit-policy/terms-and-conditions/deposit-policy-terms-and-conditions-display-text.composable';
import { useNoShowPolicyTermsAndConditionsDisplayText } from '@/property/booking-policy/no-show-policy/terms-and-conditions/no-show-policy-terms-and-conditions-display-text.composable';
import { usePaymentMethodPoliciesTermsAndConditionsDisplayText } from '@/property/booking-policy/payment-method-policy/terms-and-conditions/payment-method-policies-terms-and-conditions-display-text.composable';
import { useCheckInPolicyTermsAndConditionsDisplayText } from '@/property/check-in-policy/terms-and-conditions/check-in-policy-terms-and-conditions-display-text.composable';
import { useChildPolicyTermsAndConditionsDisplayText } from '@/property/child-policy/terms-and-conditions/child-policy-terms-and-conditions-display-text.composable';
import { useGroupsPolicyTermsAndConditionsDisplayText } from '@/property/groups-policy/terms-and-conditions/groups-policy-terms-and-conditions-display-text.composable';
import { useParkingPoliciesTermsAndConditionsDisplayText } from '@/property/parking-policy/terms-and-conditions/parking-policies-terms-and-conditions-display-text.composable';
import { useStripePreAuthTermsAndConditionsDisplayText } from '@/property/payment-gateway/stripe/terms-and-conditions/stripe-pre-auth-terms-and-conditions-display-text.composable';
import { usePetsPolicyTermsAndConditionsDisplayText } from '@/property/pets-policy/terms-and-conditions/pets-policy-terms-and-conditions-display-text.composable';
import type { Property } from '@/property/property';
import { useSmokingPolicyTermsAndConditionsDisplayText } from '@/property/smoking-policy/terms-and-conditions/smoking-policy-terms-and-conditions-display-text.composable';
import type { StayDates } from '@/stay-dates/stay-dates';

const [
  useProvideTermsAndConditionsStatement,
  useMaybeTermsAndConditionsStatement,
] = createInjectionState(
  (
    property: ComputedRef<Property>,
    bookingPolicy: ComputedRef<BookingPolicy>,
    stayDates: ComputedRef<StayDates>,
  ) => {
    const checkInNotes = computed(
      () => property.value.checkInPolicy.checkInNotes,
    );

    const additionalCancellationConditions = computed(
      () => bookingPolicy.value.additionalCancellationConditions,
    );

    const checkInPolicyTerms =
      useCheckInPolicyTermsAndConditionsDisplayText(property);

    const childPolicyTerms =
      useChildPolicyTermsAndConditionsDisplayText(property);

    const petsPolicyTerms =
      usePetsPolicyTermsAndConditionsDisplayText(property);

    const smokingPolicyTerms =
      useSmokingPolicyTermsAndConditionsDisplayText(property);

    const groupsPolicyTerms =
      useGroupsPolicyTermsAndConditionsDisplayText(property);

    const parkingPoliciesTerms =
      useParkingPoliciesTermsAndConditionsDisplayText(property);

    const depositPolicyTerms = useDepositPolicyTermsAndConditionsDisplayText(
      bookingPolicy,
      property,
    );

    const paymentMethodPoliciesTerms =
      usePaymentMethodPoliciesTermsAndConditionsDisplayText(
        bookingPolicy,
        property,
      );

    const stripePreAuthTerms =
      useStripePreAuthTermsAndConditionsDisplayText(property);

    const cancellationPoliciesTerms =
      useCancellationPoliciesTermsAndConditionsDisplayTexts(
        bookingPolicy,
        property,
        stayDates,
      );

    const noShowPolicyTerms = useNoShowPolicyTermsAndConditionsDisplayText(
      bookingPolicy,
      property,
    );

    return {
      checkInNotes,
      additionalCancellationConditions,
      checkInPolicyTerms,
      childPolicyTerms,
      petsPolicyTerms,
      smokingPolicyTerms,
      groupsPolicyTerms,
      parkingPoliciesTerms,
      depositPolicyTerms,
      paymentMethodPoliciesTerms,
      stripePreAuthTerms,
      cancellationPoliciesTerms,
      noShowPolicyTerms,
    };
  },
);

const useTermsAndConditionsStatement = () => {
  const statement = useMaybeTermsAndConditionsStatement();

  if (!statement) {
    throw new Error('Terms and conditions statement has not been provided');
  }

  return statement;
};

export {
  useTermsAndConditionsStatement,
  useProvideTermsAndConditionsStatement,
};
