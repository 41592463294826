<template>
  <TermsAndConditionsStatementSection :title="toTitleCase($t('policy'))">
    <TermsAndConditionsStatementSectionList>
      <li>{{ checkInPolicyTerms }}</li>

      <li>{{ childPolicyTerms }}</li>

      <li>{{ petsPolicyTerms }}</li>

      <li>{{ smokingPolicyTerms }}</li>

      <li v-if="groupsPolicyTerms">{{ groupsPolicyTerms }}</li>

      <li v-if="parkingPoliciesTerms">{{ parkingPoliciesTerms }}</li>
    </TermsAndConditionsStatementSectionList>
  </TermsAndConditionsStatementSection>
</template>

<script setup lang="ts">
import { useLocaleString } from '@/string/locale-string.composable';
import TermsAndConditionsStatementSection from '@/terms-and-conditions/statement/sections/TermsAndConditionsStatementSection.vue';
import TermsAndConditionsStatementSectionList from '@/terms-and-conditions/statement/sections/TermsAndConditionsStatementSectionList.vue';
import { useTermsAndConditionsStatement } from '@/terms-and-conditions/statement/terms-and-conditions-statement.composable';

const {
  checkInPolicyTerms,
  childPolicyTerms,
  petsPolicyTerms,
  smokingPolicyTerms,
  groupsPolicyTerms,
  parkingPoliciesTerms,
} = useTermsAndConditionsStatement();

const { toTitleCase } = useLocaleString();
</script>
