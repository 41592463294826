import type {
  PropertyThemeName,
  PropertyThemeNameDTO,
} from '@/property/property-theme/name/property-theme-name';

export const mapPropertyThemeNameFromDTO = (
  propertyThemeNameDTO: PropertyThemeNameDTO,
): PropertyThemeName => ({
  text: propertyThemeNameDTO.text,
});
