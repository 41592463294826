import { computed, ref } from 'vue';
import type { Image } from '@/image/image';

export const useImageCarousel = (images: Image[]) => {
  const currentSlideIndex = ref(0);

  const previouslyLoadedPlaceholderImageIds = ref<number[]>([]);
  const previouslyLoadedFullScaleImageIds = ref<number[]>([]);

  const shouldLoadPlaceholderImage = (
    imageIndex: number,
    imageId: number,
  ): boolean => {
    if (previouslyLoadedPlaceholderImageIds.value.includes(imageId)) {
      return true;
    }

    if (
      imageIndex === previousSlideIndex.value ||
      imageIndex === currentSlideIndex.value ||
      imageIndex === nextSlideIndex.value
    ) {
      previouslyLoadedPlaceholderImageIds.value.push(imageId);

      return true;
    }

    return false;
  };

  const shouldLoadFullScaleImage = (
    imageIndex: number,
    imageId: number,
  ): boolean => {
    if (previouslyLoadedFullScaleImageIds.value.includes(imageId)) {
      return true;
    }

    if (imageIndex === currentSlideIndex.value) {
      previouslyLoadedFullScaleImageIds.value.push(imageId);

      return true;
    }

    return false;
  };

  const previousSlideIndex = computed(() =>
    currentSlideIndex.value - 1 < 0
      ? images.length - 1
      : currentSlideIndex.value - 1,
  );

  const nextSlideIndex = computed(() =>
    currentSlideIndex.value + 1 > images.length - 1
      ? 0
      : currentSlideIndex.value + 1,
  );

  const onSlideChange = (newSlideIndex: number) => {
    currentSlideIndex.value = newSlideIndex;
  };

  return {
    shouldLoadPlaceholderImage,
    shouldLoadFullScaleImage,
    onSlideChange,
  };
};
