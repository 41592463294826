import type { PaymentIntent, SetupIntent } from '@stripe/stripe-js';
import type { Booking, BookingDTO } from '@/booking/booking';
import {
  mapBookingFromDTO,
  mapBookingToUpdateDTO,
} from '@/booking/booking.mapper';
import type { BookingCreateData } from '@/booking/create/data/booking-create-data';
import { mapBookingCreateDataToDTO } from '@/booking/create/data/booking-create-data.mapper';
import type {
  BookingCreateResultDTO,
  BookingCreateResult,
} from '@/booking/create/result/booking-create-result';
import { mapBookingCreateResultFromDTO } from '@/booking/create/result/booking-create-result.mapper';
import type {
  BookingCreateConfirmedResult,
  BookingCreateConfirmedResultDTO,
} from '@/booking/create/result/confirmed/booking-create-confirmed-result';
import { mapBookingCreateConfirmedResultFromDTO } from '@/booking/create/result/confirmed/booking-create-confirmed-result.mapper';
import { ky } from '@/ky';

export const sendCreateBookingRequest = async (
  propertyId: number,
  bookingCreateData: BookingCreateData,
): Promise<BookingCreateResult> => {
  const bookingCreateResultDTO = await ky
    .post(`property/${propertyId}/bookings`, {
      json: mapBookingCreateDataToDTO(bookingCreateData),
    })
    .json<BookingCreateResultDTO>();

  return mapBookingCreateResultFromDTO(bookingCreateResultDTO);
};

export const sendConfirmBookingViaStripeIntentRequest = async (
  bookingId: string,
  intent: PaymentIntent | SetupIntent,
): Promise<BookingCreateConfirmedResult> => {
  const bookingCreateConfirmedResultDTO = await ky
    .post(`bookings/${bookingId}/confirm-via-stripe-action-required`, {
      json: {
        intentId: intent.id,
        intentStatus: intent.status,
      },
    })
    .json<BookingCreateConfirmedResultDTO>();

  return mapBookingCreateConfirmedResultFromDTO(
    bookingCreateConfirmedResultDTO,
  );
};

export const sendUpdateBookingRequest = async (
  booking: Booking,
  accessToken: string,
): Promise<Booking> => {
  const bookingDTO = await ky
    .patch(`bookings/${booking.id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
      json: mapBookingToUpdateDTO(booking),
    })
    .json<BookingDTO>();

  return mapBookingFromDTO(bookingDTO);
};

export const sendFetchBookingRequest = async (
  bookingId: string,
  accessToken: string,
): Promise<Booking> => {
  const bookingDTO = await ky
    .get(`bookings/${bookingId}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .json<BookingDTO>();

  return mapBookingFromDTO(bookingDTO);
};

export const sendFetchMessagingLinkRequest = (
  bookingId: string,
  accessToken: string,
): Promise<string> =>
  ky
    .get(`bookings/${bookingId}/messaging-conversation-link`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .text();
