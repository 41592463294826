import type {
  CancelledBookingUnit,
  CancelledBookingUnitDTO,
} from '@/booking/unit/cancelled/cancelled-booking-unit';

export const mapCancelledBookingUnitFromDTO = (
  cancelledBookingUnitDTO: CancelledBookingUnitDTO,
): CancelledBookingUnit => ({
  id: cancelledBookingUnitDTO.id,
  unitId: cancelledBookingUnitDTO.unitId,
  unitName: cancelledBookingUnitDTO.unitName,
  checkInDate: cancelledBookingUnitDTO.checkInDate,
  checkOutDate: cancelledBookingUnitDTO.checkOutDate,
  chargeAmount: Number(cancelledBookingUnitDTO.chargeAmount),
});
