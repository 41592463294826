import i18n from '@/i18n';

export const useLocaleString = () => {
  const { locale } = i18n.global;

  /**
   * Converts the given space-separated text into "Title Case".
   *
   * For example: "hello world" -> "Hello World".
   */
  const toTitleCase = (text: string): string =>
    text.replace(
      /([a-zA-Z]|[à-ü]|[À-Ü])\S*/g,
      (word) =>
        word.charAt(0).toLocaleUpperCase(locale.value) +
        word.substring(1).toLocaleLowerCase(locale.value),
    );

  /**
   * Capitalizes the first letter of the given text.
   *
   * For example: "hello world" -> "Hello world".
   */
  const capitalize = (text: string): string =>
    text.charAt(0).toLocaleUpperCase(locale.value) + text.slice(1);

  return {
    toTitleCase,
    capitalize,
  };
};
