import type {
  PrivateRateOverrideWayToSell,
  PrivateRateOverrideWayToSellDTO,
} from '@/private-rate-override/way-to-sell/private-rate-override-way-to-sell';

export const mapPrivateRateOverrideWayToSellFromDTO = (
  privateRateOverrideWayToSellDTO: PrivateRateOverrideWayToSellDTO,
): PrivateRateOverrideWayToSell => ({
  wayToSellId: privateRateOverrideWayToSellDTO.wayToSellId,
  rate: Number(privateRateOverrideWayToSellDTO.rate),
});
