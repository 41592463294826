import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { Booking } from '@/booking/booking';
import type { FacebookPixel } from '@/property/facebook-pixel/facebook-pixel';
import type { FacebookPixelPageViewEventAction } from '@/property/facebook-pixel/facebook-pixel-event-action';
import type { StayDates } from '@/stay-dates/stay-dates';
import { getStayLengthFromStayDates } from '@/stay-dates/stay-dates.utilities';

export const useFacebookPixelStore = defineStore('facebook-pixel-store', () => {
  const isPixelInitialized = ref(false);

  function emitPixelPageViewEvent(
    action: FacebookPixelPageViewEventAction,
    facebookPixel: FacebookPixel | undefined,
    stayDates?: StayDates,
  ) {
    if (typeof facebookPixel === 'undefined') {
      return;
    }

    initializePixel(facebookPixel);

    fbq('trackCustom', action, {
      check_in_date: stayDates ? stayDates.checkInDate : null,
      check_out_date: stayDates ? stayDates.checkOutDate : null,
      stay_length: stayDates ? getStayLengthFromStayDates(stayDates) : null,
    });
  }

  const emitPixelBookingCompleteEvent = (booking: Booking) => {
    if (typeof booking.property.facebookPixel === 'undefined') {
      return;
    }

    initializePixel(booking.property.facebookPixel);

    fbq('track', 'purchase', {
      value: booking.totalAmount,
      currency: booking.property.currencyCode,
    });
  };

  const initializePixel = (facebookPixel: FacebookPixel) => {
    if (!isPixelInitialized.value) {
      fbq('init', `${facebookPixel.id}`);
      isPixelInitialized.value = true;
    }
  };

  return {
    emitPixelPageViewEvent,
    emitPixelBookingCompleteEvent,
  };
});
