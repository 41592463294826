export enum BookingSourceType {
  GoogleHPA = 'GoogleHPA',
  GoogleZero = 'GoogleZero',
  GoogleVR = 'GoogleVR',
  VisitScotland = 'VisitScotland',
  TogetherSite = 'TogetherSite',
  Newmind = 'Newmind',
  Other = 'Other',
}

export type BookingSource =
  | {
      type: BookingSourceType.GoogleHPA;
      campaignId?: string;
    }
  | {
      type: BookingSourceType.GoogleZero;
    }
  | {
      type: BookingSourceType.GoogleVR;
      unitId: number;
    }
  | {
      type: BookingSourceType.VisitScotland;
      bookingId: string;
    }
  | {
      type: BookingSourceType.TogetherSite;
      siteId: number;
    }
  | {
      type: BookingSourceType.Newmind;
      affiliateCode: string;
    }
  | {
      type: BookingSourceType.Other;
      id: string;
    };
