const ID_PREFIX = 'UnitSelectionUnit-';
const ID_SUFFIX = '__scrollAnchor';

export const useUnitSelectionUnitScroller = () => {
  const getScrollAnchorId = (unitId: number): string =>
    `${ID_PREFIX}${unitId}${ID_SUFFIX}`;

  const scrollFirstUnitIntoView = () => {
    const firstScrollAnchor = document.querySelector(
      `[id^=${ID_PREFIX}][id$=${ID_SUFFIX}]`,
    );

    if (firstScrollAnchor) {
      scrollAnchorIntoView(firstScrollAnchor);
    }
  };

  const scrollUnitIntoView = (unitId: number) => {
    const scrollAnchor = document.getElementById(getScrollAnchorId(unitId));

    if (scrollAnchor) {
      scrollAnchorIntoView(scrollAnchor);
    }
  };

  const scrollAnchorIntoView = (anchor: Element) => {
    setTimeout(() => {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    });
  };

  return {
    getScrollAnchorId,
    scrollFirstUnitIntoView,
    scrollUnitIntoView,
  };
};
