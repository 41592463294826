import type {
  StripeMonetaryTransactionCharge,
  StripeMonetaryTransactionChargeDTO,
} from '@/property/payment-gateway/stripe/transaction-charge/monetary/stripe-monetary-transaction-charge';
import type {
  StripePercentageTransactionCharge,
  StripePercentageTransactionChargeDTO,
} from '@/property/payment-gateway/stripe/transaction-charge/percentage/stripe-percentage-transaction-charge';

export enum StripeTransactionChargeType {
  Monetary = 'monetary',
  Percentage = 'percentage',
}

export type StripeTransactionCharge =
  | StripeMonetaryTransactionCharge
  | StripePercentageTransactionCharge;

export type StripeTransactionChargeDTO =
  | StripeMonetaryTransactionChargeDTO
  | StripePercentageTransactionChargeDTO;
