import type {
  BookingAdHocSupplement,
  BookingAdHocSupplementDTO,
} from '@/booking/ad-hoc-supplement/booking-ad-hoc-supplement';

export const mapBookingAdHocSupplementFromDTO = (
  bookingAdHocSupplement: BookingAdHocSupplementDTO,
): BookingAdHocSupplement => ({
  name: bookingAdHocSupplement.name,
  description: bookingAdHocSupplement.description,
  quantity: bookingAdHocSupplement.quantity,
  itemCharge: Number(bookingAdHocSupplement.itemCharge),
});
