import { mapDescriptivePromocodeFromDTO } from '@/property/promocode/descriptive-promocode/descriptive-promocode.mapper';
import { mapMonetaryPromocodeFromDTO } from '@/property/promocode/monetary-promocode/monetary-promocode.mapper';
import { mapPercentagePromocodeFromDTO } from '@/property/promocode/percentage-promocode/percentage-promocode.mapper';
import type { Promocode, PromocodeDTO } from '@/property/promocode/promocode';
import { PromocodeType } from '@/property/promocode/promocode';

export const mapPromocodeFromDTO = (promocodeDTO: PromocodeDTO): Promocode => {
  const { promocodeType } = promocodeDTO;

  switch (promocodeType) {
    case PromocodeType.Percentage:
      return mapPercentagePromocodeFromDTO(promocodeDTO);
    case PromocodeType.Monetary:
      return mapMonetaryPromocodeFromDTO(promocodeDTO);
    case PromocodeType.Descriptive:
      return mapDescriptivePromocodeFromDTO(promocodeDTO);
  }
};
