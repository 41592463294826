import { watchThrottled } from '@vueuse/core';
import { get, set } from 'idb-keyval';
import { cloneDeep } from 'lodash-es';
import type { Ref } from 'vue';
import { ref } from 'vue';

/**
 * Synchronizes the value of the given ref to Indexed DB, under the provided
 * key.
 *
 * The value of the ref can optionally be initialized with the current IDB
 * value.
 */
export const useIDBRef = <T>(
  key: IDBValidKey,
  refToIDBValue: Ref<T>,
  { shouldInitialize }: Options = {
    shouldInitialize: true,
  },
) => {
  const load = () => get<T>(key);

  const save = () => set(key, cloneDeep(refToIDBValue.value));

  const isInitialized = ref(false);

  const initialize = async () => {
    const persistedValue = await load();

    if (persistedValue) {
      refToIDBValue.value = persistedValue;
    }

    isInitialized.value = true;
  };

  if (shouldInitialize) {
    void initialize();
  }

  watchThrottled(refToIDBValue, save, {
    throttle: 500,
    leading: true,
    trailing: true,
    deep: true,
    immediate: !shouldInitialize,
  });

  return {
    isInitialized,
  };
};

interface Options {
  shouldInitialize: boolean;
}
