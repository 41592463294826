<template>
  <AppImageCarouselImageError v-if="hasErrored" />

  <div v-else class="relative h-full w-full">
    <picture v-if="shouldLoadPlaceholderImage && !hasLoaded">
      <AppImageCarouselImageSourceSet
        :generate-image-url="generateImageUrl"
        is-placeholder
      />

      <img
        :src="generateImageUrl(500, true)"
        alt=""
        class="absolute inset-0 m-auto max-h-full max-w-full"
      />
    </picture>

    <picture v-if="shouldLoadFullScaleImage">
      <AppImageCarouselImageSourceSet :generate-image-url="generateImageUrl" />

      <img
        :src="generateImageUrl(1000)"
        alt=""
        class="absolute inset-0 m-auto max-h-full max-w-full"
        @error="hasErrored = true"
        @load="hasLoaded = true"
      />
    </picture>
  </div>
</template>

<script setup lang="ts">
import { format, quality } from '@cloudinary/url-gen/actions/delivery';
import { blur } from '@cloudinary/url-gen/actions/effect';
import { limitFit } from '@cloudinary/url-gen/actions/resize';
import { auto } from '@cloudinary/url-gen/qualifiers/format';
import { ref } from 'vue';
import { cloudinary } from '@/cloudinary';
import AppImageCarouselImageError from '@/ui/app-image-carousel/AppImageCarouselImageError.vue';
import AppImageCarouselImageSourceSet from '@/ui/app-image-carousel/AppImageCarouselImageSourceSet.vue';

const props = defineProps<{
  url: string;
  shouldLoadPlaceholderImage: boolean;
  shouldLoadFullScaleImage: boolean;
}>();

const hasLoaded = ref(false);
const hasErrored = ref(false);

const generateImageUrl = (width: number, isPlaceholder = false): string => {
  const image = cloudinary
    .image(props.url)
    .resize(limitFit().width(width))
    .setDeliveryType('fetch')
    .delivery(format(auto()));

  if (isPlaceholder) {
    image.effect(blur(500)).delivery(quality('auto'));
  }

  return image.toURL();
};
</script>
