import type { LocationQuery } from 'vue-router';
import type { AbandonedCartRecoveryRequest } from '@/abandoned-cart/recovery-request/abandoned-cart-recovery-request';
import type { SerializableBookingItinerary } from '@/booking-itinerary/booking-itinerary';
import type { BookingSource } from '@/booking-source/booking-source';
import type { Elements } from '@/elements/elements';
import type { Occupancy } from '@/occupancy/occupancy';
import type { StayDates } from '@/stay-dates/stay-dates';
import { safeJsonParse } from '@/string/string.utilities';
import {
  BOOKING_ITINERARY_QUERY_PARAM_KEY,
  SEARCH_OCCUPANCIES_QUERY_PARAM_KEY,
  SEARCH_STAY_DATES_QUERY_PARAM_KEY,
  BOOKING_SOURCE_QUERY_PARAM_KEY,
  LOCALE_QUERY_PARAM_KEY,
  ELEMENTS_QUERY_PARAM_KEY,
  ABANDONED_CART_RECOVERY_REQUEST_QUERY_PARAM_KEY,
} from '@/url/query-params';

export const findSearchStayDatesQueryParamValue = ({
  [SEARCH_STAY_DATES_QUERY_PARAM_KEY]: searchStayDates,
}: LocationQuery): StayDates | undefined =>
  searchStayDates
    ? (safeJsonParse(searchStayDates as string) as StayDates | undefined)
    : undefined;

export const findSearchOccupanciesQueryParamValue = ({
  [SEARCH_OCCUPANCIES_QUERY_PARAM_KEY]: searchOccupancies,
}: LocationQuery): Occupancy[] | undefined =>
  searchOccupancies
    ? (safeJsonParse(searchOccupancies as string) as Occupancy[] | undefined)
    : undefined;

export const findBookingItineraryQueryParamValue = ({
  [BOOKING_ITINERARY_QUERY_PARAM_KEY]: bookingItinerary,
}: LocationQuery): SerializableBookingItinerary | undefined =>
  bookingItinerary
    ? (safeJsonParse(bookingItinerary as string) as
        | SerializableBookingItinerary
        | undefined)
    : undefined;

export const findBookingSourceQueryParamValue = ({
  [BOOKING_SOURCE_QUERY_PARAM_KEY]: bookingSource,
}: LocationQuery): BookingSource | undefined =>
  bookingSource
    ? (safeJsonParse(bookingSource as string) as BookingSource | undefined)
    : undefined;

export const findLocaleQueryParamValue = ({
  [LOCALE_QUERY_PARAM_KEY]: locale,
}: LocationQuery): string | undefined => locale as string | undefined;

export const findElementsQueryParamValue = ({
  [ELEMENTS_QUERY_PARAM_KEY]: elements,
}: LocationQuery): Elements | undefined =>
  elements
    ? (safeJsonParse(elements as string) as Elements | undefined)
    : undefined;

export const findAbandonedCartRecoveryRequestQueryParamValue = ({
  [ABANDONED_CART_RECOVERY_REQUEST_QUERY_PARAM_KEY]:
    abandonedCartRecoveryRequest,
}: LocationQuery): AbandonedCartRecoveryRequest | undefined =>
  abandonedCartRecoveryRequest
    ? (safeJsonParse(abandonedCartRecoveryRequest as string) as
        | AbandonedCartRecoveryRequest
        | undefined)
    : undefined;
