<template>
  <div class="flex flex-col gap-1">
    <BookingConfirmationAssuranceStayDates />

    <BookingConfirmationAssuranceAmountDue />

    <BookingConfirmationAssuranceFreeCancellation
      v-if="!bookingConfirmationStore.bookingHasCheckedIn"
    />
  </div>
</template>
<script setup lang="ts">
import BookingConfirmationAssuranceAmountDue from '@/booking-confirmation-page/assurances/amount-due/BookingConfirmationAssuranceAmountDue.vue';
import BookingConfirmationAssuranceFreeCancellation from '@/booking-confirmation-page/assurances/BookingConfirmationAssuranceFreeCancellation.vue';
import BookingConfirmationAssuranceStayDates from '@/booking-confirmation-page/assurances/BookingConfirmationAssuranceStayDates.vue';
import { useBookingConfirmationStore } from '@/booking-confirmation-page/booking-confirmation.store';

const bookingConfirmationStore = useBookingConfirmationStore();
</script>
