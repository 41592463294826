<template>
  <Transition
    enter-active-class="duration-200 ease-out"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="duration-200 ease-in"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <PaymentsLoadingSpinnerOverlay
      v-if="sagePayCheckoutStore.showLoadingOverlay"
    />

    <PaymentsLoadingTextOverlay
      v-else-if="sagePayCheckoutStore.isCreatingBooking"
      :text="$t('confirmingYourBooking')"
    />
  </Transition>
</template>

<script setup lang="ts">
import PaymentsLoadingSpinnerOverlay from '@/payments-page/loading-overlay/PaymentsLoadingSpinnerOverlay.vue';
import PaymentsLoadingTextOverlay from '@/payments-page/loading-overlay/PaymentsLoadingTextOverlay.vue';
import { useSagePayCheckoutStore } from '@/payments-page/sage-pay-checkout/sage-pay-checkout.store';

const sagePayCheckoutStore = useSagePayCheckoutStore();
</script>
