<template>
  <div class="grid gap-2">
    <div v-if="pricePlan.offer.description">
      <UnitSelectionPricePlanPackageDescription
        :description="pricePlan.offer.description"
      />
    </div>

    <UnitSelectionPricePlanPackageMeals :meals="meals" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { PackagePricePlanVariant } from '@/price-plan/price-plan';
import { findPackageOfferIncludedPropertyMeals } from '@/property/offer/package-offer/package-offer.utilities';
import { useSearchStore } from '@/search/search.store';
import UnitSelectionPricePlanPackageDescription from '@/unit-selection-page/price-plan/package/description/UnitSelectionPricePlanPackageDescription.vue';
import UnitSelectionPricePlanPackageMeals from '@/unit-selection-page/price-plan/package/meal/UnitSelectionPricePlanPackageMeals.vue';

const props = defineProps<{
  pricePlan: PackagePricePlanVariant;
}>();

const searchStore = useSearchStore();

const meals = computed(() =>
  findPackageOfferIncludedPropertyMeals(
    props.pricePlan.offer,
    searchStore.activeProperty,
  ),
);
</script>
