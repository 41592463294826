import type {
  PresetUnitFacility,
  PresetUnitFacilityDTO,
} from '@/property/unit/preset-unit-facility/preset-unit-facility';

export const mapPresetUnitFacilityFromDTO = (
  presetUnitFacilityDTO: PresetUnitFacilityDTO,
): PresetUnitFacility => ({
  id: presetUnitFacilityDTO.id,
  name: presetUnitFacilityDTO.name,
});
