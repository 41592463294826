import type { Occupancy } from '@/occupancy/occupancy';
import type {
  MealPricePlan,
  StandardMealPricePlanVariant,
} from '@/price-plan/meal-price-plan/meal-price-plan';
import type { UnitPricePlan } from '@/price-plan/unit-price-plan/unit-price-plan';
import type { WayToSellPricePlan } from '@/price-plan/way-to-sell-price-plan/way-to-sell-price-plan';
import type { PrivateRateOverride } from '@/private-rate-override/private-rate-override';
import type { IncludedMeal } from '@/property/meal/included-meal/included-meal';
import type { Offer } from '@/property/offer/offer';
import type { PackageOffer } from '@/property/offer/package-offer/package-offer';
import type { Promocode } from '@/property/promocode/promocode';
import type { Unit } from '@/property/unit/unit';

export enum PricePlanVariantType {
  Standard = 'standard',
  Discounted = 'discounted',
  Package = 'package',
  Private = 'private',
}

interface BasePricePlan {
  id: string;
  unit: Unit;
  rate: number;
  occupancy: Occupancy;
  searchOccupancy: Occupancy;
}

interface PromocodeApplicable {
  promocode: Promocode | undefined;
}

interface IncludedMealsApplicable {
  includedMeals: IncludedMeal[];
}

interface StandardPricePlanVariant
  extends BasePricePlan,
    PromocodeApplicable,
    IncludedMealsApplicable {
  readonly type: PricePlanVariantType.Standard;
  mealPricePlans: StandardMealPricePlanVariant[];
  preDiscountRate: number | undefined;
  offer?: never;
}

export interface DiscountedPricePlanVariant
  extends BasePricePlan,
    PromocodeApplicable,
    IncludedMealsApplicable {
  readonly type: PricePlanVariantType.Discounted;
  offer: Exclude<Offer, PackageOffer>;
  preDiscountRate: number;
  mealPricePlans: MealPricePlan[];
}

export interface PackagePricePlanVariant extends BasePricePlan {
  readonly type: PricePlanVariantType.Package;
  offer: PackageOffer;
  preDiscountRate?: never;
}

export interface PrivatePricePlanVariant extends BasePricePlan {
  readonly type: PricePlanVariantType.Private;
  privateRateOverride: PrivateRateOverride;
  offer?: never;
  preDiscountRate?: never;
}

export type PricePlanVariant =
  | StandardPricePlanVariant
  | DiscountedPricePlanVariant
  | PackagePricePlanVariant
  | PrivatePricePlanVariant;

export type PricePlan = UnitPricePlan | WayToSellPricePlan;

export type NonPackagePricePlanVariant =
  | StandardPricePlanVariant
  | DiscountedPricePlanVariant;

export interface GroupedPricePlans {
  refundablePricePlans: NonPackagePricePlanVariant[];
  nonRefundablePricePlans: DiscountedPricePlanVariant[];
  packagePricePlans: PackagePricePlanVariant[];
  privatePricePlans: PrivatePricePlanVariant[];
}
