export enum InternalMessageEventDataType {
  PcibCardCaptureSuccess = 'pcib_card_capture_success',
  PcibCardCaptureFailure = 'pcib_card_capture_failure',
}

export interface InternalMessageEventPcibCardCaptureSuccessData {
  type: InternalMessageEventDataType.PcibCardCaptureSuccess;
  cardTokenUrl: string;
  cardType: string;
  cardHolderName: string;
  cardExpiration: string;
}

interface InternalMessageEventPcibCardCaptureFailureData {
  type: InternalMessageEventDataType.PcibCardCaptureFailure;
}

export type InternalMessageEventData =
  | InternalMessageEventPcibCardCaptureSuccessData
  | InternalMessageEventPcibCardCaptureFailureData;

export type InternalMessageEvent = MessageEvent<InternalMessageEventData>;
