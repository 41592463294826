import type { MealRateAdjustment } from '@/property/meal/meal-rate-adjustment/meal-rate-adjustment';
import { MealRateAdjustmentType } from '@/property/meal/meal-rate-adjustment/meal-rate-adjustment';
import { applyMealRateAdjustmentStandardRatePercentageToValue } from '@/property/meal/meal-rate-adjustment/standard-rate-percentage/meal-rate-adjustment-standard-rate-percentage.utilities';

export const applyMealRateAdjustmentToValue = (
  mealRateAdjustment: MealRateAdjustment,
  value: number,
): number => {
  switch (mealRateAdjustment.adjustmentType) {
    case MealRateAdjustmentType.None:
      return value;
    case MealRateAdjustmentType.PerNightMonetary:
      return mealRateAdjustment.amount;
    case MealRateAdjustmentType.StandardRatePercentage:
      return applyMealRateAdjustmentStandardRatePercentageToValue(
        mealRateAdjustment,
        value,
      );
    case MealRateAdjustmentType.Free:
      return 0;
  }
};
