<template>
  <div class="grid grid-cols-2 gap-4 font-bold">
    <span class="self-center font-bold">{{ $t('totalAmountPaid') }}</span>

    <span class="self-center truncate text-right">{{
      $n(booking.amountPaid, {
        style: 'currency',
        currency: property.currencyCode,
      })
    }}</span>

    <span class="self-center font-bold">{{ $t('amountDue') }}</span>

    <span class="self-center truncate text-right">{{
      $n(booking.amountDue, {
        style: 'currency',
        currency: property.currencyCode,
      })
    }}</span>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useBookingConfirmationStore } from '@/booking-confirmation-page/booking-confirmation.store';

const { property, booking } = storeToRefs(useBookingConfirmationStore());
</script>
