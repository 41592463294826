<template>
  <header
    data-test="BookingSummaryHeader"
    class="w-full border-b border-gray-300 p-6"
  >
    <div class="mb-3 text-xl font-semibold capitalize">
      {{ $t('lengthOfNightStay', { count: stayLength }) }}
    </div>

    <div
      class="grid grid-flow-col grid-cols-[min-content_1fr_min-content] grid-rows-[repeat(3,auto)]"
    >
      <BookingSummaryHeaderStayDate
        is-check-in-date
        :date="bookingItineraryStore.stayDates!.checkInDate"
      />

      <div></div>

      <AppArrow
        class="w-[80%] self-center justify-self-center text-icon-accent-color"
      />

      <div></div>

      <BookingSummaryHeaderStayDate
        :is-check-in-date="false"
        :date="bookingItineraryStore.stayDates!.checkOutDate"
      />
    </div>
  </header>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import BookingSummaryHeaderStayDate from '@/booking-summary/header/BookingSummaryHeaderStayDate.vue';
import { getStayLengthFromStayDates } from '@/stay-dates/stay-dates.utilities';
import AppArrow from '@/ui/app-arrow/AppArrow.vue';

const bookingItineraryStore = useBookingItineraryStore();

const stayLength = computed(() =>
  getStayLengthFromStayDates(bookingItineraryStore.stayDates!),
);
</script>
