<template>
  <div v-if="extra.question">
    <label :for="textAreaLabel">
      <div
        class="break-anywhere mb-1 font-semibold text-zinc-600"
        translate="yes"
      >
        {{ extra.question }}
      </div>

      <textarea
        :id="textAreaLabel"
        v-model.trim="answer"
        class="app-textarea"
        :aria-describedby="characterCounterLabel"
        :rows="NUMBER_OF_ROWS"
        :maxlength="MAX_CHARACTER_LENGTH"
      ></textarea>
    </label>

    <AppCharacterCounter
      :id="characterCounterLabel"
      class="mt-1 flex justify-end text-sm"
      :current-number-of-characters="answer.length"
      :maximum-number-of-characters="MAX_CHARACTER_LENGTH"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useExtraItineraryItemStore } from '@/booking-itinerary/extra-itinerary/store/extra-itinerary-item.store';
import { useMandatoryExtraItineraryItemStore } from '@/booking-itinerary/extra-itinerary/store/mandatory-extra-itinerary-item.store';
import type { Extra } from '@/property/extra/extra';
import AppCharacterCounter from '@/ui/app-character-counter/AppCharacterCounter.vue';

const NUMBER_OF_ROWS = 2;
const MAX_CHARACTER_LENGTH = 128;

const props = defineProps<{
  extra: Extra;
}>();

const extraItineraryItemStore = props.extra.isMandatory
  ? useMandatoryExtraItineraryItemStore(props.extra)
  : useExtraItineraryItemStore(props.extra);

const answer = computed({
  get() {
    return extraItineraryItemStore.answer ?? '';
  },
  set(newAnswer) {
    extraItineraryItemStore.answer = newAnswer;
  },
});

const textAreaLabel = computed(() => `extra_${props.extra.id}_question`);

const characterCounterLabel = computed(
  () => `character_counter_${textAreaLabel.value}`,
);
</script>
