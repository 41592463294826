<template>
  <div class="whitespace-nowrap text-xs font-semibold first-letter:capitalize">
    {{ $t(isCheckInDate ? 'checkIn' : 'checkOut') }}
  </div>

  <div class="whitespace-nowrap font-semibold">
    {{
      $d(dateObject, {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      })
    }}
  </div>

  <div class="text-sm text-neutral-500">
    {{ $d(dateObject, { weekday: 'long' }) }}
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { createDateObject } from '@/date/date.utilities';

const props = defineProps<{ isCheckInDate: boolean; date: string }>();

const dateObject = computed(() => createDateObject(props.date));
</script>
