import { z } from 'zod';

export const UserEventIdentifierSchema = z.object({
  widgetId: z.string(),
  propertyId: z.number(),
  uniqueUserId: z.string(),
  createdAtTimestamp: z.number(),
});

export type UserEventIdentifier = z.infer<typeof UserEventIdentifierSchema>;
