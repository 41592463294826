import type {
  GoogleAnalytics,
  GoogleAnalyticsDTO,
} from '@/property/google/analytics/google-analytics';

export const mapGoogleAnalyticsFromDTO = (
  googleAnalyticsDTO: GoogleAnalyticsDTO,
): GoogleAnalytics => ({
  key: googleAnalyticsDTO.analyticsKey,
  version: googleAnalyticsDTO.analyticsVersion,
  isEcommerceEnabled: googleAnalyticsDTO.isEcommerceEnabled,
  sourceDomain: googleAnalyticsDTO.sourceDomain,
});
