<template>
  <div v-if="checkInNotes">
    <span class="font-medium capitalize">{{ $t('notes') }}:</span>
    {{ checkInNotes }}
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useSearchStore } from '@/search/search.store';

const searchStore = useSearchStore();

const checkInNotes = computed(
  () => searchStore.activeProperty.checkInPolicy.checkInNotes,
);
</script>
