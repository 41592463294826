import { Decimal } from 'decimal.js';
import type { DepositPolicyCharge } from '@/property/booking-policy/deposit-policy/charge/deposit-policy-charge';
import { DepositPolicyChargeType } from '@/property/booking-policy/deposit-policy/charge/deposit-policy-charge';
import type { NightlyRates } from '@/rates/nightly-rates/nightly-rates';
import { getTotalRateFromNightlyRates } from '@/rates/nightly-rates/nightly-rates.utilities';

export const calculateDepositPolicyChargeAmount = (
  charge: DepositPolicyCharge,
  totalRate: number,
  nightlyRates: NightlyRates,
  numberOfUnits: number,
): number => {
  switch (charge.chargeType) {
    case DepositPolicyChargeType.Percentage:
      return Decimal.mul(
        totalRate,
        Decimal.div(charge.percentage, 100),
      ).toNumber();
    case DepositPolicyChargeType.Nightly:
      return nightlyRates.length <= charge.numberOfNights
        ? totalRate
        : getTotalRateFromNightlyRates(
            nightlyRates.slice(0, charge.numberOfNights),
          );
    case DepositPolicyChargeType.Monetary:
      return charge.amount;
    case DepositPolicyChargeType.PerUnitMonetary:
      return Decimal.mul(numberOfUnits, charge.amountPerUnit).toNumber();
  }
};
