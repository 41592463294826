<template>
  <div class="text-xl font-bold">
    {{
      $n(booking.totalAmount, {
        style: 'currency',
        currency: property.currencyCode,
      })
    }}
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useBookingConfirmationStore } from '@/booking-confirmation-page/booking-confirmation.store';

const { property, booking } = storeToRefs(useBookingConfirmationStore());
</script>
