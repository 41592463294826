import type {
  DateRangedBookingPolicyDateRange,
  DateRangedBookingPolicyDateRangeDTO,
} from '@/property/booking-policy/date-ranged-booking-policy/date-ranged-booking-policy-date-range/date-ranged-booking-policy-date-range';

export const mapDateRangedBookingPolicyDateRangeFromDTO = (
  bookingPolicyDateRangeDTO: DateRangedBookingPolicyDateRangeDTO,
): DateRangedBookingPolicyDateRange => ({
  fromDate: bookingPolicyDateRangeDTO.fromDate,
  toDate: bookingPolicyDateRangeDTO.toDate,
});
