import { generateUniqueId } from '@/generator/generator.utilities';
import { MealPricePlanVariantType } from '@/price-plan/meal-price-plan/meal-price-plan';
import type {
  DiscountedMealPricePlanVariant,
  MealPricePlan,
  StandardMealPricePlanVariant,
} from '@/price-plan/meal-price-plan/meal-price-plan';
import type { MealRates } from '@/rates/meal-rates/meal-rates';
import { findMealRatesWithMealType } from '@/rates/meal-rates/meal-rates.utilities';
import { getTotalRateFromNightlyRates } from '@/rates/nightly-rates/nightly-rates.utilities';

/**
 * For each of the given standard meal rates, returns a meal price plan.
 *
 * Discounted meal rates may also be provided. If any standard meal rates have
 * corresponding discounted rates (i.e., for the same meal), then a discounted
 * price plan will be returned. Otherwise, a standard price plan is returned.
 */
export const createMealPricePlans = (
  standardMealsRates: MealRates[],
  discountedMealsRates: MealRates[],
): MealPricePlan[] =>
  standardMealsRates.map((standardMealRates) => {
    const discountedMealRates = findMealRatesWithMealType(
      discountedMealsRates,
      standardMealRates.meal.type,
    );

    return discountedMealRates
      ? createDiscountedMealPricePlan(standardMealRates, discountedMealRates)
      : createStandardMealPricePlan(standardMealRates);
  });

export const createStandardMealPricePlans = (
  mealsRates: MealRates[],
): StandardMealPricePlanVariant[] =>
  mealsRates.map((mealRates) => createStandardMealPricePlan(mealRates));

const createStandardMealPricePlan = (
  mealRates: MealRates,
): StandardMealPricePlanVariant => ({
  id: generateUniqueId(),
  type: MealPricePlanVariantType.Standard,
  meal: mealRates.meal,
  rate: getTotalRateFromNightlyRates(mealRates.nightlyRates),
});

const createDiscountedMealPricePlan = (
  standardMealRates: MealRates,
  discountedMealRates: MealRates,
): DiscountedMealPricePlanVariant => ({
  id: generateUniqueId(),
  type: MealPricePlanVariantType.Discounted,
  meal: discountedMealRates.meal,
  rate: getTotalRateFromNightlyRates(discountedMealRates.nightlyRates),
  preDiscountRate: getTotalRateFromNightlyRates(standardMealRates.nightlyRates),
});
