import type {
  LateBookingThreshold,
  LateBookingThresholdDTO,
} from '@/property/late-booking-threshold/late-booking-threshold';

export const mapLateBookingThresholdFromDTO = (
  lateBookingThresholdDTO: LateBookingThresholdDTO,
): LateBookingThreshold => ({
  numberOfDaysBeforeArrival: lateBookingThresholdDTO.numberOfDaysBeforeArrival,
  time: lateBookingThresholdDTO.time,
});
