<template>
  <div v-if="sortedUnitImages.length > 0" class="relative shrink-0">
    <AppMiniImageCarousel
      :images="sortedUnitImages"
      :image-label="unitTypeName"
      :is-loading="searchStore.isLoading"
      @open-carousel="openFullScreenCarousel"
    />
    <AppFullscreenOverlay
      :is-open="isFullscreenOverlayOpen"
      class="z-30"
      @close="isFullscreenOverlayOpen = showImageCarousel = false"
      @transition-enter-finished="
        () => {
          showImageCarousel = true;
        }
      "
    >
      <div class="mx-auto my-0 h-full">
        <Transition
          enter-active-class="transition-opacity ease-linear duration-200"
          enter-from-class="opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="transition-opacity ease-linear duration-200"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <AppImageCarousel
            v-if="showImageCarousel"
            :images="sortedUnitImages"
            :initial-image-id="initialImageId!"
          />
        </Transition>
      </div>
    </AppFullscreenOverlay>

    <AppRateBanner
      v-if="!searchStore.isLoading && bannerRate"
      :rate="bannerRate"
      :currency-code="searchStore.activeProperty.currencyCode"
      class="absolute right-0 top-3"
    />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useUnitType } from '@/property/unit/type/unit-type.composable';
import { sortUnitImagesByPriority } from '@/property/unit/unit.utilities';
import { useSearchStore } from '@/search/search.store';
import AppRateBanner from '@/ui/app-banner/AppRateBanner.vue';
import AppFullscreenOverlay from '@/ui/app-fullscreen-overlay/AppFullscreenOverlay.vue';
import AppImageCarousel from '@/ui/app-image-carousel/AppImageCarousel.vue';
import AppMiniImageCarousel from '@/ui/app-image-carousel/AppMiniImageCarousel.vue';
import { useUnitSelectionStore } from '@/unit-selection-page/unit-selection.store';

const props = defineProps<{
  unitId: number;
}>();

const searchStore = useSearchStore();
const unitSelectionStore = useUnitSelectionStore();

const { pricePlans } = storeToRefs(unitSelectionStore);

const unitOverStay = computed(() =>
  searchStore.activePropertyOverStay.findUnitOverStayByUnitIdOrFail(
    props.unitId,
  ),
);

const bannerRate = computed(() =>
  unitOverStay.value.isAvailable
    ? pricePlans.value.findFirstPricePlanForUnitId(props.unitId).rate
    : null,
);

const initialImageId = ref<number>();

const isFullscreenOverlayOpen = ref(false);
const showImageCarousel = ref(false);

const sortedUnitImages = computed(() =>
  sortUnitImagesByPriority(unitOverStay.value.unit),
);

const { unitTypeName } = useUnitType(
  computed(() => searchStore.activeProperty.unitType),
);

const openFullScreenCarousel = (imageId: number) => {
  isFullscreenOverlayOpen.value = true;
  initialImageId.value = imageId;
};
</script>
