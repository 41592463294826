import { partition } from 'lodash-es';
import type { ComputedRef, Ref } from 'vue';
import { computed, reactive } from 'vue';
import type { DailyPropertyAvailability } from '@/availability/daily-property-availability/daily-property-availability';
import type { PropertyAvailability } from '@/availability/property-availability/property-availability';
import {
  findPropertyAvailabilityDailyPropertyAvailabilityByDateOrFail,
  getPropertyAvailabilityStayDates,
} from '@/availability/property-availability/property-availability.utilities';
import {
  CodeResourceType,
  type CodeResource,
} from '@/code/resource/code-resource';
import { findPrivateRateOverrideUnitById } from '@/private-rate-override/private-rate-override.utilities';
import { getApplicableBookingPolicyFromBookingPoliciesForStayDates } from '@/property/booking-policy/booking-policies/booking-policies.utilities';
import { useBookingPolicyOverStay } from '@/property/booking-policy/over-stay/booking-policy-over-stay.composable';
import { isExtraApplicableOnStayDates } from '@/property/extra/extra.utilities';
import { MealType } from '@/property/meal/meal';
import { isMealApplicableOnStayDates } from '@/property/meal/meal.utilities';
import { isOfferApplicableOnStayDates } from '@/property/offer/offer.utilities';
import type { Property } from '@/property/property';
import {
  findPropertyIncludedMeals,
  findPropertySupplementalMeals,
  isDateCurrentlyAllowedByPropertyLateBookingThresholds,
} from '@/property/property.utilities';
import type { Unit } from '@/property/unit/unit';
import type { UnitOverStay } from '@/property/unit/unit-over-stay/unit-over-stay';
import { useUnitOverStay } from '@/property/unit/unit-over-stay/unit-over-stay.composable';

export const usePropertyOverStay = (
  property: Ref<Property>,
  maybePropertyAvailability: Ref<PropertyAvailability | undefined>,
  codeResource?: ComputedRef<CodeResource | undefined>,
) => {
  const propertyAvailability = computed(() => {
    if (!maybePropertyAvailability.value) {
      throw new Error(
        `No property availability found for property ${property.value.id}`,
      );
    }

    return maybePropertyAvailability.value;
  });

  const stayDates = computed(() =>
    getPropertyAvailabilityStayDates(propertyAvailability.value),
  );

  const dailyPropertyAvailabilityForCheckInDate = computed(() =>
    findDailyPropertyAvailabilityByDate(stayDates.value.checkInDate),
  );

  const dailyPropertyAvailabilityForCheckOutDate = computed(() =>
    findDailyPropertyAvailabilityByDate(stayDates.value.checkOutDate),
  );

  const isClosedToArrival = computed(
    () =>
      dailyPropertyAvailabilityForCheckInDate.value.isClosedToArrival ||
      !isDateCurrentlyAllowedByPropertyLateBookingThresholds(
        dailyPropertyAvailabilityForCheckInDate.value.date,
        property.value,
      ),
  );

  const isClosedToDeparture = computed(
    () => dailyPropertyAvailabilityForCheckOutDate.value.isClosedToDeparture,
  );

  const unitsOverStay = computed(() =>
    property.value.units
      .filter((unit) => !shouldCodeResourceExcludeUnit(unit.id))
      .map((unit) =>
        useUnitOverStay(unit, propertyAvailability.value, codeResource?.value),
      ),
  );

  const availabilityPartitionedUnitsOverStay = computed(() =>
    partition(unitsOverStay.value, ({ isAvailable }) => isAvailable),
  );

  const availableUnitsOverStay = computed(
    () => availabilityPartitionedUnitsOverStay.value[0],
  );

  const unavailableUnitsOverStay = computed(
    () => availabilityPartitionedUnitsOverStay.value[1],
  );

  const units = computed(() => unitsOverStay.value.map(({ unit }) => unit));

  const availableUnits = computed(() =>
    availableUnitsOverStay.value.map(({ unit }) => unit),
  );

  const unavailableUnits = computed(() =>
    unavailableUnitsOverStay.value.map(({ unit }) => unit),
  );

  const availableUnitIds = computed(() =>
    availableUnits.value.map(({ id }) => id),
  );

  const unavailableUnitIds = computed(() =>
    unavailableUnits.value.map(({ id }) => id),
  );

  const numberOfAvailableUnits = computed(() => availableUnitIds.value.length);

  const numberOfUnavailableUnits = computed(
    () => unavailableUnitIds.value.length,
  );

  const hasAvailableUnits = computed(() => numberOfAvailableUnits.value > 0);

  const allUnitsAreUnavailable = computed(
    () => numberOfUnavailableUnits.value === units.value.length,
  );

  const unitsThatOnlyViolateMinimumStay = computed(() =>
    unitsOverStay.value.filter(
      ({ onlyViolatesMinimumStay }) => onlyViolatesMinimumStay,
    ),
  );

  const hasUnitsThatOnlyViolateMinimumStay = computed(
    () => unitsThatOnlyViolateMinimumStay.value.length > 0,
  );

  const shortestMinimumStayForUnitsThatOnlyViolateMinimumStay = computed(() =>
    Math.min(
      ...unitsThatOnlyViolateMinimumStay.value.map(
        ({ minimumStay }) => minimumStay,
      ),
    ),
  );

  const includedMeals = computed(() => {
    const includedMeals = findPropertyIncludedMeals(property.value);

    return includedMeals.filter((meal) =>
      isMealApplicableOnStayDates(
        meal,
        propertyAvailability.value,
        stayDates.value,
      ),
    );
  });

  const supplementalMeals = computed(() => {
    const supplementalMeals = findPropertySupplementalMeals(property.value);

    return supplementalMeals.filter((meal) =>
      isMealApplicableOnStayDates(
        meal,
        propertyAvailability.value,
        stayDates.value,
      ),
    );
  });

  const supplementalMealTypes = computed(() =>
    supplementalMeals.value.map(({ type }) => type),
  );

  const extras = computed(() =>
    property.value.extras.filter((extra) =>
      isExtraApplicableOnStayDates(extra, stayDates.value),
    ),
  );

  const offers = computed(() =>
    property.value.offers.filter((offer) =>
      isOfferApplicableOnStayDates(
        offer,
        propertyAvailability.value,
        stayDates.value,
        property.value.timezone,
      ),
    ),
  );

  const offerIds = computed(() => offers.value.map(({ id }) => id));

  const bookingPolicy = computed(() =>
    getApplicableBookingPolicyFromBookingPoliciesForStayDates(
      property.value.bookingPolicies,
      stayDates.value,
      property.value.timezone,
    ),
  );

  const bookingPolicyOverStay = useBookingPolicyOverStay(
    property,
    bookingPolicy,
    stayDates,
  );

  const isAvailable = computed(
    () =>
      !isClosedToArrival.value &&
      !isClosedToDeparture.value &&
      hasAvailableUnits.value,
  );

  const isOfferAvailable = (offerId: string): boolean =>
    offerIds.value.includes(offerId);

  const isSupplementalMealAvailable = (
    supplementalMealType: MealType,
  ): boolean => supplementalMealTypes.value.includes(supplementalMealType);

  const findUnitById = (unitId: number): Unit =>
    property.value.units.find(({ id }) => id === unitId)!;

  const findUnitOverStayByUnitId = (unitId: number): UnitOverStay | undefined =>
    unitsOverStay.value.find(({ unit }) => unit.id === unitId);

  const findUnitOverStayByUnitIdOrFail = (unitId: number): UnitOverStay => {
    const unitOverStay = findUnitOverStayByUnitId(unitId);

    if (!unitOverStay) {
      throw new Error(`Could not find unit over stay for unit ${unitId}`);
    }

    return unitOverStay;
  };

  const findNumberOfUnitsAvailableAfterSelectionByUnitId = (
    unitId: number,
  ): number => {
    const { numberAvailableAfterSelection } =
      findUnitOverStayByUnitIdOrFail(unitId);

    return numberAvailableAfterSelection;
  };

  const findDailyPropertyAvailabilityByDate = (
    date: string,
  ): DailyPropertyAvailability =>
    findPropertyAvailabilityDailyPropertyAvailabilityByDateOrFail(
      propertyAvailability.value,
      date,
    );

  const shouldCodeResourceExcludeUnit = (unitId: number): boolean =>
    codeResource?.value &&
    codeResource.value.type === CodeResourceType.PrivateRateOverride
      ? !findPrivateRateOverrideUnitById(
          codeResource.value.privateRateOverride,
          unitId,
        )
      : false;

  return reactive({
    isAvailable,
    isClosedToArrival,
    isClosedToDeparture,
    availableUnitsOverStay,
    units,
    unavailableUnits,
    hasAvailableUnits,
    allUnitsAreUnavailable,
    hasUnitsThatOnlyViolateMinimumStay,
    shortestMinimumStayForUnitsThatOnlyViolateMinimumStay,
    property,
    propertyAvailability,
    codeResource,
    stayDates,
    includedMeals,
    supplementalMeals,
    extras,
    offers,
    bookingPolicyOverStay,
    isOfferAvailable,
    isSupplementalMealAvailable,
    findUnitById,
    findUnitOverStayByUnitId,
    findUnitOverStayByUnitIdOrFail,
    findNumberOfUnitsAvailableAfterSelectionByUnitId,
  });
};
