import { mapNoShowPolicyChargeFromDTO } from '@/property/booking-policy/no-show-policy/charge/no-show-policy-charge.mapper';
import type {
  NoShowPolicy,
  NoShowPolicyDTO,
} from '@/property/booking-policy/no-show-policy/no-show-policy';

export const mapNoShowPolicyFromDTO = (
  noShowPolicyDTO: NoShowPolicyDTO,
): NoShowPolicy => ({
  charge: mapNoShowPolicyChargeFromDTO(noShowPolicyDTO.charge),
});
