import { Decimal } from 'decimal.js';
import { zipWith } from 'lodash-es';
import { createDateObject } from '@/date/date.utilities';
import type {
  NightlyRate,
  NightlyRates,
} from '@/rates/nightly-rates/nightly-rates';

export const getTotalDecimalRateFromNightlyRates = (
  nightlyRates: NightlyRates,
): Decimal =>
  nightlyRates.reduce(
    (totalRate, { rate }) => totalRate.plus(rate),
    new Decimal(0),
  );

export const getTotalRateFromNightlyRates = (
  nightlyRates: NightlyRates,
): number => getTotalDecimalRateFromNightlyRates(nightlyRates).toNumber();

export const getFreeNightsFromNightlyRates = (nightlyRates: NightlyRates) =>
  nightlyRates.reduce<string[]>((freeNights, { date, isFreeNight }) => {
    if (isFreeNight) {
      freeNights.push(date);
    }

    return freeNights;
  }, []);

export const areAllNightlyRatesPositive = (
  nightlyRates: NightlyRates,
): boolean =>
  nightlyRates.every(({ rate }) => new Decimal(rate).greaterThan(0));

export const sortNightlyRatesByDate = (
  nightlyRates: NightlyRates,
): NightlyRates =>
  [...nightlyRates].sort((nightlyRateA, nightlyRateB) =>
    compareNightlyRatesByDate(nightlyRateB, nightlyRateA),
  );

export const sortNightlyRatesByRate = (
  nightlyRates: NightlyRates,
): NightlyRates =>
  [...nightlyRates].sort(
    (nightlyRateA, nightlyRateB) =>
      compareNightlyRatesByRate(nightlyRateA, nightlyRateB) ||
      compareNightlyRatesByDate(nightlyRateB, nightlyRateA),
  );

export const compareNightlyRatesByRate = (
  nightlyRateA: NightlyRate,
  nightlyRateB: NightlyRate,
) => new Decimal(nightlyRateA.rate).minus(nightlyRateB.rate).toNumber();

export const compareNightlyRatesByDate = (
  nightlyRateA: NightlyRate,
  nightlyRateB: NightlyRate,
) =>
  createDateObject(nightlyRateA.date).getTime() -
  createDateObject(nightlyRateB.date).getTime();

/**
 * "Zips" the given list of all nightly rates into a single nightly rates entity by combining the rates
 * at each corresponding index. Note that it is assumed all nightly rates will reference the same range
 * of dates.
 *
 * For example (only rates shown for brevity):
 * [[{ rate: 10 }, { rate: 4 }], [{ rate: 2 }, { rate: 5 }]] = [{ rate: 12 }, { rate: 9 }]
 *
 * We'll force `isFreeNight` to always be false here, as the concept no longer applies when combining
 * multiple nightly rates.
 */
export const zipAllNightlyRates = (
  allNightlyRates: NightlyRates[],
): NightlyRates =>
  zipWith(
    ...allNightlyRates,
    (firstUnitNightlyRate, ...remainingUnitNightlyRates) =>
      remainingUnitNightlyRates.reduce(
        (nightlyRate, { rate }) => ({
          ...nightlyRate,
          rate: new Decimal(nightlyRate.rate).add(rate).toNumber(),
          isFreeNight: false,
        }),
        { ...firstUnitNightlyRate, isFreeNight: false },
      ),
  );

export const adjustNightlyRatesByRate = (
  nightlyRates: NightlyRates,
  rate: number,
): NightlyRates =>
  nightlyRates.map((nightlyRate) => ({
    ...nightlyRate,
    rate,
  }));
