<template>
  <div class="flex items-center">
    <button
      v-if="bookingStageStore.hasPreviousStage"
      class="mr-2 focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-slate-800"
      :aria-label="$t('goToPreviousPage')"
      @click="bookingStageStore.goToPreviousStage"
    >
      <IconChevronLeft />
    </button>

    <h1 class="whitespace-nowrap font-semibold capitalize">
      {{ bookingStageStore.currentStage.title }}
    </h1>
  </div>
</template>

<script setup lang="ts">
import { IconChevronLeft } from '@tabler/icons-vue';
import { useBookingStageStore } from '@/booking-stage/booking-stage.store';

const bookingStageStore = useBookingStageStore();
</script>
