import pluralize from 'pluralize';
import type { ComputedRef } from 'vue';
import { computed } from 'vue';
import i18n from '@/i18n';
import { Locale } from '@/i18n/locales';
import { isLocaleGenderSpecific } from '@/i18n/locales/gender/gender';
import {
  UNIT_TYPE_NAME_UNIT,
  type UnitType,
} from '@/property/unit/type/unit-type';
import { findUnitTypeNameTranslationKeys } from '@/property/unit/type/unit-type.utilities';

export const useUnitTypeCount = (
  unitType: ComputedRef<UnitType>,
  count = 0,
) => {
  const { t, locale } = i18n.global;

  const countTranslationKey = computed(() =>
    unitType.value.isKnown ? unitType.value.countTranslationKey : 'unitCount',
  );

  const unitTypeNameCount = computed(() => {
    if (canSkipTranslation()) {
      return count === 1
        ? `1 ${unitType.value.name}`
        : `${count} ${pluralize(unitType.value.name)}`;
    }

    return t(countTranslationKey.value, count);
  });

  const unitTypeGender = computed(() =>
    isLocaleGenderSpecific(locale.value)
      ? unitType.value.isKnown
        ? unitType.value.genders[locale.value]
        : findUnitTypeNameTranslationKeys(UNIT_TYPE_NAME_UNIT).genders[
            locale.value
          ]
      : undefined,
  );

  /**
   * If the unit type does not have a translatable name, but we are currently
   * using the `enGB` locale - we can "skip" direct translation.
   */
  const canSkipTranslation = (): boolean =>
    !unitType.value.isKnown && locale.value === Locale.enGB;

  return {
    unitTypeNameCount,
    unitTypeGender,
  };
};
