<template>
  <div class="relative">
    <div class="flex min-h-[3rem]">
      <slot></slot>
    </div>

    <AppSkeletonBox
      v-show="searchStore.isLoading"
      class="absolute inset-0 z-10 rounded"
    />
  </div>
</template>

<script setup lang="ts">
import { useSearchStore } from '@/search/search.store';
import AppSkeletonBox from '@/ui/app-skeleton-box/AppSkeletonBox.vue';

const searchStore = useSearchStore();
</script>
