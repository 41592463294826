import type { Ref } from 'vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { ParkingPolicyChargeType } from '@/property/parking-policy/charge/parking-policy-charge';
import type { OnSiteParkingPolicy } from '@/property/parking-policy/on-site/on-site-parking-policy';
import type { Property } from '@/property/property';

export const useOnSiteParkingPolicyTermsAndConditionsDisplayText = (
  property: Ref<Property>,
) => {
  const { t, n } = useI18n();

  const onSiteParkingPolicy = computed(
    () => property.value.parkingPolicies.onSiteParkingPolicy,
  );

  const generateOnSiteParkingDisplayText = ({
    charge,
    isEnoughForAllGuests,
  }: OnSiteParkingPolicy): string => {
    switch (charge.chargeType) {
      case ParkingPolicyChargeType.Free:
        return t(
          isEnoughForAllGuests
            ? 'parkingForAllGuestsIsAvailableOnSiteAndIsFreeOfCharge'
            : 'limitedParkingIsAvailableOnSiteAndIsFreeOfCharge',
        );
      case ParkingPolicyChargeType.PerDayMonetary: {
        return t(
          isEnoughForAllGuests
            ? 'parkingForAllGuestsIsAvailableOnSiteAtAChargeOfChargePerDay'
            : 'limitedParkingIsAvailableOnSiteAtAChargeOfChargePerDay',
          {
            charge: n(charge.amountPerDay, {
              style: 'currency',
              currency: property.value.currencyCode,
            }),
          },
        );
      }
    }
  };

  return computed(() =>
    onSiteParkingPolicy.value
      ? generateOnSiteParkingDisplayText(onSiteParkingPolicy.value)
      : '',
  );
};
