import {
  createInjectionState,
  useArrayEvery,
  useArrayFind,
  useEventBus,
} from '@vueuse/core';
import type { ComputedRef } from 'vue';
import { computed, ref } from 'vue';
import { occupancySelectorAfterValidateEventBusKey } from '@/event-bus/event-bus';
import { generateUniqueId } from '@/generator/generator.utilities';
import { DEFAULT_NUMBER_OF_ADULTS } from '@/occupancy/occupancy';
import { mapOccupancySelectorUnitOccupanciesToOccupancies } from '@/occupancy/occupancy.utilities';
import type { OccupancySelectorChildOccupant } from '@/occupancy-selector/child-occupant/occupancy-selector-child-occupant';
import type { OccupancySelectorUnitOccupancy } from '@/occupancy-selector/unit-occupancy/occupancy-selector-unit-occupancy';
import type { Property } from '@/property/property';
import { useUnitType } from '@/property/unit/type/unit-type.composable';

const MINIMUM_NUMBER_OF_ADULTS = 1;
const MINIMUM_NUMBER_OF_CHILDREN = 0;

const MAXIMUM_NUMBER_OF_UNITS = 10;

const [useProvideOccupancySelectorStore, useMaybeOccupancySelectorStore] =
  createInjectionState(
    (injectionState: {
      property: ComputedRef<Property>;
      unitOccupancies: OccupancySelectorUnitOccupancy[];
    }) => {
      const property = injectionState.property;

      const unitOccupancies = ref(injectionState.unitOccupancies);

      const occupancies = computed(() =>
        mapOccupancySelectorUnitOccupanciesToOccupancies(unitOccupancies.value),
      );

      const unitTypeName = useUnitType(
        computed(() => property.value.unitType),
      ).unitTypeName;

      const childOccupants = computed<OccupancySelectorChildOccupant[]>(() =>
        unitOccupancies.value.flatMap(({ childOccupants }) => childOccupants),
      );

      const incrementNumberOfAdults = (unitOccupancyId: string): void => {
        const unitOccupancy = findUnitOccupancy(unitOccupancyId);

        unitOccupancy.numberOfAdults++;
      };

      const incrementNumberOfChildren = (unitOccupancyId: string): void => {
        const unitOccupancy = findUnitOccupancy(unitOccupancyId);

        unitOccupancy.childOccupants.push(generateDefaultChildOccupant());
      };

      const decrementNumberOfAdults = (unitOccupancyId: string): void => {
        const unitOccupancy = findUnitOccupancy(unitOccupancyId);

        unitOccupancy.numberOfAdults--;
      };

      const canDecrementNumberOfAdults = (unitOccupancyId: string): boolean => {
        const unitOccupancy = findUnitOccupancy(unitOccupancyId);

        return unitOccupancy.numberOfAdults > MINIMUM_NUMBER_OF_ADULTS;
      };

      const decrementNumberOfChildren = (unitOccupancyId: string): void => {
        const unitOccupancy = findUnitOccupancy(unitOccupancyId);

        unitOccupancy.childOccupants.pop();
      };

      const canDecrementNumberOfChildren = (
        unitOccupancyId: string,
      ): boolean => {
        const unitOccupancy = findUnitOccupancy(unitOccupancyId);

        return unitOccupancy.childOccupants.length > MINIMUM_NUMBER_OF_CHILDREN;
      };

      const setChildAge = (childOccupantId: string, childAge: number): void => {
        const childOccupant = findChildOccupant(childOccupantId);

        childOccupant.age = childAge;
        childOccupant.shouldShowMissingAgeError = false;
      };

      const addUnitOccupancy = (): void => {
        if (unitOccupancies.value.length < MAXIMUM_NUMBER_OF_UNITS) {
          unitOccupancies.value.push(generateDefaultUnitOccupancy());
        }
      };

      const canAddUnitOccupancy = computed<boolean>(
        () => unitOccupancies.value.length < MAXIMUM_NUMBER_OF_UNITS,
      );

      const removeUnitOccupancy = (unitOccupancyId: string): void => {
        unitOccupancies.value = unitOccupancies.value.filter(
          ({ id }) => id !== unitOccupancyId,
        );
      };

      const validate = (): void => {
        for (const childOccupant of childOccupants.value) {
          childOccupant.shouldShowMissingAgeError =
            childOccupant.age === undefined;
        }

        useEventBus(occupancySelectorAfterValidateEventBusKey).emit();
      };

      const canSubmit = useArrayEvery(
        childOccupants,
        ({ shouldShowMissingAgeError }) => !shouldShowMissingAgeError,
      );

      const firstChildOccupantWithMissingAgeError = useArrayFind(
        childOccupants,
        ({ shouldShowMissingAgeError }) => shouldShowMissingAgeError,
      );

      const generateDefaultUnitOccupancy =
        (): OccupancySelectorUnitOccupancy => ({
          id: generateUniqueId(),
          numberOfAdults: DEFAULT_NUMBER_OF_ADULTS,
          childOccupants: [],
        });

      const generateDefaultChildOccupant =
        (): OccupancySelectorChildOccupant => ({
          id: generateUniqueId(),
          age: undefined,
          shouldShowMissingAgeError: false,
        });

      const findUnitOccupancy = (
        unitOccupancyId: string,
      ): OccupancySelectorUnitOccupancy =>
        unitOccupancies.value.find(({ id }) => id === unitOccupancyId)!;

      const findChildOccupant = (
        childOccupantId: string,
      ): OccupancySelectorChildOccupant =>
        childOccupants.value.find(({ id }) => id === childOccupantId)!;

      return {
        property,
        unitOccupancies,
        occupancies,
        unitTypeName,
        incrementNumberOfAdults,
        decrementNumberOfAdults,
        incrementNumberOfChildren,
        decrementNumberOfChildren,
        canDecrementNumberOfAdults,
        canDecrementNumberOfChildren,
        setChildAge,
        addUnitOccupancy,
        canAddUnitOccupancy,
        removeUnitOccupancy,
        validate,
        canSubmit,
        firstChildOccupantWithMissingAgeError,
      };
    },
  );

const useOccupancySelectorStore = () => {
  const occupancySelectorStore = useMaybeOccupancySelectorStore();

  if (!occupancySelectorStore) {
    throw new Error(
      'Please call `useProvideOccupancySelectorStore` on the appropriate parent component',
    );
  }

  return occupancySelectorStore;
};

export { useOccupancySelectorStore, useProvideOccupancySelectorStore };
