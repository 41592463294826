import type {
  PetsAllowedPolicy,
  PetsAllowedPolicyDTO,
} from '@/property/pets-policy/allowed/pets-allowed-policy';
import type {
  PetsDisallowedPolicy,
  PetsDisallowedPolicyDTO,
} from '@/property/pets-policy/disallowed/pets-disallowed-policy';

export enum PetsPolicyType {
  Allowed = 'allowed',
  Disallowed = 'disallowed',
}

export type PetsPolicy = PetsAllowedPolicy | PetsDisallowedPolicy;

export type PetsPolicyDTO = PetsAllowedPolicyDTO | PetsDisallowedPolicyDTO;
