import type { AdditionalTax } from '@/tax/additional/additional-tax';

export const findFirstAdditionalTaxApplicableToDate = (
  additionalTaxes: AdditionalTax[],
  date: string,
): AdditionalTax | undefined =>
  additionalTaxes.find((additionalTax) =>
    additionalTaxAppliesToDate(additionalTax, date),
  );

export const findAllAdditionalTaxesApplicableToDate = (
  additionalTaxes: AdditionalTax[],
  date: string,
): AdditionalTax[] =>
  additionalTaxes.filter((additionalTax) =>
    additionalTaxAppliesToDate(additionalTax, date),
  );

const additionalTaxAppliesToDate = (
  { fromDate, toDate }: AdditionalTax,
  date: string,
): boolean => fromDate <= date && (!toDate || toDate >= date);
