import { applyMonetaryPromocodeToNightlyRates } from '@/property/promocode/monetary-promocode/monetary-promocode.utilities';
import { applyPercentagePromocodeToNightlyRates } from '@/property/promocode/percentage-promocode/percentage-promocode.utilities';
import type { Promocode } from '@/property/promocode/promocode';
import { PromocodeType } from '@/property/promocode/promocode';
import type { NightlyRates } from '@/rates/nightly-rates/nightly-rates';
import type { PromocodeRates } from '@/rates/promocode-rates/promocode-rates';

export const createPromocodeRates = (
  promocode: Promocode,
  nightlyRates: NightlyRates,
): PromocodeRates => {
  switch (promocode.promocodeType) {
    case PromocodeType.Percentage:
      return {
        promocode,
        nightlyRates: applyPercentagePromocodeToNightlyRates(
          promocode,
          nightlyRates,
        ),
      };
    case PromocodeType.Monetary:
      return {
        promocode,
        nightlyRates: applyMonetaryPromocodeToNightlyRates(
          promocode,
          nightlyRates,
        ),
      };
    default:
      return { promocode, nightlyRates };
  }
};
