import type {
  PseudoUnitAvailability,
  PseudoUnitAvailabilityDTO,
} from '@/availability/pseudo-unit-availability/pseudo-unit-availability';

export const mapPseudoUnitAvailabilityFromDTO = (
  pseudoUnitAvailabilityDTO: PseudoUnitAvailabilityDTO,
): PseudoUnitAvailability => ({
  pseudoUnitId: pseudoUnitAvailabilityDTO.pseudoUnitId,
  isBooked: pseudoUnitAvailabilityDTO.isBooked,
  isUnderMaintenance: pseudoUnitAvailabilityDTO.isUnderMaintenance,
});
