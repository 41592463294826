<template>
  <section
    v-element-size="onResize"
    role="search"
    data-test="UnitSelectionSearchBar"
    class="sticky top-inline-booking-stage-selector-height z-20 w-full bg-white"
  >
    <div
      class="accent-contrast-shadow flex flex-col items-center justify-center gap-4 bg-accent-color p-4 md:flex-row"
    >
      <div class="flex w-full flex-col gap-x-4 gap-y-2 sm:w-auto sm:flex-row">
        <UnitSelectionSearchBarAvailabilityCalendar />

        <UnitSelectionSearchBarOccupancySelector />
      </div>

      <div
        v-if="
          searchStore.activeProperty.hasAnyPromocodes ||
          searchStore.activeProperty.hasAnyPrivateRateOverrides
        "
        class="min-w-0 max-w-full"
      >
        <UnitSelectionSearchBarCodeInput />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { vElementSize } from '@vueuse/components';
import { useSearchStore } from '@/search/search.store';
import UnitSelectionSearchBarAvailabilityCalendar from '@/unit-selection-page/search-bar/availability-calendar/UnitSelectionSearchBarAvailabilityCalendar.vue';
import UnitSelectionSearchBarCodeInput from '@/unit-selection-page/search-bar/code-input/UnitSelectionSearchBarCodeInput.vue';
import UnitSelectionSearchBarOccupancySelector from '@/unit-selection-page/search-bar/occupancy-selector/UnitSelectionSearchBarOccupancySelector.vue';

const searchStore = useSearchStore();

const onResize = ({ height }: { height: number }): void => {
  document.documentElement.style.setProperty(
    '--unit-selection-search-bar-height',
    `${height}px`,
  );
};
</script>
