<template>
  <div data-test="BookingSummaryFooterTotalRate">
    {{
      $n(bookingItineraryStore.totalRate, {
        style: 'currency',
        currency: searchStore.activeProperty.currencyCode,
      })
    }}
  </div>
</template>
<script setup lang="ts">
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import { useSearchStore } from '@/search/search.store';

const searchStore = useSearchStore();
const bookingItineraryStore = useBookingItineraryStore();
</script>
