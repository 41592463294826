import type { SupplementalMeal } from '@/property/meal/supplemental-meal/supplemental-meal';

export enum MealPricePlanVariantType {
  Standard = 'standard',
  Discounted = 'discounted',
}

interface BaseMealPricePlan {
  id: string;
  meal: SupplementalMeal;
  rate: number;
}

export interface StandardMealPricePlanVariant extends BaseMealPricePlan {
  readonly type: MealPricePlanVariantType.Standard;
}

export interface DiscountedMealPricePlanVariant extends BaseMealPricePlan {
  readonly type: MealPricePlanVariantType.Discounted;
  preDiscountRate: number;
}

export type MealPricePlan =
  | StandardMealPricePlanVariant
  | DiscountedMealPricePlanVariant;
