import type { Property, PropertyDTO } from '@/property/property';
import type { Chain, ChainDTO } from '@/widget/chain/chain';

export enum UnavailableSearchResultsPreference {
  UnavailableUnits = 'unavailable_units',
  AvailableAdditionalProperties = 'available_additional_properties',
}

export enum CalendarAvailabilityAwareness {
  Aware = 'aware',
  Unaware = 'unaware',
}

export interface Widget {
  id: string;
  properties: Property[];
  rankOrderedPropertyIds: number[][];
  chain: Chain | undefined;
  unavailableSearchResultsPreference: UnavailableSearchResultsPreference;
  calendarAvailabilityAwareness: CalendarAvailabilityAwareness;
}

export interface WidgetDTO extends Omit<Widget, 'properties' | 'chain'> {
  properties: PropertyDTO[];
  chain: ChainDTO | null;
}
