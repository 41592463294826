<template>
  <RadioGroup
    v-bind="{ ...(value && { modelValue: value }) }"
    @update:model-value="(modelValue) => (value = modelValue ?? undefined)"
  >
    <slot></slot>
  </RadioGroup>
</template>

<script setup lang="ts">
import { RadioGroup } from '@headlessui/vue';

const value = defineModel<string | number | object | undefined>({
  required: true,
});
</script>
