<template>
  <AppFormValidatedInput
    :value="guestDetailsStore.hasAgreedToTermsAndConditions"
    :validation-rules="[
      isEqualToRule(true, $t('pleaseAgreeToTheTermsAndConditions')),
    ]"
  >
    <AppCheckbox
      v-model="guestDetailsStore.hasAgreedToTermsAndConditions"
      :label-text="$t('iAgreeToTheTermsAndConditions')"
      label-id="terms-and-conditions"
    />
  </AppFormValidatedInput>
</template>

<script setup lang="ts">
import { useGuestDetailsStore } from '@/guest-details-page/guest-details.store';
import AppCheckbox from '@/ui/app-checkbox/AppCheckbox.vue';
import { isEqualToRule } from '@/ui/app-form/app-form-input-validation-rules.utilities';
import AppFormValidatedInput from '@/ui/app-form/AppFormValidatedInput.vue';

const guestDetailsStore = useGuestDetailsStore();
</script>
