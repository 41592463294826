import type {
  DepositRefundPolicy,
  DepositRefundPolicyDTO,
} from '@/property/booking-policy/deposit-policy/refund-policy/deposit-refund-policy';
import { DepositRefundPolicyType } from '@/property/booking-policy/deposit-policy/refund-policy/deposit-refund-policy';

export const mapDepositRefundPolicyFromDTO = (
  depositRefundPolicyDTO: DepositRefundPolicyDTO,
): DepositRefundPolicy => {
  const { refundType } = depositRefundPolicyDTO;

  switch (refundType) {
    case DepositRefundPolicyType.BeforeArrival:
      return {
        refundType,
        minimumNumberOfDaysBeforeArrivalThreshold:
          depositRefundPolicyDTO.minimumNumberOfDaysBeforeArrivalThreshold,
      };
    case DepositRefundPolicyType.ByCancellationPolicy:
      return { refundType };
  }
};
