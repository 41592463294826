import { Decimal } from 'decimal.js';
import type { PropertyAvailability } from '@/availability/property-availability/property-availability';
import { findPropertyAvailabilityMealAvailabilityByDateOrFail } from '@/availability/property-availability/property-availability.utilities';
import type { Occupancy } from '@/occupancy/occupancy';
import type { MealType } from '@/property/meal/meal';
import { applyMealRateAdjustmentToValue } from '@/property/meal/meal-rate-adjustment/meal-rate-adjustment.utilities';
import type { SupplementalMeal } from '@/property/meal/supplemental-meal/supplemental-meal';
import { applyDiscountOfferAdjustmentToValue } from '@/property/offer/discount-offer/adjustment/discount-offer-adjustment.utilities';
import type { DiscountOffer } from '@/property/offer/discount-offer/discount-offer';
import {
  discountOfferAppliesToMeal,
  findDiscountOfferMealAdjustmentByMealTypeOrFail,
  canApplyDiscountOfferAdjustmentOnDate,
} from '@/property/offer/discount-offer/discount-offer.utilities';
import type { MealRates } from '@/rates/meal-rates/meal-rates';
import type { NightlyRates } from '@/rates/nightly-rates/nightly-rates';
import type { StayDates } from '@/stay-dates/stay-dates';
import { getNightsOfStayFromStayDates } from '@/stay-dates/stay-dates.utilities';

export const createStandardMealRates = (
  meal: SupplementalMeal,
  propertyAvailability: PropertyAvailability,
  stayDates: StayDates,
  occupancy: Occupancy,
): MealRates => ({
  meal,
  nightlyRates: createNightlyRates(
    meal,
    propertyAvailability,
    getNightsOfStayFromStayDates(stayDates),
    occupancy,
  ),
});

const createDiscountOfferMealRates = (
  meal: SupplementalMeal,
  discountOffer: DiscountOffer,
  propertyAvailability: PropertyAvailability,
  stayDates: StayDates,
  occupancy: Occupancy,
): MealRates => ({
  meal,
  nightlyRates: createNightlyRates(
    meal,
    propertyAvailability,
    getNightsOfStayFromStayDates(stayDates),
    occupancy,
    discountOffer,
  ),
});

const createFreeNightOfferMealRates = (
  meal: SupplementalMeal,
  freeNights: string[],
  propertyAvailability: PropertyAvailability,
  stayDates: StayDates,
  occupancy: Occupancy,
): MealRates => ({
  meal,
  nightlyRates: createNightlyRates(
    meal,
    propertyAvailability,
    getNightsOfStayFromStayDates(stayDates),
    occupancy,
    undefined,
    freeNights,
  ),
});

const createNightlyRates = (
  meal: SupplementalMeal,
  propertyAvailability: PropertyAvailability,
  nightsOfStay: string[],
  occupancy: Occupancy,
  discountOffer?: DiscountOffer,
  freeNights: string[] = [],
): NightlyRates =>
  nightsOfStay.map((nightOfStay) => {
    const { rate } = findPropertyAvailabilityMealAvailabilityByDateOrFail(
      propertyAvailability,
      meal.type,
      nightOfStay,
    );

    if (rate === undefined) {
      throw new Error(
        `No rate found for Meal '${meal.type}' on ${nightOfStay}`,
      );
    }

    const isFreeNight = freeNights.includes(nightOfStay);

    return {
      date: nightOfStay,
      rate: isFreeNight
        ? 0
        : adjustNightlyRate(
            rate,
            occupancy,
            meal,
            discountOffer &&
              canApplyDiscountOfferAdjustmentOnDate(
                discountOffer,
                propertyAvailability,
                nightOfStay,
              )
              ? discountOffer
              : undefined,
          ),
      isFreeNight,
    };
  });

export const findMealRatesWithMealType = (
  mealsRates: MealRates[],
  mealType: MealType,
): MealRates | undefined =>
  mealsRates.find(({ meal }) => meal.type === mealType);

export const createStandardMealsRates = (
  meals: SupplementalMeal[],
  propertyAvailability: PropertyAvailability,
  stayDates: StayDates,
  occupancy: Occupancy,
): MealRates[] =>
  meals.map((meal) =>
    createStandardMealRates(meal, propertyAvailability, stayDates, occupancy),
  );

export const createDiscountOfferMealsRates = (
  meals: SupplementalMeal[],
  discountOffer: DiscountOffer,
  propertyAvailability: PropertyAvailability,
  stayDates: StayDates,
  occupancy: Occupancy,
): MealRates[] =>
  meals.reduce<MealRates[]>((mealsRates, meal) => {
    if (discountOfferAppliesToMeal(discountOffer, meal.type)) {
      mealsRates.push(
        createDiscountOfferMealRates(
          meal,
          discountOffer,
          propertyAvailability,
          stayDates,
          occupancy,
        ),
      );
    }

    return mealsRates;
  }, []);

export const createFreeNightOfferMealsRates = (
  meals: SupplementalMeal[],
  freeNights: string[],
  propertyAvailability: PropertyAvailability,
  stayDates: StayDates,
  occupancy: Occupancy,
): MealRates[] =>
  meals.map((meal) =>
    createFreeNightOfferMealRates(
      meal,
      freeNights,
      propertyAvailability,
      stayDates,
      occupancy,
    ),
  );

/**
 * We need to adjust each nightly rate by the number of occupants provided (accounting for child discounts).
 * Additionally, a discount offer may be provided which can also discount the nightly rate.
 */
const adjustNightlyRate = (
  rate: number,
  { numberOfAdults, children }: Occupancy,
  { type, childMealRateAdjustments }: SupplementalMeal,
  discountOffer?: DiscountOffer,
): number => {
  const individualAdultRate = discountOffer
    ? applyDiscountOfferToRate(rate, discountOffer, type)
    : rate;

  const totalAdultRate = new Decimal(individualAdultRate).times(numberOfAdults);

  let totalChildRate = new Decimal(0);

  for (const { age } of children) {
    const childMealRateAdjustment = childMealRateAdjustments.find(
      ({ minimumChildAge, maximumChildAge }) =>
        age >= minimumChildAge && age <= maximumChildAge,
    );

    let individualChildRate = childMealRateAdjustment
      ? applyMealRateAdjustmentToValue(
          childMealRateAdjustment.mealRateAdjustment,
          rate,
        )
      : rate;

    if (discountOffer) {
      individualChildRate = applyDiscountOfferToRate(
        individualChildRate,
        discountOffer,
        type,
      );
    }

    totalChildRate = totalChildRate.add(individualChildRate);
  }

  return totalAdultRate.plus(totalChildRate).toNumber();
};

const applyDiscountOfferToRate = (
  rate: number,
  discountOffer: DiscountOffer,
  mealType: MealType,
): number => {
  const discountOfferMealAdjustment =
    findDiscountOfferMealAdjustmentByMealTypeOrFail(discountOffer, mealType);

  return applyDiscountOfferAdjustmentToValue(
    discountOfferMealAdjustment,
    rate,
    discountOffer.discountType,
  );
};
