import {
  ADDRESS_ADMINISTRATIVE_AREA_MAX_LENGTH,
  ADDRESS_CITY_MAX_LENGTH,
  ADDRESS_POSTCODE_MAX_LENGTH,
  ADDRESS_STREET_MAX_LENGTH,
  EMAIL_MAX_LENGTH,
  FIRST_NAME_MAX_LENGTH,
  LEAD_GUEST_NAME_MAX_LENGTH,
  PHONE_NUMBER_MAX_LENGTH,
  SPECIAL_REQUESTS_MAX_LENGTH,
  SURNAME_MAX_LENGTH,
} from '@/booking/booking';
import type {
  BookingCreateData,
  BookingCreateDataDTO,
  BookingCreateDataPaymentGatewayDTO,
} from '@/booking/create/data/booking-create-data';
import type { BookingSource } from '@/booking-source/booking-source';
import { BookingSourceType } from '@/booking-source/booking-source';
import type { Elements } from '@/elements/elements';
import { PaymentGatewayType } from '@/property/payment-gateway/payment-gateway';

export const mapBookingCreateDataToDTO = (
  bookingCreateData: BookingCreateData,
): BookingCreateDataDTO => ({
  widget_id: bookingCreateData.widgetId,
  check_in_date: bookingCreateData.checkInDate,
  check_out_date: bookingCreateData.checkOutDate,
  arrival_time: bookingCreateData.arrivalTime ?? null,
  special_requests:
    bookingCreateData.specialRequests?.substring(
      0,
      SPECIAL_REQUESTS_MAX_LENGTH,
    ) ?? null,
  locale: bookingCreateData.locale,
  promocode: bookingCreateData.promocode ?? null,
  private_rate_override: bookingCreateData.privateRateOverride
    ? {
        id: bookingCreateData.privateRateOverride.id,
        code_used: bookingCreateData.privateRateOverride.codeUsed,
      }
    : null,
  units: mapBookingCreateUnitsDataToDTO(bookingCreateData.units),
  extras: mapBookingCreateExtrasDataToDTO(bookingCreateData.extras),
  guest: mapCreateBookingGuestDataToDTO(bookingCreateData.guest),
  ...mapCreateBookingPaymentGatewayDataToDTO(bookingCreateData.paymentGateway),
  source: bookingCreateData.source
    ? mapBookingSourceToDTO(bookingCreateData.source)
    : null,
  elements: bookingCreateData.elements
    ? mapElementsToDTO(bookingCreateData.elements)
    : null,
  used_express_checkout: bookingCreateData.usedExpressCheckout,
  abandoned_cart_id: bookingCreateData.abandonedCartId ?? null,
  expected_total_amount: bookingCreateData.expectedTotalAmount.toString(),
  expected_deposit_amount: bookingCreateData.expectedDepositAmount.toString(),
});

const mapBookingCreateUnitsDataToDTO = (
  bookingCreateUnitsData: BookingCreateData['units'],
): BookingCreateDataDTO['units'] =>
  bookingCreateUnitsData.map((bookingCreateUnitData) => ({
    id: bookingCreateUnitData.id,
    way_to_sell_id: bookingCreateUnitData.wayToSellId ?? null,
    offer_id: bookingCreateUnitData.offerId ?? null,
    occupancy: {
      number_of_adults: bookingCreateUnitData.occupancy.numberOfAdults,
      children: bookingCreateUnitData.occupancy.children.map(({ age }) => ({
        age,
      })),
    },
    meal_types: bookingCreateUnitData.mealTypes,
    twin_double_bed_option: bookingCreateUnitData.twinDoubleBedOption ?? null,
    lead_guest_name: bookingCreateUnitData.leadGuestName.substring(
      0,
      LEAD_GUEST_NAME_MAX_LENGTH,
    ),
  }));

const mapBookingCreateExtrasDataToDTO = (
  bookingCreateExtrasData: BookingCreateData['extras'],
): BookingCreateDataDTO['extras'] =>
  bookingCreateExtrasData.map((bookingCreateExtraData) => ({
    id: bookingCreateExtraData.id,
    answer: bookingCreateExtraData.answer ?? null,
    number_of_nights: bookingCreateExtraData.numberOfNights ?? null,
    quantity: bookingCreateExtraData.quantity ?? 1,
  }));

const mapCreateBookingGuestDataToDTO = (
  bookingCreateGuestData: BookingCreateData['guest'],
): BookingCreateDataDTO['guest'] => ({
  forename: bookingCreateGuestData.forename.substring(0, FIRST_NAME_MAX_LENGTH),
  surname: bookingCreateGuestData.surname.substring(0, SURNAME_MAX_LENGTH),
  email_address: bookingCreateGuestData.emailAddress.substring(
    0,
    EMAIL_MAX_LENGTH,
  ),
  phone_number: bookingCreateGuestData.phoneNumber.substring(
    0,
    PHONE_NUMBER_MAX_LENGTH,
  ),
  address: bookingCreateGuestData.address.line1.substring(
    0,
    ADDRESS_STREET_MAX_LENGTH,
  ),
  postcode: bookingCreateGuestData.address.postcode.substring(
    0,
    ADDRESS_POSTCODE_MAX_LENGTH,
  ),
  administrative_area:
    bookingCreateGuestData.address.administrativeArea.substring(
      0,
      ADDRESS_ADMINISTRATIVE_AREA_MAX_LENGTH,
    ),
  city: bookingCreateGuestData.address.city.substring(
    0,
    ADDRESS_CITY_MAX_LENGTH,
  ),
  country_code: bookingCreateGuestData.address.countryCode,
});

const mapCreateBookingPaymentGatewayDataToDTO = (
  bookingCreatePaymentGatewayData: BookingCreateData['paymentGateway'],
): BookingCreateDataPaymentGatewayDTO => {
  switch (bookingCreatePaymentGatewayData.type) {
    case PaymentGatewayType.None:
      return {
        payment_gateway_type: PaymentGatewayType.None,
        recaptcha_token: bookingCreatePaymentGatewayData.recaptchaToken,
      };
    case PaymentGatewayType.Stripe:
      return {
        payment_gateway_type: PaymentGatewayType.Stripe,
        stripe: {
          payment_method_id: bookingCreatePaymentGatewayData.paymentMethodId,
        },
      };
    case PaymentGatewayType.SagePay: {
      const { strongCustomerAuth } = bookingCreatePaymentGatewayData;

      return {
        payment_gateway_type: PaymentGatewayType.SagePay,
        sage_pay: {
          merchant_session_key:
            bookingCreatePaymentGatewayData.merchantSessionKey,
          card_identifier: bookingCreatePaymentGatewayData.cardIdentifier,
          strong_customer_auth: {
            timezone_offset_minutes: strongCustomerAuth.timezoneOffsetMinutes,
            screen_height: strongCustomerAuth.screenHeight,
            screen_width: strongCustomerAuth.screenWidth,
          },
        },
      };
    }
    case PaymentGatewayType.Pcib:
      return {
        payment_gateway_type: PaymentGatewayType.Pcib,
        pcib: {
          card_token_url: bookingCreatePaymentGatewayData.cardTokenUrl,
          card_type: bookingCreatePaymentGatewayData.cardType,
          card_holder_name: bookingCreatePaymentGatewayData.cardHolderName,
          card_expiration: bookingCreatePaymentGatewayData.cardExpiration,
        },
      };
  }
};

const mapBookingSourceToDTO = (
  bookingSource: BookingSource,
): BookingCreateDataDTO['source'] => {
  const type = bookingSource.type;

  switch (type) {
    case BookingSourceType.GoogleHPA:
      return {
        type,
        campaign_id: bookingSource.campaignId ?? null,
      };
    case BookingSourceType.VisitScotland:
      return {
        type,
        booking_id: bookingSource.bookingId,
      };
    case BookingSourceType.TogetherSite:
      return {
        type,
        site_id: bookingSource.siteId,
      };
    case BookingSourceType.Newmind:
      return {
        type,
        affiliate_code: bookingSource.affiliateCode,
      };
    case BookingSourceType.Other:
      return {
        type,
        id: bookingSource.id,
      };
    default:
      return { type };
  }
};

const mapElementsToDTO = (
  elements: Elements,
): BookingCreateDataDTO['elements'] => ({
  host_url: elements.hostUrl,
});
