<template>
  <AppPrimaryButton
    :button-text="bookingSummaryContinueButton.text"
    :icon="bookingSummaryContinueButton.icon"
    :is-loading="bookingSummaryStore.isLoading"
    class="whitespace-nowrap px-6 py-3 capitalize"
    @click="goToNextStage"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useBookingStageStore } from '@/booking-stage/booking-stage.store';
import { useBookingSummaryStore } from '@/booking-summary/booking-summary.store';
import AppPrimaryButton from '@/ui/app-primary-button/AppPrimaryButton.vue';

const bookingSummaryStore = useBookingSummaryStore();
const bookingStageStore = useBookingStageStore();

const bookingSummaryContinueButton = computed(
  () => bookingStageStore.currentStage.bookingSummaryContinueButton,
);

const goToNextStage = async () => {
  await bookingStageStore.currentStage.goToNextStage();
  bookingSummaryStore.isModalOpen = false;
};
</script>
