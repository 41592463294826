<template>
  <div v-if="isUpdatingSelectedMeals" class="flex gap-2">
    <AppSecondaryButton
      class="px-4 py-1"
      :button-text="toTitleCase($t('cancel'))"
      @click="isUpdatingSelectedMeals = false"
    />

    <AppPrimaryButton
      class="px-4 py-1"
      :button-text="toTitleCase($t('save'))"
      @click="updateSelectedMeals"
    />
  </div>

  <BookingSummaryBodyUnitItemUpdateSelectedMeal
    v-else-if="arrayContainSingleItem(supplementalMeals)"
    :meal="supplementalMeals[0]"
  />

  <AppLinkButton
    v-else
    class="text-sm"
    :text="toTitleCase($t(hasSelectedAnyMeals ? 'changeMeals' : 'addMeals'))"
    @click="isUpdatingSelectedMeals = true"
  />
</template>

<script setup lang="ts">
import { arrayContainSingleItem } from '@/array/array.utilities';
import { useBookingSummaryMealsStore } from '@/booking-summary/body/unit-item/meals/booking-summary-meals.store';
import BookingSummaryBodyUnitItemUpdateSelectedMeal from '@/booking-summary/body/unit-item/meals/BookingSummaryBodyUnitItemUpdateSelectedMeal.vue';
import { useLocaleString } from '@/string/locale-string.composable';
import AppLinkButton from '@/ui/app-link-button/AppLinkButton.vue';
import AppPrimaryButton from '@/ui/app-primary-button/AppPrimaryButton.vue';
import AppSecondaryButton from '@/ui/app-secondary-button/AppSecondaryButton.vue';

const { toTitleCase } = useLocaleString();

const {
  isUpdatingSelectedMeals,
  supplementalMeals,
  hasSelectedAnyMeals,
  updateSelectedMeals,
} = useBookingSummaryMealsStore();
</script>
