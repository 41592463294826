<template>
  <div class="break-anywhere text-xs">
    <AppPriceAdjustmentTag
      v-if="!searchStore.isLoading"
      :tag-subtitle-text="discountSummaryText"
      :tag-title-text="promocode.promocodeText"
      :icon="IconTag"
    />
    <AppSkeletonText v-else class="w-48" />
  </div>
</template>
<script setup lang="ts">
import { IconTag } from '@tabler/icons-vue';
import { computed } from 'vue';
import type { Promocode } from '@/property/promocode/promocode';
import { usePromocodeDiscountSummary } from '@/property/promocode/promocode.composable';
import { useSearchStore } from '@/search/search.store';
import AppPriceAdjustmentTag from '@/ui/app-price-adjustment-tag/AppPriceAdjustmentTag.vue';
import AppSkeletonText from '@/ui/app-skeleton-text/AppSkeletonText.vue';

const props = defineProps<{
  promocode: Promocode;
}>();

const searchStore = useSearchStore();

const { discountSummaryText } = usePromocodeDiscountSummary(
  computed(() => props.promocode),
  computed(() => searchStore.activeProperty),
);
</script>
