import { HTTPError } from 'ky';
import { ref } from 'vue';
import { PropertyUnavailableError } from '@/errors/PropertyUnavailableError';
import router, { PAGE_NOT_FOUND_ROUTE } from '@/router';

export enum AppErrorType {
  PropertyUnavailableError = 'property_unavailable_error',
  HTTPError = 'http_error',
  UnknownError = 'unknown_error',
}

export type AppError =
  | {
      type: AppErrorType.HTTPError;
      code: number;
    }
  | {
      type: Exclude<AppErrorType, AppErrorType.HTTPError>;
    };

export const useErrorHandler = () => {
  const appError = ref<AppError>();

  const handleError = (error: unknown) => {
    if (error instanceof PropertyUnavailableError) {
      appError.value = {
        type: AppErrorType.PropertyUnavailableError,
      };
    } else if (error instanceof HTTPError) {
      switch (error.response.status) {
        case 404:
          void router.push({ name: PAGE_NOT_FOUND_ROUTE });
          break;
        default:
          appError.value = {
            type: AppErrorType.HTTPError,
            code: error.response.status,
          };
          break;
      }
    } else {
      appError.value = { type: AppErrorType.UnknownError };
    }
  };

  return {
    appError,
    handleError,
  };
};
