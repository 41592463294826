import { Locale } from '@/i18n/locales';

export enum GrammaticalGender {
  Masculine = 'masculine',
  Feminine = 'feminine',
  Neuter = 'neuter',
}

const genderNeutralLocales = [Locale.enGB] as const;

export type GenderNeutralLocale = (typeof genderNeutralLocales)[number];
export type GenderSpecificLocale = Exclude<Locale, GenderNeutralLocale>;

export const isLocaleGenderSpecific = (
  locale: Locale,
): locale is GenderSpecificLocale =>
  !genderNeutralLocales.includes(locale as GenderNeutralLocale);

export type LocaleToGrammaticalGender = Record<
  GenderSpecificLocale,
  GrammaticalGender
>;
