<template>
  <div v-if="isNonRefundable" class="text-xs">
    <AppPill v-if="!searchStore.isLoading">
      <div class="font-medium capitalize">
        {{ $t('nonRefundable') }}
      </div>
    </AppPill>

    <AppSkeletonText v-else class="w-24" />
  </div>

  <div v-else-if="freeCancellationStatus.isFreeCancellation" class="text-xs">
    <AppPill v-if="!searchStore.isLoading">
      <div class="font-medium first-letter:capitalize">
        {{
          $t('freeCancellationUntilDate', {
            date: $d(
              createDateObject(
                freeCancellationStatus.freeCancellationCutOffDate,
              ),
              { day: 'numeric', month: 'short' },
            ),
          })
        }}
      </div>
    </AppPill>

    <AppSkeletonText v-else class="w-40" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { createDateObject } from '@/date/date.utilities';
import type { PricePlan } from '@/price-plan/price-plan';
import { PricePlanVariantType } from '@/price-plan/price-plan';
import { useSearchStore } from '@/search/search.store';
import AppPill from '@/ui/app-pill/AppPill.vue';
import AppSkeletonText from '@/ui/app-skeleton-text/AppSkeletonText.vue';

const props = defineProps<{
  pricePlan: PricePlan;
}>();

const searchStore = useSearchStore();

const bookingPolicyOverStay = computed(
  () => searchStore.activePropertyOverStay.bookingPolicyOverStay,
);

const freeCancellationStatus = computed(
  () => bookingPolicyOverStay.value.freeCancellationStatus,
);

const isNonRefundableOffer = computed(
  () =>
    props.pricePlan.type === PricePlanVariantType.Discounted &&
    !props.pricePlan.offer.isRefundable,
);

const isNonRefundable = computed<boolean>(
  () =>
    bookingPolicyOverStay.value.isNonRefundable || isNonRefundableOffer.value,
);
</script>
