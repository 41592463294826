<template>
  <div class="justify-self-end font-semibold">
    {{
      $n(unitItineraryItemStore.totalUnitRate, {
        style: 'currency',
        currency: searchStore.activeProperty.currencyCode,
      })
    }}
  </div>
</template>
<script setup lang="ts">
import type { UnitItineraryItem } from '@/booking-itinerary/booking-itinerary';
import { useUnitItineraryItemStore } from '@/booking-itinerary/unit-itinerary-item/store/unit-itinerary-item.store';
import { useSearchStore } from '@/search/search.store';

const props = defineProps<{
  unitItineraryItem: UnitItineraryItem;
}>();

const unitItineraryItemStore = useUnitItineraryItemStore(
  props.unitItineraryItem.id,
);
const searchStore = useSearchStore();
</script>
