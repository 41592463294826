<template>
  <div>
    <IconChevronDown
      :class="{ '-rotate-180 transform': isOpen }"
      class="transition-transform duration-300"
    />
  </div>
</template>

<script setup lang="ts">
import { IconChevronDown } from '@tabler/icons-vue';
import { useAccordionState } from '@/ui/app-accordion/app-accordion.composable';

const { isOpen } = useAccordionState();
</script>
