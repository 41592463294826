import { defineStore } from 'pinia';
import isEmail from 'validator/lib/isEmail';
import { computed, ref } from 'vue';
import { makeDefaultAddress } from '@/address/address.utilities';
import type { Guest } from '@/guest/guest';
import { IDB_KEY_GUEST } from '@/idb';
import { useIDBRef } from '@/idb/idb-ref.composable';
import { isPhoneNumber } from '@/string/string.utilities';

export const useGuestStore = defineStore('guest', () => {
  const guest = ref<Guest>({
    forename: '',
    surname: '',
    emailAddress: '',
    phoneNumber: '',
    address: makeDefaultAddress(),
  });

  const administrativeArea = computed(
    () => guest.value.address.administrativeArea,
  );

  const name = computed({
    get: () => `${guest.value.forename} ${guest.value.surname}`.trim(),
    set: (name) => {
      const names = name.trim().split(/\s+(?=[\S*]+$)/);

      const nameBeforeLastSpace = names[0] ?? '';
      const nameAfterLastSpace = names[1] ?? '';

      const [forename, surname] = nameAfterLastSpace
        ? [nameBeforeLastSpace, nameAfterLastSpace]
        : ['', nameBeforeLastSpace];

      guest.value.forename = forename.trim();
      guest.value.surname = surname.trim();
    },
  });

  const countryIsUS = computed(() => guest.value.address.countryCode === 'US');

  const isFullyDefined = computed(
    () =>
      !!guest.value.forename &&
      !!guest.value.surname &&
      isEmail(guest.value.emailAddress) &&
      isPhoneNumber(guest.value.phoneNumber) &&
      !!guest.value.address.line1 &&
      !!guest.value.address.city &&
      !!guest.value.address.postcode &&
      !!guest.value.address.countryCode &&
      (!countryIsUS.value || !!guest.value.address.administrativeArea),
  );

  useIDBRef(IDB_KEY_GUEST, guest);

  return {
    guest,
    administrativeArea,
    name,
    countryIsUS,
    isFullyDefined,
  };
});
