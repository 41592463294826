import type { PrivateRateOverride } from '@/private-rate-override/private-rate-override';
import type { Promocode } from '@/property/promocode/promocode';

export enum CodeResourceType {
  Promocode = 'promocode',
  PrivateRateOverride = 'private_rate_override',
}

export type CodeResource = {
  code: string;
} & (
  | {
      type: CodeResourceType.Promocode;
      promocode: Promocode;
      privateRateOverride?: never;
    }
  | {
      type: CodeResourceType.PrivateRateOverride;
      promocode?: never;
      privateRateOverride: PrivateRateOverride;
    }
);

export type PromocodeCodeResource = Extract<
  CodeResource,
  { type: CodeResourceType.Promocode }
>;

export type PrivateRateOverrideCodeResource = Extract<
  CodeResource,
  { type: CodeResourceType.PrivateRateOverride }
>;
