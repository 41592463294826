<template>
  <div ref="container" class="flex scroll-my-2 justify-end">
    <AppListbox v-model="selectedChildAge">
      <div
        class="flex items-center"
        :class="{ 'animate-shake': shouldAnimateMissingAgeError }"
        @animationend="shouldAnimateMissingAgeError = false"
      >
        <div class="mr-2 text-right font-medium capitalize leading-none">
          <AppListboxLabel :text="$t('childAge')" />

          <Transition
            enter-active-class="duration-200"
            enter-from-class="opacity-0"
          >
            <div
              v-show="shouldShowMissingAgeError"
              class="text-xs text-red-700"
            >
              {{ $t('ageNeeded') }}
            </div>
          </Transition>
        </div>

        <div ref="listboxOptionsAnchor">
          <AppListboxButton
            :text="selectedChildAge"
            class="w-24 border py-2"
            :class="
              shouldShowMissingAgeError
                ? 'border-red-500 bg-red-500/10 text-red-700'
                : 'border-slate-900'
            "
          />
        </div>
      </div>

      <AppListboxOptions :anchor="listboxOptionsAnchor">
        <AppListboxOption disabled :value="DEFAULT_VALUE" />

        <AppListboxOption
          v-for="childAge in childAges"
          :key="childAge"
          :value="childAge.toString()"
        />
      </AppListboxOptions>
    </AppListbox>
  </div>
</template>

<script setup lang="ts">
import { useEventBus } from '@vueuse/core';
import { range } from 'lodash-es';
import { computed, nextTick, ref } from 'vue';
import { occupancySelectorAfterValidateEventBusKey } from '@/event-bus/event-bus';
import type { OccupancySelectorChildOccupant } from '@/occupancy-selector/child-occupant/occupancy-selector-child-occupant';
import { useOccupancySelectorStore } from '@/occupancy-selector/occupancy-selector.store';
import AppListbox from '@/ui/app-listbox/AppListbox.vue';
import AppListboxButton from '@/ui/app-listbox/AppListboxButton.vue';
import AppListboxLabel from '@/ui/app-listbox/AppListboxLabel.vue';
import AppListboxOption from '@/ui/app-listbox/AppListboxOption.vue';
import AppListboxOptions from '@/ui/app-listbox/AppListboxOptions.vue';

const DEFAULT_VALUE = '-';

const props = defineProps<{
  childOccupant: OccupancySelectorChildOccupant;
  minimumChildAge: number;
  maximumChildAge: number;
}>();

const { setChildAge, firstChildOccupantWithMissingAgeError } =
  useOccupancySelectorStore();

const container = ref<HTMLDivElement>();

const listboxOptionsAnchor = ref<HTMLDivElement | null>(null);

const shouldAnimateMissingAgeError = ref(false);

const selectedChildAge = computed<string>({
  get() {
    return props.childOccupant.age?.toString() ?? DEFAULT_VALUE;
  },
  set(childAge) {
    const childAgeHasChanged = childAge !== selectedChildAge.value;

    if (childAge !== DEFAULT_VALUE && childAgeHasChanged) {
      setChildAge(props.childOccupant.id, Number(childAge));
    }
  },
});

const childAges = computed(() =>
  range(props.minimumChildAge, props.maximumChildAge + 1),
);

const shouldShowMissingAgeError = computed(
  () => props.childOccupant.shouldShowMissingAgeError,
);

const triggerMissingAgeError = (): void => {
  shouldAnimateMissingAgeError.value = true;

  const isFirstChildWithMissingAgeError =
    props.childOccupant.id === firstChildOccupantWithMissingAgeError.value?.id;

  if (isFirstChildWithMissingAgeError) {
    void scrollContainerIntoView();
  }
};

const scrollContainerIntoView = async (): Promise<void> => {
  /**
   * By awaiting the next tick, we wait for other DOM transformations
   * to finish before scrolling - to ensure the final scroll position
   * is always consistent.
   */
  await nextTick();

  container.value!.scrollIntoView({
    behavior: 'smooth',
    block: 'nearest',
  });
};

useEventBus(occupancySelectorAfterValidateEventBusKey).on(() => {
  if (shouldShowMissingAgeError.value) {
    triggerMissingAgeError();
  }
});
</script>
