import { isAnyStayDateOnApplicableDaysOfWeekOfPercentagePromocode } from '@/property/promocode/percentage-promocode/percentage-promocode.utilities';
import type { Promocode } from '@/property/promocode/promocode';
import { PromocodeType } from '@/property/promocode/promocode';
import type { StayDates } from '@/stay-dates/stay-dates';
import { getStayLengthFromStayDates } from '@/stay-dates/stay-dates.utilities';

export const isPromocodeValidOnStayDates = (
  stayDates: StayDates,
  promocode: Promocode,
): boolean => {
  if (getStayLengthFromStayDates(stayDates) < promocode.minimumStayLengthDays) {
    return false;
  }

  const alwaysValid = promocode.fromDate === '' || promocode.toDate === '';
  if (
    !alwaysValid &&
    (stayDates.checkInDate < promocode.fromDate ||
      stayDates.checkOutDate > promocode.toDate)
  ) {
    return false;
  }

  if (promocode.promocodeType === PromocodeType.Percentage) {
    return isAnyStayDateOnApplicableDaysOfWeekOfPercentagePromocode(
      stayDates,
      promocode,
    );
  }

  return true;
};
