<template>
  <div class="flex justify-between">
    <div class="font-semibold">
      <span
        class="break-anywhere"
        :class="{ 'line-through': booking.isCancelled }"
      >
        {{ bookingExtra.extraName }}
      </span>

      <div
        v-if="bookingExtra.isMandatory"
        class="whitespace-nowrap text-xs font-medium capitalize text-gray-600"
      >
        {{ $t('mandatory') }}
      </div>

      <div class="font-normal">
        {{ priceDescriptionTag }}
      </div>
    </div>

    <div class="text-right">
      <div>
        {{
          $n(bookingExtra.totalCharge, {
            style: 'currency',
            currency: property.currencyCode,
          })
        }}
      </div>

      <div v-if="booking.isCancelled" class="text-xs">
        {{ $t('cancellationCharge') }}
      </div>
    </div>
  </div>

  <div v-if="bookingExtra.bookingExtraQuestion?.answer" class="break-anywhere">
    <div class="text-xs font-medium text-gray-600">
      {{ bookingExtra.bookingExtraQuestion.question }}
    </div>

    <div class="border-l border-zinc-500 pl-2 text-sm text-zinc-500">
      {{ bookingExtra.bookingExtraQuestion.answer }}
    </div>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import type { BookingExtra } from '@/booking/extra/booking-extra';
import { useBookingConfirmationStore } from '@/booking-confirmation-page/booking-confirmation.store';

const props = defineProps<{
  bookingExtra: BookingExtra;
}>();

const { property, booking } = storeToRefs(useBookingConfirmationStore());

const { n, t } = useI18n();

const priceDescriptionTag = computed(() => {
  const chargeCalculationVariables = {
    quantity: props.bookingExtra.quantity,
    charge: n(props.bookingExtra.itemCharge, {
      style: 'currency',
      currency: property.value.currencyCode,
    }),
    numberOfNights: props.bookingExtra.numberOfNights,
  };

  return props.bookingExtra.numberOfNights
    ? t(
        'quantityTimesChargeEachForXNights',
        chargeCalculationVariables,
        props.bookingExtra.numberOfNights,
      )
    : t('quantityTimesChargeEach', chargeCalculationVariables);
});
</script>
