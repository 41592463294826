import { mapDiscountOfferFromDTO } from '@/property/offer/discount-offer/discount-offer.mapper';
import { mapFreeNightOfferFromDTO } from '@/property/offer/free-night-offer/free-night-offer.mapper';
import type { Offer, OfferDTO } from '@/property/offer/offer';
import { OfferType } from '@/property/offer/offer';
import { mapPackageOfferFromDTO } from '@/property/offer/package-offer/package-offer.mapper';

export const mapOfferFromDTO = (offerDTO: OfferDTO): Offer => {
  const { offerType } = offerDTO;

  switch (offerType) {
    case OfferType.Discount:
      return mapDiscountOfferFromDTO(offerDTO);
    case OfferType.FreeNight:
      return mapFreeNightOfferFromDTO(offerDTO);
    case OfferType.Package:
      return mapPackageOfferFromDTO(offerDTO);
  }
};
