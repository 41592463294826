<template>
  <div
    class="flex flex-col"
    data-test="BookingConfirmationPaymentDetailsTransaction"
  >
    <div class="flex justify-between">
      <div class="truncate font-bold">
        {{ $t(bookingPayment.isRefund ? 'refunded' : 'paid') }}
      </div>

      <div class="truncate">
        {{
          $n(
            bookingPayment.isRefund
              ? -bookingPayment.amount
              : bookingPayment.amount,
            {
              style: 'currency',
              currency: property.currencyCode,
            },
          )
        }}
      </div>
    </div>

    <div class="truncate text-sm">{{ bookingPayment.payerName }}</div>

    <div class="text-sm">
      {{
        $d(createDateObject(bookingPayment.date), {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        })
      }}
    </div>

    <div
      v-if="bookingPayment.cardChargeAmount"
      class="mt-1 flex justify-between"
    >
      <div class="truncate font-bold">
        {{ $t('cardCharge') }}
      </div>

      <div class="truncate">
        {{
          $n(
            bookingPayment.isRefund
              ? -bookingPayment.cardChargeAmount
              : bookingPayment.cardChargeAmount,
            {
              style: 'currency',
              currency: property.currencyCode,
            },
          )
        }}
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import type { BookingPayment } from '@/booking/payment/booking-payment';
import { useBookingConfirmationStore } from '@/booking-confirmation-page/booking-confirmation.store';
import { createDateObject } from '@/date/date.utilities';

defineProps<{
  bookingPayment: BookingPayment;
}>();

const { property } = storeToRefs(useBookingConfirmationStore());
</script>
