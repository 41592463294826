import { createInjectionState } from '@vueuse/core';
import { ref } from 'vue';

interface Input {
  validate: () => boolean;
  errorMessages: readonly string[];
}

const [useProvideInputValidationGroup, useMaybeInputValidationGroup] =
  createInjectionState(() => {
    const inputs = ref<Input[]>([]);

    const register = (input: Input) => {
      inputs.value.push(input);
    };

    return { inputs, register };
  });

const useInputValidationGroup = () => {
  const inputValidationGroup = useMaybeInputValidationGroup();

  if (!inputValidationGroup) {
    throw new Error(
      'Please call `useProvideInputValidationGroup` on the appropriate parent component',
    );
  }

  return inputValidationGroup;
};

export { useInputValidationGroup, useProvideInputValidationGroup };
