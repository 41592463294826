<template>
  <div
    ref="animationContainer"
    :style="{
      height: `${height}px`,
      transitionDuration: `${TRANSITION_DURATION}ms`,
    }"
    :class="[
      {
        'overflow-hidden': height === 0 || shouldHideOverflow,
      },
      shouldAnimate ? 'transition-[height]' : 'transition-none',
    ]"
    @transitionend="onTransitionEnd"
  >
    <div ref="contentContainer">
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useElementSize } from '@vueuse/core';
import { onActivated, onMounted, ref, watch } from 'vue';

const TRANSITION_DURATION = 500;

const shouldAnimate = ref(false);
const shouldHideOverflow = ref(false);

const animationContainer = ref<HTMLDivElement | null>(null);
const contentContainer = ref<HTMLDivElement | null>(null);

const { height } = useElementSize(contentContainer);

const onTransitionEnd = (event: TransitionEvent): void => {
  if (isTransitionEventTargetEqualToTransitionContainer(event)) {
    shouldHideOverflow.value = false;
  }
};

/**
 * Returns true if the element that triggered the given transition event is
 * equal to the animation container. This is useful to detect when child
 * elements have caused the event, rather than the container element directly.
 */
const isTransitionEventTargetEqualToTransitionContainer = ({
  target,
}: TransitionEvent): boolean => target === animationContainer.value;

/**
 * This is useful to ensure that the animation does not occur when the
 * content is first rendered, or re-rendered to the DOM (e.g., after
 * a component is re-activated).
 */
const disableAnimationsForTransitionDuration = () => {
  shouldAnimate.value = false;

  setTimeout(() => {
    shouldAnimate.value = true;
  }, TRANSITION_DURATION);
};

/**
 * Rather than using a `transitionstart` event to hide overflow, we use a
 * height watcher since this will guarantee we hide overflow before any
 * DOM updates take place, removing the occasional race-condition where
 * the animation begins before overflow can be hidden.
 */
watch(
  height,
  () => {
    shouldHideOverflow.value = true;
  },
  { flush: 'pre' },
);

onMounted(disableAnimationsForTransitionDuration);

onActivated(disableAnimationsForTransitionDuration);
</script>
