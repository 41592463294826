import type { PropertyAvailability } from '@/availability/property-availability/property-availability';
import { findPropertyAvailabilityPrivateRateOverrideAvailabilityByDate } from '@/availability/property-availability/property-availability.utilities';
import { isDateWithinDaysOfWeek } from '@/day-of-week/day-of-week.utilities';
import {
  PrivateRateOverrideActivationType,
  type PrivateRateOverride,
} from '@/private-rate-override/private-rate-override';
import type { PrivateRateOverrideUnit } from '@/private-rate-override/unit/private-rate-override-unit';
import type { PrivateRateOverrideWayToSell } from '@/private-rate-override/way-to-sell/private-rate-override-way-to-sell';
import type { IncludedMeal } from '@/property/meal/included-meal/included-meal';
import {
  convertToIncludedMeal,
  isAnyMealTypeClosedOutOnAnyStayDate,
} from '@/property/meal/meal.utilities';
import type { Property } from '@/property/property';
import { findPropertyMealsByMealTypes } from '@/property/property.utilities';
import type { NightlyRates } from '@/rates/nightly-rates/nightly-rates';
import { adjustNightlyRatesByRate } from '@/rates/nightly-rates/nightly-rates.utilities';
import type { StayDates } from '@/stay-dates/stay-dates';
import {
  getNightsOfStayFromStayDates,
  getStayLengthFromStayDates,
} from '@/stay-dates/stay-dates.utilities';

export const findPrivateRateOverrideUnitById = (
  { units }: PrivateRateOverride,
  unitId: number,
): PrivateRateOverrideUnit | undefined =>
  units.find((unit) => unit.unitId === unitId);

export const findPrivateRateOverrideWayToSellById = (
  { waysToSell }: PrivateRateOverride,
  wayToSellId: number,
): PrivateRateOverrideWayToSell | undefined =>
  waysToSell.find((wayToSell) => wayToSell.wayToSellId === wayToSellId);

export const findPrivateRateOverrideUnitByIdOrFail = (
  privateRateOverride: PrivateRateOverride,
  unitId: number,
): PrivateRateOverrideUnit => {
  const privateRateOverrideUnit = findPrivateRateOverrideUnitById(
    privateRateOverride,
    unitId,
  );

  if (!privateRateOverrideUnit) {
    throw new Error(`Could not find private rate override unit ${unitId}`);
  }

  return privateRateOverrideUnit;
};

export const findPrivateRateOverrideWayToSellByIdOrFail = (
  privateRateOverride: PrivateRateOverride,
  wayToSellId: number,
): PrivateRateOverrideWayToSell => {
  const privateRateOverrideWayToSell = findPrivateRateOverrideWayToSellById(
    privateRateOverride,
    wayToSellId,
  );

  if (!privateRateOverrideWayToSell) {
    throw new Error(
      `Could not find private rate override way to sell ${wayToSellId}`,
    );
  }

  return privateRateOverrideWayToSell;
};

export const findPrivateRateOverrideIncludedPropertyMeals = (
  { includedMealTypes }: PrivateRateOverride,
  property: Property,
): IncludedMeal[] =>
  findPropertyMealsByMealTypes(property, includedMealTypes).map(
    convertToIncludedMeal,
  );

export const applyPrivateRateOverrideToUnitNightlyRates = (
  privateRateOverride: PrivateRateOverride,
  unitNightlyRates: NightlyRates,
  unitId: number,
): NightlyRates => {
  const { rate } = findPrivateRateOverrideUnitByIdOrFail(
    privateRateOverride,
    unitId,
  );

  return adjustNightlyRatesByRate(unitNightlyRates, rate);
};

export const applyPrivateRateOverrideToWayToSellNightlyRates = (
  privateRateOverride: PrivateRateOverride,
  wayToSellNightlyRates: NightlyRates,
  wayToSellId: number,
): NightlyRates => {
  const { rate } = findPrivateRateOverrideWayToSellByIdOrFail(
    privateRateOverride,
    wayToSellId,
  );

  return adjustNightlyRatesByRate(wayToSellNightlyRates, rate);
};

export const isPrivateRateOverrideApplicableOnStayDates = (
  privateRateOverride: PrivateRateOverride,
  stayDates: StayDates,
  propertyAvailability: PropertyAvailability,
): boolean =>
  isStayLengthWithinBounds(
    getStayLengthFromStayDates(stayDates),
    privateRateOverride,
  ) &&
  !isAnyMealClosedOutOnAnyStayDate(
    privateRateOverride,
    stayDates,
    propertyAvailability,
  ) &&
  isApplicableForActivationType(
    privateRateOverride,
    stayDates,
    propertyAvailability,
  );

const isStayLengthWithinBounds = (
  stayLength: number,
  { stayLengthBounds: { minimum, maximum } }: PrivateRateOverride,
): boolean => stayLength >= minimum && stayLength <= maximum;

const isAnyMealClosedOutOnAnyStayDate = (
  { includedMealTypes }: PrivateRateOverride,
  stayDates: StayDates,
  propertyAvailability: PropertyAvailability,
): boolean =>
  isAnyMealTypeClosedOutOnAnyStayDate(
    includedMealTypes,
    stayDates,
    propertyAvailability,
  );

const isApplicableForActivationType = (
  privateRateOverride: PrivateRateOverride,
  stayDates: StayDates,
  propertyAvailability: PropertyAvailability,
): boolean => {
  switch (privateRateOverride.activationType) {
    case PrivateRateOverrideActivationType.Arrivals:
      return isApplicableForArrivalsActivationType(
        privateRateOverride,
        stayDates,
        propertyAvailability,
      );
    case PrivateRateOverrideActivationType.NightsOfStay:
      return isApplicableForNightsOfStayActivationType(
        privateRateOverride,
        stayDates,
        propertyAvailability,
      );
  }
};

const isApplicableForArrivalsActivationType = (
  privateRateOverride: PrivateRateOverride,
  stayDates: StayDates,
  propertyAvailability: PropertyAvailability,
): boolean =>
  isDateWithinDateBounds(stayDates.checkInDate, privateRateOverride) &&
  isDateApplicableDayOfWeek(stayDates.checkInDate, privateRateOverride) &&
  !isClosedOutOnAnyDate(
    privateRateOverride,
    propertyAvailability,
    getNightsOfStayFromStayDates(stayDates),
  );

const isApplicableForNightsOfStayActivationType = (
  privateRateOverride: PrivateRateOverride,
  stayDates: StayDates,
  propertyAvailability: PropertyAvailability,
): boolean =>
  getNightsOfStayFromStayDates(stayDates).every(
    (nightOfStay) =>
      isDateWithinDateBounds(nightOfStay, privateRateOverride) &&
      isDateApplicableDayOfWeek(nightOfStay, privateRateOverride) &&
      !isClosedOutOnDate(
        privateRateOverride,
        propertyAvailability,
        nightOfStay,
      ),
  );

const isDateWithinDateBounds = (
  date: string,
  { dateBounds }: PrivateRateOverride,
): boolean => !dateBounds || (date >= dateBounds.from && date <= dateBounds.to);

const isDateApplicableDayOfWeek = (
  date: string,
  { daysOfWeek }: PrivateRateOverride,
): boolean => isDateWithinDaysOfWeek(date, daysOfWeek);

const isClosedOutOnAnyDate = (
  privateRateOverride: PrivateRateOverride,
  propertyAvailability: PropertyAvailability,
  dates: string[],
): boolean =>
  dates.some((date) =>
    isClosedOutOnDate(privateRateOverride, propertyAvailability, date),
  );

const isClosedOutOnDate = (
  { id }: PrivateRateOverride,
  propertyAvailability: PropertyAvailability,
  date: string,
): boolean =>
  !!findPropertyAvailabilityPrivateRateOverrideAvailabilityByDate(
    propertyAvailability,
    id,
    date,
  )?.isClosedOut;
