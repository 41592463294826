import { mapPseudoUnitAvailabilityFromDTO } from '@/availability/pseudo-unit-availability/pseudo-unit-availability.mapper';
import type {
  UnitAvailability,
  UnitAvailabilityDTO,
} from '@/availability/unit-availability/unit-availability';

export const mapUnitAvailabilityFromDTO = (
  unitAvailabilityDTO: UnitAvailabilityDTO,
): UnitAvailability => ({
  unitId: unitAvailabilityDTO.unitId,
  isClosedOut: unitAvailabilityDTO.isClosedOut,
  minimumStay: unitAvailabilityDTO.minimumStay,
  allocation: unitAvailabilityDTO.allocation,
  rate: Number(unitAvailabilityDTO.rate),
  pseudoUnitAvailabilities: unitAvailabilityDTO.pseudoUnitAvailabilities.map(
    mapPseudoUnitAvailabilityFromDTO,
  ),
});
