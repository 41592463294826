import { differenceInWeeks } from 'date-fns';
import { get, update } from 'idb-keyval';
import type {
  AbandonedCart,
  CapturedAbandonedCart,
} from '@/abandoned-cart/abandoned-cart';
import { sendAbandonedCart } from '@/abandoned-cart/abandoned-cart.api';
import { IDB_KEY_CAPTURED_ABANDONED_CARTS } from '@/idb';

const CAPTURED_ABANDONED_CART_EXPIRY_TIME_IN_WEEKS = 12;

export const captureNewAbandonedCart = async (
  abandonedCart: AbandonedCart,
  widgetId: string,
  propertyId: number,
) => {
  await filterOutInvalidCapturedAbandonedCartsFromIdb();

  if (await hasAbandonedCartBeenCapturedRecently(abandonedCart)) {
    return;
  }

  void sendAbandonedCart(abandonedCart, widgetId, propertyId);
  void updateCapturedAbandonedCartInIdb({
    emailAddress: abandonedCart.emailAddress,
    uniqueUserId: abandonedCart.uniqueUserId,
    capturedAtTimestamp: Date.now(),
  });
};

const getCapturedAbandonedCartFromIdbByUniqueUserId = async (
  uniqueUserId: string,
): Promise<CapturedAbandonedCart | undefined> => {
  const idbCapturedAbandonedCarts = await get<CapturedAbandonedCart[]>(
    IDB_KEY_CAPTURED_ABANDONED_CARTS,
  );

  return idbCapturedAbandonedCarts?.find(
    (idbCapturedAbandonedCart) =>
      idbCapturedAbandonedCart.uniqueUserId === uniqueUserId,
  );
};

const updateCapturedAbandonedCartInIdb = async (
  newCapturedAbandonedCart: CapturedAbandonedCart,
) => {
  await update(
    IDB_KEY_CAPTURED_ABANDONED_CARTS,
    (idbCapturedAbandonedCarts: CapturedAbandonedCart[] | undefined) => {
      if (!idbCapturedAbandonedCarts) {
        return [newCapturedAbandonedCart];
      }

      return [
        ...idbCapturedAbandonedCarts.filter(
          (idbCapturedAbandonedCart) =>
            idbCapturedAbandonedCart.uniqueUserId !==
            newCapturedAbandonedCart.uniqueUserId,
        ),
        newCapturedAbandonedCart,
      ];
    },
  );
};

const hasAbandonedCartBeenCapturedRecently = async (
  abandonedCart: AbandonedCart,
): Promise<boolean> => {
  const existingCapturedAbandonedCart =
    await getCapturedAbandonedCartFromIdbByUniqueUserId(
      abandonedCart.uniqueUserId,
    );

  return (
    existingCapturedAbandonedCart !== undefined &&
    existingCapturedAbandonedCart.emailAddress === abandonedCart.emailAddress &&
    differenceInWeeks(
      Date.now(),
      existingCapturedAbandonedCart.capturedAtTimestamp,
    ) < CAPTURED_ABANDONED_CART_EXPIRY_TIME_IN_WEEKS
  );
};

const filterOutInvalidCapturedAbandonedCartsFromIdb =
  async (): Promise<void> => {
    await update(
      IDB_KEY_CAPTURED_ABANDONED_CARTS,
      (idbCapturedAbandonedCarts: CapturedAbandonedCart[] | undefined) =>
        idbCapturedAbandonedCarts?.filter(
          (capturedAbandonedCart) =>
            typeof capturedAbandonedCart.capturedAtTimestamp === 'number' &&
            typeof capturedAbandonedCart.emailAddress === 'string' &&
            typeof capturedAbandonedCart.uniqueUserId === 'string' &&
            differenceInWeeks(
              Date.now(),
              capturedAbandonedCart.capturedAtTimestamp,
            ) < CAPTURED_ABANDONED_CART_EXPIRY_TIME_IN_WEEKS,
        ) ?? [],
    );
  };
