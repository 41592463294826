<template>
  <div class="flex justify-between">
    <div class="text-xl font-semibold">
      {{ toTitleCase($t('privateRatesApplied')) }}
    </div>

    <div class="text-neutral-500">
      {{ bookingPrivateRateOverride.codeUsed }}
    </div>
  </div>
</template>

<script setup lang="ts">
import type { BookingPrivateRateOverride } from '@/booking/private-rate-override/booking-private-rate-override';
import { useLocaleString } from '@/string/locale-string.composable';

defineProps<{ bookingPrivateRateOverride: BookingPrivateRateOverride }>();

const { toTitleCase } = useLocaleString();
</script>
