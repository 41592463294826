<template>
  <AppModal class="z-30" :is-open="isOpen" @close="$emit('cancel')">
    <AppModalDialog class="sm:max-w-2xl" @close="$emit('cancel')">
      <template #header>
        <span class="align-middle font-semibold leading-none">
          {{ $t('removePrivateRate') }}
        </span>
      </template>

      {{
        $t(
          'ifYouRemoveThisPrivateRateYourUnitsWillBeRemovedAndYouWillBeRedirected',
          { unitTypeNamePlural, unitTypeName, unitTypeGender },
        )
      }}

      <template #footer>
        <div class="flex justify-between space-x-4">
          <AppSecondaryButton
            class="w-full p-3"
            :button-text="capitalize($t('cancel'))"
            @click="$emit('cancel')"
          />

          <AppPrimaryButton
            class="w-full p-3"
            :button-text="capitalize($t('continue'))"
            @click="$emit('confirm')"
          />
        </div>
      </template>
    </AppModalDialog>
  </AppModal>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useUnitType } from '@/property/unit/type/unit-type.composable';
import { useSearchStore } from '@/search/search.store';
import { useLocaleString } from '@/string/locale-string.composable';
import AppModal from '@/ui/app-modal/AppModal.vue';
import AppModalDialog from '@/ui/app-modal/AppModalDialog.vue';
import AppPrimaryButton from '@/ui/app-primary-button/AppPrimaryButton.vue';
import AppSecondaryButton from '@/ui/app-secondary-button/AppSecondaryButton.vue';

defineProps<{ isOpen: boolean }>();

defineEmits(['cancel', 'confirm']);

const searchStore = useSearchStore();

const { capitalize } = useLocaleString();

const { unitTypeName, unitTypeNamePlural, unitTypeGender } = useUnitType(
  computed(() => searchStore.activeProperty.unitType),
);
</script>
