<template>
  <p class="break-anywhere font-medium text-slate-800" translate="yes">
    {{
      unitNumber
        ? $t('numberedUnitName', {
            unitNumber,
            unitName: unitItineraryItemStore.unit.name,
          })
        : unitItineraryItemStore.unit.name
    }}
  </p>
</template>

<script setup lang="ts">
import { useUnitItineraryItemStore } from '@/booking-itinerary/unit-itinerary-item/store/unit-itinerary-item.store';

const props = defineProps<{
  unitItineraryItemId: string;
  unitNumber: number | undefined;
}>();

const unitItineraryItemStore = useUnitItineraryItemStore(
  props.unitItineraryItemId,
);
</script>
