import type { PaymentGatewayType } from '@/property/payment-gateway/payment-gateway';
import type {
  BOOKING_CONFIRMATION_ROUTE,
  EXTRAS_ROUTE,
  GUEST_DETAILS_ROUTE,
  PAYMENTS_ROUTE,
  UNIT_SELECTION_ROUTE,
  VANITY_ROUTE,
} from '@/router';

export enum UserEventActionLabel {
  Search = 'search',
  BookingComplete = 'booking_complete',
  PageView = 'page_view',
}

export interface UserEvent {
  userEventIdentifier: {
    uniqueUserId: string;
    createdAtTimestamp: number;
  };
  userEventAction: UserEventAction;
  userAgent: string;
  screenSize: {
    width: number;
    height: number;
  };
  datetime: number;
  timezone: string;
}

export type UserEventAction =
  | PageViewUserEventAction
  | SearchUserEventAction
  | BookingCompleteUserEventAction;

export interface PageViewUserEventAction {
  label: UserEventActionLabel.PageView;
  metadata:
    | {
        page_name:
          | typeof VANITY_ROUTE
          | typeof UNIT_SELECTION_ROUTE
          | typeof EXTRAS_ROUTE
          | typeof GUEST_DETAILS_ROUTE
          | typeof PAYMENTS_ROUTE;
        widget_id: string;
        property_id: number;
        is_express_checkout: boolean;
        used_elements: boolean;
        payment_gateway: PaymentGatewayType;
      }
    | {
        page_name: typeof BOOKING_CONFIRMATION_ROUTE;
        widget_id: string;
        property_id: number;
        booking_id: string;
        is_express_checkout: boolean;
        used_elements: boolean;
        payment_gateway: PaymentGatewayType;
      };
}

export interface SearchUserEventAction {
  label: UserEventActionLabel.Search;
  metadata: {
    check_in_date: string;
    check_out_date: string;
    did_find_availability: boolean;
    widget_id: string;
    property_id: number;
    is_user_acknowledged: boolean;
  };
}

export interface BookingCompleteUserEventAction {
  label: UserEventActionLabel.BookingComplete;
  metadata: {
    widget_id: string;
    property_id: number;
    booking_id: string;
    is_express_checkout: boolean;
    used_elements: boolean;
    payment_gateway: PaymentGatewayType;
  };
}
