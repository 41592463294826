import type { Extra, ExtraDTO } from '@/property/extra/extra';
import { mapExtraTaxFromDTO } from '@/property/extra/tax/extra-tax.mapper';

export const mapExtraFromDTO = (extraDTO: ExtraDTO): Extra => ({
  id: extraDTO.id,
  name: extraDTO.name,
  description: extraDTO.description,
  charge: Number(extraDTO.charge),
  question: extraDTO.question,
  dateRange: extraDTO.dateRange
    ? {
        fromDate: extraDTO.dateRange.fromDate,
        toDate: extraDTO.dateRange.toDate,
      }
    : undefined,
  tax: extraDTO.tax ? mapExtraTaxFromDTO(extraDTO.tax) : undefined,
  ...(extraDTO.isMandatory
    ? {
        isMandatory: true,
        chargeType: extraDTO.chargeType,
      }
    : {
        isMandatory: false,
        chargeType: extraDTO.chargeType,
      }),
});
