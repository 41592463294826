<template>
  <AppRadioGroup v-model="bookingUnit.twinDoubleBedOption">
    <AppRadioGroupLabel class="mb-2 font-medium">
      {{ toTitleCase($t('bedPreference')) }}
    </AppRadioGroupLabel>

    <div class="flex flex-col items-start gap-2">
      <AppRadioGroupOption :value="TwinDoubleBedOption.Double">
        <span>{{ toTitleCase($t('oneDoubleBed')) }}</span>

        <img :src="DoubleBed" alt="" class="ml-2 w-12" />
      </AppRadioGroupOption>

      <AppRadioGroupOption :value="TwinDoubleBedOption.Twin">
        <span>{{ toTitleCase($t('twoSingleBeds')) }}</span>

        <img :src="TwinBed" alt="" class="ml-2 w-12" />
      </AppRadioGroupOption>
    </div>
  </AppRadioGroup>
</template>

<script setup lang="ts">
import DoubleBed from '@/assets/images/double-bed.svg';
import TwinBed from '@/assets/images/twin-bed.svg';
import type { ActiveBookingUnit } from '@/booking/unit/active/active-booking-unit';
import { TwinDoubleBedOption } from '@/booking/unit/active/active-booking-unit';
import { useLocaleString } from '@/string/locale-string.composable';
import AppRadioGroup from '@/ui/app-radio-group/AppRadioGroup.vue';
import AppRadioGroupLabel from '@/ui/app-radio-group/AppRadioGroupLabel.vue';
import AppRadioGroupOption from '@/ui/app-radio-group/AppRadioGroupOption.vue';

const bookingUnit = defineModel<ActiveBookingUnit>({ required: true });

const { toTitleCase } = useLocaleString();
</script>
