import { isEqual } from 'lodash-es';
import { generateUniqueId } from '@/generator/generator.utilities';
import type { ChildOccupant } from '@/occupancy/child-occupant/child-occupant';
import type { Occupancy } from '@/occupancy/occupancy';
import type { OccupancySelectorUnitOccupancy } from '@/occupancy-selector/unit-occupancy/occupancy-selector-unit-occupancy';
import { MAX_INFANT_AGE } from '@/property/property';

export const getNumberOfChildrenInOccupancy = ({
  children,
}: Occupancy): number => children.length;

export const getNumberOfOccupantsInOccupancy = (occupancy: Occupancy): number =>
  occupancy.numberOfAdults + getNumberOfChildrenInOccupancy(occupancy);

export const getTotalNumberOfOccupantsInOccupancies = (
  occupancies: Occupancy[],
): number =>
  occupancies.reduce(
    (totalNumberOfOccupants, occupancy) =>
      totalNumberOfOccupants + getNumberOfOccupantsInOccupancy(occupancy),
    0,
  );

export const getTotalNumberOfAdultsInOccupancies = (
  occupancies: Occupancy[],
): number =>
  occupancies.reduce(
    (totalNumberOfAdults, occupancy) =>
      totalNumberOfAdults + occupancy.numberOfAdults,
    0,
  );

export const getAgePartitionedChildrenInOccupancy = ({
  children,
}: Occupancy): ChildrenPartitionedByAge =>
  children.reduce<ChildrenPartitionedByAge>(
    (agePartitionedChildren, childOccupant) => {
      if (childOccupant.age > MAX_INFANT_AGE) {
        agePartitionedChildren.nonInfantChildren.push(childOccupant);
      } else {
        agePartitionedChildren.infantChildren.push(childOccupant);
      }

      return agePartitionedChildren;
    },
    { nonInfantChildren: [], infantChildren: [] },
  );

export const getAgePartitionedNumberOfChildrenInOccupancy = (
  occupancy: Occupancy,
): NumberOfChildrenPartitionedByAge => {
  const { nonInfantChildren, infantChildren } =
    getAgePartitionedChildrenInOccupancy(occupancy);

  return {
    numberOfNonInfantChildren: nonInfantChildren.length,
    numberOfInfantChildren: infantChildren.length,
  };
};

export const doOccupanciesContainOccupancy = (
  occupancies: Occupancy[],
  occupancyToFind: Occupancy,
): boolean =>
  !!occupancies.find((occupancy) => isEqual(occupancy, occupancyToFind));

export const removeChildrenFromOccupancies = (
  occupancies: Occupancy[],
): Occupancy[] =>
  occupancies.map((occupancy) => ({
    ...occupancy,
    children: [],
  }));

/**
 * Returns whether occupancy A is "larger" than occupancy B. To be "larger", an Occupancy
 * must have:
 * - The greater number of adults.
 * - If both occupancies have the same number of adults, ties are broken using the greater
 * number of non-infant children.
 * - If both occupancies have the same number of non-infant children, ties are broken using
 * the greater number of infant children.
 * - If both occupancies have the same number of infant children, then both occupancies are
 * the same size (and consequently, occupancy A is not larger).
 */
export const isOccupancyALargerThanOccupancyB = (
  occupancyA: Occupancy,
  occupancyB: Occupancy,
): boolean => {
  const occupancyANumberOfAdults = occupancyA.numberOfAdults;

  const occupancyBNumberOfAdults = occupancyB.numberOfAdults;

  if (occupancyANumberOfAdults !== occupancyBNumberOfAdults) {
    return occupancyANumberOfAdults > occupancyBNumberOfAdults;
  }

  const {
    numberOfNonInfantChildren: occupancyANumberOfNonInfantChildren,
    numberOfInfantChildren: occupancyANumberOfInfantChildren,
  } = getAgePartitionedNumberOfChildrenInOccupancy(occupancyA);

  const {
    numberOfNonInfantChildren: occupancyBNumberOfNonInfantChildren,
    numberOfInfantChildren: occupancyBNumberOfInfantChildren,
  } = getAgePartitionedNumberOfChildrenInOccupancy(occupancyB);

  if (
    occupancyANumberOfNonInfantChildren !== occupancyBNumberOfNonInfantChildren
  ) {
    return (
      occupancyANumberOfNonInfantChildren > occupancyBNumberOfNonInfantChildren
    );
  }

  if (occupancyANumberOfInfantChildren !== occupancyBNumberOfInfantChildren) {
    return occupancyANumberOfInfantChildren > occupancyBNumberOfInfantChildren;
  }

  return false;
};

export const mapOccupancySelectorUnitOccupanciesToOccupancies = (
  occupancySelectorUnitOccupancies: OccupancySelectorUnitOccupancy[],
): Occupancy[] =>
  occupancySelectorUnitOccupancies.map(
    ({ numberOfAdults, childOccupants }) => ({
      numberOfAdults,
      children: childOccupants.map(({ age }) => {
        if (age === undefined) {
          throw new Error(
            'Found an undefined child age when mapping OccupancySelectorUnitOccupancies to Occupancies',
          );
        }

        return {
          age,
        };
      }),
    }),
  );

export const mapOccupanciesToOccupancySelectorUnitOccupancies = (
  occupancies: Occupancy[],
): OccupancySelectorUnitOccupancy[] =>
  occupancies.map(({ numberOfAdults, children }) => ({
    id: generateUniqueId(),
    numberOfAdults,
    childOccupants: children.map(({ age }) => ({
      id: generateUniqueId(),
      age,
      shouldShowMissingAgeError: false,
    })),
  }));

interface ChildrenPartitionedByAge {
  nonInfantChildren: ChildOccupant[];
  infantChildren: ChildOccupant[];
}

interface NumberOfChildrenPartitionedByAge {
  numberOfNonInfantChildren: number;
  numberOfInfantChildren: number;
}
