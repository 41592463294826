<template>
  <Menu
    v-slot="{ open }"
    as="div"
    class="fixed top-0 z-30 w-full overflow-hidden bg-white shadow-md"
    :style="{ height: `${height}px` }"
    :class="{ 'transition-[height] duration-500': shouldTransitionHeight }"
  >
    <div ref="transitionContainer">
      <div class="px-6 py-4">
        <div class="relative flex">
          <DropdownBookingStageSelectorTitle v-show="!open" />

          <DropdownBookingStageSelectorStageCount class="absolute right-0" />
        </div>

        <MenuItems>
          <template
            v-for="(stage, index) of bookingStageStore.stages"
            :key="stage.type"
          >
            <BookingStageSelectorItemSeparator
              v-if="index > 0"
              :stage="stage"
              :is-horizontal="false"
            />

            <MenuItem :disabled="!stage.canAccess">
              <BookingStageSelectorItem :stage="stage" />
            </MenuItem>
          </template>
        </MenuItems>
      </div>
    </div>
  </Menu>
</template>

<script setup lang="ts">
import { Menu, MenuItems, MenuItem } from '@headlessui/vue';
import { useElementSize } from '@vueuse/core';
import { ref, watch } from 'vue';
import { useBookingStageStore } from '@/booking-stage/booking-stage.store';
import DropdownBookingStageSelectorStageCount from '@/booking-stage/selector/dropdown/stage-count/DropdownBookingStageSelectorStageCount.vue';
import DropdownBookingStageSelectorTitle from '@/booking-stage/selector/dropdown/title/DropdownBookingStageSelectorTitle.vue';
import BookingStageSelectorItem from '@/booking-stage/selector/item/BookingStageSelectorItem.vue';
import BookingStageSelectorItemSeparator from '@/booking-stage/selector/item/separator/BookingStageSelectorItemSeparator.vue';

const bookingStageStore = useBookingStageStore();

const shouldTransitionHeight = ref(false);
const transitionContainer = ref<HTMLDivElement>();

const { height } = useElementSize(transitionContainer);

watch(height, (height) => {
  setTimeout(() => {
    shouldTransitionHeight.value = height > 0;
  });
});
</script>
