import { Decimal } from 'decimal.js';
import type { Ref } from 'vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import type { BookingPolicy } from '@/property/booking-policy/booking-policy';
import type { NoShowPolicyCharge } from '@/property/booking-policy/no-show-policy/charge/no-show-policy-charge';
import { NoShowPolicyChargeType } from '@/property/booking-policy/no-show-policy/charge/no-show-policy-charge';
import type { Property } from '@/property/property';

export const useNoShowPolicyTermsAndConditionsDisplayText = (
  bookingPolicy: Ref<BookingPolicy>,
  property: Ref<Property>,
) => {
  const { t, n } = useI18n();

  const noShowPolicy = computed(() => bookingPolicy.value.noShowPolicy);

  const generateChargeDisplayText = (charge: NoShowPolicyCharge): string => {
    switch (charge.chargeType) {
      case NoShowPolicyChargeType.Full:
        return t(
          'inTheEventOfANoShowOrBookingReductionTheFullCostOfTheBookingIsCharged',
        );
      case NoShowPolicyChargeType.Free:
        return t(
          'inTheEventOfANoShowOrBookingReductionNoChargeWillBeTakenAndAFullRefundWillBeGiven',
        );
      case NoShowPolicyChargeType.Monetary:
        return t(
          'inTheEventOfANoShowOrBookingReductionAChargeEqualToChargeAmountWillBeTakenAndTheRemainingAmountWillBeRefunded',
          {
            chargeAmount: generateMonetaryChargeAmountDisplayText(
              charge.amount,
            ),
          },
        );
      case NoShowPolicyChargeType.Percentage:
        return t(
          'inTheEventOfANoShowOrBookingReductionAChargeEqualToPercentAmountOfTheStayWillBeTakenAndTheRemainingAmountWillBeRefunded',
          {
            percentAmount: n(
              new Decimal(charge.percentage).dividedBy(100).toNumber(),
              'percent',
            ),
          },
        );
      case NoShowPolicyChargeType.PerUnitMonetary:
        return t(
          'inTheEventOfANoShowOrBookingReductionAChargeEqualToChargeAmountPerUnitBookedWillBeTakenAndTheRemainingAmountWillBeRefunded',
          {
            chargeAmount: generateMonetaryChargeAmountDisplayText(
              charge.amountPerUnit,
            ),
          },
        );
      case NoShowPolicyChargeType.Nightly:
        return t(
          'inTheEventOfANoShowOrBookingReductionAChargeEqualToTheFirstNightCountNightsOfTheStayWillBeTakenAndTheRemainingAmountWillBeRefunded',
          { nightCount: charge.numberOfNights },
        );
    }
  };

  const generateMonetaryChargeAmountDisplayText = (
    chargeAmount: number,
  ): string =>
    n(chargeAmount, {
      style: 'currency',
      currency: property.value.currencyCode,
    });

  return computed(() => generateChargeDisplayText(noShowPolicy.value.charge));
};
