<template>
  <div>
    <i18n-t
      keypath="youHaveAlreadySelectedUnitsForPropertyName"
      tag="p"
      scope="global"
      class="mb-2"
    >
      <template #unitTypeName>
        {{ unitTypeNamePlural }}
      </template>

      <template #unitTypeGender>
        {{ unitTypeGender }}
      </template>

      <template #propertyName>
        <span class="font-semibold">
          {{ propertyToSwitchFrom.name }}
        </span>
      </template>
    </i18n-t>

    <i18n-t
      keypath="theseUnitsWillBeDiscardedIfYouSwitchToPropertyName"
      tag="p"
      scope="global"
      :unit-type-gender="unitTypeGender"
    >
      <template #unitTypeName>
        {{ unitTypeNamePlural }}
      </template>

      <template #unitTypeGender>{{ unitTypeGender }}</template>

      <template #propertyName>
        <span class="font-semibold">
          {{ propertyToSwitchTo.name }}
        </span>
      </template>
    </i18n-t>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { Property } from '@/property/property';
import { useUnitType } from '@/property/unit/type/unit-type.composable';

const props = defineProps<{
  propertyToSwitchFrom: Property;
  propertyToSwitchTo: Property;
}>();

const { unitTypeNamePlural, unitTypeGender } = useUnitType(
  computed(() => props.propertyToSwitchFrom.unitType),
);
</script>
