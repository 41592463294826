<template>
  <div>
    <div class="flex flex-col-reverse items-end sm:flex-row sm:items-baseline">
      <div
        v-if="preDiscountRate"
        class="sm:mr-2"
        :class="isMeal ? 'text-sm' : 'text-base'"
      >
        <p
          v-if="!searchStore.isLoading"
          class="whitespace-nowrap text-red-600 line-through"
          :class="
            isMeal ? 'font-medium decoration-1' : 'font-semibold decoration-2'
          "
        >
          {{ getRateDisplayText(preDiscountRate) }}
        </p>

        <AppSkeletonText v-else class="w-10" />
      </div>

      <div :class="isMeal ? 'text-base' : 'text-2xl'">
        <p
          v-if="!searchStore.isLoading"
          :class="{ 'font-semibold leading-none': !isMeal }"
          class="whitespace-nowrap"
        >
          {{ getRateDisplayText(rate) }}
        </p>

        <AppSkeletonText v-else class="w-20" />
      </div>
    </div>

    <div v-if="priceLabel" class="flex justify-end text-xs font-semibold">
      <span v-if="!searchStore.isLoading"> {{ priceLabel }}</span>

      <AppSkeletonText v-else class="w-14" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useSearchStore } from '@/search/search.store';
import AppSkeletonText from '@/ui/app-skeleton-text/AppSkeletonText.vue';

const props = withDefaults(
  defineProps<{
    rate: number;
    preDiscountRate?: number | null;
    isMeal?: boolean;
    priceLabel?: string | null;
  }>(),
  {
    preDiscountRate: null,
    isMeal: false,
    priceLabel: null,
  },
);

const searchStore = useSearchStore();

const { n } = useI18n();

const getRateDisplayText = (rate: number): string =>
  (props.isMeal ? '+' : '') +
  n(rate, {
    style: 'currency',
    currency: searchStore.activeProperty.currencyCode,
  });
</script>
