<template>
  <div class="flex items-center justify-between space-x-4">
    <AppSecondaryButton
      class="w-full p-3"
      :button-text="toTitleCase($t('doNotChange'))"
      @click="$emit('close')"
    />

    <AppPrimaryButton
      class="w-full p-3"
      :button-text="toTitleCase($t('change'))"
      @click="(bookingItineraryStore.unitItinerary = []), $emit('close')"
    />
  </div>
</template>

<script setup lang="ts">
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import { useLocaleString } from '@/string/locale-string.composable';
import AppPrimaryButton from '@/ui/app-primary-button/AppPrimaryButton.vue';
import AppSecondaryButton from '@/ui/app-secondary-button/AppSecondaryButton.vue';

const { toTitleCase } = useLocaleString();

const bookingItineraryStore = useBookingItineraryStore();

defineEmits(['close']);
</script>
