<template>
  <i18n-t
    v-if="
      advancedPaymentPolicy.advancedPaymentType ===
      AdvancedPaymentPolicyType.OnArrival
    "
    keypath="amountDueAtTheTimeOfCheckin"
    tag="div"
    scope="global"
  >
    <template #amount>
      <span class="font-bold">
        {{ amountDueText }}
      </span>
    </template>
  </i18n-t>

  <i18n-t
    v-else-if="
      advancedPaymentPolicy.advancedPaymentType ===
      AdvancedPaymentPolicyType.OnDeparture
    "
    keypath="amountDueAtTheTimeOfCheckout"
    tag="div"
    scope="global"
  >
    <template #amount>
      <span class="font-bold">
        {{ amountDueText }}
      </span>
    </template>
  </i18n-t>

  <i18n-t
    v-else-if="
      advancedPaymentPolicy.advancedPaymentType ===
      AdvancedPaymentPolicyType.BeforeArrival
    "
    keypath="amountDueNumberOfDaysBeforeArrival"
    tag="div"
    scope="global"
  >
    <template #amount>
      <span class="font-bold">
        {{ amountDueText }}
      </span>
    </template>

    <template #numberOfDays>
      <span class="font-bold">
        {{ $t('countDays', advancedPaymentPolicy.numberOfDaysBeforeArrival) }}
      </span>
    </template>
  </i18n-t>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useBookingConfirmationStore } from '@/booking-confirmation-page/booking-confirmation.store';
import type { AdvancedPaymentPolicy } from '@/property/booking-policy/advanced-payment-policy/advanced-payment-policy';
import { AdvancedPaymentPolicyType } from '@/property/booking-policy/advanced-payment-policy/advanced-payment-policy';

const { n } = useI18n();

const { property } = storeToRefs(useBookingConfirmationStore());

const props = defineProps<{
  amountDue: number;
  advancedPaymentPolicy: AdvancedPaymentPolicy;
}>();

const amountDueText = computed(() =>
  n(props.amountDue, {
    style: 'currency',
    currency: property.value.currencyCode,
  }),
);
</script>
