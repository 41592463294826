import type { Ref } from 'vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import type { PetsAllowedPolicy } from '@/property/pets-policy/allowed/pets-allowed-policy';
import { PetsPolicyChargeType } from '@/property/pets-policy/charge/pets-policy-charge';
import { PetsPolicyType } from '@/property/pets-policy/pets-policy';
import type { Property } from '@/property/property';

export const usePetsPolicyTermsAndConditionsDisplayText = (
  property: Ref<Property>,
) => {
  const { t, n } = useI18n();

  const petsPolicy = computed(() => property.value.petsPolicy);

  const generatePetsAllowedDisplayText = ({
    charge,
  }: PetsAllowedPolicy): string => {
    switch (charge.chargeType) {
      case PetsPolicyChargeType.Free:
        return t('petsAreAllowedAndCanStayFreeOfCharge');
      case PetsPolicyChargeType.PerDayMonetary:
        return t('petsAreAllowedAtAChargeOfChargePerDay', {
          charge: generateChargeAmountDisplayText(charge.amountPerDay),
        });
      case PetsPolicyChargeType.PerStayMonetary:
        return t('petsAreAllowedAtAChargeOfChargePerStay', {
          charge: generateChargeAmountDisplayText(charge.amountPerStay),
        });
    }
  };

  const generateChargeAmountDisplayText = (chargeAmount: number): string =>
    n(chargeAmount, {
      style: 'currency',
      currency: property.value.currencyCode,
    });

  return computed(() =>
    petsPolicy.value.policyType === PetsPolicyType.Allowed
      ? generatePetsAllowedDisplayText(petsPolicy.value)
      : t('petsAreNotAllowed'),
  );
};
