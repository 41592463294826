import type {
  GoogleAdwords,
  GoogleAdwordsDTO,
} from '@/property/google/adwords/google-adwords';

export const mapGoogleAdwordsFromDTO = (
  googleAdwordsDTO: GoogleAdwordsDTO,
): GoogleAdwords => ({
  id: googleAdwordsDTO.conversionId,
  label: googleAdwordsDTO.conversionLabel,
});
