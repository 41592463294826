<template>
  <footer class="bg-zinc-100 p-4 print:hidden">
    <ContainerLayout>
      <div class="flex items-center justify-between gap-2">
        <div class="flex flex-wrap gap-2">
          <TheFooterPrivacyPolicy :is-condensed="isCondensed" />

          <template v-if="!!property?.extendedTermsAndConditions">
            <div>|</div>

            <TheFooterTermsAndConditions
              :extended-terms-and-conditions="
                property.extendedTermsAndConditions
              "
              :is-condensed="isCondensed"
            />
          </template>
        </div>

        <div class="flex">
          <TheFooterLocaleSelector :is-condensed="isCondensed" />
        </div>
      </div>
    </ContainerLayout>
  </footer>
</template>

<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import TheFooterLocaleSelector from '@/footer/TheFooterLocaleSelector.vue';
import TheFooterPrivacyPolicy from '@/footer/TheFooterPrivacyPolicy.vue';
import TheFooterTermsAndConditions from '@/footer/TheFooterTermsAndConditions.vue';
import ContainerLayout from '@/layouts/ContainerLayout.vue';
import type { Property } from '@/property/property';

defineProps<{ property: Property | undefined }>();

const isCondensed = useBreakpoints(breakpointsTailwind).smaller('lg');
</script>
