<template>
  <AppLinkButton
    :text="isCondensed ? toTitleCase($t('privacy')) : privacyPolicyText"
    :label="privacyPolicyText"
    variant="basic"
    @click="isPrivacyPolicyModalOpen = true"
  />

  <PrivacyPolicyModal
    :is-open="isPrivacyPolicyModalOpen"
    @close="isPrivacyPolicyModalOpen = false"
  />
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import PrivacyPolicyModal from '@/privacy-policy/PrivacyPolicyModal.vue';
import { useLocaleString } from '@/string/locale-string.composable';
import AppLinkButton from '@/ui/app-link-button/AppLinkButton.vue';

defineProps<{ isCondensed: boolean }>();

const { t } = useI18n();
const { toTitleCase } = useLocaleString();

const isPrivacyPolicyModalOpen = ref(false);

const privacyPolicyText = computed(() => toTitleCase(t('privacyPolicy')));
</script>
