import type {
  PrivateRateOverride,
  PrivateRateOverrideDTO,
} from '@/private-rate-override/private-rate-override';
import { mapPrivateRateOverrideUnitFromDTO } from '@/private-rate-override/unit/private-rate-override-unit.mapper';
import { mapPrivateRateOverrideWayToSellFromDTO } from '@/private-rate-override/way-to-sell/private-rate-override-way-to-sell.mapper';

export const mapPrivateRateOverrideFromDTO = (
  privateRateOverrideDTO: PrivateRateOverrideDTO,
): PrivateRateOverride => ({
  id: privateRateOverrideDTO.id,
  dateBounds: privateRateOverrideDTO.dateBounds
    ? {
        from: privateRateOverrideDTO.dateBounds.from,
        to: privateRateOverrideDTO.dateBounds.to,
      }
    : undefined,
  stayLengthBounds: {
    minimum: privateRateOverrideDTO.stayLengthBounds.minimum,
    maximum: privateRateOverrideDTO.stayLengthBounds.maximum,
  },
  activationType: privateRateOverrideDTO.activationType,
  daysOfWeek: privateRateOverrideDTO.daysOfWeek,
  units: privateRateOverrideDTO.units.map(mapPrivateRateOverrideUnitFromDTO),
  waysToSell: privateRateOverrideDTO.waysToSell.map(
    mapPrivateRateOverrideWayToSellFromDTO,
  ),
  includedMealTypes: privateRateOverrideDTO.includedMealTypes,
});
