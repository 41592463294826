<template>
  <UseElementBounding v-slot="{ top }">
    <div
      class="flex"
      :style="{
        maxHeight: `calc(var(--app-height) - ${top}px)`,
      }"
    >
      <div
        class="mb-2 flex w-full flex-col items-center rounded border border-zinc-300"
      >
        <BookingSummaryHeader />
        <BookingSummaryBody />
        <InlineBookingSummaryFooter
          v-if="!bookingItineraryStore.hasEmptyUnitItinerary"
        />
      </div>
    </div>
  </UseElementBounding>
</template>

<script setup lang="ts">
import { UseElementBounding } from '@vueuse/components';
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import BookingSummaryBody from '@/booking-summary/body/BookingSummaryBody.vue';
import InlineBookingSummaryFooter from '@/booking-summary/footer/InlineBookingSummaryFooter.vue';
import BookingSummaryHeader from '@/booking-summary/header/BookingSummaryHeader.vue';

const bookingItineraryStore = useBookingItineraryStore();
</script>
