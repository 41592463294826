<template>
  <div class="text-xs">
    <AppPriceAdjustmentTag
      v-if="!searchStore.isLoading"
      :tag-subtitle-text="discountTagDisplayText"
      :tag-title-text="toTitleCase($t('specialOffer'))"
      :icon="IconDiscount2"
    />
    <AppSkeletonText v-else class="w-48" />
  </div>
</template>
<script setup lang="ts">
import { IconDiscount2 } from '@tabler/icons-vue';
import { computed } from 'vue';
import type { DiscountedPricePlanVariant } from '@/price-plan/price-plan';
import { useDiscountOffer } from '@/property/offer/discount-offer/discount-offer.composable';
import { useFreeNightOffer } from '@/property/offer/free-night-offer/free-night-offer.composable';
import { OfferType } from '@/property/offer/offer';
import { useSearchStore } from '@/search/search.store';
import { useLocaleString } from '@/string/locale-string.composable';
import AppPriceAdjustmentTag from '@/ui/app-price-adjustment-tag/AppPriceAdjustmentTag.vue';
import AppSkeletonText from '@/ui/app-skeleton-text/AppSkeletonText.vue';

const props = defineProps<{
  pricePlan: DiscountedPricePlanVariant;
}>();

const searchStore = useSearchStore();

const { toTitleCase } = useLocaleString();

const discountTagDisplayText = computed(() => {
  if (props.pricePlan.offer.offerType === OfferType.Discount) {
    const { generateDiscountOfferSummary } = useDiscountOffer(
      props.pricePlan.offer,
    );

    return generateDiscountOfferSummary(
      props.pricePlan.unit,
      props.pricePlan.mealPricePlans.map((mealPricePlan) => mealPricePlan.meal),
      searchStore.activeProperty,
    );
  }

  const { generateFreeNightOfferSummary } = useFreeNightOffer(
    props.pricePlan.offer,
  );

  return generateFreeNightOfferSummary(searchStore.stayLength);
});
</script>
