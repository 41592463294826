<template>
  <div class="grid gap-3" data-test="ExtrasItem">
    <div class="break-anywhere">
      <ExtrasItemName :extra="extra" />

      <ExtrasItemDescription :extra="extra" />
    </div>

    <ExtrasItemNumberOfNightsSelector
      v-if="
        extra.chargeType === ExtraChargeType.SelectNightsOfStay &&
        stayLength > MINIMUM_NUMBER_NIGHTS
      "
      :extra="{
        ...extra,
        chargeType: extra.chargeType,
      }"
    />

    <ExtrasItemQuestion :extra="extra" />

    <div class="flex flex-wrap items-center">
      <div class="flex flex-[2] items-center justify-between">
        <div>
          <ExtrasItemCharge
            v-if="
              !(
                props.extra.isMandatory &&
                props.extra.chargeType === ExtraChargeType.Single
              )
            "
            :extra="extra"
          />
        </div>

        <ExtrasItemRunningTotalRate class="px-2 font-semibold" :extra="extra" />
      </div>

      <ExtrasItemQuantitySelectorSpinner
        v-if="!extra.isMandatory"
        :extra="extra"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import ExtrasItemCharge from '@/extras-page/item/charge/ExtrasItemCharge.vue';
import ExtrasItemDescription from '@/extras-page/item/description/ExtrasItemDescription.vue';
import ExtrasItemName from '@/extras-page/item/name/ExtrasItemName.vue';
import ExtrasItemNumberOfNightsSelector from '@/extras-page/item/number-of-nights-selector/ExtrasItemNumberOfNightsSelector.vue';
import ExtrasItemQuantitySelectorSpinner from '@/extras-page/item/quantity-selector-spinner/ExtrasItemQuantitySelectorSpinner.vue';
import ExtrasItemQuestion from '@/extras-page/item/question/ExtrasItemQuestion.vue';
import ExtrasItemRunningTotalRate from '@/extras-page/item/running-total-rate/ExtrasItemRunningTotalRate.vue';
import { ExtraChargeType } from '@/property/extra/extra';
import type { Extra } from '@/property/extra/extra';
import { getStayLengthFromStayDates } from '@/stay-dates/stay-dates.utilities';

const MINIMUM_NUMBER_NIGHTS = 1;

const props = defineProps<{
  extra: Extra;
}>();

const bookingItineraryStore = useBookingItineraryStore();

const stayLength = computed(() =>
  getStayLengthFromStayDates(bookingItineraryStore.stayDates!),
);
</script>
