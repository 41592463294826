/**
 * Compares values A and B by their respective position in the corresponding array of values.
 *
 * A positive number will be returned if value A comes after value B in the array, or if
 * value A does not exist within the array.
 *
 * A negative number will be returned if value A comes before value B in the array, or if
 * value B does not exist with the array.
 *
 * Zero will be returned if value A and B are equal.
 */
export const compareValuesByArrayPosition = <T>(
  valueA: T,
  valueB: T,
  values: T[],
): number => {
  const positionOfValueA = values.indexOf(valueA);
  const positionOfValueB = values.indexOf(valueB);

  if (positionOfValueA === -1 && positionOfValueB === -1) {
    return 0;
  }

  if (positionOfValueA === -1) {
    return 1;
  }

  if (positionOfValueB === -1) {
    return -1;
  }

  return positionOfValueA - positionOfValueB;
};

export const arrayContainSingleItem = <T>(array: T[]): array is [T] =>
  array.length === 1;
