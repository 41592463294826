<template>
  <div class="flex whitespace-nowrap">
    {{ $d(createDateObject(fromDate), dateFormatOptions) }}
    <IconArrowNarrowRight stroke-width="1" :size="24" class="mx-1" />
    {{ $d(createDateObject(toDate), dateFormatOptions) }}
  </div>
</template>
<script setup lang="ts">
import { IconArrowNarrowRight } from '@tabler/icons-vue';
import { createDateObject } from '@/date/date.utilities';

defineProps<{
  fromDate: string;
  toDate: string;
}>();

const dateFormatOptions: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
};
</script>
