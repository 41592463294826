import { isToday } from 'date-fns';
import type { Ref } from 'vue';
import { ref, computed, reactive } from 'vue';
import { createDateObject } from '@/date/date.utilities';
import type { BookingPolicy } from '@/property/booking-policy/booking-policy';
import { useCancellationPolicyOverStay } from '@/property/booking-policy/cancellation-policy/over-stay/cancellation-policy-over-stay.composable';
import { DepositPolicyType } from '@/property/booking-policy/deposit-policy/deposit-policy';
import { useMandatoryDepositPolicyOverStay } from '@/property/booking-policy/deposit-policy/mandatory/over-stay/mandatory-deposit-policy-over-stay.composable';
import type {
  BookingPolicyOverStay,
  FreeCancellationStatus,
} from '@/property/booking-policy/over-stay/booking-policy-over-stay';
import type { Property } from '@/property/property';
import type { StayDates } from '@/stay-dates/stay-dates';

export const useBookingPolicyOverStay = (
  property: Ref<Property>,
  bookingPolicy: Ref<BookingPolicy>,
  stayDates: Ref<StayDates>,
): BookingPolicyOverStay => {
  const cancellationPolicyOverStay = useCancellationPolicyOverStay(
    property,
    bookingPolicy,
    stayDates,
  );

  const depositPolicyOverStay = computed(() =>
    bookingPolicy.value.depositPolicy.policyType === DepositPolicyType.Mandatory
      ? useMandatoryDepositPolicyOverStay(
          property,
          ref(bookingPolicy.value.depositPolicy),
          ref(cancellationPolicyOverStay),
          stayDates,
        )
      : undefined,
  );

  const depositRefundPolicyOverStay = computed(
    () => depositPolicyOverStay.value?.depositRefundPolicyOverStay,
  );

  /**
   * Determining whether a booking should be considered non-refundable is
   * based on the both the cancellation policy and the deposit policy.
   *
   * Note this is to decide if the booking *as a whole* is "non-refundable",
   * not whether an individual element like the deposit is non-refundable.
   */
  const isNonRefundable = computed(() => {
    const depositPolicyRendersBookingNonRefundable =
      !!depositPolicyOverStay.value &&
      depositPolicyOverStay.value.rendersBookingNonRefundable;

    return (
      cancellationPolicyOverStay.rendersBookingNonRefundable ||
      depositPolicyRendersBookingNonRefundable
    );
  });

  /**
   * If there is no deposit refund policy given, then the free cancellation cut-off date is
   * simply the cut-off date of the given cancellation policy.
   *
   * Otherwise, it is the earlier cut-off date of either the deposit refund policy or the
   * cancellation policy.
   */
  const freeCancellationCutOffDate = computed(() => {
    const cancellationPolicyCutOffDate = cancellationPolicyOverStay.cutOffDate;

    if (!depositRefundPolicyOverStay.value) {
      return cancellationPolicyCutOffDate;
    }

    const depositRefundPolicyCutOffDate =
      depositRefundPolicyOverStay.value.cutOffDate;

    return depositRefundPolicyCutOffDate < cancellationPolicyCutOffDate
      ? depositRefundPolicyCutOffDate
      : cancellationPolicyCutOffDate;
  });

  /**
   * Determining whether free cancellation should apply to a given stay is based on
   * both the cancellation policy AND the deposit policy.
   *
   * The cancellation policy must be "free" for free cancellation to apply, but the
   * deposit policy can override this by either making the cut-off date for free
   * cancellation more strict, or removing free cancellation altogether.
   */
  const freeCancellationStatus = computed<FreeCancellationStatus>(() => {
    if (!cancellationPolicyOverStay.isFree) {
      // If the cancellation policy isn't free, then it definitely can't be free cancellation
      return {
        isFreeCancellation: false,
      };
    }

    if (
      depositPolicyOverStay.value &&
      !depositRefundPolicyOverStay.value?.isApplicable
    ) {
      // If there is a deposit, but no deposit refund policy applicable for the stay, it can't be free cancellation
      return {
        isFreeCancellation: false,
      };
    }

    if (isToday(createDateObject(freeCancellationCutOffDate.value))) {
      // We want to ignore free cancellation when the cut-off date is "today".
      return {
        isFreeCancellation: false,
      };
    }

    return {
      isFreeCancellation: true,
      freeCancellationCutOffDate: freeCancellationCutOffDate.value,
    };
  });

  return reactive({
    bookingPolicy,
    isNonRefundable,
    freeCancellationStatus,
  });
};
