import { mapBookingPolicyFromDTO } from '@/property/booking-policy/booking-policy.mapper';
import type {
  StandardBookingPolicy,
  StandardBookingPolicyDTO,
} from '@/property/booking-policy/standard-booking-policy/standard-booking-policy';

export const mapStandardBookingPolicyFromDTO = (
  standardBookingPolicyDTO: StandardBookingPolicyDTO,
): StandardBookingPolicy => ({
  minimumNumberOfDaysBeforeArrivalThreshold:
    standardBookingPolicyDTO.minimumNumberOfDaysBeforeArrivalThreshold,
  maximumNumberOfDaysBeforeArrivalThreshold:
    standardBookingPolicyDTO.maximumNumberOfDaysBeforeArrivalThreshold ??
    undefined,
  bookingPolicy: mapBookingPolicyFromDTO(
    standardBookingPolicyDTO.bookingPolicy,
  ),
});
