import type { DateRangedBookingPolicy } from '@/property/booking-policy/date-ranged-booking-policy/date-ranged-booking-policy';
import { dateRangedBookingPolicyDateRangeCoversDate } from '@/property/booking-policy/date-ranged-booking-policy/date-ranged-booking-policy-date-range/date-ranged-booking-policy-date-range.utilities';
import type { StayDates } from '@/stay-dates/stay-dates';

/**
 * Returns true if any single date range within the policy "covers" the
 * given arrival date, false otherwise.
 */
export const isDateRangedBookingPolicyApplicableForStayDates = (
  { dateRanges }: DateRangedBookingPolicy,
  { checkInDate }: StayDates,
): boolean =>
  dateRanges.some((dateRange) =>
    dateRangedBookingPolicyDateRangeCoversDate(dateRange, checkInDate),
  );
