<template>
  <div class="px-6 py-4">
    <AppInputBox
      v-model.trim="code"
      :icon="IconTag"
      :should-auto-focus="shouldAutoFocusInput"
      :placeholder-text="$t('enterPromocodeHere')"
      :success-state="inputBoxSuccessState"
      @enter="isCodeValid ? $emit('close') : applyCode()"
    />

    <CodeInputFeedback />
  </div>
</template>

<script setup lang="ts">
import { IconTag } from '@tabler/icons-vue';
import { computed } from 'vue';
import { CodeInputStatusType } from '@/code/input/code-input-status';
import { useCodeInputStore } from '@/code/input/code-input.store';
import CodeInputFeedback from '@/code/input/feedback/CodeInputFeedback.vue';
import AppInputBox from '@/ui/app-input-box/AppInputBox.vue';

defineProps<{ shouldAutoFocusInput: boolean }>();

defineEmits(['close']);

const { code, status, isCodeValid, applyCode } = useCodeInputStore();

const inputBoxSuccessState = computed(() => {
  switch (status.value.type) {
    case CodeInputStatusType.Valid:
      return 'valid';
    case CodeInputStatusType.Pending:
      return 'pending';
    case CodeInputStatusType.Normal:
      return 'normal';
    default:
      return 'error';
  }
});
</script>
