import { mapOccupancyLimitsFromDTO } from '@/property/occupancy-limits/occupancy-limits.mapper';
import { mapWayToSellAdjustmentFromDTO } from '@/property/way-to-sell/adjustment/way-to-sell-adjustment.mapper';
import type {
  WayToSell,
  WayToSellDTO,
} from '@/property/way-to-sell/way-to-sell';

export const mapWayToSellFromDTO = (wayToSellDTO: WayToSellDTO): WayToSell => ({
  id: wayToSellDTO.id,
  name: wayToSellDTO.name,
  occupancyLimits: mapOccupancyLimitsFromDTO(wayToSellDTO.occupancyLimits),
  adjustment: mapWayToSellAdjustmentFromDTO(wayToSellDTO.adjustment),
});
