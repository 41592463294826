<template>
  <Listbox
    v-slot="{ open }"
    :model-value="value ?? null"
    v-bind="{ ...(compareBy && { by: compareBy }) }"
    @update:model-value="(modelValue) => (value = modelValue ?? undefined)"
  >
    <slot :is-open="open"></slot>
  </Listbox>
</template>

<script setup lang="ts">
import { Listbox } from '@headlessui/vue';

defineProps<{ compareBy?: <T>(a: T, b: T) => boolean }>();

const value = defineModel<string | number | object | undefined>({
  required: true,
});
</script>
