import type {
  AdditionalTaxRateBookingBasis,
  AdditionalTaxRateBookingBasisDTO,
} from '@/tax/additional/rate/basis/booking/additional-tax-rate-booking-basis';
import type {
  AdditionalTaxRateGuestBasis,
  AdditionalTaxRateGuestBasisDTO,
} from '@/tax/additional/rate/basis/guest/additional-tax-rate-guest-basis';
import type {
  AdditionalTaxRateUnitBasis,
  AdditionalTaxRateUnitBasisDTO,
} from '@/tax/additional/rate/basis/unit/additional-tax-rate-unit-basis';

export enum AdditionalTaxRateBasisType {
  Unit = 'unit',
  Guest = 'guest',
  Booking = 'booking',
}

export type AdditionalTaxRateBasis =
  | AdditionalTaxRateBookingBasis
  | AdditionalTaxRateGuestBasis
  | AdditionalTaxRateUnitBasis;

export type AdditionalTaxRateBasisDTO =
  | AdditionalTaxRateBookingBasisDTO
  | AdditionalTaxRateGuestBasisDTO
  | AdditionalTaxRateUnitBasisDTO;
