<template>
  <div
    class="flex flex-col justify-center text-left md:mr-4 md:text-right"
    :class="{
      'text-red-700': showCalendarStatusError,
      'animate-shake': animateCalendarStatusError,
    }"
    @animationend="animateCalendarStatusError = false"
  >
    <div class="font-semibold capitalize">{{ calendarStatusHeading }}</div>
    <div class="font-base text-sm">{{ calendarStatusSubHeading }}</div>
  </div>
</template>
<script setup>
import { useAvailabilityCalendarStore } from '@/availability-calendar/availability-calendar.store';

const {
  calendarStatusHeading,
  calendarStatusSubHeading,
  showCalendarStatusError,
  animateCalendarStatusError,
} = useAvailabilityCalendarStore();
</script>
