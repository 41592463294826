import type {
  PackageOfferNightlyUnitRate,
  PackageOfferNightlyUnitRateDTO,
} from '@/property/offer/package-offer/package-offer-nightly-rate/package-offer-nightly-unit-rate/package-offer-nightly-unit-rate';

export const mapPackageOfferNightlyUnitRateFromDTO = (
  packageOfferNightlyUnitRateDTO: PackageOfferNightlyUnitRateDTO,
): PackageOfferNightlyUnitRate => ({
  rate: Number(packageOfferNightlyUnitRateDTO.rate),
  unitId: packageOfferNightlyUnitRateDTO.unitId,
});
