import { subtractDays } from '@/date/date.utilities';
import type { BeforeArrivalDepositRefundPolicy } from '@/property/booking-policy/deposit-policy/refund-policy/before-arrival/before-arrival-deposit-refund-policy';
import type { StayDates } from '@/stay-dates/stay-dates';
import { getNumberOfDaysBetweenNowAndArrival } from '@/stay-dates/stay-dates.utilities';

export const isBeforeArrivalDepositRefundPolicyApplicableForStayDates = (
  beforeArrivalDepositRefundPolicy: BeforeArrivalDepositRefundPolicy,
  stayDates: StayDates,
  timezone: string,
): boolean => {
  const numberOfDaysBeforeArrival = getNumberOfDaysBetweenNowAndArrival(
    stayDates,
    timezone,
  );

  return isNumberOfDaysBeforeArrivalInsideBeforeArrivalDepositRefundPolicyMinimumThreshold(
    beforeArrivalDepositRefundPolicy,
    numberOfDaysBeforeArrival,
  );
};

export const getBeforeArrivalDepositRefundPolicyCutOffDateForStayDates = (
  {
    minimumNumberOfDaysBeforeArrivalThreshold,
  }: BeforeArrivalDepositRefundPolicy,
  { checkInDate }: StayDates,
): string =>
  subtractDays(checkInDate, minimumNumberOfDaysBeforeArrivalThreshold);

const isNumberOfDaysBeforeArrivalInsideBeforeArrivalDepositRefundPolicyMinimumThreshold =
  (
    {
      minimumNumberOfDaysBeforeArrivalThreshold,
    }: BeforeArrivalDepositRefundPolicy,
    numberOfDaysBeforeArrival: number,
  ): boolean =>
    numberOfDaysBeforeArrival >= minimumNumberOfDaysBeforeArrivalThreshold;
