import type { RecaptchaAction } from '@/recaptcha/recaptcha';

/**
 * Asynchronously generates a reCAPTCHA token for the current user, labelled by
 * the given 'action'.
 *
 * For more on actions see https://developers.google.com/recaptcha/docs/v3#actions.
 */
export const generateRecaptchaToken = async (
  action: RecaptchaAction,
): Promise<string> =>
  /**
   * Manual promise resolution allows us to correctly await the generation of
   * the token.
   *
   * See https://stackoverflow.com/questions/62015796/how-to-wait-for-the-recaptcha-v3-token-synchronously.
   */
  new Promise((resolve) => {
    grecaptcha.ready(
      () =>
        void (async () => {
          const token = await grecaptcha.execute(
            import.meta.env.VITE_RECAPTCHA_SITE_KEY,
            { action },
          );

          resolve(token);
        })(),
    );
  });
