<template>
  <AppFormValidatedInput
    v-slot="{ isValid }"
    :value="guestStore.guest.forename"
    :validation-rules="[
      isRequiredRule($t('firstNameIsRequired')),
      isLessThanOrEqualToLengthRule(
        FIRST_NAME_MAX_LENGTH,
        $t('inputNameMustNotExceedMaxLengthCharacters', {
          inputName: capitalize($t('firstName')),
          maxLength: FIRST_NAME_MAX_LENGTH,
        }),
      ),
    ]"
  >
    <AppFloatingLabelInput
      v-model="guestStore.guest.forename"
      type="text"
      :label="capitalize($t('firstName'))"
      label-id="forename"
      autocomplete-type="given-name"
      :is-valid="isValid"
      :max-length="FIRST_NAME_MAX_LENGTH"
      rounded-border-sides="top"
    />
  </AppFormValidatedInput>
</template>

<script setup lang="ts">
import { FIRST_NAME_MAX_LENGTH } from '@/booking/booking';
import { useGuestStore } from '@/guest/guest.store';
import { useLocaleString } from '@/string/locale-string.composable';
import AppFloatingLabelInput from '@/ui/app-floating-label-input/AppFloatingLabelInput.vue';
import {
  isRequiredRule,
  isLessThanOrEqualToLengthRule,
} from '@/ui/app-form/app-form-input-validation-rules.utilities';
import AppFormValidatedInput from '@/ui/app-form/AppFormValidatedInput.vue';

const guestStore = useGuestStore();

const { capitalize } = useLocaleString();
</script>
