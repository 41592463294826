<template>
  <UnitSelectionAdditionalPropertiesItemButton
    :button-text="toTitleCase($t('switchProperty'))"
    @click="onSwitchPropertyClick"
  />

  <UnitSelectionAdditionalPropertiesSwitchPropertyNonEmptyItineraryWarningModal
    :is-open="isPropertySwitchWarningModalOpen"
    :property-to-switch-from="searchStore.activeProperty"
    :property-to-switch-to="property"
    @close="isPropertySwitchWarningModalOpen = false"
    @switch="onPropertySwitchWarningSwitch"
  />
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import type { Property } from '@/property/property';
import { useSearchStore } from '@/search/search.store';
import { useLocaleString } from '@/string/locale-string.composable';
import UnitSelectionAdditionalPropertiesItemButton from '@/unit-selection-page/additional-properties/item/button/UnitSelectionAdditionalPropertiesItemButton.vue';
import UnitSelectionAdditionalPropertiesSwitchPropertyNonEmptyItineraryWarningModal from '@/unit-selection-page/additional-properties/switch-property-non-empty-itinerary-warning-modal/UnitSelectionAdditionalPropertiesSwitchPropertyNonEmptyItineraryWarningModal.vue';
import { useUnitSelectionStore } from '@/unit-selection-page/unit-selection.store';

const props = defineProps<{ property: Property }>();

const searchStore = useSearchStore();
const unitSelectionStore = useUnitSelectionStore();
const bookingItineraryStore = useBookingItineraryStore();

const { toTitleCase } = useLocaleString();

const isPropertySwitchWarningModalOpen = ref(false);

const onSwitchPropertyClick = () => {
  if (bookingItineraryStore.hasEmptyUnitItinerary) {
    switchSearchProperty();
  } else {
    isPropertySwitchWarningModalOpen.value = true;
  }
};

const onPropertySwitchWarningSwitch = () => {
  isPropertySwitchWarningModalOpen.value = false;

  switchSearchProperty();
};

const switchSearchProperty = () => {
  void unitSelectionStore.updateProperty(props.property);
};
</script>
