<template>
  <DialogPanel
    class="flex max-h-[90%] w-full flex-col overflow-hidden rounded-t-xl bg-white shadow-xl sm:max-h-full sm:rounded-xl"
  >
    <header
      v-if="!!$slots['header']"
      class="flex items-center justify-between border-b border-neutral-200 px-6 py-4"
    >
      <DialogTitle class="mr-4">
        <slot name="header"></slot>
      </DialogTitle>

      <AppCloseButton v-if="shouldShowCloseButton" @close="$emit('close')" />
    </header>

    <div
      ref="body"
      class="overflow-y-auto"
      :tabindex="isBodyOverflowing ? 0 : -1"
      :class="{ 'px-6 py-4': addBodyPadding }"
      role="region"
    >
      <slot></slot>
    </div>

    <footer v-if="!!$slots['footer']">
      <div class="border-t border-neutral-200 px-6 py-4">
        <slot name="footer"></slot>
      </div>
    </footer>
  </DialogPanel>
</template>

<script setup lang="ts">
import { DialogPanel, DialogTitle } from '@headlessui/vue';
import { useResizeObserver } from '@vueuse/core';
import { ref } from 'vue';
import AppCloseButton from '@/ui/app-close-button/AppCloseButton.vue';

withDefaults(
  defineProps<{
    addBodyPadding?: boolean;
    shouldShowCloseButton?: boolean;
  }>(),
  {
    addBodyPadding: true,
    shouldShowCloseButton: true,
  },
);

defineEmits(['close']);

const body = ref<HTMLDivElement | null>(null);

const isBodyOverflowing = ref(false);

useResizeObserver(body, ([entry]) => {
  if (!entry) {
    return;
  }

  const { scrollHeight, clientHeight } = entry.target;

  isBodyOverflowing.value = scrollHeight > clientHeight;
});
</script>
