import type {
  NonePaymentGateway,
  NonePaymentGatewayDTO,
} from '@/property/payment-gateway/none/none-payment-gateway';
import type {
  PcibPaymentGateway,
  PcibPaymentGatewayDTO,
} from '@/property/payment-gateway/pcib/pcib-payment-gateway';
import type {
  SagePayPaymentGateway,
  SagePayPaymentGatewayDTO,
} from '@/property/payment-gateway/sage-pay/sage-pay-payment-gateway';
import type {
  StripePaymentGateway,
  StripePaymentGatewayDTO,
} from '@/property/payment-gateway/stripe/stripe-payment-gateway';

export enum PaymentGatewayType {
  None = 'none',
  Stripe = 'stripe',
  SagePay = 'sage_pay',
  Pcib = 'pcib',
}

export type PaymentGateway =
  | NonePaymentGateway
  | StripePaymentGateway
  | SagePayPaymentGateway
  | PcibPaymentGateway;

export type PaymentGatewayDTO =
  | NonePaymentGatewayDTO
  | StripePaymentGatewayDTO
  | SagePayPaymentGatewayDTO
  | PcibPaymentGatewayDTO;
