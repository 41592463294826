import type { Address } from '@/address/address';

export const makeDefaultAddress = (): Address => ({
  line1: '',
  line2: '',
  line3: '',
  postcode: '',
  city: '',
  administrativeArea: '',
  countryCode: '',
});
