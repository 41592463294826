import type {
  FreeCancellationPolicy,
  FreeCancellationPolicyDTO,
} from '@/property/booking-policy/cancellation-policy/free/free-cancellation-policy';
import type {
  MonetaryCancellationPolicy,
  MonetaryCancellationPolicyDTO,
} from '@/property/booking-policy/cancellation-policy/monetary/monetary-cancellation-policy';
import type {
  NightlyCancellationPolicy,
  NightlyCancellationPolicyDTO,
} from '@/property/booking-policy/cancellation-policy/nightly/nightly-cancellation-policy';
import type {
  PerUnitMonetaryCancellationPolicy,
  PerUnitMonetaryCancellationPolicyDTO,
} from '@/property/booking-policy/cancellation-policy/per-unit/per-unit-monetary-cancellation-policy';
import type {
  PercentageCancellationPolicy,
  PercentageCancellationPolicyDTO,
} from '@/property/booking-policy/cancellation-policy/percentage/percentage-cancellation-policy';

export enum CancellationPolicyType {
  Free = 'free',
  Nightly = 'nightly',
  Percentage = 'percentage',
  Monetary = 'monetary',
  PerUnitMonetary = 'per_unit_monetary',
}

export type CancellationPolicy =
  | FreeCancellationPolicy
  | MonetaryCancellationPolicy
  | NightlyCancellationPolicy
  | PercentageCancellationPolicy
  | PerUnitMonetaryCancellationPolicy;

export type NonFreeCancellationPolicy = Exclude<
  CancellationPolicy,
  FreeCancellationPolicy
>;

export interface BaseCancellationPolicy {
  minimumNumberOfDaysBeforeArrivalThreshold: number;
  maximumNumberOfDaysBeforeArrivalThreshold: number | undefined;
}

export type CancellationPolicyDTO =
  | FreeCancellationPolicyDTO
  | MonetaryCancellationPolicyDTO
  | NightlyCancellationPolicyDTO
  | PercentageCancellationPolicyDTO
  | PerUnitMonetaryCancellationPolicyDTO;
