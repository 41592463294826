import type { Time } from '@/time/time';

export const getTimesInTimeInterval = (
  startTime: Time,
  endTime: Time,
  gapInMinutes: number,
) => {
  const times: Time[] = [];

  let endHours = endTime.hours;
  const endMinutes = endTime.minutes;

  let startHours = startTime.hours;
  let startMinutes = startTime.minutes;

  if (startHours > endHours) {
    endHours += 24;
  }

  while (
    startHours < endHours ||
    (startHours === endHours && startMinutes <= endMinutes)
  ) {
    times.push({
      hours: startHours % 24,
      minutes: startMinutes,
    });

    startMinutes += gapInMinutes;

    if (startMinutes >= 60) {
      startHours += 1;
      startMinutes -= 60;
    }
  }

  return times;
};

export const compareTimes = (timeA: Time, timeB: Time): number => {
  if (
    timeA.hours < timeB.hours ||
    (timeA.hours === timeB.hours && timeA.minutes < timeB.minutes)
  ) {
    return -1;
  }

  if (
    timeA.hours > timeB.hours ||
    (timeA.hours === timeB.hours && timeA.minutes > timeB.minutes)
  ) {
    return 1;
  }

  return 0;
};

/**
 * Rounds the given time down to the previous half hour. This does not round if
 * the time already sits on a half hour period. For example:
 * - 12:00 -> 12:00
 * - 12:01 -> 12:00
 * - 12:29 -> 12:00
 * - 12:30 -> 12:30
 * - 12:31 -> 12:30
 * - 12:59 -> 12:30
 */
export const roundTimeToPreviousHalfHour = ({ hours, minutes }: Time): Time => {
  if (minutes > 0 && minutes < 30) {
    return { hours, minutes: 0 };
  }

  if (minutes > 30) {
    return { hours, minutes: 30 };
  }

  return { hours, minutes };
};

/**
 * Rounds the given time up to the next half hour. This does not round if the
 * time already sits on a half hour period. For example:
 * - 12:00 -> 12:00
 * - 12:01 -> 12:30
 * - 12:29 -> 12:30
 * - 12:30 -> 12:30
 * - 12:31 -> 13:00
 * - 12:59 -> 13:00
 * - 23:59 -> 00:00
 */
export const roundTimeToNextHalfHour = ({ hours, minutes }: Time): Time => {
  if (minutes > 0 && minutes < 30) {
    return { hours, minutes: 30 };
  }

  if (minutes > 30) {
    return { hours: (hours + 1) % 24, minutes: 0 };
  }

  return { hours, minutes };
};
