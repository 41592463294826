/**
 * Logs the given error, along with any custom attributes, to the console. The
 * same details are then forwarded to New Relic.
 */
export const logError = (error: Error, customAttributes?: CustomAttributes) => {
  logErrorToConsole(error, customAttributes);

  const isNewRelicBrowserAvailable = !!window.NREUM;

  if (isNewRelicBrowserAvailable) {
    logErrorToNewRelic(error, customAttributes);
  }
};

const logErrorToConsole = (
  error: Error,
  customAttributes?: CustomAttributes,
): void => {
  console.error(error);

  if (customAttributes) {
    console.info(
      'The previous error contained the following custom attributes:',
    );
    console.table(customAttributes);
  }
};

const logErrorToNewRelic = (
  error: Error,
  customAttributes?: CustomAttributes,
): void => {
  /**
   * We need to try/catch the error to ensure a stack trace is always available.
   *
   * @see {@link https://docs.newrelic.com/docs/browser/new-relic-browser/browser-apis/noticeerror/#browser-limits}
   */
  try {
    throw error;
  } catch (error) {
    /**
     * @see {@link https://docs.newrelic.com/docs/browser/new-relic-browser/browser-agent-spa-api/noticeerror-browser-agent-api/}
     */
    newrelic.noticeError(error as Error, customAttributes);
  }
};

export type CustomAttributes = Record<string, newrelic.SimpleType>;
