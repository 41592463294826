import type {
  PrivateRateOverrideUnit,
  PrivateRateOverrideUnitDTO,
} from '@/private-rate-override/unit/private-rate-override-unit';

export const mapPrivateRateOverrideUnitFromDTO = (
  privateRateOverrideUnitDTO: PrivateRateOverrideUnitDTO,
): PrivateRateOverrideUnit => ({
  unitId: privateRateOverrideUnitDTO.unitId,
  rate: Number(privateRateOverrideUnitDTO.rate),
});
