<template>
  <AppLayout :property>
    <ContainerLayout class="p-4">
      <BookingConfirmationHeader class="mb-6" />

      <BookingConfirmationAssuranceList v-if="!booking.isCancelled" />

      <div class="gap-10 md:flex md:flex-row">
        <div class="flex-[2]">
          <BookingConfirmationPropertyDetails />

          <BookingConfirmationMessageHostButton
            v-if="booking.guestCanStartMessagingConversation"
            class="mt-5"
          />

          <BookingConfirmationEditBookingDetailsContainer class="mt-5" />

          <BookingConfirmationSummary class="mt-5" />

          <BookingConfirmationTermsAndConditionsStatement
            v-if="!booking.isCancelled"
            class="mt-5"
          />
        </div>

        <div class="mt-6 flex-[1] overflow-hidden md:mt-0">
          <BookingConfirmationPaymentDetails />

          <BookingConfirmationPrintButton />
        </div>
      </div>

      <AppBloomByFreetobookLink class="mt-10" />
    </ContainerLayout>
  </AppLayout>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import BookingConfirmationAssuranceList from '@/booking-confirmation-page/assurances/BookingConfirmationAssuranceList.vue';
import { useBookingConfirmationStore } from '@/booking-confirmation-page/booking-confirmation.store';
import BookingConfirmationMessageHostButton from '@/booking-confirmation-page/BookingConfirmationMessageHostButton.vue';
import BookingConfirmationPrintButton from '@/booking-confirmation-page/BookingConfirmationPrintButton.vue';
import BookingConfirmationEditBookingDetailsContainer from '@/booking-confirmation-page/edit-booking-details/BookingConfirmationEditBookingDetailsContainer.vue';
import BookingConfirmationHeader from '@/booking-confirmation-page/header/BookingConfirmationHeader.vue';
import BookingConfirmationPaymentDetails from '@/booking-confirmation-page/payment-details/BookingConfirmationPaymentDetails.vue';
import BookingConfirmationPropertyDetails from '@/booking-confirmation-page/property-details/BookingConfirmationPropertyDetails.vue';
import BookingConfirmationSummary from '@/booking-confirmation-page/summary/BookingConfirmationSummary.vue';
import BookingConfirmationTermsAndConditionsStatement from '@/booking-confirmation-page/terms-and-conditions/statement/BookingConfirmationTermsAndConditionsStatement.vue';
import AppLayout from '@/layouts/AppLayout.vue';
import ContainerLayout from '@/layouts/ContainerLayout.vue';
import AppBloomByFreetobookLink from '@/ui/app-bloom-by-freetobook-link/AppBloomByFreetobookLink.vue';

const { property, booking } = storeToRefs(useBookingConfirmationStore());
</script>
