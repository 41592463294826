<template>
  <div class="relative">
    <input
      :id="labelId"
      v-model.trim="value"
      :type="type"
      class="peer block w-full appearance-none px-3 pb-2 pt-6 text-gray-800 shadow-[0_0_0_1px] transition-colors focus:relative focus:z-[2] focus:shadow-[0_0_0_2px] focus:outline-none focus:ring-0"
      :class="[
        roundedBorderClass,
        isValid
          ? 'bg-white shadow-gray-300'
          : 'relative z-[1] bg-red-500/10 shadow-[0_0_0_1px] shadow-red-500 focus:shadow-red-500',
      ]"
      placeholder=" "
      :autocomplete="autocompleteType"
      :aria-invalid="!isValid"
      :maxlength="maxLength"
    />

    <label
      :for="labelId"
      class="label-placeholder absolute top-[1.1rem] z-[3] origin-[0] px-4 duration-300"
      :class="
        isValid
          ? 'text-gray-700 peer-focus:text-gray-900'
          : 'text-red-700 peer-focus:text-red-700'
      "
    >
      {{ label }}
    </label>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  type: string;
  label: string;
  labelId: string;
  autocompleteType: string;
  isValid: boolean;
  maxLength: number;
  roundedBorderSides?: 'top' | 'bottom' | 'none';
}>();

const value = defineModel<string>({ required: true });

const roundedBorderClass = computed(() => {
  switch (props.roundedBorderSides) {
    case 'top':
      return 'rounded-t-lg';
    case 'bottom':
      return 'rounded-b-lg';
    case 'none':
      return 'rounded-none';
    default:
      return 'rounded-lg';
  }
});
</script>

<style scoped>
.peer:focus:placeholder-shown ~ .label-placeholder,
.peer:not(:focus, :placeholder-shown) ~ .label-placeholder,
.peer:focus:not(:placeholder-shown) ~ .label-placeholder,
.peer:autofill ~ .label-placeholder {
  top: 0.3rem;
  scale: 0.75;
  padding-left: 1rem;
}
</style>
