import type { BookingPolicies } from '@/property/booking-policy/booking-policies/booking-policies';
import type { BookingPolicy } from '@/property/booking-policy/booking-policy';
import type { DateRangedBookingPolicy } from '@/property/booking-policy/date-ranged-booking-policy/date-ranged-booking-policy';
import { isDateRangedBookingPolicyApplicableForStayDates } from '@/property/booking-policy/date-ranged-booking-policy/date-ranged-booking-policy.utilities';
import type { StandardBookingPolicy } from '@/property/booking-policy/standard-booking-policy/standard-booking-policy';
import { isStandardBookingPolicyApplicableForStayDates } from '@/property/booking-policy/standard-booking-policy/standard-booking-policy.utilities';
import type { StayDates } from '@/stay-dates/stay-dates';

/**
 * Returns the single applicable booking policy for the given stay dates.
 *
 * Highest priority should go to date-ranged policies, then standard policies.
 */
export const getApplicableBookingPolicyFromBookingPoliciesForStayDates = (
  bookingPolicies: BookingPolicies,
  stayDates: StayDates,
  timezone: string,
): BookingPolicy => {
  const applicableDateRangedBookingPolicy =
    getApplicableDateRangedBookingPolicyFromBookingPoliciesForStayDates(
      bookingPolicies,
      stayDates,
    );

  if (applicableDateRangedBookingPolicy) {
    return applicableDateRangedBookingPolicy.bookingPolicy;
  }

  const applicableStandardBookingPolicy =
    getApplicableStandardBookingPolicyFromBookingPoliciesForStayDates(
      bookingPolicies,
      stayDates,
      timezone,
    );

  if (applicableStandardBookingPolicy) {
    return applicableStandardBookingPolicy.bookingPolicy;
  }

  throw new Error('Could not find any applicable booking policy');
};

/**
 * Returns the first applicable date-ranged policy for the given stay dates.
 * Returns undefined if none are applicable.
 */
const getApplicableDateRangedBookingPolicyFromBookingPoliciesForStayDates = (
  { dateRangedBookingPolicies }: BookingPolicies,
  stayDates: StayDates,
): DateRangedBookingPolicy | undefined =>
  dateRangedBookingPolicies.find((dateRangedBookingPolicy) =>
    isDateRangedBookingPolicyApplicableForStayDates(
      dateRangedBookingPolicy,
      stayDates,
    ),
  );

/**
 * Returns the first applicable standard policy for the given stay dates.
 * Returns undefined if none of applicable.
 */
const getApplicableStandardBookingPolicyFromBookingPoliciesForStayDates = (
  { standardBookingPolicies }: BookingPolicies,
  stayDates: StayDates,
  timezone: string,
): StandardBookingPolicy | undefined =>
  standardBookingPolicies.find((standardBookingPolicy) =>
    isStandardBookingPolicyApplicableForStayDates(
      standardBookingPolicy,
      stayDates,
      timezone,
    ),
  );
