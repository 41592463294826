import type { CodeResourceType } from '@/code/resource/code-resource';
import type { PrivateRateOverride } from '@/private-rate-override/private-rate-override';
import type { Promocode } from '@/property/promocode/promocode';

export enum CodeInputStatusType {
  Valid = 'valid',
  Invalid = 'invalid',
  Pending = 'pending',
  Normal = 'normal',
}

export type CodeInputStatus =
  | {
      type: Exclude<CodeInputStatusType, CodeInputStatusType.Valid>;
    }
  | {
      type: CodeInputStatusType.Valid;
      resource:
        | {
            type: CodeResourceType.Promocode;
            promocode: Promocode;
          }
        | {
            type: CodeResourceType.PrivateRateOverride;
            privateRateOverride: PrivateRateOverride;
          };
    };
