<template>
  <div class="flex items-center justify-between gap-2">
    <div>
      <AppLinkButton
        v-show="code.length !== 0"
        class="text-sm"
        :text="$t('clearPromocode')"
        @click="clearCode"
      />
    </div>

    <AppPrimaryButton
      :disabled="code.length === 0"
      :button-text="toTitleCase($t(isCodeValid ? 'done' : 'apply'))"
      class="min-w-[7rem] p-3"
      @click="isCodeValid ? $emit('close') : applyCode()"
    />
  </div>
</template>

<script setup lang="ts">
import { useCodeInputStore } from '@/code/input/code-input.store';
import { useLocaleString } from '@/string/locale-string.composable';
import AppLinkButton from '@/ui/app-link-button/AppLinkButton.vue';
import AppPrimaryButton from '@/ui/app-primary-button/AppPrimaryButton.vue';

const emit = defineEmits(['close', 'clear']);

const { code, isCodeValid, applyCode, removeCode } = useCodeInputStore();

const { toTitleCase } = useLocaleString();

const clearCode = () => {
  removeCode();

  emit('clear');
};
</script>
