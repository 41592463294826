<template>
  <div
    role="status"
    class="inline-block rounded-xl border px-3 py-1 text-center"
    :class="className"
  >
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

type Variant = 'default' | 'success';

const props = withDefaults(
  defineProps<{
    variant?: Variant;
  }>(),
  {
    variant: 'default',
  },
);

const className = computed(() => {
  switch (props.variant) {
    case 'success':
      return 'bg-green-700 border-green-700 text-white';
  }

  return 'bg-transparent border-slate-800 text-slate-800';
});
</script>
