<template>
  <div
    :class="{ 'h-56': status === StripePaymentStatus.Initializing }"
    class="relative"
  >
    <div>
      <div v-show="expressCheckoutElementIsReady">
        <div ref="expressCheckoutElementContainer"></div>

        <AppDivider
          :text="$t('orPayByCard')"
          class="my-6 first-letter:capitalize"
        />
      </div>

      <AppForm
        v-if="propertyIsUsingExpressCheckout"
        ref="guestDetailsForm"
        class="mb-4 space-y-4"
        @submit="() => confirmPayment(true)"
      >
        <input type="submit" hidden />

        <AppFormInputValidationGroup>
          <StripePaymentGuestNameInput />
        </AppFormInputValidationGroup>

        <AppFormInputValidationGroup>
          <StripePaymentGuestEmailAddressInput />
        </AppFormInputValidationGroup>

        <AppFormInputValidationGroup>
          <StripePaymentGuestPhoneNumberInput />
        </AppFormInputValidationGroup>
      </AppForm>

      <div
        v-if="cardErrorMessage"
        ref="cardValidationErrorContainer"
        :class="{ 'animate-shake': shouldAnimateCardValidationError }"
        class="mb-4 scroll-mt-[calc(theme(spacing.inline-booking-stage-selector-height)+theme(space.4))]"
        @animationend="shouldAnimateCardValidationError = false"
      >
        <PaymentsCardError :message="cardErrorMessage" />
      </div>

      <div ref="paymentElementContainer"></div>
    </div>

    <StripePaymentLoadingOverlay :status="status" />
  </div>
</template>

<script setup lang="ts">
import type {
  PaymentIntent,
  PaymentMethod,
  SetupIntent,
  StripeError,
} from '@stripe/stripe-js';
import { useEventBus } from '@vueuse/core';
import { HTTPError } from 'ky';
import { computed, nextTick, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue3-toastify';
import { makeDefaultAddress } from '@/address/address.utilities';
import { sendConfirmBookingViaStripeIntentRequest } from '@/booking/booking.api';
import { useBookingCreate } from '@/booking/create/booking-create.composable';
import type { BookingCreateError } from '@/booking/create/error/booking-create-error';
import { BookingCreateErrorType } from '@/booking/create/error/booking-create-error';
import { shouldBookingCreateProcessingErrorTriggerAvailabilityError } from '@/booking/create/error/processing/booking-create-processing-error.utilities';
import { BookingCreateResultType } from '@/booking/create/result/booking-create-result';
import { useBookingConfirmationNavigation } from '@/booking-confirmation-page/booking-confirmation-navigation.composable';
import { useBookingSummaryStore } from '@/booking-summary/booking-summary.store';
import {
  bookingCreateAvailabilityErrorEventBusKey,
  submitPaymentEventBusKey,
} from '@/event-bus/event-bus';
import { useGuestStore } from '@/guest/guest.store';
import { logError } from '@/log/log.utilities';
import PaymentsCardError from '@/payments-page/PaymentsCardError.vue';
import StripePaymentGuestEmailAddressInput from '@/payments-page/stripe-payment/guest-email-address/StripePaymentGuestEmailAddressInput.vue';
import StripePaymentGuestNameInput from '@/payments-page/stripe-payment/guest-name/StripePaymentGuestNameInput.vue';
import StripePaymentGuestPhoneNumberInput from '@/payments-page/stripe-payment/guest-phone-number/StripePaymentGuestPhoneNumberInput.vue';
import { StripePaymentStatus } from '@/payments-page/stripe-payment/stripe-payment-status';
import StripePaymentLoadingOverlay from '@/payments-page/stripe-payment/StripePaymentLoadingOverlay.vue';
import { PaymentGatewayType } from '@/property/payment-gateway/payment-gateway';
import type { StripePaymentGateway } from '@/property/payment-gateway/stripe/stripe-payment-gateway';
import { useSearchStore } from '@/search/search.store';
import {
  elements,
  stripe,
  useStripeElementsStore,
} from '@/stripe-elements/stripe-elements.store';
import AppDivider from '@/ui/app-divider/AppDivider.vue';
import AppForm from '@/ui/app-form/AppForm.vue';
import AppFormInputValidationGroup from '@/ui/app-form/AppFormInputValidationGroup.vue';

const props = defineProps<{ stripePaymentGateway: StripePaymentGateway }>();

const { t } = useI18n();

const { createBooking } = useBookingCreate();
const {
  goToBookingConfirmationWithBookingCreateResult,
  goToBookingConfirmationWithBookingCreateConfirmedResult,
} = useBookingConfirmationNavigation();

const guestStore = useGuestStore();
const searchStore = useSearchStore();
const bookingSummaryStore = useBookingSummaryStore();
const stripeElementsStore = useStripeElementsStore();

const expressCheckoutElementIsReady = ref(false);
const shouldAnimateCardValidationError = ref(false);

const status = ref<StripePaymentStatus>(StripePaymentStatus.Initializing);

const cardErrorMessage = ref<string>();

const paymentElementContainer = ref<HTMLDivElement | null>(null);
const expressCheckoutElementContainer = ref<HTMLDivElement | null>(null);
const cardValidationErrorContainer = ref<HTMLDivElement | null>(null);

const guestDetailsForm = ref<InstanceType<typeof AppForm> | null>(null);

const propertyIsUsingExpressCheckout = computed(
  () => searchStore.activePropertyCanUseExpressCheckout,
);

const initializePaymentElement = () => {
  const paymentElement = elements.create(
    'payment',
    stripeElementsStore.generatePaymentElementOptions(),
  );

  if (!paymentElementContainer.value) {
    throw new Error(
      'Found no container to mount the Stripe payment element to',
    );
  }

  paymentElement.mount(paymentElementContainer.value);

  paymentElement.on('loaderstart', () => {
    status.value = StripePaymentStatus.Ready;
  });

  paymentElement.on('ready', () => {
    status.value = StripePaymentStatus.Ready;
  });
};

const initializeExpressCheckoutElement = () => {
  const expressCheckoutElement = elements.create(
    'expressCheckout',
    stripeElementsStore.generateExpressCheckoutElementOptions(),
  );

  if (!expressCheckoutElementContainer.value) {
    throw new Error(
      'Found no container to mount the Stripe express checkout element to',
    );
  }

  expressCheckoutElement.mount(expressCheckoutElementContainer.value);

  expressCheckoutElement.on('ready', ({ availablePaymentMethods }) => {
    if (availablePaymentMethods) {
      expressCheckoutElementIsReady.value = true;
    }
  });

  if (propertyIsUsingExpressCheckout.value) {
    expressCheckoutElement.on('click', ({ resolve }) => {
      resolve({ phoneNumberRequired: true, emailRequired: true });
    });
  }

  expressCheckoutElement.on('confirm', () => confirmPayment(false, true));
};

const confirmPayment = async (
  shouldValidateGuestDetailsForm: boolean,
  usedExpressCheckoutElement = false,
) => {
  if (shouldValidateGuestDetailsForm) {
    guestDetailsForm.value?.validate();

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!guestDetailsForm.value?.isValid) {
      return;
    }
  }

  const { error } = await elements.submit();

  if (error) {
    handleStripeError(error);

    return;
  }

  cardErrorMessage.value = undefined;

  bookingSummaryStore.isLoading = true;
  status.value = StripePaymentStatus.CheckingCard;

  const { error: paymentMethodError, paymentMethod } =
    await stripe.createPaymentMethod(
      stripeElementsStore.generatePaymentMethodOptions(),
    );

  if (paymentMethodError) {
    handleStripeError(paymentMethodError);
  } else {
    status.value = StripePaymentStatus.ConfirmingBooking;

    await createBookingWithPaymentMethod(
      paymentMethod,
      usedExpressCheckoutElement,
    );
  }

  bookingSummaryStore.isLoading = false;
  status.value = StripePaymentStatus.Ready;
};

const createBookingWithPaymentMethod = async (
  paymentMethod: PaymentMethod,
  usedExpressCheckoutElement: boolean,
) => {
  try {
    if (propertyIsUsingExpressCheckout.value) {
      updateGuestDetailsFromPaymentMethod(
        paymentMethod,
        usedExpressCheckoutElement,
      );
    }

    const bookingCreateResult = await createBooking({
      type: PaymentGatewayType.Stripe,
      paymentMethodId: paymentMethod.id,
    });

    if (bookingCreateResult.type === BookingCreateResultType.Confirmed) {
      await goToBookingConfirmationWithBookingCreateResult(bookingCreateResult);
    } else if (
      bookingCreateResult.type === BookingCreateResultType.StripeActionRequired
    ) {
      const { error, paymentIntent, setupIntent } =
        await stripe.handleNextAction({
          clientSecret: bookingCreateResult.clientSecret,
        });

      if (error) {
        handleStripeError(error);
      } else {
        await confirmBookingWithIntent(
          bookingCreateResult.bookingId,
          paymentIntent ?? setupIntent,
        );
      }
    } else {
      throw new Error(
        `Unexpected booking create result type ${bookingCreateResult.type}`,
      );
    }
  } catch (error) {
    void handleBookingCreateError(error);
  }
};

const confirmBookingWithIntent = async (
  bookingId: string,
  intent: PaymentIntent | SetupIntent,
) => {
  const bookingCreateConfirmedResult =
    await sendConfirmBookingViaStripeIntentRequest(bookingId, intent);

  await goToBookingConfirmationWithBookingCreateConfirmedResult(
    bookingCreateConfirmedResult,
  );
};

const handleBookingCreateError = async (error: unknown) => {
  if (error instanceof HTTPError) {
    const responseBody = (await error.response.json()) as BookingCreateError;

    if (
      responseBody.type === BookingCreateErrorType.ProcessingError &&
      shouldBookingCreateProcessingErrorTriggerAvailabilityError(
        responseBody.error,
      )
    ) {
      useEventBus(bookingCreateAvailabilityErrorEventBusKey).emit();

      return;
    }

    if (responseBody.type === BookingCreateErrorType.StripeCardError) {
      handleStripeError(responseBody.error);

      return;
    }
  }

  toast.error(
    t('sorrySomethingWentWrongWhenConfirmingYourBookingPleaseTryAgain'),
    { autoClose: false },
  );

  logError(error as Error);
};

const handleStripeError = (stripeError: StripeError) => {
  void animateCardValidationError();

  if (stripeError.type === 'validation_error') {
    cardErrorMessage.value = t(
      'pleaseEnsureAllYourCardDetailsHaveBeenCorrectlyEnteredBelow',
    );
  } else if (stripeError.type === 'card_error') {
    cardErrorMessage.value = stripeError.message;
  } else {
    logError(new Error(stripeError.message));
  }

  if (!cardErrorMessage.value) {
    cardErrorMessage.value = t(
      'sorrySomethingWentWrongWhenProcessingYourCardPleaseTryAgain',
    );
  }
};

const updateGuestDetailsFromPaymentMethod = (
  paymentMethod: PaymentMethod,
  usedExpressCheckoutElement: boolean,
) => {
  guestStore.guest.address = makeDefaultAddress();

  if (usedExpressCheckoutElement) {
    if (paymentMethod.billing_details.name) {
      guestStore.name = paymentMethod.billing_details.name;
    } else {
      logError(
        new Error(`No name found for payment method ${paymentMethod.id}`),
      );
    }

    if (paymentMethod.billing_details.email) {
      guestStore.guest.emailAddress = paymentMethod.billing_details.email;
    } else {
      logError(
        new Error(
          `No email address found for payment method ${paymentMethod.id}`,
        ),
      );
    }

    if (paymentMethod.billing_details.phone) {
      guestStore.guest.phoneNumber = paymentMethod.billing_details.phone;
    } else {
      logError(
        new Error(
          `No phone number found for payment method ${paymentMethod.id}`,
        ),
      );
    }
  }

  if (paymentMethod.billing_details.address?.line1) {
    guestStore.guest.address.line1 =
      paymentMethod.billing_details.address.line1;
  }

  if (paymentMethod.billing_details.address?.postal_code) {
    guestStore.guest.address.postcode =
      paymentMethod.billing_details.address.postal_code;
  }

  if (paymentMethod.billing_details.address?.city) {
    guestStore.guest.address.city = paymentMethod.billing_details.address.city;
  }

  if (paymentMethod.billing_details.address?.state) {
    guestStore.guest.address.administrativeArea =
      paymentMethod.billing_details.address.state;
  }

  if (paymentMethod.billing_details.address?.country) {
    guestStore.guest.address.countryCode =
      paymentMethod.billing_details.address.country;
  }
};

const animateCardValidationError = async () => {
  shouldAnimateCardValidationError.value = true;

  await nextTick();

  cardValidationErrorContainer.value?.scrollIntoView({
    behavior: 'smooth',
    block: 'nearest',
  });
};

onMounted(async () => {
  await stripeElementsStore.initialize(
    props.stripePaymentGateway.publishableKey,
  );

  initializePaymentElement();
  initializeExpressCheckoutElement();
});

useEventBus(submitPaymentEventBusKey).on(
  () => void confirmPayment(propertyIsUsingExpressCheckout.value),
);
</script>

<style scoped>
:deep(iframe:focus-visible) {
  outline: none;
}
</style>
