import type {
  FacebookPixel,
  FacebookPixelDTO,
} from '@/property/facebook-pixel/facebook-pixel';

export const mapFacebookPixelFromDTO = (
  facebookPixelDTO: FacebookPixelDTO,
): FacebookPixel => ({
  id: facebookPixelDTO.facebookId,
});
