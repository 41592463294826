<template>
  <div ref="sagePayCheckoutFormContainer"></div>

  <SagePayCheckout3DSModal
    v-if="bookingCreateSagePay3DSRequiredResult"
    :booking-create-sage-pay3-d-s-required-result="
      bookingCreateSagePay3DSRequiredResult
    "
  />
</template>

<script setup lang="ts">
import { useEventBus } from '@vueuse/core';
import { HTTPError } from 'ky';
import { onMounted, onUnmounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue3-toastify';
import { useBookingCreate } from '@/booking/create/booking-create.composable';
import type { BookingCreateError } from '@/booking/create/error/booking-create-error';
import { BookingCreateErrorType } from '@/booking/create/error/booking-create-error';
import { shouldBookingCreateProcessingErrorTriggerAvailabilityError } from '@/booking/create/error/processing/booking-create-processing-error.utilities';
import { BookingCreateResultType } from '@/booking/create/result/booking-create-result';
import type { BookingCreateSagePay3DSRequiredResult } from '@/booking/create/result/sage-pay-3ds-required/booking-create-sage-pay-3ds-required-result';
import { useBookingConfirmationNavigation } from '@/booking-confirmation-page/booking-confirmation-navigation.composable';
import { useBookingSummaryStore } from '@/booking-summary/booking-summary.store';
import {
  bookingCreateAvailabilityErrorEventBusKey,
  submitPaymentEventBusKey,
} from '@/event-bus/event-bus';
import { logError } from '@/log/log.utilities';
import { useSagePayCheckoutMessageEvent } from '@/payments-page/sage-pay-checkout/message-event/sage-pay-checkout-message-event.composable';
import { useSagePayCheckoutStore } from '@/payments-page/sage-pay-checkout/sage-pay-checkout.store';
import SagePayCheckout3DSModal from '@/payments-page/sage-pay-checkout/SagePayCheckout3DSModal.vue';
import { PaymentGatewayType } from '@/property/payment-gateway/payment-gateway';

const { t } = useI18n();

const { createBooking } = useBookingCreate();
useSagePayCheckoutMessageEvent();
const { goToBookingConfirmationWithBookingCreateConfirmedResult } =
  useBookingConfirmationNavigation();

const bookingSummaryStore = useBookingSummaryStore();
const sagePayCheckoutStore = useSagePayCheckoutStore();

const sagePayCheckoutFormContainer = ref<HTMLDivElement | null>(null);
const sagePayCheckoutForm = ref<SagePayCheckoutForm>();

const bookingCreateSagePay3DSRequiredResult =
  ref<BookingCreateSagePay3DSRequiredResult>();

const initializeForm = () => {
  sagePayCheckoutForm.value = sagepayCheckout({
    containerSelector: () => sagePayCheckoutFormContainer.value!,
    merchantSessionKey: sagePayCheckoutStore.merchantSessionKey,
    onTokenise: (tokenizationResult) =>
      tokenizationResult.success
        ? void onTokenizationSuccess(tokenizationResult)
        : void onTokenizationFailure(tokenizationResult),
  });
};

const tokenizeForm = () => {
  sagePayCheckoutStore.cardErrorMessage = undefined;

  sagePayCheckoutForm.value?.tokenise({
    newMerchantSessionKey: sagePayCheckoutStore.merchantSessionKey,
  });
};

const destroyForm = () => {
  // Allow page transitions to finish before destroying
  setTimeout(() => {
    sagePayCheckoutForm.value?.destroy();
  }, 500);
};

const onTokenizationSuccess = async ({
  cardIdentifier,
}: SagePayCheckoutFormTokenizationSuccess) => {
  bookingSummaryStore.isLoading = true;
  sagePayCheckoutStore.isCreatingBooking = true;

  try {
    const bookingCreateResult = await createBooking({
      type: PaymentGatewayType.SagePay,
      merchantSessionKey: sagePayCheckoutStore.merchantSessionKey,
      cardIdentifier,
      strongCustomerAuth: {
        timezoneOffsetMinutes: new Date().getTimezoneOffset(),
        screenHeight: screen.height,
        screenWidth: screen.width,
      },
    });

    if (bookingCreateResult.type === BookingCreateResultType.Confirmed) {
      await goToBookingConfirmationWithBookingCreateConfirmedResult(
        bookingCreateResult,
      );
    } else if (
      bookingCreateResult.type === BookingCreateResultType.SagePay3DSRequired
    ) {
      bookingCreateSagePay3DSRequiredResult.value = bookingCreateResult;
      sagePayCheckoutStore.is3DSModalOpen = true;
    } else {
      throw new Error(
        `Unexpected booking create result type ${bookingCreateResult.type}`,
      );
    }
  } catch (error) {
    void handleBookingCreateError(error);
  } finally {
    bookingSummaryStore.isLoading = false;
    sagePayCheckoutStore.isCreatingBooking = false;
  }
};

const onTokenizationFailure = async ({
  error,
}: SagePayCheckoutFormTokenizationFailure) => {
  toast.error(
    t('sorrySomethingWentWrongWhenProcessingYourCardPleaseTryAgain'),
    { autoClose: false },
  );

  logError(new Error(error.errorMessage));

  await sagePayCheckoutStore.loadMerchantSessionKey();
};

const handleBookingCreateError = async (error: unknown) => {
  await sagePayCheckoutStore.loadMerchantSessionKey();

  if (error instanceof HTTPError) {
    const responseBody = (await error.response.json()) as BookingCreateError;

    if (
      responseBody.type === BookingCreateErrorType.ProcessingError &&
      shouldBookingCreateProcessingErrorTriggerAvailabilityError(
        responseBody.error,
      )
    ) {
      useEventBus(bookingCreateAvailabilityErrorEventBusKey).emit();

      return;
    }

    if (responseBody.type === BookingCreateErrorType.SagePayCardError) {
      sagePayCheckoutStore.setCardErrorMessageFromSagePayCode(
        responseBody.sagePayCode,
      );

      return;
    }
  }

  toast.error(
    t('sorrySomethingWentWrongWhenConfirmingYourBookingPleaseTryAgain'),
    { autoClose: false },
  );

  logError(error as Error);
};

onMounted(initializeForm);

useEventBus(submitPaymentEventBusKey).on(tokenizeForm);

onUnmounted(destroyForm);
</script>
