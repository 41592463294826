<template>
  <AppFormValidatedInput
    v-slot="{ isValid }"
    :value="guestDetailsStore.confirmEmail"
    :validation-rules="[
      isEqualToRule(
        guestStore.guest.emailAddress,
        $t('emailAddressesDoNotMatch'),
      ),
    ]"
  >
    <AppFloatingLabelInput
      v-model="guestDetailsStore.confirmEmail"
      type="text"
      :label="capitalize($t('confirmEmail'))"
      label-id="confirm-email"
      autocomplete-type="email"
      :is-valid="isValid"
      :max-length="EMAIL_MAX_LENGTH"
      rounded-border-sides="bottom"
    />
  </AppFormValidatedInput>
</template>

<script setup lang="ts">
import { EMAIL_MAX_LENGTH } from '@/booking/booking';
import { useGuestStore } from '@/guest/guest.store';
import { useGuestDetailsStore } from '@/guest-details-page/guest-details.store';
import { useLocaleString } from '@/string/locale-string.composable';
import AppFloatingLabelInput from '@/ui/app-floating-label-input/AppFloatingLabelInput.vue';
import { isEqualToRule } from '@/ui/app-form/app-form-input-validation-rules.utilities';
import AppFormValidatedInput from '@/ui/app-form/AppFormValidatedInput.vue';

const guestStore = useGuestStore();
const guestDetailsStore = useGuestDetailsStore();

const { capitalize } = useLocaleString();
</script>
