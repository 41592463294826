<template>
  <div class="flex items-center justify-between">
    <div class="mr-2">
      <div class="font-medium capitalize">{{ $t('children') }}</div>

      <div class="text-sm text-neutral-600">
        <p
          v-if="childPolicy.childrenAreAllowed"
          class="whitespace-nowrap capitalize"
        >
          {{
            $t('agesBetween', {
              fromAge: childPolicy.minimumChildAge,
              toAge: childPolicy.maximumChildAge,
            })
          }}
        </p>

        <p v-else>
          {{ $t('allGuestsMustBe18OrOverNoChildrenAreAllowed') }}
        </p>
      </div>
    </div>

    <div class="flex items-center text-center">
      <AppAdjuster
        :is-incrementing="false"
        :disabled="
          !canDecrementNumberOfChildren(unitOccupancy.id) ||
          !childPolicy.childrenAreAllowed
        "
        :label="toTitleCase($t('removeChild'))"
        @click="decrementNumberOfChildren(unitOccupancy.id)"
      />

      <span
        class="w-12"
        :class="{ 'text-neutral-300': !childPolicy.childrenAreAllowed }"
        :aria-label="$t('numberOfSelectedChildren')"
      >
        {{ unitOccupancy.childOccupants.length }}
      </span>

      <AppAdjuster
        is-incrementing
        :disabled="!childPolicy.childrenAreAllowed"
        :label="toTitleCase($t('addChild'))"
        @click="incrementNumberOfChildren(unitOccupancy.id)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useOccupancySelectorStore } from '@/occupancy-selector/occupancy-selector.store';
import type { OccupancySelectorUnitOccupancy } from '@/occupancy-selector/unit-occupancy/occupancy-selector-unit-occupancy';
import { useLocaleString } from '@/string/locale-string.composable';
import AppAdjuster from '@/ui/app-adjuster/AppAdjuster.vue';

defineProps<{
  unitOccupancy: OccupancySelectorUnitOccupancy;
}>();

const { toTitleCase } = useLocaleString();

const {
  property,
  incrementNumberOfChildren,
  decrementNumberOfChildren,
  canDecrementNumberOfChildren,
} = useOccupancySelectorStore();

const childPolicy = computed(() => property.value.childPolicy);
</script>
