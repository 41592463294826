import type {
  PaymentGateway,
  PaymentGatewayDTO,
} from '@/property/payment-gateway/payment-gateway';
import { PaymentGatewayType } from '@/property/payment-gateway/payment-gateway';
import { mapStripePaymentGatewayFromDTO } from '@/property/payment-gateway/stripe/stripe-payment-gateway.mapper';

export const mapPaymentGatewayFromDTO = (
  paymentGatewayDTO: PaymentGatewayDTO,
): PaymentGateway => {
  const { type } = paymentGatewayDTO;

  switch (type) {
    case PaymentGatewayType.None:
    case PaymentGatewayType.Pcib:
      return { type };
    case PaymentGatewayType.SagePay:
      return {
        type,
        canTakeDeposits: paymentGatewayDTO.canTakeDeposits,
      };
    case PaymentGatewayType.Stripe:
      return mapStripePaymentGatewayFromDTO(paymentGatewayDTO);
  }
};
