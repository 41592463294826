<template>
  <div class="flex min-h-app flex-col">
    <main class="flex grow flex-col">
      <slot></slot>
    </main>

    <TheFooter class="mb-modal-booking-summary-footer-height" :property />
  </div>
</template>

<script setup lang="ts">
import TheFooter from '@/footer/TheFooter.vue';
import type { Property } from '@/property/property';
import { useViewportHeightCalculator } from '@/viewport-height-calculator/viewport-height-calculator';

defineProps<{ property: Property | undefined }>();

useViewportHeightCalculator();
</script>
