<template>
  <div
    v-if="
      !bookingItineraryStore.hasEmptyUnitItinerary &&
      bookingSummaryStore.showModalVersion
    "
  >
    <ModalBookingSummaryFooter v-element-size="onResize" />

    <Dialog
      :open="bookingSummaryStore.isModalOpen"
      :aria-label="$t('bookingSummary')"
      @close="bookingSummaryStore.isModalOpen = false"
    >
      <div class="fixed inset-0 z-30 bg-black/30" aria-hidden="true"></div>

      <DialogPanel
        class="fixed bottom-modal-booking-summary-footer-height z-30 flex max-h-[70%] w-full flex-col"
      >
        <div
          class="flex flex-col items-center overflow-hidden rounded-t-lg bg-white shadow-[0_0px_10px_0px_rgba(0,0,0,0.4)]"
        >
          <button
            class="text-zinc-300 focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-slate-800"
            @click="bookingSummaryStore.isModalOpen = false"
          >
            <IconChevronDown />
          </button>

          <BookingSummaryHeader class="pt-0" />
          <BookingSummaryBody />
        </div>

        <ModalBookingSummaryFooter />
      </DialogPanel>
    </Dialog>
  </div>
</template>

<script setup lang="ts">
import { Dialog, DialogPanel } from '@headlessui/vue';
import { IconChevronDown } from '@tabler/icons-vue';
import { vElementSize } from '@vueuse/components';
import { watchEffect } from 'vue';
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import BookingSummaryBody from '@/booking-summary/body/BookingSummaryBody.vue';
import { useBookingSummaryStore } from '@/booking-summary/booking-summary.store';
import ModalBookingSummaryFooter from '@/booking-summary/footer/ModalBookingSummaryFooter.vue';
import BookingSummaryHeader from '@/booking-summary/header/BookingSummaryHeader.vue';

const bookingSummaryStore = useBookingSummaryStore();
const bookingItineraryStore = useBookingItineraryStore();

const onResize = ({ height }: { height: number }): void => {
  document.documentElement.style.setProperty(
    '--modal-booking-summary-footer-height',
    `${height}px`,
  );
};

watchEffect(() => {
  if (bookingItineraryStore.hasEmptyUnitItinerary) {
    bookingSummaryStore.isModalOpen = false;
  }
});
</script>
