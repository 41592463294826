<template>
  <AppAccordion
    v-model="isOpen"
    class="rounded-md border-2 border-dashed border-yellow-500 bg-yellow-500/5"
  >
    <AppAccordionButton
      class="flex items-center justify-between gap-2 p-4 transition-colors duration-300 hover:bg-yellow-500/10 focus-visible:shadow-[0_0_0_3px] focus-visible:shadow-yellow-500 focus-visible:outline-none"
      :class="isOpen ? 'rounded-t-md' : 'rounded-md'"
    >
      <BookingConfirmationEditBookingDetailsHeader />

      <AppAccordionChevron />
    </AppAccordionButton>

    <AppAccordionPanel>
      <div class="p-4">
        <Transition
          enter-active-class="transition-opacity ease-linear duration-200"
          enter-from-class="opacity-0"
          enter-to-class="opacity-100"
        >
          <AppForm
            v-if="!shouldShowSavedSuccessfully"
            ref="form"
            class="space-y-8"
            @submit="onSave"
          >
            <BookingConfirmationEditBookingDetailsUnits />

            <BookingConfirmationEditBookingDetailsArrivalTime />

            <AppFormInputValidationGroup
              v-if="
                bookingConfirmationStore.property.widgetSettings
                  .allowsSpecialRequests
              "
            >
              <BookingConfirmationEditBookingDetailsSpecialRequests />
            </AppFormInputValidationGroup>

            <AppPrimaryButton
              v-show="canSave"
              class="w-full bg-yellow-500 p-3 text-black"
              :button-text="$t('saveDetails')"
              :is-loading="isSaving"
              @click="onSave"
            />
          </AppForm>

          <BookingConfirmationEditBookingDetailsSavedSuccessfully v-else />
        </Transition>
      </div>
    </AppAccordionPanel>
  </AppAccordion>
</template>

<script setup lang="ts">
import { isEqual } from 'lodash-es';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue3-toastify';
import { useBookingConfirmationStore } from '@/booking-confirmation-page/booking-confirmation.store';
import BookingConfirmationEditBookingDetailsArrivalTime from '@/booking-confirmation-page/edit-booking-details/arrival-time/BookingConfirmationEditBookingDetailsArrivalTime.vue';
import BookingConfirmationEditBookingDetailsHeader from '@/booking-confirmation-page/edit-booking-details/header/BookingConfirmationEditBookingDetailsHeader.vue';
import BookingConfirmationEditBookingDetailsSavedSuccessfully from '@/booking-confirmation-page/edit-booking-details/saved-successfully/BookingConfirmationEditBookingDetailsSavedSuccessfully.vue';
import BookingConfirmationEditBookingDetailsSpecialRequests from '@/booking-confirmation-page/edit-booking-details/special-requests/BookingConfirmationEditBookingDetailsSpecialRequests.vue';
import BookingConfirmationEditBookingDetailsUnits from '@/booking-confirmation-page/edit-booking-details/unit/BookingConfirmationEditBookingDetailsUnits.vue';
import { logError } from '@/log/log.utilities';
import AppAccordion from '@/ui/app-accordion/AppAccordion.vue';
import AppAccordionButton from '@/ui/app-accordion/AppAccordionButton.vue';
import AppAccordionChevron from '@/ui/app-accordion/AppAccordionChevron.vue';
import AppAccordionPanel from '@/ui/app-accordion/AppAccordionPanel.vue';
import AppForm from '@/ui/app-form/AppForm.vue';
import AppFormInputValidationGroup from '@/ui/app-form/AppFormInputValidationGroup.vue';
import AppPrimaryButton from '@/ui/app-primary-button/AppPrimaryButton.vue';

const emit = defineEmits(['close']);

const { t } = useI18n();

const bookingConfirmationStore = useBookingConfirmationStore();

const form = ref<InstanceType<typeof AppForm> | null>(null);

const isOpen = ref(false);
const isSaving = ref(false);
const shouldShowSavedSuccessfully = ref(false);

const canSave = computed(
  () =>
    !isEqual(
      bookingConfirmationStore.booking.arrivalTime,
      bookingConfirmationStore.editableBooking.arrivalTime,
    ) ||
    (bookingConfirmationStore.booking.specialRequests ?? '') !==
      (bookingConfirmationStore.editableBooking.specialRequests ?? '') ||
    bookingConfirmationStore.booking.activeBookingUnits.some(
      (bookingUnit, index) => {
        const editableBookingUnit =
          bookingConfirmationStore.editableBooking.activeBookingUnits[index];

        return (
          (bookingUnit.leadGuestName ?? '') !==
            (editableBookingUnit?.leadGuestName ?? '') ||
          bookingUnit.twinDoubleBedOption !==
            editableBookingUnit?.twinDoubleBedOption
        );
      },
    ),
);

const onSave = async () => {
  if (!canSave.value) {
    return;
  }

  form.value?.validate();

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!form.value?.isValid) {
    return;
  }

  isSaving.value = true;

  try {
    await bookingConfirmationStore.updateBooking();

    shouldShowSavedSuccessfully.value = true;

    setTimeout(() => {
      emit('close');
    }, 2500);
  } catch (error) {
    toast.error(
      t('sorrySomethingWentWrongWhenUpdatingYourBookingDetailsPleaseTryAgain'),
      { autoClose: false },
    );

    logError(error as Error);
  } finally {
    isSaving.value = false;
  }
};
</script>
