import type {
  BookingAdditionalTax,
  BookingAdditionalTaxDTO,
} from '@/booking/booking-taxes/additional/booking-additional-tax';
import { mapAdditionalTaxFromDTO } from '@/tax/additional/additional-tax.mapper';

export const mapBookingAdditionalTaxFromDTO = (
  bookingAdditionalTaxDTO: BookingAdditionalTaxDTO,
): BookingAdditionalTax => ({
  tax: mapAdditionalTaxFromDTO(bookingAdditionalTaxDTO.tax),
  amount: Number(bookingAdditionalTaxDTO.amount),
});
