<template>
  <div class="flex-grow">
    <AppDateRange
      :from-date="searchStore.stayDates.checkInDate"
      :to-date="searchStore.stayDates.checkOutDate"
    />
  </div>
</template>

<script setup lang="ts">
import { useSearchStore } from '@/search/search.store';
import AppDateRange from '@/ui/app-date-range/AppDateRange.vue';

const searchStore = useSearchStore();
</script>
