import type {
  AdditionalTaxRateMonetaryCharge,
  AdditionalTaxRateMonetaryChargeDTO,
} from '@/tax/additional/rate/charge/monetary/additional-tax-rate-monetary-charge';
import type {
  AdditionalTaxRatePercentageCharge,
  AdditionalTaxRatePercentageChargeDTO,
} from '@/tax/additional/rate/charge/percentage/additional-tax-rate-percentage-charge';

export enum AdditionalTaxRateChargeType {
  Monetary = 'monetary',
  Percentage = 'percentage',
}

export type AdditionalTaxRateCharge =
  | AdditionalTaxRateMonetaryCharge
  | AdditionalTaxRatePercentageCharge;

export type AdditionalTaxRateChargeDTO =
  | AdditionalTaxRateMonetaryChargeDTO
  | AdditionalTaxRatePercentageChargeDTO;
