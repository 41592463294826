<template>
  <div>
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { useProvideAccordionState } from '@/ui/app-accordion/app-accordion.composable';

const isOpen = defineModel<boolean>({ required: true });

useProvideAccordionState(isOpen);
</script>
