<template>
  <AppModal :is-open="isOpen" @close="$emit('close')">
    <AppModalDialog class="sm:max-w-5xl" @close="$emit('close')">
      <template #header>
        <span class="align-middle font-semibold leading-none">
          {{ toTitleCase($t('selectLanguage')) }}
        </span>
      </template>

      <LocaleSelector @select="$emit('close')" />
    </AppModalDialog>
  </AppModal>
</template>

<script setup lang="ts">
import LocaleSelector from '@/locale-selector/LocaleSelector.vue';
import { useLocaleString } from '@/string/locale-string.composable';
import AppModal from '@/ui/app-modal/AppModal.vue';
import AppModalDialog from '@/ui/app-modal/AppModalDialog.vue';

defineProps<{ isOpen: boolean }>();

defineEmits(['close']);

const { toTitleCase } = useLocaleString();
</script>
