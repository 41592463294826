import type { Address, AddressDTO } from '@/address/address';

export const mapAddressFromDTO = (addressDTO: AddressDTO): Address => ({
  line1: addressDTO.line1,
  line2: addressDTO.line2,
  line3: addressDTO.line3,
  postcode: addressDTO.postcode,
  city: addressDTO.city,
  administrativeArea: addressDTO.administrativeArea,
  countryCode: addressDTO.countryCode,
});
