import type { Ref } from 'vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { PaymentGatewayType } from '@/property/payment-gateway/payment-gateway';
import type { Property } from '@/property/property';

export const useStripePreAuthTermsAndConditionsDisplayText = (
  property: Ref<Property>,
) => {
  const { t } = useI18n();

  const paymentGateway = computed(() => property.value.paymentGateway);

  return computed(() =>
    paymentGateway.value.type === PaymentGatewayType.Stripe &&
    paymentGateway.value.isPreAuthEnabled
      ? t('weReserveTheRightToTemporarilyHoldAnAmountPriorToArrival')
      : '',
  );
};
