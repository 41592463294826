import { differenceInMinutes } from 'date-fns';
import type { Booking } from '@/booking/booking';
import {
  createDateObject,
  getCurrentDateObjectInTimezone,
} from '@/date/date.utilities';

// The number of minutes for which a guest is able to modify a booking after it has been created
const GUEST_MODIFICATION_TIME_LIMIT_MINUTES = 60;

export const isBookingCurrentlyWithinGuestModificationTimeLimit = (
  booking: Booking,
): boolean =>
  getMinutesSinceBookingWasCreated(booking) <=
  GUEST_MODIFICATION_TIME_LIMIT_MINUTES;

export const canGuestModifyBooking = (booking: Booking): boolean =>
  !booking.isCancelled &&
  booking.usedExpressCheckout &&
  !booking.hasBeenModifiedByGuest &&
  isBookingCurrentlyWithinGuestModificationTimeLimit(booking);

const getMinutesSinceBookingWasCreated = (booking: Booking): number =>
  differenceInMinutes(
    getCurrentDateObjectInTimezone('Europe/London'),
    createDateObject(booking.creationDateTime),
  );
