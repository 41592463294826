import { computed } from 'vue';
import { useRouter } from 'vue-router';
import {
  PCIB_CARD_CAPTURE_FAILURE_ROUTE,
  PCIB_CARD_CAPTURE_SUCCESS_ROUTE,
} from '@/router';

export const useUrlResolver = () => {
  const router = useRouter();

  const { origin } = location;

  const pcibCardCaptureSuccessUrl = computed(
    () =>
      new URL(
        router.resolve({
          name: PCIB_CARD_CAPTURE_SUCCESS_ROUTE,
          query: {
            cardTokenUrl: '{cardToken}',
            cardType: '{cardType}',
            cardHolderName: '{cardHolderName}',
            cardExpiration: '{expiration}',
          },
        }).href,
        origin,
      ),
  );

  const pcibCardCaptureFailureUrl = computed(
    () =>
      new URL(
        router.resolve({
          name: PCIB_CARD_CAPTURE_FAILURE_ROUTE,
        }).href,
        origin,
      ),
  );

  return { pcibCardCaptureSuccessUrl, pcibCardCaptureFailureUrl };
};
