import { createInjectionState } from '@vueuse/core';
import type { Ref } from 'vue';
import { watch, ref, computed } from 'vue';
import type { PropertyAvailability } from '@/availability/property-availability/property-availability';
import {
  CodeInputStatusType,
  type CodeInputStatus,
} from '@/code/input/code-input-status';
import { CodeResourceType } from '@/code/resource/code-resource';
import type { PrivateRateOverride } from '@/private-rate-override/private-rate-override';
import { sendFetchPropertyPrivateRateOverrideByCodeRequest } from '@/private-rate-override/private-rate-override.api';
import { isPrivateRateOverrideApplicableOnStayDates } from '@/private-rate-override/private-rate-override.utilities';
import type { Promocode } from '@/property/promocode/promocode';
import { fetchPropertyPromocode } from '@/property/promocode/promocode.api';
import { isPromocodeValidOnStayDates } from '@/property/promocode/promocode.utilities';
import type { Property } from '@/property/property';
import type { StayDates } from '@/stay-dates/stay-dates';

const [useProvideCodeInputStore, useMaybeCodeInputStore] = createInjectionState(
  (injectionState: {
    property: Ref<Property>;
    stayDates: Ref<StayDates>;
    propertyAvailability: Ref<PropertyAvailability>;
  }) => {
    const { property, stayDates, propertyAvailability } = injectionState;

    const code = ref('');

    const status = ref<CodeInputStatus>({ type: CodeInputStatusType.Normal });

    const isCodeValid = computed(
      () => status.value.type === CodeInputStatusType.Valid,
    );

    const applyCode = async () => {
      if (code.value.length === 0) {
        return;
      }

      status.value = { type: CodeInputStatusType.Pending };

      const [promocode, privateRateOverride] = await Promise.all([
        fetchPropertyPromocode(code.value, property.value.id),
        sendFetchPropertyPrivateRateOverrideByCodeRequest(
          property.value.id,
          code.value,
        ),
      ]);

      if (promocode) {
        setStatusWithPromocode(promocode);
      } else if (privateRateOverride) {
        setStatusWithPrivateRateOverride(privateRateOverride);
      } else {
        status.value = { type: CodeInputStatusType.Invalid };
      }
    };

    const removeCode = () => {
      code.value = '';
    };

    const setStatusWithPromocode = (promocode: Promocode) => {
      status.value = isPromocodeValidOnStayDates(stayDates.value, promocode)
        ? {
            type: CodeInputStatusType.Valid,
            resource: {
              type: CodeResourceType.Promocode,
              promocode,
            },
          }
        : { type: CodeInputStatusType.Invalid };
    };

    const setStatusWithPrivateRateOverride = (
      privateRateOverride: PrivateRateOverride,
    ) => {
      status.value = isPrivateRateOverrideApplicableOnStayDates(
        privateRateOverride,
        stayDates.value,
        propertyAvailability.value,
      )
        ? {
            type: CodeInputStatusType.Valid,
            resource: {
              type: CodeResourceType.PrivateRateOverride,
              privateRateOverride,
            },
          }
        : { type: CodeInputStatusType.Invalid };
    };

    watch(code, (_, oldCode) => {
      if (oldCode) {
        status.value = { type: CodeInputStatusType.Normal };
      }
    });

    watch(propertyAvailability, () => {
      if (status.value.type !== CodeInputStatusType.Valid) {
        void applyCode();

        return;
      }

      if (status.value.resource.type === CodeResourceType.Promocode) {
        setStatusWithPromocode(status.value.resource.promocode);
      } else {
        setStatusWithPrivateRateOverride(
          status.value.resource.privateRateOverride,
        );
      }
    });

    watch(property, applyCode);

    return {
      property,
      code,
      status,
      isCodeValid,
      applyCode,
      removeCode,
    };
  },
);

const useCodeInputStore = () => {
  const codeInputStore = useMaybeCodeInputStore();

  if (!codeInputStore) {
    throw new Error(
      'Please call `useProvideCodeInputStore` on the appropriate parent component',
    );
  }

  return codeInputStore;
};

export { useCodeInputStore, useProvideCodeInputStore };
