<template>
  <div class="mb-2 text-xl font-bold capitalize">
    {{ $t(booking.isCancelled ? 'extrasCancelled' : 'extrasBooked') }}
  </div>

  <ul>
    <li
      v-for="(bookingExtra, index) in [
        ...mandatoryBookingExtras,
        ...optionalBookingExtras,
      ]"
      :key="index"
      class="mb-5"
      data-test="BookingConfirmationSummaryExtraList__extras"
    >
      <BookingConfirmationSummaryExtra :booking-extra="bookingExtra" />
    </li>

    <li
      v-for="(bookingAdHocSupplement, index) in booking.bookingAdHocSupplements"
      :key="index"
      class="mb-5"
      data-test="BookingConfirmationSummaryExtraList__adHocSupplements"
    >
      <BookingConfirmationSummaryAdHocSupplement
        :booking-ad-hoc-supplement="bookingAdHocSupplement"
      />
    </li>
  </ul>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { partitionBookingExtrasByMandatoryAndOptional } from '@/booking/extra/booking-extra.utilities';
import { useBookingConfirmationStore } from '@/booking-confirmation-page/booking-confirmation.store';
import BookingConfirmationSummaryAdHocSupplement from '@/booking-confirmation-page/summary/extra/BookingConfirmationSummaryAdHocSupplement.vue';
import BookingConfirmationSummaryExtra from '@/booking-confirmation-page/summary/extra/BookingConfirmationSummaryExtra.vue';

const { bookingExtras, booking } = storeToRefs(useBookingConfirmationStore());

const partitionedBookingExtras = computed(() =>
  partitionBookingExtrasByMandatoryAndOptional(bookingExtras.value),
);

const mandatoryBookingExtras = computed(
  () => partitionedBookingExtras.value[0],
);

const optionalBookingExtras = computed(() => partitionedBookingExtras.value[1]);
</script>
