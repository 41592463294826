<template>
  <AppModal class="z-30" :is-open="isOpen" @close="emit('close')">
    <AppModalDialog class="sm:max-w-2xl" @close="emit('close')">
      <template #header>
        <span class="align-middle font-semibold capitalize leading-none">
          {{ $t('changeYourStayDates') }}
        </span>
      </template>

      <UnitSelectionPricePlanMultiDateBookingSelectionWarningBody />

      <template #footer>
        <UnitSelectionPricePlanMultiDateBookingSelectionWarningFooter
          @close="emit('close')"
        />
      </template>
    </AppModalDialog>
  </AppModal>
</template>

<script setup lang="ts">
import AppModal from '@/ui/app-modal/AppModal.vue';
import AppModalDialog from '@/ui/app-modal/AppModalDialog.vue';
import UnitSelectionPricePlanMultiDateBookingSelectionWarningBody from '@/unit-selection-page/price-plan/multi-date-booking-selection-warning/UnitSelectionPricePlanMultiDateBookingSelectionWarningBody.vue';
import UnitSelectionPricePlanMultiDateBookingSelectionWarningFooter from '@/unit-selection-page/price-plan/multi-date-booking-selection-warning/UnitSelectionPricePlanMultiDateBookingSelectionWarningFooter.vue';

defineProps<{
  isOpen: boolean;
}>();

const emit = defineEmits(['close']);
</script>
