import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import type { PropertyAvailability } from '@/availability/property-availability/property-availability';
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import {
  CodeResourceType,
  type CodeResource,
} from '@/code/resource/code-resource';
import type { PrivateRateOverride } from '@/private-rate-override/private-rate-override';
import {
  sendFetchPropertyPrivateRateOverrideByCodeRequest,
  sendFetchWidgetPrimaryPropertyPrivateRateOverrideByCodeRequest,
} from '@/private-rate-override/private-rate-override.api';
import { isPrivateRateOverrideApplicableOnStayDates } from '@/private-rate-override/private-rate-override.utilities';
import type { Promocode } from '@/property/promocode/promocode';
import {
  fetchPropertyPromocode,
  fetchWidgetPrimaryPropertyPromocode,
} from '@/property/promocode/promocode.api';
import { isPromocodeValidOnStayDates } from '@/property/promocode/promocode.utilities';
import { useRoute } from '@/router/route.composable';
import type { StayDates } from '@/stay-dates/stay-dates';

export const useCodeResourceStore = defineStore('code-resource', () => {
  const { widgetIdRouteParam, propertyIdRouteParam, codeQueryParam } =
    useRoute();

  const bookingItineraryStore = useBookingItineraryStore();

  const resource = ref<CodeResource>();

  const promocode = computed(() => resource.value?.promocode);

  const privateRateOverride = computed(
    () => resource.value?.privateRateOverride,
  );

  const initialize = async () => {
    if (!codeQueryParam.value) {
      return;
    }

    const [promocode, privateRateOverride] = await Promise.all(
      propertyIdRouteParam.value
        ? [
            fetchPropertyPromocode(
              codeQueryParam.value,
              propertyIdRouteParam.value,
            ),
            sendFetchPropertyPrivateRateOverrideByCodeRequest(
              propertyIdRouteParam.value,
              codeQueryParam.value,
            ),
          ]
        : [
            fetchWidgetPrimaryPropertyPromocode(
              codeQueryParam.value,
              widgetIdRouteParam.value,
            ),
            sendFetchWidgetPrimaryPropertyPrivateRateOverrideByCodeRequest(
              widgetIdRouteParam.value,
              codeQueryParam.value,
            ),
          ],
    );

    if (promocode) {
      setWithPromocode(codeQueryParam.value, promocode);
    } else if (privateRateOverride) {
      setWithPrivateRateOverride(codeQueryParam.value, privateRateOverride);
    }
  };

  const setWithPromocode = (code: string, promocode: Promocode) => {
    resource.value = {
      code,
      type: CodeResourceType.Promocode,
      promocode,
    };
  };

  const setWithPrivateRateOverride = (
    code: string,
    privateRateOverride: PrivateRateOverride,
  ) => {
    bookingItineraryStore.clearSelectedUnitItinerary();

    resource.value = {
      code,
      type: CodeResourceType.PrivateRateOverride,
      privateRateOverride,
    };
  };

  const clear = () => {
    if (resource.value?.type === CodeResourceType.PrivateRateOverride) {
      bookingItineraryStore.clearSelectedUnitItinerary();
    }

    resource.value = undefined;
  };

  const validate = (
    stayDates: StayDates,
    propertyAvailability: PropertyAvailability,
  ) => {
    if (!resource.value) {
      return;
    }

    const isValid =
      resource.value.type === CodeResourceType.Promocode
        ? isPromocodeValidOnStayDates(stayDates, resource.value.promocode)
        : isPrivateRateOverrideApplicableOnStayDates(
            resource.value.privateRateOverride,
            stayDates,
            propertyAvailability,
          );

    if (!isValid) {
      clear();
    }
  };

  watch(resource, (resource) => {
    codeQueryParam.value = resource?.code;
  });

  return {
    resource,
    promocode,
    privateRateOverride,
    initialize,
    setWithPromocode,
    setWithPrivateRateOverride,
    clear,
    validate,
  };
});
