import { Decimal } from 'decimal.js';
import type { Occupancy } from '@/occupancy/occupancy';
import type { NightlyRates } from '@/rates/nightly-rates/nightly-rates';
import { getTotalRateFromNightlyRates } from '@/rates/nightly-rates/nightly-rates.utilities';
import type { AdditionalTaxRate } from '@/tax/additional/rate/additional-tax-rate';
import {
  AdditionalTaxRateInclusionType,
  AdditionalTaxRateFrequencyType,
} from '@/tax/additional/rate/additional-tax-rate';
import { AdditionalTaxRateBasisType } from '@/tax/additional/rate/basis/additional-tax-rate-basis';
import type { AdditionalTaxRateGuestBasis } from '@/tax/additional/rate/basis/guest/additional-tax-rate-guest-basis';
import { AdditionalTaxRateChargeType } from '@/tax/additional/rate/charge/additional-tax-rate-charge';
import type { AdditionalTaxRateMonetaryCharge } from '@/tax/additional/rate/charge/monetary/additional-tax-rate-monetary-charge';
import type { AdditionalTaxRatePercentageCharge } from '@/tax/additional/rate/charge/percentage/additional-tax-rate-percentage-charge';
import type { AdditionalTaxRateChargeLimit } from '@/tax/additional/rate/charge-limit/additional-tax-rate-charge-limit';
import { AdditionalTaxRateChargeLimitType } from '@/tax/additional/rate/charge-limit/additional-tax-rate-charge-limit';
import type { AdditionalTaxRateMonetaryChargeLimit } from '@/tax/additional/rate/charge-limit/monetary/additional-tax-rate-monetary-charge-limit';
import type { AdditionalTaxRateNightsChargeLimit } from '@/tax/additional/rate/charge-limit/nights/additional-tax-rate-nights-charge-limit';
import { applyTaxPercentageToValue } from '@/tax/tax.utilities';

export const calculateAdditionalTaxRateAmount = (
  {
    charge,
    chargeLimit,
    basis,
    frequencyType,
    inclusionType,
  }: AdditionalTaxRate,
  nightlyRates: NightlyRates,
  numberOfUnits: number,
  occupancies: Occupancy[],
): number => {
  const numberOfNights = nightlyRates.length;

  if (charge.type === AdditionalTaxRateChargeType.Percentage) {
    if (frequencyType === AdditionalTaxRateFrequencyType.Nightly) {
      return calculatePerNightPercentageAdditionalTaxAmount(
        charge,
        chargeLimit,
        inclusionType,
        nightlyRates,
      );
    }

    return calculatePerStayPercentageAdditionalTaxAmount(
      charge,
      chargeLimit,
      inclusionType,
      getTotalRateFromNightlyRates(nightlyRates),
    );
  }

  if (basis.type === AdditionalTaxRateBasisType.Unit) {
    if (frequencyType === AdditionalTaxRateFrequencyType.Nightly) {
      return calculatePerUnitPerNightMonetaryAdditionalTaxAmount(
        charge,
        chargeLimit,
        numberOfUnits,
        numberOfNights,
      );
    }

    return calculatePerUnitPerStayMonetaryAdditionalTaxAmount(
      charge,
      chargeLimit,
      numberOfUnits,
    );
  }

  if (basis.type === AdditionalTaxRateBasisType.Booking) {
    if (frequencyType === AdditionalTaxRateFrequencyType.Nightly) {
      return calculatePerBookingPerNightMonetaryAdditionalTaxAmount(
        charge,
        chargeLimit,
        numberOfNights,
      );
    }

    return calculatePerBookingPerStayMonetaryAdditionalTaxAmount(
      charge,
      chargeLimit,
    );
  }

  if (frequencyType === AdditionalTaxRateFrequencyType.Nightly) {
    return calculatePerGuestPerNightMonetaryAdditionalTaxAmount(
      charge,
      chargeLimit,
      basis,
      numberOfNights,
      occupancies,
    );
  }

  return calculatePerGuestPerStayMonetaryAdditionalTaxAmount(
    charge,
    chargeLimit,
    basis,
    occupancies,
  );
};

export const calculatePerNightPercentageAdditionalTaxAmount = (
  percentageCharge: AdditionalTaxRatePercentageCharge,
  chargeLimit: AdditionalTaxRateChargeLimit | undefined,
  inclusionType: AdditionalTaxRateInclusionType,
  nightlyRates: NightlyRates,
): number => {
  const numberOfTaxableNights = applyChargeLimitIfNightly(
    chargeLimit,
    nightlyRates.length,
  );

  const taxableNightlyRates = nightlyRates.slice(0, numberOfTaxableNights);

  const taxableAmount = getTotalRateFromNightlyRates(taxableNightlyRates);

  return applyChargeLimitIfMonetary(
    chargeLimit,
    applyPercentageCharge(percentageCharge, inclusionType, taxableAmount),
  );
};

export const calculatePerStayPercentageAdditionalTaxAmount = (
  percentageCharge: AdditionalTaxRatePercentageCharge,
  chargeLimit: AdditionalTaxRateChargeLimit | undefined,
  inclusionType: AdditionalTaxRateInclusionType,
  stayAmount: number,
): number =>
  applyChargeLimitIfMonetary(
    chargeLimit,
    applyPercentageCharge(percentageCharge, inclusionType, stayAmount),
  );

export const calculatePerUnitPerNightMonetaryAdditionalTaxAmount = (
  monetaryCharge: AdditionalTaxRateMonetaryCharge,
  chargeLimit: AdditionalTaxRateChargeLimit | undefined,
  numberOfUnits: number,
  numberOfNights: number,
): number => {
  const numberOfTaxableNights = applyChargeLimitIfNightly(
    chargeLimit,
    numberOfNights,
  );

  return applyChargeLimitIfMonetary(
    chargeLimit,
    new Decimal(numberOfUnits)
      .times(numberOfTaxableNights)
      .times(monetaryCharge.amount)
      .toNumber(),
  );
};

export const calculatePerUnitPerStayMonetaryAdditionalTaxAmount = (
  monetaryCharge: AdditionalTaxRateMonetaryCharge,
  chargeLimit: AdditionalTaxRateChargeLimit | undefined,
  numberOfUnits: number,
): number =>
  applyChargeLimitIfMonetary(
    chargeLimit,
    new Decimal(numberOfUnits).times(monetaryCharge.amount).toNumber(),
  );

export const calculatePerBookingPerNightMonetaryAdditionalTaxAmount = (
  monetaryCharge: AdditionalTaxRateMonetaryCharge,
  chargeLimit: AdditionalTaxRateChargeLimit | undefined,
  numberOfNights: number,
): number => {
  const numberOfTaxableNights = applyChargeLimitIfNightly(
    chargeLimit,
    numberOfNights,
  );

  return applyChargeLimitIfMonetary(
    chargeLimit,
    new Decimal(numberOfTaxableNights).times(monetaryCharge.amount).toNumber(),
  );
};

export const calculatePerBookingPerStayMonetaryAdditionalTaxAmount = (
  monetaryCharge: AdditionalTaxRateMonetaryCharge,
  chargeLimit: AdditionalTaxRateChargeLimit | undefined,
): number => applyChargeLimitIfMonetary(chargeLimit, monetaryCharge.amount);

export const calculatePerGuestPerNightMonetaryAdditionalTaxAmount = (
  monetaryCharge: AdditionalTaxRateMonetaryCharge,
  chargeLimit: AdditionalTaxRateChargeLimit | undefined,
  guestBasis: AdditionalTaxRateGuestBasis,
  numberOfNights: number,
  occupancies: Occupancy[],
): number => {
  const numberOfTaxableNights = applyChargeLimitIfNightly(
    chargeLimit,
    numberOfNights,
  );

  const numberOfTaxableGuests = applyGuestBasis(guestBasis, occupancies);

  return applyChargeLimitIfMonetary(
    chargeLimit,
    new Decimal(numberOfTaxableGuests)
      .times(numberOfTaxableNights)
      .times(monetaryCharge.amount)
      .toNumber(),
  );
};

export const calculatePerGuestPerStayMonetaryAdditionalTaxAmount = (
  monetaryCharge: AdditionalTaxRateMonetaryCharge,
  chargeLimit: AdditionalTaxRateChargeLimit | undefined,
  guestBasis: AdditionalTaxRateGuestBasis,
  occupancies: Occupancy[],
): number => {
  const numberOfTaxableGuests = applyGuestBasis(guestBasis, occupancies);

  return applyChargeLimitIfMonetary(
    chargeLimit,
    new Decimal(numberOfTaxableGuests).times(monetaryCharge.amount).toNumber(),
  );
};

const applyPercentageCharge = (
  { percentage }: AdditionalTaxRatePercentageCharge,
  inclusionType: AdditionalTaxRateInclusionType,
  amount: number,
): number =>
  applyTaxPercentageToValue(
    percentage,
    amount,
    inclusionType === AdditionalTaxRateInclusionType.Include,
  );

const applyChargeLimitIfMonetary = (
  chargeLimit: AdditionalTaxRateChargeLimit | undefined,
  amount: number,
): number =>
  chargeLimit?.type === AdditionalTaxRateChargeLimitType.Monetary
    ? applyMonetaryChargeLimit(chargeLimit, amount)
    : amount;

const applyMonetaryChargeLimit = (
  monetaryChargeLimit: AdditionalTaxRateMonetaryChargeLimit,
  amount: number,
): number => Math.min(monetaryChargeLimit.amount, amount);

const applyChargeLimitIfNightly = (
  chargeLimit: AdditionalTaxRateChargeLimit | undefined,
  numberOfNights: number,
): number =>
  chargeLimit?.type === AdditionalTaxRateChargeLimitType.Nights
    ? applyNightlyChargeLimit(chargeLimit, numberOfNights)
    : numberOfNights;

const applyNightlyChargeLimit = (
  nightlyChargeLimit: AdditionalTaxRateNightsChargeLimit,
  numberOfNights: number,
): number => Math.min(nightlyChargeLimit.numberOfNights, numberOfNights);

const applyGuestBasis = (
  { minimumAge }: AdditionalTaxRateGuestBasis,
  occupancies: Occupancy[],
): number =>
  occupancies.reduce(
    (
      currentNumberOfTaxableGuests: number,
      { numberOfAdults, children }: Occupancy,
    ) => {
      const taxableChildren = minimumAge
        ? children.filter(({ age }) => age >= minimumAge)
        : children;

      const numberOfTaxableChildren = taxableChildren.length;

      return (
        currentNumberOfTaxableGuests + numberOfAdults + numberOfTaxableChildren
      );
    },
    0,
  );
