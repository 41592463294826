import { createInjectionState } from '@vueuse/core';
import type { Ref } from 'vue';
import { generateUniqueId } from '@/generator/generator.utilities';

const [useProvideAccordionState, useMaybeAccordionState] = createInjectionState(
  (isOpen: Ref<boolean>) => {
    const id = generateUniqueId();

    return {
      isOpen,
      buttonId: `accordion-button-${id}`,
      panelId: `accordion-panel-${id}`,
    };
  },
);

const useAccordionState = () => {
  const state = useMaybeAccordionState();

  if (!state) {
    throw new Error('Accordion state has not been provided');
  }

  return state;
};

export { useAccordionState, useProvideAccordionState };
