import type { StripeError } from '@stripe/stripe-js';
import type { BookingCreateProcessingError } from '@/booking/create/error/processing/booking-create-processing-error';

export enum BookingCreateErrorType {
  ProcessingError = 'processing_error',
  StripeCardError = 'stripe_card_error',
  SagePayCardError = 'sage_pay_card_error',
}

export type BookingCreateError =
  | {
      type: BookingCreateErrorType.ProcessingError;
      error: BookingCreateProcessingError;
    }
  | {
      type: BookingCreateErrorType.StripeCardError;
      error: StripeError;
    }
  | {
      type: BookingCreateErrorType.SagePayCardError;
      sagePayCode: string;
    };
