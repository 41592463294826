import type {
  MandatoryDepositPolicy,
  MandatoryDepositPolicyDTO,
} from '@/property/booking-policy/deposit-policy/mandatory/mandatory-deposit-policy';
import type {
  NoDepositPolicy,
  NoDepositPolicyDTO,
} from '@/property/booking-policy/deposit-policy/no/no-deposit-policy';

export enum DepositPolicyType {
  Mandatory = 'mandatory',
  No = 'no',
}

export type DepositPolicy = MandatoryDepositPolicy | NoDepositPolicy;

export type DepositPolicyDTO = MandatoryDepositPolicyDTO | NoDepositPolicyDTO;
