import { Decimal } from 'decimal.js';
import type { BookingVat } from '@/booking/booking-taxes/vat/booking-vat';
import { applyTaxPercentageToValue } from '@/tax/tax.utilities';
import type { TaxableExtra } from '@/tax/taxable-extra/taxable-extra';
import { calculateTaxableExtrasVatAmounts } from '@/tax/taxable-extra/taxable-extra.utilities';
import type { Vat } from '@/tax/vat/vat';

export const createBookingVat = (
  vat: Vat,
  totalUnitRate: number,
  taxableExtras: TaxableExtra[],
): BookingVat => {
  const unitVatAmount = applyTaxPercentageToValue(
    vat.percentage,
    totalUnitRate,
    true,
  );

  const { included: extraIncludedVatAmount, excluded: extraExcludedVatAmount } =
    calculateTaxableExtrasVatAmounts(taxableExtras);

  return {
    vat,
    includedAmount: Decimal.add(
      unitVatAmount,
      extraIncludedVatAmount,
    ).toNumber(),
    excludedAmount: extraExcludedVatAmount,
  };
};
