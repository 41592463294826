import type { Ref } from 'vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import type { Property } from '@/property/property';
import {
  smokingPolicyAllowsSmokingInAllAreas,
  smokingPolicyDisallowsSmokingInAllAreas,
} from '@/property/smoking-policy/utilities/smoking-policy.utilities';
import { useUnitType } from '@/property/unit/type/unit-type.composable';

export const useSmokingPolicyTermsAndConditionsDisplayText = (
  property: Ref<Property>,
) => {
  const { t, locale } = useI18n();

  const unitType = computed(() => property.value.unitType);

  const smokingPolicy = computed(() => property.value.smokingPolicy);

  const { unitTypeNamePlural, unitTypeGender } = useUnitType(unitType);

  const smokingAreasPartialDisplayTexts = computed(() => {
    const partialDisplayTexts = [];

    if (smokingPolicy.value.isAllowedInOpenAir) {
      partialDisplayTexts.push(t('someOfTheOpenAirAreas'));
    }

    if (smokingPolicy.value.isAllowedInPublicAreas) {
      partialDisplayTexts.push(t('someOfThePublicAreas'));
    }

    if (smokingPolicy.value.isAllowedInRooms) {
      partialDisplayTexts.push(
        t('someOfTheUnits', {
          unitTypeName: unitTypeNamePlural.value,
          unitTypeGender: unitTypeGender.value,
        }),
      );
    }

    return partialDisplayTexts;
  });

  const smokingAreasDisplayText = computed(() => {
    const listFormatter = new Intl.ListFormat(locale.value, {
      style: 'long',
      type: 'conjunction',
    });

    return listFormatter.format(smokingAreasPartialDisplayTexts.value);
  });

  return computed(() => {
    if (smokingPolicyDisallowsSmokingInAllAreas(smokingPolicy.value)) {
      return t('thereIsNoSmokingPermittedAnywhereWithinTheProperty');
    }

    if (smokingPolicyAllowsSmokingInAllAreas(smokingPolicy.value)) {
      return t('smokingIsPermittedInTheProperty');
    }

    return t('smokingIsAllowedInAreas', {
      areas: smokingAreasDisplayText.value,
    });
  });
};
