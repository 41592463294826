<template>
  <button
    class="inline-flex max-w-full items-center text-left focus-visible:rounded-sm focus-visible:outline focus-visible:outline-1 focus-visible:outline-offset-2 focus-visible:outline-inherit disabled:text-neutral-300"
    :class="variant === 'underlined' ? 'underline' : 'hover:underline'"
    :disabled="disabled"
    :aria-label="label ?? text"
    @click="(event) => $emit('click', event)"
  >
    <span v-if="!!slots['icon']" class="mr-2">
      <slot name="icon"></slot>
    </span>

    <div class="truncate">{{ text }}</div>
  </button>
</template>

<script setup lang="ts">
import { useSlots } from 'vue';

type Variant = 'basic' | 'underlined';

const {
  text,
  variant = 'underlined',
  disabled = false,
  label,
} = defineProps<{
  text: string;
  variant?: Variant;
  disabled?: boolean;
  label?: string;
}>();

defineEmits(['click']);

const slots = useSlots();
</script>
