import type {
  AdditionalTaxRatePercentageCharge,
  AdditionalTaxRatePercentageChargeDTO,
} from '@/tax/additional/rate/charge/percentage/additional-tax-rate-percentage-charge';

export const mapAdditionalTaxRatePercentageChargeFromDTO = (
  additionalTaxRatePercentageChargeDTO: AdditionalTaxRatePercentageChargeDTO,
): AdditionalTaxRatePercentageCharge => ({
  type: additionalTaxRatePercentageChargeDTO.type,
  percentage: Number(additionalTaxRatePercentageChargeDTO.percentage),
});
