<template>
  <AppModal class="z-30" :is-open="isOpen" @close="emit('close')">
    <AppModalDialog class="sm:max-w-2xl" @close="emit('close')">
      <template #header>
        <UnitSelectionPricePlanNonFittingSearchOccupancyWarningHeader />
      </template>

      <UnitSelectionPricePlanNonFittingSearchOccupancyWarningBody
        :price-plan="pricePlan"
      />

      <OccupancySelectorBody class="mt-6" />

      <template #footer>
        <OccupancySelectorFooter
          :search-button-icon="IconSearch"
          :search-button-text="capitalize($t('search'))"
          @submit="updateUnitSelectionSearchOccupancies"
        />
      </template>
    </AppModalDialog>
  </AppModal>
</template>

<script setup lang="ts">
import { IconSearch } from '@tabler/icons-vue';
import { useOccupancySelectorStore } from '@/occupancy-selector/occupancy-selector.store';
import OccupancySelectorBody from '@/occupancy-selector/OccupancySelectorBody.vue';
import OccupancySelectorFooter from '@/occupancy-selector/OccupancySelectorFooter.vue';
import type { PricePlan } from '@/price-plan/price-plan';
import { useLocaleString } from '@/string/locale-string.composable';
import AppModal from '@/ui/app-modal/AppModal.vue';
import AppModalDialog from '@/ui/app-modal/AppModalDialog.vue';
import UnitSelectionPricePlanNonFittingSearchOccupancyWarningBody from '@/unit-selection-page/price-plan/non-fitting-search-occupancy-warning/body/UnitSelectionPricePlanNonFittingSearchOccupancyWarningBody.vue';
import UnitSelectionPricePlanNonFittingSearchOccupancyWarningHeader from '@/unit-selection-page/price-plan/non-fitting-search-occupancy-warning/header/UnitSelectionPricePlanNonFittingSearchOccupancyWarningHeader.vue';
import { useUnitSelectionStore } from '@/unit-selection-page/unit-selection.store';

const props = defineProps<{ isOpen: boolean; pricePlan: PricePlan }>();

const emit = defineEmits(['close']);

const { updateOccupancies } = useUnitSelectionStore();

const { occupancies } = useOccupancySelectorStore();

const { capitalize } = useLocaleString();

const updateUnitSelectionSearchOccupancies = () => {
  void updateOccupancies(occupancies.value, props.pricePlan.unit.id);

  emit('close');
};
</script>
