<template>
  <footer class="flex w-full flex-col gap-2 md:flex-row md:items-center">
    <UnitSelectionPricePlanScarcityFlag
      :class="{ 'animate-shake': shouldShakeFlag }"
      :price-plan="pricePlan"
      @animationend="shouldShakeFlag = false"
    />

    <UnitSelectionPricePlanQuantitySelectorSpinner
      :price-plan="pricePlan"
      @no-units-remaining="shouldShakeFlag = true"
    />
  </footer>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import type { PricePlan } from '@/price-plan/price-plan';
import UnitSelectionPricePlanQuantitySelectorSpinner from '@/unit-selection-page/price-plan/quantity-selector-spinner/UnitSelectionPricePlanQuantitySelectorSpinner.vue';
import UnitSelectionPricePlanScarcityFlag from '@/unit-selection-page/price-plan/scarcity-flag/UnitSelectionPricePlanScarcityFlag.vue';

defineProps<{
  pricePlan: PricePlan;
}>();

const shouldShakeFlag = ref(false);
</script>
