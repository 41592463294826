import { generateUniqueId } from '@/generator/generator.utilities';
import type { Occupancy } from '@/occupancy/occupancy';
import {
  createMealPricePlans,
  createStandardMealPricePlans,
} from '@/price-plan/meal-price-plan/meal-price-plan.utilities';
import { PricePlanVariantType } from '@/price-plan/price-plan';
import { calculatePricePlanOccupancy } from '@/price-plan/price-plan.utilities';
import type {
  DiscountedUnitPricePlanVariant,
  PackageUnitPricePlanVariant,
  PrivateUnitPricePlanVariant,
  StandardUnitPricePlanVariant,
  UnitPricePlan,
} from '@/price-plan/unit-price-plan/unit-price-plan';
import { PromocodeType } from '@/property/promocode/promocode';
import { getTotalRateFromNightlyRates } from '@/rates/nightly-rates/nightly-rates.utilities';
import type {
  NonPackageOfferRates,
  PackageOfferRates,
} from '@/rates/offer-rates/offer-rates';
import {
  areOfferRatesCheaper,
  getCheapestNonRefundableNonPackageOfferRates,
  getCheapestRefundableNonPackageOfferRates,
  getPackageOffersRates,
} from '@/rates/offer-rates/offer-rates.utilities';
import type { UnitRates } from '@/rates/unit-rates/unit-rates';

/**
 * From the given unit rates, we should try to return the following price plans:
 * - A price plan representing the cheapest, refundable, non-package offer that applies
 * to the unit. If none exists, use the standard rate of the unit instead.
 * - A price plan representing the cheapest, non-refundable, non-package offer that
 * applies to the unit. If none exists, or the rate is not actually cheaper than the
 * refundable price plan, just omit it.
 * - Price plans for each package offer that applies to the unit.
 * - A price plan for any private rates that apply to the unit.
 */
export const createUnitPricePlans = (unitRates: UnitRates): UnitPricePlan[] => {
  const pricePlanOccupancy = calculatePricePlanOccupancy(
    unitRates.occupancy,
    unitRates.unit.occupancyLimits,
  );

  return createUnitPricePlansForOccupancy(pricePlanOccupancy, unitRates);
};

const createUnitPricePlansForOccupancy = (
  pricePlanOccupancy: Occupancy,
  unitRates: UnitRates,
): UnitPricePlan[] => {
  const pricePlans: UnitPricePlan[] = [];

  const cheapestRefundableNonPackageOfferRates =
    getCheapestRefundableNonPackageOfferRates(unitRates.offersRates);

  const cheapestNonRefundableNonPackageOfferRates =
    getCheapestNonRefundableNonPackageOfferRates(unitRates.offersRates);

  const packageOffersRates = getPackageOffersRates(unitRates.offersRates);

  if (cheapestRefundableNonPackageOfferRates) {
    pricePlans.push(
      createDiscountedUnitPricePlan(
        pricePlanOccupancy,
        unitRates,
        cheapestRefundableNonPackageOfferRates,
      ),
    );

    if (
      cheapestNonRefundableNonPackageOfferRates &&
      areOfferRatesCheaper(
        cheapestNonRefundableNonPackageOfferRates,
        cheapestRefundableNonPackageOfferRates,
      )
    ) {
      pricePlans.push(
        createDiscountedUnitPricePlan(
          pricePlanOccupancy,
          unitRates,
          cheapestNonRefundableNonPackageOfferRates,
        ),
      );
    }
  } else {
    pricePlans.push(createStandardUnitPricePlan(pricePlanOccupancy, unitRates));

    if (cheapestNonRefundableNonPackageOfferRates) {
      pricePlans.push(
        createDiscountedUnitPricePlan(
          pricePlanOccupancy,
          unitRates,
          cheapestNonRefundableNonPackageOfferRates,
        ),
      );
    }
  }

  pricePlans.push(
    ...createPackageUnitPricePlans(
      pricePlanOccupancy,
      unitRates,
      packageOffersRates,
    ),
  );

  const privatePricePlan = createPrivateUnitPricePlan(
    pricePlanOccupancy,
    unitRates,
  );

  if (privatePricePlan) {
    pricePlans.push(privatePricePlan);
  }

  return pricePlans;
};

const createStandardUnitPricePlan = (
  pricePlanOccupancy: Occupancy,
  {
    unit,
    occupancy,
    nightlyRates,
    mealsRates,
    promocodeRates,
    includedMeals,
  }: UnitRates,
): StandardUnitPricePlanVariant => ({
  id: generateUniqueId(),
  type: PricePlanVariantType.Standard,
  preDiscountRate:
    promocodeRates &&
    promocodeRates.promocode.promocodeType !== PromocodeType.Descriptive
      ? getTotalRateFromNightlyRates(nightlyRates)
      : undefined,
  rate: getTotalRateFromNightlyRates(
    promocodeRates?.nightlyRates ?? nightlyRates,
  ),
  unit,
  searchOccupancy: occupancy,
  occupancy: pricePlanOccupancy,
  promocode: promocodeRates?.promocode,
  mealPricePlans: createStandardMealPricePlans(mealsRates),
  includedMeals,
});

const createDiscountedUnitPricePlan = (
  pricePlanOccupancy: Occupancy,
  { unit, occupancy, nightlyRates, mealsRates, includedMeals }: UnitRates,
  nonPackageOfferRates: NonPackageOfferRates,
): DiscountedUnitPricePlanVariant => ({
  id: generateUniqueId(),
  type: PricePlanVariantType.Discounted,
  rate: getTotalRateFromNightlyRates(
    nonPackageOfferRates.promocodeRates?.nightlyRates ??
      nonPackageOfferRates.nightlyRates,
  ),
  unit,
  searchOccupancy: occupancy,
  occupancy: pricePlanOccupancy,
  promocode: nonPackageOfferRates.promocodeRates?.promocode,
  preDiscountRate: getTotalRateFromNightlyRates(nightlyRates),
  offer: nonPackageOfferRates.offer,
  mealPricePlans: createMealPricePlans(
    mealsRates,
    nonPackageOfferRates.mealsRates,
  ),
  includedMeals,
});

const createPackageUnitPricePlans = (
  pricePlanOccupancy: Occupancy,
  unitRates: UnitRates,
  packageOffersRates: PackageOfferRates[],
): PackageUnitPricePlanVariant[] =>
  packageOffersRates.map((packageOfferRates) =>
    createPackageUnitPricePlan(
      pricePlanOccupancy,
      unitRates,
      packageOfferRates,
    ),
  );

const createPackageUnitPricePlan = (
  pricePlanOccupancy: Occupancy,
  { unit, occupancy }: UnitRates,
  packageOfferRates: PackageOfferRates,
): PackageUnitPricePlanVariant => ({
  id: generateUniqueId(),
  type: PricePlanVariantType.Package,
  rate: getTotalRateFromNightlyRates(packageOfferRates.nightlyRates),
  unit,
  searchOccupancy: occupancy,
  occupancy: pricePlanOccupancy,
  offer: packageOfferRates.offer,
});

const createPrivateUnitPricePlan = (
  pricePlanOccupancy: Occupancy,
  { unit, occupancy, privateRates }: UnitRates,
): PrivateUnitPricePlanVariant | undefined =>
  privateRates
    ? {
        id: generateUniqueId(),
        type: PricePlanVariantType.Private,
        rate: getTotalRateFromNightlyRates(privateRates.nightlyRates),
        unit,
        searchOccupancy: occupancy,
        occupancy: pricePlanOccupancy,
        privateRateOverride: privateRates.privateRateOverride,
      }
    : undefined;
