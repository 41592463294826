export enum WayToSellAdjustmentType {
  MonetarySurcharge = 1,
  MonetaryDiscount = 2,
  PercentageSurcharge = 3,
  PercentageDiscount = 4,
}

export interface WayToSellAdjustment {
  type: WayToSellAdjustmentType;
  /**
   * Note that adjustment amount uses the same scale for monetary and
   * percentage discounts. Meaning the adjustment amount for 25%
   * would be 25.
   */
  adjustmentAmount: number;
}

export interface WayToSellAdjustmentDTO
  extends Omit<WayToSellAdjustment, 'adjustmentAmount'> {
  adjustmentAmount: string;
}
