import { mapPetsPolicyChargeFromDTO } from '@/property/pets-policy/charge/pets-policy-charge.mapper';
import type {
  PetsPolicy,
  PetsPolicyDTO,
} from '@/property/pets-policy/pets-policy';
import { PetsPolicyType } from '@/property/pets-policy/pets-policy';

export const mapPetsPolicyFromDTO = (
  petsPolicyDTO: PetsPolicyDTO,
): PetsPolicy => {
  const { policyType } = petsPolicyDTO;

  switch (policyType) {
    case PetsPolicyType.Allowed:
      return {
        policyType,
        charge: mapPetsPolicyChargeFromDTO(petsPolicyDTO.charge),
      };
    case PetsPolicyType.Disallowed:
      return { policyType };
  }
};
