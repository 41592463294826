<template>
  <MenuButton
    v-slot="{ open }"
    class="flex pl-2 focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-slate-800"
  >
    <div class="mr-2 whitespace-nowrap font-semibold">
      {{ bookingStageStore.currentStageNumber }} /
      {{ bookingStageStore.totalNumberOfStages }}
    </div>

    <IconChevronDown
      :size="22"
      class="transition-transform duration-500"
      :class="{ 'rotate-180': open }"
    />
  </MenuButton>
</template>

<script setup lang="ts">
import { MenuButton } from '@headlessui/vue';
import { IconChevronDown } from '@tabler/icons-vue';
import { useBookingStageStore } from '@/booking-stage/booking-stage.store';

const bookingStageStore = useBookingStageStore();
</script>
