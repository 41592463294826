<template>
  <div class="break-anywhere capitalize">
    {{ $t('unitRate', { unitTypeName }) }}
    <span v-if="unitRateTag" class="pl-1 text-neutral-500">
      ({{ unitRateTag }})
    </span>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { UnitItineraryItem } from '@/booking-itinerary/booking-itinerary';
import { useUnitItineraryItemStore } from '@/booking-itinerary/unit-itinerary-item/store/unit-itinerary-item.store';
import { OfferType } from '@/property/offer/offer';
import { UNIT_TYPE_NAME_BED } from '@/property/unit/type/unit-type';
import { useUnitType } from '@/property/unit/type/unit-type.composable';
import { useUnit } from '@/property/unit/unit.composable';
import { useSearchStore } from '@/search/search.store';

const props = defineProps<{
  unitItineraryItem: UnitItineraryItem;
}>();

const searchStore = useSearchStore();
const unitItineraryItemStore = useUnitItineraryItemStore(
  props.unitItineraryItem.id,
);

const { mealServiceTypeDisplayText } = useUnit(unitItineraryItemStore.unit);
const unitRateTag =
  unitItineraryItemStore.offer?.offerType === OfferType.Package
    ? unitItineraryItemStore.offer.name
    : unitItineraryItemStore.includedMeals.length === 0
    ? mealServiceTypeDisplayText
    : undefined;

const { unitTypeName } = useUnitType(
  computed(() =>
    unitItineraryItemStore.unit.type.name === UNIT_TYPE_NAME_BED
      ? unitItineraryItemStore.unit.type
      : searchStore.activeProperty.unitType,
  ),
);
</script>
