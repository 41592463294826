<template>
  <div class="flex items-center justify-between">
    <div class="mr-2 font-medium capitalize">{{ $t('adults') }}</div>

    <div class="flex items-center text-center">
      <AppAdjuster
        :is-incrementing="false"
        :disabled="!canDecrementNumberOfAdults(unitOccupancy.id)"
        :label="toTitleCase($t('removeAdult'))"
        @click="decrementNumberOfAdults(unitOccupancy.id)"
      />

      <span class="w-12" :aria-label="$t('numberOfSelectedAdults')">
        {{ unitOccupancy.numberOfAdults }}
      </span>

      <AppAdjuster
        is-incrementing
        :disabled="false"
        :label="toTitleCase($t('addAdult'))"
        @click="incrementNumberOfAdults(unitOccupancy.id)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useOccupancySelectorStore } from '@/occupancy-selector/occupancy-selector.store';
import type { OccupancySelectorUnitOccupancy } from '@/occupancy-selector/unit-occupancy/occupancy-selector-unit-occupancy';
import { useLocaleString } from '@/string/locale-string.composable';
import AppAdjuster from '@/ui/app-adjuster/AppAdjuster.vue';

defineProps<{
  unitOccupancy: OccupancySelectorUnitOccupancy;
}>();

const { toTitleCase } = useLocaleString();

const {
  incrementNumberOfAdults,
  decrementNumberOfAdults,
  canDecrementNumberOfAdults,
} = useOccupancySelectorStore();
</script>
