<template>
  <AppPrimaryButton
    class="w-full !border-none !bg-[#5bc0de] px-12 py-3 print:hidden md:w-auto"
    style="color: black"
    :button-text="t('messageUs')"
    :icon="IconMessageCircle2"
    :is-loading="isLoading"
    @click="onClick"
  />
</template>
<script setup lang="ts">
import { IconMessageCircle2 } from '@tabler/icons-vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue3-toastify';
import { useBookingConfirmationStore } from '@/booking-confirmation-page/booking-confirmation.store';
import { logError } from '@/log/log.utilities';
import AppPrimaryButton from '@/ui/app-primary-button/AppPrimaryButton.vue';

const bookingConfirmationStore = useBookingConfirmationStore();

const isLoading = ref(false);

const { t } = useI18n();

const onClick = async () => {
  isLoading.value = true;

  try {
    window.location.href = await bookingConfirmationStore.fetchMessagingLink();
  } catch (error) {
    toast.error(t('sorrySomethingWentWrongWhileTryingToMessageTheHost'), {
      autoClose: false,
    });

    logError(error as Error);
  }

  isLoading.value = false;
};
</script>
