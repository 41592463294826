import { AdditionalTaxRateChargeLimitType } from '@/tax/additional/rate/charge-limit/additional-tax-rate-charge-limit';
import type {
  AdditionalTaxRateMonetaryChargeLimit,
  AdditionalTaxRateMonetaryChargeLimitDTO,
} from '@/tax/additional/rate/charge-limit/monetary/additional-tax-rate-monetary-charge-limit';

export const mapAdditionalTaxRateMonetaryChargeLimitFromDTO = (
  additionalTaxRateMonetaryChargeLimitDTO: AdditionalTaxRateMonetaryChargeLimitDTO,
): AdditionalTaxRateMonetaryChargeLimit => ({
  type: AdditionalTaxRateChargeLimitType.Monetary,
  amount: Number(additionalTaxRateMonetaryChargeLimitDTO.amount),
});
