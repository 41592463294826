<template>
  <AppImageCarouselImageError v-if="hasErrored" />

  <div v-else>
    <img
      v-if="shouldLoadPlaceholderImage"
      :src="generateCloudinaryURL(true)"
      alt=""
      class="absolute inset-0 z-[1] h-full w-full"
    />

    <img
      v-if="shouldLoadFullScaleImage"
      :src="generateCloudinaryURL(false)"
      alt=""
      class="absolute inset-0 z-[2] h-full w-full object-cover"
      @error="hasErrored = true"
    />
  </div>
</template>

<script setup lang="ts">
import { format } from '@cloudinary/url-gen/actions/delivery';
import { blur } from '@cloudinary/url-gen/actions/effect';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { auto } from '@cloudinary/url-gen/qualifiers/format';
import { ref } from 'vue';
import { cloudinary } from '@/cloudinary';
import AppImageCarouselImageError from '@/ui/app-image-carousel/AppImageCarouselImageError.vue';

const props = defineProps<{
  url: string;
  width: number;
  shouldLoadPlaceholderImage: boolean;
  shouldLoadFullScaleImage: boolean;
}>();

const hasErrored = ref(false);

const generateCloudinaryURL = (isPlaceholder: boolean): string => {
  const image = cloudinary
    .image(props.url)
    .resize(fill().width(props.width).aspectRatio('16:9'))
    .setDeliveryType('fetch')
    .delivery(format(auto()));

  if (isPlaceholder) {
    image.effect(blur(2000));
  }

  return image.toURL();
};
</script>
