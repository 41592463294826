<template>
  <div class="text-sm first-letter:capitalize">
    {{
      $t('includesNamedTaxOfAmount', {
        taxName: bookingVat.vat.name,
        amount: $n(bookingVat.includedAmount, {
          style: 'currency',
          currency: searchStore.activeProperty.currencyCode,
        }),
      })
    }}
  </div>
</template>

<script setup lang="ts">
import type { BookingVat } from '@/booking/booking-taxes/vat/booking-vat';
import { useSearchStore } from '@/search/search.store';

defineProps<{ bookingVat: BookingVat }>();

const searchStore = useSearchStore();
</script>
