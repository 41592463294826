import type {
  AdditionalTaxRateChargeLimit,
  AdditionalTaxRateChargeLimitDTO,
} from '@/tax/additional/rate/charge-limit/additional-tax-rate-charge-limit';
import { AdditionalTaxRateChargeLimitType } from '@/tax/additional/rate/charge-limit/additional-tax-rate-charge-limit';
import { mapAdditionalTaxRateMonetaryChargeLimitFromDTO } from '@/tax/additional/rate/charge-limit/monetary/additional-tax-rate-monetary-charge-limit.mapper';
import { mapAdditionalTaxRateNightsChargeLimitFromDTO } from '@/tax/additional/rate/charge-limit/nights/additional-tax-rate-nights-charge-limit.mapper';

export const mapAdditionalTaxRateChargeLimitFromDTO = (
  additionalTaxRateChargeLimitDTO: AdditionalTaxRateChargeLimitDTO,
): AdditionalTaxRateChargeLimit => {
  const { type } = additionalTaxRateChargeLimitDTO;

  switch (type) {
    case AdditionalTaxRateChargeLimitType.Monetary:
      return mapAdditionalTaxRateMonetaryChargeLimitFromDTO(
        additionalTaxRateChargeLimitDTO,
      );
    case AdditionalTaxRateChargeLimitType.Nights:
      return mapAdditionalTaxRateNightsChargeLimitFromDTO(
        additionalTaxRateChargeLimitDTO,
      );
    default:
      throw new Error(
        `Unexpected additional tax rate charge limit type: ${JSON.stringify(
          type,
        )}`,
      );
  }
};
