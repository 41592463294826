import { PropertyUnavailableError } from '@/errors/PropertyUnavailableError';
import type { Property } from '@/property/property';
import type { Widget } from '@/widget/widget';

export const compareWidgetPropertiesByRank = (
  widget: Widget,
  propertyA: Property,
  propertyB: Property,
): number =>
  findWidgetPropertyRankIndexById(widget, propertyA.id) -
  findWidgetPropertyRankIndexById(widget, propertyB.id);

export const findWidgetPropertyById = (
  { properties }: Widget,
  propertyId: number,
): Property | undefined => properties.find(({ id }) => id === propertyId);

export const findWidgetOnlineProperties = ({
  properties,
}: Widget): Property[] =>
  properties.filter(({ widgetSettings: { isOnline } }) => isOnline);

export const findWidgetPrimaryProperty = (
  widget: Widget,
): Property | undefined => {
  const onlineProperties = findWidgetOnlineProperties(widget);

  for (const propertyIds of widget.rankOrderedPropertyIds) {
    for (const property of onlineProperties) {
      if (propertyIds.includes(property.id)) {
        return property;
      }
    }
  }

  return;
};

const findWidgetPropertyRankIndexById = (
  { rankOrderedPropertyIds }: Widget,
  propertyId: number,
): number =>
  rankOrderedPropertyIds.findIndex((propertyIds) =>
    propertyIds.includes(propertyId),
  );

export const findWidgetPropertyByIdOrFail = (
  widget: Widget,
  propertyId: number,
): Property => {
  const property = findWidgetPropertyById(widget, propertyId);

  if (!property) {
    throw new Error(
      `Could not find property ${propertyId} within widget ${widget.id}`,
    );
  }

  return property;
};

export const findWidgetOnlinePropertyByIdOrFail = (
  widget: Widget,
  propertyId: number,
): Property => {
  const property = findWidgetPropertyByIdOrFail(widget, propertyId);

  if (!property.widgetSettings.isOnline) {
    throw new PropertyUnavailableError(
      `Property ${propertyId} is not online within widget ${widget.id}`,
    );
  }

  return property;
};

export const findWidgetPrimaryPropertyOrFail = (widget: Widget): Property => {
  const primaryProperty = findWidgetPrimaryProperty(widget);

  if (!primaryProperty) {
    throw new PropertyUnavailableError(
      `Could not find primary property for widget ${widget.id}`,
    );
  }

  return primaryProperty;
};
