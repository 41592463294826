<template>
  <AppModal :is-open="isOpen" @close="emit('close')">
    <AppModalDialog class="sm:max-w-2xl" @close="emit('close')">
      <template #header>
        <span class="align-middle font-semibold leading-none">
          {{ $t('privacyAndCookies') }}
        </span>
      </template>

      <PrivacyPolicy />

      <template #footer>
        <AppSecondaryButton
          class="ml-auto p-3"
          :button-text="toTitleCase($t('close'))"
          @click="$emit('close')"
        />
      </template>
    </AppModalDialog>
  </AppModal>
</template>

<script setup lang="ts">
import PrivacyPolicy from '@/privacy-policy/PrivacyPolicy.vue';
import { useLocaleString } from '@/string/locale-string.composable';
import AppModal from '@/ui/app-modal/AppModal.vue';
import AppModalDialog from '@/ui/app-modal/AppModalDialog.vue';
import AppSecondaryButton from '@/ui/app-secondary-button/AppSecondaryButton.vue';

defineProps<{ isOpen: boolean }>();

const emit = defineEmits(['close']);

const { toTitleCase } = useLocaleString();
</script>
