import type {
  PropertyThemeHeader,
  PropertyThemeHeaderDTO,
} from '@/property/property-theme/header/property-theme-header';

export const mapPropertyThemeHeaderFromDTO = (
  propertyThemeHeaderDTO: PropertyThemeHeaderDTO,
): PropertyThemeHeader => ({
  url: propertyThemeHeaderDTO.url,
  isOverlayEnabled: propertyThemeHeaderDTO.isOverlayEnabled,
});
