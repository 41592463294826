<template>
  <div>
    <BookingConfirmationSummaryUnitList />

    <BookingConfirmationSummaryExtraList
      v-if="
        bookingExtras.length > 0 || booking.bookingAdHocSupplements.length > 0
      "
    />

    <BookingConfirmationSummaryPromocode
      v-if="promocode"
      :promocode="promocode"
    />

    <BookingConfirmationSummaryPrivateRateOverride
      v-else-if="booking.bookingPrivateRateOverride"
      class="mb-5"
      :booking-private-rate-override="booking.bookingPrivateRateOverride"
    />

    <BookingConfirmationSummarySpecialRequests
      v-if="booking.specialRequests && !booking.isCancelled"
      class="mb-5"
      :special-requests="booking.specialRequests"
    />

    <BookingConfirmationSummaryTotal />
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useBookingConfirmationStore } from '@/booking-confirmation-page/booking-confirmation.store';
import BookingConfirmationSummaryExtraList from '@/booking-confirmation-page/summary/extra/BookingConfirmationSummaryExtraList.vue';
import BookingConfirmationSummaryPrivateRateOverride from '@/booking-confirmation-page/summary/private-rate-override/BookingConfirmationSummaryPrivateRateOverride.vue';
import BookingConfirmationSummaryPromocode from '@/booking-confirmation-page/summary/promocode/BookingConfirmationSummaryPromocode.vue';
import BookingConfirmationSummarySpecialRequests from '@/booking-confirmation-page/summary/special-requests/BookingConfirmationSummarySpecialRequests.vue';
import BookingConfirmationSummaryTotal from '@/booking-confirmation-page/summary/total/BookingConfirmationSummaryTotal.vue';
import BookingConfirmationSummaryUnitList from '@/booking-confirmation-page/summary/unit/BookingConfirmationSummaryUnitList.vue';

const { promocode, bookingExtras, booking } = storeToRefs(
  useBookingConfirmationStore(),
);
</script>
