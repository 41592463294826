import type { PrivateRateOverride } from '@/private-rate-override/private-rate-override';
import {
  applyPrivateRateOverrideToUnitNightlyRates,
  applyPrivateRateOverrideToWayToSellNightlyRates,
} from '@/private-rate-override/private-rate-override.utilities';
import type { NightlyRates } from '@/rates/nightly-rates/nightly-rates';
import type { PrivateRates } from '@/rates/private-rates/private-rates';

export const calculatePrivateRatesOnUnitNightlyRates = (
  privateRateOverride: PrivateRateOverride,
  unitId: number,
  unitNightlyRates: NightlyRates,
): PrivateRates => ({
  privateRateOverride,
  nightlyRates: applyPrivateRateOverrideToUnitNightlyRates(
    privateRateOverride,
    unitNightlyRates,
    unitId,
  ),
});

export const calculatePrivateRatesOnWayToSellNightlyRates = (
  privateRateOverride: PrivateRateOverride,
  wayToSellId: number,
  wayToSellNightlyRates: NightlyRates,
): PrivateRates => ({
  privateRateOverride,
  nightlyRates: applyPrivateRateOverrideToWayToSellNightlyRates(
    privateRateOverride,
    wayToSellNightlyRates,
    wayToSellId,
  ),
});
