import { mapBookingOfferFromDTO } from '@/booking/offer/booking-offer.mapper';
import type {
  ActiveBookingUnit,
  ActiveBookingUnitDTO,
} from '@/booking/unit/active/active-booking-unit';
import { mapBookingUnitMealFromDTO } from '@/booking/unit/meal/booking-unit-meal.mapper';

export const mapActiveBookingUnitFromDTO = (
  activeBookingUnitDTO: ActiveBookingUnitDTO,
): ActiveBookingUnit => ({
  id: activeBookingUnitDTO.id,
  unitId: activeBookingUnitDTO.unitId,
  unitName: activeBookingUnitDTO.unitName,
  checkInDate: activeBookingUnitDTO.checkInDate,
  checkOutDate: activeBookingUnitDTO.checkOutDate,
  rate: Number(activeBookingUnitDTO.rate),
  bookingUnitWayToSell: activeBookingUnitDTO.bookingUnitWayToSell ?? undefined,
  bookingUnitOffer: activeBookingUnitDTO.bookingUnitOffer
    ? mapBookingOfferFromDTO(activeBookingUnitDTO.bookingUnitOffer)
    : undefined,
  occupancy: activeBookingUnitDTO.occupancy,
  bookingUnitMeals: activeBookingUnitDTO.bookingUnitMeals.map(
    mapBookingUnitMealFromDTO,
  ),
  twinDoubleBedOption: activeBookingUnitDTO.twinDoubleBedOption ?? undefined,
  leadGuestName: activeBookingUnitDTO.leadGuestName ?? undefined,
  isNonRefundable: activeBookingUnitDTO.isNonRefundable,
});

export const mapActiveBookingUnitToUpdateDTO = (
  activeBookingUnit: ActiveBookingUnit,
) => ({
  id: activeBookingUnit.id,
  twinDoubleBedOption: activeBookingUnit.twinDoubleBedOption ?? null,
  leadGuestName: activeBookingUnit.leadGuestName ?? null,
});
