<!--
  If PCIB fails to capture a card, this is where we expect them to redirect to within the iframe.
  We'll immediately send a message event back to the parent window, which should be the
  payment page hosting the iframe, indicating that capturing failed.

  We don't render any template here as it's expected that, instead, the parent window will be
  displaying some kind of overlay during this process.
-->

<script setup lang="ts">
import { InternalMessageEventDataType } from '@/message-event/internal/internal-message-event';
import { sendInternalPostMessageEvent } from '@/message-event/internal/internal-message-event.utilities';

sendInternalPostMessageEvent(parent, {
  type: InternalMessageEventDataType.PcibCardCaptureFailure,
});
</script>
