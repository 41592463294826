import type {
  BookingGuest,
  BookingGuestDTO,
} from '@/booking/guest/booking-guest';

export const mapBookingGuestFromDTO = (
  bookingGuestDTO: BookingGuestDTO,
): BookingGuest => ({
  forename: bookingGuestDTO.forename,
  emailAddress: bookingGuestDTO.emailAddress,
});
