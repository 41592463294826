import type { PropertyAvailability } from '@/availability/property-availability/property-availability';
import { findPropertyAvailabilityDailyPropertyAvailabilityByDateOrFail } from '@/availability/property-availability/property-availability.utilities';
import { WayToSellAdjustmentType } from '@/property/way-to-sell/adjustment/way-to-sell-adjustment';
import { applyWayToSellAdjustmentToValue } from '@/property/way-to-sell/adjustment/way-to-sell-adjustment.utilities';
import type { WayToSell } from '@/property/way-to-sell/way-to-sell';
import type { NightlyRates } from '@/rates/nightly-rates/nightly-rates';
import type { StayDates } from '@/stay-dates/stay-dates';
import { getNightsOfStayFromStayDates } from '@/stay-dates/stay-dates.utilities';

export const isWayToSellApplicableOnStayDates = (
  wayToSell: WayToSell,
  propertyAvailability: PropertyAvailability,
  stayDates: StayDates,
): boolean =>
  !isWayToSellClosedOutOnAllDates(
    wayToSell,
    propertyAvailability,
    getNightsOfStayFromStayDates(stayDates),
  );

export const applyWayToSellToNightlyRates = (
  wayToSell: WayToSell,
  nightlyRates: NightlyRates,
  propertyAvailability: PropertyAvailability,
): NightlyRates =>
  nightlyRates.map((nightlyRate) =>
    isWayToSellClosedOutOnDate(
      wayToSell,
      propertyAvailability,
      nightlyRate.date,
    )
      ? nightlyRate
      : {
          ...nightlyRate,
          rate: applyWayToSellAdjustmentToValue(
            wayToSell.adjustment,
            nightlyRate.rate,
          ),
        },
  );

const isWayToSellClosedOutOnAllDates = (
  wayToSell: WayToSell,
  propertyAvailability: PropertyAvailability,
  dates: string[],
): boolean =>
  dates.every((date) =>
    isWayToSellClosedOutOnDate(wayToSell, propertyAvailability, date),
  );

/**
 * A way to sell should be closed out if it provides a discount and the current
 * date is closed to discount ways to sell.
 */
export const isWayToSellClosedOutOnDate = (
  wayToSell: WayToSell,
  propertyAvailability: PropertyAvailability,
  date: string,
): boolean =>
  isWayToSellDiscount(wayToSell) &&
  findPropertyAvailabilityDailyPropertyAvailabilityByDateOrFail(
    propertyAvailability,
    date,
  ).isClosedToDiscountWaysToSell;

const isWayToSellDiscount = ({ adjustment }: WayToSell): boolean =>
  [
    WayToSellAdjustmentType.MonetaryDiscount,
    WayToSellAdjustmentType.PercentageDiscount,
  ].includes(adjustment.type);
