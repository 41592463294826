import type { BookingAdditionalTax } from '@/booking/booking-taxes/additional/booking-additional-tax';
import type { Occupancy } from '@/occupancy/occupancy';
import type { NightlyRates } from '@/rates/nightly-rates/nightly-rates';
import type { AdditionalTax } from '@/tax/additional/additional-tax';
import { calculateAdditionalTaxRateAmount } from '@/tax/additional/rate/additional-tax-rate.utilities';

export const createBookingAdditionalTax = (
  additionalTax: AdditionalTax,
  nightlyRates: NightlyRates,
  numberOfUnits: number,
  occupancies: Occupancy[],
): BookingAdditionalTax => ({
  tax: additionalTax,
  amount: calculateAdditionalTaxRateAmount(
    additionalTax.rate,
    nightlyRates,
    numberOfUnits,
    occupancies,
  ),
});
