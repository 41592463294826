<template>
  <button
    :aria-label="
      $t(isLeftButton ? 'navigateToPreviousMonth' : 'navigateToNextMonth')
    "
    class="group select-none focus-visible:outline-none"
    :class="{ 'text-slate-300': props.isDisabled }"
    :disabled="props.isDisabled"
  >
    <div
      class="flex h-full items-center bg-white transition-colors group-hover:from-neutral-200 group-hover:to-transparent group-focus-visible:shadow-[0_0_0_2px_inset] group-focus-visible:shadow-slate-800"
      :class="
        isLeftButton
          ? 'rounded-tl-xl group-hover:bg-gradient-to-r'
          : 'rounded-tr-xl group-hover:bg-gradient-to-l'
      "
    >
      <Component
        :is="icon"
        class="mx-3 group-active:group-enabled:scale-95"
        :size="28"
      />
    </div>
  </button>
</template>
<script setup lang="ts">
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-vue';

const props = withDefaults(
  defineProps<{
    isLeftButton: boolean;
    isDisabled?: boolean;
  }>(),
  {
    isDisabled: false,
  },
);

const icon = props.isLeftButton ? IconChevronLeft : IconChevronRight;
</script>
