import type { VNode } from 'vue';
import type { MessageContext } from 'vue-i18n';
import { Locale } from '@/i18n/locales';
import type { GrammaticalGender } from '@/i18n/locales/gender/gender';
import type { DeDEMessageFunctions } from '@/i18n/locales/message-functions/de-DE';
import { deDE } from '@/i18n/locales/message-functions/de-DE';
import type { EsESMessageFunctions } from '@/i18n/locales/message-functions/es-ES';
import { esES } from '@/i18n/locales/message-functions/es-ES';
import type { FrFRMessageFunctions } from '@/i18n/locales/message-functions/fr-FR';
import { frFR } from '@/i18n/locales/message-functions/fr-FR';
import type { ItITMessageFunctions } from '@/i18n/locales/message-functions/it-IT';
import { itIT } from '@/i18n/locales/message-functions/it-IT';
import type { PtPTMessageFunctions } from '@/i18n/locales/message-functions/pt-PT';
import { ptPT } from '@/i18n/locales/message-functions/pt-PT';

export interface BasicMessageFunctionArguments<T extends Record<string, string>>
  extends Omit<MessageContext, 'values' | 'type'> {
  values: T;
  type: 'text';
}

export interface ComponentInterpolationMessageFunctionArguments<
  T extends Record<string, VNode[]>,
> extends Omit<MessageContext, 'values' | 'type'> {
  values: T;
  type: 'vnode';
}

export interface TranslationKeyToMessageFunction {
  youHaveSelectedTheLastUnitAvailable: (
    ctx: BasicMessageFunctionArguments<{
      unitTypeName: string;
      unitTypeGender: GrammaticalGender;
    }>,
  ) => string;
  amountOffEachUnit: (
    ctx: BasicMessageFunctionArguments<{
      amount: string;
      unitTypeName: string;
      unitTypeGender: GrammaticalGender;
    }>,
  ) => string;
  pleaseSelectAUnitToContinue: (
    ctx: BasicMessageFunctionArguments<{
      unitTypeName: string;
      unitTypeGender: GrammaticalGender;
    }>,
  ) => string;
  someOfTheUnits: (
    ctx: BasicMessageFunctionArguments<{
      unitTypeName: string;
      unitTypeGender: GrammaticalGender;
    }>,
  ) => string;
  theseUnitsWillBeDiscardedIfYouSwitchToPropertyName: (
    ctx:
      | BasicMessageFunctionArguments<{
          unitTypeName: string;
          unitTypeGender: GrammaticalGender;
          propertyName: string;
        }>
      | ComponentInterpolationMessageFunctionArguments<{
          unitTypeName: VNode[];
          unitTypeGender: VNode[];
          propertyName: VNode[];
        }>,
  ) => string | (string | VNode[])[];
  youAreAboutToRemoveTheLastUnit: (
    ctx: BasicMessageFunctionArguments<{
      unitTypeName: string;
      unitTypeGender: GrammaticalGender;
    }>,
  ) => string;
  youHaveAlreadySelectedUnitsForDifferentStayDates: (
    ctx: BasicMessageFunctionArguments<{
      unitTypeName: string;
      unitTypeGender: GrammaticalGender;
    }>,
  ) => string;
  youHaveAlreadySelectedUnitsForPropertyName: (
    ctx:
      | BasicMessageFunctionArguments<{
          unitTypeName: string;
          unitTypeGender: GrammaticalGender;
          propertyName: string;
        }>
      | ComponentInterpolationMessageFunctionArguments<{
          unitTypeName: VNode[];
          unitTypeGender: VNode[];
          propertyName: VNode[];
        }>,
  ) => string | (string | VNode[])[];
  ifYouRemoveThisPrivateRateYourUnitsWillBeRemovedAndYouWillBeRedirected: (
    ctx: BasicMessageFunctionArguments<{
      unitTypeName: string;
      unitTypeNamePlural: string;
      unitTypeGender: GrammaticalGender;
    }>,
  ) => string;
  unitsBooked: (
    ctx: BasicMessageFunctionArguments<{
      unitTypeName: string;
      unitTypeGender: GrammaticalGender;
    }>,
  ) => string;
}

interface LocaleToMessageFunctions {
  [Locale.deDE]: DeDEMessageFunctions;
  [Locale.esES]: EsESMessageFunctions;
  [Locale.frFR]: FrFRMessageFunctions;
  [Locale.itIT]: ItITMessageFunctions;
  [Locale.ptPT]: PtPTMessageFunctions;
}

export const localeToMessageFunctions: LocaleToMessageFunctions = {
  [Locale.deDE]: deDE,
  [Locale.esES]: esES,
  [Locale.frFR]: frFR,
  [Locale.itIT]: itIT,
  [Locale.ptPT]: ptPT,
};
