import type {
  AdditionalTaxRateCharge,
  AdditionalTaxRateChargeDTO,
} from '@/tax/additional/rate/charge/additional-tax-rate-charge';
import { AdditionalTaxRateChargeType } from '@/tax/additional/rate/charge/additional-tax-rate-charge';
import { mapAdditionalTaxRateMonetaryChargeFromDTO } from '@/tax/additional/rate/charge/monetary/additional-tax-rate-monetary-charge.mapper';
import { mapAdditionalTaxRatePercentageChargeFromDTO } from '@/tax/additional/rate/charge/percentage/additional-tax-rate-percentage-charge.mapper';

export const mapAdditionalTaxRateChargeFromDTO = (
  additionalTaxRateChargeDTO: AdditionalTaxRateChargeDTO,
): AdditionalTaxRateCharge => {
  const { type } = additionalTaxRateChargeDTO;

  switch (type) {
    case AdditionalTaxRateChargeType.Monetary:
      return mapAdditionalTaxRateMonetaryChargeFromDTO(
        additionalTaxRateChargeDTO,
      );
    case AdditionalTaxRateChargeType.Percentage:
      return mapAdditionalTaxRatePercentageChargeFromDTO(
        additionalTaxRateChargeDTO,
      );
    default:
      throw new Error(
        `Unexpected additional tax rate charge type: ${JSON.stringify(type)}`,
      );
  }
};
