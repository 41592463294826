<template>
  <div
    class="flex items-end justify-between sm:items-center"
    data-test="UnitSelectionMealPricePlan"
  >
    <div class="break-anywhere text-base" translate="yes">
      <AppCheckbox
        v-if="!searchStore.isLoading"
        v-model="checkedMeals"
        :input-value="mealPricePlan.meal"
        :label-id="mealPricePlan.id"
        :label-text="mealPricePlan.meal.name"
      />

      <AppSkeletonText v-else class="w-40" />
    </div>

    <div class="ml-2">
      <UnitSelectionPricePlanRate
        :rate="mealPricePlan.rate"
        :pre-discount-rate="preDiscountRate"
        is-meal
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { MealPricePlan } from '@/price-plan/meal-price-plan/meal-price-plan';
import { MealPricePlanVariantType } from '@/price-plan/meal-price-plan/meal-price-plan';
import type { PricePlan } from '@/price-plan/price-plan';
import { useSearchStore } from '@/search/search.store';
import AppCheckbox from '@/ui/app-checkbox/AppCheckbox.vue';
import AppSkeletonText from '@/ui/app-skeleton-text/AppSkeletonText.vue';
import UnitSelectionPricePlanRate from '@/unit-selection-page/price-plan/rate/UnitSelectionPricePlanRate.vue';
import { useUnitSelectionStore } from '@/unit-selection-page/unit-selection.store';

const props = defineProps<{
  mealPricePlan: MealPricePlan;
  pricePlan: PricePlan;
}>();

const searchStore = useSearchStore();
const unitSelectionStore = useUnitSelectionStore();

const checkedMeals = computed({
  get() {
    return (
      unitSelectionStore.pricePlanSelectedMeals.get(props.pricePlan.id) ?? []
    );
  },
  set(value) {
    unitSelectionStore.pricePlanSelectedMeals.set(props.pricePlan.id, value);
  },
});

const preDiscountRate = computed(() =>
  props.mealPricePlan.type === MealPricePlanVariantType.Discounted
    ? props.mealPricePlan.preDiscountRate
    : null,
);
</script>
