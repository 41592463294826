export const getRGBColorFromHex = (hex: string): string => {
  const color = hex.replace('#', '');

  const r = parseInt(color.slice(0, 2), 16);
  const g = parseInt(color.slice(2, 4), 16);
  const b = parseInt(color.slice(4, 6), 16);

  return `${r} ${g} ${b}`;
};

/**
 * "Safely" parses the given JSON text. Rather than throwing an error if the
 * text is not valid JSON, undefined is returned.
 */
export const safeJsonParse = (text: string): unknown => {
  try {
    return JSON.parse(text);
  } catch (error) {
    console.error(`Could not parse text "${text}" in to JSON.`);
    console.error(error);

    return undefined;
  }
};

export const isPhoneNumber = (text: string): boolean =>
  /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/.test(text);
