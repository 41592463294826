import { convertDateToDayOfWeek } from '@/day-of-week/day-of-week.utilities';
import { isDateCurrentlyAllowedByLateBookingThreshold } from '@/property/late-booking-threshold/late-booking-threshold.utilities';
import type { LateBookingThresholds } from '@/property/late-booking-threshold/late-booking-thresholds';

export const isDateCurrentlyAllowedByLateBookingThresholds = (
  date: string,
  timezone: string,
  lateBookingThresholds: LateBookingThresholds,
): boolean =>
  isDateCurrentlyAllowedByLateBookingThreshold(
    date,
    timezone,
    lateBookingThresholds[convertDateToDayOfWeek(date)],
  );
