import { watchDebounced } from '@vueuse/core';
import { defineStore } from 'pinia';
import isEmail from 'validator/es/lib/isEmail';
import { ref } from 'vue';
import { toast } from 'vue3-toastify';
import { captureNewAbandonedCart } from '@/abandoned-cart/abandoned-cart.utilities';
import { createAbandonedCartRecoveryRequestInIdb } from '@/abandoned-cart/recovery-request/abandoned-cart-recovery-request.utilities';
import { EMAIL_MAX_LENGTH } from '@/booking/booking';
import { useExtraItineraryItemStore } from '@/booking-itinerary/extra-itinerary/store/extra-itinerary-item.store';
import { useMandatoryExtraItineraryItemStore } from '@/booking-itinerary/extra-itinerary/store/mandatory-extra-itinerary-item.store';
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import { useUnitItineraryItemStore } from '@/booking-itinerary/unit-itinerary-item/store/unit-itinerary-item.store';
import { useCodeResourceStore } from '@/code/resource/code-resource.store';
import { useGuestStore } from '@/guest/guest.store';
import i18n from '@/i18n';
import { useSearchStore } from '@/search/search.store';
import { useQueryParams } from '@/url/query-params/query-params.composable';
import { useUserEventIdentifier } from '@/user-event/identifier/user-event-identifier.utilities';

const { locale, t } = i18n.global;

export const useAbandonedCartStore = defineStore('abandoned-cart', () => {
  const bookingItineraryStore = useBookingItineraryStore();
  const guestStore = useGuestStore();
  const searchStore = useSearchStore();
  const codeResourceStore = useCodeResourceStore();
  const { abandonedCartRecoveryRequestQueryParam } = useQueryParams();

  const isInitialized = ref(false);

  const initialize = async () => {
    if (isInitialized.value) {
      return;
    }

    watchForUpdates();

    if (abandonedCartRecoveryRequestQueryParam.value) {
      const { id, displayedRate } =
        abandonedCartRecoveryRequestQueryParam.value;
      abandonedCartRecoveryRequestQueryParam.value = undefined;

      if (
        !bookingItineraryStore.hasEmptyUnitItinerary &&
        bookingItineraryStore.totalRate !== displayedRate
      ) {
        toast.info(t('pleaseNoteThePriceOfYourBookingHasBeenUpdated'), {
          autoClose: false,
        });
      }

      await createAbandonedCartRecoveryRequestInIdb(
        id,
        searchStore.activePropertyId,
        searchStore.widget.id,
      );
    }

    isInitialized.value = true;
  };

  const watchForUpdates = () => {
    watchDebounced(
      [
        () => guestStore.guest.emailAddress,
        () => searchStore.activePropertyId,
        () => bookingItineraryStore.serializableBookingItinerary,
      ],
      async ([
        emailAddress,
        activePropertyId,
        serializableBookingItinerary,
      ]) => {
        if (
          !isEmail(emailAddress) ||
          emailAddress.length > EMAIL_MAX_LENGTH ||
          !serializableBookingItinerary?.unitItinerary.length ||
          codeResourceStore.privateRateOverride
        ) {
          return;
        }

        const selectedExtraItinerary =
          bookingItineraryStore.selectedExtraItinerary.map((selectedExtra) => {
            const extraItineraryItem = useExtraItineraryItemStore(
              selectedExtra.extra,
            );

            return {
              id: selectedExtra.extra.id,
              answer: selectedExtra.answer ?? '',
              displayedRate: extraItineraryItem.totalRate,
              numberOfNightsSelected:
                extraItineraryItem.numberOfNightsApplied ?? 1,
              quantity: extraItineraryItem.quantity,
            };
          });

        const mandatoryExtraItinerary =
          bookingItineraryStore.mandatoryExtraItinerary.map(
            (mandatoryExtra) => ({
              id: mandatoryExtra.extra.id,
              answer: mandatoryExtra.answer ?? '',
              displayedRate: useMandatoryExtraItineraryItemStore(
                mandatoryExtra.extra,
              ).totalRate,
            }),
          );

        const unitItinerary = bookingItineraryStore.unitItinerary.map(
          (unitItineraryItem) => ({
            unitId: unitItineraryItem.unitId,
            wayToSellId: unitItineraryItem.wayToSellId,
            offerId: unitItineraryItem.offerId,
            occupancy: unitItineraryItem.occupancy,
            selectedMealTypes: unitItineraryItem.selectedMealTypes,
            leadGuestName: unitItineraryItem.leadGuestName,
            twinDoubleBedOption: unitItineraryItem.twinDoubleBedOption,
            displayedRate: useUnitItineraryItemStore(unitItineraryItem.id)
              .totalRate,
          }),
        );

        void captureNewAbandonedCart(
          {
            emailAddress,
            uniqueUserId: (
              await useUserEventIdentifier(
                searchStore.widget.id,
                activePropertyId,
              )
            ).uniqueUserId,
            name: guestStore.name,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            locale: locale.value,
            itinerary: {
              checkInDate: serializableBookingItinerary.checkInDate,
              checkOutDate: serializableBookingItinerary.checkOutDate,
              checkInTime: serializableBookingItinerary.checkInTime,
              specialRequests: serializableBookingItinerary.specialRequests,
              unitItinerary: unitItinerary,
              selectedExtraItinerary: selectedExtraItinerary,
              mandatoryExtraItinerary: mandatoryExtraItinerary,
              promocodeText: codeResourceStore.promocode?.promocodeText,
              displayedTaxRate: bookingItineraryStore.taxAmount,
              displayedRate: bookingItineraryStore.totalRate,
            },
          },
          searchStore.widget.id,
          activePropertyId,
        );
      },
      {
        debounce: 3000,
        immediate: true,
      },
    );
  };

  return {
    initialize,
  };
});
