import { mapAddressFromDTO } from '@/address/address.mapper';
import { mapImageFromDTO } from '@/image/image.mapper';
import { mapOccupancyFromDTO } from '@/occupancy/occupancy.mapper';
import { mapBookingPoliciesFromDTO } from '@/property/booking-policy/booking-policies/booking-policies.mapper';
import { mapCheckInPolicyFromDTO } from '@/property/check-in-policy/check-in-policy.mapper';
import { mapChildPolicyFromDTO } from '@/property/child-policy/child-policy.mapper';
import { mapExtraFromDTO } from '@/property/extra/extra.mapper';
import { mapFacebookPixelFromDTO } from '@/property/facebook-pixel/facebook-pixel.mapper';
import { mapGoogleAdwordsFromDTO } from '@/property/google/adwords/google-adwords.mapper';
import { mapGoogleAnalyticsFromDTO } from '@/property/google/analytics/google-analytics.mapper';
import { mapGroupsPolicyFromDTO } from '@/property/groups-policy/groups-policy.mapper';
import { mapLateBookingThresholdsFromDTO } from '@/property/late-booking-threshold/late-booking-thresholds.mapper';
import { mapMealFromDTO } from '@/property/meal/meal.mapper';
import { mapOfferFromDTO } from '@/property/offer/offer.mapper';
import { mapParkingPoliciesFromDTO } from '@/property/parking-policy/parking-policies.mapper';
import { mapPaymentGatewayFromDTO } from '@/property/payment-gateway/payment-gateway.mapper';
import { mapPetsPolicyFromDTO } from '@/property/pets-policy/pets-policy.mapper';
import type { Property, PropertyDTO } from '@/property/property';
import { mapPropertyTaxesFromDTO } from '@/property/property-taxes/property-taxes.mapper';
import { mapPropertyThemeFromDTO } from '@/property/property-theme/property-theme.mapper';
import { mapPropertyVanityFromDTO } from '@/property/property-vanity/property-vanity.mapper';
import { mapPropertyWidgetSettingsFromDTO } from '@/property/property-widget-settings/property-widget-settings.mapper';
import { mapSmokingPolicyFromDTO } from '@/property/smoking-policy/smoking-policy.mapper';
import { mapUnitTypeFromName } from '@/property/unit/type/unit-type.mapper';
import { mapUnitFromDTO } from '@/property/unit/unit.mapper';

export const mapPropertyFromDTO = (propertyDTO: PropertyDTO): Property => ({
  id: propertyDTO.id,
  name: propertyDTO.name,
  unitType: mapUnitTypeFromName(propertyDTO.unitTypeName),
  address: mapAddressFromDTO(propertyDTO.address),
  phoneNumber: propertyDTO.phoneNumber,
  emailAddress: propertyDTO.emailAddress,
  currencyCode: propertyDTO.currencyCode,
  timezone: propertyDTO.timezone,
  units: propertyDTO.units.map(mapUnitFromDTO),
  priorityOrderedUnitIds: propertyDTO.priorityOrderedUnitIds,
  meals: propertyDTO.meals.map(mapMealFromDTO),
  offers: propertyDTO.offers.map(mapOfferFromDTO),
  hasAnyPromocodes: propertyDTO.hasAnyPromocodes,
  hasAnyPrivateRateOverrides: propertyDTO.hasAnyPrivateRateOverrides,
  extras: propertyDTO.extras.map(mapExtraFromDTO),
  images: propertyDTO.images.map(mapImageFromDTO),
  mainImageId: propertyDTO.mainImageId ?? undefined,
  priorityOrderedExtraIds: propertyDTO.priorityOrderedExtraIds,
  childPolicy: mapChildPolicyFromDTO(propertyDTO.childPolicy),
  bookingPolicies: mapBookingPoliciesFromDTO(propertyDTO.bookingPolicies),
  smokingPolicy: mapSmokingPolicyFromDTO(propertyDTO.smokingPolicy),
  checkInPolicy: mapCheckInPolicyFromDTO(propertyDTO.checkInPolicy),
  parkingPolicies: mapParkingPoliciesFromDTO(propertyDTO.parkingPolicies),
  groupsPolicy: mapGroupsPolicyFromDTO(propertyDTO.groupsPolicy),
  petsPolicy: mapPetsPolicyFromDTO(propertyDTO.petsPolicy),
  propertyTaxes: mapPropertyTaxesFromDTO(propertyDTO.taxes),
  propertyTheme: mapPropertyThemeFromDTO(propertyDTO.propertyTheme),
  propertyVanity: propertyDTO.propertyVanity
    ? mapPropertyVanityFromDTO(propertyDTO.propertyVanity)
    : undefined,
  paymentGateway: mapPaymentGatewayFromDTO(propertyDTO.paymentGateway),
  widgetSettings: mapPropertyWidgetSettingsFromDTO(propertyDTO.widgetSettings),
  defaultSearchOccupancy: mapOccupancyFromDTO(
    propertyDTO.defaultSearchOccupancy,
  ),
  unitOrderPreference: propertyDTO.unitOrderPreference,
  maxStayLength: propertyDTO.maxStayLength,
  googleAnalytics: propertyDTO.googleAnalytics
    ? mapGoogleAnalyticsFromDTO(propertyDTO.googleAnalytics)
    : undefined,
  googleAdwords: propertyDTO.googleAdwords
    ? mapGoogleAdwordsFromDTO(propertyDTO.googleAdwords)
    : undefined,
  facebookPixel: propertyDTO.facebookPixel
    ? mapFacebookPixelFromDTO(propertyDTO.facebookPixel)
    : undefined,
  extendedTermsAndConditions: propertyDTO.extendedTermsAndConditions,
  lateBookingThresholds: mapLateBookingThresholdsFromDTO(
    propertyDTO.lateBookingThresholds,
  ),
  canUseExpressCheckout: !!propertyDTO.expressCheckout,
});
