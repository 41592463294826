<template>
  <div>
    <PropertyCheckInTimeSelector
      v-model="editableBooking.arrivalTime"
      :property
    />

    <div v-if="checkInNotes" class="mt-2">
      <span class="font-medium capitalize">{{ $t('notes') }}:</span>
      {{ checkInNotes }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useBookingConfirmationStore } from '@/booking-confirmation-page/booking-confirmation.store';
import PropertyCheckInTimeSelector from '@/property/check-in-time-selector/PropertyCheckInTimeSelector.vue';

const bookingConfirmationStore = useBookingConfirmationStore();

const { property, editableBooking } = storeToRefs(bookingConfirmationStore);

const checkInNotes = computed(() => property.value.checkInPolicy.checkInNotes);
</script>
