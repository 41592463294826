import { CardType } from '@/card/card';
import { getLanguageCodeOfLocale } from '@/intl/intl.utilities';

const PCIB_CARD_CAPTURE_BASE_URL =
  'https://service.pcibooking.net/api/payments/capturecard';

enum PcibCardType {
  AmericanExpress = 'AMEX',
  Discover = 'Discover',
  Electron = 'Electron',
  Maestro = 'Maestro',
  Mastercard = 'MasterCard',
  Visa = 'Visa',
}

export const generatePcibCardCaptureIFrameUrl = (
  accessToken: string,
  cardTypes: CardType[],
  locale: string,
  host: string,
  successUrl: string,
  failureUrl: string,
): string => {
  const url = new URL(PCIB_CARD_CAPTURE_BASE_URL);

  const pcibCardTypes = convertCardTypesToPcibCardTypes(cardTypes);

  url.searchParams.set('accessToken', accessToken);
  url.searchParams.set('brand', import.meta.env.VITE_PCIB_BRAND);
  url.searchParams.set('language', getLanguageCodeOfLocale(locale));
  url.searchParams.set('removeBaseCss', 'true');
  url.searchParams.set('css', 'PortalWebCardCapture');
  url.searchParams.set('autoDetectCardType', 'false');
  url.searchParams.set('showOwnerId', 'false');
  url.searchParams.set('cardTypes', pcibCardTypes.join(','));
  url.searchParams.set('submitWithPostMessage', 'true');
  url.searchParams.set('postMessageHost', host);
  url.searchParams.set('cvv', 'true');
  url.searchParams.set('success', successUrl);
  url.searchParams.set('failure', failureUrl);

  return url.toString();
};

/**
 * Converts the given list of card types into 'PCIB' card types. Some card
 * types may be omitted if not supported by PCIB, or combined in to a
 * single PCIB card type.
 */
const convertCardTypesToPcibCardTypes = (
  cardTypes: CardType[],
): PcibCardType[] =>
  Array.from(
    cardTypes.reduce((pcibCardTypes, cardType) => {
      const pcibCardType = mapCardTypeToPcibCardType(cardType);

      if (pcibCardType) {
        pcibCardTypes.add(pcibCardType);
      }

      return pcibCardTypes;
    }, new Set<PcibCardType>()),
  );

const mapCardTypeToPcibCardType = (
  cardType: CardType,
): PcibCardType | undefined => {
  switch (cardType) {
    case CardType.AmericanExpress:
      return PcibCardType.AmericanExpress;
    case CardType.Discover:
      return PcibCardType.Discover;
    case CardType.Electron:
      return PcibCardType.Electron;
    case CardType.Maestro:
      return PcibCardType.Maestro;
    case CardType.Mastercard:
      return PcibCardType.Mastercard;
    case CardType.VisaCredit:
    case CardType.VisaDebit:
      return PcibCardType.Visa;
    default:
      return undefined;
  }
};
