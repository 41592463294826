import type {
  DepositPolicyCharge,
  DepositPolicyChargeDTO,
} from '@/property/booking-policy/deposit-policy/charge/deposit-policy-charge';
import { DepositPolicyChargeType } from '@/property/booking-policy/deposit-policy/charge/deposit-policy-charge';

export const mapDepositPolicyChargeFromDTO = (
  depositPolicyChargeDTO: DepositPolicyChargeDTO,
): DepositPolicyCharge => {
  const { chargeType } = depositPolicyChargeDTO;

  switch (chargeType) {
    case DepositPolicyChargeType.Nightly:
      return {
        chargeType: DepositPolicyChargeType.Nightly,
        numberOfNights: depositPolicyChargeDTO.numberOfNights,
      };
    case DepositPolicyChargeType.Monetary:
      return {
        chargeType: DepositPolicyChargeType.Monetary,
        amount: Number(depositPolicyChargeDTO.amount),
      };
    case DepositPolicyChargeType.Percentage:
      return {
        chargeType: DepositPolicyChargeType.Percentage,
        percentage: Number(depositPolicyChargeDTO.percentage),
      };
    case DepositPolicyChargeType.PerUnitMonetary:
      return {
        chargeType: DepositPolicyChargeType.PerUnitMonetary,
        amountPerUnit: Number(depositPolicyChargeDTO.amountPerUnit),
      };
  }
};
