<template>
  <div class="h-12 w-full md:w-48">
    <AppNumberSelectorSpinner v-model="quantity" :item-name="extra.name" />
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { useExtraItineraryItemStore } from '@/booking-itinerary/extra-itinerary/store/extra-itinerary-item.store';
import type { NonMandatoryExtra } from '@/property/extra/extra';
import AppNumberSelectorSpinner from '@/ui/app-number-selector-spinner/AppNumberSelectorSpinner.vue';

const props = defineProps<{
  extra: NonMandatoryExtra;
}>();

const extraItineraryItemStore = useExtraItineraryItemStore(props.extra);

const quantity = computed({
  get() {
    return extraItineraryItemStore.quantity;
  },
  set(newQuantity) {
    extraItineraryItemStore.quantity = newQuantity;
  },
});
</script>
