import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useExtraItineraryItemStore } from '@/booking-itinerary/extra-itinerary/store/extra-itinerary-item.store';
import type { Extra } from '@/property/extra/extra';
import { ExtraChargeType } from '@/property/extra/extra';
import { useSearchStore } from '@/search/search.store';

export const useExtraPriceDescription = (extra: Extra) => {
  const { t, n } = useI18n();
  const searchStore = useSearchStore();

  const charge = computed(() =>
    n(extra.charge, {
      style: 'currency',
      currency: searchStore.activeProperty.currencyCode,
    }),
  );

  const priceDescriptionTag = computed(() => {
    if (extra.isMandatory) {
      return extra.chargeType === ExtraChargeType.AllNightsOfStay
        ? t('chargePerNight', {
            charge: charge.value,
          })
        : undefined;
    }

    const extraItineraryItemStore = useExtraItineraryItemStore(extra);

    return extra.chargeType === ExtraChargeType.Single
      ? t('quantityTimesChargeEach', {
          quantity: extraItineraryItemStore.quantity,
          charge: charge.value,
        })
      : t(
          'quantityTimesChargeEachForXNights',
          {
            quantity: extraItineraryItemStore.quantity,
            charge: charge.value,
            numberOfNights: extraItineraryItemStore.numberOfNightsApplied,
          },
          extraItineraryItemStore.numberOfNightsApplied!,
        );
  });

  return { priceDescriptionTag };
};
