<template>
  <div class="flex items-center justify-between">
    <AppLinkButton
      class="mr-2 text-sm"
      :text="capitalize($t('addAdditionalUnitTypeName', { unitTypeName }))"
      :disabled="!canAddUnitOccupancy"
      @click="addUnitOccupancy"
    />

    <AppPrimaryButton
      :button-text="searchButtonText"
      :icon="searchButtonIcon"
      class="min-w-[7rem] p-3"
      @click="search"
    />
  </div>
</template>

<script setup lang="ts">
import { type Icon } from '@tabler/icons-vue';
import { useOccupancySelectorStore } from '@/occupancy-selector/occupancy-selector.store';
import { useLocaleString } from '@/string/locale-string.composable';
import AppLinkButton from '@/ui/app-link-button/AppLinkButton.vue';
import AppPrimaryButton from '@/ui/app-primary-button/AppPrimaryButton.vue';

defineProps<{ searchButtonText: string; searchButtonIcon?: Icon }>();

const emit = defineEmits(['submit']);

const { capitalize } = useLocaleString();

const {
  canSubmit,
  canAddUnitOccupancy,
  unitTypeName,
  addUnitOccupancy,
  validate,
} = useOccupancySelectorStore();

const search = (): void => {
  validate();

  if (canSubmit.value) {
    emit('submit');
  }
};
</script>
