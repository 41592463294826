<template>
  <PropertyCheckInTimeSelector
    v-model="bookingItineraryStore.checkInTime"
    :property="searchStore.activeProperty"
  />
</template>

<script setup lang="ts">
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import PropertyCheckInTimeSelector from '@/property/check-in-time-selector/PropertyCheckInTimeSelector.vue';
import { useSearchStore } from '@/search/search.store';

const searchStore = useSearchStore();
const bookingItineraryStore = useBookingItineraryStore();
</script>
