import { Decimal } from 'decimal.js';
import { partition, isEqual, groupBy } from 'lodash-es';
import type { Occupancy } from '@/occupancy/occupancy';
import { isOccupancyALargerThanOccupancyB } from '@/occupancy/occupancy.utilities';
import type { PricePlan } from '@/price-plan/price-plan';
import type { OccupancyLimits } from '@/property/occupancy-limits/occupancy-limits';
import {
  occupancyLimitsCanFitOccupancy,
  constrainOccupancyToOccupancyLimits,
  occupancyLimitsAreEqualToOccupancy,
} from '@/property/occupancy-limits/occupancy-limits.utilities';
import {
  compareUnitWaysToSellByPriority,
  compareUnitsByPriority,
} from '@/property/unit/unit.utilities';

export const comparePricePlansByRate = (
  pricePlanA: PricePlan,
  pricePlanB: PricePlan,
): number => new Decimal(pricePlanA.rate).minus(pricePlanB.rate).toNumber();

export const comparePricePlansByOccupancyAndSearchOccupancyEquality = (
  pricePlanA: PricePlan,
  pricePlanB: PricePlan,
): number =>
  +isPricePlanOccupancyEqualToSearchOccupancy(pricePlanB) -
  +isPricePlanOccupancyEqualToSearchOccupancy(pricePlanA);

export const comparePricePlansByWhetherOccupancyCanFitSearchOccupancy = (
  pricePlanA: PricePlan,
  pricePlanB: PricePlan,
): number =>
  +isPricePlanSearchOccupancyLargerThanOccupancy(pricePlanA) -
  +isPricePlanSearchOccupancyLargerThanOccupancy(pricePlanB);

export const comparePricePlansByUnitPriority = (
  pricePlanA: PricePlan,
  pricePlanB: PricePlan,
  priorityOrderedUnitIds: number[],
) =>
  compareUnitsByPriority(
    priorityOrderedUnitIds,
    pricePlanA.unit,
    pricePlanB.unit,
  );

export const comparePricePlansByWayToSellPriority = (
  pricePlanA: PricePlan,
  pricePlanB: PricePlan,
) => {
  if (!pricePlanA.wayToSell) {
    return 1;
  }

  if (!pricePlanB.wayToSell) {
    return -1;
  }

  return compareUnitWaysToSellByPriority(
    pricePlanA.unit,
    pricePlanA.wayToSell,
    pricePlanB.wayToSell,
  );
};

const isPricePlanOccupancyEqualToSearchOccupancy = ({
  occupancy,
  searchOccupancy,
}: PricePlan): boolean => isEqual(occupancy, searchOccupancy);

export const isPricePlanSearchOccupancyLargerThanOccupancy = ({
  occupancy,
  searchOccupancy,
}: PricePlan): boolean =>
  isOccupancyALargerThanOccupancyB(searchOccupancy, occupancy);

export const partitionPricePlansByOccupancyLimitsAndSearchOccupancyEquality = <
  T extends PricePlan,
>(
  pricePlans: T[],
): [T[], T[]] =>
  partition(pricePlans, (pricePlan) =>
    isPricePlanSearchOccupancyEqualToOccupancyLimits(pricePlan),
  );

export const partitionPricePlansByOccupancyLimitsThatCanFitSearchOccupancy = <
  T extends PricePlan,
>(
  pricePlans: T[],
): [T[], T[]] =>
  partition(pricePlans, (pricePlan) =>
    canPricePlanOccupancyLimitsFitSearchOccupancy(pricePlan),
  );

export const partitionPricePlansByOccupanciesThatDidFitSearchOccupancy = <
  T extends PricePlan,
>(
  pricePlans: T[],
): [T[], T[]] =>
  partition(pricePlans, (pricePlan) =>
    isPricePlanSearchOccupancyLargerThanOccupancy(pricePlan),
  );

export const partitionPricePlansByOccupancyAndSearchOccupancyEquality = (
  pricePlans: PricePlan[],
): [PricePlan[], PricePlan[]] =>
  partition(pricePlans, ({ occupancy, searchOccupancy }) =>
    isEqual(searchOccupancy, occupancy),
  );

export const groupPricePlansByAttribute = <T extends PricePlan>(
  pricePlans: T[],
  attribute: keyof PricePlan,
): T[][] =>
  Object.values(
    groupBy(pricePlans, (pricePlan) => JSON.stringify(pricePlan[attribute])),
  );

/**
 * The price plan occupancy will fit the given search occupancy as closely as it can, according to
 * the given occupancy limits.
 */
export const calculatePricePlanOccupancy = (
  searchOccupancy: Occupancy,
  occupancyLimits: OccupancyLimits,
): Occupancy =>
  occupancyLimitsCanFitOccupancy(occupancyLimits, searchOccupancy)
    ? searchOccupancy
    : constrainOccupancyToOccupancyLimits(occupancyLimits, searchOccupancy);

/**
 * The occupancy limits of the price plan should be the occupancy limits of the way to sell where
 * applicable, otherwise, the occupancy limits of the unit.
 */
export const getPricePlanOccupancyLimits = ({
  wayToSell,
  unit,
}: PricePlan): OccupancyLimits =>
  wayToSell?.occupancyLimits ?? unit.occupancyLimits;

const isPricePlanSearchOccupancyEqualToOccupancyLimits = (
  pricePlan: PricePlan,
): boolean => {
  const occupancyLimits = getPricePlanOccupancyLimits(pricePlan);

  return occupancyLimitsAreEqualToOccupancy(
    occupancyLimits,
    pricePlan.searchOccupancy,
  );
};

const canPricePlanOccupancyLimitsFitSearchOccupancy = (
  pricePlan: PricePlan,
): boolean => {
  const occupancyLimits = getPricePlanOccupancyLimits(pricePlan);

  return occupancyLimitsCanFitOccupancy(
    occupancyLimits,
    pricePlan.searchOccupancy,
  );
};

export const findPricePlansWithSameOccupancyLimitsAsPricePlan = <
  T extends PricePlan,
>(
  pricePlanToCompare: T,
  pricePlans: T[],
): T[] =>
  findPricePlansWithOccupancyLimits(
    getPricePlanOccupancyLimits(pricePlanToCompare),
    pricePlans,
  );

export const findPricePlansWithSameSearchOccupancyAsPricePlan = <
  T extends PricePlan,
>(
  pricePlanToCompare: T,
  pricePlans: T[],
): T[] =>
  findPricePlansWithSearchOccupancy(
    pricePlanToCompare.searchOccupancy,
    pricePlans,
  );

export const findPricePlansWithSameOccupancyLimitsAsCheapestPricePlan = <
  T extends PricePlan,
>(
  pricePlans: T[],
): T[] =>
  findPricePlansWithSameOccupancyLimitsAsPricePlan(
    findCheapestPricePlan(pricePlans),
    pricePlans,
  );

const findPricePlansWithOccupancyLimits = <T extends PricePlan>(
  occupancyLimitsToFind: OccupancyLimits,
  pricePlans: T[],
): T[] =>
  pricePlans.filter((pricePlan) => {
    const occupancyLimits = getPricePlanOccupancyLimits(pricePlan);

    return isEqual(occupancyLimits, occupancyLimitsToFind);
  });

const findPricePlansWithSearchOccupancy = <T extends PricePlan>(
  searchOccupancy: Occupancy,
  pricePlans: T[],
): T[] =>
  pricePlans.filter((pricePlan) =>
    isEqual(pricePlan.searchOccupancy, searchOccupancy),
  );

export const findCheapestPricePlan = <T extends PricePlan>(
  pricePlans: T[],
): T =>
  pricePlans.reduce((previousCheapestPricePlan, currentPricePlan) =>
    new Decimal(currentPricePlan.rate).lessThan(previousCheapestPricePlan.rate)
      ? currentPricePlan
      : previousCheapestPricePlan,
  );

export const findLargestOccupancyPricePlan = <T extends PricePlan>(
  pricePlans: T[],
): T =>
  pricePlans.reduce((previousLargestOccupancyPricePlan, currentPricePlan) =>
    isOccupancyALargerThanOccupancyB(
      currentPricePlan.occupancy,
      previousLargestOccupancyPricePlan.occupancy,
    )
      ? currentPricePlan
      : previousLargestOccupancyPricePlan,
  );

export const isPricePlanCheaperThanAllPricePlans = (
  pricePlanToCompare: PricePlan,
  pricePlans: PricePlan[],
): boolean =>
  pricePlans.every((pricePlan) =>
    new Decimal(pricePlanToCompare.rate).lessThan(pricePlan.rate),
  );
