<template>
  <PropertyLogo
    v-if="searchStore.activeProperty.propertyTheme.logo"
    :property-theme-logo="searchStore.activeProperty.propertyTheme.logo"
    :min-edge-length="125"
    :max-edge-length="175"
  />
</template>

<script setup lang="ts">
import PropertyLogo from '@/property/property-theme/logo/PropertyLogo.vue';
import { useSearchStore } from '@/search/search.store';

const searchStore = useSearchStore();
</script>
