import { GrammaticalGender } from '@/i18n/locales/gender/gender';
import type { TranslationKeyToMessageFunction } from '@/i18n/locales/message-functions/message-functions';
import type esESStaticTranslations from '@/i18n/locales/messages/es-ES.json';

export type EsESMessageFunctions = Omit<
  TranslationKeyToMessageFunction,
  keyof typeof esESStaticTranslations
>;

export const esES: EsESMessageFunctions = {
  youHaveSelectedTheLastUnitAvailable: ({ values }) => {
    switch (values.unitTypeGender) {
      case GrammaticalGender.Neuter:
      case GrammaticalGender.Masculine:
        return `Has seleccionado el último ${values.unitTypeName} disponible`;
      case GrammaticalGender.Feminine:
        return `Has seleccionado la última ${values.unitTypeName} disponible`;
    }
  },
  pleaseSelectAUnitToContinue: ({ values }) => {
    switch (values.unitTypeGender) {
      case GrammaticalGender.Neuter:
      case GrammaticalGender.Masculine:
        return `Por favor, seleccione un ${values.unitTypeName} para continuar`;
      case GrammaticalGender.Feminine:
        return `Por favor, seleccione una ${values.unitTypeName} para continuar`;
    }
  },
  someOfTheUnits: ({ values }) => {
    switch (values.unitTypeGender) {
      case GrammaticalGender.Neuter:
      case GrammaticalGender.Masculine:
        return `Algunos de los ${values.unitTypeName}`;
      case GrammaticalGender.Feminine:
        return `Algunas de las ${values.unitTypeName}`;
    }
  },
  theseUnitsWillBeDiscardedIfYouSwitchToPropertyName: ({ type, values }) => {
    if (type !== 'vnode') {
      switch (values.unitTypeGender) {
        case GrammaticalGender.Neuter:
        case GrammaticalGender.Masculine:
          return `Estos ${values.unitTypeName} se descartarán si cambia a ${values.propertyName}.`;
        case GrammaticalGender.Feminine:
          return `Estas ${values.unitTypeName} se descartarán si cambia a ${values.propertyName}.`;
      }
    }

    return [
      values.unitTypeGender[0]!.children === GrammaticalGender.Feminine
        ? 'Estas '
        : 'Estos ',
      values.unitTypeName,
      ' se descartarán si cambia a ',
      values.propertyName,
      '.',
    ];
  },
  youAreAboutToRemoveTheLastUnit: ({ values }) => {
    switch (values.unitTypeGender) {
      case GrammaticalGender.Neuter:
      case GrammaticalGender.Masculine:
        return `Está a punto de eliminar el último ${values.unitTypeName} de su selección. Será redirigido a la página de selección de ${values.unitTypeName}. ¿Desea continuar?`;
      case GrammaticalGender.Feminine:
        return `Está a punto de eliminar la última ${values.unitTypeName} de su selección. Será redirigido a la página de selección de ${values.unitTypeName}. ¿Desea continuar?`;
    }
  },
  ifYouRemoveThisPrivateRateYourUnitsWillBeRemovedAndYouWillBeRedirected: ({
    values: { unitTypeGender, unitTypeName, unitTypeNamePlural },
  }) => {
    switch (unitTypeGender) {
      case GrammaticalGender.Neuter:
      case GrammaticalGender.Masculine:
        return `Si elimina esta tarifa privada, su ${unitTypeNamePlural} actualmente seleccionado será eliminado y será redirigido a la página de selección del ${unitTypeName}. ¿Le gustaría continuar?`;
      case GrammaticalGender.Feminine:
        return `Si elimina esta tarifa privada, su ${unitTypeNamePlural} actualmente seleccionada será eliminada y será redirigida a la página de selección de la ${unitTypeName}. ¿Le gustaría continuar?`;
    }
  },
  unitsBooked: ({ values: { unitTypeName, unitTypeGender } }) => {
    switch (unitTypeGender) {
      case GrammaticalGender.Neuter:
      case GrammaticalGender.Masculine:
        return `${unitTypeName} reservados`;
      case GrammaticalGender.Feminine:
        return `${unitTypeName} reservadas`;
    }
  },
};
