<template>
  <div class="flex w-full flex-col items-center">
    <AvailabilityCalendarDayNames class="sticky top-0 bg-white px-2" />
    <div class="overflow-x-hidden">
      <div class="text-center">
        <button
          v-if="canNavigateUp"
          class="my-2 rounded-full border-2 border-slate-800 px-8 py-2 font-medium capitalize focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
          @click="onNavigateUp"
        >
          {{ $t('showMoreDates') }}
        </button>
      </div>
      <div ref="availabilityCalendar">
        <div
          v-for="(startOfMonthDate, index) in startOfMonthDates"
          :key="startOfMonthDate"
          :ref="
            (element) => {
              availabilityCalendarMonths[index] = element as HTMLElement;
            }
          "
          class="px-2 py-4"
        >
          <AvailabilityCalendarMonth :start-of-month-date="startOfMonthDate" />
        </div>
      </div>
      <div class="text-center">
        <button
          ref="showMoreButton"
          class="my-2 rounded-full border-2 border-slate-800 px-8 py-2 font-medium capitalize focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
          @click="onNavigateDown"
        >
          {{ $t('showMoreDates') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { addMonths, format, isFuture } from 'date-fns';
import {
  ref,
  nextTick,
  onMounted,
  computed,
  watch,
  onBeforeUnmount,
} from 'vue';
import { useAvailabilityCalendarStore } from '@/availability-calendar/availability-calendar.store';
import AvailabilityCalendarDayNames from '@/availability-calendar/AvailabilityCalendarDayNames.vue';
import AvailabilityCalendarMonth from '@/availability-calendar/AvailabilityCalendarMonth.vue';

const NUM_OF_MONTHS_TO_MOVE = 6;

const showMoreButton = ref<HTMLElement>();
const scrollableParent = ref<HTMLElement>();
const availabilityCalendar = ref<HTMLElement>();
const availabilityCalendarMonths = ref<HTMLElement[]>([]);

const { load } = useAvailabilityCalendarStore();

const props = defineProps<{
  startOfMonthDates: number[];
}>();

const emit =
  defineEmits<
    (
      eventName: 'navigateUp' | 'navigateDown',
      numOfMonthsToMove: number,
      updateDate: boolean,
    ) => void
  >();

onMounted(() => {
  observer.observe(showMoreButton.value!);
});

onBeforeUnmount(() => {
  observer.unobserve(showMoreButton.value!);
});

watch(
  () => props.startOfMonthDates,
  async () => {
    const startDate = props.startOfMonthDates.at(0)!;
    const endDate = props.startOfMonthDates.at(-1)!;
    await loadCalendarAvailability(startDate, endDate);
  },
);

const observer = new IntersectionObserver(
  ([entry]) => {
    if (entry?.isIntersecting) {
      const startDate = addMonths(props.startOfMonthDates.at(-1)!, 1).getTime();
      const endDate = addMonths(
        props.startOfMonthDates.at(-1)!,
        NUM_OF_MONTHS_TO_MOVE,
      ).getTime();
      void loadCalendarAvailability(startDate, endDate);
    }
  },
  { threshold: 0.1 },
);

const getScrollableParent = (
  element: HTMLElement | undefined,
): HTMLElement | undefined => {
  if (element) {
    if (element.scrollHeight > element.clientHeight) {
      return element;
    } else {
      return getScrollableParent(element.parentElement ?? undefined);
    }
  }
  return undefined;
};

const canNavigateUp = computed(() => {
  const firstMonthInList = props.startOfMonthDates[0];
  return firstMonthInList && isFuture(firstMonthInList);
});

const onNavigateUp = () => {
  const element = availabilityCalendarMonths.value[0];
  let previousOffset = 0;
  if (element && availabilityCalendar.value) {
    previousOffset = element.offsetTop - availabilityCalendar.value.scrollTop;
  }

  const numOfMonthsBeforeUpdate = props.startOfMonthDates.length;

  emit('navigateUp', NUM_OF_MONTHS_TO_MOVE, false);

  if (scrollableParent.value === undefined) {
    scrollableParent.value = getScrollableParent(availabilityCalendar.value);
  }

  void nextTick(() => {
    if (scrollableParent.value) {
      const indexOfMonthToKeepInView =
        props.startOfMonthDates.length - numOfMonthsBeforeUpdate;
      const elementToResetScrollTop =
        availabilityCalendarMonths.value[indexOfMonthToKeepInView];
      if (elementToResetScrollTop) {
        scrollableParent.value.scrollTop =
          elementToResetScrollTop.offsetTop - previousOffset;
      }
    }
  });
};

const onNavigateDown = () => {
  emit('navigateDown', NUM_OF_MONTHS_TO_MOVE, false);
};

const loadCalendarAvailability = async (startDate: number, endDate: number) => {
  await load(format(startDate, 'yyyy-MM-dd'), format(endDate, 'yyyy-MM-dd'));
};
</script>
