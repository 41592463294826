import type {
  DiscountOfferMealAdjustment,
  DiscountOfferMealAdjustmentDTO,
} from '@/property/offer/discount-offer/adjustment/meal-adjustment/discount-offer-meal-adjustment';

export const mapDiscountOfferMealAdjustmentFromDTO = (
  discountOfferMealAdjustmentDTO: DiscountOfferMealAdjustmentDTO,
): DiscountOfferMealAdjustment => ({
  mealType: discountOfferMealAdjustmentDTO.mealType,
  adjustmentAmount: Number(discountOfferMealAdjustmentDTO.adjustmentAmount),
});
