import type {
  AllowedChildPolicy,
  AllowedChildPolicyDTO,
} from '@/property/child-policy/allowed/allowed-child-policy';

export const mapAllowedChildPolicyFromDTO = (
  allowedChildPolicyDTO: AllowedChildPolicyDTO,
): AllowedChildPolicy => ({
  childrenAreAllowed: true,
  minimumChildAge: allowedChildPolicyDTO.minimumChildAge,
  maximumChildAge: allowedChildPolicyDTO.maximumChildAge,
});
