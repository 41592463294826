import { PaymentMethodType } from '@/payment-method/payment-method';
import type {
  CardPaymentMethodPolicy,
  CardPaymentMethodPolicyDTO,
} from '@/property/booking-policy/payment-method-policy/card/card-payment-method-policy';
import { mapCardPaymentMethodPolicyCardFromDTO } from '@/property/booking-policy/payment-method-policy/card/card-payment-method-policy-card.mapper';

export const mapCardPaymentMethodPolicyFromDTO = (
  cardPaymentMethodPolicyDTO: CardPaymentMethodPolicyDTO,
): CardPaymentMethodPolicy => ({
  paymentMethodType: PaymentMethodType.Card,
  cards: cardPaymentMethodPolicyDTO.cards.map(
    mapCardPaymentMethodPolicyCardFromDTO,
  ),
});
