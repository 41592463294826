import type { ChildOccupant } from '@/occupancy/child-occupant/child-occupant';

export const DEFAULT_NUMBER_OF_ADULTS = 2;

export interface Occupancy {
  numberOfAdults: number;
  children: ChildOccupant[];
}

export interface AdultOnlyOccupancy extends Occupancy {
  children: never[];
}

export type OccupancyDTO = Occupancy;
