import type {
  BookingCreateConfirmedResult,
  BookingCreateConfirmedResultDTO,
} from '@/booking/create/result/confirmed/booking-create-confirmed-result';
import type {
  BookingCreateSagePay3DSRequiredResult,
  BookingCreateSagePay3DSRequiredResultDTO,
} from '@/booking/create/result/sage-pay-3ds-required/booking-create-sage-pay-3ds-required-result';
import type {
  BookingCreateStripeActionRequiredResult,
  BookingCreateStripeActionRequiredResultDTO,
} from '@/booking/create/result/stripe-action-required/booking-create-stripe-action-required';

export enum BookingCreateResultType {
  Confirmed = 'confirmed',
  SagePay3DSRequired = 'sage_pay_3ds_required',
  StripeActionRequired = 'stripe_action_required',
}

export type BookingCreateResult =
  | BookingCreateConfirmedResult
  | BookingCreateSagePay3DSRequiredResult
  | BookingCreateStripeActionRequiredResult;

export type BookingCreateResultDTO =
  | BookingCreateConfirmedResultDTO
  | BookingCreateSagePay3DSRequiredResultDTO
  | BookingCreateStripeActionRequiredResultDTO;
