<template>
  <div
    v-if="!bookingStageStore.shouldShowDropdownSelector"
    class="flex items-center"
  >
    <button
      class="mr-2 focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-slate-800"
      :aria-label="$t('goToPreviousPage')"
      @click="bookingStageStore.goToPreviousStage"
    >
      <IconChevronLeft :size="38" />
    </button>

    <h2 class="text-2xl font-semibold capitalize">
      {{ label }}
    </h2>
  </div>
</template>

<script setup lang="ts">
import { IconChevronLeft } from '@tabler/icons-vue';
import { useBookingStageStore } from '@/booking-stage/booking-stage.store';

const bookingStageStore = useBookingStageStore();

defineProps<{
  label: string;
}>();
</script>
