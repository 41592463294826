<template>
  <AppPopover v-slot="{ close }">
    <div ref="popoverButton">
      <AppPopoverButton :as="VanitySearchOptionButton" :icon="IconCalendar">
        <VanitySearchAvailabilityCalendarButtonContent />
      </AppPopoverButton>
    </div>

    <div
      ref="popoverPanel"
      class="pointer-events-none absolute left-0 right-0 z-10 flex justify-center"
      :style="floatingStyles"
    >
      <div class="pointer-events-auto">
        <AppPopoverPanel>
          <AvailabilityCalendarContainer
            :check-in-date="
              vanityStore.stayDates?.checkInDate ?? today.toISOString()
            "
            :check-out-date="
              vanityStore.stayDates?.checkOutDate ?? tomorrow.toISOString()
            "
            :auto-select-stay-dates="false"
          />

          <template #footer>
            <AvailabilityCalendarFooter
              :button-text="capitalize($t('select'))"
              @submit="
                () => {
                  close();
                  $emit('submit');
                }
              "
            />
          </template>
        </AppPopoverPanel>
      </div>
    </div>
  </AppPopover>
</template>

<script setup lang="ts">
import { shift, useFloating, autoUpdate } from '@floating-ui/vue';
import { IconCalendar } from '@tabler/icons-vue';
import { addDays } from 'date-fns';
import { ref } from 'vue';
import AvailabilityCalendarContainer from '@/availability-calendar/AvailabilityCalendarContainer.vue';
import AvailabilityCalendarFooter from '@/availability-calendar/AvailabilityCalendarFooter.vue';
import { getCurrentDateObjectInTimezone } from '@/date/date.utilities';
import { useSearchStore } from '@/search/search.store';
import { useLocaleString } from '@/string/locale-string.composable';
import AppPopover from '@/ui/app-popover/AppPopover.vue';
import AppPopoverButton from '@/ui/app-popover/AppPopoverButton.vue';
import AppPopoverPanel from '@/ui/app-popover/AppPopoverPanel.vue';
import VanitySearchAvailabilityCalendarButtonContent from '@/vanity-page/search/availability-calendar/VanitySearchAvailabilityCalendarButtonContent.vue';
import VanitySearchOptionButton from '@/vanity-page/search/button/VanitySearchOptionButton.vue';
import { useVanityStore } from '@/vanity-page/vanity.store';

defineEmits(['submit']);

const searchStore = useSearchStore();
const vanityStore = useVanityStore();

const { capitalize } = useLocaleString();

const popoverButton = ref<HTMLElement | null>(null);
const popoverPanel = ref<HTMLElement | null>(null);

const today = getCurrentDateObjectInTimezone(
  searchStore.activeProperty.timezone,
);

const tomorrow = addDays(today, 1);

const { floatingStyles } = useFloating(popoverButton, popoverPanel, {
  placement: 'bottom',
  middleware: [
    shift({
      crossAxis: true,
    }),
  ],
  whileElementsMounted: autoUpdate,
});
</script>
