import type {
  AdditionalTaxRate,
  AdditionalTaxRateDTO,
} from '@/tax/additional/rate/additional-tax-rate';
import { mapAdditionalTaxRateBasisFromDTO } from '@/tax/additional/rate/basis/additional-tax-rate-basis.mapper';
import { mapAdditionalTaxRateChargeFromDTO } from '@/tax/additional/rate/charge/additional-tax-rate-charge.mapper';
import { mapAdditionalTaxRateChargeLimitFromDTO } from '@/tax/additional/rate/charge-limit/additional-tax-rate-charge-limit.mapper';

export const mapAdditionalTaxRateFromDTO = (
  additionalTaxRateDTO: AdditionalTaxRateDTO,
): AdditionalTaxRate => ({
  charge: mapAdditionalTaxRateChargeFromDTO(additionalTaxRateDTO.charge),
  chargeLimit: additionalTaxRateDTO.chargeLimit
    ? mapAdditionalTaxRateChargeLimitFromDTO(additionalTaxRateDTO.chargeLimit)
    : undefined,
  basis: mapAdditionalTaxRateBasisFromDTO(additionalTaxRateDTO.basis),
  frequencyType: additionalTaxRateDTO.frequencyType,
  inclusionType: additionalTaxRateDTO.inclusionType,
});
