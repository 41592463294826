import { AdditionalTaxRateBasisType } from '@/tax/additional/rate/basis/additional-tax-rate-basis';
import type {
  AdditionalTaxRateGuestBasis,
  AdditionalTaxRateGuestBasisDTO,
} from '@/tax/additional/rate/basis/guest/additional-tax-rate-guest-basis';

export const mapAdditionalTaxRateGuestBasisFromDTO = (
  additionalTaxRateGuestBasisDTO: AdditionalTaxRateGuestBasisDTO,
): AdditionalTaxRateGuestBasis => ({
  type: AdditionalTaxRateBasisType.Guest,
  minimumAge: additionalTaxRateGuestBasisDTO.minimumAge ?? undefined,
});
