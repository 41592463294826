<template>
  <div class="absolute inset-0">
    <img
      :src="generateImageUrl(500, 300, true)"
      alt=""
      class="absolute z-[1] h-full w-full object-cover"
    />

    <picture>
      <source media="(max-width: 500px)" :srcset="generateImageUrl(500, 300)" />

      <source
        media="(max-width: 1000px)"
        :srcset="generateImageUrl(1000, 400)"
      />

      <source
        media="(min-width: 1000px)"
        :srcset="generateImageUrl(2000, 400)"
      />

      <img alt="" class="absolute z-[2] h-full w-full object-cover" />
    </picture>
  </div>
</template>

<script setup lang="ts">
import { format, quality } from '@cloudinary/url-gen/actions/delivery';
import { blur } from '@cloudinary/url-gen/actions/effect';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { auto } from '@cloudinary/url-gen/qualifiers/format';
import { cloudinary } from '@/cloudinary';
import type { PropertyThemeHeader } from '@/property/property-theme/header/property-theme-header';

const props = defineProps<{
  propertyHeader: PropertyThemeHeader;
}>();

const generateImageUrl = (
  width: number,
  height: number,
  isPlaceholder = false,
): string => {
  const image = cloudinary
    .image(props.propertyHeader.url)
    .setDeliveryType('fetch')
    .delivery(format(auto()))
    .resize(fill().width(width).height(height));

  if (isPlaceholder) {
    image.effect(blur(500)).delivery(quality('auto'));
  }

  return image.toURL();
};
</script>
