<template>
  <TermsAndConditionsStatement
    class="rounded-md border p-4"
    :property="bookingConfirmationStore.property"
    :booking-policy="bookingConfirmationStore.booking.bookingPolicy"
    :stay-dates="bookingConfirmationStore.bookingStayDates"
    :is-non-refundable="
      bookingConfirmationStore.booking.isAnyActiveBookingUnitNonRefundable
    "
  />
</template>

<script setup lang="ts">
import { useBookingConfirmationStore } from '@/booking-confirmation-page/booking-confirmation.store';
import TermsAndConditionsStatement from '@/terms-and-conditions/statement/TermsAndConditionsStatement.vue';

const bookingConfirmationStore = useBookingConfirmationStore();
</script>
