import type {
  BookingAdHocSupplement,
  BookingAdHocSupplementDTO,
} from '@/booking/ad-hoc-supplement/booking-ad-hoc-supplement';
import type {
  BookingTaxes,
  BookingTaxesDTO,
} from '@/booking/booking-taxes/booking-taxes';
import type {
  BookingExtra,
  BookingExtraDTO,
} from '@/booking/extra/booking-extra';
import type {
  BookingGuest,
  BookingGuestDTO,
} from '@/booking/guest/booking-guest';
import type {
  BookingPayment,
  BookingPaymentDTO,
} from '@/booking/payment/booking-payment';
import type {
  BookingPrivateRateOverride,
  BookingPrivateRateOverrideDTO,
} from '@/booking/private-rate-override/booking-private-rate-override';
import type {
  ActiveBookingUnit,
  ActiveBookingUnitDTO,
} from '@/booking/unit/active/active-booking-unit';
import type {
  CancelledBookingUnit,
  CancelledBookingUnitDTO,
} from '@/booking/unit/cancelled/cancelled-booking-unit';
import type {
  BookingPolicy,
  BookingPolicyDTO,
} from '@/property/booking-policy/booking-policy';
import type { Promocode, PromocodeDTO } from '@/property/promocode/promocode';
import type { Property, PropertyDTO } from '@/property/property';
import type { Time } from '@/time/time';

export const FIRST_NAME_MAX_LENGTH = 20;
export const SURNAME_MAX_LENGTH = 64;
export const EMAIL_MAX_LENGTH = 128;
export const PHONE_NUMBER_MAX_LENGTH = 20;
export const ADDRESS_STREET_MAX_LENGTH = 30;
export const ADDRESS_CITY_MAX_LENGTH = 25;
export const ADDRESS_POSTCODE_MAX_LENGTH = 20;
export const ADDRESS_ADMINISTRATIVE_AREA_MAX_LENGTH = 25;
export const LEAD_GUEST_NAME_MAX_LENGTH = 97;
export const SPECIAL_REQUESTS_MAX_LENGTH = 255;

export interface Booking {
  id: string;
  prefix: string;
  checkInDate: string;
  checkOutDate: string;
  arrivalTime: Time | undefined;
  specialRequests: string | undefined;
  locale: string;
  widgetId: string;
  property: Property;
  activeBookingUnits: ActiveBookingUnit[];
  cancelledBookingUnits: CancelledBookingUnit[];
  bookingExtras: BookingExtra[];
  bookingAdHocSupplements: BookingAdHocSupplement[];
  bookingGuest: BookingGuest;
  bookingPolicy: BookingPolicy;
  bookingTaxes: BookingTaxes;
  bookingPayments: BookingPayment[];
  promocode: Promocode | undefined;
  bookingPrivateRateOverride: BookingPrivateRateOverride | undefined;
  totalAmount: number;
  totalAmountBeforeTax: number;
  amountPaid: number;
  amountDue: number;
  isCancelled: boolean;
  isAnyActiveBookingUnitNonRefundable: boolean;
  depositAmount: number;
  guestCanStartMessagingConversation: boolean;
  usedExpressCheckout: boolean;
  usedElements: boolean;
  hasBeenModifiedByGuest: boolean;
  creationDateTime: string;
}

export interface BookingDTO
  extends Omit<
    Booking,
    | 'arrivalTime'
    | 'specialRequests'
    | 'property'
    | 'activeBookingUnits'
    | 'cancelledBookingUnits'
    | 'bookingExtras'
    | 'bookingAdHocSupplements'
    | 'bookingGuest'
    | 'bookingPolicy'
    | 'bookingTaxes'
    | 'bookingPayments'
    | 'promocode'
    | 'bookingPrivateRateOverride'
  > {
  arrivalTime: Time | null;
  specialRequests: string | null;
  property: PropertyDTO;
  activeBookingUnits: ActiveBookingUnitDTO[];
  cancelledBookingUnits: CancelledBookingUnitDTO[];
  bookingExtras: BookingExtraDTO[];
  bookingAdHocSupplements: BookingAdHocSupplementDTO[];
  bookingGuest: BookingGuestDTO;
  bookingPolicy: BookingPolicyDTO;
  bookingTaxes: BookingTaxesDTO;
  bookingPayments: BookingPaymentDTO[];
  promocode: PromocodeDTO | null;
  bookingPrivateRateOverride: BookingPrivateRateOverrideDTO | null;
}
