import type {
  StripeElements,
  StripeElementsOptionsMode,
  StripeExpressCheckoutElementOptions,
} from '@stripe/stripe-js';

export const generateStripeElementsOptions = (
  amount: number,
  currency: string,
): StripeElementsOptionsMode => ({
  mode: amount > 0 ? 'payment' : 'setup',
  amount,
  currency,
  paymentMethodCreation: 'manual',
  fonts: [
    {
      cssSrc:
        'https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap',
    },
  ],
  appearance: {
    labels: 'floating',
    variables: {
      fontSmooth: 'always',
      fontFamily: 'Montserrat',
      colorText: 'rgb(30, 41, 59)',
      colorDanger: 'rgb(185, 28, 28)',
      colorTextPlaceholder: 'rgb(107, 114, 128)',
      borderRadius: '0.5rem',
      focusBoxShadow: 'rgb(31, 41, 55) 0px 0px 0px 2px',
    },
    rules: {
      '.Input': {
        borderColor: 'rgb(209, 213, 219)',
        boxShadow: 'none',
      },
      '.Input:focus': {
        borderColor: 'transparent',
      },
      '.Input--invalid': {
        borderColor: 'var(--colorDanger)',
        boxShadow: 'none',
      },
      '.Input--invalid:focus': {
        borderColor: 'var(--colorDanger)',
        boxShadow: 'var(--colorDanger) 0px 0px 0px 1px',
      },
      '.Label--invalid': {
        color: 'var(--colorDanger)',
      },
    },
  },
});

export const generateStripePaymentElementOptions = (
  emailAddress: string,
  postcode: string,
  countryCode: string,
) =>
  ({
    layout: 'tabs',
    terms: { card: 'never' },
    defaultValues: {
      billingDetails: {
        email: emailAddress,
        address: { postal_code: postcode, country: countryCode },
      },
    },
  }) as const;

export const generateStripeExpressCheckoutOptions =
  (): StripeExpressCheckoutElementOptions => ({
    buttonType: {
      applePay: 'book',
      googlePay: 'book',
    },
  });

export const generateStripePaymentMethodOptions = (
  elements: StripeElements,
  name: string,
) => ({
  elements,
  params: {
    billing_details: {
      name,
    },
  },
});
