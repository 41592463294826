<template>
  <div class="truncate text-sm">
    {{ checkInDateText }} • {{ stayLengthText }}
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { createDateObject } from '@/date/date.utilities';
import type { StayDates } from '@/stay-dates/stay-dates';
import { getStayLengthFromStayDates } from '@/stay-dates/stay-dates.utilities';

const props = defineProps<{ stayDates: StayDates }>();

const { t, d } = useI18n();

const checkInDateText = computed(() =>
  d(createDateObject(props.stayDates.checkInDate), {
    day: 'numeric',
    month: 'short',
  }),
);

const stayLengthText = computed(() =>
  t('nightCount', getStayLengthFromStayDates(props.stayDates)),
);
</script>
