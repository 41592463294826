<template>
  <section data-test="UnitSelectionPricePlan" class="grid gap-2">
    <UnitSelectionPricePlanHeader :price-plan="pricePlan" />

    <UnitSelectionPricePlanStatuses :price-plan="pricePlan" />

    <UnitSelectionPricePlanDiscountTag
      v-if="pricePlan.type === PricePlanVariantType.Discounted"
      :price-plan="pricePlan"
    />

    <UnitSelectionPricePlanPromocodeDiscountTag
      v-if="promocode"
      :promocode="promocode"
    />

    <UnitSelectionPricePlanOccupancy :price-plan="pricePlan" />

    <UnitSelectionPricePlanPackage
      v-if="pricePlan.type === PricePlanVariantType.Package"
      :price-plan="pricePlan"
    />

    <UnitSelectionMealPricePlans
      v-else-if="pricePlan.type !== PricePlanVariantType.Private"
      :price-plan="pricePlan"
    />

    <UnitSelectionPricePlanFooter :price-plan="pricePlan" />
  </section>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { PricePlan } from '@/price-plan/price-plan';
import { PricePlanVariantType } from '@/price-plan/price-plan';
import UnitSelectionPricePlanDiscountTag from '@/unit-selection-page/price-plan/discount-tag/UnitSelectionPricePlanDiscountTag.vue';
import UnitSelectionPricePlanFooter from '@/unit-selection-page/price-plan/footer/UnitSelectionPricePlanFooter.vue';
import UnitSelectionPricePlanHeader from '@/unit-selection-page/price-plan/header/UnitSelectionPricePlanHeader.vue';
import UnitSelectionMealPricePlans from '@/unit-selection-page/price-plan/meal-price-plan/UnitSelectionMealPricePlans.vue';
import UnitSelectionPricePlanOccupancy from '@/unit-selection-page/price-plan/occupancy/UnitSelectionPricePlanOccupancy.vue';
import UnitSelectionPricePlanPackage from '@/unit-selection-page/price-plan/package/UnitSelectionPricePlanPackage.vue';
import UnitSelectionPricePlanPromocodeDiscountTag from '@/unit-selection-page/price-plan/promocode-discount-tag/UnitSelectionPricePlanPromocodeDiscountTag.vue';
import UnitSelectionPricePlanStatuses from '@/unit-selection-page/price-plan/status/UnitSelectionPricePlanStatuses.vue';

const props = defineProps<{
  pricePlan: PricePlan;
}>();

const promocode = computed(() =>
  props.pricePlan.type !== PricePlanVariantType.Package &&
  props.pricePlan.type !== PricePlanVariantType.Private
    ? props.pricePlan.promocode
    : undefined,
);
</script>
