<template>
  <Transition
    enter-active-class="ease-out duration-300"
    enter-from-class="opacity-0 -translate-y-1"
    enter-to-class="opacity-100 translate-y-0"
    leave-active-class="ease-in duration-200"
    leave-from-class="opacity-100 translate-y-0"
    leave-to-class="opacity-0 -translate-y-1"
  >
    <div
      v-show="isOpen"
      role="tooltip"
      class="rounded-md border border-neutral-200 bg-white p-2 shadow-[0_0_12px_1px_rgba(0,0,0,0.2)]"
    >
      <div role="region">
        <slot></slot>
      </div>

      <div
        id="tooltipArrow"
        class="absolute z-10 h-4 w-4 rotate-45 rounded-sm bg-white"
        aria-hidden
      ></div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { useTimeoutFn } from '@vueuse/core';
import { onUnmounted, watch } from 'vue';

const props = withDefaults(
  defineProps<{
    isOpen: boolean;
    autoHide?: boolean;
    autoHideDelay?: number;
  }>(),
  {
    autoHide: true,
    autoHideDelay: 1500,
  },
);

const emit = defineEmits<(eventName: 'close') => void>();

const { start } = useTimeoutFn(() => {
  emit('close');
}, props.autoHideDelay);

onUnmounted(() => {
  removeEventListeners();
});

watch(
  () => props.isOpen,
  (isOpen) => {
    if (isOpen) {
      addEventListeners();
      if (props.autoHide) {
        start();
      }
    } else {
      removeEventListeners();
    }
  },
);

const addEventListeners = () => {
  document.addEventListener('keyup', handleKeyUpEvents);
};

const removeEventListeners = () => {
  document.removeEventListener('keyup', handleKeyUpEvents);
};

const handleKeyUpEvents = (event: KeyboardEvent) => {
  if (event.key === 'Escape') {
    emit('close');
  }
};
</script>
